// Chakra imports
import {
  FormControl,
  Flex,
  useToast,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  CircularProgress,
  ModalBody,
  ModalCloseButton,
  Box,
  ModalFooter
} from "@chakra-ui/react";
import React, { useState } from "react";
import { createProject } from "RestAPI";
import { useLocation, useNavigate } from "react-router-dom";
import BBInput from "components/Input/Input";
import BBButton from "components/Button/Button";

const CreateCampaignSetForm = (props) => {
  const { project } = props
  const [campaignSetName, setCampaignSetName] = useState('')
  const [submit, setSubmit] = useState(false)
  const [campaignSets, setCampaignSets] = useState(props.project && props.project.campaignSets?.length ? props.project.campaignSets : [])
  const toast = useToast()
  const [loading, setLoading] = useState(false)

  const saveProject = () => {
    setSubmit(true)
    if (!campaignSetName) {
      return;
    }
    setLoading(true)
    if (campaignSetName) {
      campaignSets.push({ campaignSetName })
      setCampaignSets(campaignSets)
    }
    
    createProject({ id: project ? project.id : null, ...project, campaignSets })
      .then(response => {
        toast({
          title: `Campaignset created successfully`,
          description: "",
          status: 'success',
          position: 'top',
          duration: 3000,
          isClosable: true,
        })
        props.setProjectData(response)
        props.onClose()
        setLoading(false)
      }).catch(err => {
        console.log("error occured")
        toast({
          title: `An error occured when saving the project.`,
          description: "Please try after sometime or create an issue",
          status: 'error',
          position: 'top',
          duration: 3000,
          isClosable: true,
        })
        setLoading(false)
      })
  }
  return (
    <>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader color='brandbassBlue'>Create Campaign set<ModalCloseButton /></ModalHeader>
        <ModalBody pb={6}>
          <Box>
            <Flex flexDirection="column" pt={"10px"}>
              <Flex flexDirection="column" gap={2}>
                <FormControl isRequired>
                  <BBInput required={true} isInvalid={submit && !campaignSetName} label="CampaignSet Name" id='set-name' placeholder='Campaign set name' value={campaignSetName} onChange={(e) => setCampaignSetName(e.target.value)} />
                </FormControl>
              </Flex>
            </Flex>
          </Box>
        </ModalBody>
        <ModalFooter>
          <BBButton ml={4} mr={3}
            variant='outline'
            onClick={props.onClose}
          >
            Cancel
          </BBButton>
          <BBButton onClick={saveProject} isLoading={loading} loadingText="Saving...">
            Save
          </BBButton>
        </ModalFooter>
      </ModalContent>
    </>
  );
};

export default CreateCampaignSetForm;

