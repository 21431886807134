import { AddIcon } from "@chakra-ui/icons"
import {
    Badge,
    Box,
    Button,
    Checkbox,
    CheckboxGroup,
    Flex,
    FormControl,
    FormLabel,
    Icon,
    IconButton,
    Image,
    Img,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    NumberDecrementStepper,
    NumberIncrementStepper,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    Select,
    Slider,
    SliderFilledTrack,
    SliderMark,
    SliderThumb,
    SliderTrack,
    Spacer,
    Text,
    Textarea,
    Tooltip,
} from "@chakra-ui/react"
import { VideoCameraIcon } from "components/Icons/Icons"
import { AppContext } from "contexts/AppContext"
import { useContext, useEffect, useState } from "react"
import { FaArrowRight, FaPlus, FaVideo } from "react-icons/fa"
import { SubmitVideoModal } from '../../views/Dashboard/Campaign/SubmitVideo'
import { globalStyles } from '../../theme/styles'
import moment from "moment"

const GigDetailsCard = (props) => {
    const { placeholderImage, userData } = useContext(AppContext)
    const { gig, refreshCampaign, campaign } = props
    const [showGigModal, setShowGigModal] = useState(false)
    const [showSubmitVideoModal, setShowSubmitVideoModal] = useState(false)
    const backgroundVariants = [
        `linear-gradient(4.29deg, #FDEDE5 7.36%, #FEF5F0 53.39%)`,
        `linear-gradient(1.51deg, #EBF1FF 5.34%, #F2F5FE 72.41%)`,
        `linear-gradient(15.95deg, #F1F8FE 15.52%, #ECF6FF 70.25%)`,
        `linear-gradient(171deg, rgba(238,174,202,1) 0%, rgba(148,187,233,1) 100%)`
    ]
    const backgroundVariantIndex = Math.floor(Math.random() * backgroundVariants.length)
    let allowSubmission = campaign.campaignStatus === 'Active' ? true : false
    if(gig.gigVariation === 'Publish'){
        // let publishSubmission = campaign.submissions.filter(sub => sub.gig?.gigVariation === 'Publish')
        // if(publishSubmission?.length){
        //     allowSubmission = true
        // }else{
            allowSubmission = false
        // }
    }
    if (campaign.submissions?.length) {
        let gigSubmissions = campaign.submissions.filter(sub => (sub.submissionStatus === 'Submitted' || sub.submissionStatus === 'Accepted') && sub.gig?.id === gig.id)
        if (gigSubmissions?.length) {
            allowSubmission = false
        }
    }
    const cardColor = gig.gigVariation === 'Content' ? backgroundVariants[2] : backgroundVariants[0]
    return <>
        <Box position='relative' w='230px' h='264px' borderRadius='30px'
            // background={backgroundVariants[backgroundVariantIndex]}
            background={cardColor}
        >
            <Flex flexDirection='column' alignItems='start' pt='12px' gap='8px'>
                {userData.role === 'AMBASSADOR' && allowSubmission ? <IconButton position='absolute' right={-4} top={-2} onClick={(e) => { e.stopPropagation(); setShowSubmitVideoModal(true) }}><Tooltip label='Submit Video' fontSize='md'><VideoCameraIcon /></Tooltip></IconButton> : null}
                <Box position='relative' alignSelf='center'>
                    <Image src={gig.imageUrl} w='195px' h='150px' objectFit='cover' borderRadius="10px" />
                    {/* <a target='_blank' href={instagramAccount ? `https://www.instagram.com/${instagramAccount}` : '#'}>
                        <Image src={InstagramLinkIcon} position='absolute' bottom={0} right={0} />
                    </a> */}
                </Box>
                <Box textAlign='start' ml={4} w='85%' cursor='pointer' onClick={() => setShowGigModal(true)}>
                    <Text fontWeight='500' fontSize='16px' lineHeight='24px' isTruncated w='full'>
                        {gig.gigName}
                    </Text>
                    <Text fontWeight='400' fontSize='14px' lineHeight='24px' color='brandbassGray.500' isTruncated w='full'>
                        {gig.gigDescription}
                    </Text>
                    <Flex mt={2} w='full'>
                        <Text fontWeight='500' fontSize='12px' lineHeight='20px'>
                            {gig.gigVariation}
                        </Text>
                        <Spacer />
                        <Icon float='right' as={FaArrowRight} />
                    </Flex>
                </Box>
            </Flex>
        </Box>
        {/* <Box
            w='full'
            boxShadow="xl"
            p="6"
            rounded="md"
            bg="white"
            cursor="pointer"
            onClick={() => setShowGigModal(true)}
            background={backgroundVariants[backgroundVariantIndex]}
        >
            <Flex gap="20px">
                <Image
                    h={100}
                    w={100}
                    src={gig.image || gig.imageUrl}
                    fallbackSrc={placeholderImage(100)}
                    borderRadius="lg"
                    overflow='hidden'
                />
                <Flex justifyContent="space-evenly" flexDirection="column" w='full' overflow='hidden'>
                    <Text fontSize="2xl" color="brandbassBlue" isTruncated w='full'>
                        {gig.gigName}
                    </Text>
                    <Text fontSize="lg" color="gray.500" isTruncated w='full' >
                        {gig.gigDescription}
                    </Text>
                </Flex>
                <Spacer/>
                <Flex flexDirection='column'>
                {userData.role === 'AMBASSADOR' ? <IconButton mt={-4} onClick={(e) => {e.stopPropagation(); setShowSubmitVideoModal(true)} }><Tooltip label='Submit Video' fontSize='md'><AddIcon/></Tooltip></IconButton> :null}
                <Spacer/>
                <Badge>{gig.gigVariation}</Badge>
                </Flex>
            </Flex>
        </Box> */}
        <GigModal badgeColor={cardColor} visible={showGigModal} gig={gig} closeHandler={() => setShowGigModal(false)} />
        <SubmitVideoModal visible={showSubmitVideoModal} gig={gig} closeHandler={() => setShowSubmitVideoModal(false)} campaign={props.campaign} refreshCampaign={refreshCampaign} />
    </>
}

export default GigDetailsCard

export const GigModal = (props) => {
    const { placeholderImage } = useContext(AppContext)
    const { visible, closeHandler, gig, badgeColor } = props
    const { id, imageUrl, gigName, gigDescription, gigPrice, videoDuration, contentSettings, videoScenario, additionalDetails, gigRequirement, postContent, tags, publishChannel, publishTime, publishUrl } = gig

    const [showModal, setShowModal] = useState(false)

    useEffect(() => setShowModal(!!visible), [visible])

    return !gig ? null :
        <Modal isOpen={showModal} onClose={closeHandler} size="xl">
            <ModalOverlay />
            <ModalContent>
                <ModalHeader color='brandbassBlue'>Gig details</ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={6}>
                    <Flex
                        flexDirection='column'
                        borderColor='brandbassBlue.500'
                        borderWidth={1}
                        borderStyle='solid'
                        borderRadius='1rem'
                        boxShadow='5px 5px 0px 0px'
                        w='full'
                        h='auto'
                        p='1rem'
                        color='brandbassBlue.500'
                        gap='1rem'
                        overflow='scroll'
                    >
                        <Flex gap='1rem'>
                            <Image w={100} h={100} src={imageUrl} fallbackSrc={placeholderImage(100)} />
                            <Flex flexDirection='column' justify='space-between'>
                                <Flex flexDirection='column'>
                                    <Text fontWeight='600' fontSize='18px' textTransform='capitalize'>
                                        {gigName}
                                    </Text>
                                    <Text fontWeight='400' fontSize='14px' >
                                        {gigDescription}
                                    </Text>
                                </Flex>
                                <Badge borderColor='primary' borderWidth='1px' background={badgeColor} fontWeight='bold' p={1} textAlign='center' fontSize='sm'>{gig.gigVariation}</Badge>
                            </Flex>
                        </Flex>
                        {
                            gig.gigVariation?.toLowerCase() === 'content' && <>
                                <Flex fontSize='14px' p='1rem' justifyContent='space-between' borderRadius='0.5rem' borderColor='brandbassBlue.500' borderWidth={1} borderStyle='solid'>
                                    <Text fontWeight='400'> Video Scenario: </Text>
                                    <Text fontWeight='500'> {videoScenario} </Text>
                                </Flex>
                                <Flex fontSize='14px' p='1rem' justifyContent='space-between' borderRadius='0.5rem' borderColor='brandbassBlue.500' borderWidth={1} borderStyle='solid'>
                                    <Text fontWeight='400'> Content Settings: </Text>
                                    <Text fontWeight='500'> {`${contentSettings}, ${videoDuration}`} </Text>
                                </Flex>
                                <Flex flexDirection='column' >
                                    <Text fontWeight='500'> Additional Details: </Text>
                                    <Text p='1rem' fontWeight='400' borderRadius='0.5rem' borderColor='brandbassBlue.500' borderWidth={1} borderStyle='solid' > {additionalDetails} </Text>
                                </Flex>
                                <Flex flexDirection='column' >
                                    <Text fontWeight='500' > Requirements: </Text>
                                    <Text p='1rem' fontWeight='400' borderRadius='0.5rem' borderColor='brandbassBlue.500' borderWidth={1} borderStyle='solid' > {gigRequirement} </Text>
                                </Flex>
                                <Flex flexDirection='column' >
                                    <Text fontWeight='500' > Giveaway: </Text>
                                    <Text p='1rem' fontWeight='400' borderRadius='0.5rem' borderColor='brandbassBlue.500' borderWidth={1} borderStyle='solid' > {gig.productGiveawayValue ? `Product Giveaway - $${gig.productGiveawayValue}`: 
                                        `${gig.giveawayType} - ${gig.giveawayType === 'Coupon(%)' ? '' : '$'}${gig.giveawayValue}${gig.giveawayType === 'Coupon(%)' ? '%' : ''}`} </Text>
                                </Flex>
                            </>
                        }
                        {
                            gig.gigVariation?.toLowerCase() === 'publish' && <>
                                <Flex fontSize='14px' p='1rem' justifyContent='space-between' borderRadius='0.5rem' borderColor='brandbassBlue.500' borderWidth={1} borderStyle='solid'>
                                    <Text fontWeight='400'> Publish channel: </Text>
                                    <Text fontWeight='500'> {publishChannel} </Text>
                                </Flex>
                                <Flex fontSize='14px' p='1rem' justifyContent='space-between' borderRadius='0.5rem' borderColor='brandbassBlue.500' borderWidth={1} borderStyle='solid'>
                                    <Text fontWeight='400'> Publish time: </Text>
                                    <Text fontWeight='500'> {moment(publishTime).format('MMMM Do YYYY, h:mm a')} </Text>
                                </Flex>
                                <Flex flexDirection='column' >
                                    <Text fontWeight='500'> Post content: </Text>
                                    <Text p='1rem' fontWeight='400' borderRadius='0.5rem' borderColor='brandbassBlue.500' borderWidth={1} borderStyle='solid' > {postContent} </Text>
                                </Flex>
                                <Flex flexDirection='column' >
                                    <Text fontWeight='500'> Tags: </Text>
                                    <Text p='1rem' fontWeight='400' borderRadius='0.5rem' borderColor='brandbassBlue.500' borderWidth={1} borderStyle='solid' > {tags} </Text>
                                </Flex>
                            </>
                        }

                    </Flex>
                </ModalBody>
                <ModalFooter>

                </ModalFooter>
            </ModalContent>
        </Modal>
}