// Chakra imports
import {
  Avatar,
  Box,
  Flex,
  Image,
  IconButton,
  Text,
  Center,
  SkeletonCircle, SkeletonText,
  Spacer,
  useColorModeValue,
  Modal, Tag, TagLabel, ModalHeader, ModalOverlay, ModalBody, ModalContent, ModalCloseButton, CircularProgress, Button, ButtonGroup
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import React, { useState, useEffect, useContext, useCallback } from "react";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { AppContext } from "contexts/AppContext";
import { TickIcon } from "components/Icons/Icons";
import { ViewIcon } from "@chakra-ui/icons";
import ReactDataGrid from '@inovua/reactdatagrid-community'
import '@inovua/reactdatagrid-community/index.css'
import { getAllSubmissions, updateSubmission, getOneSubmission } from "RestAPI";
import { GigModal } from "components/Card/GigDetailsCard";
import moment from "moment";
import ShowUserForm from "../Users/components/ShowUser";
import { BeeIcon } from "components/Icons/Icons";
import { globalStyles } from "theme/styles";
import NoSubmissions from "assets/svg/emptyList/NoCampaigns.svg"
import "./Campaign.css"
import SelectFilter from '@inovua/reactdatagrid-community/SelectFilter'


const SubmissionList = (props) => {
  const { userData, placeholderImage } = useContext(AppContext)
  const navigate = useNavigate()
  const textColor = useColorModeValue("gray.700", "white");
  const goToCreateCampaign = () => navigate('/new/campaign', { replace: true })
  const [overView, setOverview] = useState(null)
  const [loading, setLoading] = useState(true)
  const [showGigModal, setShowGigModal] = useState(false)
  const [selectedGig, setSelectedGig] = useState(null)
  const [showAmbModal, setShowAmbModal] = useState(false)
  const [selectedAmb, setSelectedAmb] = useState(null)
  const [showIssueForm, setShowIssueForm] = useState(false)
  const [issueCampaign, setIssueCampaign] = useState(null)
  const [noSubmissions, setNoSubmissions] = useState(false)
  const [searchParams] = useSearchParams()
  const [status,setStatus] = useState(searchParams?.get('status')?searchParams.get('status'):'')
  const [showSubmissionModal, setShowSubmissionModal] = useState(false)
  const [SubmissionID, setSubmissionID] = useState(null)

  const defaultSortInfo = { id: "id", name: 'id', dir: -1, type: 'number' }
  const fetchSubmissions = ({ skip, limit, sortInfo, currentData, filterValue, groupBy }) => {
    const params = {}
    if (!isNaN(skip) && skip >= 0 && !!limit) {
      params.pagination = {
        perPage: limit,
        page: !skip ? 1 : (skip / limit) + 1
      }
    }
    if (!!sortInfo) {
      params.sort = {
        order: sortInfo.dir > 0 ? 'ASC' : 'DESC',
        field: sortInfo.id || 'id'
      }
    }
    if (!!filterValue && !!filterValue.length) {
      params.filter = {
        campaignName: filterValue[0].value,
        brandName: filterValue[1]?.value,
        ambassadorName: filterValue[2]?.value,
        status: !!filterValue[3]?.value ? filterValue[3].value : 'Submitted'
      }
    }
    if(status){
      params.filter = {
        status : ''
      }
    }
    if(props.filter){
      params.filter = props.filter
    }
    return getAllSubmissions(params)
      .then(response => {
        setLoading(false)
        if(!response) return ({})
        let data = response.data ? response.data : response
        if (data) {
          setOverview({
            allSubmissions: data,
            submittedSubmissions: data.filter(item => item.submissionStatus.toUpperCase() === 'SUBMITTED'),
            acceptedSubmissions: data.filter(item => item.submissionStatus.toUpperCase() === 'ACCEPTED'),
            revisedSubmissions: data.filter(item => item.submissionStatus.toUpperCase() === 'IN REVISION')
          })
        }
        return ({ data, count: response.total ? parseInt(response.total) : response.length || 0 })
      })
      .catch(err => {setLoading(false); console.error(err)})
  }

  const defaultFilterValue = [
    { name: 'campaign', type: 'string', operator: 'contains', value: '' },
    { name: 'brand', type: 'string', operator: 'contains', value: '' },
    { name: 'ambassador', type: 'string', operator: 'contains', value: '' },
    { name: 'submissionStatus', type: 'select', operator: 'eq', value: 'Submitted' },
  ];
  let columns = [
    {
      id: "id",
      name: "id",
      type: "number",
      header: <Text color="gray3" textTransform='uppercase'>ID</Text>,
      defaultVisible: true,
      defaultWidth: 100,
      sortable: true,
      render: ({ value }) => (
        <Text color="black3" fontWeight="600">
          {value}
        </Text>
      ),
    },
    {
      id: "campaign",
      name: "campaign",
      type: "string",
      header: <Text color="gray3">CAMPAIGN</Text>,
      defaultVisible: true,
      minWidth: 240,
      maxWidth: 240,
      sortable: true,
      // filterEditorProps: (props) => ({placeholder: 'place'}),
      filterEditorProps: {
        style: {
          background: '#fefefe',
        },
        placeholder: 'Search by name...'
      },
      render: ({ data }) => (
        <>
          <Flex
            align="center"
            py=".8rem"
            minWidth="100%"
            flexWrap="nowrap"
            gap="1rem"
          >
            <Image
              src={data.campaign?.imageUrl}
              objectFit="cover"
              minW="56px"
              maxW="56px"
              minH="56px"
              maxH="56px"
              borderRadius="lg"
              fallbackSrc={placeholderImage(40)}
            />
            <Flex flexDirection="column">
              <Text
                fontSize="md"
                color={globalStyles.colors.primary}
                fontWeight="500px"
                minWidth="100%"
                cursor="pointer"
                onClick={() => navigate(`/campaigns/${data.campaign.id}?displaySubmissions`)}
              >
                {data.campaign?.name}
              </Text>
              <Text
                fontSize="md"
                color="gray.500"
                fontWeight="normal"
                minWidth="100%"
              >
                {data.campaign?.notes}
              </Text>
            </Flex>
          </Flex>
        </>
      ),
    },
    {
      id: "gig",
      name: "gig",
      header: <Text color="gray3">GIG</Text>,
      defaultVisible: true,
      minWidth: 200,
      maxWidth: 240,
      sortable: false,
      render: ({ data }) =>
        <Flex alignItems="end"  >
          {!data.gig ? (
            "-"
          ) : (
            <Flex flexDir={'row'} gap={2}>
              <Avatar
                name={data.gig.gigName}
                title={data.gig.gigName}
                src={data.gig.imageUrl}
                _hover={{ cursor: "pointer" }}
                onClick={() => { setShowGigModal(true); setSelectedGig(data) }}
              >
              </Avatar>
              <Flex flexDir={'column'}>
                <Text fontWeight={'600'}>{data.gig.gigName}</Text>
                <Text fontSize={'xs'}>{data.gig.gigVariation}</Text>
              </Flex>
              {selectedGig?.id === data.id && selectedGig?.gig?.id === data.gig.id ? <GigModal visible={showGigModal} gig={selectedGig.gig} closeHandler={() => { setShowGigModal(false); setSelectedGig(null) }} /> :null}
            </Flex>
          )}
        </Flex>
    }]
    if(userData.role === 'AMBASSADOR' || userData.role === 'SUPER_ADMIN' || userData.role === 'CLIENT_SUPER_ADMIN'){
      columns.push({
      id: "brand",
      name: "brand",
      header: <Text color="gray3" textAlign='center'>Brand</Text>,
      defaultVisible: true,
      minWidth: 240,
      maxWidth: 260,
      sortable: true,
      filterEditorProps: {
        style: {
          background: '#fefefe',
        },
        placeholder: 'Search by brand name...'
      },
      render: ({ data }) => (
        <>
          <Flex
            align="center"
            py=".8rem"
            minWidth="100%"
            flexWrap="nowrap"
            gap="1rem"
          >
            <Image
              src={data.campaign?.brand?.logoUrl}
              objectFit="cover"
              minW="56px"
              maxW="56px"
              minH="56px"
              maxH="56px"
              borderRadius="lg"
              fallbackSrc={placeholderImage(40)}
            />
            <Flex flexDirection="column">
              <Text
                fontSize="md"
                color={globalStyles.colors.primary}
                fontWeight="500px"
                minWidth="100%"
                cursor="pointer"
              >
                {data.campaign?.brand?.brandName}
              </Text>
            </Flex>
          </Flex>
        </>
      ),
    })}
    if(userData.role !== 'AMBASSADOR'){
      columns.push({
      id: "ambassador",
      name: "ambassador",
      header: <Text color="gray3" textAlign='center'>AMBASSADOR</Text>,
      defaultVisible: true,
      maxWidth: 200,
      sortable: false,
      filterEditorProps: {
        style: {
          background: '#fefefe',
        },
        placeholder: 'Search by name...'
      },
      render: ({ data }) => (
        <>
          <Flex alignItems="start" >
            {!data.user ? (
              "-"
            ) : (
              <Flex gap={2}>
                <Avatar
                  name={data.user.firstName}
                  title={data.user.firstName}
                  src={data.user.profileImageUrl}
                  _hover={{cursor: "pointer" }}
                  onClick={() => { setSelectedAmb(data); setShowAmbModal(true); }}
                >                 
                </Avatar>
                <Text mt={2}>{data.user.firstName} {data.user.lastName}</Text>
                {selectedAmb?.id === data.id && selectedAmb?.user?.id == data.user.id ? <Modal size={'4xl'} isOpen={showAmbModal} onClose={() => setShowAmbModal(false)}>
                  <ShowUserForm onClose={() => { setShowAmbModal(false); setSelectedAmb(null)}} UserID={selectedAmb.user.id} />
                </Modal> : null}
              </Flex>
            )}
          </Flex>
        </>
      ),
    })}
    columns = columns.concat([
    {
      id: 'submissionStatus',
      name: "submissionStatus",
      header: <Text color="gray3">STATUS</Text>,
      defaultVisible: true,
      maxWidth: 160,
      sortable: true,
      filterEditor: SelectFilter,
      filterEditorProps: {
        style: {
          background: '#fefefe',
        },
        placeholder: 'Submitted',
        dataSource: [{id: 'Submitted', label: 'Submitted'},{id:'Accepted', label: 'Accepted'},{id:'In-Revision', label: 'In Revision'}]
      },
      render: ({ value }) => {
        let bgColor = globalStyles.colors.statusGreen
        if (value === 'Invited') {
          bgColor = globalStyles.colors.statusYellow
        } else if (value === 'Attn. Needed') {
          bgColor = globalStyles.colors.statusRed
        } else if (value === 'Closed') {
          bgColor = '#4DA6F6'
        } else if (value === 'Draft') {
          bgColor = globalStyles.colors.statusGray
        }
        return (
          < Tag textAlign={'center'} minW={28} backgroundColor={bgColor}><Spacer /><TagLabel textAlign={'center'}>{value}</TagLabel><Spacer /></Tag>
        )
      },
    },
    
   
    {
      id:"createdOn",
      name: "createdOn",
      header: <Text color="gray3">CREATED ON</Text>,
      defaultVisible: true,
      maxWidth: 150,
      sortable: true,
      render: ({ value }) =>
      (
        <Text color="black3" fontWeight="600" fontSize='md'>
          {moment(value).format('MM-DD-YYYY')}
        </Text>
      ),
    },
    {
      id:"updatedOn",
      name: "updatedOn",
      header: <Text color="gray3">UPDATED ON</Text>,
      defaultVisible: true,
      maxWidth: 150,
      sortable: true,
      render: ({ value }) =>
      (
        <Text color="black3" fontWeight="600" fontSize='md'>
          {moment(value).format('MM-DD-YYYY')}
        </Text>
      ),
    },
    {
      id: 'actions',      
      header: <Text color="gray3">ACTIONS</Text>,
      defaultVisible: true,
      defaultWidth: 150,
      sortable: false,
      render: ({ data }) => <Flex gap='0.25rem' >
        <Flex title="View" justifyContent="center" alignItems="center">
          <IconButton onClick={() => {setSubmissionID(data.id); setShowSubmissionModal(true)}}
            variant='outline' size="xs"
            colorScheme='teal' borderRadius={"50%"}
            aria-label='Help'
            icon={<ViewIcon />}
          />
        </Flex>
        {data.submissionStatus === 'Closed' ? null : <Flex title="Duplicate" justifyContent="center" alignItems="center">

          <IconButton onClick={() => createIssue(data.campaign)}
            variant='outline' size="xs"
            colorScheme='teal' borderRadius={"50%"}
            aria-label='Help'
            icon={<BeeIcon />}
          />
        </Flex>}
      </Flex>
    },
  ]);
  columns.forEach(item => item.style = { borderLeftColor: 'white', borderRightColor: 'white', borderTopColor: 'white', borderBottomColor: 'gray.200' })

  const openIssueOnCampaign = (campaign) => {
    setIssueCampaign(campaign)
  }

  const dataSource = useCallback(fetchSubmissions, [status])
  
  const createIssue = (campaign) => {
    navigate('/new/issue/', {state: {issueData: {campaign}} })
  }

  const handleClick = (e, status)=>{
    e.preventDefault()
    setStatus(status)
  }

  useEffect(() => {
    !!issueCampaign ? setShowIssueForm(true) : setShowIssueForm(false)
  }, [issueCampaign])
  return (
    <>
      {/* <IssueForm visible={showIssueForm} closeHandler={() => setShowIssueForm(false)} campaign={issueCampaign} /> */}

      <Card id="campaigns-list" mt='2px' p='16px' overflowX={{ sm: "scroll" }} boxShadow='0px 0px 20px 1px rgb(0 0 0 / 10%)' borderRadius='10px'>
        <CardHeader p='0px 0px 8px 0px'>
          <Flex flex={1} align="center" gap='2rem' w="100%" ml={2} mr={2}>
            <Flex direction='column' gap='2rem' w="100%">
              <Flex gap='1rem' w="100%" flexDirection='column'>
                <Text fontSize='30px' color={textColor} fontWeight='bold' pb='.5rem'>
                  Submissions
                </Text>
               
              </Flex>
              {/* <Flex gap='20px' p='10px' alignItems='center' borderRadius='10px' minWidth="100%">
                {
                  loading && (
                    <Flex align="center" minWidth="100%" flexWrap="nowrap">
                      <Box padding='6' boxShadow='lg' minWidth="100%">
                        <SkeletonText mt='4' noOfLines={4} spacing='4' />
                      </Box>
                    </Flex>
                  )
                }
                {!loading && !noSubmissions && !props.hideOverview && dataSource && dataSource.length > 0 && (<>
                  <CounterCard title={"All"} width={125} count={overView ? overView.allSubmissions.length : 0} clickHandler={(e) => handleClick(e)} selected={!status}/>
                  <CounterCard title={"Drafted"} width={125} count={overView ? overView.draftedSubmissions.length : 0} clickHandler={(e) => handleClick(e,'Drafted')} selected={status==='Drafted'}/>
                  <CounterCard title={"Submitted"} width={125} count={overView ? overView.submittedSubmissions.length : 0} clickHandler={(e) => handleClick(e,'Submitted')} selected={status==='Submitted'}/>
                  <CounterCard title={"Accepted"} width={125} count={overView ? overView.acceptedSubmissions.length : 0} clickHandler={(e) => handleClick(e,'Accepted')} selected={status==='Accepted'}/> 
                  <CounterCard title={"Revision"} width={125} count={overView ? overView.revisedSubmissions.length : 0} clickHandler={(e) => handleClick(e,'In Revision')} selected={status==='In Revision'}/>
                </>)}
              </Flex> */}

            </Flex>
          </Flex>
        </CardHeader>

        {
          loading && (
            <Flex align="center" minWidth="100%" flexWrap="nowrap">
              <Box padding='6' boxShadow='lg' minWidth="100%">
                <SkeletonCircle size='20' />
                <SkeletonText mt='4' noOfLines={4} spacing='4' />
              </Box>
            </Flex>
          )
        }
        {/* {!loading && ( */}
          <CardBody  >
            {!!noSubmissions && (
              <Flex align="center" minWidth="100%" flexWrap="nowrap">
              <Box padding='6' minWidth="100%" justifyContent={"center"}>
                <Center>
              <Image
                src={NoSubmissions}
                maxW="40%"
                borderRadius="lg"
                fallbackSrc={placeholderImage(40)}
                boxShadow='xs'
              /></Center>
              </Box>
            </Flex>
            )}
            {dataSource && dataSource.length > 0 && (<>
              <ReactDataGrid
                className="brandbass-data-grid"
                style={{ minHeight: 860, borderColor: 'white' }}
                idProperty="id"
                columns={columns}
                dataSource={dataSource}
                pagination={true}
                defaultLimit={10}
                defaultSortInfo={defaultSortInfo}
                defaultLoading={loading}
                checkboxColumn={checkboxColumn}
                checkboxOnlyRowSelect={true}
                rowHeight={70}
                headerHeight={60}
                showZebraRows={false}
                enableSelection={true}
                showHoverRows={true}
                showCellBorders="horizontal"
                enableColumnAutosize={true}
                defaultFilterValue={defaultFilterValue}
                enableColumnFilterContextMenu={false}
                renderColumnContextMenu={null}
                emptyText="No submissions"
              />
            </>)}

          </CardBody>
        {/* )} */}
      </Card>
      {showSubmissionModal && <SubmissionModal SubmissionID={SubmissionID} visible={showSubmissionModal} closeHandler={
        (refresh) => {
          setShowSubmissionModal(false); 
          setSubmissionID(null); 
          if(refresh) setStatus('All')
        }
      } />
      }
    </>
  );
};

export default SubmissionList;

export const checkboxColumn = {
  renderCheckbox: (checkboxProps) => {
    const { onChange, checked } = checkboxProps
    return (
      <Box
        className="data-grid-checkbox"
        cursor='pointer'
        background={!!checked ? 'primary' : 'white'}
        borderRadius='4px'
        height='20px'
        width='20px'
        display='flex'
        alignItems='center'
        justifyContent='center'
        borderColor={!checked ? 'gray3' : 'primary'}
        borderWidth='2px'
        borderStyle='solid'
        fontSize={12}
        color={!checked ? 'primary' : 'white'}
        onClick={e => {
          e.stopPropagation();
          onChange(!checked);
        }}
      >
        {checked === false ? '' : checked === true ? <TickIcon /> : '--'}
      </Box>
    );

  }
}

const SubmissionModal = (props) => {
  const {SubmissionID} = props;
  const [loading, setLoading] = useState(false)
  const [submission, setSubmission] = useState({})
  const {userData} = useContext(AppContext)
  const backgroundVariants = [
    `linear-gradient(4.29deg, #FDEDE5 7.36%, #FEF5F0 53.39%)`,
    `linear-gradient(1.51deg, #EBF1FF 5.34%, #F2F5FE 72.41%)`,
    `linear-gradient(15.95deg, #F1F8FE 15.52%, #ECF6FF 70.25%)`,
    `linear-gradient(171deg, rgba(238,174,202,1) 0%, rgba(148,187,233,1) 100%)`
  ]

  async function handleSubmissionAction(video, type){
    if(type === 'accept'){
      video.submissionStatus = 'Accepted';
      setLoading(true);
      const res = await updateSubmission(video);
      setLoading(false);
      if(res && res.data){
        props.closeHandler(true)
      }
    }else if(type == 'revision'){
      video.submissionStatus = 'In-Revision';
      setLoading(true);
      const res = await updateSubmission(video);
      setLoading(false);
      if(res && res.data){
        props.closeHandler(true)
      }
    }
  }

  async function handleDownload(submission){    
    const video = await fetch(submission.signedUrl, {
        method: 'GET',
        headers: {
          'Content-Type': submission.mimeType
        }
    });
    const videoblob = await video.blob();
    const videoUrl = URL.createObjectURL(videoblob);
    
    const anchor = document.createElement("a");
    anchor.href = videoUrl;
    let extn = submission.s3Key.split('.')
    if(extn.length && extn[1]){
        extn = extn[1]
    }
    anchor.download = `${submission.gig.gigName}_video.${extn}`;
    
    document.body.appendChild(anchor);
    anchor.click();
  }

  useEffect(async() => {
    if(SubmissionID){
      setLoading(true)
      const submission = await getOneSubmission(SubmissionID)
      if(submission?.id){
        setSubmission(submission)
      }
      setLoading(false)
    }
  },[SubmissionID])

  return <Modal isOpen={props.visible} onClose={props.closeHandler} size='4xl'>
    <ModalOverlay/>
    <ModalContent>
      <ModalHeader color='brandbassBlue'>Video</ModalHeader>
      <ModalCloseButton/>
      <ModalBody>
        {/* <Box position='relative' h='100%' w={'full'} paddingRight={'px'}> */}
          <Flex flexDirection="column" p={1} minH={100} className="video-container" gap={2} align='center'>
                              
            {submission?.signedUrl && <video
                controls
                className="video-card"
                // style={{ maxWidth: 480, maxHeight: 320, minWidth: 320, minHeight: 240, boxShadow: '0px 0px 5px 1px grey' }}
                // width="320"
                // height="320"
                preload="metadata"
            >
                <source src={submission?.signedUrl} type="video/mp4" />
                Your browser does not support the video tag.
            </video>}
                        
            <Flex flexDirection='column' gap='0.5rem' maxW={{ base: '100%', xl: '50%' }} >
                <Flex maxH='400px' overflow='scroll' flexDirection='column' gap='0.5rem' >
                    {submission.revision > 0 ? <Text fontWeight='500'> <em>Revision</em>: {submission.revision} </Text> : null }
                    {submission.gig?.publishChannel ? 
                    // !gig.publishUrl && userData.role === 'AMBASSADOR' ? <Input value={publishUrl} placeholder="Enter publish URL" onChange={(e) => setPublishUrl(e.target.value)}></Input> :
                      <Text>{submission.gig.publishChannel} {submission.gig.publishUrl || ''}</Text>
                    : null}
                    <span fontWeight='500'> <em>Status</em>:  <Text as='span' fontWeight='700'> {submission.submissionStatus} </Text> </span>
                </Flex>
                {['AMBASSADOR','SUPER_ADMIN'].includes(userData.role) ? null :
                  // submission.submissionStatus === 'Drafted' && gig.gigVariation === 'Publish' ? loading ? <CircularProgress value={80}/> : <Button colorScheme='blue' onClick={() => submitPublishGig(submission)}>Submit</Button> : null
                  // : 
                <ButtonGroup variant='outline' spacing='3'>
                    {submission.submissionStatus === 'Accepted' || submission.submissionStatus === 'Drafted'? null : loading ? <CircularProgress value={80}/> : <Button colorScheme='blue' onClick={() => handleSubmissionAction(submission,'accept')}>Approve</Button>}
                    {submission.submissionStatus === 'Accepted' || submission.submissionStatus === 'Drafted' ? null : loading ? <CircularProgress value={80}/> : <Button colorScheme='orange' onClick={() => handleSubmissionAction(submission,'revision')}>Revise</Button>}
                    {submission.submissionStatus === 'Accepted' ? loading ? <CircularProgress value={80}/> : <Button colorScheme='blue' onClick={() => handleDownload(submission)}>Download</Button> : null}                                                
                    {/* {submission.submissionStatus === 'Accepted' ? loading ? <CircularProgress value={80}/> : <a href={submission.signedUrl} download="video.mp4" >Download</a> : null} */}
                </ButtonGroup>
                }
            </Flex>
          </Flex>
        {/* </Box> */}
      </ModalBody>
    </ModalContent>
  </Modal>
}