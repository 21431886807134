import {
    Box,
    Text,
    Spinner,
    Flex,
    Spacer,
} from "@chakra-ui/react";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import { TickIconGreen } from "components/Icons/Icons";
import { useEffect, useState } from "react";
import { globalStyles } from "theme/styles";
const CounterCard = (props) => {
    const {  title, count, width, clickHandler, selected } = props
    return <Flex onClick={!!clickHandler ? clickHandler : () => {}} cursor='pointer'>
    <Card overflowX={{ sm: "scroll" }} minW={width} maxW={250} boxShadow='0px 0px 20px 1px rgb(0 0 0 / 10%)' borderRadius='10px' bgColor="lightBlue">
        <CardBody>
            <Box>
                <Flex>
                    <Text className="count-header">{title}</Text>
                    <Spacer/>
                    {selected && <TickIconGreen mt={'4px'}/>}
                    </Flex>
                <Text className="count-number">
                    { count}
                </Text>
            </Box>
        </CardBody>
    </Card>
    </Flex>
}

export default CounterCard