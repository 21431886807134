import React, { useState } from "react";
// Chakra imports
import {
  Box,
  Flex,
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Link,
  Switch,
  Text,
  useColorModeValue,
  Select,
  Image
} from "@chakra-ui/react";
import signInImage from "assets/svg/LoginBG.svg";
import BrandBassShortIcon from 'assets/svg/brandbass-short-logo.svg';
import BrandBassIcon from 'assets/svg/brandbass-logo.svg';
import { forcePasswordChange } from "RestAPI";
import { useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import BBInput from "components/Input/Input";
import BBButton from "components/Button/Button";

function ForceChangePassword(props) {
  // Chakra color mode
  const titleColor = useColorModeValue("brandbassBlue2.500", "white");
  const textColor = useColorModeValue("gray.400", "white");
  const [error, seterror] = useState('')
  const [userPassword, setUserPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const navigate = useNavigate();
  const location = useLocation();

  const resetPassword = async () => {
    if (userPassword !== confirmPassword) {
      seterror('Passwords do not match!')
      return
    }
    const res = await forcePasswordChange({ userName: location.state.userIdentifier, userPassword, oldPassword: location.state.oldPassword })
    if (res) {
      navigate("/auth")
    }
    seterror('')
  }

  return (
    <Flex position='relative'>
      <Flex
        h={{ sm: "initial", md: "75vh", lg: "85vh" }}
        w='100%'
        maxW='1044px'
        mx='auto'
        justifyContent='space-between'
        mb='30px'
        pt={{ sm: "100px", md: "0px" }}>
        <Flex
          alignItems='center'
          justifyContent='start'
          style={{ userSelect: "none" }}
          w={{ base: "100%", md: "50%", lg: "42%" }}>
          <Flex
            direction='column'
            w='100%'
            background='transparent'
            p='48px'
            mt={{ md: "150px", lg: "80px" }}>
            <Heading color={titleColor} fontSize='32px' mb='10px'>
              Welcome!
            </Heading>
            <Text
              mb='36px'
              ms='4px'
              color={textColor}
              fontWeight='bold'
              fontSize='14px'>
              Please setup new password
            </Text>
            <FormControl>
              <BBInput value={userPassword} mb='36px' label="Password" type='password'
                placeholder='Your password' onChange={e => setUserPassword(e.target.value)} />

              <BBInput mb='36px' label="Confirm Password" type='password' value={confirmPassword}
                placeholder='Confirm password' onChange={(e) => setConfirmPassword(e.target.value)} />

              {!!error && <Text as="p" mt="10px" color={'red'}> {error} </Text>}

              <BBButton type='submit'
                w='100%'
                h='45'
                mb='20px'
                mt='20px'
                onClick={resetPassword}
                >
                Change Password
              </BBButton>
            </FormControl>
          </Flex>
        </Flex>
        <Box
          display={{ base: "none", md: "block" }}
          overflowX='hidden'
          h='100%'
          w='50vw'
          mt={-70}
          position='absolute'
          right='0px'>
          <Flex
            flexDirection='column'
            justifyContent='center'
            alignItems='center'
            bgImage={signInImage}
            w='100%'
            h='100%'
            bgSize='cover'
            bgPosition='50%'
            position='absolute'
            borderBottomLeftRadius='20px'>
            <Image src={BrandBassShortIcon} w='67px' h='87px' />
              <Image src={BrandBassIcon} w='250px' />
          </Flex>
        </Box>
      </Flex>
    </Flex>
  );
}

export default ForceChangePassword;
