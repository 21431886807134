import { createContext, useEffect, useState } from "react";

export const AppContext = createContext()

export const AppProvider = props => {
    const [userData, setUserData] = useState(null)
    const [hasCreatePermission, setHasCreatePermission] = useState(false)
    const [hasEditPermission, setHasEditPermission] = useState(false)
    const [performOwnerCheck, setPerformOwnerCheck] = useState(false)
    const placeholderImage = (size) => !!size && !isNaN(size) ? `https://via.placeholder.com/${size}` : ''
    const value = {
        placeholderImage,
        userData,
        setUserData,
        hasCreatePermission,
        setHasCreatePermission,
        hasEditPermission,
        setHasEditPermission,
        performOwnerCheck,
        setPerformOwnerCheck
    }

    useEffect(() => {
        if(!userData) {
            setHasCreatePermission(false)
            setHasEditPermission(false)
            return
        }
        setHasCreatePermission(false)
        setHasEditPermission(false)
        if(['SUPER_ADMIN', 'BRAND_SUPER_ADMIN', 'BRAND_MANAGER'].includes(userData?.role?.toUpperCase())) {
            setHasCreatePermission(true)
        }

        if(['SUPER_ADMIN', 'CLIENT_SUPER_ADMIN', 'BRAND_SUPER_ADMIN', 'BRAND_MANAGER'].includes(userData?.role?.toUpperCase())) {
            setHasEditPermission(true)
        }
        return
    },[userData])
    
    return (
        <AppContext.Provider value={value}>
            {props.children}
        </AppContext.Provider>
    )
}
