import { Box, ChakraProvider, CloseButton, Flex, Spinner, Text } from "@chakra-ui/react";
import BBButton from "components/Button/Button";
import { AppContext } from "contexts/AppContext";
import { useCallback, useContext, useEffect, useState } from "react";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import { globalStyles } from "theme/styles";
import theme from "theme/theme";

const FormLayout = (props) => {
    const { userData } = useContext(AppContext)
    const location = useLocation()
    const navigate = useNavigate()
    const goHome = useCallback(() => navigate('/'))
    // const [onClose, setOnClose] = useState(() => goHome)
    // const [onSubmit, setOnSubmit] = useState(() => () => { })
    // const [onCancel, setOnCancel] = useState(() => goHome)
    // const [onDraft, setOnDraft] = useState(null)
    // const [caption, setCaption] = useState('')
    // const [disabledSubmit, setDisabledSubmit] = useState('')

    const initializeForm = ({ caption, submitHandler, closeHandler, cancelHandler, draftHandler, disabledSubmitHandler }) => {
        // setCaption(!!caption ? caption : '')
        // setOnSubmit(() => submitHandler)
        // setOnDraft(() => draftHandler)
        // setOnCancel(() => !!cancelHandler ? cancelHandler : goHome)
        // setOnClose(() => !!closeHandler ? closeHandler : goHome)
        // setDisabledSubmit(() => !!disabledSubmitHandler ? disabledSubmitHandler : false)
    }

    if (!userData || !userData.id) { return <Navigate to='/auth' state={{ fromPage: window.location.pathname + window.location.search }} /> }

    return <>
        <ChakraProvider theme={theme} resetCss={false} w='full'>
            <Box overflow='scroll' position='relative'>
                {/* <Flex justify='space-between' px='2rem' py='2rem' align='center' boxShadow='md' flexWrap='wrap' maxW='full' flexDirection={{ base: 'column', md: 'row' }}>
                    <Flex gap='1rem' align='center'>
                        <CloseButton onClick={ />
                        <Text color='gray3' fontFamily='Rubik' fontWeight='500' fontSize='xl'>{caption || ''}</Text>
                    </Flex>
                    <Flex justify='space-evenly' align='center' flex='0.25' gap='1rem'>
                        <BBButton _hover={{ bgColor: 'primary', color: 'white' }} minW='150px' fontSize='lg' variant='outline' onClick={onCancel}>Cancel</BBButton>
                        {
                            !!onDraft &&
                            <BBButton _hover={{ bgColor: 'primary', color: 'white' }} minW='150px' fontSize='lg' variant='outline' onClick={onDraft}>Draft</BBButton>
                        }
                        <BBButton _disabled={disabledSubmit} disabled={disabledSubmit} minW='150px' fontSize='lg' onClick={onSubmit}>Save</BBButton>
                    </Flex>
                </Flex> */}
                <Flex maxW='6xl' w='full' flexDirection='column' flex={1} id='form-container' margin='auto' marginTop='120px' paddingBottom={'300px'}>
                    <Outlet context={{ initializeForm }} />
                </Flex>
            </Box>
        </ChakraProvider>
    </>
}

export default FormLayout

export const FormHeader = (props) => {
    const goHome = useCallback(() => navigate('/'))
    const { caption, submitHandler, closeHandler = goHome, cancelHandler = goHome, draftHandler, disabledSubmitHandler, loading, hideSave } = props
    return <>
        <Flex position='absolute' bgColor='white' top={0} right={0} left={0} justify='space-between' px='2rem' py='2rem' align='center' boxShadow='md' flexWrap='wrap' maxW='full' flexDirection={{ base: 'column', md: 'row' }}>
            <Flex gap='1rem' align='center'>
                <CloseButton onClick={closeHandler} />
                <Text color='gray3' fontFamily='Rubik' fontWeight='500' fontSize='xl'>{caption || ''}</Text>
            </Flex>
            <Flex justify='space-evenly' align='center' flex='0.25' gap='1rem' flexDirection={{ base: 'column', md: 'row' }}>
                <BBButton _hover={{ bgColor: 'primary', color: 'white' }} minW='150px' fontSize='lg' variant='outline' onClick={cancelHandler}>Cancel</BBButton>
                {
                    !!draftHandler &&
                    <BBButton _hover={{ bgColor: 'primary', color: 'white' }} minW='150px' fontSize='lg' variant='outline' onClick={draftHandler} disabled={loading}>Draft</BBButton>
                }
                {hideSave ? null : loading ? <Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color={globalStyles.colors.primary} size='xl' /> : 
                    <BBButton _disabled={disabledSubmitHandler} disabled={disabledSubmitHandler} minW='150px' fontSize='lg' onClick={submitHandler}>Submit</BBButton>}
            </Flex>
        </Flex>
    </>
}