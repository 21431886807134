// Chakra imports
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  Input,
  Link,
  Switch,
  Text,
  useColorModeValue,
  FormHelperText,
  CircularProgress,
  Select,
  Image,
  Textarea,
  useToast,
  Spinner,
  Checkbox
} from "@chakra-ui/react";
// Assets
import BgSignUp from "assets/img/BgSignUp.png";
import React, { useState, useEffect, useContext } from "react";
import { FaApple, FaFacebook, FaGoogle } from "react-icons/fa";
import { NavLink, useParams } from "react-router-dom";
import { getBrandData, getCampaignsCategories, getFileUploadSignedUrl, saveBrand, createBrand, createAttachmentObject, getUserSubscriptions } from 'RestAPI';
import { Select as CRSelect } from "chakra-react-select";
import { AppContext } from "contexts/AppContext";
import { FormHeader } from "layouts/FormLayout";
import BBInput from "components/Input/Input";
import BBTextarea from "components/Textarea/Textarea";
import { useNavigate } from "react-router-dom"
import MatchProfileForm from "views/Dashboard/Campaign/MatchProfile";
import { saveBrandInterest, approveUser } from "RestAPI";
import BBButton from "components/Button/Button";
import { TrashIcon } from "components/Icons/Icons";
import FileAttachment from "./FileAttachment";

function BrandRegistration() {
  const toast = useToast()
  const { userData, setUserData } = useContext(AppContext)
  const titleColor = useColorModeValue("teal.300", "teal.200");
  const textColor = useColorModeValue("gray.700", "white");
  const bgColor = useColorModeValue("white", "gray.700");
  const bgIcons = useColorModeValue("teal.200", "rgba(255, 255, 255, 0.5)");

  const [brandName, setBrandName] = useState('');
  const [website, setWebsite] = useState('');
  const [summary, setSummary] = useState('');
  const [email, setEmail] = useState('');
  const [industries, setIndustries] = useState([]);
  const [errortext, setErrortext] = useState('');
  const [categoryOptions, setcategoryOptions] = useState([]);
  const [imageUrl, setImageUrl] = useState('')

  const [jobTitle, setJobTitle] = useState('');
  const [logo, setLogo] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [categoryArr, setCategoryArr] = useState([]);
  const [instagram, setInstagram] = useState('');
  const [facebook, setFacebook] = useState('');
  const [terms, setTerms] = useState(newBrand ? false : true);
  const [loading, setLoading] = useState(false)
  const newBrand = false;
  const [submit, setSubmit] = useState(false);
  const {BrandID} =  useParams()
  const brandId = BrandID ? BrandID : userData && userData.brand ? userData.brand.id : null
  const navigate = useNavigate();
  const [showMatchProfileForm, setShowMatchProfileForm] = useState(false)
  const [attachments, setAttachments] = useState([])
  const [subdomain, setSubdomain] = useState('')
  const [comission, setComission] = useState(40)
  const [showOnlyPreferredAmbassadors, setShowOnlyPreferredAmbassadors] = useState(false)
  const [disableAutomatch, setDisableAutomatch] = useState(false)

  useEffect(() => {
    if (brandId) {
      getBrandData(brandId)
        .then(async response => {
          setLoading(true);
          if (response.data) {
            try {
              const { data } = response || {};
              if (data) {
                setBrandName(data.brandName);
                setWebsite(data.website);
                setSummary(data.summary);
                setEmail(data.email);
                setLogo(data.logoUrl)
                setJobTitle(data.jobTitle);
                setInstagram(data.instagram);
                setFacebook(data.facebook);
                setImageUrl(data.logoUrl);
                setAttachments(data.attachments)
                setSubdomain(data.subdomain)
                setComission(data.brandbassComission)
                setShowOnlyPreferredAmbassadors(data.showOnlyPreferredAmbassadors)
                setDisableAutomatch(data.disableAutomatch)
                let catOptions = await getCampaignsCategories();
                if (catOptions.data) {
                  setcategoryOptions(catOptions.data.map(item => {
                    return { label: item.name, name:item.name , value: item.name, id: item.id }
                  }));

                  if (data.categories) {
                    let selectedCategories = [];
                    for (let cat of data.categories) {
                      let match = catOptions.data.filter(c => c.name === cat.name)
                      if (match.length) {
                        selectedCategories.push({  name:match[0].name,label: match[0].name, value: match[0].name, id: match[0].id })
                      }
                    }
                    setCategoryArr(selectedCategories)
                  }
                }
              }
              setLoading(false);
            } catch (error) {
              setLoading(false);
            }
          }
        })
        .catch(err => {
          setLoading(false);
          setErrortext(err);
        });
    }else{
      getCampaignsCategories()
      .then(response => {
        setLoading(false);
        if (response.data) {
          setcategoryOptions(response.data.map((item, index) => {
            return {label: item.name, value: item.name, id: item.id}
          }));
        }
      })
      .catch(err => {
        setLoading(false);
        setErrortext(err);
      });
    }
  }, []);

  const handleCampaignImage = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    setImageUrl(URL.createObjectURL(file) || '');
    const s3Obj = await getFileUploadSignedUrl({ mime: file.type }, "Logo");
    if (!s3Obj || !s3Obj.data || !s3Obj.data.key) {
      setImageUrl(null)
      return
    }
    setLogo(s3Obj.data.key);
    await fetch(s3Obj.data.url, {
      method: 'PUT',
      headers: { 'Content-Type': file.type },
      body: file,
    });
  };


  const updateMatchInterest = async ({interest}) => {
    delete interest.updatedOn;
    delete interest.isDeleted;
    delete interest.isUserApproved;
    delete interest.pc1;
    delete interest.pc2;

    const response = await saveBrandInterest(interest)
    if(response){
      toast({
        title: `Match profile saved`,
        description: "Successfully updated match profile",
        status: 'success',
        position: 'top',
        duration: 3000,
        isClosable: true,
      })
      if(!userData.isApproved){
        let response = await approveUser({id: userData.id})
        if(response && response.data){
          setUserData({...userData, isApproved: true})
        }
      }
    }
    navigate('/')
  }

  const handleSubmitPress = () => {
    setSubmit(true);
    if(
      !brandName ||
      !website ||
      !summary ||
      !email ||
      (!categories && !categories.length) || 
      !logo ||
      !jobTitle ||
      !instagram ||
      !facebook){
        return;
      }
    setLoading(true);

    brandId ?
      saveBrand(brandId, {
        brandName,
        website,
        summary,
        email,
        categories: categoryArr.map(item => { return { id: item.id } }) || [],
        industries,
        logoUrl: logo.includes('https') ? null : logo,
        jobTitle,
        instagram: instagram && instagram.trim(),
        facebook: facebook && facebook.trim(),
        attachments,
        brandbassComission: comission > 0 && comission < 100 ? comission : 40,
        subdomain,
        showOnlyPreferredAmbassadors,
        disableAutomatch
      })
        .then(async response => {
          setLoading(false);
            // await AsyncStorage.setItem('brand', JSON.stringify(response.data));
          if (response.data.matchProfileAvailable || userData.role === 'SUPER_ADMIN') {
            let subscriptions = await getUserSubscriptions()
            // TODO:: uncomment when subscriptions is ready
            // if(subscriptions && subscriptions.data && subscriptions.data.length){
              return navigate(userData.role === 'SUPER_ADMIN' ? '/brands' : '/',{replace: true})
            // }else{            
            //   return navigation.navigate('/new/subscription')
            // }
            // navigation.navigate('/');
          } else {
            setShowMatchProfileForm(true)
          }
        })
        .catch(err => {
          setLoading(false);
          setErrortext(err);
        })
      :
      createBrand({
        users: [{ id: userData.id }],
        brandName,
        website,
        summary,
        email,
        categories: categoryArr.map(item => { return { id: item.id } }) || [],
        industries,
        logoUrl: logo,
        jobTitle,
        instagram: instagram && instagram.trim(),
        facebook: facebook && facebook.trim(),
        attachments
      })
        .then(async response => {
          setLoading(false);
          if (response.data) {
            localStorage.setItem('brand', JSON.stringify(response.data));
            if (response.data.matchProfileAvailable || userData.role === 'SUPER_ADMIN') {
              navigation.navigate('/');
            } else {
              setShowMatchProfileForm(true)
            }
          }
        })
        .catch(err => {
          setLoading(false);
          setErrortext(err);
        })
  };
  return (
    <>
      <FormHeader
        caption='Brand Registration'
        submitHandler={handleSubmitPress}
        closeHandler={()=>navigate('/')}
        cancelHandler={()=>navigate('/')}
        loading={loading}
      />
      <Flex flexDirection="column" >
        <Box p={8} borderWidth="0px" borderRadius="lg" overflow="hidden" w='full' alignSelf='center'>
          <Flex flexDirection="column" gap={10}>
            <Flex justify="space-between" flexDirection={{base:'column',lg:'row'}}> 
              <Box w={{base:'100%',lg:"50%"}}>
                <FormControl isRequired mt={10}>
                  <BBInput
                    required={true}
                    isInvalid={submit && !brandName}
                    label="Brand name:"
                    placeholder='Brand name'
                    onChange={(e) => {
                      setSubmit(false);
                      setBrandName(e.target.value)
                    }
                    }
                    value={brandName || ''} />
                </FormControl>
                <FormControl isRequired mt={10}>
                  <BBInput
                    required={true}
                    isInvalid={submit && !website}
                    label="Website:"
                    placeholder='Website'
                    onChange={(e) => {
                      setSubmit(false);
                      setWebsite(e.target.value)
                    }
                    }
                    value={website || ''} />
                </FormControl>
              </Box>
              <Box position="relative" w={{base:'100%',lg:"30%"}} alignItems="center" mt={{base:'20px'}}>
                <Input
                  type="file"
                  onChange={handleCampaignImage}
                  bg="transparent"
                  position="absolute"
                  inset="0 0 0 0"
                  cursor="pointer"
                  opacity={0}
                  h="100%"
                />
                <Image
                  src={imageUrl}
                  objectFit='contain'
                  boxShadow='lg'
                  fallbackSrc="https://via.placeholder.com/250"
                  borderRadius="lg"
                  w={250}
                  h={250}
                  overflow='hidden'
                  {...(submit && !imageUrl && { border: '2px solid red' })}
                />
                {submit && !imageUrl && <Text color={'#EB5757'} variant="bb-status">Please upload Image</Text>}
              </Box>
            </Flex>
          </Flex>
          <FormControl isRequired mt={8}>
            <FormLabel lineHeight='22px' fontWeight={'bold'} fontSize='16px' padding='5px 2px'>Categories</FormLabel>
            <CRSelect
              id="categories"
              isMulti
              name="colors"
              options={categoryOptions}
              placeholder="Select one or more options"
              closeMenuOnSelect={false}
              size="lg"
              value={categoryArr}
              onChange={(selections) => { console.log("selections", selections); setCategoryArr(selections) }}
            />
          </FormControl>
          <FormControl isRequired mt={10}>
            <BBTextarea
              required={true}
              label={'Summary:'}
              value={summary || ''}
              id="summary"
              onChange={(e) =>{
                setSubmit(false);
                setSummary(e.target.value)
              }
              }
              isInvalid={submit && !summary}
              placeholder="Enter your summary here"
            />
          </FormControl>
          <Flex direction={{base:'column',lg:"row"}}>
            <FormControl isRequired mt={8} direction="column">
              <BBInput
                required={true}
                isInvalid={submit && !facebook}
                label="Facebook:"
                id="facebook"
                placeholder="Facebook"
                onChange={(e) =>{setSubmit(false);
                  setFacebook(e.target.value)
                }}
                value={facebook || ''} />
            </FormControl>
            <FormControl isRequired mt={8} ml={{lg:8}} direction="column" >
              <BBInput
                required={true}
                isInvalid={submit && !instagram}
                label="Instagram:"
                id="instagram"
                placeholder="Instagram"
                onChange={(e) =>{setSubmit(false);
                  setInstagram(e.target.value)
                }}
                value={instagram || ''} />
            </FormControl>
          </Flex>
          <Flex direction={{base:'column',lg:"row"}}>
            <FormControl isRequired mt={8} direction="column">
              <BBInput
                required={true}
                isInvalid={submit && !email}
                label="Contact Email:"
                id="email"
                placeholder="Contact Email"
                onChange={(e) =>{setSubmit(false);
                  setEmail(e.target.value)}
                }
                value={email || ''} />
            </FormControl>
            <FormControl isRequired mt={8} ml={{lg:8}} direction="column" >
              <BBInput
                required={true}
                isInvalid={submit && !jobTitle}
                label="Job Title:"
                id="jobTitle"
                placeholder="Job Title"
                onChange={(e) =>{setSubmit(false);
                  setJobTitle(e.target.value)
                }}
                value={jobTitle || ''} />
            </FormControl>
          </Flex>
          {userData.role === 'SUPER_ADMIN' ? 
          <>
            <Flex direction={{base:'column',lg:"row"}}>
              <FormControl isRequired mt={8} direction="column">
                <BBInput
                  label="Subdomain:"
                  id="subdomain"
                  placeholder="Subdomain"
                  onChange={(e) =>{
                    setSubdomain(e.target.value)}
                  }
                  value={subdomain || ''} />
              </FormControl>
              <FormControl isRequired mt={8} ml={{lg:8}} direction="column" >
                <BBInput
                  type='number'
                  label="Brandbass Comission:"
                  id="comission"
                  placeholder="Brandbass Comission"
                  onChange={(e) =>{
                    setComission(e.target.value)
                  }}
                  value={comission || ''} />
              </FormControl>              
            </Flex> 
            <Flex direction={{base:'column',lg:"row"}}>
              <FormControl isRequired mt={9} direction="column">
                <Text fontSize={16} fontWeight='bold'>Recommend only preferred ambassadors:</Text>
                <Checkbox spacing='1rem' mt={6} size='lg' isChecked={!!showOnlyPreferredAmbassadors} onChange={(e) => setShowOnlyPreferredAmbassadors(e.target.checked)}>
                  <Text>{showOnlyPreferredAmbassadors ? 'YES' : 'NO'}</Text>
                </Checkbox>
              </FormControl>
              <FormControl isRequired mt={9} ml={8} direction="column">
                <Text fontSize={16} fontWeight='bold'>Disable Auto match:</Text>
                <Checkbox spacing='1rem' mt={6} size='lg' isChecked={!!disableAutomatch} onChange={(e) => setDisableAutomatch(e.target.checked)}>
                  <Text>{disableAutomatch ? 'YES' : 'NO'}</Text>
                </Checkbox>
              </FormControl>
            </Flex>
          </> : null}
          <FileAttachment attachments={attachments} setAttachments={setAttachments} />
        </Box >
      </Flex >
      {showMatchProfileForm ? <MatchProfileForm
        visible={showMatchProfileForm}
        closeHandler={() => setShowMatchProfileForm(false)}
        dataHandler={(val) => updateMatchInterest({ interest: val })}
      /> : null}
    </>
  );
}

export default BrandRegistration;
