// Chakra imports
import {
  Button,
  Flex,
  Stack,
  Box,
  Text,
  Spacer,
  Divider,
  Avatar,
  HStack,
  useColorModeValue,
  Image,
  Switch,
  FormLabel,
  AvatarGroup,
  SimpleGrid,
  Link,
  Skeleton, SkeletonCircle, SkeletonText,
  useDisclosure,
  Hide
} from "@chakra-ui/react";
// Custom components
import { EmailIcon, ExternalLinkIcon } from "@chakra-ui/icons";
import { PersonIcon } from "components/Icons/Icons";
import { useParams } from "react-router-dom";
import moment from "moment";
import { AppContext } from "contexts/AppContext";
import React, { useState, useContext, useEffect } from "react";
import Chat from "components/Chat/Chat"
import SocialIcon from "components/Icons/SocialIcon"
import { useToast } from '@chakra-ui/react'
import brandImage from "assets/img/img.png";

import Facebook from "assets/icons/Facebook.png";
import Instagram from "assets/icons/Instagram.png";
import Twitter from "assets/icons/Twitter.png";
import Linkedin from "assets/icons/Linkedin.png";

import { getDashboardUser, getCampaign, allConversationsByUser, changeUserStatus, approveUser, activeAwayStatus, userEnableStatus } from "RestAPI"
const ViewUser = (props) => {
  const toast = useToast()
  const { placeholderImage } = useContext(AppContext)
  const { UserID } = useParams();
  const textColor = useColorModeValue("gray.700", "white");
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  const [role, setRole] = useState('Brand Admin')
  const [roleType, setRoleType] = useState('AMBASSADOR')
  const [address, setAddress] = useState({})
  const [campaign, setCampaign] = useState({})
  const [conversation, setConversation] = useState([])
  const [userChatData, setUserChatData] = useState(null)

  const backgroundVariants = [
    `linear-gradient(4.29deg, #FDEDE5 7.36%, #FEF5F0 53.39%)`,
    `linear-gradient(1.51deg, #EBF1FF 5.34%, #F2F5FE 72.41%)`,
    `linear-gradient(15.95deg, #F1F8FE 15.52%, #ECF6FF 70.25%)`
  ]
  const fetchCampaignDetails = async () => {
    const response = await getCampaign(399)
    setCampaign(response.data && response.data.id ? response.data : null)
  }
  const fetchConversations = async () => {
    const response = await allConversationsByUser(UserID)
    console.log(response)
    setConversation(response.data)
    loadUserConversation(response.data[0])
  }

  const loadUserConversation = async (selectedChat) => {
    if (userChatData?.moduleId && selectedChat.moduleId === userChatData.moduleId) {
      setUserChatData(null)
    } else {
      setUserChatData(selectedChat)
    }
}

  const activateUser = async () => {
    let data = {
      id: UserID
    }
    let userData = await changeUserStatus(data);
    if (userData && userData.statusCode < 300) {
      toast({
        title: 'User Updated',
        description: "User updated successfully",
        status: 'success',
        duration: 9000,
        isClosable: true,
      })
    } else {
      toast({
        title: 'Error',
        description: "Status code:" + userData.statusCode + ", " + userData.message,
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
    }
  }
  const approveResume = async () => {
    let data = {
      id: UserID
    }
    let userData = await approveUser(data);
    if (userData && userData.statusCode < 300) {
      toast({
        title: 'User Updated',
        description: "User updated successfully",
        status: 'success',
        duration: 9000,
        isClosable: true,
      })
    } else {
      toast({
        title: 'Error',
        description: "Status code:" + userData.statusCode + ", " + userData.message,
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
    }
  }
  const updateStatus = async () => {
    let data = {
      id: UserID
    }
    let userData = await activeAwayStatus(data);
    if (userData && userData.statusCode < 300) {
      toast({
        title: 'User Updated',
        description: "User updated successfully",
        status: 'success',
        duration: 9000,
        isClosable: true,
      })
    } else {
      toast({
        title: 'Error',
        description: "Status code:" + userData.statusCode + ", " + userData.message,
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
    }
  }
  const enableStatus = async () => {
    let data = {
      id: UserID
    }
    let userData = await userEnableStatus(data);
    if (userData && userData.statusCode < 300) {
      toast({
        title: 'User Updated',
        description: "User updated successfully",
        status: 'success',
        duration: 9000,
        isClosable: true,
      })
    } else {
      toast({
        title: 'Error',
        description: "Status code:" + userData.statusCode + ", " + userData.message,
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
    }
  }

  useEffect(() => {
    console.log(props)
    fetchCampaignDetails()
    fetchConversations()
    getDashboardUser(UserID).then(response => {
      setData(response.data)
      console.log(response.data)
      setRoleType(response.data.role && response.data.role.indexOf('BRAND') != -1 ? 'BRAND' : 'AMBASSADOR')
      setRole(response.data.role ? response.data.role.replaceAll('_', ' ') : response.data.role)
      setAddress(response.data.addresses && response.data.addresses.length > 0 ? response.data.addresses[0] : {})
      setLoading(false)
    })

  }, [])
  return (
    <Flex flexDirection='column' gap='40px' pt='40px' width={{base:'100%',xxl:'80%'}} margin={"auto"}>
       <Flex align="center" minWidth="100%" flexWrap="nowrap" flexDirection={{base:'column',lg:'row'}}>
        <Box>
          <Text fontStyle={"italic"} textAlign='left' align="left" fontWeight='500' fontSize='16px' >
            Hello! This is your profile. Here you can change the settings for your profile.
          </Text>
        </Box>
        <Spacer />
        <Box>
          <Stack align="right" spacing={4} direction={{base:'column',md:'row'}}>

            {data && !data.deletedOn && (
              <Button leftIcon={<PersonIcon />} bgColor='white' onClick={activateUser}>Inactivate</Button>
            )}
            {data && data.deletedOn && (
              <Button leftIcon={<PersonIcon />} bgColor='white' onClick={activateUser}>Activate</Button>
            )}
            {data && !data.isEnabled && (
              <Button leftIcon={<EmailIcon />} bgColor='white' onClick={enableStatus} isDisabled={data && data.deletedOn}>Enable</Button>
            )}
            {data && data.isEnabled && (
              <Button leftIcon={<EmailIcon />} bgColor='white' onClick={enableStatus} isDisabled={data && data.deletedOn}>Disable</Button>
            )}
            {data && !data.isApproved && (
              <Button leftIcon={<EmailIcon />} bgColor='white' onClick={approveResume} isDisabled={data && data.deletedOn}>Approve</Button>
            )}
            {data && data.isApproved && (
              <Button leftIcon={<EmailIcon />} bgColor='white' onClick={approveResume} isDisabled={data && data.deletedOn}>Reject</Button>
            )}
            {data && data.role === 'AMBASSADOR' && (
              <>
                {data && !data.isActive && (
                  <Button leftIcon={<EmailIcon />} bgColor='white' onClick={updateStatus} isDisabled={data && data.deletedOn}>Set Active</Button>
                )}
                {data && data.isActive && (
                  <Button leftIcon={<EmailIcon />} bgColor='white' onClick={updateStatus} isDisabled={data && data.deletedOn}>Set Away</Button>
                )}

              </>
            )}
          </Stack>
        </Box>
      </Flex>
    <HStack gap={'5px'} direction='column' marginBottom={{ base: '10px', lg: '0px' }} flexDirection={{ base: 'column', lg: 'row' }} width="100%">
      <Flex width={{ base: '100%', lg: "65%" }}>
        {loading && (
          <Flex align="center" minWidth="100%" flexWrap="nowrap">
            <Box padding='6' boxShadow='lg' bg='white' minWidth="100%">
              <SkeletonCircle size='10' />
              <SkeletonText mt='4' noOfLines={4} spacing='4' />
            </Box>
          </Flex>
        )}

        {!loading && (
          <Stack width={'100%'}>
            <Text textAlign='left' align="left" fontWeight='500' fontSize='16px' >
              Account overview
            </Text>
            <Box position='relative' borderRadius='15px' bgColor='blackAlpha.50' >
              <HStack direction='column' width={'100%'} p={4}>
                <Hide below='lg'>
                  <Box position='relative' w='100%' h='100%' minW="150px" minH="200px" maxW="200px" borderRadius='10px' bgColor='brandbassBlue2.200'>
                    <Flex flexDirection='column' alignItems='left'>
                      <Stack textAlign='left' p={5}>
                        <Text textAlign='left' align="left" fontWeight='500' fontSize='16px' >
                          Active<br />campaigns
                        </Text>
                        <Text align="center" fontWeight='700' fontSize='5xl' color='brandbassBlue2.500'>
                          {data.campaign?.activeCount || 0}
                        </Text>
                      </Stack>
                    </Flex>
                  </Box>
                </Hide>
                <Hide below='lg'>
                  <Box position='relative' w='100%' h='100%' minW="120px" minH="200px" maxW="200px" borderRadius='10px' bgColor='brandbassBlue2.200'>
                    <Flex flexDirection='column' alignItems='left' justifyContent={'space-evenly'}>
                      <Stack textAlign='left' p={5}>
                        <Text textAlign='left' align="left" fontWeight='500' fontSize='16px' >
                          Campaigns authored
                        </Text>
                        <Text align="center" fontWeight='700' fontSize='5xl' color='brandbassBlue2.500'>
                          {data.campaign?.totalCount || 0}
                        </Text>
                      </Stack>
                    </Flex>
                  </Box>
                </Hide>
                <Hide below='lg'>
                  <Stack direction='column'>
                    <Box position='relative' w='100%' h='100%' minW="120px" borderRadius='10px' bgColor='brandbassBlue2.200'>
                      <Flex flexDirection='column' alignItems='left'>
                        <HStack direction='column' p={4}>
                          <Text textAlign='left' align="left" fontWeight='500' fontSize='16px' >
                            Teams:
                          </Text>
                          <AvatarGroup size="sm" max={2}>
                            {campaign && campaign.gigs && campaign.gigs.map((user) => {
                              return (
                                <Avatar bg='white.500' borderColor='blue.500'
                                  name={`${user.gigName}`}
                                  key={user.id}
                                  src={user.imageUrl}
                                  _hover={{ zIndex: "3", cursor: "pointer" }}
                                />
                              );
                            })}
                          </AvatarGroup>
                        </HStack>
                      </Flex>
                    </Box>
                    <Box position='relative' w='100%' h='100%' minW="150px" borderRadius='10px' bgColor='brandbassBlue2.200'>
                      <Flex flexDirection='column' alignItems='left' gap='1rem'>
                        <HStack direction='column' p={4}>
                          <Text textAlign='left' align="left" fontWeight='500' fontSize='16px' >
                            Groups:
                          </Text>
                          <AvatarGroup size="sm" max={2}>

                            {conversation && conversation.map((user) => {
                              return (
                                <Avatar bg='white.500' borderColor='blue.500'
                                  name={`${user.moduleTitle}`}
                                  key={user.moduleId}
                                  src={user.moduleImageUrl}
                                  _hover={{ zIndex: "3", cursor: "pointer" }}
                                />
                              );
                            })}

                          </AvatarGroup>
                        </HStack>
                      </Flex>
                    </Box>
                  </Stack>
                </Hide>

                <Box position='relative' w='100%' h='100%' bgColor="transparent" >
                  <Image position={"relative"} width={"100%"} cursor={'pointer'} src={data.brand?.logoUrl ? data.brand.logoUrl : brandImage} height="200px" objectFit={"cover"} onClick={() => setShowUser(true)} />
                  <Text variant="image-label">{data.brand?.brandName}</Text>
                </Box>
              </HStack>
            </Box>
          </Stack>
        )}
        </Flex>
        <Flex id="brandCard" width={{ base: '100%', lg: '35%' }}>
        {loading && (
          <Flex align="center" minWidth="100%" flexWrap="nowrap">
            <Box padding='6' boxShadow='lg' bg='white' minWidth="100%">
              <SkeletonCircle size='10' />
              <SkeletonText mt='4' noOfLines={4} spacing='4' />
            </Box>
          </Flex>
        )}

        {!loading && (
          <Box width={"100%"} bgColor='brandbassBlue2.200' borderRadius='15px' p='25px'>
            <Flex alignItems='center' gap='1rem' height="200px">
              <Box>
                <Stack direction='column' position={"relative"} top={"40px"}>
                  <Text whiteSpace={{base:'normal',md:'nowrap'}} fontWeight='500' fontSize='16px' lineHeight='24px'>
                    {`${data.firstName} ${data.lastName}`}
                  </Text>
                  <Text whiteSpace={{base:'normal',md:'nowrap'}} fontWeight='400' fontSize='14px' lineHeight='24px' color='brandbassGray.500'>
                    {role}
                  </Text>
                </Stack>
              </Box>
              <Spacer />
              <Box>
              <Avatar src={data.profileImageUrl} size='2xl' mr="25px"/>
              </Box>
              {/* <HStack spacing={4} direction='row'>
                <Box position="relative" w={'150px'} h='200px'>
                  <Box position="absolute"
                    left={0}
                    bottom={10} >
                    <Stack direction='column' >
                      <Text fontWeight='500' fontSize='16px' lineHeight='24px'>
                        {`${data.firstName} ${data.lastName}`}
                      </Text>
                      <Text fontWeight='400' fontSize='14px' lineHeight='24px' color='brandbassGray.500'>
                        {role}
                      </Text>
                    </Stack>
                  </Box>
                </Box>
                <Box position='relative' textAlign="center">
                  <Avatar src={data.profileImageUrl} w='150px' h='150px' borderRadius="50%" me="18px" />
                </Box>
              </HStack> */}
            </Flex>
          </Box>
        )}
      </Flex>
    </HStack>
      <Flex align="center" minWidth="100%" flexWrap="nowrap">
        {loading && (
          <Flex align="center" minWidth="100%" flexWrap="nowrap">
          <Box padding='6' boxShadow='lg' bg='white' minWidth="100%">
            <SkeletonCircle size='10' />
            <SkeletonText mt='4' noOfLines={4} spacing='4' />
          </Box>
        </Flex>
        )}

        {!loading && (
          <HStack spacing={{base:2,lg:4}} py={2} width="100%" flexDirection={{base:'column',lg:'row'}} gap={'10px'}>
              <Flex id="brandCard"  w={{ base: 'full', lg: '40%' }} >
              <Box bgColor='white' w='full' borderRadius='10px' p='25px' boxShadow={"xl"}>
                <Flex flexDirection='column' alignItems='left' gap='1rem'>
                  <Box position='relative'>
                    <Text py={2} fontWeight='500' fontSize='20px' lineHeight='24px'>
                      Basic Information
                    </Text>
                  </Box>
                  <Box textAlign='left'>
                    <SimpleGrid w="100%" columns={{base:1, lg:2}} spacing={10}>
                      <Stack direction='column'>
                        <Text fontWeight='500' fontSize='18px' lineHeight='24px'>
                          Name
                        </Text>
                        <Text fontSize='14px' lineHeight='24px' color='brandbassGray.500'>
                          {data.firstName ? data.firstName : 'NA'}
                        </Text>
                      </Stack>
                      <Stack direction='column'>
                        <Text fontWeight='500' fontSize='18px' lineHeight='24px'>
                          Last Name
                        </Text>
                        <Text fontSize='14px' lineHeight='24px' color='brandbassGray.500'>
                          {data.lastName ? data.lastName : 'NA'}
                        </Text>
                      </Stack>
                      <Stack direction='column'>
                        <Text fontWeight='500' fontSize='18px' lineHeight='24px'>
                          Date of Birth
                        </Text>
                        <Text fontSize='14px' lineHeight='24px' color='brandbassGray.500'>
                          {data.dob ? data.dob : 'NA'}
                        </Text>
                      </Stack>
                      <Stack direction='column'>
                        <Text fontWeight='500' fontSize='18px' lineHeight='24px'>
                          Zipcode
                        </Text>
                        <Text fontSize='14px' lineHeight='24px' color='brandbassGray.500'>
                          {address.zipCode ? address.zipCode : 'NA'}
                        </Text>
                      </Stack>
                      <Stack direction='column'>
                        <Text fontWeight='500' fontSize='18px' lineHeight='24px'>
                          Business Email
                        </Text>
                        <Text fontSize='14px' lineHeight='24px' color='brandbassGray.500'>
                          {data.email ? data.email : 'NA'}
                        </Text>
                      </Stack>
                      <Stack direction='column'>
                        <Text fontWeight='500' fontSize='18px' lineHeight='24px'>
                          Phone Number
                        </Text>
                        <Text fontSize='14px' lineHeight='24px' color='brandbassGray.500'>
                          {data.mobileNumber ? data.mobileNumber : 'NA'}
                        </Text>
                      </Stack>
                      <Stack direction='column'>
                        <Text fontWeight='500' fontSize='18px' lineHeight='24px'>
                          Address
                        </Text>
                        <Text fontSize='14px' lineHeight='24px' color='brandbassGray.500'>
                          {address.lineOne ? address.lineOne : 'NA'}
                        </Text>
                        <Text fontSize='14px' lineHeight='24px' color='brandbassGray.500'>
                          {address.lineTwo}
                        </Text>
                        <Text fontSize='14px' lineHeight='24px' color='brandbassGray.500'>
                          {address.city}
                        </Text>
                        <Text fontSize='14px' lineHeight='24px' color='brandbassGray.500'>
                          {address.state} {address.country}
                        </Text>
                      </Stack>
                      <Stack direction='column'>
                        <Text fontWeight='500' fontSize='18px' lineHeight='24px'>
                          Social Media
                        </Text>
                        <HStack spacing={3} py={2}>
                          <Link href={data.instagramAccount} isExternal>
                            <Avatar size='sm' name='Instagram' src={Instagram} bg='blue.100' p={1} />
                          </Link>
                          <Link href={data.facebookAccount} isExternal>
                            <Avatar size='sm' name='Instagram' src={Facebook} bg='blue.100' p={1} />
                          </Link>
                          <Link href={data.youtubeUrl} isExternal>
                            <Avatar size='sm' name='Twitter' src={Twitter} bg='blue.100' p={1} />
                          </Link>
                          <Link href={data.youtubeUrl} isExternal>
                            <Avatar size='sm' name='Linkedin' src={Linkedin} bg='blue.100' p={1} />
                          </Link>
                        </HStack>

                      </Stack>
                    </SimpleGrid>
                  </Box>
                </Flex>
              </Box>
            </Flex>
            {roleType == "AMBASSADOR" && (
               <Stack direction='column' width={{base:'100%', lg:'60%'}}>
               <HStack spacing={{base:0, md:4}} gap={'10px'} flexDirection={{base:'column',lg:'row'}}>
                 <Flex id="brandCard" minH="330px" width={"100%"}>
                   <Box minH='330px' w='100%' bgColor='white' borderRadius='15px' p='25px' boxShadow={"xl"}>
                     <Flex flexDirection='column' alignItems='left' gap='1rem'>
                       <Box position='relative'>
                         <Text py={2} fontWeight='500' fontSize='18px' lineHeight='24px'>
                           Platform Settings
                         </Text>
                         <Text py={2} fontSize='14px' lineHeight='24px' color='brandbassGray.500'>
                           ACCOUNT
                         </Text>
                       </Box>
                       <Box textAlign='left'>
                         <HStack spacing={4} py={2}>
                           <Switch size='lg' id='messages' isChecked />
                           <FormLabel color='brandbassGray.500' htmlFor='isChecked'>Email me when I have messages pending</FormLabel>
                         </HStack>
                         <HStack spacing={4} py={2}>
                           <Switch size='lg' id='gigs' />
                           <FormLabel color='brandbassGray.500' htmlFor='isChecked'>Email me when I have new gigs submissions</FormLabel>
                         </HStack>
                         <HStack spacing={4} py={2}>
                           <Switch size='lg' id='updates' isChecked />
                           <FormLabel color='brandbassGray.500' htmlFor='isChecked'>Email me for Brandbass updates</FormLabel>
                         </HStack>
                       </Box>
                     </Flex>
                   </Box>
                 </Flex>
                 <Flex id="matchProfile" w='100%'>
                   <Box minH="330px"  width={"100%"} borderRadius='15px' position='relative' bgImage='https://i.imgur.com/nwjxMC4.png' bgRepeat="no-repeat" bgSize="cover">
                     <Button onClick={() => setShowMatchProfileForm(true)} position='absolute' bottom={0} w='full' bgColor='brandbassPink.500' variant="solid" >Do your match profile!</Button>
                   </Box>
                 </Flex>
               </HStack>
               <Text textAlign='left' align="left" fontWeight='500' fontSize='16px'>
                 Read more:
               </Text>
               <HStack>
                 <Flex gap={'10px'} w={{ base: 'full', lg: '100%' }} flexDirection={{base:'column',lg:'row'}}>
                   <BBButton height="55px" borderRadius={"10px"} onClick={() => window.open('https://www.brandbass.ai/blogs.html')} bgColor='brandbassBlue2.200' color="black" w="100%" fontWeight='600' fontSize='18px'>
                     Brandbass Blog
                   </BBButton>
                   <BBButton height="55px" borderRadius={"10px"} onClick={() => window.open('https://www.brandbass.ai')} bgColor='brandbassBlue2.200' color="black" w="100%" fontWeight='600' fontSize='18px'>
                     Brandbass Website
                   </BBButton>
                 </Flex>
               </HStack>
             </Stack>
            )}
            {roleType == "BRAND" && (
              <Stack direction='column' w={{ base: 'full', lg: '60%'}}>
              <HStack spacing={{base:0, md:4}}  flexDirection={{base:'column',lg:'row'}}>
              <Flex id="brandCard" w={'100%'}>
                <Box bgColor='white' w='100%' borderRadius='15px' p={{base:'0',lg:'25px'}}>
                  <Flex flexDirection='column' alignItems='left' gap='1rem'>
                    <Box textAlign='left'>
                      <HStack gap={{base:'15px',lg:'10px'}} py={2} flexDirection={{base:'column',lg:'row'}}>
                        <Flex mt='10px' py='10px' h={{base:'400px',md:'450px',lg:'450px'}} w={'100%'} overflowY='scroll' flexDirection='column' gap={'10px'}>
                          {
                            conversation.map((item, idx) => {
                              return <Flex  cursor={'pointer'} bgColor={!!userChatData && userChatData.moduleId === item.moduleId ? 'lightBlue' : ''} flexDirection='column' minW='100%' alignItems='flex-start' bgColor='brandbassBlue2.200'  
                              onClick={() => loadUserConversation(item)}>
                                <Flex p='10px' borderRadius='10px' minW='100%' >
                                  <HStack spacing={2}>
                                    <Avatar src={item.moduleImageUrl} w='50px' h='50px' borderRadius="12px" me="18px" />
                                    <Text fontFamily='Rubik' fontSize='16px' textAlign={['left', 'center']} lineHeight='24px'>{item.moduleTitle}</Text>
                                  </HStack>
                                  <Spacer />
                                  <Button align="right" variant='ghost'>VIEW</Button>
                                </Flex>
                              </Flex>
                            })
                          }
                        </Flex>
                        <Flex id='chat' w={{xxl:'100%',base:'100%',md:'50%'}} flex={{ base: 1, lg: 0.5 }} mt='15px'>
                          {
                            userChatData && userChatData.moduleId &&
                            <Chat
                              moduleId={userChatData.moduleId}
                              module={'campaign'}
                              participants={[userChatData.receiverId, userChatData.senderId]}
                              width={500}
                              height={450}
                              disableChat={true}
                            />
                          }
                        </Flex>
                        {/* <Chat CampaignID={campaign.id} messages={campaign.messages} width={500} height={450} /> */}
                      </HStack>
                    </Box>
                  </Flex>
                </Box>
              </Flex>
              </HStack>
            </Stack>
            )}
          </HStack>
        )}
      </Flex>
    </Flex>
  );
};

export default ViewUser;
