// Chakra imports
import {
  useToast,
  Box,
  Flex,
  SkeletonCircle,
  SkeletonText,
  Text,
  Grid,
  GridItem,
  Radio,
  FormControl,
  FormLabel,
  Switch,
} from "@chakra-ui/react";
import BBInput from "components/Input/Input";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getSetting, updateSetting } from "RestAPI";
import BBButton from "components/Button/Button";

const Settings = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [settings, setSettings] = useState({});
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const [submit, setSubmit] = useState(false);
  const [response, setResponse] = useState({});
  const [isSaving,setSaving]=useState(false);

  const saveSettings = () => {
    response.data.settings = settings;
    setSaving(true);
    updateSetting(response)
      .then((response) => {
        toast({
          title: `Successfully saved`,
          description: "",
          status: "success",
          position: "top",
          duration: 3000,
          isClosable: true,
        });
        setSaving(false);
        setResponse(response);
        setSettings(response.data.settings);
      })
      .catch((err) => {
        toast({
          title: `An error occured when saving the settings.`,
          description: "Please try after sometime or create an issue",
          status: "error",
          position: "top",
          duration: 3000,
          isClosable: true,
        });
        setSaving(false);
      });
  };

  const updateSettingsData = (e,isBoolean=false) => {
    let name = e.target.name.split("-");
    settings[name[0]].value[name[1]] = isBoolean ? e.target.checked : e.target.value
    setSettings({ ...settings })
    console.log("settings", settings)
  };

  useEffect(() => {
    setLoading(true);
    getSetting({ role: "SUPER_ADMIN" }).then((response) => {
      if (response && response.data && response.data.settings) {
        console.log("response: data", response);
        setResponse(response);
        setSettings(response.data.settings);
        setLoading(false);
      }
    });
  }, []);

  return (
    <>
      {loading && (
        <Flex align="center" minWidth="100%" flexWrap="nowrap">
          <Box padding="6" boxShadow="lg" minWidth="100%">
            <SkeletonCircle size="20" />
            <SkeletonText mt="4" noOfLines={4} spacing="4" />
          </Box>
        </Flex>
      )}
      {!loading && (
        <Flex flexDirection="column">
          <Box
            p={8}
            borderWidth="0px"
            borderRadius="lg"
            w="full"
            alignSelf="center"
          >
            <Flex flexDirection="column" gap={10}>
              <Flex gap={3} direction={"column"}>
                <Text fontSize="18px" fontWeight={"bold"}>
                  Invitation Notification Settings
                </Text>
                <Grid
                  templateColumns={{
                    base: "repeat(1, 1fr)",
                    lg: "repeat(5, 1fr)",
                  }}
                  gap={2}
                >
                  <GridItem>
                    <BBInput
                      name="InviteNotificationIterations-timeTo"
                      type="number"
                      min={0}
                      labelFontWeight="normal"
                      required
                      label="Time To"
                      placeholder="Time To"
                      value={
                        settings.InviteNotificationIterations &&
                        settings.InviteNotificationIterations.value.timeTo
                      }
                      onChange={(e) => {
                        updateSettingsData(e);
                      }}
                    />
                  </GridItem>
                  <GridItem>
                    <BBInput
                      name="InviteNotificationIterations-timeFrom"
                      type="number"
                      min={0}
                      labelFontWeight="normal"
                      required
                      label="Time From"
                      placeholder="Time From"
                      value={
                        settings.InviteNotificationIterations &&
                        settings.InviteNotificationIterations.value.timeFrom
                      }
                      onChange={(e) => {
                        updateSettingsData(e);
                      }}
                    />
                  </GridItem>
                  <GridItem>
                    <BBInput
                      name="InviteNotificationIterations-timeInterval"
                      type="number"
                      min={0}
                      labelFontWeight="normal"
                      required
                      label="Time Interval"
                      placeholder="Time Interval"
                      value={
                        settings.InviteNotificationIterations &&
                        settings.InviteNotificationIterations.value.timeInterval
                      }
                      onChange={(e) => {
                        updateSettingsData(e);
                      }}
                    />
                  </GridItem>
                  <GridItem>
                    {" "}
                    <BBInput
                      type="number"
                      min={0}
                      labelFontWeight="normal"
                      required
                      label="Time Gap"
                      placeholder="Time Gap"
                      value={
                        settings.InviteNotificationIterations &&
                        settings.InviteNotificationIterations.value
                          .timeGapBetweenIterations
                      }
                      name="InviteNotificationIterations-timeGapBetweenIterations"
                      onChange={(e) => {
                        updateSettingsData(e);
                      }}
                    />{" "}
                  </GridItem>
                  <GridItem>
                    {" "}
                    <BBInput
                      type="number"
                      min={0}
                      labelFontWeight="normal"
                      required
                      label="Max Attempt"
                      placeholder="Max Attempt"
                      value={
                        settings.InviteNotificationIterations &&
                        settings.InviteNotificationIterations.value.maxAttempts
                      }
                      name="InviteNotificationIterations-maxAttempts"
                      onChange={(e) => {
                        updateSettingsData(e);
                      }}
                    />{" "}
                  </GridItem>
                </Grid>
              </Flex>
              <Flex gap={3} direction={"column"}>
                <Text fontSize="18px" fontWeight={"bold"}>
                  Reminder Settings
                </Text>
                <Grid
                  templateColumns={{
                    base: "repeat(1, 1fr)",
                    lg: "repeat(5, 1fr)",
                  }}
                  gap={2}
                >
                  <GridItem>
                    <BBInput
                      type="number"
                      min={0}
                      labelFontWeight="normal"
                      value={
                        settings.SubmissionReminder &&
                        settings.SubmissionReminder.value.maxDays
                      }
                      label="Submission Max Day"
                      placeholder="Submission Max Day"
                      name="SubmissionReminder-maxDays"
                      onChange={(e) => {
                        updateSettingsData(e);
                      }}
                    />
                  </GridItem>
                  <GridItem>
                    <BBInput
                      type="number"
                      min={0}
                      labelFontWeight="normal"
                      value={
                        settings.RevisionReminder &&
                        settings.RevisionReminder.value.maxDays
                      }
                      required
                      label="Revision Max Day"
                      placeholder="Revision Max Day"
                      name="RevisionReminder-maxDays"
                      onChange={(e) => {
                        updateSettingsData(e);
                      }}
                    />
                  </GridItem>
                  <GridItem>
                    <BBInput
                      type="number"
                      min={0}
                      labelFontWeight="normal"
                      value={
                        settings.ShippingReminderSettings &&
                        settings.ShippingReminderSettings.value.delay
                      }
                      required
                      label="Shipping Delay"
                      placeholder="Shipping Delay"
                      name="ShippingReminderSettings-delay"
                      onChange={(e) => {
                        updateSettingsData(e);
                      }}
                    />
                  </GridItem>
                  <GridItem>
                    <BBInput
                      type="number"
                      min={0}
                      labelFontWeight="normal"
                      value={
                        settings.MatchNotificationSettings &&
                        settings.MatchNotificationSettings.value.delay
                      }
                      required
                      label="Match Delay"
                      placeholder="Match Delay"
                      name="MatchNotificationSettings-delay"
                      onChange={(e) => {
                        updateSettingsData(e);
                      }}
                    />
                  </GridItem>
                </Grid>
              </Flex>
              <Flex gap={3} direction={"column"}>
                <Text fontSize="18px" fontWeight={"bold"}>
                  Ambassador Settings
                </Text>
                <Grid
                  templateColumns={{
                    base: "repeat(1, 1fr)",
                    lg: "repeat(5, 1fr)",
                  }}
                  gap={2}
                  flexDirection={{ base: "column", lg: "row" }}
                >
                  <GridItem>
                    <BBInput
                      type="number"
                      min={0}
                      labelFontWeight="normal"
                      value={
                        settings.AmbassadorFee &&
                        settings.AmbassadorFee.value.defaultGigFee
                      }
                      required
                      label="Default Gig Fee"
                      placeholder="Default Gig Fee"
                      name="AmbassadorFee-defaultGigFee"
                      onChange={(e) => {
                        updateSettingsData(e);
                      }}
                    />
                  </GridItem>
                  <GridItem>
                    {" "}
                    <BBInput
                      type="number"
                      min={0}
                      labelFontWeight="normal"
                      label="Campaign Max Invite"
                      placeholder="Campaign Max Invite"
                      value={
                        settings.CampaignMaxInviteLimit &&
                        settings.CampaignMaxInviteLimit.value.maxInviteLimit
                      }
                      name="CampaignMaxInviteLimit-maxInviteLimit"
                      onChange={(e) => {
                        updateSettingsData(e);
                      }}
                    />
                  </GridItem>
                  <GridItem>
                    <BBInput
                      type="number"
                      min={0}
                      labelFontWeight="normal"
                      required
                      label="Campaign Limit"
                      placeholder="Campaign Limit"
                      value={
                        settings.AmbassadorCampaignLimit &&
                        settings.AmbassadorCampaignLimit.value.maxCampaignLimit
                      }
                      name="AmbassadorCampaignLimit-maxCampaignLimit"
                      onChange={(e) => {
                        updateSettingsData(e);
                      }}
                    />
                  </GridItem>
                  <GridItem>
                    <BBInput
                      type="number"
                      min={0}
                      labelFontWeight="normal"
                      required
                      label="Campaign Distance"
                      placeholder="Campaign Distance"
                      value={
                        settings.CampaignDistanceLimit &&
                        settings.CampaignDistanceLimit.value.distanceLimit
                      }
                      name="CampaignDistanceLimit-distanceLimit"
                      onChange={(e) => {
                        updateSettingsData(e);
                      }}
                    />
                  </GridItem>
                  <GridItem
                    paddingTop="37px"
                    justifyItems="start"
                    alignItems="center"
                    display="inline-grid"
                  >
                    <FormControl display="flex" alignItems="center">
                      <Switch
                        colorScheme="brandbassBlue2"
                        value={
                          settings.AmbassadorProfileSettings &&
                          settings.AmbassadorProfileSettings.value
                            .AdminApprovalRequired
                        }
                        name="AmbassadorProfileSettings-AdminApprovalRequired"
                        onChange={(e) => {
                          updateSettingsData(e,true);
                        }}
                        id="approval-required"
                      />
                      <FormLabel ml="5px" mb="0">
                        Approval Required?
                      </FormLabel>
                    </FormControl>
                  </GridItem>
                </Grid>
              </Flex>
              <Flex gap={3} direction={"column"}>
                <Grid
                  templateColumns={{
                    base: "repeat(1, 1fr)",
                    lg: "repeat(5, 1fr)",
                  }}
                  gap={2}
                >
                  <GridItem>
                    <Text fontSize="18px" fontWeight={"bold"}>
                      Brand Settings
                    </Text>
                  </GridItem>
                </Grid>

                <Grid
                  templateColumns={{
                    base: "repeat(1, 1fr)",
                    lg: "repeat(5, 1fr)",
                  }}
                  gap={2}
                  flexDirection={{ base: "column", lg: "row" }}
                >
                  <GridItem>
                    <BBInput
                      type="number"
                      value={
                        settings.GigPrice &&
                        settings.GigPrice.value.defaultGigPrice
                      }
                      name="GigPrice-defaultGigPrice"
                      min={0}
                      labelFontWeight="normal"
                      required
                      label="Default Content Gig Fee"
                      placeholder="Default Gig Fee"
                      onChange={(e) => {
                        updateSettingsData(e);
                      }}
                    />
                  </GridItem>

                  <GridItem>
                    <BBInput
                      type="number"
                      value={
                        settings.GigPrice &&
                        settings.GigPrice.value.defaultGigPricePublish
                      }
                      name="GigPrice-defaultGigPricePublish"
                      min={0}
                      labelFontWeight="normal"
                      required
                      label="Default Publish Gig Fee"
                      placeholder="Default Gig Fee"
                      onChange={(e) => {
                        updateSettingsData(e);
                      }}
                    />
                  </GridItem>

                  <GridItem paddingTop="37px"
                    justifyItems="start"
                    alignItems="center"
                    display="inline-grid">
                    <FormControl display="flex" alignItems="center">
                      <Switch
                        colorScheme="brandbassBlue2"
                        value={
                          settings.GigPrice &&
                          settings.GigPrice.value.firstGigFree
                        }
                        name="GigPrice-firstGigFree"
                        onChange={(e) => {
                          updateSettingsData(e,true);
                        }}
                        id="approval-required"
                      />
                      <FormLabel ml="5px" mb="0">
                        First Gig Free? *
                      </FormLabel>
                    </FormControl>
                  </GridItem>                  
                </Grid>
              </Flex>
              <Flex gap={3} direction={"column"}>
                <Grid gap={2}>
                  <GridItem >
                    <Text fontSize="18px" fontWeight={"bold"}>
                      Signup Settings
                    </Text>
                  </GridItem>
                </Grid>
                <Grid
                  templateColumns={{
                    base: "repeat(1, 1fr)",
                    lg: "repeat(5, 1fr)",
                  }}
                  gap={2}
                  flexDirection={{ base: "column", lg: "row" }}
                >                  
                  <GridItem>
                    <FormControl display="flex" alignItems="center">
                      <Switch
                        colorScheme="brandbassBlue2"
                        value={
                          settings.UserInvitationSettings &&
                          settings.UserInvitationSettings.value
                            .inviteRequiredForSignup
                        }
                        name="UserInvitationSettings-inviteRequiredForSignup"
                        onChange={(e) => {
                          updateSettingsData(e,true);
                        }}
                        id="approval-required"
                      />
                      <FormLabel ml="5px" mb="0">
                        Invite Required? *
                      </FormLabel>
                    </FormControl>
                  </GridItem>
                </Grid>
              </Flex>
              <Flex>
                <BBButton onClick={saveSettings} isLoading={isSaving} loadingText="Saving...">Save</BBButton>
              </Flex>
            </Flex>
          </Box>
        </Flex>
      )}
    </>
  );
};

export default Settings;
