import {
    Box, Button, Checkbox, Flex, Modal, ModalBody, ModalCloseButton, ModalContent,
    ModalHeader, ModalOverlay, Spacer, Spinner, Text, Avatar, Image, Input
} from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import {
    getUserRecommendation, campaignInvite
} from "RestAPI";
import { useToast } from '@chakra-ui/react'
import { ViewAmbassadorProfile } from "../Users/components/ShowUser";
import { globalStyles } from "theme/styles";
import SpinnerGif from "assets/img/SpinnerGif.gif"
import { BBSecondaryButton } from "components/Button/Button";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import "./Campaign.css"
import { AppContext } from "contexts/AppContext";

export const RecommendedAmbassadors = (props) => {
    const {userData} = useContext(AppContext)
    const { visible, closeHandler, campaign } = props
    const invitesAlreadyExist = !!campaign?.invites && !!campaign?.invites?.length && !!campaign.invites.filter(inv => inv.inviteStatus !== 'Rejected').length
    const [showModal, setShowModal] = useState(false)
    const [loading, setLoading] = useState(false)
    const {CampaignID} = useParams()
    const [searchParams] = useSearchParams();
    const [searchString, setSearchString] = useState('')
    const queryCampaignID = searchParams.get('CampaignID')
    const [originalAmbassadors, setOriginalAmbassadors] = useState([])
    const [ambassadors, setAmbassadors] = useState([])
    const toast = useToast();
    const [selectedUser, setSelectedUser] = useState(null)
    const navigate = useNavigate()
    const [pageCount, setPageCount] = useState(1)
    const [currentPage, setCurrentPage] = useState(0)

    const submit = async () => {
        let selectedAmbassadors = originalAmbassadors.filter(a => !!a.selected)
        if (selectedAmbassadors.length > 3) {
            toast({
                title: 'Choose 3 ambassadors',
                description: "Please choose upto 3 ambassadors.",
                status: 'error',
                position: 'top',
                duration: 3000,
                isClosable: true,
            })
            return
        }
        selectedAmbassadors = selectedAmbassadors.map(a => {
            return { user: a.id }
        })
        if (selectedAmbassadors.length) {
            let campaignId = campaign && campaign.id ? campaign.id : CampaignID ? CampaignID : queryCampaignID
            await campaignInvite(campaignId, { invites: selectedAmbassadors })
            toast({
                title: `Invites sent successfully.`,
                description: "",
                status: 'success',
                position: 'top',
                duration: 3000,
                isClosable: true,
            })
            closeHandler({}, true)
        }
    }

    const getRecommendations = async () => {
        if(loading) return
        setLoading(true)
        let response = await getUserRecommendation({ campaignId: campaign && campaign.id ? campaign.id : CampaignID ? CampaignID : queryCampaignID})
        setLoading(false)
        if (response && response.data && response.data.users) {
            const suitableAmbassadors = response.data.users.map(a => {
                a.selected = false
                return a
            })
            setPageCount(Math.floor(suitableAmbassadors.length/10) + 1)
            setOriginalAmbassadors(suitableAmbassadors)
            setAmbassadors(userData.role === 'SUPER_ADMIN' ? suitableAmbassadors : suitableAmbassadors.slice(0,10))
            setSelectedUser(suitableAmbassadors[0])
        }
    }

    const shuffleAmbassadors = () => {
        let currPage = currentPage >= pageCount-1 ? 0 : currentPage + 1
        setCurrentPage(currPage)
        let shuffledAmbassadors = originalAmbassadors.slice(currPage*10, (currPage + 1) * 10)
        setAmbassadors(shuffledAmbassadors)
        setSelectedUser(shuffledAmbassadors[0])
    }

    const updateAmbassadors = (val, type) => {
        if (invitesAlreadyExist) return
        let amb = originalAmbassadors.filter(a => !!a.selected)
        if (amb.length >= 3 && !!type) {
            toast({
                title: 'Choose 3 ambassadors',
                description: "Please choose upto 3 ambassadors.",
                status: 'error',
                position: 'top',
                duration: 3000,
                isClosable: true,
            })
            return
        }
        setOriginalAmbassadors(originalAmbassadors.map(amb => {
            if (amb.id === val.id) {
                amb.selected = type
            }
            return amb
        }))
    }

    const searchAmbassadors = () => {
        if(searchString?.length){
            let searchResults = []
            ambassadors.forEach(user => {
                if(user.firstName.indexOf(searchString) > -1){
                    searchResults.push(user)
                }else if(user.lastName.indexOf(searchString) > -1){
                    searchResults.push(user)
                }else if(user.userName.indexOf(searchString) > -1){
                    searchResults.push(user)
                }else if(user.email.indexOf(searchString) > -1){
                    searchResults.push(user)
                }
            })
            setAmbassadors(searchResults)
            setSelectedUser(searchResults[0])
        }else{
            setAmbassadors(ambassadors)
            setSelectedUser(ambassadors[0])
        }
    }

    const clearSearch = () => {
        setSearchString('');
        setAmbassadors(originalAmbassadors);
        setSelectedUser(originalAmbassadors[0])
    }

    useEffect(() => {
        if(!visible) return setShowModal(!!visible)
        if (!invitesAlreadyExist) {
            getRecommendations()
        }else{
            let users = campaign.invites?.map(inv => {
                return {...inv.user,...inv}
            })
            setAmbassadors(users)
            setSelectedUser(users[0])
        }
        setShowModal(!!visible)
    }, [visible])

    return <>
        <Modal isOpen={showModal} onClose={closeHandler} size="6xl">
            <ModalOverlay />
            <ModalContent>
                <ModalHeader color='brandbassBlue'></ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={6} flexDirection='row'>
                    {loading ? 
                    <Flex align='center' flexDirection='column' gap='1rem' pb='2rem'>
                        <Text fontStyle='italic' fontWeight='600' fontSize='20px'>Hang tight for a couple of seconds!</Text>
                        <Text fontStyle='italic' fontWeight='600' fontSize='20px'>Working on your ambassador results, all you have to do is select your top 3 and send the invite.</Text>
                        <Spinner thickness='8px' speed='0.85s' emptyColor='gray.200' color='brandbassBlue' w='8rem' h='8rem' mt='3rem'/>
                        {/* <Image src={SpinnerGif} w='8rem' h='8rem' /> */}
                        </Flex> 
                        :
                            campaign.ambassadorLocationType?.toLowerCase() !== 'digital/remote' && !ambassadors?.length
                                ?   <Flex flexDirection='column' align='center' gap='1rem' p={4}>
                                    <Text textAlign='center' p={2} fontStyle='italic' fontWeight='600' fontSize='20px'>
                                    
                                        Hang tight! We are activating ambassadors in your area. For more immediate matches please switch to Digital/Remote in edit campaign</Text> 
                                    <Flex align='center' gap='2rem' >
                                        <BBSecondaryButton onClick={closeHandler}>I'll wait</BBSecondaryButton>
                                        <BBSecondaryButton onClick={()=> navigate(`/edit/campaign/${campaign.id}`)} >Edit campaign</BBSecondaryButton>
                                    </Flex>
                                    </Flex> 
                                :
                            !ambassadors.length 
                            ?
                            <Flex flexDirection='column' align='center' gap='1rem' p={4}>
                            <Text textAlign='center' p={2} fontStyle='italic' fontWeight='600' fontSize='20px'>
                             
                                Hang tight! Having technical difficulty finding ambassadors. Please try later.</Text> 
                            <Flex align='center' gap='2rem' >
                                <BBSecondaryButton onClick={closeHandler}>I'll wait</BBSecondaryButton>
                                {/* <BBSecondaryButton onClick={()=> navigate(`/edit/campaign/${campaign.id}`)} >Edit campaign</BBSecondaryButton> */}
                            </Flex>
                            </Flex>
                            :
                            <Flex  id="recommendations" flexDirection='column' gap={2}>
                            {campaign.invites?.length && (campaign.invites.filter(inv => inv.inviteStatus !== 'Rejected')?.length) ? null : 
                            <Flex gap={8}>
                                <Flex gap={16} ml={4} alignItems='center'>
                                    <Text>{originalAmbassadors?.length ? originalAmbassadors.filter(a => !!a.selected).length : 0}/3 Selected</Text>
                                    <Button
                                        colorScheme="brandbassBlue"
                                        ml={6}
                                        borderRadius="lg"
                                        onClick={submit}
                                    >
                                        Send Invite
                                    </Button>
                                </Flex>
                                {userData.role === 'SUPER_ADMIN' ?
                                <Flex ml={16}>
                                    <Input value={searchString} onChange={(e) => setSearchString(e.target.value)} type={'text'} placeholder={'Search by name'}/>
                                    <Button
                                        colorScheme="brandbassBlue"
                                        ml={6}
                                        borderRadius="lg"
                                        onClick={searchAmbassadors}
                                    >
                                        Search
                                    </Button>
                                    <Button
                                        variant={'outline'}
                                        colorScheme="brandbassBlue"
                                        ml={6}
                                        borderRadius="lg"
                                        onClick={clearSearch}
                                    >
                                        Clear
                                    </Button>
                                </Flex> : null}
                                {userData.brand?.showOnlyPreferredAmbassadors ? <Button
                                    colorScheme="brandbassBlue"
                                    ml={6}
                                    borderRadius="lg"
                                    onClick={shuffleAmbassadors}
                                    variant='outline'
                                >
                                    Shuffle
                                </Button> : null}
                            </Flex>
                            }
                            <Flex>
                                <Box
                                    p={4}
                                    borderRadius="lg"
                                    overflow="scroll"
                                    width="30%"
                                >
                                    <Flex flexDirection='column' gap={2}>
                                        {
                                                ambassadors.map((user, idx) => {
                                                    return (
                                                        <Flex key={idx} justifyContent="space-between" cursor='pointer' onClick={() => setSelectedUser(user)}
                                                            backgroundColor={selectedUser?.id === user.id ? globalStyles.colors.brandbassBlue2[200] : 'white'}
                                                            borderColor={selectedUser?.id === user.id ? 'white' : globalStyles.colors.primary}
                                                            borderWidth={selectedUser?.id === user.id ? '0px' : '0.5px'}
                                                            p={2} borderRadius='md'>
                                                            <Flex alignItems="center">
                                                                <Box
                                                                    me="12px"
                                                                    borderRadius="50%"
                                                                    border="1px solid"
                                                                    borderColor='white'
                                                                    display="flex"
                                                                    alignItems="center"
                                                                    justifyContent="center"
                                                                    w="50px"
                                                                    h="50px"
                                                                >
                                                                    <Avatar src={user.profileImageUrl} w='48px' h='48px' borderRadius="50%" />
                                                                </Box>
                                                                <Flex direction="column">
                                                                    <Text
                                                                        fontSize={{ sm: "md", md: "lg", lg: "md" }}
                                                                        // color={textColor}
                                                                        fontWeight="bold"
                                                                    >
                                                                        {user.firstName}
                                                                    </Text>
                                                                    <Text
                                                                        fontSize={{ sm: "xs", md: "sm", lg: "xs" }}
                                                                        color="gray.400"
                                                                        fontWeight="semibold"
                                                                    >
                                                                        {user.inviteStatus ? user.inviteStatus : user.tagLine}
                                                                    </Text>
                                                                </Flex>
                                                                <Spacer />

                                                            </Flex>
                                                            <Box
                                                                alignSelf='center'
                                                                ml={2}
                                                            >
                                                                <Checkbox float='right' spacing='6rem' isChecked={user.selected} onChange={e => { e.stopPropagation(); updateAmbassadors(user, e.target.checked) }}></Checkbox>
                                                            </Box>
                                                        </Flex>)
                                                })
                                        }

                                    </Flex>
                                </Box>
                                {selectedUser && <Box
                                    p={4}
                                    borderRadius="lg"
                                    overflow="scroll"
                                    width="70%">
                                    <ViewAmbassadorProfile user={selectedUser} />
                                </Box>}
                            </Flex>
                        </Flex>}
                </ModalBody>
            </ModalContent>
        </Modal>
    </>
}
