import {
  Avatar,
  CloseButton, Flex, Grid,
  GridItem,
  Radio, RadioGroup, Text, VStack, useToast
} from "@chakra-ui/react";
import BBAutocomplete from "components/Autocomplete/Autocomplete";
import BBTextarea from "components/Textarea/Textarea";
import { AppContext } from "contexts/AppContext";
import { FormHeader } from "layouts/FormLayout";
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { saveIssue } from "RestAPI";
import { getCampaigns, getIssueReasons } from "RestAPI";

const IssueForm = (props) => {
  const { visible, closeHandler, gig, callback } = props
  const navigate = useNavigate()
  const location = useLocation()
  const issueData = location.state?.issueData
  const campaign = location.state?.campaign || issueData?.campaign || null
  const readOnly = location.state?.readOnly

  const emptyIssue = {
    moduleId: !!campaign ? campaign.id : null,
    issueDescription: null,
    issueType: !!campaign ? 'Campaign' : null,
    reason: null,
    campaign: !!campaign ? campaign : null,
    // attachments: null
  }
  const gigFromIssueData = issueData?.campaign?.gigs?.filter(item => item.id === issueData.moduleId)
  const { userData } = useContext(AppContext)
  const [showModal, setShowModal] = useState(false)
  const [reasons, setReasons] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showTextArea, setShowTextArea] = useState(false);
  const [selectedAnswers, setSelectedAnswers] = useState([]);
  const [queAnswers, setQueAnswers] = useState([]);
  const [comment, setComment] = useState('');
  const [type, setType] = useState(!!campaign ? 'Campaign' : issueData?.type ? issueData.type : null);
  const [issue, setIssue] = useState(issueData || emptyIssue)
  const [selectedCampaign, setSelectedCampaign] = useState(campaign || null)
  const [selectedGig, setSelectedGig] = useState(gig || !!gigFromIssueData?.length ? gigFromIssueData[0] : null)
  const [campaignList, setcampaignList] = useState([])
  const title = 'Help'
  const toast = useToast()

  const updateIssue = (obj) => {
    setIssue({ ...issue, ...obj })
  }

  const fetchAllCampaigns = () => {
    getCampaigns()
      .then(response => setcampaignList(!!response && !!response.data ? response.data : []))
      .catch(err => { console.error(err) })
  }

  const fetchReasons = () => {
    const reasonType = userData.role.toLowerCase().includes('brand')
      ? `Campaign_Brand`
      : userData.role.toLowerCase().includes('ambassador')
        ? `Campaign_Ambassador`
        : null
    getIssueReasons(reasonType)
      .then(res => setReasons(res.data))
      .catch(err => console.error(err))
  }

  const showCampaignGigOptions = () => {
    fetchReasons()
    !selectedCampaign && fetchAllCampaigns()
  }

  const updateCategory = (cat) => {
    setType(!type || type !== cat ? cat : null)
  }

  const handleSelectCampaign = (name) => {
    const campaign = campaignList.filter(item => name === `${item.name}-${item.id}`)
    if (!campaign || !campaign.length) {
      setSelectedCampaign(null)
      updateIssue({ issueType: null })
      return
    }
    setSelectedCampaign(campaign[0])
    updateIssue({ issueType: 'Campaign', moduleId: campaign[0].id, campaign: campaign[0] })
  }

  const handleSelectGig = (name) => {
    const gig = selectedCampaign.gigs.filter(item => name === `${item.gigName}-${item.id}`)
    if (!gig || !gig.length) {
      setSelectedGig(null)
      updateIssue({ issueType: 'Campaign', moduleId: selectedCampaign.id })
      return
    }
    setSelectedGig(gig[0])
    updateIssue({ issueType: 'Gig', moduleId: gig[0].id })
  }

  const validateInput = () => !!issue.issueType && !!issue.reason

  const clearCampaign = () => {
    setSelectedGig(null)
    setSelectedCampaign(null)
    updateIssue({ issueType: 'Campaign', moduleId: null, reason: null, campaign: null })
  }

  const clearGig = () => {
    updateIssue({ issueType: 'Campaign', moduleId: selectedCampaign.id, reason: null })
    setSelectedGig(null)
  }

  const submit = async () => {
    if (!validateInput() || !!readOnly) return
    setLoading(true)
    saveIssue(issue)
      .then(res => {
        setLoading(false)
        toast({
          title: `Issue submitted successfully`,
          description: "",
          status: 'success',
          position: 'top',
          duration: 3000,
          isClosable: true,
        })
        if (!!callback) callback()
        navigate(-1)
      })
      .catch(err => {
        setLoading(false)
        toast({
          title: `An error occured when saving the issue.`,
          description: "Please try after sometime",
          status: 'error',
          position: 'top',
          duration: 3000,
          isClosable: true,
        })
        console.error(err)
      })
  }

  const cancel = () => {
    setIssue(emptyIssue)
    setSelectedCampaign(null)
    setSelectedGig(null)
    setType(null)
    navigate(-1)
  }

  useEffect(() => setShowModal(!!visible), [visible])
  useEffect(() => setSelectedCampaign(campaign), [campaign])
  useEffect(() => {
    setType(issueData?.issueType ? issueData.issueType : type)
    setIssue({
      ...issue,
      issueDescription: issueData?.issueDescription || null,
      issueType: issueData?.issueType || null,
      moduleId: issueData?.moduleId || null,
      reason: issueData?.reason || null,
      campaign: issueData?.campaign || null
    })
    if (['Campaign', 'Gig'].includes(issueData?.issueType)) {
      setSelectedCampaign(issueData?.campaign ? issueData.campaign : selectedCampaign)
      setSelectedGig(issueData?.issueType === 'Gig' ? issueData?.campaign?.gigs?.filter(item => item.id === issueData.moduleId)[0] : selectedGig)
    }
  }, [issueData])

  useEffect(() => {
    setShowTextArea(!!type)
    if (['Campaign', 'Gig'].includes(type)) {
      showCampaignGigOptions()
      updateIssue({ issueType: type })
    } else {
      updateIssue({ campaign: null, issueType: type, reason: 'Others', moduleId: type === 'Profile' ? userData.id : null })
    }
  }, [type])

  return <>
    <FormHeader
      caption='Help'
      loading={loading}
      hideSave = {issueData?.id?true:false}
      submitHandler={() => submit()}
      closeHandler={() => navigate(-1)}
      cancelHandler={() => navigate(-1)}
    />
    <Text color='gray3' mb='20px' ml={4} mr={4}>
      We are always here to help you out! Please select a category in which you have an issue to be resolved.
    </Text>

    <Flex id="issue-form" flexDirection='column' gap={10} margin={4}>
      <Grid templateColumns='repeat(4, 1fr)' gap={3}>
        <GridItem
          display='flex' justifyContent='center' w='100%' h='10' border='2px solid' borderColor='primary' borderRadius='lg' alignItems='center' cursor='pointer' flexDirection='column' bgColor={['Campaign', 'Gig'].includes(type) ? 'primary' : 'white'} onClick={() => updateCategory('Campaign')}>
          <Text fontWeight='600' fontStyle='italic' color={['Campaign', 'Gig'].includes(type) ? 'white' : 'primary'}>Campaigns and Gigs</Text>
        </GridItem>
        <GridItem
          display='flex' justifyContent='center' w='100%' h='10' border='2px solid' borderColor='primary' borderRadius='lg' alignItems='center' cursor='pointer' flexDirection='column' bgColor={['Invoice', 'Payment'].includes(type) ? 'primary' : 'white'} onClick={() => updateCategory('Invoice')}>
          <Text fontWeight='600' fontStyle='italic' color={['Invoice', 'Payment'].includes(type) ? 'white' : 'primary'}>Invoices and Payments</Text>
        </GridItem>
        <GridItem
          display='flex' justifyContent='center' w='100%' h='10' border='2px solid' borderColor='primary' borderRadius='lg' alignItems='center' cursor='pointer' flexDirection='column' bgColor={type === 'Profile' ? 'primary' : 'white'} onClick={() => updateCategory('Profile')}>
          <Text fontWeight='600' fontStyle='italic' color={type === 'Profile' ? 'white' : 'primary'}>Profile</Text>
        </GridItem>
        <GridItem
          display='flex' justifyContent='center' w='100%' h='10' border='2px solid' borderColor='primary' borderRadius='lg' alignItems='center' cursor='pointer' flexDirection='column' bgColor={type === 'Technical' ? 'primary' : 'white'} onClick={() => updateCategory('Technical')}>
          <Text fontWeight='600' fontStyle='italic' color={type === 'Technical' ? 'white' : 'primary'}>Technical</Text>
        </GridItem>
      </Grid>

      {
        !!type && ['Campaign', 'Gig'].includes(type) &&
        (
          !selectedCampaign
            ?
            <BBAutocomplete
              disabled={issueData?.id}
              defaultValues={[]}
              style={{}}
              options={campaignList.map(item => ({
                id: item.id,
                value: item.id,
                name: `${item.name}-${item.id}`,
                imageUrl: item.imageUrl
              }))}
              isMultiple={false}
              label='Select Campaign'
              onChange={handleSelectCampaign}
            />
            :
            <Flex flexDirection='column' gap='10px'>
              <Text fontWeight='bold' fontStyle='italic' color='primary'>Campaign:</Text>
              <Flex boxShadow='base' borderRadius='md' p={4} alignItems='center' gap='1rem' position='relative'>
                <Avatar size='lg' name={selectedCampaign.name} src={selectedCampaign.imageUrl} />
                <Flex flexDirection='column'>
                  <Text fontWeight='bold' fontStyle='italic' color='primary'>{selectedCampaign.name}</Text>
                  <Text>{selectedCampaign.notes}</Text>
                </Flex>
                {issueData?.id ? null : <CloseButton position='absolute' top='20px' right='20px' onClick={clearCampaign} />}
              </Flex>
              {
                !selectedGig
                  ?
                  <BBAutocomplete
                    disabled={issueData?.id}
                    defaultValues={[]}
                    style={{}}
                    options={selectedCampaign.gigs?.map(item => ({
                      id: item.id,
                      value: item.id,
                      name: `${item.gigName}-${item.id}`,
                      imageUrl: item.imageUrl
                    }))}
                    isMultiple={false}
                    label='Select Gig (if the issue is specific to a gig)'
                    onChange={handleSelectGig}
                  />
                  : <>
                    <Text fontWeight='bold' fontStyle='italic' color='primary'>Gig:</Text>
                    <Flex boxShadow='base' borderRadius='md' p={4} alignItems='center' gap='1rem' position='relative'>
                      <Avatar size='lg' name={selectedGig.gigName} src={selectedGig.imageUrl} />
                      <Flex flexDirection='column'>
                        <Text fontWeight='bold' fontStyle='italic' color='primary'>{selectedGig.gigName}</Text>
                        <Text>{selectedGig.gigDescription}</Text>
                      </Flex>
                      <CloseButton disabled={issueData?.id} position='absolute' top='20px' right='20px' onClick={clearGig} />
                    </Flex>
                  </>
              }
            </Flex>
        )
      }

      {
        !!type && ['Campaign', 'Gig'].includes(type) && !!selectedCampaign &&
        <RadioGroup name="issueReason" onChange={(val) => updateIssue({ reason: val })} defaultValue={issue.reason}>
          <Text fontWeight='bold' fontStyle='italic' color='primary' mb='12px'>Reason:</Text>
          <VStack align='stretch' spacing='1rem'>
            {
              reasons?.map((item, idx) => <Radio isDisabled = {issueData?.id} key={idx} value={item.reasonText} color='primary'>{item.reasonText}</Radio>)
            }
          </VStack>
        </RadioGroup>
      }

      {
        !!showTextArea &&
        <BBTextarea
          disabled={issueData?.id}
          value={issue.issueDescription || ''}
          required={true}
          placeholder='Please specify more details'
          onChange={(e) => updateIssue({ issueDescription: e.target.value })}
        />
      }

    </Flex>
  </>
}

export default IssueForm
