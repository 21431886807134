import {
  Box,
  Button,
  Flex,
  Icon,
  Spacer,
  Text,
  useColorModeValue,
  Avatar
} from "@chakra-ui/react";
import React from "react";

function InvoicesRow(props) {
  const textColor = useColorModeValue("primary", "white");
  const { gigs, name, date, code, price, format, logo, credits } = props;

  return (
    <Flex my={{ sm: "1rem", xl: "10px" }} alignItems="center">
      <Avatar src={logo} w="60px" h="60px" me="5px" mr={4}/>
      <Flex direction="column">
        <Text fontSize="md" color={textColor} fontWeight="bold">
          {name}
        </Text>
        <Flex gap={2}>
          <Text fontSize="sm" color="gray.400" fontWeight="semibold" me="16px">
            Gigs: {gigs}
          </Text>
          <Text fontSize="sm" color="gray.400" fontWeight="semibold" me="16px">
            Ref: {code}
          </Text>
        </Flex>
      </Flex>
      <Spacer />
      <Box me="12px">
        <Flex gap={8}>
          <Flex gap={2}>
            <Text fontSize="md" color={'primary'} fontWeight="400">Credits: </Text> 
            <Text fontSize="md" color="primary" fontWeight="bold">{credits}</Text>
          </Flex>
          <Flex gap={2}>
            <Text fontSize="md" color={'primary'} fontWeight="400">Amount:</Text>
            <Text fontSize="md" color="primary" fontWeight="bold">${price}</Text>
          </Flex>
        </Flex>
        <Text fontSize="md" color={'gray.400'} fontWeight="semibold">
          {date}
        </Text>
      </Box>
      {/* <Button p="4px" bg="transparent" variant="no-hover">
        <Flex alignItems="center" p="12px">
          <Icon as={logo} w="20px" h="auto" me="5px" />
          <Text fontSize="md" color={textColor} fontWeight="bold">
            {format}
          </Text>
        </Flex>
      </Button> */}
    </Flex>
  );
}

export default InvoicesRow;
