// Chakra imports
import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Icon,
  Image,
  Input,
  List, IconButton,
  ListIcon,
  ListItem,
  Select,
  Spinner,
  Table,
  Tbody,
  Text,
  Th,Center,
  Thead,
  Tr, Skeleton, SkeletonCircle, SkeletonText,
  Divider, Spacer,
  useColorModeValue,
  Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Tag, TagLabel
} from "@chakra-ui/react";
// Custom components
import EmptyList from "assets/img/empty/campaigns.png";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CounterCard from "components/Card/CounterCard.js";
import DashboardTableRow from "components/Tables/DashboardTableRow";
import CampaignTableRow from "components/Tables/CampaignTableRow";
import React, { useState, useEffect, useContext, useCallback } from "react";
import { IoCheckmarkDoneCircleSharp } from "react-icons/io5";
import { Navigate, useParams, useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { getCampaigns } from "RestAPI"
import { CompletedIcon } from "components/Icons/Icons";
import { GigIcon } from "components/Icons/Icons";
import { SettingsIcon } from "components/Icons/Icons";
import { SupportIcon } from "components/Icons/Icons";
import { GlobeIcon } from "components/Icons/Icons";
import { AppContext } from "contexts/AppContext";
import { LinkArrowIcon } from "components/Tables/CampaignTableRow";
import { LinkButton } from "components/Tables/CampaignTableRow";
import { TickIcon } from "components/Icons/Icons";
import { VerticalSeparator } from "./CampaignOverview";
import { EditIcon, ViewIcon } from "@chakra-ui/icons";
import ReactDataGrid from '@inovua/reactdatagrid-community'
import '@inovua/reactdatagrid-community/index.css'
import { TrashIcon, CopyIcon } from "components/Icons/Icons";
import { getCampaign } from "RestAPI";
import { deleteCampaign, cloneCampaign } from "RestAPI";
import { GigModal } from "components/Card/GigDetailsCard";
import moment from "moment";
import ShowUserForm from "../Users/components/ShowUser";
import { BeeIcon } from "components/Icons/Icons";
import IssueForm from "../Issues/IssueForm";
import { globalStyles } from "theme/styles";
import { FaPlus } from "react-icons/fa";
import BBButton from "components/Button/Button";
import NoCampaigns from "assets/svg/emptyList/NoCampaigns.svg"
import "./Campaign.css"
import { TickIconGreen } from "components/Icons/Icons";

const CampaignList = (props) => {
  const { userData, placeholderImage, hasEditPermission, hasCreatePermission } = useContext(AppContext)
  const navigate = useNavigate()
  const textColor = useColorModeValue("gray.700", "white");
  const goToCreateCampaign = () => navigate('/new/campaign', { replace: true })
  const [overView, setOverview] = useState(null)
  const [deleteCampaignId, setDeleteCampaignId] = useState(null)
  const [showDeleteCampaignModal, setShowDeleteCampaignModal] = useState(false)
  const title = "Campaigns"
  const [loading, setLoading] = useState(true)
  const [showGigModal, setShowGigModal] = useState(false)
  const [selectedGig, setSelectedGig] = useState(null)
  const [cloneCampaignId, setCloneCampaignId] = useState(null)
  const [showCloneCampaignModal, setShowCloneCampaignModal] = useState(false)
  const [showAmbModal, setShowAmbModal] = useState(false)
  const [selectedAmb, setSelectedAmb] = useState(null)
  const [showIssueForm, setShowIssueForm] = useState(false)
  const [issueCampaign, setIssueCampaign] = useState(null)
  const [noCampaigns, setNoCampaigns] = useState(false)
  const [searchParams] = useSearchParams()
  const [status,setStatus] = useState(searchParams?.get('status')?searchParams.get('status'):'')

  const defaultSortInfo = { id: "id", name: 'id', dir: -1, type: 'number' }
  const fetchAllCampaigns = () => {
    setLoading(true)
    getCampaigns()
      .then(response => {
        setLoading(false);
        if (response) {
          let campaigns = response.data ? response.data : response
          if(!campaigns.length) {
            return setNoCampaigns(true)
          }
          setOverview({
            allCampaigns: campaigns,
            draftCampaigns: campaigns.filter(item => item.campaignStatus.toUpperCase() === 'DRAFT'),
            createdCampaigns: campaigns.filter(item => item.campaignStatus.toUpperCase() === 'CREATED'),
            invitedCampaigns: campaigns.filter(item => item.invites.length > 0),
            activeCampaigns: campaigns.filter(item => item.campaignStatus.toUpperCase() === 'ACTIVE'),
            closedCampaigns: campaigns.filter(item => item.campaignStatus.toUpperCase() === 'CLOSED'),
            pendingCampaigns: campaigns.filter(item => item.campaignStatus.toUpperCase() === 'CREATED' && (item.invites[0]?.inviteStatus === 'Pending' || item.invites[0]?.inviteStatus === 'Seen')),
            attnNeededCampaigns: campaigns.filter(item => (!item.invites || !item.invites.length || (item.invites?.length && !item.invites.filter(inv => inv.inviteStatus !== 'Rejected')?.length)) && item.campaignStatus.toUpperCase() === 'CREATED')         
          })
        }
      }).catch(err => { setLoading(false); console.error(err) })
  }
  const fetchCampaigns = ({ skip, limit, sortInfo, currentData, filterValue, groupBy }) => {
    const params = {}
    if (!isNaN(skip) && skip >= 0 && !!limit) {
      params.pagination = {
        perPage: limit,
        page: !skip ? 1 : (skip / limit) + 1
      }
    }
    if (!!sortInfo) {
      params.sort = {
        order: sortInfo.dir > 0 ? 'ASC' : 'DESC',
        field: sortInfo.id || 'id'
      }
    }
    if (!!filterValue && !!filterValue.length) {
      params.filter = {
        campaignName: filterValue[0].value,
        brandName: filterValue[1]?.value,
        ambassadorName: filterValue[2]?.value
      }
    }
    if(status){
      params.filter = {
        status
      }
    }
    if(props.filter){
      params.filter = props.filter
    }
    return getCampaigns(params)
      .then(response => {
        if(!response) return ({})
        let data = response.data ? response.data : response
        if (data) {
          data = data.map(campaign => {
            let invites = campaign.invites || []
            if (campaign.campaignStatus === 'Created' && (!invites.length || (invites.length && !invites.filter(inv => inv.inviteStatus !== 'Rejected')?.length))) {
              campaign.campaignStatus = 'Attn. Needed'
            }
            if (campaign.campaignStatus === 'Created' && invites.length) {
              campaign.campaignStatus = 'Invited'
            }
            campaign.gigCount = campaign.gigs?.length
            if(userData.role === 'AMBASSADOR'){
              let compensation = 0;
              for(let gig of campaign.gigs){
                compensation += gig.ambassadorFee
                if(gig.giveawayValue && (gig.giveawayType === 'Cash Tip' || gig.giveawayValue === 'Alternate Sample')){
                  compensation += gig.giveawayValue;
                }
                if(gig.productGiveawayValue){
                  compensation += gig.productGiveawayValue;
                }
              }
              campaign.compensation = compensation
            }else{
              campaign.amount = campaign.budget
            }
            return campaign
          })
        }
        return ({ data, count: response.total ? parseInt(response.total) : response.length || 0 })
      })
      .catch(err => console.error(err))
  }

  const defaultFilterValue = [
    { name: 'name', type: 'string', operator: 'contains', value: '' },
    { name: 'brand', type: 'string', operator: 'contains', value: '' },
    { name: 'invites', type: 'string', operator: 'contains', value: '' },
  ];
  let columns = [
    {
      id: "id",
      name: "id",
      type: "number",
      header: <Text color="gray3" textTransform='uppercase'>ID</Text>,
      defaultVisible: false,
      defaultWidth: 100,
      sortable: true,
      render: ({ value }) => (
        <Text color="black3" fontWeight="600">
          {value}
        </Text>
      ),
    },
    {
      id: "name",
      name: "name",
      type: "string",
      header: <Text color="gray3">CAMPAIGN NAME</Text>,
      defaultVisible: true,
      minWidth: 240,
      maxWidth: 240,
      sortable: true,
      // filterEditorProps: (props) => ({placeholder: 'place'}),
      filterEditorProps: {
        style: {
          background: '#fefefe',
        },
        placeholder: 'Search by name...'
      },
      render: ({ data }) => (
        <>
          <Flex
            align="center"
            py=".8rem"
            minWidth="100%"
            flexWrap="nowrap"
            gap="1rem"
          >
            <Image
              src={data.imageUrl}
              objectFit="cover"
              minW="56px"
              maxW="56px"
              minH="56px"
              maxH="56px"
              borderRadius="lg"
              fallbackSrc={placeholderImage(40)}
            />
            <Flex flexDirection="column">
              <Text
                fontSize="md"
                color={globalStyles.colors.primary}
                fontWeight="500px"
                minWidth="100%"
                cursor="pointer"
                onClick={() => navigate(`/campaigns/${data.id}`)}
              >
                {data.name}
              </Text>
              <Text
                fontSize="md"
                color="gray.500"
                fontWeight="normal"
                minWidth="100%"
              >
                {data.notes}
              </Text>
            </Flex>
          </Flex>
        </>
      ),
    },
    {
      id: "gigCount",
      name: "gigCount",
      header: <Text color="gray3" textAlign={'center'}>{'  '} # GIGS</Text>,
      defaultVisible: true,
      maxWidth: 100,
      sortable: false,
      render: ({ value }) =>
      (
        <Text color="black3" fontWeight="600" fontSize='md' textAlign={'center'}>
          {value}
        </Text>
      ),
    },
    {
      id: "gigs",
      name: "gigs",
      header: <Text color="gray3">GIGS</Text>,
      defaultVisible: true,
      minWidth: 200,
      maxWidth: 240,
      sortable: false,
      render: ({ value }) =>
        !!value &&
        <Flex alignItems="end"  >
          {!value || !value.length ? (
            "-"
          ) : (
            <AvatarGroup size="md">
              {value.map((gig) =>
                <Avatar
                  name={gig.gigName}
                  title={gig.gigName}
                  key={gig.id}
                  src={gig.imageUrl}
                  _hover={{ zIndex: "3", cursor: "pointer" }}
                  onClick={() => { setShowGigModal(true); setSelectedGig(gig.id) }}
                >
                  {selectedGig === gig.id ? <GigModal visible={showGigModal} gig={gig} closeHandler={() => { setShowGigModal(false); setSelectedGig(null) }} /> : null}
                </Avatar>
              )}
            </AvatarGroup>
          )}
        </Flex>

    }]
    if(userData.role === 'AMBASSADOR' || userData.role === 'SUPER_ADMIN' || userData.role === 'CLIENT_SUPER_ADMIN'){
      columns.push({
      id: "brand",
      name: "brand",
      header: <Text color="gray3" textAlign='center'>Brand</Text>,
      defaultVisible: true,
      minWidth: 240,
      maxWidth: 260,
      sortable: true,
      filterEditorProps: {
        style: {
          background: '#fefefe',
        },
        placeholder: 'Search by brand name...'
      },
      render: ({ value }) => (
        <>
          <Flex
            align="center"
            py=".8rem"
            minWidth="100%"
            flexWrap="nowrap"
            gap="1rem"
          >
            <Image
              src={value.logoUrl}
              objectFit="cover"
              minW="56px"
              maxW="56px"
              minH="56px"
              maxH="56px"
              borderRadius="lg"
              fallbackSrc={placeholderImage(40)}
            />
            <Flex flexDirection="column">
              <Text
                fontSize="md"
                color={globalStyles.colors.primary}
                fontWeight="500px"
                minWidth="100%"
                cursor="pointer"
              >
                {value.brandName}
              </Text>
            </Flex>
          </Flex>
        </>
      ),
    })}
    if(userData.role !== 'AMBASSADOR'){
      columns.push({
      id: "invites",
      name: "invites",
      header: <Text color="gray3" textAlign='center'>AMBASSADORS</Text>,
      defaultVisible: true,
      maxWidth: 200,
      sortable: false,
      filterEditorProps: {
        style: {
          background: '#fefefe',
        },
        placeholder: 'Search by name...'
      },
      render: ({ value }) => (
        <>
          <Flex alignItems="start" >
            {!value || !value.length ? (
              "-"
            ) : (
              <AvatarGroup size="md" mt={4}>
                {value.map((invite) =>
                  !invite.user ? null : (
                    <Avatar
                      name={invite.user.firstName}
                      title={invite.user.firstName}
                      key={invite.id}
                      src={invite.user.profileImageUrl}
                      _hover={{ zIndex: "3", cursor: "pointer" }}
                      onClick={() => { setSelectedAmb(invite.user.id); setShowAmbModal(true) }}
                    >
                      {selectedAmb === invite.user.id ? <Modal size={'4xl'} isOpen={showAmbModal} onClose={() => setShowAmbModal(false)}>
                        <ShowUserForm onClose={() => { setShowAmbModal(false); setSelectedAmb(null) }} UserID={invite.user.id} />
                      </Modal> : null}
                      {invite.inviteStatus === 'Accepted' ? <TickIconGreen style={{position: 'absolute', left: 16, top: -12}}/> : null }
                    </Avatar>
                  )
                )}
              </AvatarGroup>
            )}
          </Flex>
        </>
      ),
    })}
    columns = columns.concat([
    {
      id: 'campaignStatus',
      name: "campaignStatus",
      header: <Text color="gray3">STATUS</Text>,
      defaultVisible: true,
      maxWidth: 160,
      sortable: true,
      render: ({ value }) => {
        let bgColor = globalStyles.colors.statusGreen
        if (value === 'Invited') {
          bgColor = globalStyles.colors.statusYellow
        } else if (value === 'Attn. Needed') {
          bgColor = globalStyles.colors.statusRed
        } else if (value === 'Closed') {
          bgColor = '#4DA6F6'
        } else if (value === 'Draft') {
          bgColor = globalStyles.colors.statusGray
        }
        return (
          < Tag textAlign={'center'} minW={28} backgroundColor={bgColor}><Spacer /><TagLabel textAlign={'center'}>{value}</TagLabel><Spacer /></Tag>
        )
      },
    },
    {
      id: 'submissions',
      name: "submissions",
      header: <Text color="gray3">SUBMISSIONS</Text>,
      defaultVisible: true,
      maxWidth: 120,
      sortable: false,
      render: ({ value }) => {
        let accepted = value.filter(s => s.submissionStatus === 'Accepted')
        let submitted = value.filter(s => s.submissionStatus === 'Submitted')
        let revision = value.filter(s => s.submissionStatus === 'In Revision')
        return ( value.length ? 
          < Flex flexDirection={'column'}>          
          {accepted?.length ? <Text>Accepted: {accepted.length}</Text> : null}
          {submitted?.length ? <Text>Submitted: {submitted.length}</Text> : null}
          {revision?.length ? <Text>In Revision: {revision.length}</Text> : null}
          </Flex> : <Text>None</Text> 
        )
      },
    },
   {
      id:'amount',
      name: userData.role === 'AMBASSADOR' ? 'compensation' : 'amount',
      header: <Text color="gray3">{userData.role === 'AMBASSADOR' ? 'COMPENSATION' : 'BUDGET'}</Text>,
      defaultVisible: userData.role === 'SUPER_ADMIN' ? true : false,
      maxWidth: 125,
      sortable: true,
      render: ({ value }) =>
        isNaN(value) ? null : (
          <Text color="black3" fontWeight="600" fontSize='md'>
            ${value}
          </Text>
        ),
    },
    {
      id:"createdOn",
      name: "createdOn",
      header: <Text color="gray3">CREATED ON</Text>,
      defaultVisible: true,
      maxWidth: 150,
      sortable: true,
      render: ({ value }) =>
      (
        <Text color="black3" fontWeight="600" fontSize='md'>
          {moment(value).format('MM-DD-YYYY')}
        </Text>
      ),
    },
    {
      id:"updatedOn",
      name: "updatedOn",
      header: <Text color="gray3">UPDATED ON</Text>,
      defaultVisible: true,
      maxWidth: 150,
      sortable: true,
      render: ({ value }) =>
      (
        <Text color="black3" fontWeight="600" fontSize='md'>
          {moment(value).format('MM-DD-YYYY')}
        </Text>
      ),
    },
    {
      id: 'actions',      
      header: <Text color="gray3">ACTIONS</Text>,
      defaultVisible: true,
      defaultWidth: 150,
      sortable: false,
      render: ({ data }) => <Flex gap='0.25rem' >
        <Flex title="View" justifyContent="center" alignItems="center">
          <IconButton onClick={() => navigate(`/campaigns/${data.id}`)}
            variant='outline' size="xs"
            colorScheme='teal' borderRadius={"50%"}
            aria-label='Help'
            icon={<ViewIcon />}
          />
        </Flex>
        <Flex title="Edit" justifyContent="center" alignItems="center" >
          {
            data.campaignStatus === "Active" || data.campaignStatus === "Closed" || ['AMBASSADOR','BRAND_USER','BRAND_MEMBER'].includes(userData?.role?.toUpperCase())
              ? null
              : data.createdBy?.id === userData.id || userData.role === 'BRAND_SUPER_ADMIN' || userData.role === 'CLIENT_SUPER_ADMIN' ?
              <IconButton onClick={() => navigate(`/edit/campaign/${data.id}`)}
                variant='outline' size="xs"
                colorScheme='teal' borderRadius={"50%"}
                aria-label='Edit'
                icon={<EditIcon />}
              /> : null
          }
        </Flex>
        <Flex title="Delete" justifyContent="center" alignItems="center">
          {
            data.payment?.length || userData?.role === 'AMBASSADOR'
              ? null
              : data.createdBy?.id === userData.id || userData?.role?.includes('ADMIN') ?
              <IconButton onClick={() => {
                setDeleteCampaignId(data.id)
                setShowDeleteCampaignModal(true)
              }}
                variant='outline' size="xs"
                colorScheme='teal' borderRadius={"50%"}
                aria-label='Delete'
                icon={<TrashIcon />}
              /> : null 
          }
        </Flex>
        {userData.role === 'AMBASSADOR' || !hasCreatePermission ? null : <Flex title="Duplicate" justifyContent="center" alignItems="center">
          {

            <IconButton onClick={() => {
              setCloneCampaignId(data.id)
              setShowCloneCampaignModal(true)
            }}
              variant='outline' size="xs"
              colorScheme='teal' borderRadius={"50%"}
              aria-label='Clone'
              icon={<CopyIcon />}
            />
          }
        </Flex>}
        {data.campaignStatus === 'Closed' ? null : <Flex title="Duplicate" justifyContent="center" alignItems="center">

          <IconButton onClick={() => createIssue(data)}
            variant='outline' size="xs"
            colorScheme='teal' borderRadius={"50%"}
            aria-label='Help'
            icon={<BeeIcon />}
          />
        </Flex>}
      </Flex>
    },
  ]);
  columns.forEach(item => item.style = { borderLeftColor: 'white', borderRightColor: 'white', borderTopColor: 'white', borderBottomColor: 'gray.200' })

  const openIssueOnCampaign = (campaign) => {
    setIssueCampaign(campaign)
  }

  const dataSource = useCallback(fetchCampaigns, [status])
  
  const createIssue = (campaign) => {
    navigate('/new/issue/', {state: {issueData: {campaign}} })
  }

  const handleClick = (e, status)=>{
    e.preventDefault()
    setStatus(status)
  }
  useEffect(fetchAllCampaigns, [])
  useEffect(fetchAllCampaigns,[status])
  useEffect(() => {
    !!issueCampaign ? setShowIssueForm(true) : setShowIssueForm(false)
  }, [issueCampaign])
  return (
    <>
      {/* <IssueForm visible={showIssueForm} closeHandler={() => setShowIssueForm(false)} campaign={issueCampaign} /> */}

      <Card id="campaigns-list" mt='2px' p='16px' overflowX={{ sm: "scroll" }} boxShadow='0px 0px 20px 1px rgb(0 0 0 / 10%)' borderRadius='10px'>
        <CardHeader p='0px 0px 8px 0px'>
          <Flex flex={1} align="center" gap='2rem' w="100%" ml={2} mr={2}>
            <Flex direction='column' gap='2rem' w="100%">
              <Flex gap='1rem' w="100%" flexDirection='column'>
                <Text fontSize='30px' color={textColor} fontWeight='bold' pb='.5rem'>
                  {title}
                </Text>
                {
                  !loading && !!noCampaigns && <>
                  <Text fontStyle='italic' fontWeight='600' fontSize='16px' maxW='540px'>This is your campaign view, where you can edit and create new campaigns. Let’s start with your first campaign!</Text>
                  </>
                }
                {
                  !!hasCreatePermission && 
                  <BBButton shadow={true} maxW='fit-content' size="sm" leftIcon={<FaPlus />} colorScheme='brandbassBlue' color="white" onClick={goToCreateCampaign}>
                    CAMPAIGN
                  </BBButton>
                }
              </Flex>
              <Flex gap='20px' p='10px' alignItems='center' borderRadius='10px' minWidth="100%">
                {
                  loading && (
                    <Flex align="center" minWidth="100%" flexWrap="nowrap">
                      <Box padding='6' boxShadow='lg' minWidth="100%">
                        <SkeletonText mt='4' noOfLines={4} spacing='4' />
                      </Box>
                    </Flex>
                  )
                }
                {!loading && !noCampaigns && !props.hideOverview && dataSource && dataSource.length > 0 && (<>
                  <CounterCard title={"All"} width={125} count={overView ? overView.allCampaigns.length : 0} clickHandler={(e) => handleClick(e)} selected={!status}/>
                  {userData.role === 'AMBASSADOR' ?  null : <CounterCard title={"Draft"} width={125} count={overView ? overView.draftCampaigns.length : 0} clickHandler={(e) => handleClick(e,'Draft')} selected={status==='Draft'}/>}
                  {userData.role === 'AMBASSADOR' ?  <CounterCard title={"Pending"} width={125} count={overView ? overView.pendingCampaigns.length : 0} clickHandler={(e) => handleClick(e,'Pending')} selected={status==='Pending'}/> :
                    <CounterCard title={"Created"} width={125} count={overView ? overView.createdCampaigns.length : 0} clickHandler={(e) => handleClick(e,'Created')} selected={status==='Created'}/>}
                  <CounterCard title={"Active"} width={125} count={overView ? overView.activeCampaigns.length : 0} clickHandler={(e) => handleClick(e,'Active')} selected={status==='Active'}/>
                  <CounterCard title={"Closed"} width={125} count={overView ? overView.closedCampaigns.length : 0} clickHandler={(e) => handleClick(e,'Closed')} selected={status==='Closed'}/>
                  {userData.role === 'AMBASSADOR' ?  null : <CounterCard title={"Attn. Needed"} width={150} count={overView ? overView.attnNeededCampaigns?.length || 0 : 0} clickHandler={(e) => handleClick(e,'Attn. Needed')}  selected={status==='Attn. Needed'}/>}
                </>)}
              </Flex>

            </Flex>
          </Flex>
        </CardHeader>

        {
          loading && (
            <Flex align="center" minWidth="100%" flexWrap="nowrap">
              <Box padding='6' boxShadow='lg' minWidth="100%">
                <SkeletonCircle size='20' />
                <SkeletonText mt='4' noOfLines={4} spacing='4' />
              </Box>
            </Flex>
          )
        }
        {!loading && (
          <CardBody  >
            {!!noCampaigns && (
              <Flex align="center" minWidth="100%" flexWrap="nowrap">
              <Box padding='6' minWidth="100%" justifyContent={"center"}>
                <Center>
              <Image
                src={NoCampaigns}
                maxW="40%"
                borderRadius="lg"
                fallbackSrc={placeholderImage(40)}
                boxShadow='xs'
              /></Center>
              </Box>
            </Flex>
            )}
            {!noCampaigns && dataSource && dataSource.length > 0 && (<>
              <ReactDataGrid
                className="brandbass-data-grid"
                style={{ minHeight: 860, borderColor: 'white' }}
                idProperty="id"
                columns={columns}
                dataSource={dataSource}
                pagination={true}
                defaultLimit={10}
                defaultSortInfo={defaultSortInfo}
                defaultLoading={loading}
                checkboxColumn={checkboxColumn}
                checkboxOnlyRowSelect={true}
                rowHeight={70}
                headerHeight={60}
                showZebraRows={false}
                enableSelection={true}
                showHoverRows={true}
                showCellBorders="horizontal"
                enableColumnAutosize={true}
                defaultFilterValue={defaultFilterValue}
                enableColumnFilterContextMenu={false}
                renderColumnContextMenu={null}
                emptyText="No campaigns"
              />
              <DeleteCampaignModal
                visible={showDeleteCampaignModal}
                closeHandler={(refresh) => {
                  setShowDeleteCampaignModal(false)
                  if (refresh) {
                    fetchAllCampaigns()
                  }
                }}
                dataHandler={(val) => updateCampaignDetails({ interest: val })}
                campaignId={deleteCampaignId}
              />
              <CloneCampaignModal
                visible={showCloneCampaignModal}
                closeHandler={(clonedCampaignId) => {
                  setShowCloneCampaignModal(false)
                  if (clonedCampaignId) {
                    navigate(`/edit/campaign/${clonedCampaignId}`)
                  }
                }}
                campaignId={cloneCampaignId}
              />
            </>)}

          </CardBody>
        )}
      </Card>

    </>
  );
};

export default CampaignList;

export const checkboxColumn = {
  renderCheckbox: (checkboxProps) => {
    const { onChange, checked } = checkboxProps
    return (
      <Box
        className="data-grid-checkbox"
        cursor='pointer'
        background={!!checked ? 'primary' : 'white'}
        borderRadius='4px'
        height='20px'
        width='20px'
        display='flex'
        alignItems='center'
        justifyContent='center'
        borderColor={!checked ? 'gray3' : 'primary'}
        borderWidth='2px'
        borderStyle='solid'
        fontSize={12}
        color={!checked ? 'primary' : 'white'}
        onClick={e => {
          e.stopPropagation();
          onChange(!checked);
        }}
      >
        {checked === false ? '' : checked === true ? <TickIcon /> : '--'}
      </Box>
    );

  }
}

export const DeleteCampaignModal = (props) => {
  const { visible, closeHandler, campaignId } = props
  const [showModal, setShowModal] = useState(false)
  const [deletable, setDeletable] = useState(null)
  const [message, setMessage] = useState('')
  const [refresh, setRefresh] = useState(false)
  const checkCampaignDetails = async () => {
    if (!campaignId || isNaN(campaignId)) {
      setDeletable(false)
    }
    const campaign = await getCampaign(campaignId);
    if (!campaign || !campaign.data || !campaign.data.id) {
      setDeletable(false)
    } else {
      setDeletable(campaign.data.payment?.length > 0 ? false : true)
    }
  };

  const deleteCampaignById = () => !campaignId || isNaN(campaignId)
    ? false
    : deleteCampaign(campaignId)
      .then(res => {
        if (res && !res.error && !!res.data) {
          setMessage('Deleted successfully')
          setRefresh(true)
        } else {
          setMessage('Deletion failed. Please contact support')
        }
      }).catch(err => {
        setMessage('Deletion failed. Please contact support')
        console.error(err)
      })

  useEffect(() => {
    setShowModal(!!visible)
    if (!!visible) checkCampaignDetails()

    return () => {
      setDeletable(null)
      setMessage('')
    }
  }, [visible])

  return !campaignId ? null : <>
    <Modal isOpen={showModal} onClose={() => closeHandler(refresh)} size="xl" scrollBehavior='outside' >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader color='brandbassBlue'>Delete Campaign</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <Flex p={8} borderColor='grayBlue' borderWidth="1px" borderRadius="lg" alignItems='center' justifyContent='center'>
            {
              deletable === null && <Spinner size='xl' />
            }
            {
              deletable === true &&
              <Text fontSize='20px'>
                {
                  message || `Are you sure you want to delete this Campaign?`
                }
              </Text>
            }
            {
              deletable === false &&
              <Text fontSize='20px' fontStyle='italic'>
                This campaign has been paid for and so cannot be deleted right now.
              </Text>
            }
          </Flex>
        </ModalBody>
        <ModalFooter>
          {
            deletable === true && !message && <>
              <Button
                colorScheme="brandbassBlue"
                variant="outline"
                borderRadius="lg" mr={3}
                onClick={() => closeHandler()}
              >
                Cancel
              </Button>
              <Button
                colorScheme="brandbassBlue"

                borderRadius="lg"
                onClick={deleteCampaignById}
              >
                Delete
              </Button>

            </>
          }
          {
            (deletable === false || !!message) && <>
              <Button
                colorScheme="brandbassBlue"
                borderRadius="lg"
                onClick={() => closeHandler(refresh)}
              >
                OK
              </Button>
            </>
          }
        </ModalFooter>
      </ModalContent>
    </Modal>
  </>
}

export const CloneCampaignModal = (props) => {
  const { visible, closeHandler, campaignId } = props
  const [showModal, setShowModal] = useState(false)
  const [clonable, setClonable] = useState(true)
  const [message, setMessage] = useState('')
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [newCampaignId, setNewCampaignId] = useState(null)

  const cloneCampaignHandler = (preserveCampaignCritera) => {
    setLoading(true);
    cloneCampaign({ parentId: campaignId, preserveCampaignCritera }).then(res => {
      setLoading(false)
      if (!res || !res.data || !res.data.id) setLoading(false);
      const newCampaignId = res.data.id
      setMessage('Duplicated successfully')
      setNewCampaignId(newCampaignId)
    }).catch(err => {
      setLoading(false);
    });
  }

  useEffect(() => {
    setShowModal(!!visible)

    return () => {
      setMessage('')
    }
  }, [visible])

  return !campaignId ? null : <>
    <Modal isOpen={showModal} onClose={() => closeHandler(newCampaignId)} size="xl" scrollBehavior='outside' >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader color='brandbassBlue'>Duplicate Campaign</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <Flex p={8} borderColor='grayBlue' borderWidth="1px" borderRadius="lg" alignItems='center' justifyContent='center'>
            {
              clonable === null && <Spinner size='xl' />
            }
            {
              clonable === true &&
              <Text fontSize='20px'>
                {
                  message || `Keep campaign criteria and get matched with new ambassador?`
                }
              </Text>
            }
          </Flex>
        </ModalBody>
        <ModalFooter>
          {
            loading ? <Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='brandbassBlue' size='xl' /> :
              clonable === true && !message && <>
                <Button
                  colorScheme="brandbassBlue"
                  variant="outline"
                  borderRadius="lg" mr={3}
                  onClick={() => closeHandler()}
                >
                  Cancel
                </Button>

                <Button
                  colorScheme="brandbassBlue"
                  mr={3}
                  borderRadius="lg"
                  onClick={() => cloneCampaignHandler(false)}
                >
                  No
                </Button>
                <Button
                  colorScheme="brandbassBlue"

                  borderRadius="lg"
                  onClick={() => cloneCampaignHandler(true)}
                >
                  Yes
                </Button>
              </>
          }
          {
            (clonable === false || !!message) && <>
              <Button
                colorScheme="brandbassBlue"
                borderRadius="lg"
                onClick={() => closeHandler(newCampaignId)}
              >
                OK
              </Button>
            </>
          }
        </ModalFooter>
      </ModalContent>
    </Modal>
  </>
}