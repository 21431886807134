
import { HeartIcon } from "components/Icons/Icons"
import { AppContext } from "contexts/AppContext"
import { useContext } from "react"
import InstagramLinkIcon from 'assets/svg/InstagramLinkIcon.svg'
import {
  Box,
  Button,
  Flex,
  Image,
  Modal,
  Tag,
  Text,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import ShowUserForm from '../../views/Dashboard/Users/components/ShowUser'
const AmbassadorCard = (props) => {
    const { placeholderImage, userData } = useContext(AppContext)
    const { invite, colorIndex } = props
    const { user } = invite
    const { id = '', imageUrl = '', profileImageUrl = '', firstName = '', lastName = '', instagramAccount = '', videoResumeUrl = '', tagLine = '', summary = '', logoUrl='' } = user
    const backgroundVariantIndex = colorIndex > 2 ? colorIndex % 3 : colorIndex
    const backgroundVariants = [
        `linear-gradient(4.29deg, #FDEDE5 7.36%, #FEF5F0 53.39%)`,
        `linear-gradient(1.51deg, #EBF1FF 5.34%, #F2F5FE 72.41%)`,
        `linear-gradient(15.95deg, #F1F8FE 15.52%, #ECF6FF 70.25%)`
    ]
    const [showAmbassador, setShowAmbassador] = useState(false);

    const openAmbassador = () => {
        setShowAmbassador(true);
      };
    return (!invite || !invite.user || !invite.user.id) ? null : <>
        <Box position='relative' w='230px' h='240px' borderRadius='30px' background={backgroundVariants[backgroundVariantIndex]}>
            {/* <HeartIcon cursor='pointer' background='transparent' position='absolute' top={30} right={25} /> */}
            <Flex flexDirection='column' alignItems='center' pt={30} gap='1rem'>
                <Box position='relative'>
                    <Image src={imageUrl || profileImageUrl || logoUrl} fallbackSrc={placeholderImage(80)} borderRadius='50%' w='80px' h='80px' objectFit='cover'/>
                    {/* <a target='_blank' href={instagramAccount ? `https://www.instagram.com/${instagramAccount}` : '#'}>
                        <Image src={InstagramLinkIcon} position='absolute' bottom={0} right={0} />
                    </a> */}
                </Box>
                <Box textAlign='center'>
                    <Text fontWeight='500' fontSize='16px' lineHeight='24px'>
                        {userData.role === 'AMBASSADOR' ? user.brandName : `${firstName} ${lastName}`}
                    </Text>
                    <Text fontWeight='400' fontSize='14px' lineHeight='24px' color='brandbassGray.500'>
                        {tagLine?.substr(0,20) || summary?.substr(0,20) || 'Ambassador'}
                    </Text>
                    {invite.inviteStatus ? <Tag variant='outline' fontWeight={'400'} fontSize='14px' lineHeight='24px' color='brandbassGray.500' mt={2}>
                        {invite.inviteStatus === 'Rejected'?'Declined':invite.inviteStatus}
                    </Tag> : null }
                </Box>
                <Button bgColor='white' borderRadius='2rem' onClick={() => openAmbassador(true)}>
                    Watch Video
                </Button>
            </Flex>
        </Box>
        <Modal size={'4xl'} isOpen={showAmbassador} onClose={() => setShowAmbassador(false)}>
            <ShowUserForm onClose={() => setShowAmbassador(false)} UserID={user.id} />
        </Modal>
    </>
}

export default AmbassadorCard