import ProjectList from "./components/ProjectList";

function Projects() {
  return <ProjectList
    title={"Projects"}
    captions={["Name", "Description", "Owner", "Members", "Status", "Date", ""]}
  />

}

export default Projects;
