// Chakra imports
import { ViewIcon } from "@chakra-ui/icons";
import {
  Text,
  Box, Tag, TagLabel,
  useColorModeValue, SkeletonCircle, SkeletonText,
  Modal, IconButton,
  Flex,
  Avatar, Spacer
} from "@chakra-ui/react";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import moment from "moment";
import React, { useEffect, useState, useContext, useCallback } from "react";
import { getBrands } from "RestAPI";
import { checkboxColumn } from "views/Dashboard/Campaign/CampaignList";
import { globalStyles } from "theme/styles";
import { useNavigate } from "react-router-dom";
import ShowUserForm from 'views/Dashboard/Users/components/ShowUser'
import { AppContext } from "contexts/AppContext";

const BrandsUsersList = ({ title, captions }) => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [brandsData, setBrandsData] = useState(null)
  const [showBrandDetails, setShowBrandDetails] = useState(null);
  const [userID, setUserID] = useState(null);
  const textColor = useColorModeValue("black3", "white");
  const {userData} = useContext(AppContext)

  const refreshList = ({ skip, limit, sortInfo, currentData, filterValue, groupBy }) => {
    const params = {}
    if (!isNaN(skip) && skip >= 0 && !!limit) {
      params.pagination = {
        perPage: limit,
        page: !skip ? 1 : (skip / limit) + 1
      }
    }
    if (!!sortInfo) {
      params.sort = {
        order: sortInfo.dir > 0 ? 'ASC' : 'DESC',
        field: sortInfo.id || 'id'
      }
    }
    if (!!filterValue && !!filterValue.length) {
      params.filter = {
        name: filterValue[0].value,
        brand: filterValue[1]?.value,
        email: filterValue[2]?.value
      }
    }
    // setLoading(true)
    return getBrands(params).then(response => {
      // setLoading(false);
      if (response && Array.isArray(response.data)) {
        response.data = response.data.map(brand => {
          brand.nameObj = { userName: brand.userName, profileImageUrl: brand.profileImageUrl, firstName: brand.firstName, lastName: brand.lastName }
          return brand
        })
        setBrandsData(response.data)
        return ({ data: response.data, count: response.total ? parseInt(response.total) : response.data.length || 0 })
      }
    }).catch(err => { setLoading(false); console.error(err) })
  }

  const defaultFilterValue = [
    { name: 'name', type: 'string', operator: 'contains', value: '' },
    { name: 'brand', type: 'string', operator: 'contains', value: '' },
    { name: 'email', type: 'string', operator: 'contains', value: '' }
  ];

  const dataSource = useCallback(refreshList, [])

  const columns = [
    {
      name: "id",
      type: "number",
      header: <Text color="gray3" textTransform='uppercase'>ID</Text>,
      defaultVisible: true,
      defaultFlex: 1,
      maxWidth: 40,
      sortable: true,
      render: ({ value }) => (
        <Text color="black3" fontSize='md' fontWeight="normal">
          {value}
        </Text>
      ),
    },
    {
      id: 'name',
      name: "nameObj",
      type: "string",
      header: <Text color="gray3" textTransform='uppercase'>User Name</Text>,
      defaultVisible: true,
      defaultFlex: 1,
      defaultWidth: 200,
      sortable: true,
      filterEditorProps: {
        style: {
          background: '#fefefe',
        },
        placeholder: 'Search by name...'
      },
      render: ({ value }) => (
        <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap" >
          <Avatar src={value.profileImageUrl} name={value.userName} w="50px" borderRadius="50px" me="18px" />
          <Flex flexDirection={'column'}>
            <Text color="black3" fontSize='md' fontWeight="normal">
              {value.firstName} {value.lastName}
            </Text>
            <Text color="gray" fontSize='sm' fontWeight="normal">
              {value.userName}
            </Text>
          </Flex>
        </Flex>
      ),
    },
    {
      name: "role",
      type: "string",
      header: <Text color="gray3" textTransform='uppercase'>Role</Text>,
      defaultVisible: true,
      defaultFlex: 1,
      maxWidth: 140,
      sortable: true,
      render: ({ value }) => (
        <Text color="black3" fontSize='xs' fontWeight="normal">
          {value?.replaceAll('_',' ')}
        </Text>
      ),
    },
    {
      name: "brand",
      type: "string",
      header: <Text color="gray3" textTransform='uppercase'>Brand</Text>,
      defaultVisible: true,
      defaultFlex: 1,
      defaultWidth: 100,
      sortable: true,
      filterEditorProps: {
        style: {
          background: '#fefefe',
        },
        placeholder: 'Search by brand...'
      },
      render: ({ value }) => (
        <Text color="black3" fontSize='md' fontWeight="normal">
          {value?.brandName || ''}
        </Text>
      )
    },
    {
      name: "email",
      type: "string",
      header: <Text color="gray3" textTransform='uppercase'>Email</Text>,
      defaultVisible: true,
      defaultFlex: 1,
      defaultWidth: 100,
      sortable: true,
      filterEditorProps: {
        style: {
          background: '#fefefe',
        },
        placeholder: 'Search by email...'
      },
      render: ({ value }) => (
        <Text color="black3" fontSize='md' fontWeight="normal">
          {value}
        </Text>
      )
    },
    // {
    //   name: "gender",
    //   type: "string",
    //   header: <Text color="gray3" textTransform='uppercase'>Gender</Text>,
    //   defaultVisible: true,
    //   defaultFlex: 1,
    //   defaultWidth: 100,
    //   sortable: true,
    //   render: ({ value }) => (
    //     <Text color="black3" fontSize='md' fontWeight="normal">
    //       {value}
    //     </Text>
    //   )
    // },
    {
      name: "status",
      header: <Text color="gray3" textTransform='uppercase'>STATUS</Text>,
      defaultVisible: true,
      defaultFlex: 1,
      maxWidth: 150,
      sortable: true,
      render: ({ value }) => {
        let bgColor = globalStyles.colors.statusGreen
        if (value === 'Created') {
          bgColor = globalStyles.colors.statusGreen
        } else if (value === 'Deleted') {
          bgColor = globalStyles.colors.statusRed
        }
        return (
          < Tag textAlign={'center'} minW={28} backgroundColor={bgColor}><Spacer /><TagLabel textAlign={'center'}>{value}</TagLabel><Spacer /></Tag>
        )
      },
    },
    {
      name: "createdOn",
      type: "date",
      header: <Text color="gray3" textTransform='uppercase'>Created on</Text>,
      defaultVisible: true,
      defaultFlex: 1,
      maxWidth: 100,
      sortable: true,
      render: ({ value }) => (
        <Text color="black3" fontSize='md' fontWeight="normal">
          {moment(value).format('MM-DD-YYYY')}
        </Text>
      ),
    },
    {
      header: <Text color="gray3" textAlign='center'>ACTION</Text>,
      defaultVisible: true,
      defaultFlex: 1,
      maxWidth: 75,
      textAlign: 'center',
      sortable: false,
      render: ({ data }) => <Flex gap='0.25rem' justifyContent="center" alignItems="center" >
        <IconButton onClick={() => { setShowBrandDetails(true); setUserID(data.id) }}
          variant='outline' size="xs"
          colorScheme='teal' borderRadius={"50%"}
          aria-label='view brand'
          icon={<ViewIcon />}
        />
      </Flex>
    },
  ]

  columns.forEach(item => item.style = { borderLeftColor: 'white', borderRightColor: 'white', borderTopColor: 'white', borderBottomColor: 'gray.200' })

  // useEffect(refreshList, [])

  return (
    <>
      <Modal size={'4xl'} isOpen={showBrandDetails} onClose={() => setShowBrandDetails(false)}>
        <ShowUserForm showControls={userData.role === 'SUPER_ADMIN'} onClose={() => setShowBrandDetails(false)} UserID={userID} />
      </Modal>
      <Card mt='5px' p='16px' overflowX={{ sm: "scroll" }} boxShadow='0px 0px 20px 1px rgb(0 0 0 / 10%)' borderRadius='10px'>
        <CardHeader p='12px 0px 28px 0px'>
          <Flex flex={1} align="center" gap='2rem' w="100%" ml={2} mr={2}>
            <Flex direction='column' gap='2rem' w="100%">
              <Flex gap='1rem' flexDirection='column'>
                <Text fontSize='30px' color={textColor} fontWeight='bold' pb='.5rem'>
                  Brand Users
                </Text>
              </Flex>
            </Flex>
          </Flex>
        </CardHeader>
        <CardBody >
          {
            loading && (
              <Flex align="center" minWidth="100%" flexWrap="nowrap">
                <Box padding='6' boxShadow='lg' minWidth="100%">
                  <SkeletonCircle size='20' />
                  <SkeletonText mt='4' noOfLines={4} spacing='4' />
                </Box>
              </Flex>
            )
          }
          {!loading && (
            <ReactDataGrid
              loading={brandsData === null}
              className="brandbass-data-grid"
              style={{ minHeight: 860, borderColor: 'white' }}
              idProperty="id"
              columns={columns}
              dataSource={dataSource}
              pagination={true}
              defaultLimit={10}
              defaultLoading={loading}
              defaultSortInfo={{ id: "id", name: 'id', dir: -1, type: 'number' }}
              checkboxColumn={checkboxColumn}
              checkboxOnlyRowSelect={true}
              rowHeight={70}
              headerHeight={60}
              showZebraRows={false}
              enableSelection={true}
              showHoverRows={true}
              showCellBorders="horizontal"
              enableColumnAutosize={true}
              defaultFilterValue={defaultFilterValue}
              enableColumnFilterContextMenu={false}
              emptyText="No Brands"
            />
          )}
        </CardBody>
      </Card>

    </>
  );
};

export default BrandsUsersList;
