import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Button, ButtonGroup, Flex, Text, CircularProgress, Spacer, Input } from "@chakra-ui/react"
import { useState, useContext } from "react"
import {updateSubmission} from "RestAPI"
import { AppContext } from "contexts/AppContext"
import moment from "moment"


const CampaignSubmissions = (props) => {
    const { userData } = useContext(AppContext)
    const { submissions, gigs, refreshCampaign} = props
    const [loading, setLoading] = useState(false)
    const [publishUrl, setPublishUrl] = useState('')

    const backgroundVariants = [
        `linear-gradient(4.29deg, #FDEDE5 7.36%, #FEF5F0 53.39%)`,
        `linear-gradient(1.51deg, #EBF1FF 5.34%, #F2F5FE 72.41%)`,
        `linear-gradient(15.95deg, #F1F8FE 15.52%, #ECF6FF 70.25%)`,
        `linear-gradient(171deg, rgba(238,174,202,1) 0%, rgba(148,187,233,1) 100%)`
    ]

    async function handleSubmissionAction(video, type){
        if(type === 'accept'){
          video.submissionStatus = 'Accepted';
          setLoading(true);
          const res = await updateSubmission(video);
          setLoading(false);
          if(res && res.data){
            refreshCampaign()
          }
        }else if(type == 'revision'){
          video.submissionStatus = 'In-Revision';
          setLoading(true);
          const res = await updateSubmission(video);
          setLoading(false);
          if(res && res.data){
            refreshCampaign();
          }
        }
    }

    async function handleDownload(gig, submission){    
        const video = await fetch(submission.signedUrl, {
            method: 'GET',
            headers: {
              'Content-Type': submission.mimeType
            }
        });
        const videoblob = await video.blob();
        const videoUrl = URL.createObjectURL(videoblob);
        
        const anchor = document.createElement("a");
        anchor.href = videoUrl;
        let extn = submission.s3Key.split('.')
        if(extn.length && extn[1]){
            extn = extn[1]
        }
        anchor.download = `${gig.gigName}_video.${extn}`;
        
        document.body.appendChild(anchor);
        anchor.click();
    }

    async function submitPublishGig(video){
        if(publishUrl.length){
          setLoading(true)
          video.gig.publishUrl = publishUrl;
          let res = await updateSubmission(video)
          setLoading(false)
          refreshCampaign()
        }
    }

    // map submissions and gigs in single array
    gigs.forEach(gig => gig.submissions = submissions.filter(submission => submission.gig.id === gig.id))

    return (!submissions || !submissions.length) ? null : <Flex flexDirection='column' gap='1rem'>
        <Accordion allowMultiple>
            {
                gigs.sort((a,b) => a.key-b.key).map((gig, idx) => <AccordionItem key={idx}>
                    <AccordionButton>
                        <Box flex='1' textAlign='left'>
                            <Flex>
                                <Text fontWeight='600' fontSize='18px'>${gig.gigPrice}</Text>
                                <Spacer/>
                                <Text fontWeight='400' fontSize='14px'>{gig.gigVariation}</Text>
                            </Flex>
                            <Text fontWeight='600' fontSize='18px' textTransform='capitalize'>
                                {gig.gigName}
                            </Text>
                            <Text fontWeight='500'> <em>{gig.gigVariation === 'Content' ? 'Video scenario' : 'Publish Channel'}</em>: {gig.gigVariation === 'Content' ? gig.videoScenario : gig.publishChannel} </Text>
                            <Text fontWeight='500'> <em>{gig.gigVariation === 'Content' ? 'Content settings' : 'Publish Time'}</em>: {gig.gigVariation === 'Content' ? `${gig.contentSettings}, ${gig.videoDuration}` : moment(gig.publishTime).format('DD MMM YYYY')} </Text>
                        </Box>
                        <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel pb={4}>
                        {
                            gig.submissions.map((submission, idx) => {
                                const backgroundVariantIndex = idx > (backgroundVariants.length - 1) ? idx % backgroundVariants.length : idx
                                return (
                                    <Flex
                                        flexDirection={{ base: 'column', lg: 'row', xl: 'column', xxl: 'row' }}
                                        gap='1rem'
                                        p='10px'
                                        key={idx}
                                        w='full'
                                        justifyContent='space-evenly'
                                        boxShadow='xs'
                                        background={backgroundVariants[backgroundVariantIndex]}
                                        alignItems='center'
                                    >
                                        <video
                                            controls
                                            style={{ maxWidth: 320, maxHeight: 240, minWidth: 320, minHeight: 240, boxShadow: '0px 0px 5px 1px grey' }}
                                            width="320"
                                            height="240"
                                            preload="metadata"
                                        >
                                            <source src={submission.signedUrl} type={submission.mimeType} />
                                            Your browser does not support the video tag.
                                        </video>
                                                    
                                        <Flex flexDirection='column' gap='0.5rem' maxW={{ base: '100%', xl: '50%' }} >
                                            <Flex maxH='400px' overflow='scroll' flexDirection='column' gap='0.5rem' >
                                                {submission.revision > 0 ? <Text fontWeight='500'> <em>Revision</em>: {submission.revision} </Text> : null }
                                                {gig.publishChannel ? !gig.publishUrl && userData.role === 'AMBASSADOR' ? <Input value={publishUrl} placeholder="Enter publish URL" onChange={(e) => setPublishUrl(e.target.value)}></Input> :
                                                 <Text>{gig.publishChannel} {gig.publishUrl || ''}</Text>
                                                : null}
                                                <span fontWeight='500'> <em>Status</em>:  <Text as='span' fontWeight='700'> {submission.submissionStatus} </Text> </span>
                                            </Flex>
                                            {userData.role === 'AMBASSADOR' ? submission.submissionStatus === 'Drafted' && gig.gigVariation === 'Publish' ? loading ? <CircularProgress value={80}/> : <Button colorScheme='blue' onClick={() => submitPublishGig(submission)}>Submit</Button> : null
                                             : 
                                            <ButtonGroup variant='outline' spacing='3'>
                                                {submission.submissionStatus === 'Accepted' || submission.submissionStatus === 'Drafted'? null : loading ? <CircularProgress value={80}/> : <Button colorScheme='blue' onClick={() => handleSubmissionAction(submission,'accept')}>Approve</Button>}
                                                {submission.submissionStatus === 'Accepted' || submission.submissionStatus === 'Drafted' ? null : loading ? <CircularProgress value={80}/> : <Button colorScheme='orange' onClick={() => handleSubmissionAction(submission,'revision')}>Revise</Button>}
                                                {submission.submissionStatus === 'Accepted' ? loading ? <CircularProgress value={80}/> : <Button colorScheme='blue' onClick={() => handleDownload(gig, submission)}>Download</Button> : null}                                                
                                                {/* {submission.submissionStatus === 'Accepted' ? loading ? <CircularProgress value={80}/> : <a href={submission.signedUrl} download="video.mp4" >Download</a> : null} */}
                                            </ButtonGroup>}
                                        </Flex>
                                    </Flex>
                                )
                            })
                        }
                    </AccordionPanel>
                </AccordionItem>
                )
            }
        </Accordion>
    </Flex>
}

export default CampaignSubmissions

/*
 {
                submissions.map((item, idx) => <Flex p='10px' key={idx} w='full' justifyContent='space-between' boxShadow='xs' bgColor='gray.100' alignItems='center'>
                    <Flex flexDirection='column' gap='0.5rem' maxW={{ base: '100%', xl: '50%' }} >
                        <Flex maxH='400px' overflow='scroll' flexDirection='column' gap='0.5rem' >
                            <Text fontWeight='600' fontSize='18px'>${item.gig.gigPrice}</Text>
                            <Text fontWeight='600' fontSize='18px' textTransform='capitalize'>
                                {item.gig.gigName}
                            </Text>
                            <Text fontWeight='500'> <em>Revision</em>: {item.revision} </Text>
                            <span fontWeight='500'> <em>Status</em>:  <Text as='span' fontWeight='700'> {item.submissionStatus} </Text> </span>

                            <Text fontWeight='500'> <em>Video scenario</em>: {item.gig.videoScenario} </Text>
                            <Text fontWeight='500'> <em>Content settings</em>: {`${item.gig.contentSettings}, ${item.gig.videoDuration}`} </Text>

                            <Flex flexDirection='column' >
                                <Text fontWeight='500' > <em>Requirements</em>: </Text>
                                <Text fontWeight='400' > {item.gig.gigRequirement} </Text>
                            </Flex>
                        </Flex>
                        <ButtonGroup variant='outline' spacing='6'>
                            <Button colorScheme='green'>Approve</Button>
                            <Button colorScheme='orange'>Revise</Button>
                            <Button colorScheme='red'>Reject</Button>
                        </ButtonGroup>
                    </Flex>

                    <video
                        controls
                        style={{ maxWidth: 320, maxHeight: 240, boxShadow: '0px 0px 5px 1px grey' }}
                        width="320"
                        height="240"
                        preload="metadata"
                    >
                        <source src={item.s3Key} type={item.mimeType} />
                        Your browser does not support the video tag.
                    </video>
                </Flex>)
            } 
*/