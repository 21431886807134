import { mode } from "@chakra-ui/theme-tools";

export const globalStyles = {
  colors: {
    gray: {
      700: "#1f2733", 
    },
    primary: '#032441',
    secondary:'#41A3F9',
    cta:'#2F80ED',
    success:'#27AE60',
    warning:'#E2B93B',
    error:'#EB5757',
    black1:'#000000',
    black2:'#1D1D1D',
    black3:'#282828',
    white:'#FFFFFF', 
    gray1:'#333333', 
    gray2:'#4F4F4F',
    gray3:'#828282',
    lightBlue:'#E2F1FF',
    statusGreen: '#6FCF97',
    statusYellow: '#f2f50d',
    statusBlue: '#4DA6F6',
    statusGray: '#CBD5E0',
    statusRed: '#f77e89',
    // grayBlue:'#E5E5E5',
    // darkBlue:'#092c4c',
    // gray:'#536b82',
    // cyanBlue: '#657b8f',
    gray4:'#E0E0E0',
    // gray5: '#9dabb7',
    // lightBlack:'#e1e1e1',
    // gray6:'#BDBDBD',
    // bbPeach: 'rgba(254, 245, 240, 1)',
    gray7: '#D2D1CD',
    gray8: '#E2E8F0',
    // pink: '#FF489F',
    brandbassBlue: {
      500: "rgb(3, 36, 65)", // "#032441",
      600: "rgba(3, 36, 65, 0.7)", // "#032441",
    },
    brandbassBlue2: {
      200: `rgba(226, 241, 255, 1)`,
      500: `rgba(77, 166, 246, 1)`, // `#4DA6F6`,
      600: `rgba(77, 166, 246, 0.7)`, // `#4DA6F6`,
    },
    brandbassPink: {
      500: `rgba(255, 72, 159, 1)`, 
      600: `rgba(255, 72, 159, 0.85)`, 
    },
    brandbassGray: {
      100: `rgba(196, 196, 196, 0.56)`,
      200: `rgba(215, 223, 233, 1)`,
      500: `rgba(126, 133, 142, 1)`,
      800: `rgba(72, 72, 72, 1)`,
    },
    brandbassPeach: {
      500: `rgba(253, 237, 229, 1)`
    },
    // bodyBG: `rgba(248,249,250,1)`
  },
  styles: {
    global: (props) => ({
      body: {
        // bg: mode("gray.50", "gray.800")(props),
        fontFamily: 'Open Sans'
      },
      html: {
        fontFamily: 'Open Sans'
      }
    }),
  },
};
