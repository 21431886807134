import Card from "components/Card/Card";
import { AppContext } from "contexts/AppContext";
import { useContext, useEffect, useState } from "react"
import { Text, Box, useColorModeValue, Flex, Spinner, Image, Skeleton, SkeletonCircle, SkeletonText, Spacer, } from "@chakra-ui/react";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import { GigIcon } from "components/Icons/Icons";
import moment from "moment";
import { getNotifications } from "RestAPI";
import BBButton from "components/Button/Button";
import { ChatIcon } from "components/Icons/Icons";
import { useNavigate } from "react-router-dom";
import { updateNotification } from "RestAPI";
import "./Notification.css"
import { globalStyles } from "theme/styles";

const NotificationList = (props) => {
    const { placeholderImage } = useContext(AppContext)
    const navigate = useNavigate()
    const textColor = useColorModeValue("black3", "white");
    const title = `Your Notifications`
    const [notificationListData, setnotificationListData] = useState([])
    const [allnotificationListData, setallnotificationListData] = useState([])
    const [loading, setLoading] = useState(true)

    const refreshList = () => {
        setLoading(true)
        getNotifications().then(response => {
            setLoading(false);
            if (response && response.data && response.data.length) {
                setnotificationListData(response.data)
                setallnotificationListData(response.data)
            }
        }).catch(err => { setLoading(false); console.error(err) })
    }

    const handleNotificationClick = async (item) => {
        if (item.status !== 'Seen') {
            setLoading(true)
            item.status = 'Seen';
            await updateNotification(item)
            setLoading(false)
        }
        if (item.module === 'Campaign' && item.eventType === "newMessage") {
            navigate(`/campaigns/${item.moduleId}`, { state: {displayConversation: true }})
        } else if (item.module === 'Campaign') {
            navigate(`/campaigns/${item.moduleId}`)
        } else if (item.module === 'Contract' && item.eventType === "newMessage") {
            navigate(`/contracts/${item.moduleId}`, { state: {displayConversation: true }})
        } else if(item.module === 'Contract'){
            navigate(`/contracts/${item.moduleId}`)
        } else if(item.eventType === 'newMessage'){
            navigate(`/conversations`,{state: {type:item.module.toLowerCase(),id:item.moduleId}})
        } 
    }

    const loadAll = () => setnotificationListData(allnotificationListData)
    const loadUnread = () => setnotificationListData(allnotificationListData.filter(item => item.status.toLowerCase() !== 'seen'))

    useEffect(refreshList, [])

    return <>
        <Card id="notification-list" mt='5px' p='16px' overflowX={{ sm: "scroll" }} boxShadow='0px 0px 20px 1px rgb(0 0 0 / 10%)' borderRadius='10px'>
            <CardHeader p='12px 0px 28px 0px'>
                <Flex flex={1} align="center" gap='2rem'>
                    <Flex direction='column' gap='2rem'>
                        <Flex gap='2rem' flexDirection='column' >
                            <Text fontSize='30px' color={textColor} fontWeight='500' pb='.5rem'>
                                {title}
                            </Text>
                            <Text fontSize='18px' color={textColor} fontWeight='500' pb='.5rem' fontStyle='italic'>
                                Here you can see all your notifications and manage them.
                            </Text>
                        </Flex>
                    </Flex>
                </Flex>
            </CardHeader>
            <CardBody>
                <Flex id='container' flex={1} w='100%' >
                    {
                        loading && (
                            <Flex align="center" minWidth="100%" flexWrap="nowrap">
                                <Box padding='6' boxShadow='lg' minWidth="100%">
                                    <SkeletonText mt='4' noOfLines={5} spacing='4' />
                                </Box>
                            </Flex>
                        )
                    }
                    {!loading && (<>
                        {
                            
                            <Flex flexDirection='column' gap='2rem' flex={1}>
                                <Flex gap='1rem'>
                                    <BBButton  onClick={loadAll}>
                                        All Notifications
                                    </BBButton>
                                    <BBButton variant='outline' onClick={loadUnread}>
                                        Unread Notifications
                                    </BBButton>
                                </Flex>
                                <Flex id='wrapper' w='full' flex={1} gap='1rem' justifyContent={'center'} >
                                    <Flex border='1px' borderColor='gray4'
                                        id='list'
                                        flexDirection='column'
                                        py={3}
                                        flex={{ base: 1, lg: 0.5 }}
                                        h='70vh'
                                        w={{base: '75%', lg: 'full'}}
                                        overflowY='scroll'
                                        borderRadius='1rem'
                                    >
                                    {!notificationListData || !notificationListData.length
                                        ?
                                        <Text fontSize='16px' fontWeight='600' color='black' textAlign={'center'} >
                                            No notifications
                                        </Text>
                                        :
                                            notificationListData.map((item, idx) => <Flex
                                                cursor='pointer'
                                                gap='1rem'
                                                key={idx}
                                                py={3}
                                                px={6}
                                                borderBottom='1px solid'
                                                borderColor='gray8'
                                                alignItems='center'
                                                backgroundColor={item.status.toLowerCase() !== 'seen' ? 'inherit' : 'inherit'} // highlight unread messages
                                            >
                                                <Box position='relative' w='80px' h='80px'>
                                                    <Image
                                                        src={item.imageUrl}
                                                        w='full'
                                                        h='full'
                                                        borderRadius='10px'
                                                        fallbackSrc={placeholderImage(80)}
                                                    />
                                                    {item.module?.toLowerCase().includes('campaign') ||  item.module?.toLowerCase().includes('user') ? <Flex
                                                        alignItems='center'
                                                        justifyContent='center'
                                                        bgColor='brandbassBlue.500'
                                                        borderRadius='full'
                                                        w='35px'
                                                        h='35px'
                                                        position='absolute'
                                                        bottom={0}
                                                        right={0}
                                                        transform='translateX(50%)'
                                                    >
                                                        {
                                                            item.eventType?.toLowerCase().includes('message')
                                                                ? <ChatIcon color='white' />
                                                                : <GigIcon color='white' />
                                                        }

                                                    </Flex>: null }
                                                </Box>
                                                <Flex justifyContent='space-between' alignItems='center' flex={1}>
                                                    <Flex flexDirection='column' ml='40px' justifyContent='center'>
                                                        <Text flexWrap={'wrap'} fontSize='16px' fontStyle='italic' fontWeight={item.status.toLowerCase() !== 'seen' ? 'bold' : 'normal'} color='brandbassBlue.500'>
                                                            {item.title}
                                                        </Text>
                                                        <Text style={{fontSize: 14, color: globalStyles.colors.gray3}}>
                                                            {moment(item.createdOn).fromNow()}
                                                        </Text>
                                                    </Flex>
                                                    <Spacer/>
                                                    {['campaign','contract'].includes(item.module?.toLowerCase()) || item.eventType.toLowerCase() === 'newmessage' ? <BBButton variant='outline' colorScheme='primary' onClick={() => handleNotificationClick(item)}>
                                                        View
                                                    </BBButton> :null }
                                                </Flex>
                                            </Flex>
                                            )
                                        }
                                    </Flex>
                                </Flex>
                            </Flex>
                        }
                    </>
                    )}
                </Flex>
            </CardBody>
        </Card>
    </>
}

export default NotificationList