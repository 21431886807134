import { AppContext } from "contexts/AppContext"
import { useContext, useState, useEffect } from "react";
import { useLocation, Navigate } from "react-router-dom";
import {getUserData} from "RestAPI"
import CheckPermission from "./CheckPermission";

const CheckAuth = ({ children, access }) => {
    const { userData, setUserData } = useContext(AppContext)
    const location = useLocation()
    const [loading, setLoading] = useState(null)
    const fromPage = location.pathname + window.location.search

    const goToRoute = () => <Navigate to="/auth" state={{ fromPage }} />

    const fetchUserData = async() => {
        if(!!userData && !!userData.id) return setLoading(false)

        const user = await getUserData()
        setLoading(false)
        
        if(!user?.data) return goToRoute()
        setUserData(user.data)
    }

    useEffect(fetchUserData, [userData])
    
    return (loading === null) 
    ? null 
    : !loading && !userData 
        ? goToRoute() 
        : ['create', 'edit'].includes(access) 
            ? <CheckPermission access={access}>{children}</CheckPermission> 
            : children
}

export default CheckAuth