// Chakra imports
import { Button, Flex, Text, useColorModeValue, Box } from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import InvoicesRow from "components/Tables/InvoicesRow";
import moment from "moment";
import React from "react";

const Invoices = ({ title, data }) => {
  const textColor = useColorModeValue("gray.700", "white");

  return (
    <Box mt={2} borderWidth={'1px'} borderColor={'solid'} borderRadius="lg" height={'600px'} overflowY={'scroll'} style={{scrollbarColor:'gray', scrollbarWidth:'10px', scrollbarGutter:'stable'}}>
    <Card
      p='16px'>
      {/* <CardHeader>
        <Flex justify='space-between' align='center' mb='1rem' w='100%'>
          <Text fontSize='lg' color={textColor} fontWeight='bold'>
            {title}
          </Text>
          <Button
            colorScheme='teal'
            borderColor='teal.300'
            color='teal.300'
            variant='outline'
            fontSize='xs'
            p='8px 32px'>
            VIEW ALL
          </Button>
        </Flex>
      </CardHeader> */}
      <CardBody>
        <Flex direction='column' w='100%'>
          {data.map((row) => {
            return (
              <InvoicesRow
                credits={row.creditcount}
                gigs={row.gigcount}
                name={row.name}
                date={moment(row.createdOn).format('MM-DD-YYYY')}
                code={row.stripeReferenceId}
                price={row.amount}
                logo={row.imageUrl}
                format={row.format}
              />
            );
          })}
        </Flex>
      </CardBody>
    </Card>
    </Box>
  );
};

export default Invoices;
