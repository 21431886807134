// Chakra imports
import { Box, Flex, Grid, Icon, Text } from "@chakra-ui/react";
// Assets
import BackgroundCard1 from "assets/img/BackgroundCard1.png";
import { MastercardIcon, VisaIcon } from "components/Icons/Icons";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { FaPaypal, FaWallet } from "react-icons/fa";
import { RiMastercardFill } from "react-icons/ri";
import { getCards } from "RestAPI";
import { getOrders } from "RestAPI";
import { getCreditBalance } from "RestAPI";
import {
  billingData,
  invoicesData,
  newestTransactions,
  olderTransactions,
} from "variables/general";
import { getFlag } from "../Campaign/PaymentModal";
import BillingInformation from "./components/BillingInformation";
import CreditCard from "./components/CreditCard";
import Invoices from "./components/Invoices";
import PaymentMethod from "./components/PaymentMethod";
import PaymentStatistics from "./components/PaymentStatistics";
import Transactions from "./components/Transactions";
import "./Billing.css"

function Billing() {
  const [creditBalance, setCreditBalance] = useState(0)
  const [totalAmount, setTotalAmount] = useState(0)
  const [monthAmount, setMonthAmount] = useState(0)
  const [orders, setOrders] = useState([])
  const [cards, setCards] = useState([])

  useEffect(async() => {
    let response = await getCreditBalance()
    if(response){
      setCreditBalance(response)
    }
    let orders = await getOrders()
    if(orders?.length){
      setTotalAmount(orders.reduce((sum, a) => Number(sum) + Number(a.budget ? a.budget : 0),0))
      console.log(moment().startOf('month'))
      setMonthAmount(orders.filter(o => moment(o.createdOn).format('YYYY-MM-DD') > moment().startOf('month').format('YYYY-MM-DD')).reduce((sum,a) => Number(sum) + Number(a.budget || 0), 0))
      setOrders(orders || [])
    }
    let cards = await getCards()
    if(cards && cards.data?.length){
      setCards(cards.data || [])
    }
  },[])

  return (
    <Flex id="billing" flexDirection={'column'} width={'80%'} margin={'auto'} boxShadow='0px 0px 20px 1px rgb(0 0 0 / 10%)' borderRadius='10px' p={16}>
      <Text fontSize='30px' color={'primary'} fontWeight='bold' pb='.5rem'>Billing</Text>
      <Text fontWeight='600' fontStyle='italic' fontSize='16px' lineHeight='24px'>Here you can review your orders and change the payment settings.</Text>
      <Flex direction='column' pt={{ base: "120px", md: "40px" }} >
        <Grid templateColumns={{ sm: "1fr", lg: "2fr" }} templateRows='1fr'>
          <Box>
            <Grid
              templateColumns={{
                sm: "1fr",
                md: "1fr 1fr",
                xl: "1fr 1fr 1fr",
              }}
              templateRows={{ sm: "auto auto auto", md: "1fr auto", xl: "1fr" }}
              gap={{base:'2px', lg: '20px'}}>
              <PaymentStatistics
                icon={<Icon h={"24px"} w={"24px"} color='white' as={FaWallet} />}
                title={"Credits"}
                description={"Remaining"}
                leftAmount={creditBalance.contentCredits || 0}
                specialAmount={creditBalance.userCredits || 0}
                specialLabel={'Users'}
                rightAmount={creditBalance.publishCredits || 0}
                leftLabel={'Content'}
                rightLabel={'Publish'}
              />
              <PaymentStatistics
                icon={<Icon h={"24px"} w={"24px"} color='white' as={FaPaypal} />}
                title={"Spending"}
                description={"Campaigns and projects"}
                leftAmount={totalAmount || 0}
                rightAmount={monthAmount || 0}
                leftLabel={'Overall'}
                rightLabel={'This month'}
              />              
            </Grid>
            <Flex mt={8}>
              <BillingInformation title={"Billing Information"} />
            </Flex>
            <Flex flexDirection='column' gap='1rem' mt={4}>
              <Text fontSize='lg' color={'primary'} fontWeight='bold'>
                Payment Methods
              </Text>
           
              
                <Grid
                  templateColumns={{sm: "1fr",md: "1fr 1fr",xl: "1fr 1fr",}}
                  // templateRows={{ sm: "auto auto auto", md: "1fr auto", xl: "1fr" }}
                  gap={{base:'2px', lg: '20px'}}>
                {/* <Flex justify='space-between'> */}
                {cards.map((savedCard, idx) =><Flex key={idx} borderWidth='1px' borderColor='primary' bgColor={'white'} p='0.9rem' rounded='lg' overflow='hidden' cursor='pointer' color={'primary'} justify='space-between'>
                  <Flex flexDirection='column'>
                    <Text fontFamily='Rubik' fontWeight='500'>
                      xxxx xxxx xxxx {savedCard.card.last4}
                    </Text>
                    <Text fontFamily='Rubik' fontWeight='500' textTransform='uppercase'>
                      {savedCard.card.exp_month.toString().padStart(2, "0")}/{savedCard.card.exp_year.toString().slice(-2)}
                    </Text>
                  </Flex>
                  <Flex flexDirection='column'>
                    <Text fontFamily='Rubik' fontWeight='500' textTransform='uppercase'>
                      {savedCard.card.brand}
                    </Text>
                    <Text  fontFamily='Rubik' fontWeight='500' textTransform='uppercase' fontSize='2rem'>
                      {getFlag(savedCard.card.country)}
                    </Text>
                  </Flex>
                  </Flex>
                )}
                {/* </Flex> */}
                </Grid>
             
            
            </Flex>
            {/* <PaymentMethod
              title={"Payment Method"}
              cards={cards}
              mastercard={{
                icon: <MastercardIcon w='100%' h='100%' />,
                number: "7812 2139 0823 XXXX",
              }}
              visa={{
                icon: <VisaIcon w='100%' h='100%' />,
                number: "7812 2139 0823 XXXX",
              }}
            /> */}
            <Text mt={8} fontSize='lg' color={'primary'} fontWeight='bold'>
              Orders
            </Text>
            <Invoices title={"Orders"} data={orders} />
          </Box>
          
        </Grid>
      </Flex>
    </Flex>

  );
}

export default Billing;
