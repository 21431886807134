import {
    ChakraProvider, Flex, Portal, useDisclosure,
    Modal, ModalBody, ModalCloseButton, ModalContent,
    ModalFooter, ModalHeader, ModalOverlay, Text, useToast
} from "@chakra-ui/react";

import { AppContext } from "contexts/AppContext";
import { useContext, useState, useEffect } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import theme from "theme/theme";
import Sidebar from "components/Sidebar";
import MainPanel from "components/Layout/MainPanel";
import AdminNavbar from "components/Navbars/AdminNavbar";
import PanelContent from "components/Layout/PanelContent";
import PanelContainer from "components/Layout/PanelContainer";
import routes from "routes.js";
import BBButton from "components/Button/Button";
import { BBSecondaryButtonWithoutHover } from "components/Button/Button";
import { deleteTeam } from "RestAPI";
import { deleteProject, deleteIssue, deleteGroup, deleteUser, deleteBrand, deleteContract } from "RestAPI";
export default function Dashboard(props) {
    const { ...rest } = props;
    const location = useLocation();
    const [sidebarVariant, setSidebarVariant] = useState("transparent");
    const [fixed, setFixed] = useState(false);
    const [breadcrumbPath, setBreadcrumbPath] = useState(null);
    const { userData } = useContext(AppContext);
    const fromPage = location.state && location.state.fromPage && location.state.fromPage.pathname ? location.state.fromPage.pathname : "/";
    const { isOpen, onOpen, onClose } = useDisclosure();

    useEffect(() => {
        setBreadcrumbPath(location)
    }, [location.pathname]);
    const dashRoutes = !userData?.role ? [] : routes.filter(item => !item.access || item.access.includes(userData.role))

    if (!userData || !userData.id) { return <Navigate to='/auth' replace={true} state={{ fromPage: window.location.pathname + window.location.search }} /> }
    return (
        <ChakraProvider theme={theme} resetCss={false} w="100%">
            <Sidebar
                routes={dashRoutes}
                logoText={"Brandbass"}
                display="none"
                sidebarVariant={sidebarVariant}
                {...rest}
            />
            <MainPanel
                w={{
                    base: "100%",
                    xl: "calc(100% - 275px)",
                }}
            >
                <Portal >
                    <AdminNavbar
                        onOpen={onOpen}
                        logoText={"Brandbass"}
                        // brandText={'getActiveRoute(routes)'}
                        // secondary={getActiveNavbar(routes)}
                        fixed={fixed}
                        {...rest}
                    />
                </Portal>

                <PanelContent>
                    <PanelContainer mt='64px'>
                        {/* <Flex
                            pe={{ sm: "0px", md: "16px" }}
                            w={{ sm: "100%", md: "auto" }}
                            alignItems="left"
                            flexDirection="row"
                            mb={3}
                        >
                            <NextBreadcrumbs locationId={breadcrumbPath}/>
                        </Flex> */}
                        <Outlet />
                    </PanelContainer>
                </PanelContent>

            </MainPanel>
        </ChakraProvider>
    );
}

export const DeleteEntityModal = (props) => {
    const toast = useToast()
    const { visible, closeHandler, entityId, entity } = props
    const [showModal, setShowModal] = useState(false)
    const entities = ['project', 'team', 'group', 'user', 'issue', 'brand','contract']
    const deleteEntityById = () => {
        if (!entityId || isNaN(entityId)) return closeHandler()
        if (!(entities.includes(entity?.toLowerCase()))) return closeHandler()
        let deleteCall = () => { }

        switch (entity) {
            case 'project':
                deleteCall = deleteProject(entityId)
                break;
            case 'team':
                deleteCall = deleteTeam(entityId)
                break;
            case 'group':
                deleteCall = deleteGroup(entityId)
                break;
            case 'user':
                deleteCall = deleteUser(entityId)
                break;
            case 'issue':
                deleteCall = deleteIssue(entityId)
                break;
            case 'brand':
                deleteCall = deleteBrand(entityId)
            case 'contract':
                deleteCall = deleteContract(entityId)
            default:
                return closeHandler();
        }

        return deleteCall.then(res => {
            if(res.data && res.data.error){
                toast({
                    title: `Error while deleting ${entity}`,
                    description: res.data.error,
                    status: 'warning',
                    position: 'top',
                    duration: 3000,
                    isClosable: true,
                })
            }else{
                toast({
                    title: `${entity.toUpperCase()} deleted successfully`,
                    description: "",
                    status: 'success',
                    position: 'top',
                    duration: 3000,
                    isClosable: true,
                })
            }
            closeHandler(true)
        }).catch(err => {
            toast({
                title: `An error occured when trying to delete the ${entity.toUpperCase()}.`,
                description: "Please try after sometime or create an issue",
                status: 'error',
                position: 'top',
                duration: 3000,
                isClosable: true,
            })
            closeHandler()
        })
    }

    useEffect(() => setShowModal(!!visible), [visible])

    return !entityId ? null : <>
        <Modal isOpen={showModal} onClose={closeHandler} size="xl" scrollBehavior='outside' >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader color='brandbassBlue'>Delete</ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={6}>
                    <Flex p={8} borderColor='grayBlue' borderWidth="1px" borderRadius="lg" alignItems='center' justifyContent='center'>
                        <Text fontSize='20px'>
                            {
                                `Are you sure you want to delete this ${entity}?`
                            }
                        </Text>
                    </Flex>
                </ModalBody>
                <ModalFooter>

                    <BBSecondaryButtonWithoutHover
                        colorScheme="brandbassBlue"
                        variant="outline"
                        borderRadius="lg" mr={3}
                        onClick={closeHandler}
                    >
                        Cancel
                    </BBSecondaryButtonWithoutHover>
                    <BBButton
                        colorScheme="brandbassBlue"
                        borderRadius="lg"
                        onClick={deleteEntityById}
                    >
                        Delete
                    </BBButton>
                </ModalFooter>
            </ModalContent>
        </Modal>
    </>
}