import { Box, ChakraProvider, Flex, Image, Text } from "@chakra-ui/react";
import { AppContext } from "contexts/AppContext";
import { useContext } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import theme from "theme/theme";
import BrandBassShortIcon from 'assets/svg/brandbass-short-logo.svg'
import BrandBassIcon from 'assets/svg/brandbass-logo.svg'
import LightBulbIcon from 'assets/svg/light-bulb.svg'
import ProfileIcon from 'assets/svg/person-auth.svg'
import HomeIcon from 'assets/svg/home-dark.svg'
import {getUserData} from "RestAPI"

export default function Login(props) {
    const location = useLocation();
    const { userData, setUserData } = useContext(AppContext);
    const fromPage = location.state && location.state.fromPage && location.state.fromPage ? location.state.fromPage : "/";
    const brandSubdomain = window.location.hostname.split('.')[0]?.toLowerCase() || ''
    const isPremium = brandSubdomain === 'dashboard' || brandSubdomain === 'dashboard-prod' || brandSubdomain === 'admin' ? false : true

    if (!userData || !userData.id) {
        getUserData()
        .then(res => {
            if(res && res.data && res.data.id){
                setUserData(res.data)
            }
        })
    }
    if (userData && userData.id && ((!!userData.brand && !!userData.brand.id && !!(userData.brand.subdomain || !isPremium)) || 
        (userData.role.toLowerCase() === 'ambassador' || userData.role.toLowerCase() === 'super_admin' || userData.role === 'CLIENT_SUPER_ADMIN'))) {
        return <Navigate to={fromPage} replace={true} /> 
    }
    if (userData && userData.id) { return <Navigate to='/new/brand-registration' replace={true} /> }

    return (
        <ChakraProvider theme={theme} resetCss={false} w="100%">
            <Flex flexDirection='column' minH='100vh'>
                <Box  zIndex={0}>
                    <Outlet />
                </Box>
            </Flex>
        </ChakraProvider>
    );
}
