// Chakra imports
import { EditIcon, ViewIcon } from "@chakra-ui/icons";
import {
  Text,
  Box, Tag, TagLabel,
  useColorModeValue, SkeletonCircle, SkeletonText,
  Modal, IconButton,
  Flex,
  Avatar, Spacer
} from "@chakra-ui/react";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import moment from "moment";
import React, { useEffect, useState, useContext } from "react";
import { getAllBrands } from "RestAPI";
import { checkboxColumn } from "views/Dashboard/Campaign/CampaignList";
import { globalStyles } from "theme/styles";
import { useNavigate } from "react-router-dom";
import ShowUserForm from 'views/Dashboard/Users/components/ShowUser'
import BrandDetails from "views/Dashboard/Brands/BrandDetails";
import { AppContext } from "contexts/AppContext";
import { DeleteEntityModal } from "layouts/Dashboard";
import { TrashIcon } from "components/Icons/Icons";

const BrandsUsersList = ({ title, captions }) => {
  const {userData} = useContext(AppContext)
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)
  const [brandsData, setBrandsData] = useState(null)
  const [showBrandDetails, setShowBrandDetails] = useState(null);
  const [BrandID, setBrandID] = useState(null);
  const textColor = useColorModeValue("black3", "white");
  const [deleteModal, setDeleteModal] = useState(false)

  const refreshList = () => {
    setLoading(true)
    getAllBrands({ role: 'SUPER_ADMIN' }).then(response => {
      setLoading(false);
      if (response && Array.isArray(response.data) && !!response.data.length) {
        response.data = response.data.map(brand => {
          brand.nameObj = { brandName: brand.brandName, logoUrl: brand.logoUrl }
          return brand
        })
        setBrandsData(response.data)
      }
    }).catch(err => { setLoading(false); console.error(err) })
  }

  const columns = [
    {
      name: "id",
      type: "number",
      header: <Text color="gray3" textTransform='uppercase'>ID</Text>,
      defaultVisible: true,
      defaultFlex: 1,
      maxWidth: 40,
      sortable: true,
      render: ({ value }) => (
        <Text color="black3" fontSize='md' fontWeight="normal">
          {value}
        </Text>
      ),
    },
    {
      name: "nameObj",
      type: "string",
      header: <Text color="gray3" textTransform='uppercase'>Brand Name</Text>,
      defaultVisible: true,
      defaultFlex: 1,
      minWidth: 175,
      sortable: true,
      filterEditorProps: {
        style: {
          background: '#fefefe',
        },
        placeholder: 'Search by name...'
      },
      render: ({ value }) => (
        <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap" >
          <Avatar src={value.logoUrl} name={value.brandName} w="50px" borderRadius="50px" me="18px" />
          <Text color="gray" fontSize='sm' fontWeight="normal">
              {value.brandName}
            </Text>
        </Flex>
      ),
    },
    {
      name: "subdomain",
      type: "string",
      header: <Text color="gray3" textTransform='uppercase'>SubDomain</Text>,
      defaultVisible: true,
      defaultFlex: 1,
      maxWidth: 140,
      sortable: true,
      render: ({ value }) => (
        <Text color="black3" fontSize='xs' fontWeight="normal">
          {value}
        </Text>
      ),
    },
    {
      name: "website",
      type: "string",
      header: <Text color="gray3" textTransform='uppercase'>Website</Text>,
      defaultVisible: true,
      defaultFlex: 1,
      defaultWidth: 100,
      sortable: true,
      render: ({ value }) => (
        <Text color="black3" fontSize='xs' fontWeight="normal">
          {value}
        </Text>
      )
    },
    {
      name: "email",
      type: "string",
      header: <Text color="gray3" textTransform='uppercase'>Email</Text>,
      defaultVisible: true,
      defaultFlex: 1,
      defaultWidth: 100,
      sortable: true,
      render: ({ value }) => (
        <Text color="black3" fontSize='xs' fontWeight="normal">
          {value}
        </Text>
      )
    },
    {
        name: "instagram",
        type: "string",
        header: <Text color="gray3" textTransform='uppercase'>Instagram</Text>,
        defaultVisible: true,
        defaultFlex: 1,
        defaultWidth: 100,
        sortable: true,
        render: ({ value }) => (
          <Text color="black3" fontSize='xs' fontWeight="normal">
            {value}
          </Text>
        )
      },
      {
        name: "facebook",
        type: "string",
        header: <Text color="gray3" textTransform='uppercase'>facebook</Text>,
        defaultVisible: true,
        defaultFlex: 1,
        defaultWidth: 100,
        sortable: true,
        render: ({ value }) => (
          <Text color="black3" fontSize='xs' fontWeight="normal">
            {value}
          </Text>
        )
      },
      {
        name: "averageRating",
        header: <Text color="gray3" textTransform='uppercase'>Rating</Text>,
        defaultVisible: userData.role === 'SUPER_ADMIN' ? true : false,
        defaultFlex: 1,
        maxWidth: 75,
        sortable: true,
        render: ({ value }) => {
          return (
            <Text color="black3" fontSize='md' fontWeight="normal">
            {value}
          </Text>
          )
        },
      },
      {
        name: "creditCount",
        header: <Text color="gray3" textTransform='uppercase'>Credits</Text>,
        defaultVisible: userData.role === 'SUPER_ADMIN' ? true : false,
        defaultFlex: 1,
        maxWidth: 75,
        sortable: true,
        render: ({ value }) => {
          return (
            <Text color="black3" fontSize='md' fontWeight="normal">
            {value}
          </Text>
          )
        },
      },
      {
        name: "brandbassComission",
        header: <Text color="gray3" textTransform='uppercase'>Comission</Text>,
        defaultVisible: userData.role === 'SUPER_ADMIN' ? true : false,
        defaultFlex: 1,
        maxWidth: 75,
        sortable: true,
        render: ({ value }) => {
          return (
            <Text color="black3" fontSize='md' fontWeight="normal">
            {value}
          </Text>
          )
        },
      },
    {
      name: "createdOn",
      type: "date",
      header: <Text color="gray3" textTransform='uppercase'>Created on</Text>,
      defaultVisible: true,
      defaultFlex: 1,
      maxWidth: 100,
      sortable: true,
      render: ({ value }) => (
        <Text color="black3" fontSize='md' fontWeight="normal">
          {moment(value).format('MM-DD-YYYY')}
        </Text>
      ),
    },
    {
      header: <Text color="gray3" textAlign='center'>ACTION</Text>,
      defaultVisible: true,
      defaultFlex: 1,
      maxWidth: 120,
      textAlign: 'center',
      sortable: false,
      render: ({ data }) => <Flex gap='0.25rem' justifyContent="center" alignItems="center" >
        <IconButton onClick={() => { setShowBrandDetails(true); setBrandID(data.id) }}
          variant='outline' size="xs"
          colorScheme='teal' borderRadius={"50%"}
          aria-label='view brand'
          icon={<ViewIcon />}
        />
        {userData.role === 'SUPER_ADMIN' ? <IconButton onClick={() => { navigate(`/edit/brand-registration/${data.id}`)}}
          variant='outline' size="xs"
          colorScheme='teal' borderRadius={"50%"}
          aria-label='view brand'
          icon={<EditIcon />}
        /> : null}
        {userData.role === 'SUPER_ADMIN' && !data.deletedOn ? <IconButton onClick={() => { setBrandID(data.id); setDeleteModal(true) }}
          variant='outline' size="xs"
          colorScheme='teal' borderRadius={"50%"}
          aria-label='view brand'
          icon={<TrashIcon />}
        /> : null}
      </Flex>
    },
  ]

  columns.forEach(item => item.style = { borderLeftColor: 'white', borderRightColor: 'white', borderTopColor: 'white', borderBottomColor: 'gray.200' })

  const filterTypes = Object.assign({}, ReactDataGrid.defaultProps.filterTypes, {
    brand: {
      name: 'brand',
      emptyValue: null,
      operators: [
        {
          name: 'contains',
          fn: ({ value, filterValue, data }) => {
            const match = value.brandName?.toLowerCase().indexOf(filterValue.toLowerCase()) !== -1 
            return !filterValue ? true : match                  
          }
        }
      ]
    }
  });

  useEffect(refreshList, [])

  return (
    <>
      <Modal size={'4xl'} isOpen={showBrandDetails} onClose={() => setShowBrandDetails(false)}>
        <BrandDetails onClose={() => setShowBrandDetails(false)} BrandID={BrandID} />
      </Modal>
      <DeleteEntityModal
          visible={deleteModal} 
          closeHandler={(refresh) => {
              refreshList()
              setDeleteModal(false)
          }} 
          entity='brand'
          entityId={BrandID}
      />
      <Card mt='5px' p='16px' overflowX={{ sm: "scroll" }} boxShadow='0px 0px 20px 1px rgb(0 0 0 / 10%)' borderRadius='10px'>
        <CardHeader p='12px 0px 28px 0px'>
          <Flex flex={1} align="center" gap='2rem' w="100%" ml={2} mr={2}>
            <Flex direction='column' gap='2rem' w="100%">
              <Flex gap='1rem' flexDirection='column'>
                <Text fontSize='30px' color={textColor} fontWeight='bold' pb='.5rem'>
                  Brands
                </Text>
              </Flex>
            </Flex>
          </Flex>
        </CardHeader>
        <CardBody >
          {
            loading && (
              <Flex align="center" minWidth="100%" flexWrap="nowrap">
                <Box padding='6' boxShadow='lg' minWidth="100%">
                  <SkeletonCircle size='20' />
                  <SkeletonText mt='4' noOfLines={4} spacing='4' />
                </Box>
              </Flex>
            )
          }
          {!loading && (
            <ReactDataGrid
              loading={brandsData === null}
              className="brandbass-data-grid"
              style={{ minHeight: 860, borderColor: 'white' }}
              idProperty="id"
              columns={columns}
              dataSource={brandsData || []}
              pagination={true}
              defaultLimit={10}
              defaultLoading={loading}
              defaultSortInfo={{ id: "id", name: 'id', dir: -1, type: 'number' }}
              checkboxColumn={checkboxColumn}
              checkboxOnlyRowSelect={true}
              rowHeight={70}
              headerHeight={60}
              showZebraRows={false}
              enableSelection={true}
              showHoverRows={true}
              showCellBorders="horizontal"
              enableColumnAutosize={true}
              defaultFilterValue={[{ name: 'nameObj', type: 'brand', operator: 'contains', value: '' }]}
              filterTypes={filterTypes}
              enableColumnFilterContextMenu={false}
              emptyText="No Brands"
            />
          )}
        </CardBody>
      </Card>

    </>
  );
};

export default BrandsUsersList;
