// Chakra imports
import {
    FormControl,
    Flex,
    useToast,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Box,
    ModalFooter,
    Text
  } from "@chakra-ui/react";
  import React, { useEffect, useState } from "react";
  import { getAllCampaigns,createProject } from "RestAPI";
  import { useLocation, useNavigate } from "react-router-dom";
  import BBAutocomplete from "components/Autocomplete/Autocomplete";
  import BBButton from "components/Button/Button";
  import { FaPlus } from "react-icons/fa"

  const CreateCampaignForm = (props) => {
    const location = useLocation()
    const {project,campaignSet} = props
    const [campaigns, setCampaigns] = useState([])
    const [submit, setSubmit] = useState(false)
    const [selectedCampaigns, setSelectedCampaigns] = useState([])
    const [loading, setLoading] = useState(false)

    const navigate = useNavigate()
    const toast = useToast()
    const fetchCampaigns = async () => {
        const campaigns = await getAllCampaigns();
        setCampaigns(campaigns.data);
    };
  
    useEffect(() => {
        fetchCampaigns()
    }, []);

    const getSelectedCampaigns = (items) => {
      let userIds = items.map(i => i.originalValue?.id)
      let data = campaigns.filter(item => userIds.includes(item.id) );
      setSelectedCampaigns(data.map(p => { return { id: p.id, value: p.name, name: p.name,imageUrl:p.imageUrl} }))
    }

    const saveCampaign = ()=>{
      setSubmit(true);
      if (!selectedCampaigns.length) {
        return;
      }
      setLoading(true)
      if (selectedCampaigns.length) {
          if(project.campaignSets && !!project.campaignSets.length){
            project.campaignSets.map(campaign=>{
              if(campaign.id === campaignSet.id){
                campaign.campaigns =  campaign.campaigns.concat(selectedCampaigns)
              }
              return campaign
            })
          }
      }
      createProject({ id: project ? project.id : null,...project })
        .then(response => {
          toast({
            title: `Campaigns created successfully`,
            description: "",
            status: 'success',
            position: 'top',
            duration: 3000,
            isClosable: true,
          })
          setLoading(false)
          props.fetchProjectDetails()
          props.onClose()
        }).catch(err => {
          toast({
            title: `An error occured when saving the Campaign Set.`,
            description: "Please try after sometime or create an issue",
            status: 'error',
            position: 'top',
            duration: 3000,
            isClosable: true,
          })
          setLoading(false)
        })
    }

    return (
      <>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader color='brandbassBlue'>Add Campaign <ModalCloseButton /></ModalHeader>
          <ModalBody pb={6}>
            <Box>
              <Flex flexDirection="column" pt={"10px"} gap={10}>
                <Flex flexDirection="column" >
                  <FormControl isRequired>
                  <BBAutocomplete  
                       options={campaigns.map(p => { return { id: p.id, value: p.name, name: p.name,imageUrl:p.imageUrl} })}
                       isMultiple={true} 
                       label='Select Existing Campaign'
                       onChange={(value, items) => getSelectedCampaigns(items)}/>            
                    </FormControl>
                </Flex>
                <Text>OR</Text>
                <Flex flexDirection="column">
                  <FormControl isRequired>
                      <BBButton leftIcon={<FaPlus />} onClick={()=> navigate('/new/campaign',{state:{project:props.project,campaignSet:props.campaignSet,navigateTo:'projectView'}})}>Create Campaign</BBButton>            
                    </FormControl>
                </Flex>
              </Flex>
            </Box>
          </ModalBody>
          <ModalFooter>
            <BBButton ml={4} mr={3}
              variant='outline'
              onClick={props.onClose}
            >
              Cancel
            </BBButton>
            <BBButton onClick={saveCampaign} isLoading={loading} loadingText="Saving...">
              Save
            </BBButton>
          </ModalFooter>
        </ModalContent>
      </>
    );
  };
  
  export default CreateCampaignForm;
  
  