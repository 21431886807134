// Chakra imports
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Checkbox,
  Stack,
  Flex,
  IconButton,
  useToast,
  Avatar,
  Text,
  Box,
  TableContainer,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Table
} from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { getAllBrands, createCredit, getCreditPaymentObject, getContractCreditPaymentObject, updatePaymentStatus } from "RestAPI";
import { FormHeader } from "layouts/FormLayout";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import BBInput from "components/Input/Input";
import BBTextarea from "components/Textarea/Textarea";
import BBDropdown from "components/Dropdown/Dropdown";
import { AppContext } from "contexts/AppContext";
import { CreditPaymentModal } from "../Campaign/PaymentModal";
import BBAutocomplete from "components/Autocomplete/Autocomplete";
import { getBrandContracts } from "RestAPI";

const CreditForm = ({ onClose, refreshList }) => {
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [saveReady, setSaveReady] = useState(false)
  const [brandsList, setBrandList] = useState([]);
  const [creditType, setCreditType] = useState("");
  const [gigVariation, setGigVariation] = useState("");
  const [gigVariationType, setGigVariationType] = useState("");
  const [notes, setNotes] = useState("");
  const [numCredits, setNumCredits] = useState("");
  const [brandID, setBrandID] = useState("");  
  const [searchParams] = useSearchParams()
  const cType = searchParams.get('type')
  const [campaignType, setCampaignType] = useState(cType?cType:'Public')
  const [contract, setContract] = useState(null)
  const [ambassador, setAmbassador] = useState(null)
  const [contracts, setContracts] = useState([])
  const [contractItems, setContractItems] = useState([])
  const navigate = useNavigate();
  const toast = useToast();
  const {userData} = useContext(AppContext)
  const [showCheckoutModal, setShowCheckoutModal] = useState(false)
  const [payment, setPayment] = useState({})  
  const [creditList, setCreditList] = useState([])
  const paymentIntentFromStripe = searchParams.get('payment_intent')
  const pId = searchParams.get('paymentId')
  const stripeRedirect = searchParams.get('redirect_status')
  const minQuantityPercentage = userData.brand && userData.brand.contractMinQuantityPercentage ? userData.brand.contractMinQuantityPercentage : 30
  const brandbassComission = userData.brand && userData.brand.brandbassComission ? userData.brand.brandbassComission : 40
  const [totalAmount, setTotalAmount] = useState(0)  

  const handleSubmit = async() => {
    setSubmit(true);
    if (campaignType === 'Public' && 
      (!gigVariation ||
      !gigVariationType ||
      !notes ||
      !numCredits ||
      (!brandID && userData.role === 'SUPER_ADMIN'))
    ) {
      return;
    }
    if(campaignType === 'Exclusive' && (!contract || !ambassador || !totalAmount)){
      if(!totalAmount){
        toast({
          title: `Select quantity`,
          description: "",
          status: "warning",
          position: "top",
          duration: 3000,
          isClosable: true,
        })
      }else{
        toast({
          title: `Select contract to proceed`,
          description: "",
          status: "warning",
          position: "top",
          duration: 3000,
          isClosable: true,
        })
      }
      return;
    }
    setLoading(true)
    if(userData.role === 'SUPER_ADMIN'){
      saveCreditForm()
    }else{
      let response = campaignType === 'Public' ? await getCreditPaymentObject({gigVariation,gigVariationType,numCredits}) : await getContractCreditPaymentObject(contractItems)
      if(response){
        setPayment(response.payment)
        setCreditList(response.creditList)
      }
      localStorage.setItem('credit',JSON.stringify({campaignType, creditType, gigVariation, gigVariationType, notes, numCredits, payment: response.payment, contractItems, contract, ambassador}))
      setShowCheckoutModal(true)
    }
    setLoading(false)
  }

  const saveCreditForm = () => {
    setLoading(true);
    
    let body = []

    if(campaignType === 'Exclusive'){
      contractItems.forEach(item => {
        let credit = {}
        credit.brand = { id: userData.role === 'SUPER_ADMIN' ? brandID : userData.brand.id}
        credit.gigVariation = item.itemType 
        credit.gigVariationType = 'Video'
        credit.notes = notes
        credit.numCredits = item.creditCount
        credit.payment = {id: payment.paymentId}
        credit.campaignType = campaignType
        credit.contract = contract
        credit.ambassador = ambassador
        if(item.creditCount > 0){
          body.push(credit)
        }
      })
    }else{
      body.push({
        brand: { id: userData.role === 'SUPER_ADMIN' ? brandID : userData.brand.id},
        creditType,
        gigVariation,
        gigVariationType,
        notes,
        numCredits,
        payment :{id: payment.paymentId}
      })
    }
    createCredit(body)
      .then((response) => {
        toast({
          title: `Credit created successfully`,
          description: "",
          status: "success",
          position: "top",
          duration: 3000,
          isClosable: true,
        });
        setLoading(false);
        navigate("/credits");
      })
      .catch((err) => {
        toast({
          title: `An error occured when saving the Credit.`,
          description: "Please try after sometime or create an issue",
          status: "error",
          position: "top",
          duration: 3000,
          isClosable: true,
        });
        setLoading(false);
      });
  };

  const updateContractItems = (value, id) => {
    let contractItem = contractItems.find(i => i.id === id)
    let minQuantity = Math.floor(contractItem.minQuantity * minQuantityPercentage / 100)
    if(value > 0 && value < minQuantity){
      toast({
        title: `Minimum Quantity Required.`,
        description: `Value cannot be less than ${minQuantity}`,
        status: "error",
        position: "top",
        duration: 3000,
        isClosable: true,
      });
    }
    setContractItems(contractItems.map(i => {
      if(i.id === id){
        i.creditCount = value > 0 && value < minQuantity ? minQuantity : value
      }
      return i
    }))
  }

  useEffect(() => {
    const amount = contractItems.reduce((sum, item) => sum + item.cost * (item.creditCount ? Number(item.creditCount) : 0), 0)
    setTotalAmount(amount)
  },[contractItems])

  useEffect(() => {
    if(saveReady){
      saveCreditForm()
      localStorage.removeItem('credit')
    }
  },[saveReady])

  useEffect(async() => {
    if(!paymentIntentFromStripe || !stripeRedirect) return
    setShowCheckoutModal(false)
    if(stripeRedirect.toLowerCase() === 'succeeded') {
      // toast({
      //   title: 'Payment successful.',
      //   description: "Credits have been added to your account",
      //   status: 'success',
      //   position: 'top',
      //   duration: 3000,
      //   isClosable: true,
      // })
      if(pId){
        await updatePaymentStatus(pId, 'Succeeded', paymentIntentFromStripe)
        let savedData = localStorage.getItem('credit')
        savedData = JSON.parse(savedData)
        setCampaignType(savedData.campaignType)
        creditType, gigVariation, gigVariationType, notes, numCredits, payment, contractItems, contract, ambassador
        setCreditType(savedData.creditType)
        setPayment(savedData.payment)
        setNotes(savedData.notes)
        if(savedData.campaignType === 'Public'){
          setGigVariation(savedData.gigVariation)
          setGigVariationType(savedData.gigVariationType)
          setNumCredits(savedData.numCredits)          
        }else{
          setContractItems(savedData.contractItems)
          setContract(savedData.contract)
          setAmbassador(savedData.ambassador)
        }
        setSaveReady(true)
      }else{
        saveCreditForm()
      }
    }
  } , [paymentIntentFromStripe, stripeRedirect])

  useEffect(() => {
    if(userData.role === 'SUPER_ADMIN'){
      getAllBrands().then((response) => {
        if (Array.isArray(response.data) && response.data.length) {
          let brands = response.data.map((brand) => {
            return {
              id: Number(brand.id),
              name: brand.brandName,
              label: brand.brandName,
              value: Number(brand.id),
            };
          });
          setBrandList(brands);
        }
      });
    }
    getBrandContracts().then((response) => {
      setContracts(response)
    })
  }, []);

  return (
    <>
      <FormHeader
        caption={"Create Credit"}
        submitHandler={() => handleSubmit()}
        closeHandler={() => navigate("/credits")}
        cancelHandler={() => navigate("/credits")}
      />
      <Flex flexDirection="column" pt={{ base: "120px", md: "75px" }}>
        <Flex flexDirection="column" gap={2}>
          {userData.role === 'SUPER_ADMIN' ? <><FormControl style={{ margin: 16 }}>
            <BBAutocomplete
              width={{ base: "50%", sm: "100%" }}
              height="50px"
              options={brandsList}
              isMultiple={false} 
              label='Brand'
              onChange={(value, item) => {
                setSubmit(false);
                setBrandID(item?.originalValue.id)
              }} />
          </FormControl> 
          <FormControl style={{ margin: 16 }}>
            <BBDropdown
              width={{ base: "50%", sm: "100%" }}
              height="50px"
              label="Credit Type"
              placeholder="Select Credit Type"
              required={true}
              isInvalid={submit && !creditType}
              value={creditType || ""}
              onClick={(e) => {
                setSubmit(false);
                setCreditType(e.target.value);
              }}
              options={[
                { id: 1, value: "Free", name: "Free", label: "Free" },
                {id: 2,value: "Transaction",name: "Transaction",label: "Transaction"}
              ]}
            />
          </FormControl></>: null}
          <FormControl style={{ margin: 16 }}>
            <BBDropdown
              width={{ base: "50%", sm: "100%" }}
              height="50px"
              label="Campaign type"
              placeholder="Select Campaign type"
              required={true}
              isInvalid={submit && !campaignType}
              value={campaignType || ""}
              onClick={(e) => {
                setSubmit(false);
                setCampaignType(e.target.value);
              }}
              options={[
                { id: 1, value: "Public", name: "Public", label: "Public" },
                {id: 2, value: "Exclusive",name: "Exclusive",label: "Exclusive"}
              ]}
            />
          </FormControl>
          {campaignType === 'Exclusive' ? 
          <>
          <Flex flexDirection={'row'} justifyContent='space-around'>
            <FormControl style={{ margin: 16 }}>
              <BBAutocomplete
                height="50px"
                options={contracts.map(c => ({id: c.id, label: c.title, value: c.title, name: c.title}) )}
                isMultiple={false} 
                label='Contract'
                onChange={(value, item) => {
                  setSubmit(false);
                  let contr = contracts.find(c => c.id === item.originalValue.id)
                  setContract(contr)
                  setAmbassador(contr.invites[0]?.user)
                  setContractItems(contr.items)
                }} />
            </FormControl>
            {ambassador ? 
              <Flex width={{ base: "50%", sm: "50%" }} style={{ marginTop: 48 }}>
                <Avatar src={ambassador.profileImageUrl} w="50px" borderRadius="50px" me="18px" />
                <Flex direction="column">
                  <Text
                    fontSize="md"
                    fontWeight="normal"
                    minWidth="100%"
                  >
                    {ambassador.firstName} {ambassador.lastName}
                  </Text>
                  <Text fontSize="sm" color={"gray.500"} fontWeight="normal">
                    {ambassador.email}
                  </Text>
                </Flex>
              </Flex>: null }
            </Flex>
            {contract ? <Flex style={{margin: 16}} flexDirection={'column'} gap={2}>
              <TableContainer>
                <Table size='sm'>
                  <Thead>
                    <Tr>
                      <Th>TYPE</Th>
                      <Th>AGREED</Th>
                      <Th>BOUGHT</Th>
                      <Th>COST</Th>
                      <Th>QUANTITY</Th>
                      <Th>TOTAL</Th>
                    </Tr>                    
                  </Thead>
                  <Tbody>
                  {contractItems.map(item => {
                    return (
                      <Tr key={item.id}>
                        <Td>{item.itemType}</Td>
                        <Td>{item.minQuantity}</Td>
                        <Td>{contract.credits && contract.credits.length ? contract.credits.filter(c => c.gigVariation === item.itemType).reduce((sum,a)=>sum+a.numCredits,0) : 0}</Td>
                        <Td>${item.cost}</Td>
                        <Td><Input value={item.creditCount}  placeholder="Enter Quantity" onChange={(e) => updateContractItems(e.target.value, item.id)}>
                        </Input></Td>
                        <Td>${item.cost * item.creditCount || 0}</Td>
                      </Tr>
                    )
                  })}
                  </Tbody>
                </Table>
              </TableContainer>
              <Flex>
                <Text mt={2} fontSize={'md'} fontWeight={'bold'} mb={4}>Total amount: </Text>
                <Box padding={2} borderRadius={'md'} borderColor={'brandbassBlue.600'} border={'1px'} ml={8}><Text fontSize={'lg'} fontWeight={'bold'}>${totalAmount + (totalAmount * brandbassComission / 100)}</Text></Box>
                <Text mt={2} ml={2} fontSize={'md'}  mb={4}>Including Brandbass comission of ${totalAmount * brandbassComission / 100} @{brandbassComission}%</Text>
              </Flex>
            </Flex> : null}            
            <FormControl style={{ margin: 16 }}>
              <BBTextarea
                required={true}
                label={"Notes:"}
                value={notes || ""}
                id="notes"
                onChange={(e) => {
                  setSubmit(false);
                  setNotes(e.target.value);
                }}
                isInvalid={submit && !notes}
                placeholder="Enter your notes here"
              />
            </FormControl>
          </>
          :
          <>
          <FormControl style={{ margin: 16 }}>
            <BBDropdown
              width={{ base: "50%", sm: "100%" }}
              height="50px"
              label="Gig Variation"
              placeholder="Select Gig Variation"
              required={true}
              isInvalid={submit && !gigVariation}
              value={gigVariation || ""}
              onClick={(e) => {
                setSubmit(false);
                setGigVariation(e.target.value);
              }}
              options={[
                { id: 1, value: "Content", name: "Content", label: "Content" },
                {id: 2, value: "Publish",name: "Publish",label: "Publish"},
                {id: 3, value: "Content & Publish",name: "Content & Publish",label: "Content & Publish"}
              ]}
            />
          </FormControl>
          <FormControl style={{ margin: 16 }}>
            <BBDropdown
              width={{ base: "50%", sm: "100%" }}
              height="50px"
              label="Gig Variation Type"
              placeholder="Select Gig Variation Type"
              required={true}
              isInvalid={submit && !gigVariationType}
              value={gigVariationType || ""}
              onClick={(e) => {
                setSubmit(false);
                setGigVariationType(e.target.value);
              }}
              options={[
                { id: 1, value: "Video", name: "Video", label: "Video" },
                {id: 2,value: "Audio", name: "Audio",label: "Audio"},
                {id: 3,value: "Text",name: "Text",label: "Text"}
              ]}
            />
          </FormControl>
          <FormControl style={{ margin: 16 }}>
            <BBTextarea
              required={true}
              label={"notes:"}
              value={notes || ""}
              id="notes"
              onChange={(e) => {
                setSubmit(false);
                setNotes(e.target.value);
              }}
              isInvalid={submit && !notes}
              placeholder="Enter your notes here"
            />
          </FormControl>
          <FormControl style={{ margin: 16 }}>
            <BBInput
              type="number"
              value={numCredits}
              isInvalid={submit && !numCredits}
              required
              min={0}
              label="No Of Credits"
              placeholder="No Of Credits"
              onChange={(e) => {
                setSubmit(false);
                setNumCredits(e.target.value);
              }}
            />
          </FormControl>
          </>}
        </Flex>
      </Flex>
      {showCheckoutModal && <CreditPaymentModal
          visible={showCheckoutModal}
          closeHandler={(e, proceedNext) => {
              setShowCheckoutModal(false);   
          }}
          successHandler={() => {
              setShowCheckoutModal(false)
          }}
          paymentDetails={payment}
          creditList={creditList}
      />}
    </>
  );
};

export default CreditForm;
