/*eslint-disable*/
import React from "react";
import PropTypes from "prop-types";
import {
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    Button,
    Text,
    FormControl,FormLabel
} from '@chakra-ui/react'
import { ChevronDownIcon } from "@chakra-ui/icons"

export default function BBDropdown(props) {
    let label = null;
    if (props.value && props.value !== 'None') {
        props.options.forEach(option => { if (option.value === props.value) { label = option.name?option.name:option.label } })
    }
    return (
        <>
           <FormControl {...(props.required && { isRequired: props.mandatoryWithOutLabel ? false : props.required })} >
                {props.label && <FormLabel lineHeight='22px'
                    fontWeight='bold'
                    fontSize='16px'
                    {...props.mandatoryWithOutLabel && {visibility: 'hidden'}}
                    padding={"0px 1px 10px 1px"}>{props.label}</FormLabel>}
                <Menu>
                    <MenuButton {...props} borderRadius="5px" height={props.height ? props.height : '55px'} borderColor={"gray6"} border="1px solid"
                        fontWeight={"normal"} textAlign={"left"} width={props.width ? props.width : '320px'} as={Button} rightIcon={<ChevronDownIcon />}>
                        {props.value && label ? label : props.placeholder}
                    </MenuButton>
                    <MenuList paddingTop="0px" paddingBottom="0px" borderColor={"gray6"}>
                        {props.options && !!props.options.length && props.options.map((item, index) => {
                            return (
                                <MenuItem {...props} key={index} onClick={(e) => props.onClick ? props.onClick(e) : props.onChange(e)}
                                    _hover={{ backgroundColor: 'brandbassBlue.600', color: 'white' }}
                                    height={props.height ? props.height : '55px'}
                                    isDisabled={item.disabled}
                                    value={item.value} width={props.width ? props.width : '320px'} >{item.name?item.name:item.label}</MenuItem>
                            )
                        })}
                    </MenuList>
                </Menu>
                {props.isInvalid && props.required && <Text color={'#EB5757'} variant="bb-status">Please enter the value</Text>}
            </FormControl>
        </>
    );
}
