import {
    Button,
    Link,
    AvatarBadge,
    Text,
    Heading,
    SkeletonCircle,
    Tooltip,
    Box,
    Image,
    Stack, Avatar, Flex,
    useColorModeValue,
    SkeletonText, Badge,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    HStack,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    AvatarGroup,
    Modal,

} from "@chakra-ui/react";
import Instagram from "assets/svg/InstagramLinkIcon.svg";
import BBAutocomplete from "components/Autocomplete/Autocomplete";
import BBButton from "components/Button/Button";
import { AppContext } from "contexts/AppContext";
import { useContext, useEffect, useState } from "react"
import { FaFacebook, FaInstagram, FaHome, FaArrowRight, FaBullhorn } from "react-icons/fa";
import { MdOutlineStarOutline, MdLibraryAddCheck } from "react-icons/md";
import { updatePreferredAmbassadors } from "RestAPI";
import { getAmbassadors } from "RestAPI";
import { getBrandData } from "RestAPI"
import ShowUserForm from "../Users/components/ShowUser";

const BrandDetails = (props) => {
    const { BrandID } = props;
    const [brand, setBrand] = useState(null)
    const [loading, setLoading] = useState(false)
    const {userData} = useContext(AppContext)
    const [showAmbModal, setShowAmbModal] = useState(false)
    const [selectedAmb, setSelectedAmb] = useState(null)
    const [showPreferredAmbassadorModal, setShowPreferredAmbassadorModal] = useState(false)
    const [ambassadors, setAmbassadors] = useState([])
    const [preferredAmbassadors, setPreferredAmbassadors] = useState(null)

    const fetchBrandDetails = async () => {
        setLoading(true)
        await getBrandData(BrandID).then(response => {
            // response.data.categories = response.data && response.data.categories ? response.data.categories.split(";") : response.data.categories
            setBrand(response.data && response.data.id ? response.data : null)
            setLoading(false)
        }).catch(err => {console.log(err);setLoading(false)})
    }

    const fetchAllAmbassadors = async() => {
      setLoading(true)
      let ambassadors = await getAmbassadors({pagination : {
        perPage: 1000,
        page: 1
      }})
      setAmbassadors(ambassadors.data || [])
      setLoading(false)
    }

    const saveBrandPreferredAmbassadors = async() => {
      setShowPreferredAmbassadorModal(false)
      setLoading(true)
      let res = await updatePreferredAmbassadors(BrandID, preferredAmbassadors)
      fetchBrandDetails()
      setLoading(false)
    }

    const updateBrandPreferredAmbassadors = (items) => {
      if(items?.length){
        setPreferredAmbassadors(ambassadors.filter(amb => items.includes(amb.firstName +' '+amb.lastName)))
      }else{
        setPreferredAmbassadors([])
      }
    }

    useEffect(() => {
        fetchBrandDetails()
        if(userData.role === 'SUPER_ADMIN'){
          fetchAllAmbassadors()
        }
    }, [])
     console.log("brand",brand)
    return (
      <>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <ModalCloseButton />
          </ModalHeader>
          <ModalBody pb={6}>
            {loading && !brand && (
              <Flex align="center" minWidth="100%" flexWrap="nowrap">
                <Box padding="6" boxShadow="lg" bg="white" minWidth="100%">
                  <SkeletonCircle size="10" />
                  <SkeletonText mt="4" noOfLines={4} spacing="4" />
                </Box>
              </Flex>
            )}

            {!loading && brand && (
              <>
                <Box
                  position="relative"
                  w="100%"
                  h="100%"
                  borderRadius="10px"
                  bgColor="#F8F9FA"
                >
                  <Stack direction="column" p={6} spacing={6}>
                    <Box position="relative" w="100%" h="100%" mb={"20px"}>
                      <HStack gap={"10px"} direction="column" flexDirection={{base:"column",lg:"row"}}>
                        <Box
                          position="relative"
                          textAlign="center"
                          w={{ base: "full", lg: "70%" }}
                        >
                          <Box boxShadow="xl" rounded="md" bg="white">
                            <Flex>
                              <Image
                                h={"100%"}
                                w={"100%"}
                                maxH="300px"
                                src={brand.logoUrl}
                                borderRadius="lg"
                                overflow="hidden"
                                alt={""}
                              />

                              <Text
                                fontSize="2xl"
                                color="white"
                                mixBlendMode={"difference"}
                                position="absolute"
                                bottom={0}
                                left={0}
                                p={6}
                                ml={4}
                                mb={4}
                              >
                                <HStack direction="column" spacing={6}>
                                  <span>{brand.brandName} </span>
                                  <FaArrowRight />
                                </HStack>
                              </Text>
                              <Text
                                fontSize="sm"
                                color="white"
                                position="absolute"
                                mixBlendMode={"difference"}
                                bottom={0}
                                left={0}
                                p={6}
                                ml={4}
                                fontWeight={"bold"}
                              >
                               {brand.email}
                              </Text>
                            </Flex>
                          </Box>
                        </Box>
                        <Box
                          position="relative"
                          w={{ base: "full", lg: "25%" }}
                          bgColor="#E2E8F0"
                          borderRadius="15px"
                        >
                          <Stack direction="column" p={3} spacing={6}>
                            <Box
                              position="relative"
                              bgColor="#FFFFFF"
                              borderRadius="15px"
                              padding="10px 45px"
                            >
                              <HStack direction="column" spacing={2}>
                                <MdOutlineStarOutline fontSize={"25px"} />{" "}
                                <Text
                                  textAlign="center"
                                  fontFamily="Rubik"
                                  fontWeight="700"
                                  fontSize="25px"
                                  lineHeight="16px"
                                  color="brandbassGray.500"
                                >
                                  {brand.averageRating}
                                </Text>
                              </HStack>
                              <Text
                                fontFamily="Rubik"
                                fontWeight="400"
                                fontSize="18px"
                                lineHeight="24px"
                                color="brandbassGray.500"
                              >
                                Ratings
                              </Text>
                            </Box>
                            <Box
                              position="relative"
                              bgColor="#FFFFFF"
                              borderRadius="15px"
                              padding="10px 45px"
                            >
                              <HStack direction="column" spacing={2}>
                                <MdLibraryAddCheck fontSize={"25px"} />{" "}
                                <Text
                                  fontFamily="Rubik"
                                  fontWeight="700"
                                  fontSize="25px"
                                  lineHeight="16px"
                                  color="brandbassGray.500"
                                >
                                  {brand.campaignCount}
                                </Text>
                              </HStack>
                              <Text
                                fontFamily="Rubik"
                                fontWeight="400"
                                fontSize="18px"
                                lineHeight="24px"
                                color="brandbassGray.500"
                              >
                                Campaign
                              </Text>
                            </Box>
                            <Box
                              position="relative"
                              bgColor="#FFFFFF"
                              borderRadius="15px"
                              padding="10px 45px"
                            >
                              <HStack direction="column" spacing={2}>
                                <FaBullhorn fontSize={"25px"} />{" "}
                                <Text
                                  fontFamily="Rubik"
                                  fontWeight="700"
                                  fontSize="25px"
                                  lineHeight="16px"
                                  color="brandbassGray.500"
                                >
                                  {brand.gigCount}
                                </Text>
                              </HStack>
                              <Text
                                fontFamily="Rubik"
                                fontWeight="400"
                                fontSize="18px"
                                lineHeight="24px"
                                color="brandbassGray.500"
                              >
                                Gigs
                              </Text>
                            </Box>
                          </Stack>
                        </Box>
                      </HStack>
                    </Box>
                     <Box position="relative" textAlign="left" mb={"15px"}>
                      <Heading
                        as="h3"
                        size="lg"
                        mb={2}
                        fontSize={"24px"}
                      >
                        Social Media
                      </Heading>
                      {(brand.instagram || brand.facebook) ? <HStack direction="column" gap={6} flexDirection={{base:'column',lg:'row'}}>
                        {brand.instagram && <Button
                          leftIcon={<FaInstagram />}
                          background="brandbassBlue2.200"
                          variant="solid"
                          onClick={()=>window.open(`https://www.instagram.com/${brand.instagram}`,"_blank",'noopener,noreferrer')}
                        >
                          {brand.instagram}  
                        </Button>}
                        {brand.facebook && <Button
                          leftIcon={<FaFacebook />}
                          background="brandbassBlue2.200"
                          variant="solid"
                          onClick={()=>window.open(`https://www.facebook.com/${brand.facebook}`,"_blank",'noopener,noreferrer')}
                        >
                          {brand.facebook}
                        </Button>}
                      </HStack> : 'NA'}
                    </Box>
                    <Box position="relative" textAlign="left" mb={"15px"}>
                      <HStack direction="column" gap={6} flexDirection={{base:'column',lg:'row'}}>
                        <Box
                          position="relative"
                          textAlign="left"
                          w={{ base: "full", lg: "50%" }}
                        >
                          <Heading
                            as="h3"
                            size="lg"
                            fontSize={"24px"}
                            mb={"15px"}
                          >
                            Website
                          </Heading>
                          {brand.website ? (
                            <Button
                              leftIcon={<FaHome />}
                              background="#E2F1FF"
                              variant="solid"
                              onClick={()=>window.open(brand.website && brand.website.includes("http:/") ? brand.website : `http://${brand.website}`,"_blank",'noopener,noreferrer')}
                            >
                              {brand.website}
                            </Button>
                          ) : (
                            <Text>NA</Text>
                          )}
                        </Box>
                        <Box
                          position="relative"
                          textAlign="left"
                          w={{ base: "full", lg: "50%" }}
                        >
                          <Heading
                            as="h3"
                            size="lg"
                            fontSize={"24px"}
                            mb={"15px"}
                          >
                            Categories
                          </Heading>
                          {brand?.categories && !!brand.categories.length
                            ? brand.categories.map((category,index) => {
                                return (
                                  <Badge
                                    key={index}
                                    fontFamily="Rubik"
                                    fontWeight="400"
                                    fontSize="18px"
                                    lineHeight="24px"
                                    color="brandbassGray.700"
                                    background={"#E0E0E0"}
                                    variant="solid"
                                    borderRadius="50px"
                                    p={2}
                                    pl={6}
                                    pr={6}
                                    m={2}
                                  >
                                    {category.name?category.name:category}
                                  </Badge>
                                );
                              })
                            : "NA"}
                        </Box>
                      </HStack>
                    </Box>
                    {userData.role === 'SUPER_ADMIN' ? <Box position="relative" textAlign="left" mb={"15px"}>
                      <Flex justifyContent={'space-between'}>
                        <Heading as="h3" size="lg" fontSize={"24px"} mb={"15px"}>
                          Preferred Ambassadors
                        </Heading>
                        {!showPreferredAmbassadorModal ? <BBButton variant='outline' onClick={() => setShowPreferredAmbassadorModal(true)}>Edit</BBButton> : <BBButton variant='outline' onClick={() => saveBrandPreferredAmbassadors()}>Save</BBButton> }
                        {/* {showPreferredAmbassadorModal ? <PreferredAmbassadorModal visible={showPreferredAmbassadorModal} preferredAmbassadors={brand.preferredAmbassadors} closeHandler={() => {setShowPreferredAmbassadorModal(falst); fetchBrandDetails()}}/> : null} */}
                      </Flex>
                      {!showPreferredAmbassadorModal ? !brand.preferredAmbassadors?.length ? <Text>None</Text> : <AvatarGroup size="md" mt={4} flexWrap={'wrap'}>
                        {brand.preferredAmbassadors.map((user) =>
                          !user ? null : (
                            <Avatar
                              name={user.firstName}
                              title={user.firstName}
                              key={user.id}
                              src={user.profileImageUrl}
                              _hover={{ zIndex: "3", cursor: "pointer" }}
                              onClick={() => { setSelectedAmb(user.id); setShowAmbModal(true) }}
                            >
                              {selectedAmb === user.id && showAmbModal ? <Modal size={'4xl'} isOpen={showAmbModal} onClose={() => setShowAmbModal(false)}>
                                <ShowUserForm onClose={() => { setShowAmbModal(false); setSelectedAmb(null) }} UserID={user.id} />
                              </Modal> : null}
                            </Avatar>
                          )
                        )}
                      </AvatarGroup> : null}
                     {ambassadors?.length && showPreferredAmbassadorModal? 
                     <BBAutocomplete 
                      defaultValues={brand.preferredAmbassadors? brand.preferredAmbassadors.map(a => { return a.firstName +' '+ a.lastName }) : []}      
                      options={ambassadors.map(a => { return { id: a.id, value: a.firstName +' '+ a.lastName, name: a.firstName +' '+ a.lastName} })}
                      isMultiple={true} label='Select ambassador'
                      onChange={(value, item) => updateBrandPreferredAmbassadors(value)}/> : null }
                    </Box> : null}
                    <Box position="relative" textAlign="left" mb={"15px"}>
                      <Heading as="h3" size="lg" fontSize={"24px"} mb={"15px"}>
                        About Brand
                      </Heading>
                      <Text
                        fontFamily="Rubik"
                        fontWeight="400"
                        fontSize="14px"
                        lineHeight="24px"
                        color="brandbassGray.500"
                      >
                        {brand.summary ? brand.summary : "NA"}
                      </Text>
                    </Box>
                  </Stack>
                </Box>
              </>
            )}
          </ModalBody>
        </ModalContent>
      </>
    );
};

export default BrandDetails;