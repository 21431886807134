import {
  Avatar,
  Badge,
  Button,
  Flex,
  Td,
  Text,
  Tr,
  useColorModeValue,
  AvatarGroup
} from "@chakra-ui/react";
import React from "react";
import {useNavigate} from 'react-router-dom'

function TablesTableRow(props) {
  const { name, description, owner, users, status, date, row, editItem } = props;
  const textColor = useColorModeValue("gray.700", "white");
  const bgStatus = useColorModeValue("gray.400", "#1a202c");
  const colorStatus = useColorModeValue("white", "gray.400");
  const navigate = useNavigate();
  
  return (
    <Tr onClick={() => navigate(`${row.id}`,{state: row, replace: true})} cursor="pointer">
      <Td minWidth={{ sm: "250px" }} pl="0px">
        <Text fontSize="md" color={textColor} fontWeight="bold">
          {name}
        </Text>
      </Td>

      <Td minWidth={{ sm: "250px" }} pl="0px">
        <Text fontSize="md" color={textColor} fontWeight="bold">
          {description}
        </Text>
      </Td>
      <Td minWidth={{ sm: "250px" }} pl="0px">
        <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
          <Avatar src={owner.profileImageUrl} w="50px" borderRadius="12px" me="18px" />
          <Flex direction="column">
            <Text
              fontSize="md"
              color={textColor}
              fontWeight="bold"
              minWidth="100%"
            >
              {owner.firstName} {owner.lastName}
            </Text>
            <Text fontSize="sm" color="gray.400" fontWeight="normal">
              {owner.email}
            </Text>
          </Flex>
        </Flex>
      </Td>
      <Td>
        <AvatarGroup size="sm">
          {users.map((user) => {
            return (
              <Avatar
                name={`${user.firstName} ${user.lastName}`}
                key={user.id}
                src={user.profileImageUrl}
                _hover={{ zIndex: "3", cursor: "pointer" }}
              />
            );
          })}
        </AvatarGroup>
      </Td>
      <Td>
        <Badge
          bg={status === "Online" ? "green.400" : bgStatus}
          color={status === "Online" ? "white" : colorStatus}
          fontSize="16px"
          p="3px 10px"
          borderRadius="8px"
        >
          {status?'Active':'Inactive'}
        </Badge>
      </Td>
      <Td>
        <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem">
          {date}
        </Text>
      </Td>
      <Td>
        <Button p="0px" bg="transparent" variant="no-hover" onClick={(e) => { e.stopPropagation(); editItem(row)}}>
          <Text
            fontSize="md"
            color="gray.400"
            fontWeight="bold"
            cursor="pointer"
          >
            Edit
          </Text>
        </Button>
      </Td>
    </Tr>
  );
}

export default TablesTableRow;
