import { AppContext } from "contexts/AppContext"
import { useContext, useEffect, useState } from "react";
import NoPermission from "views/403/NoPermission";

const CheckPermission = ({ children, access }) => {
    const { userData, hasEditPermission, hasCreatePermission, setPerformOwnerCheck, performOwnerCheck } = useContext(AppContext);
    const [haveSpecialPrivilege, setHaveSpecialPrivilege] = useState(null)

    const checkPrivilege = () => {
        if(!userData?.role) return false
        if((access === 'edit' && hasEditPermission) || (access === 'create' && hasCreatePermission)) {
            setPerformOwnerCheck(false)
            return setHaveSpecialPrivilege(true)
        }

        const role = userData.role.toUpperCase()
        if(role.includes('ADMIN')) {
            setPerformOwnerCheck(false)
            return setHaveSpecialPrivilege(true)
        }

        if(role.includes('MANAGER')) {
            return !performOwnerCheck ? setPerformOwnerCheck(true) : setHaveSpecialPrivilege(false)
        }

        setPerformOwnerCheck(false)
        return false
    }

    useEffect(() => {
        checkPrivilege()
    },[userData, hasEditPermission])

    return !userData?.role || haveSpecialPrivilege===null ? null : haveSpecialPrivilege ? children : <NoPermission />
}

export default CheckPermission

/*
export enum UserRole  {
  SUPER_ADMIN = "SUPER_ADMIN",
  AMBASSADOR = "AMBASSADOR",
  BRAND_MANAGER = "BRAND_MANAGER",
  BRAND_SUPER_ADMIN = "BRAND_SUPER_ADMIN",
  BRAND_USER = "BRAND_USER",
  NONE = "NONE"
}

*/
