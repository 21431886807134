import TeamList from "./components/TeamList";

function Teams() {
  
  return <TeamList
    title={"Teams"}
  />
}

export default Teams;
