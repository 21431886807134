// Chakra imports
import { CheckIcon, CloseIcon, EditIcon, ViewIcon } from "@chakra-ui/icons";
import {
  Button,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr, Box, Tag, TagLabel, Center,
  useColorModeValue, Skeleton, SkeletonCircle, SkeletonText,
  Modal, IconButton,
  useDisclosure,
  Flex,
  AvatarGroup,
  Avatar,
  Badge, Spacer
} from "@chakra-ui/react";
import ReactDataGrid from "@inovua/reactdatagrid-community";
// Custom components
import Card from "components/Card/Card.js";
import CounterCard from "components/Card/CounterCard.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import { TrashIcon } from "components/Icons/Icons";
import TeamsTableRow from "components/Tables/TeamsTableRow";
import moment from "moment";
import React, { useEffect, useState, useContext } from "react";
import { getBrandContracts } from "RestAPI";
import { checkboxColumn } from "views/Dashboard/Campaign/CampaignList";
import ContractForm from "./ContractForm";
import { AppContext } from "contexts/AppContext";
import { globalStyles } from "theme/styles";
import EmptyList from "assets/img/empty/project.png";
import BBButton from "components/Button/Button";
import { FaPlus } from "react-icons/fa";
import { useNavigate, useSearchParams } from "react-router-dom";
import { DeleteEntityModal } from "layouts/Dashboard";
import ViewContract from "./ViewContract";
import "../Contract.css"

const ContractList = ({ title, captions }) => {
  const navigate = useNavigate()
  const { hasEditPermission, hasCreatePermission, userData } = useContext(AppContext)
  const textColor = useColorModeValue("black3", "white");
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [selectedRow, setSelectedRow] = useState({})
  const [contractData, setContractData] = useState(null)
  const [loading, setLoading] = useState(true)
  const [overView, setOverview] = useState(null)
  const [searchParams] = useSearchParams()
  const editItem = (row) => {
    navigate(`/edit/contract/${row.id}`, {state: {data: row}} )
  }
  const [deleteModal, setDeleteModal] = useState(null)
  const [entityId, setEntityId] = useState(null)
  const [showContractDetails, setShowContractDetails] = useState(false)
  const [contractId, setContractId] = useState();

  const deleteItem = (row) => {
    setEntityId(row.id)
    setDeleteModal(true)
  }


  const refreshList = () => {
    setLoading(true)
    getBrandContracts().then(response => {
      setLoading(false);
      if (response) {
        response = response.map(contract=>{
          contract.duration=`${contract.duration} ${contract.durationUnit}`
          contract.amount = userData.role === 'AMBASSADOR' ? contract.amountAmbassador : contract.amountBrand
          contract.creditOverview = {bought: contract.credits.reduce((sum,a) => sum + a.numCredits, 0)}
          contract.creditOverview.agreed = contract.items.reduce((sum,a)=> sum + a.minQuantity,0)
          contract.minCreditsAvailable = true
          contract.items.forEach(item => {
            let credits = contract.credits.filter(c => c.gigVariation === item.itemType).reduce((sum,a)=>sum + a.numCredits,0)
            if(credits <= 0){
              contract.minCreditsAvailable = false
            }
          })
          return contract
        })
        setContractData(response)
        setOverview({
          totalContracts: response.length,
          draftContracts: response.filter(item => item.contractStatus === 'Draft'),
          pendingContracts: response.filter(item => item.contractStatus === 'Initiated'),
          activeContracts: response.filter(item => item.contractStatus === 'Active'),
          expiredContracts: response.filter(item => item.contractStatus === 'Expired'),
        })
      }
    }).catch(err => { setLoading(false); console.error(err) })
  }

  let columns = [
    {
      name: "id",
      type: "number",
      header: <Text color="gray3" textTransform='uppercase'>ID</Text>,
      defaultVisible: false,
      defaultFlex: 1,
      defaultWidth: 100,
      sortable: true,
      render: ({ value }) => (
        <Text color="black3" fontSize='md' fontWeight="normal">
          {value}
        </Text>
      ),
    },
    {
      name: "title",
      type: "string",
      header: <Text color="gray3" textTransform='uppercase'>Name</Text>,
      defaultVisible: true,
      defaultFlex: 1,
      defaultWidth: 100,
      sortable: true,
      filterEditorProps: {
        style: {
          background: '#fefefe',
        },
        placeholder: 'Search by name...'
      },
      render: ({ value }) => (
          <Text color="black3" fontSize='md' fontWeight="normal">
            {value}
          </Text>
      ),
    },
    {
      name: "contractType",
      type: "string",
      header: <Text color="gray3" textTransform='uppercase'>Type</Text>,
      defaultVisible: true,
      defaultFlex: 1,
      defaultWidth: 100,
      maxWidth: 100,
      sortable: true,
      render: ({ value }) => (
        <Text color="black3" fontSize='md' fontWeight="normal">
          {value}
        </Text>
      ),
    },
    {
      name: "contractStatus",
      type: "string",
      header: <Text color="gray3" textTransform='uppercase'>Status</Text>,
      defaultVisible: true,
      defaultFlex: 1,
      defaultWidth: 100,
      maxWidth: 100,
      sortable: true,
      render: ({ value }) => (
        <Text color="black3" fontSize='md' fontWeight="normal">
          {value}
        </Text>
      ),
    }]
  if(userData.role === 'AMBASSADOR' || userData.role === 'SUPER_ADMIN' || userData.role === 'CLIENT_SUPER_ADMIN'){
    columns.push({
      name: "brand",
      type: "string",
      header: <Text color="gray3" textTransform='uppercase'>Brand</Text>,
      defaultVisible: true,
      defaultFlex: 1,
      defaultWidth: 100,
      minWidth: 240,
      sortable: true,
      render: ({ value }) => {
        return <Flex cursor="pointer" align="center" py=".8rem" minWidth="100%" flexWrap="nowrap" >
          <Avatar src={value.logoUrl} w="50px" borderRadius="50px" me="18px" />
          <Flex direction="column">
            <Text
              fontSize="md"
              color={textColor}
              fontWeight="normal"
              minWidth="100%"
            >
              {value.brandName}
            </Text>
          </Flex>
        </Flex>
      },
    })
  }
  if(userData.role !== 'AMBASSADOR'){
      columns.push({
      name: "invites",
      type: "string",
      header: <Text color="gray3" textTransform='uppercase'>Ambassador</Text>,
      defaultVisible: true,
      defaultFlex: 1,
      defaultWidth: 100,
      minWidth: 240,
      sortable: true,
      render: ({ value }) => {
        let ambassador = value?.length ? value[0].user : null
        return ambassador ? (
        <Flex cursor="pointer" align="center" py=".8rem" minWidth="100%" flexWrap="nowrap" >
          <Avatar src={ambassador.profileImageUrl} w="50px" borderRadius="50px" me="18px" />
          <Flex direction="column">
            <Text
              fontSize="md"
              color={textColor}
              fontWeight="normal"
              minWidth="100%"
            >
              {ambassador.firstName} {ambassador.lastName}
            </Text>
            <Text fontSize="sm" color={"gray.500"} fontWeight="normal">
              {ambassador.email}
            </Text>
          </Flex>
        </Flex>) : null 
      },
    })
  }
  columns = columns.concat([
    {
      name: "minCreditsAvailable",
      type: "string",
      header: <Text color="gray3" textTransform='uppercase' >Min Credit Status</Text>,
      defaultVisible: userData.role === 'AMBASSADOR' ? false : true,
      defaultFlex: 1,
      defaultWidth: 100,
      maxWidth: 120,
      sortable: true,
      render: ({ value }) => (
        <Flex flexDirection={'column'}>
          {!!value ? <IconButton colorScheme={'green'} icon={<CheckIcon/>}></IconButton> : 
          <IconButton colorScheme={'red'} icon={<CloseIcon/>}></IconButton>}
          {/* <Text color="black3" fontSize='md' fontWeight="normal">
            Agreed: {value?.agreed}
          </Text>
          <Text color="black3" fontSize='md' fontWeight="normal">
            Bought: {value?.bought}
          </Text> */}
          {/* <Text color="black3" fontSize='md' fontWeight="normal">
            Balance: {value?.agreed - value?.bought}
          </Text> */}
        </Flex>
      ),
    },
    {
      name: "startDate",
      type: "string",
      header: <Text color="gray3" textTransform='uppercase'>Start Date</Text>,
      defaultVisible: true,
      defaultFlex: 1,
      defaultWidth: 100,
      maxWidth: 120,
      sortable: true,
      render: ({ value }) => (
        <Text color="black3" fontSize='md' fontWeight="normal">
          {moment(value).format('MM-DD-YYYY')}
        </Text>
      ),
    },
    {
      name: "duration",
      type: "string",
      header: <Text color="gray3" textTransform='uppercase'>Duration</Text>,
      defaultVisible: true,
      defaultFlex: 1,
      defaultWidth: 100,
      maxWidth: 120,
      sortable: true,
      render: ({ value }) => (
        <Text color="black3" fontSize='md' fontWeight="normal">
          {value}
        </Text>
      ),
    },
    {
      name: "amountAmbassador",
      type: "string",
      header: <Text color="gray3" textTransform='uppercase'>Amount</Text>,
      defaultVisible: true,
      defaultFlex: 1,
      defaultWidth: 100,
      maxWidth: 120,
      sortable: true,
      render: ({ value }) => (
        <Text color="black3" fontSize='md' fontWeight="normal">
          ${value}
        </Text>
      ),
    },
    {
      name: "createdOn",
      type: "date",
      header: <Text color="gray3" textTransform='uppercase'>Created on</Text>,
      defaultVisible: true,
      defaultFlex: 1,
      maxWidth: 120,
      sortable: true,
      render: ({ value }) => (
        <Text color="black3" fontSize='md' fontWeight="normal">
          {moment(value).format('MM-DD-YYYY')}
        </Text>
      ),
    },
    {
      header: <Text color="gray3" textAlign='center'>ACTIONS</Text>,
      defaultVisible: true,
      defaultFlex: 1,
      defaultWidth: 30,
      textAlign: 'center',
      sortable: false,
      render: ({ data }) =>  <Flex gap='0.25rem' justifyContent="center" alignItems="center" >
        {userData?.role?.includes('ADMIN') && data?.createdBy?.id === userData.id
      ? <><IconButton onClick={() => editItem(data)}
          variant='outline' size="xs"
          colorScheme='teal' borderRadius={"50%"}
          aria-label='Edit item'
          icon={<EditIcon />}
        />
        <IconButton onClick={() => deleteItem(data) }
          disabled={data.isDefault}
          variant='outline' size="xs"
          colorScheme='teal' borderRadius={"50%"}
          aria-label='Delete item'
          icon={<TrashIcon />}
        /></> :null }
        <IconButton onClick={() => navigate(`/contracts/${data.id}`)}
          variant='outline' size="xs"
          colorScheme='teal' borderRadius={"50%"}
          aria-label='View item'
          icon={<ViewIcon />}
        /> 
      </Flex>
    },
  ])

  columns.forEach(item => item.style = { borderLeftColor: 'white', borderRightColor: 'white', borderTopColor: 'white', borderBottomColor: 'gray.200' })

  useEffect(refreshList, [])

  return (
    <>
      <DeleteEntityModal 
        visible={deleteModal} 
        closeHandler={(refresh) => {
          if(!!refresh) refreshList()
          setDeleteModal(false)
        }} 
        entity='contract'
        entityId={entityId}
      />
      <Card id="contract-list" mt='5px' p='16px' overflowX={{ sm: "scroll" }} boxShadow='0px 0px 20px 1px rgb(0 0 0 / 10%)' borderRadius='10px'>
        <CardHeader p='12px 0px 28px 0px'>
          <Flex flex={1} align="center" gap='2rem' w="100%" ml={2} mr={2}>
            <Flex direction='column' gap='2rem' w="100%">
              <Flex gap='1rem' flexDirection='column'>
                <Text fontSize='30px' color={textColor} fontWeight='bold' pb='.5rem'>
                  {title}
                </Text>
                <Spacer />
                {
                  !!hasCreatePermission &&
                  <BBButton shadow={true} maxW='fit-content' boxShadow='1px 4px 10px rgba(31, 100, 255, 0.25)' size="sm" leftIcon={<FaPlus />} colorScheme='brandbassBlue' color="white" onClick={() => navigate('/new/contract')}>
                    CONTRACT
                  </BBButton>
                }
              </Flex>
              <Flex gap='20px' p='10px' alignItems='center' borderRadius='10px' >
                {
                  loading && (
                    <Flex align="center" minWidth="100%" flexWrap="nowrap">
                      <Box padding='6' boxShadow='lg' minWidth="100%">
                        <SkeletonText mt='4' noOfLines={4} spacing='4' />
                      </Box>
                    </Flex>
                  )
                }
                {!loading && (<>
                  <CounterCard title={"Total"} width={180} count={overView ? overView.totalContracts : 0} />
                  <CounterCard title={"Draft"} width={180} count={overView ? overView.draftContracts.length : 0} />
                  <CounterCard title={"Pending"} width={180} count={overView ? overView.pendingContracts.length : 0} />
                  <CounterCard title={"Active"} width={180} count={overView ? overView.activeContracts.length : 0} />
                  <CounterCard title={"Expired"} width={180} count={overView ? overView.expiredContracts.length : 0} />
                </>)}
              </Flex>
            </Flex>
          </Flex>
        </CardHeader>
        <CardBody >
          {
            loading && (
              <Flex align="center" minWidth="100%" flexWrap="nowrap">
                <Box padding='6' boxShadow='lg' minWidth="100%">
                  <SkeletonCircle size='20' />
                  <SkeletonText mt='4' noOfLines={4} spacing='4' />
                </Box>
              </Flex>
            )
          }
          {!loading && (
            <ReactDataGrid
              loading={contractData === null}
              className="brandbass-data-grid"
              style={{ minHeight: 860, borderColor: 'white' }}
              idProperty="id"
              columns={columns}
              dataSource={contractData || []}
              pagination={true}
              defaultLimit={10}
              defaultLoading={loading}
              defaultSortInfo={{ id: "id", name: 'id', dir: -1, type: 'number' }}
              checkboxColumn={checkboxColumn}
              checkboxOnlyRowSelect={true}
              rowHeight={70}
              headerHeight={60}
              showZebraRows={false}
              enableSelection={true}
              showHoverRows={true}
              showCellBorders="horizontal"
              enableColumnAutosize={true}
              defaultFilterValue={[{ name: 'contractName', type: 'string', operator: 'contains', value: '' }]}
              enableColumnFilterContextMenu={false}
              emptyText="No contracts"
            />
          )}
        </CardBody>
      </Card>
      <Modal size={'4xl'} isOpen={showContractDetails} onClose={() => setShowContractDetails(false)}>
        <ViewContract onClose={() => setShowContractDetails(false)} ContractID={contractId} />
      </Modal>
    </>
  );
};

export default ContractList;
