import { Box, Button, Divider, Progress, Flex, Grid, AvatarGroup, Avatar,  Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, Spacer, CircularProgress, Spinner, SimpleGrid, IconButton, Tag, TagLabel, Badge, useToast } from "@chakra-ui/react"
import BBButton from "components/Button/Button"
import { EditIcon,TrashIcon } from "components/Icons/Icons"
import { useContext, useEffect, useState } from "react"
import { useParams, useNavigate } from "react-router-dom"
import { MinusIcon } from '@chakra-ui/icons'
import { getProject,createProject } from "RestAPI"
import { globalStyles } from "theme/styles"
import { DeleteEntityModal } from "layouts/Dashboard";
import { FaPlus } from "react-icons/fa"
import {
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
    SkeletonText
} from '@chakra-ui/react'
import CreateCampaignSetForm from "./CreateCampaignSetForm";
import CreateCampaignForm from "./CreateCampaignModal";
import {DeleteCampaignModal} from "views/Dashboard/Campaign/CampaignList";

const ProjectDetails = () => {
    const { ProjectID } = useParams()
    const [project, setProject] = useState({})
    const navigate = useNavigate()
    const [deleteModal, setDeleteModal] = useState(null)
    const [entityId, setEntityId] = useState(null)
    const [openCampaignSetModal,setCampaignSetModal] = useState(false)
    const [openCampaignModal,setCampaignModal] = useState(false)
    const [selectedCampaignSet,setSelectedCampaignSet] = useState({})
    const [deleteCampaignId, setDeleteCampaignId] = useState(null)
    const [showDeleteCampaignModal, setShowDeleteCampaignModal] = useState(false)
    const [showRemovingCampaignModal, setShowRemovingCampaignModal] = useState(false)
    const [campaignId, setCampaignId] = useState(null)
    const [loading, setLoading] = useState(false)
    const [campaignSetId,setCampaignSetId] =  useState(null)
    const [campaignSetName,setCampaignSetName] =  useState(null)
    
    const deleteItem = (row) => {
        setEntityId(row.id)
        setDeleteModal(true)
    }

    const setProjectData = (data) =>{
       setProject(data)
    }

    const assignProjectProgress = (project) => {
        if(!project.campaignSets?.length) project.progress = 0;
        for(let set of project.campaignSets){
            if(!set.campaigns?.length){
                set.progress = 0;
                continue;
            }
            let setProgress = 0;
            for(let campaign of set.campaigns){
                switch(campaign.campaignStatus){
                    case 'Draft':
                        campaign.progress = 10;
                        break;
                    case 'Created':
                        if(campaign.invites?.length){
                            campaign.progress = 40
                            campaign.campaignStatus = 'Invited'
                        }else{
                            campaign.progress = 20
                            campaign.campaignStatus = 'Attn. Needed'
                        }
                        break;
                    case 'Active':
                        if(campaign.submissions?.length){
                            let accepted = campaign.submissions.filter(sub => sub.submissionStatus === 'Accepted')
                            campaign.progress = Math.floor(accepted.length / campaign.gigs.length) * 20 + 50
                        }else{
                            campaign.progress = 50
                        }
                        break;
                    case 'Closed':
                        campaign.progress = 100
                        break;
                    default:
                        campaign.progress = 0;
                        break;
                }
                setProgress += campaign.progress/100
            }
            set.progress = Math.floor(setProgress/set.campaigns.length * 100)
        }
    }

    const fetchProjectDetails = async () => {
        setLoading(true)
        const response = await getProject(ProjectID)
        if (response && response.data && !response.data.error) {
            console.log("response", response.data)
            assignProjectProgress(response.data)
            setProject(response.data)
            setLoading(false)
        }
    }

    const campaignStatus = {
        'Invited': globalStyles.colors.statusYellow,
        'Attn. Needed': globalStyles.colors.statusRed,
        'Closed': '#4DA6F6',
        'Draft': globalStyles.colors.statusGray,
        'Active': globalStyles.colors.statusGreen,
    }

    useEffect(() => {
        fetchProjectDetails()
    }, [ProjectID])

    return (
        <>
            <DeleteEntityModal
                visible={deleteModal}
                closeHandler={(refresh) => {
                    if (!!refresh) navigate("/projects")
                    setDeleteModal(false)
                }}
                entity='project'
                entityId={entityId}
            />
            <DeleteCampaignModal
                visible={showDeleteCampaignModal}
                closeHandler={(refresh) => {
                  setShowDeleteCampaignModal(false)
                  if (refresh) {
                    fetchProjectDetails()
                  }
                }}
                campaignId={deleteCampaignId}
              />
              <UnAssignCampaignModal  
                 visible={showRemovingCampaignModal}
                 closeHandler={(refresh) => {
                    setShowRemovingCampaignModal(false)
                  }}
                  setProjectData={setProjectData}
                  campaignSetId={campaignSetId}
                  campaignId={campaignId}
                  project={project}
                  campaignSetName={campaignSetName}
               />
            <Modal size={'4xl'} isOpen={openCampaignSetModal} onClose={() => setCampaignSetModal(false)}>
                <CreateCampaignSetForm refreshPage={fetchProjectDetails} onClose={() => setCampaignSetModal(false)} project={project}  setProjectData={setProjectData}/>
            </Modal>
            <Modal size={'4xl'} isOpen={openCampaignModal} onClose={() => setCampaignModal(false)}>
                <CreateCampaignForm refreshPage={fetchProjectDetails} onClose={() => setCampaignModal(false)} project={project} campaignSet={selectedCampaignSet} setProjectData={setProjectData} fetchProjectDetails={fetchProjectDetails}/>
            </Modal>
            {
                loading && (
                    <Flex align="center" minWidth="100%" flexWrap="nowrap">
                        <Box padding='6' boxShadow='lg' minWidth="100%">
                            <SkeletonText mt='4' noOfLines={4} spacing='4' />
                        </Box>
                    </Flex>
                )
            }
            {!loading && project && Object.keys(project).length > 0 && <Flex flexDirection='column' gap='2rem'>
                <Flex id="project-form" flexDirection={{ base: 'column', xl: 'row' }} gap='1.5rem'> 
                    <Flex as='div'
                        flexDirection='column'
                        backgroundColor='white'
                        minH='700px'
                        w={{ base: 'full', lg: '100%', xxl: '80%' }}
                        overflow='scroll'
                        borderRadius='3xl'
                        p={{base:'10px',md:'20px'}}
                        gap='20px'
                    >
                        <Flex gap='1.5rem' minW='full' flexDirection={{ base: 'column', md: 'row' }}>
                            <Avatar size='xl' name={project.projectName} src={project.imageURL} />
                            <Flex flexDirection='column' flex={1} mt={"15px"}>
                                <Text fontWeight='500' fontSize='20px'>{project.projectName}</Text>
                                <Text as={"p"} fontSize="12px" noOfLines={2}>{project.description}</Text>
                            </Flex>
                            <Flex justifySelf='flex-end' justifyContent={{ base: 'flex-start', md: 'flex-end' }} flex={1} gap={2}>
                                <Flex flexDirection='column' gap='2rem'>
                                    <Flex gap={2} justify='flex-end' align='center'>
                                        <IconButton onClick={() => navigate(`/edit/project/${project.id}`, { state: { data: project,navigateTo:'projectView'}})}
                                            variant='outline' size="md"
                                            colorScheme='teal'
                                            aria-label='Edit Project'
                                            icon={<EditIcon />}
                                        />
                                        <IconButton onClick={() => deleteItem(project)}
                                            variant='outline' size="md"
                                            colorScheme='teal'
                                            aria-label='Delete Project'
                                            icon={<TrashIcon />}
                                        />
                                    </Flex>
                                </Flex>
                            </Flex>

                        </Flex>
                        <Flex>
                            <BBButton shadow={true} leftIcon={<FaPlus />} onClick={() => setCampaignSetModal(true)} >Add</BBButton>
                        </Flex>
                        <Divider size={'lg'} borderColor="black" borderBottomWidth='3px' />
                        {/* <Flex mt={"20px"} gap={"10px"}> */}
                        {project.campaignSets && !!project.campaignSets.length &&
                            <Flex flexDirection='column' gap='1rem' overflow={"auto"}>
                                <Accordion width={'100%'} allowToggle>
                                    {project.campaignSets.map((campaignSet, index) => {
                                        return (
                                            <AccordionItem key={index}  width={"100%"}>
                                                <h2>
                                                    <AccordionButton width={"100%"} overflow={"auto"}>
                                                        <Box flex='1' textAlign='left'>
                                                            <Flex flexDirection='column'>
                                                                <Flex align='center'>
                                                                    <Text whiteSpace={"nowrap"} mr={"5px"}>{campaignSet.campaignSetName}</Text>
                                                                    <Flex mr={'10px'} align='center' justifySelf='flex-end' justifyContent={{ base: 'flex-start', md: 'flex-end' }} flex={1} gap={10}>
                                                                        {/* <Tag textAlign={'center'} minW={20} backgroundColor={'statusGreen'}><Spacer /><TagLabel textAlign={'center'}>{'created'}</TagLabel><Spacer /></Tag> */}
                                                                        <Text>{campaignSet && campaignSet.campaigns && !!campaignSet.campaigns.length ? `$${campaignSet.campaigns.reduce((sum, a) => Number(sum)+ Number(a.amount ? a.amount : 0), 0)}` : '$0'}</Text>
                                                                        <Progress hasStripe value={campaignSet.progress} minW={'200px'}/>
                                                                        <BBButton shadow={true} onClick={()=>{console.log("campaignSet",campaignSet);setSelectedCampaignSet(campaignSet);setCampaignModal(true)}}>Add Campaign</BBButton>
                                                                    </Flex>
                                                                </Flex>
                                                            </Flex>
                                                        </Box>
                                                        <AccordionIcon />
                                                    </AccordionButton>
                                                </h2>
                                                <AccordionPanel pb={4} width={"100%"}>
                                                    {campaignSet.campaigns && !!campaignSet.campaigns.length ? <>
                                                        <Flex >
                                                            <TableContainer width={'100%'}>
                                                                <Table variant='simple' width={"100%"}>
                                                                    <Tbody>
                                                                        {campaignSet.campaigns.map((campaign, index) => {
                                                                            return (<Tr key={index}>
                                                                                <Td>
                                                                                    <Flex cursor="pointer" align="center" minWidth="100%" flexWrap="nowrap" >
                                                                                        <Avatar name={campaign.name} src={campaign.imageUrl} w="50px" borderRadius="50px" me="10px" />
                                                                                        <Flex direction="column" onClick={() => navigate(`/campaigns/${campaign.id}`)}>
                                                                                            <Text
                                                                                                fontSize="md"
                                                                                                fontWeight="normal"
                                                                                                minWidth="100%"
                                                                                            >
                                                                                                {campaign.name}
                                                                                            </Text>
                                                                                        </Flex>
                                                                                    </Flex>
                                                                                </Td>
                                                                                <Td>
                                                                                    < Tag textAlign={'center'} minW={28} backgroundColor={campaignStatus[campaign.campaignStatus]}><Spacer /><TagLabel textAlign={'center'}>{campaign.campaignStatus}</TagLabel><Spacer /></Tag>
                                                                                </Td>
                                                                                <Td><Text color="black3" fontWeight="600" fontSize='md' textAlign={'center'}>
                                                                                    {campaign.gigs?.length} Gigs
                                                                                </Text></Td>
                                                                                <Td>
                                                                                    {campaign.gigs && !!campaign.gigs.length ?
                                                                                        <Flex alignItems="end"  >
                                                                                            <AvatarGroup size="md" max={2}>
                                                                                                {campaign.gigs.map((gig) =>
                                                                                                    <Avatar
                                                                                                        name={gig.gigName}
                                                                                                        title={gig.gigName}
                                                                                                        key={gig.id}
                                                                                                        src={gig.imageUrl}
                                                                                                    >
                                                                                                    </Avatar>
                                                                                                )}
                                                                                            </AvatarGroup>
                                                                                        </Flex>
                                                                                        : <Text>-</Text>}
                                                                                </Td>
                                                                                <Td>
                                                                                    {campaign.invites && !!campaign.invites.length ?
                                                                                        <Flex alignItems="start">
                                                                                            <AvatarGroup size="md" max={2}>
                                                                                                {campaign.invites.map((invite) =>
                                                                                                    <Avatar
                                                                                                        name={invite.user.firstName}
                                                                                                        title={invite.user.firstName}
                                                                                                        key={invite.id}
                                                                                                        src={invite.user.profileImageUrl}
                                                                                                    >
                                                                                                    </Avatar>
                                                                                                )}
                                                                                            </AvatarGroup>
                                                                                        </Flex>
                                                                                        : <Text>-</Text>}
                                                                                </Td>
                                                                                <Td minW={'200px'}>
                                                                                    <Progress hasStripe value={campaign.progress} />
                                                                                </Td>
                                                                                <Td>
                                                                                    <Flex direction={"row"} gap={"5px"}>
                                                                                        <Flex title="Edit" justifyContent="center" alignItems="center" >
                                                                                            <IconButton onClick={() => navigate(`/edit/campaign/${campaign.id}`,{state: {project:project,navigateTo:'projectView'}})}
                                                                                                variant='outline' size="xs"
                                                                                                colorScheme='teal' borderRadius={"50%"}
                                                                                                aria-label='Edit'
                                                                                                icon={<EditIcon />} />
                                                                                        </Flex>
                                                                                        <Flex title="Delete" justifyContent="center" alignItems="center">

                                                                                            <IconButton onClick={() => {
                                                                                                setDeleteCampaignId(campaign.id)
                                                                                                setShowDeleteCampaignModal(true)
                                                                                            }}
                                                                                                variant='outline' size="xs"
                                                                                                colorScheme='teal' borderRadius={"50%"}
                                                                                                aria-label='Delete'
                                                                                                icon={<TrashIcon />}
                                                                                            />
                                                                                        </Flex>
                                                                                        <Flex title="un-assign" justifyContent="center" alignItems="center">

                                                                                            <IconButton onClick={() => {
                                                                                                 setShowRemovingCampaignModal(true)
                                                                                                 setCampaignId(campaign.id)
                                                                                                 setCampaignSetId(campaignSet.id)
                                                                                                 setCampaignSetName(campaignSet.campaignSetName)
                                                                                            }}
                                                                                                variant='outline' size="xs"
                                                                                                colorScheme='teal' borderRadius={"50%"}
                                                                                                aria-label='Delete'
                                                                                                icon={<MinusIcon />}
                                                                                            />
                                                                                        </Flex>
                                                                                    </Flex>

                                                                                </Td>
                                                                            </Tr>)
                                                                        })}
                                                                    </Tbody>
                                                                </Table>
                                                            </TableContainer>
                                                        </Flex>
                                                    </> : <Text>No Campaigns</Text>}
                                                </AccordionPanel>
                                            </AccordionItem>
                                        )
                                    })}
                                </Accordion>
                            </Flex>
                        }
                    </Flex>
                </Flex>
                <Flex>
                </Flex>
            </Flex>}
        </>

    )

}

export default ProjectDetails

export const UnAssignCampaignModal = (props) => {
    const { visible, closeHandler, campaignId, project,campaignSetId,campaignSetName } = props
    const [showModal, setShowModal] = useState(false)
    const [removable, setRemovable] = useState(null)
    const [message, setMessage] = useState('')
    const [refresh, setRefresh] = useState(false)
    const [loading, setLoading] = useState(false)
    const toast = useToast()

    const checkCampaignDetails = async () => {
      if (!campaignId || isNaN(campaignId)) {
        setRemovable(false)
      }
      setRemovable(true)
    };

    const removeCampaign = () =>{
        setLoading(true)
        if(project && project.campaignSets && !!project.campaignSets.length){
            project.campaignSets.map(campaignSet=>{
                if(campaignSet.id === campaignSetId){
                    campaignSet.campaigns = campaignSet.campaigns.filter(campaign=> campaign.id !== campaignId)
                }
                return campaignSet
            })
        }
        createProject({ id: project.id,...project })
        .then(response => {
          toast({
            title: `Campaign Removed successfully`,
            description: "",
            status: 'success',
            position: 'top',
            duration: 3000,
            isClosable: true,
          })
          setLoading(false)
          if (response && !response.error) {
            setMessage('Removed successfully')
            setRefresh(true)
            props.setProjectData(response)
          }
        }).catch(err => {
            console.log("error",err)
          toast({
            title: `An error occured when removing the Campaign.`,
            description: "Please try after sometime or create an issue",
            status: 'error',
            position: 'top',
            duration: 3000,
            isClosable: true,
          })
          setMessage('Removing Campaign has failed. Please contact support')
          setLoading(false)
        })
    }
  
    useEffect(() => {
      setShowModal(!!visible)
      if (!!visible) checkCampaignDetails()
  
      return () => {
        setRemovable(null)
        setMessage('')
      }
    }, [visible])
  
    return !campaignId ? null : <>
      <Modal isOpen={showModal} onClose={() => closeHandler(refresh)} size="xl" scrollBehavior='outside' >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader color='brandbassBlue'>Un-Assign Campaign</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Flex p={8} borderColor='grayBlue' borderWidth="1px" borderRadius="lg" alignItems='center' justifyContent='center'>
              {
                removable === null && <Spinner size='xl' />
              }
              {
                removable === true &&
                <Text fontSize='20px'>
                  {
                    message || `Are you sure you want to Un-Assign this Campaign from ${campaignSetName}?`
                  }
                </Text>
              }
            </Flex>
          </ModalBody>
          <ModalFooter>
            {
              removable === true && !message && <>
                <Button
                  colorScheme="brandbassBlue"
                  variant="outline"
                  borderRadius="lg" mr={3}
                  onClick={() => closeHandler()}
                >
                  Cancel
                </Button>
                <Button
                  isLoading={loading}
                  loadingText="Removing..."
                  colorScheme="brandbassBlue"
                  borderRadius="lg"
                  onClick={removeCampaign}
                >
                  Remove
                </Button>
  
              </>
            }
            {
              (removable === false || !!message) && <>
                <Button
                  colorScheme="brandbassBlue"
                  borderRadius="lg"
                  onClick={() => closeHandler(refresh)}
                >
                  OK
                </Button>
              </>
            }
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  }
