// Chakra imports
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Stack,
  Input,
  Link,
  Switch,
  Text,
  useColorModeValue,
  FormHelperText,
  CircularProgress,
  Heading,
  Image
} from "@chakra-ui/react";
// Assets
import BgSignUp from "assets/img/BgSignUp.png";
import React, { useState, useEffect } from "react";
import { FaApple, FaFacebook, FaGoogle } from "react-icons/fa";
import { NavLink, useNavigate, Navigate } from "react-router-dom";
import { checkEmailAvailability, checkUsernameAvailability, signUpUser } from 'RestAPI';
import BrandBassShortIcon from 'assets/svg/brandbass-short-logo.svg'
import BrandBassIcon from 'assets/svg/brandbass-logo.svg'
import BBInput from "components/Input/Input";
import BBButton from "components/Button/Button";

function SignUp() {
  const navigate = useNavigate()
  const titleColor = useColorModeValue("teal.300", "teal.200");
  const textColor = useColorModeValue("gray.700", "white");
  const bgColor = useColorModeValue("white", "gray.700");

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [userName, setUserName] = useState('')
  const [email, setEmail] = useState('')
  const [userPassword, setUserPassword] = useState('')
  const [usernameAvailable, setUsernameAvailable] = useState(true)
  const [emailAvailable, setEmailAvailable] = useState(true)
  const [loading, setLoading] = useState(false)
  const [brandName, setBrandName] = useState('')
  const [website, setWebsite] = useState('')
  const [validPassword, setValidPassword] = useState(true);
  const [submit, setSubmit] = useState(false);

  const usernameAvailability = () => {
    if (userName) {
      checkUsernameAvailability(userName)
        .then(response => {
          if (response.data) {
            setUsernameAvailable(response.data.available)
          }
        })
    }
  }

  const checkPassword = () => {
    const regexFunc = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,16}$/;
    if (!regexFunc.test(userPassword)) {
      setValidPassword(false);
      return;
    }
    setValidPassword(true);
  };

  const emailAvailability = () => {
    if (email) {
      checkEmailAvailability(email)
        .then(response => {
          if (response.data) {
            setEmailAvailable(response.data.available)
          }
        })
    }
  }
  const registerUser = () => {
    setSubmit(true)
    if(!userName || !email || !userPassword ||  !firstName || !lastName || !brandName || !website){
      return;
    }
    setLoading(true)
    signUpUser({ userName: userName.trim(), userEmail: email.trim(), userPassword }, { role: 'BRAND_SUPER_ADMIN', firstName, lastName, brandName, website })
      .then(response => {
        setLoading(false)
        if (response) {
          localStorage.setItem('userName', userName.trim())
          localStorage.setItem('userEmail', email.trim())
          navigate(`/auth/confirm-registration`)
        }
      }).catch(err => setLoading(false))
  }

  return (
    <Flex position='relative'>
      <Flex
        mx='auto'
        // h={{ sm: "initial", md: "100vh", lg: "100vh" }}
        // w='100%'
        maxW='1044px'
        textAlign="center"
        justifyContent='center'
      // pb='50px'
      // mt={'30px'}
      // pt={{ sm: "100px", md: "0px" }}
      >
        <Flex
          alignItems='center'
          justifyContent='start'
          style={{ userSelect: "none" }}
          w={{ base: "100%", md: "100%", lg: "100%" }}>
          <Stack >
            <Flex justifyContent='center' m={4}>
              <Flex gap='1rem' alignItems='center'>
                <Image src={BrandBassShortIcon} w='67px' h='87px' />
                <Image src={BrandBassIcon} w='250px' />
              </Flex>
            </Flex>
            <Flex
              direction='column'
              w='100%'
              background='transparent'
              p='12px'
              mt={{ md: "100px", lg: "40px" }}>
              <Heading color={textColor} fontSize='32px' mb='10px'>
                Welcome!
              </Heading>
              <Text
                ms='4px'
                color={textColor}
                fontWeight='bold'
                fontSize='14px'>
                Enter your brand information to get started on the Brandbass dashboard!
              </Text>
              <Flex
                mt={'10px'}
                direction='column'
                alignSelf='center'
                justifySelf='center'
                overflow='hidden'>
                <Box
                  position='absolute'
                  minH={{ base: "50vh", md: "50vh" }}
                  w={{ md: "calc(100vw - 50px)" }}
                  borderRadius={{ md: "15px" }}
                  left='0'
                  right='0'
                  bgRepeat='no-repeat'
                  overflow='hidden'
                  zIndex='-1'
                  top='0'

                  // bgImage={BgSignUp}
                  bgImage={`linear-gradient(180deg, #4DA6F6 0%, rgba(77, 166, 246, 0) 100%)`}
                  bgSize='cover'
                  mx={{ md: "auto" }}
                  mt={-70}>
                </Box>
                <Flex alignItems='center' justifyContent='center' mb='60px' >
                  <Flex
                    direction='column'
                    w='445px'
                    background='transparent'
                    borderRadius='15px'
                    p='40px'
                    mx={{ base: "100px" }}
                    bg={bgColor}
                    boxShadow='0 20px 27px 0 rgb(0 0 0 / 5%)'>
                    <FormControl>
                      <BBInput
                        label="Brand Name"
                        value={brandName}
                        placeholder='Your brand name'
                        onChange={(e) => {setSubmit(false);setBrandName(e.target.value)}}
                        required={true}
                        isInvalid={submit && !brandName}/>
                    </FormControl>

                    <FormControl mt={5}>
                      <BBInput
                        label="Website"
                        value={website}
                        placeholder='Your website'
                        onChange={(e) => {setSubmit(false);setWebsite(e.target.value)}}
                        required={true}
                        isInvalid={submit && !website}/>
                    </FormControl>

                    <FormControl mt={5}>
                      <BBInput
                        label="Work Email"
                        value={email}
                        placeholder='Your email address'
                        onChange={(e) => {setSubmit(false);setEmail(e.target.value)}}
                        onBlur={emailAvailability}
                        status={!emailAvailable ? 'error' : null} message={'Email is not available. Please choose another one.'}
                        required={true}
                        isInvalid={submit && !email}
                      />

                    </FormControl>

                    <Flex direction="row" mt={5}>
                      <Flex direction="column" mr='4px'>
                        <BBInput
                          label="First Name"
                          value={firstName}
                          placeholder='Your first name'
                          onChange={(e) => {setSubmit(false);setFirstName(e.target.value)}}
                          required={true}
                          isInvalid={submit && !firstName}
                         />
                      </Flex>
                      <Flex direction="column" ml="4px">
                        <BBInput
                          label="Last Name"
                          value={lastName}
                          placeholder='Your last name'
                          onChange={(e) => {setSubmit(false);setLastName(e.target.value)}} 
                          required={true}
                          isInvalid={submit && !lastName}
                          />
                      </Flex>
                    </Flex>
                    <FormControl mt={5}>
                      <BBInput
                        label="Username"
                        value={userName}
                        placeholder='Choose username'
                        onChange={(e) => {setSubmit(false);setUserName(e.target.value)}}
                        onBlur={usernameAvailability}
                        status={!usernameAvailable ? 'error' : null} message={'Username is not available. Please choose another one.'}
                        required={true}
                        isInvalid={submit && !userName}
                      />
                    </FormControl>
                    <FormControl mt={5}>
                      <BBInput
                        label="Password"
                        type='password'
                        value={userPassword}
                        placeholder='Your password'
                        onChange={(e) => {setSubmit(false);setUserPassword(e.target.value)}}
                        onBlur={checkPassword}
                        status={!validPassword ? 'error' : null}
                        message={'Password must be at least 8 characters and it must contain at least one uppercase, one lowercase, one number and one special character'}
                        required={true}
                        isInvalid={submit && !userPassword}
                      />
                    </FormControl>

                    {loading ? <CircularProgress value={80} /> :

                      <BBButton type='submit'
                        w='100%'
                        h='45'
                        mb='24px'
                        mt="30px"
                        onClick={registerUser}>
                        SIGN UP
                      </BBButton>

                    }
                    <Flex
                      flexDirection='column'
                      justifyContent='center'
                      alignItems='center'
                      maxW='100%'
                      mt='0px'>
                      <Text color={textColor} fontWeight='medium'>
                        Already have an account?
                        <NavLink style={{ color: titleColor, fontWeight: 'bold', marginLeft: 8 }} to="/auth">
                          Sign In
                        </NavLink>
                      </Text>
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          </Stack>
        </Flex>
      </Flex>
    </Flex>

  );
}

export default SignUp;
