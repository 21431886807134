import { Badge, Box, Button, Flex, Image, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Spinner, Text } from "@chakra-ui/react"
import { CardElement, Elements, PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js"
import BBButton from "components/Button/Button";
import { BBSecondaryButton } from "components/Button/Button";
import { BrandBassIcon } from "components/Icons/Icons";
import { TrashIcon } from "components/Icons/Icons";
import { AppContext } from "contexts/AppContext";
import { useCallback, useContext, useEffect, useMemo, useState } from "react"
import { updatePaymentStatus } from "RestAPI";
import "./Payment.css"
import SuccessIcon from "assets/icons/Success.svg"
import { getCards } from "RestAPI";
import { PlusSquareIcon } from "@chakra-ui/icons";
import { makeCampaignPayment } from "RestAPI";
import { updatePaymentIntent } from "RestAPI";
import { createPaymentIntent } from "RestAPI";
import { useNavigate } from "react-router-dom";
const secureStripeLogo = 'https://assets-global.website-files.com/5e45da2b13017cb9f2787118/5e45da2b13017c83307876c8_Secure-payments-stripe.jpg'

const PaymentModal = (props) => {
  const { placeholderImage } = useContext(AppContext)
  const elements = useElements();
  const { visible, closeHandler, dataHandler, campaign, paymentDetails, clientSecret, successHandler } = props
  const [showModal, setShowModal] = useState(false)
  const [succeeded, setSucceeded] = useState(false);
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState('');
  const [disabled, setDisabled] = useState(true);

  const submit = () => {
    dataHandler()
    closeHandler()
  }

  const handleSubmit = async ev => {
    ev.preventDefault();
    setProcessing(true);

    const payload = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement)
      }
    });

    if (payload.error) {
      setError(`Payment failed ${payload.error.message}`);
      setProcessing(false);
    } else {
      setError(null);
      setProcessing(false);
      setSucceeded(true);
      await updatePaymentStatus(paymentDetails.paymentId, 'Succeeded');
    }
  };

  // const UpdatePaymentStatus = () => {
  //   const updatePaymentFor0 = async () => await updatePaymentStatus(clientSecret, 'Succeeded');
  //   useEffect(updatePaymentFor0, [])
  //   return <></>
  // }

  useEffect(() => setShowModal(!!visible), [visible])
  useEffect(() => !!succeeded && setTimeout((e) => {
    successHandler()
    closeHandler(e, true)
  }, 5000), [succeeded])

  return !paymentDetails.paymentId && !clientSecret
    ? null
    :
    <>
      <Modal isOpen={showModal} onClose={closeHandler} size="6xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader color='brandbassBlue'>
            <Text fontSize='2xl' fontFamily='Rubik' >Checkout</Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Box
              p={8}
              borderWidth="1.5px"
              borderRadius="lg"
              overflow="scroll"
            >
              <Flex flexDirection={{ base: 'column', lg: 'row' }} w='full' gap={6}>
                <Flex flexDirection='column' minW='50%' flex={1} justify='space-between' gap='1rem' >
                  <Flex flexDirection='column' gap='1rem' overflow='scroll' maxH='400px'>
                    {
                      campaign.gigs.map((gig, idx) => <>
                        <Box
                          key={idx}
                          p="3"
                          rounded="md"
                          borderWidth='1px' borderColor='primary'
                        >
                          <Flex gap="20px" flex={1}>
                            <Image
                              h='75px'
                              w='75px'
                              minW='75px'
                              minH='75px'
                              src={gig.image || gig.imageUrl}
                              fallbackSrc={placeholderImage(75)}
                              borderRadius="lg"
                              overflow='hidden'
                            />
                            <Flex justifyContent="space-evenly" flexDirection="column" w='full' overflow='hidden'>
                              <Text fontSize="lg" color="brandbassBlue" isTruncated title={gig.gigName}>
                                {gig.gigName}
                              </Text>
                              <Badge w='fit-content' p='2px' colorScheme='gray' variant='subtle'>{gig.gigVariation?.toUpperCase()}</Badge>
                            </Flex>
                            <Badge alignSelf='flex-start' p='2px'>
                              ${Number(gig.gigPrice) + Number(gig.giveawayValue)}
                            </Badge>
                          </Flex>
                        </Box>
                      </>
                      )
                    }
                  </Flex>
                  <Flex flexDirection='column' mt='1rem' borderTop='0.5px solid' borderTopColor='gray7'>
                    <Flex opacity={0} justify='space-between' px={8} py={4} >
                      <Text fontSize='12px'>You have 0 credits</Text>
                      <BBSecondaryButton h='18px'><Text fontSize='10px'>Apply</Text></BBSecondaryButton>
                    </Flex>
                    <Flex justify='space-between' px={8} py={4} >
                      <Text fontSize='18px'>Total amount</Text>
                      <Text fontWeight='600' fontSize='20px'>${paymentDetails.amount/100}</Text>
                    </Flex>

                  </Flex>
                </Flex>
                <Flex flexDirection='column' flex={1}>
                  {
                    !paymentDetails.amount && !!paymentDetails?.paymentId && <>
                      <Flex flexDirection='column' align='center' gap='1rem' boxShadow='base' rounded='md' flex={1}>
                        <Text fontSize='xl' fontWeight='500'>Credits applied</Text>
                        <Image src={SuccessIcon} w='50%' h='50%' />
                        <Text>Credit balance was used to pay for this campaign.</Text>
                        {['Exclusive', 'Exclusive group'].includes(campaign.ambassadorFlag) ? null : <BBButton mt='3rem' onClick={successHandler}>Invite Ambassadors</BBButton>}
                      </Flex>
                    </>
                  }
                  {
                    !!paymentDetails.amount &&
                    <CheckoutForm campaign={campaign} paymentInfo={paymentDetails} secret={clientSecret} />
                  }
                </Flex>
              </Flex>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
}

export default PaymentModal

export const CheckoutForm = (props) => {
  const { paymentInfo, subscriptionIntent } = props
  const [isLoading, setIsLoading] = useState(true)
  const [savedCards, setSavedCards] = useState(null)
  const [selectedCard, setSelectedCard] = useState(null)

  const [paymentDetails, setpaymentDetails] = useState(paymentInfo)
  const [options, setOptions] = useState(null)
  const [stripeReferenceId, setStripeReferenceId] = useState(null)
  const currentYear = new Date().getFullYear()
  const currentMonth = new Date().getMonth() + 1

  const fetchCards = async () => {
    const cards = await getCards()
    if (!cards || !cards.data || !cards.data.length) {
      setSelectedCard('new')
      return setSavedCards([])
    }
    // setSavedCards(cards.data)
    setIsLoading(false)
    const validCardsByYear = cards.data.filter(item => item.card.exp_year > currentYear)
    const validCardsByMonth = cards.data.filter(item => !(item.card.exp_year < currentYear) && !(item.card.exp_year > currentYear) && (item.card.exp_month >= currentMonth))
    if(validCardsByYear.length || validCardsByMonth.length){
      setSavedCards([...validCardsByYear, ...validCardsByMonth])
    }else{
      setSelectedCard('new')
    }
  }
  const fetchPaymentIntent = async () => {
    setIsLoading(true)
    let paymentObjResponse = {}
    if(subscriptionIntent && subscriptionIntent.client_secret){
      paymentObjResponse.data = subscriptionIntent
    }else{
      paymentObjResponse = await createPaymentIntent({
        paymentId: paymentDetails.paymentId,
        paymentMethod: !selectedCard || selectedCard === 'new' ? "" : selectedCard,
        amount: paymentDetails.amount
      })
    }

    if (!paymentObjResponse) return setIsLoading(false)
    const optionsObj = { appearance: { theme: 'stripe' } }
    if (!!paymentObjResponse?.data?.client_secret) optionsObj.clientSecret = paymentObjResponse?.data?.client_secret
    setOptions(optionsObj)
    setStripeReferenceId(paymentObjResponse?.data?.id)
    setIsLoading(false)
  }

  useEffect(fetchCards, [])
  useEffect(() => setpaymentDetails(paymentInfo), [paymentInfo])
  useEffect(() => {
    if (!selectedCard) return
    fetchPaymentIntent()
  }, [selectedCard])

  return (!savedCards || !savedCards.length || selectedCard === 'new')
    ? <NewPaymentMethod
      paymentDetails={paymentDetails}
      options={options}
      secureStripeLogo={secureStripeLogo}
      selectedCard={selectedCard}
      setSelectedCard={setSelectedCard}
      savedCards={savedCards}
      campaign={props.campaign}
    />
    : <ExistingPaymentMethod
      paymentDetails={paymentDetails}
      options={options}
      secureStripeLogo={secureStripeLogo}
      selectedCard={selectedCard}
      setSelectedCard={setSelectedCard}
      savedCards={savedCards}
      stripeReferenceId={stripeReferenceId}
    />
}

export const NewPaymentMethod = (props) => {
  const stripe = useStripe()
  const { options, isLoading, secureStripeLogo, paymentDetails, selectedCard, setSelectedCard, stripeReferenceId, savedCards, campaign } = props
  const [loading, setLoading] = useState(isLoading)
  const [stripeOptions, setStripeOptions] = useState(options || null)
  useEffect(() => setStripeOptions(options), [options])
  useEffect(() => setLoading(!!isLoading), [isLoading])
  return !stripeOptions ? null : !!loading ? <Spinner w='8rem' h='8rem' alignSelf='center' speed="0.9s" /> : <>
    <Flex flexDirection='column' flex={1} justify='space-between' gap='5rem'>
      <form id="payment-form">
        <Flex flexDirection='column' flex={1} justify='space-between' gap='5rem'>
          <Flex flexDirection='column' gap='1rem' w='60%' alignSelf='center'>
            <Elements options={stripeOptions} stripe={stripe}>
              <PaymentElement id="payment-element" />
              <MakePayment stripeReferenceId={stripeReferenceId} newCard={true} paymentInfo={paymentDetails} clientSecret={stripeOptions?.clientSecret} paymentMethod={selectedCard} CampaignID={campaign?.id}/>
            </Elements>
            {savedCards?.length ? <BBSecondaryButton onClick={() => setSelectedCard(null)}>
              Use saved card
            </BBSecondaryButton> : null}
          </Flex>
          <Flex flex={1} justify='flex-end'>
            <Image src={secureStripeLogo} w='33%' />
          </Flex>
        </Flex>
      </form>
    </Flex>
  </>
}

export const ExistingPaymentMethod = (props) => {
  const stripe = useStripe()
  const { options, isLoading, savedCards, secureStripeLogo, paymentDetails, selectedCard, setSelectedCard, stripeReferenceId } = props
  const [loading, setLoading] = useState(isLoading)
  const [stripeOptions, setStripeOptions] = useState(options || null)

  const chooseCard = (id) => {
    if (!id) return
    if (id === selectedCard) {
      return setSelectedCard(null)
    }
    return setSelectedCard(id)
  }

  useEffect(() => setStripeOptions(options), [options])
  useEffect(() => setLoading(!!isLoading), [isLoading])

  return !savedCards ? null : !!loading ? <Spinner w='8rem' h='8rem' alignSelf='center' speed="0.9s" /> : <Flex flexDirection='column' flex={1} justify='space-between' gap='5rem'>
    <Flex flexDirection='column' gap='1rem'>
      {
        savedCards.map((savedCard, idx) =>
          <Box key={idx} borderWidth='1px' borderColor='primary' bgColor={selectedCard === savedCard.id ? 'primary' : 'white'} p='0.9rem' rounded='lg' overflow='hidden' onClick={() => chooseCard(savedCard.id)} cursor='pointer' color={selectedCard === savedCard.id ? 'white' : 'primary'}>
            <Flex justify='space-between'>
              <Flex flexDirection='column'>
                <Text fontFamily='Rubik' fontWeight='500'>
                  xxxx xxxx xxxx {savedCard.card.last4}
                </Text>
                <Text fontFamily='Rubik' fontWeight='500' textTransform='uppercase'>
                  {savedCard.card.exp_month.toString().padStart(2, "0")}/{savedCard.card.exp_year.toString().slice(-2)}
                </Text>
              </Flex>
              <Flex flexDirection='column'>
                <Text fontFamily='Rubik' fontWeight='500' textTransform='uppercase'>
                  {savedCard.card.brand}
                </Text>
                <Text  fontFamily='Rubik' fontWeight='500' textTransform='uppercase' fontSize='2rem'>
                  {getFlag(savedCard.card.country)}
                </Text>
              </Flex>
            </Flex>
          </Box>
        )
      }
    </Flex>

    <Flex flexDirection='column' gap='1rem' w='60%' alignSelf='center'>
      <BBSecondaryButton onClick={() => setSelectedCard('new')} >
        <PlusSquareIcon /> &nbsp; Add Card
      </BBSecondaryButton>
      {
        !!selectedCard && !!stripeOptions &&
        <Elements options={stripeOptions} stripe={stripe}>
          <MakePayment stripeReferenceId={stripeReferenceId} savedCard={true} paymentInfo={paymentDetails} clientSecret={stripeOptions?.clientSecret} paymentMethod={selectedCard} />
        </Elements>
      }
    </Flex>
    <Flex flex={1} justify='flex-end'>
      <Image src={secureStripeLogo} w='33%' />
    </Flex>
  </Flex>
}


export const MakePayment = (props) => {
  const { paymentInfo, clientSecret, paymentMethod, savedCard, newCard, stripeReferenceId } = props
  const stripe = useStripe()
  const elements = useElements()
  const navigate = useNavigate()
  const [paymentIsLoading, setPaymentIsLoading] = useState(false)
  const [paymentDetails, setpaymentDetails] = useState(paymentInfo || {})

  const submitNewCard = async (e) => {
    e.preventDefault();
    if (!stripe || !elements) return
    setPaymentIsLoading(true);
    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${window.location.href}?paymentId=${paymentInfo?.paymentId}&CampaignID=${props.CampaignID}`,
      },
    });
  }

  const submitSavedCard = async (e) => {
    e.preventDefault();
    if (!stripe || !elements) return
    setPaymentIsLoading(true);

    const paymentMethodId = !paymentMethod || paymentMethod === 'new' ? "" : paymentMethod
    const stripeResponse = await stripe.confirmCardPayment(clientSecret, {
      payment_method: paymentMethodId,
    });

    if (!stripeResponse?.error) {
      console.log(stripeResponse)
      const updatePaymentResponse = await updatePaymentStatus(paymentDetails.paymentId, 'Succeeded', stripeReferenceId, paymentMethodId);
      if(updatePaymentResponse?.data?.id) navigate(`${window.location.pathname}?payment_intent=${clientSecret}&redirect_status=succeeded`)
    }

    if (!!stripeResponse?.error) {
      console.error(stripeResponse?.error)
    }

    setPaymentIsLoading(false);
  }

  useEffect(() => setpaymentDetails(paymentInfo), [paymentInfo])

  return <>
    <BBButton onClick={!!savedCard ? submitSavedCard : submitNewCard} disabled={paymentIsLoading || !stripe || !elements} id="submit">
      {paymentIsLoading ? <Spinner /> : `Pay now - $${paymentDetails?.amount/100}`}
    </BBButton>
  </>
}

export const SubscriptionPaymentModal = (props) => {
  const { visible, closeHandler, subscription, paymentDetails, subscriptionIntent } = props
  const [showModal, setShowModal] = useState(false)
  
  useEffect(() => setShowModal(!!visible), [visible])
  
  return !paymentDetails.paymentId ? null
    :
    <>
      <Modal isOpen={showModal} onClose={closeHandler} size="6xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader color='brandbassBlue'>
            <Text fontSize='2xl' fontFamily='Rubik' >Checkout</Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Box
              p={8}
              borderWidth="1.5px"
              borderRadius="lg"
              overflow="scroll"
            >
              <Flex flexDirection={{ base: 'column', lg: 'row' }} w='full' gap={6}>
                <Flex flexDirection='column' minW='50%' flex={1} justify='space-between' gap='1rem' >
                  <Flex flexDirection='column' gap='1rem' overflow='scroll' maxH='400px'>
                  <Text>Subscription: {subscription.name.toUpperCase()}</Text>
                    {
                      subscription.products.map((product, idx) => 
                        <Box
                          key={idx}
                          p="3"
                          rounded="md"
                          borderWidth='1px' borderColor='primary'
                        >
                          <Flex gap="20px" flex={1}>                            
                            <Flex justifyContent="space-evenly" flexDirection="column" w='full' overflow='hidden'>
                              <Text fontSize="lg" color="brandbassBlue" isTruncated title={product.productName}>
                                {product.productName} - {product.count} {product.unitLabel}s
                              </Text>
                              <Badge w='fit-content' p='2px' colorScheme='gray' variant='subtle'>${product.productPrice/100}</Badge>
                            </Flex>
                            <Badge alignSelf='flex-start' p='2px'>
                              ${Number(product.productPrice/100) * Number(product.count)}
                            </Badge>
                          </Flex>
                        </Box>
                      )
                    }
                  </Flex>
                  <Flex flexDirection='column' mt='1rem' borderTop='0.5px solid' borderTopColor='gray7'>
                    <Flex justify='space-between' px={8} py={4} >
                      <Text fontSize='18px'>Total amount</Text>
                      <Text fontWeight='600' fontSize='20px'>${paymentDetails.amount/100}</Text>
                    </Flex>
                  </Flex>
                </Flex>
                <Flex flexDirection='column' flex={1}>                 
                  {
                    !!paymentDetails.amount &&
                    <CheckoutForm paymentInfo={paymentDetails} subscriptionIntent={subscriptionIntent}/>
                  }
                </Flex>
              </Flex>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
}

export const CreditPaymentModal = (props) => {
  const { visible, closeHandler, creditList, paymentDetails } = props
  const [showModal, setShowModal] = useState(false)
  
  useEffect(() => setShowModal(!!visible), [visible])
  
  return !paymentDetails.paymentId ? null
    :
    <>
      <Modal isOpen={showModal} onClose={closeHandler} size="6xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader color='brandbassBlue'>
            <Text fontSize='2xl' fontFamily='Rubik' >Checkout</Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Box
              p={8}
              borderWidth="1.5px"
              borderRadius="lg"
              overflow="scroll"
            >
              <Flex flexDirection={{ base: 'column', lg: 'row' }} w='full' gap={6}>
                <Flex flexDirection='column' minW='50%' flex={1} justify='space-between' gap='1rem' >
                  <Flex flexDirection='column' gap='1rem' overflow='scroll' maxH='400px'>
                  
                    {
                      creditList.map((credit, idx) => 
                        <Box
                          key={idx}
                          p="3"
                          rounded="md"
                          borderWidth='1px' borderColor='primary'
                        >
                          <Flex gap="20px" flex={1}>                            
                            <Flex justifyContent="space-evenly" flexDirection="column" w='full' overflow='hidden'>
                              <Text fontSize="lg" color="brandbassBlue" isTruncated title={credit.gigVariation}>
                                {credit.gigVariation} - {credit.numCredits} credits
                              </Text>
                              <Badge w='fit-content' p='2px' colorScheme='gray' variant='subtle'>${credit.unitPrice/100}</Badge>
                            </Flex>
                            <Badge alignSelf='flex-start' p='2px'>
                              ${Number(credit.unitPrice/100) * Number(credit.numCredits)}
                            </Badge>
                          </Flex>
                        </Box>
                      )
                    }
                  </Flex>
                  <Flex flexDirection='column' mt='1rem' borderTop='0.5px solid' borderTopColor='gray7'>
                    <Flex justify='space-between' px={8} py={4} >
                      <Text fontSize='18px'>Total amount</Text>
                      <Text fontWeight='600' fontSize='20px'>${paymentDetails.amount/100}</Text>
                    </Flex>
                  </Flex>
                </Flex>
                <Flex flexDirection='column' flex={1}>                 
                  {
                    !!paymentDetails.amount &&
                    <CheckoutForm paymentInfo={paymentDetails}/>
                  }
                </Flex>
              </Flex>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
}

export const getFlag = (code) => {
  if (!code) return code
  code = code.toUpperCase()
  if (code == 'AD') return '🇦🇩';
  if (code == 'AE') return '🇦🇪';
  if (code == 'AF') return '🇦🇫';
  if (code == 'AG') return '🇦🇬';
  if (code == 'AI') return '🇦🇮';
  if (code == 'AL') return '🇦🇱';
  if (code == 'AM') return '🇦🇲';
  if (code == 'AO') return '🇦🇴';
  if (code == 'AQ') return '🇦🇶';
  if (code == 'AR') return '🇦🇷';
  if (code == 'AS') return '🇦🇸';
  if (code == 'AT') return '🇦🇹';
  if (code == 'AU') return '🇦🇺';
  if (code == 'AW') return '🇦🇼';
  if (code == 'AX') return '🇦🇽';
  if (code == 'AZ') return '🇦🇿';
  if (code == 'BA') return '🇧🇦';
  if (code == 'BB') return '🇧🇧';
  if (code == 'BD') return '🇧🇩';
  if (code == 'BE') return '🇧🇪';
  if (code == 'BF') return '🇧🇫';
  if (code == 'BG') return '🇧🇬';
  if (code == 'BH') return '🇧🇭';
  if (code == 'BI') return '🇧🇮';
  if (code == 'BJ') return '🇧🇯';
  if (code == 'BL') return '🇧🇱';
  if (code == 'BM') return '🇧🇲';
  if (code == 'BN') return '🇧🇳';
  if (code == 'BO') return '🇧🇴';
  if (code == 'BQ') return '🇧🇶';
  if (code == 'BR') return '🇧🇷';
  if (code == 'BS') return '🇧🇸';
  if (code == 'BT') return '🇧🇹';
  if (code == 'BV') return '🇧🇻';
  if (code == 'BW') return '🇧🇼';
  if (code == 'BY') return '🇧🇾';
  if (code == 'BZ') return '🇧🇿';
  if (code == 'CA') return '🇨🇦';
  if (code == 'CC') return '🇨🇨';
  if (code == 'CD') return '🇨🇩';
  if (code == 'CF') return '🇨🇫';
  if (code == 'CG') return '🇨🇬';
  if (code == 'CH') return '🇨🇭';
  if (code == 'CI') return '🇨🇮';
  if (code == 'CK') return '🇨🇰';
  if (code == 'CL') return '🇨🇱';
  if (code == 'CM') return '🇨🇲';
  if (code == 'CN') return '🇨🇳';
  if (code == 'CO') return '🇨🇴';
  if (code == 'CR') return '🇨🇷';
  if (code == 'CU') return '🇨🇺';
  if (code == 'CV') return '🇨🇻';
  if (code == 'CW') return '🇨🇼';
  if (code == 'CX') return '🇨🇽';
  if (code == 'CY') return '🇨🇾';
  if (code == 'CZ') return '🇨🇿';
  if (code == 'DE') return '🇩🇪';
  if (code == 'DJ') return '🇩🇯';
  if (code == 'DK') return '🇩🇰';
  if (code == 'DM') return '🇩🇲';
  if (code == 'DO') return '🇩🇴';
  if (code == 'DZ') return '🇩🇿';
  if (code == 'EC') return '🇪🇨';
  if (code == 'EE') return '🇪🇪';
  if (code == 'EG') return '🇪🇬';
  if (code == 'EH') return '🇪🇭';
  if (code == 'ER') return '🇪🇷';
  if (code == 'ES') return '🇪🇸';
  if (code == 'ET') return '🇪🇹';
  if (code == 'FI') return '🇫🇮';
  if (code == 'FJ') return '🇫🇯';
  if (code == 'FK') return '🇫🇰';
  if (code == 'FM') return '🇫🇲';
  if (code == 'FO') return '🇫🇴';
  if (code == 'FR') return '🇫🇷';
  if (code == 'GA') return '🇬🇦';
  if (code == 'GB') return '🇬🇧';
  if (code == 'GD') return '🇬🇩';
  if (code == 'GE') return '🇬🇪';
  if (code == 'GF') return '🇬🇫';
  if (code == 'GG') return '🇬🇬';
  if (code == 'GH') return '🇬🇭';
  if (code == 'GI') return '🇬🇮';
  if (code == 'GL') return '🇬🇱';
  if (code == 'GM') return '🇬🇲';
  if (code == 'GN') return '🇬🇳';
  if (code == 'GP') return '🇬🇵';
  if (code == 'GQ') return '🇬🇶';
  if (code == 'GR') return '🇬🇷';
  if (code == 'GS') return '🇬🇸';
  if (code == 'GT') return '🇬🇹';
  if (code == 'GU') return '🇬🇺';
  if (code == 'GW') return '🇬🇼';
  if (code == 'GY') return '🇬🇾';
  if (code == 'HK') return '🇭🇰';
  if (code == 'HM') return '🇭🇲';
  if (code == 'HN') return '🇭🇳';
  if (code == 'HR') return '🇭🇷';
  if (code == 'HT') return '🇭🇹';
  if (code == 'HU') return '🇭🇺';
  if (code == 'ID') return '🇮🇩';
  if (code == 'IE') return '🇮🇪';
  if (code == 'IL') return '🇮🇱';
  if (code == 'IM') return '🇮🇲';
  if (code == 'IN') return '🇮🇳';
  if (code == 'IO') return '🇮🇴';
  if (code == 'IQ') return '🇮🇶';
  if (code == 'IR') return '🇮🇷';
  if (code == 'IS') return '🇮🇸';
  if (code == 'IT') return '🇮🇹';
  if (code == 'JE') return '🇯🇪';
  if (code == 'JM') return '🇯🇲';
  if (code == 'JO') return '🇯🇴';
  if (code == 'JP') return '🇯🇵';
  if (code == 'KE') return '🇰🇪';
  if (code == 'KG') return '🇰🇬';
  if (code == 'KH') return '🇰🇭';
  if (code == 'KI') return '🇰🇮';
  if (code == 'KM') return '🇰🇲';
  if (code == 'KN') return '🇰🇳';
  if (code == 'KP') return '🇰🇵';
  if (code == 'KR') return '🇰🇷';
  if (code == 'KW') return '🇰🇼';
  if (code == 'KY') return '🇰🇾';
  if (code == 'KZ') return '🇰🇿';
  if (code == 'LA') return '🇱🇦';
  if (code == 'LB') return '🇱🇧';
  if (code == 'LC') return '🇱🇨';
  if (code == 'LI') return '🇱🇮';
  if (code == 'LK') return '🇱🇰';
  if (code == 'LR') return '🇱🇷';
  if (code == 'LS') return '🇱🇸';
  if (code == 'LT') return '🇱🇹';
  if (code == 'LU') return '🇱🇺';
  if (code == 'LV') return '🇱🇻';
  if (code == 'LY') return '🇱🇾';
  if (code == 'MA') return '🇲🇦';
  if (code == 'MC') return '🇲🇨';
  if (code == 'MD') return '🇲🇩';
  if (code == 'ME') return '🇲🇪';
  if (code == 'MF') return '🇲🇫';
  if (code == 'MG') return '🇲🇬';
  if (code == 'MH') return '🇲🇭';
  if (code == 'MK') return '🇲🇰';
  if (code == 'ML') return '🇲🇱';
  if (code == 'MM') return '🇲🇲';
  if (code == 'MN') return '🇲🇳';
  if (code == 'MO') return '🇲🇴';
  if (code == 'MP') return '🇲🇵';
  if (code == 'MQ') return '🇲🇶';
  if (code == 'MR') return '🇲🇷';
  if (code == 'MS') return '🇲🇸';
  if (code == 'MT') return '🇲🇹';
  if (code == 'MU') return '🇲🇺';
  if (code == 'MV') return '🇲🇻';
  if (code == 'MW') return '🇲🇼';
  if (code == 'MX') return '🇲🇽';
  if (code == 'MY') return '🇲🇾';
  if (code == 'MZ') return '🇲🇿';
  if (code == 'NA') return '🇳🇦';
  if (code == 'NC') return '🇳🇨';
  if (code == 'NE') return '🇳🇪';
  if (code == 'NF') return '🇳🇫';
  if (code == 'NG') return '🇳🇬';
  if (code == 'NI') return '🇳🇮';
  if (code == 'NL') return '🇳🇱';
  if (code == 'NO') return '🇳🇴';
  if (code == 'NP') return '🇳🇵';
  if (code == 'NR') return '🇳🇷';
  if (code == 'NU') return '🇳🇺';
  if (code == 'NZ') return '🇳🇿';
  if (code == 'OM') return '🇴🇲';
  if (code == 'PA') return '🇵🇦';
  if (code == 'PE') return '🇵🇪';
  if (code == 'PF') return '🇵🇫';
  if (code == 'PG') return '🇵🇬';
  if (code == 'PH') return '🇵🇭';
  if (code == 'PK') return '🇵🇰';
  if (code == 'PL') return '🇵🇱';
  if (code == 'PM') return '🇵🇲';
  if (code == 'PN') return '🇵🇳';
  if (code == 'PR') return '🇵🇷';
  if (code == 'PS') return '🇵🇸';
  if (code == 'PT') return '🇵🇹';
  if (code == 'PW') return '🇵🇼';
  if (code == 'PY') return '🇵🇾';
  if (code == 'QA') return '🇶🇦';
  if (code == 'RE') return '🇷🇪';
  if (code == 'RO') return '🇷🇴';
  if (code == 'RS') return '🇷🇸';
  if (code == 'RU') return '🇷🇺';
  if (code == 'RW') return '🇷🇼';
  if (code == 'SA') return '🇸🇦';
  if (code == 'SB') return '🇸🇧';
  if (code == 'SC') return '🇸🇨';
  if (code == 'SD') return '🇸🇩';
  if (code == 'SE') return '🇸🇪';
  if (code == 'SG') return '🇸🇬';
  if (code == 'SH') return '🇸🇭';
  if (code == 'SI') return '🇸🇮';
  if (code == 'SJ') return '🇸🇯';
  if (code == 'SK') return '🇸🇰';
  if (code == 'SL') return '🇸🇱';
  if (code == 'SM') return '🇸🇲';
  if (code == 'SN') return '🇸🇳';
  if (code == 'SO') return '🇸🇴';
  if (code == 'SR') return '🇸🇷';
  if (code == 'SS') return '🇸🇸';
  if (code == 'ST') return '🇸🇹';
  if (code == 'SV') return '🇸🇻';
  if (code == 'SX') return '🇸🇽';
  if (code == 'SY') return '🇸🇾';
  if (code == 'SZ') return '🇸🇿';
  if (code == 'TC') return '🇹🇨';
  if (code == 'TD') return '🇹🇩';
  if (code == 'TF') return '🇹🇫';
  if (code == 'TG') return '🇹🇬';
  if (code == 'TH') return '🇹🇭';
  if (code == 'TJ') return '🇹🇯';
  if (code == 'TK') return '🇹🇰';
  if (code == 'TL') return '🇹🇱';
  if (code == 'TM') return '🇹🇲';
  if (code == 'TN') return '🇹🇳';
  if (code == 'TO') return '🇹🇴';
  if (code == 'TR') return '🇹🇷';
  if (code == 'TT') return '🇹🇹';
  if (code == 'TV') return '🇹🇻';
  if (code == 'TW') return '🇹🇼';
  if (code == 'TZ') return '🇹🇿';
  if (code == 'UA') return '🇺🇦';
  if (code == 'UG') return '🇺🇬';
  if (code == 'UM') return '🇺🇲';
  if (code == 'US') return '🇺🇸';
  if (code == 'UY') return '🇺🇾';
  if (code == 'UZ') return '🇺🇿';
  if (code == 'VA') return '🇻🇦';
  if (code == 'VC') return '🇻🇨';
  if (code == 'VE') return '🇻🇪';
  if (code == 'VG') return '🇻🇬';
  if (code == 'VI') return '🇻🇮';
  if (code == 'VN') return '🇻🇳';
  if (code == 'VU') return '🇻🇺';
  if (code == 'WF') return '🇼🇫';
  if (code == 'WS') return '🇼🇸';
  if (code == 'XK') return '🇽🇰';
  if (code == 'YE') return '🇾🇪';
  if (code == 'YT') return '🇾🇹';
  if (code == 'ZA') return '🇿🇦';
  if (code == 'ZM') return '🇿🇲';
  return '🏳';
}