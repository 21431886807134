// Chakra imports
import { ViewIcon,CheckIcon,CloseIcon } from "@chakra-ui/icons";
import {
  Text,Box,
  useColorModeValue, SkeletonCircle, SkeletonText,
  Modal, IconButton,
  useDisclosure,
  Flex,
  AvatarGroup,
  Avatar,
  Badge, Spacer
} from "@chakra-ui/react";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import React, { useEffect, useState, useContext, useCallback } from "react";
import { getAmbassadors } from "RestAPI";
import { globalStyles } from "theme/styles";
import { useNavigate } from "react-router-dom";
import ShowUserForm from 'views/Dashboard/Users/components/ShowUser'
import { checkboxColumn } from "views/Dashboard/Campaign/CampaignList";
import moment from "moment";
import { AppContext } from "contexts/AppContext";
import BBButton from "components/Button/Button";

const AmbassdorsList = ({ title, captions }) => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [ambassdorsData, setAmbassdorsData] = useState(null)
  const [showAmbassador, setShowAmbassador] = useState(false);
  const [userID, setUserID] = useState(null);
  const textColor = useColorModeValue("black3", "white");
  const {userData} = useContext(AppContext)
  const [gridRef, setGridRef] = useState(null);

  const downloadBlob = (blob, fileName = 'Ambassadors.csv') => {
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
  
    link.setAttribute('href', url);
    link.setAttribute('download', fileName);
    link.style.position = 'absolute';
    link.style.visibility = 'hidden';
  
    document.body.appendChild(link);
  
    link.click();
  
    document.body.removeChild(link);
  };

  const SEPARATOR = ','

  const exportCSV = () => {
    const columns = gridRef.current.visibleColumns;

    const header = columns.map((c) => c.name).join(SEPARATOR);
    const rows = gridRef.current.data.map((data) => {
      const cols = columns.map((c) => {
        if(c.id ==='nameObj'){
          return `${data[c.id].firstName} ${data[c.id].lastName} (${data[c.id].userName})`
        }
        return data[c.id]
      })
      return cols.join(SEPARATOR)
    });

    const contents = [header].concat(rows).join('\n');
    const blob = new Blob([contents], { type: 'text/csv;charset=utf-8;' });

    downloadBlob(blob, `Ambassadors_${moment().format('MM-DD-YYYY hh:mm')}`);
  };

  const refreshList = ({ skip, limit, sortInfo, currentData, filterValue, groupBy }) => {
    const params = {}
    if (!isNaN(skip) && skip >= 0 && !!limit) {
      params.pagination = {
        perPage: limit,
        page: !skip ? 1 : (skip / limit) + 1
      }
    }
    if (!!sortInfo) {
      params.sort = {
        order: sortInfo.dir > 0 ? 'ASC' : 'DESC',
        field: sortInfo.id || 'id'
      }
    }
    if (!!filterValue && !!filterValue.length) {
      params.filter = {
        name: filterValue[0].value,
        email: filterValue[1]?.value
      }
    }
    setLoading(true)
    return getAmbassadors(params).then(response => {
      setLoading(false);
      if (response && Array.isArray(response.data)) {
        response.data = response.data.map(ambassdor=>{
            ambassdor.userStatus = "Away"
            ambassdor.recommendedStatus = "Inactive"
            if(ambassdor && ambassdor.role === 'BRAND_MANAGER') {
              ambassdor.recommendedStatus = "NA"
              if (ambassdor.brands && ambassdor.brands !== null) {
                ambassdor.matchProfile = "Set"
              } else {
                ambassdor.matchProfile = "Not Set"
              }
              if(ambassdor.isDeleted || ambassdor.deletedOn) {
                ambassdor.userStatus = "Deleted"
              } else if(record.isApproved) {
                ambassdor.userStatus = "Approved"
              } else if(record.isActive){
                ambassdor.userStatus = "Active"
              } else {
                ambassdor.userStatus = "Away"
              }
          } else {
            if(ambassdor.isDeleted || ambassdor.deletedOn) {
              ambassdor.userStatus ="Deleted"
            } else if(ambassdor.isApproved) {
              ambassdor.userStatus ="Approved"
            } else if(ambassdor.isActive && ambassdor.isEnabled && ambassdor.videoResumeUrl && ambassdor.interest){
              ambassdor.userStatus = "Active"
            } else {
              ambassdor.userStatus ="Away"
            }
            if (ambassdor.isActive && ambassdor.isEnabled && ambassdor.isApproved && ambassdor.interest) {
              ambassdor.recommendedStatus="Active"
            } else {
              ambassdor.recommendedStatus="Inactive"
            }
            ambassdor.matchProfile = 'interest' in ambassdor && ambassdor.interest && Object.keys(ambassdor.interest).length > 0 ? 'Set' : 'Not Set'                       
          }
          ambassdor.nameObj = {profileImageUrl:ambassdor.profileImageUrl,userName:ambassdor.userName, firstName: ambassdor.firstName, lastName: ambassdor.lastName}
          return ambassdor
        })
        setAmbassdorsData(response.data)
        return ({ data: response.data, count: response.total ? parseInt(response.total) : response.data.length || 0 })
      }
    }).catch(err => { setLoading(false); console.error(err) })
  }

  const dataSource = useCallback(refreshList, [])

  const defaultFilterValue = [
    { name: 'name', type: 'string', operator: 'contains', value: '' },
    { name: 'email', type: 'string', operator: 'contains', value: '' }
  ];

  const columns = [
    {
      name: "id",
      type: "number",
      header: <Text color="gray3" textTransform='uppercase'>ID</Text>,
      defaultVisible: true,
      defaultFlex: 1,
      defaultWidth: 100,
      sortable: true,
      render: ({ value }) => (
        <Text color="black3" fontSize='md' fontWeight="normal">
          {value}
        </Text>
      ),
    },
    {
      id: "nameObj",
      name: "nameObj",
      type: "string",
      header: <Text color="gray3" textTransform='uppercase'>User Name</Text>,
      defaultVisible: true,
      defaultFlex: 1,
      minWidth: 200,
      sortable: true,
      filterEditorProps: {
        style: {
          background: '#fefefe',
        },
        placeholder: 'Search by name...'
      },
      render: ({ value }) => (
        <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap" >
          <Avatar src={value.profileImageUrl} name={value.userName} w="50px" borderRadius="50px" me="18px" />
          <Flex flexDirection={'column'}>
            <Text color="black3" fontSize='md' fontWeight="normal">
              {value.firstName} {value.lastName}
            </Text>
            <Text color="gray" fontSize='sm' fontWeight="normal">
              {value.userName}
            </Text>
          </Flex>
        </Flex>
      ),
    },
    // {
    //   name: "firstName",
    //   type: "string",
    //   header: <Text color="gray3" textTransform='uppercase'>First Name</Text>,
    //   defaultVisible: true,
    //   defaultFlex: 1,
    //   defaultWidth: 100,
    //   sortable: true,
    //   render: ({ value }) => (
    //     <Text color="black3" fontSize='md' fontWeight="normal">
    //       {value}
    //     </Text>
    //   ),
    // },
    // {
    //   name: "lastName",
    //   type: "string",
    //   header: <Text color="gray3" textTransform='uppercase'>Last Name</Text>,
    //   defaultVisible: true,
    //   defaultFlex: 1,
    //   defaultWidth: 100,
    //   sortable: true,
    //   render: ({ value }) => (
    //     <Text color="black3" fontSize='md' fontWeight="normal">
    //       {value}
    //     </Text>
    //   )
    // },
    {
        name: "email",
        type: "string",
        header: <Text color="gray3" textTransform='uppercase'>Email</Text>,
        defaultVisible: true,
        defaultFlex: 1,
        defaultWidth: 100,
        sortable: true,
        filterEditorProps: {
          style: {
            background: '#fefefe',
          },
          placeholder: 'Search by email...'
        },
        render: ({ value }) => (
          <Text color="black3" fontSize='md' fontWeight="normal">
            {value}
          </Text>
        )
      },
      {
        name: "gender",
        type: "string",
        header: <Text color="gray3" textTransform='uppercase'>Gender</Text>,
        defaultVisible: true,
        defaultFlex: 1,
        defaultWidth: 100,
        sortable: true,
        render: ({ value }) => (
          <Text color="black3" fontSize='md' fontWeight="normal">
            {value}
          </Text>
        )
      },
      {
        name: "matchProfile",
        type: "string",
        header: <Text color="gray3" textTransform='uppercase'>Match Profile</Text>,
        defaultVisible: true,
        defaultFlex: 1,
        defaultWidth: 100,
        sortable: true,
        render: ({ value }) => (
            
          <Text color="black3" fontSize='md' fontWeight="normal">
            {value === 'Set' ? <CheckIcon color='green' mr={"5px"} fontSize={"14px"}/> : <CloseIcon color='red.500' mr={"5px"} fontSize={"12px"}/> }{value}
          </Text>
        )
      },
    // {
    //   name: "isActive",
    //   header: <Text color="gray3" textTransform='uppercase'>STATUS</Text>,
    //   defaultVisible: true,
    //   defaultFlex: 1,
    //   maxWidth: 160,
    //   sortable: true,
    //   render: ({ value }) => {
    //     let val = value ? 'Active' : 'InActive';
    //     let bgColor = globalStyles.colors.statusGreen
    //     if (val === 'Created') {
    //       bgColor = globalStyles.colors.statusGreen
    //     } else if (val === 'Deleted') {
    //       bgColor = globalStyles.colors.statusRed
    //     }
    //     return (
    //       < Tag textAlign={'center'} minW={28} backgroundColor={bgColor}><Spacer /><TagLabel textAlign={'center'}>{val}</TagLabel><Spacer /></Tag>
    //     )
    //   },
    // },
    {
        name: "isActive",
        type: "string",
        header: <Text color="gray3" textTransform='uppercase'>Ambassador Active/Away</Text>,
        defaultVisible: true,
        defaultFlex: 1,
        defaultWidth: 100,
        sortable: true,
        render: ({ value }) => (
          <Text color="black3" fontSize='md' fontWeight="normal">
            {value ? <CheckIcon color='green' mr={"5px"} fontSize={"14px"}/> : <CloseIcon color='red.500' mr={"5px"} fontSize={"12px"}/> }{value ? 'Active' : 'InActive'}
          </Text>
        )
      },
    
      {
        name: "videoResumeUrl",
        type: "string",
        header: <Text color="gray3" textTransform='uppercase'>Video Resume</Text>,
        defaultVisible: true,
        defaultFlex: 1,
        defaultWidth: 100,
        sortable: true,
        render: ({ value }) => (
          <Text color="black3" fontSize='md' fontWeight="normal">
            {value ? <CheckIcon color='green' mr={"5px"} fontSize={"14px"}/> : <CloseIcon color='red.500' mr={"5px"} fontSize={"12px"}/> }{value ? 'Ready' : 'Not Ready'}
          </Text>
        )
      },
      {
        name: "userStatus",
        type: "string",
        header: <Text color="gray3" textTransform='uppercase'>User Status</Text>,
        defaultVisible: true,
        defaultFlex: 1,
        defaultWidth: 100,
        sortable: true,
        render: ({ value }) => {
          let color = globalStyles.colors.statusGreen
          if (value === 'Approved') {
            color = globalStyles.colors.statusGreen
          } else if (value === 'Deleted') {
            color = globalStyles.colors.statusRed
          }else if (value === 'Active') {
            color = globalStyles.colors.lightBlue
          }else if (value === 'Away') {
            color = globalStyles.colors.warning
          }
          return(<Text color={color} fontSize='md' fontWeight="normal">
            {value}
          </Text>)
        }
      },
      {
        name: "isEnabled",
        type: "string",
        header: <Text color="gray3" textTransform='uppercase'>Enable/Disable</Text>,
        defaultVisible: true,
        defaultFlex: 1,
        defaultWidth: 100,
        sortable: true,
        render: ({ value }) => (
          <Text color="black3" fontSize='md' fontWeight="normal">
            {value ? <CheckIcon color='green' mr={"5px"} fontSize={"14px"}/> : <CloseIcon color='red.500' mr={"5px"} fontSize={"12px"}/> }{value ? 'Enabled' : 'Not Enabled'}
          </Text>
        )
      },
      {
        name: "recommendedStatus",
        type: "string",
        header: <Text color="gray3" textTransform='uppercase'>Recommender Status</Text>,
        defaultVisible: true,
        defaultFlex: 1,
        defaultWidth: 100,
        sortable: true,
        render: ({ value }) => {
          let color = globalStyles.colors.warning
          if (value === 'Active') {
            color = globalStyles.colors.statusGreen
          } 
          return(<Text color={color} fontSize='md' fontWeight="normal">
            {value}
          </Text>)
      }
      },
    {
      name: "createdOn",
      type: "date",
      header: <Text color="gray3" textTransform='uppercase'>Created on</Text>,
      defaultVisible: true,
      defaultFlex: 1,
      maxWidth: 160,
      sortable: true,
      render: ({ value }) => (
        <Text color="black3" fontSize='md' fontWeight="normal">
          {moment(value).format('MM-DD-YYYY')}
        </Text>
      ),
    },
    {
      header: <Text color="gray3" textAlign='center'>ACTION</Text>,
      defaultVisible: true,
      defaultFlex: 1,
      maxWidth: 60,
      textAlign: 'center',
      sortable: false,
      render: ({ data }) => <Flex gap='0.25rem' justifyContent="center" alignItems="center" >
       <IconButton onClick={() => {setShowAmbassador(true);setUserID(data.id)}}
          variant='outline' size="xs"
          colorScheme='teal' borderRadius={"50%"}
          aria-label='view ambassdor'
          icon={<ViewIcon />}
        />
      </Flex>
    },
  ]
  
  columns.forEach(item => item.style = { borderLeftColor: 'white', borderRightColor: 'white', borderTopColor: 'white', borderBottomColor: 'gray.200' })

  // useEffect(refreshList, [])

  return (
    <>
      <Modal size={'4xl'} isOpen={showAmbassador} onClose={() => setShowAmbassador(false)}>
        <ShowUserForm onClose={() => setShowAmbassador(false)} UserID={userID} showControls={userData.role === 'SUPER_ADMIN'}/>
      </Modal>
      <Card mt='5px' p='16px' overflowX={{ sm: "scroll" }} boxShadow='0px 0px 20px 1px rgb(0 0 0 / 10%)' borderRadius='10px'>
        <CardHeader p='12px 0px 28px 0px'>
          <Flex flex={1} align="center" gap='2rem' w="100%" ml={2} mr={2}>
            <Flex direction='column' gap='2rem' w="100%">
              <Flex gap='1rem' flexDirection='row' justifyContent={'space-between'}>
                <Text fontSize='30px' color={textColor} fontWeight='bold' pb='.5rem'>
                  Ambassadors
                </Text>
                <BBButton onClick={exportCSV}>Export</BBButton>
            </Flex>
          </Flex>
          </Flex>
        </CardHeader>
        <CardBody >
          {
            loading && (
              <Flex align="center" minWidth="100%" flexWrap="nowrap">
                <Box padding='6' boxShadow='lg' minWidth="100%">
                  <SkeletonCircle size='20' />
                  <SkeletonText mt='4' noOfLines={4} spacing='4' />
                </Box>
              </Flex>
            )
          }
          {dataSource && dataSource.length > 0 && (
            <ReactDataGrid
              handle={setGridRef}
              loading={ambassdorsData === null}
              className="brandbass-data-grid"
              style={{ minHeight: 860, borderColor: 'white' }}
              idProperty="id"
              columns={columns}
              dataSource={dataSource}
              pagination={true}
              defaultLimit={10}
              defaultLoading={loading}
              defaultSortInfo={{ id: "id", name: 'id', dir: -1, type: 'number' }}
              checkboxColumn={checkboxColumn}
              checkboxOnlyRowSelect={true}
              rowHeight={70}
              headerHeight={60}
              showZebraRows={false}
              enableSelection={true}
              showHoverRows={true}
              showCellBorders="horizontal"
              enableColumnAutosize={true}
              defaultFilterValue={defaultFilterValue}
              enableColumnFilterContextMenu={false}
              emptyText="No Ambassadors"
            />
          )}
        </CardBody>
      </Card>

    </>
  );
};

export default AmbassdorsList;
