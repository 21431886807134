// Chakra imports
import {
  Button,
  Table,
  Tbody,
  Text,
  Th, IconButton,
  Thead, Box, Tag, TagLabel, Center, Image,
  Tr, Skeleton, SkeletonCircle, SkeletonText,
  useColorModeValue,
  Modal,
  useDisclosure,
  Flex, Spacer
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CounterCard from "components/Card/CounterCard.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import TeamsTableRow from "components/Tables/TeamsTableRow";
import moment from "moment";
import React, { useEffect, useState, useContext } from "react";
import ProjectForm from "./ProjectForm";
import { useNavigate } from 'react-router-dom'
import { getBrandProjects } from "RestAPI";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import { checkboxColumn } from "views/Dashboard/Campaign/CampaignList";
import { EditIcon,ViewIcon } from "@chakra-ui/icons";
import { TrashIcon } from "components/Icons/Icons";
import { AppContext } from "contexts/AppContext";
import { globalStyles } from "theme/styles";
import EmptyList from "assets/img/empty/project.png";
import NoProjects from "assets/svg/emptyList/NoProjects.svg"
import BBButton from "components/Button/Button";
import { FaPlus } from "react-icons/fa";
import { DeleteEntityModal } from "layouts/Dashboard";
import "../Project.css"

const ProjectList = ({ title, captions }) => {
  const navigate = useNavigate()
  const { placeholderImage, hasEditPermission, hasCreatePermission, userData } = useContext(AppContext)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [selectedRow, setSelectedRow] = useState({})
  const [projectData, setProjectData] = useState([])
  const [loading, setLoading] = useState(true)
  const [overView, setOverview] = useState(null)
  const textColor = useColorModeValue("black3", "white");
  const [deleteModal, setDeleteModal] = useState(null)
  const [entityId, setEntityId] = useState(null)

  const deleteItem = (row) => {
    setEntityId(row.id)
    setDeleteModal(true)
  }

  const refreshList = () => {
    setLoading(true)
    getBrandProjects().then(response => {
      setLoading(false);
      if (Array.isArray(response) && response.length) {
        setProjectData(response)
        setOverview({
          totalUsers: response.length,
          activeUsers: response.filter(item => !!item.isActive),
          inactiveUsers: response.filter(item => !item.isActive),
        })
      }
    }).catch(err => { setLoading(false); console.error(err) })
  }

  const editItem = (row) => {
    navigate(`/edit/project/${row.id}`, {state: {data: row}} )
  }

  const columns = [
    {
      name: "id",
      type: "number",
      header: <Text color="gray3" textTransform='uppercase'>ID</Text>,
      defaultVisible: false,
      defaultFlex: 1,
      defaultWidth: 100,
      sortable: true,
      render: ({ value }) => (
        <Text color="black3" fontSize='md' fontWeight="normal">
          {value}
        </Text>
      ),
    },
    {
      name: "projectName",
      type: "string",
      header: <Text color="gray3" textTransform='uppercase'>Project Name</Text>,
      defaultVisible: true,
      defaultFlex: 1,
      defaultWidth: 100,
      sortable: true,
      filterEditorProps: {
        style: {
          background: '#fefefe',
        },
        placeholder: 'Search by name...'
      },
      render: ({ value }) => (
        <Text color="black3" fontSize='md' fontWeight="normal">
          {value}
        </Text>
      ),
    },
    {
      name: "description",
      type: "string",
      header: <Text color="gray3" textTransform='uppercase'>description</Text>,
      defaultVisible: true,
      defaultFlex: 1,
      defaultWidth: 100,
      sortable: true,
      render: ({ value }) => (
        <Text color="black3" fontSize='md' fontWeight="normal">
          {value}
        </Text>
      ),
    },
    {
      name: "createdOn",
      type: "date",
      header: <Text color="gray3" textTransform='uppercase'>Created on</Text>,
      defaultVisible: true,
      defaultFlex: 1,
      maxWidth: 120,
      sortable: true,
      render: ({ value }) => (
        <Text color="black3" fontSize='md' fontWeight="normal">
          {moment(value).format('MM-DD-YYYY')}
        </Text>
      ),
    },
    {
      header: <Text color="gray3" textAlign='center'>ACTION</Text>,
      defaultVisible: true,
      defaultFlex: 1,
      defaultWidth: 30,
      textAlign: 'center',
      sortable: false,
      render: ({ data }) => <Flex gap='0.25rem' justifyContent="center" alignItems="center" >
        {
          (userData?.role?.includes('ADMIN') || data?.owner?.id === userData.id) && <>
            <IconButton onClick={() => editItem(data)}
              variant='outline' size="xs"
              colorScheme='teal' borderRadius={"50%"}
              aria-label='Send email'
              icon={<EditIcon />}
            />
            <IconButton onClick={() => deleteItem(data)}
              variant='outline' size="xs"
              colorScheme='teal' borderRadius={"50%"}
              aria-label='Send email'
              icon={<TrashIcon />}
            />
          </>
        }

        <IconButton onClick={() => navigate("/projects/"+data.id)}
          variant='outline' size="xs"
          colorScheme='teal' borderRadius={"50%"}
          aria-label='view project'
          icon={<ViewIcon />}
        />
      </Flex>
    },
  ]

  columns.forEach(item => item.style = { borderLeftColor: 'white', borderRightColor: 'white', borderTopColor: 'white', borderBottomColor: 'gray.200' })

  useEffect(refreshList, [])

  return (
    <>
      <DeleteEntityModal 
        visible={deleteModal} 
        closeHandler={(refresh) => {
          if(!!refresh) refreshList()
          setDeleteModal(false)
        }} 
        entity='project'
        entityId={entityId}
      />
      <Card id="projects-list" mt='5px' p='16px' overflowX={{ sm: "scroll" }} boxShadow='0px 0px 20px 1px rgb(0 0 0 / 10%)' borderRadius='10px'>
        <CardHeader p='12px 0px 28px 0px'>
          <Flex flex={1} align="center" gap='2rem' w="100%" ml={2} mr={2}>
            <Flex direction='column' gap='2rem' w="100%">
              <Flex gap='1rem' flexDirection='column'>
                <Text fontSize='30px' color={textColor} fontWeight='bold' pb='.5rem'>
                  {title}
                </Text>
                {
                  !loading && projectData && projectData.length == 0 && <>
                  <Text fontStyle='italic' fontWeight='600' fontSize='16px' maxW='540px'>This is your project view, where you can edit and create new projects. Let's start with your first project!</Text>
                  </>
                }
                <Spacer />
                {
                  !!hasCreatePermission &&
                  <BBButton shadow={true} maxW='fit-content' size="sm" leftIcon={<FaPlus />} colorScheme='brandbassBlue' color="white" onClick={() => navigate('/new/project')}>
                    PROJECT
                  </BBButton>
                }
              </Flex>
              <Flex gap='20px' p='10px' alignItems='center' borderRadius='10px' >
                {
                  loading && (
                    <Flex align="center" minWidth="100%" flexWrap="nowrap">
                      <Box padding='6' boxShadow='lg' minWidth="100%">
                        <SkeletonText mt='4' noOfLines={4} spacing='4' />
                      </Box>
                    </Flex>
                  )
                }
                {!loading && projectData && projectData.length > 0 && (<>
                  <CounterCard title={"Total"} width={250} count={overView ? overView.totalUsers : 0} />
                  <CounterCard title={"Active"} width={250} count={overView ? overView.activeUsers.length : 0} />
                  <CounterCard title={"Inactive"} width={250} count={overView ? overView.inactiveUsers.length : 0} />
                </>)}
              </Flex>
            </Flex>
          </Flex>
        </CardHeader>
        <CardBody >
          
            {
              loading && (
                <Flex align="center" minWidth="100%" flexWrap="nowrap">
                  <Box padding='6' boxShadow='lg' minWidth="100%">
                    <SkeletonCircle size='20' />
                    <SkeletonText mt='4' noOfLines={4} spacing='4' />
                  </Box>
                </Flex>
              )
            }
            {projectData && projectData.length == 0 && (
              <Flex align="center" minWidth="100%" flexWrap="nowrap">
                <Box padding='6' minWidth="100%" justifyContent={"center"}>
                  <Center>
                    <Image
                      src={NoProjects}
                      maxW="40%"
                      borderRadius="lg"
                      fallbackSrc={placeholderImage(40)}
                      boxShadow='xs'
                    /></Center>
                </Box>
              </Flex>
            )}
            {!loading && projectData && projectData.length > 0 && (
              <ReactDataGrid
                loading={projectData === null}
                className="brandbass-data-grid"
                style={{ minHeight: 860, borderColor: 'white' }}
                idProperty="id"
                columns={columns}
                dataSource={projectData || []}
                pagination={true}
                defaultLimit={10}
                defaultLoading={loading}
                defaultSortInfo={{ id: "id", name: 'id', dir: -1, type: 'number' }}
                checkboxColumn={checkboxColumn}
                checkboxOnlyRowSelect={true}
                rowHeight={70}
                headerHeight={60}
                showZebraRows={false}
                enableSelection={true}
                showHoverRows={true}
                showCellBorders="horizontal"
                enableColumnAutosize={true}
                defaultFilterValue={[{ name: 'projectName', type: 'string', operator: 'contains', value: '' }]}
                enableColumnFilterContextMenu={false}
                emptyText="No projects"
              />

            )}
         
        </CardBody>
        <Modal size={'lg'} isOpen={isOpen} onClose={onClose}>
          <ProjectForm onClose={onClose} refreshList={refreshList} data={selectedRow} />
        </Modal>
      </Card>
    </>
  );
};

export default ProjectList;

