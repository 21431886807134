import {
    Button,
    Link,
    AvatarBadge,
    Text,
    Heading,
    SkeletonCircle,
    Tooltip,
    Box,
    Image,
    Stack, Avatar, Flex,
    useColorModeValue,
    SkeletonText, Badge,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    HStack,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    Icon,
    AvatarGroup,
    Tabs, TabList, TabPanels, Tab, TabPanel,Spacer, useToast, Input
} from "@chakra-ui/react";
import Instagram from "assets/svg/InstagramLinkIcon.svg";
import Facebook from "assets/svg/facebook.svg"
import { useContext, useEffect, useState } from "react"
import { FaFacebook, FaInstagram, FaHome, FaArrowRight, FaBullhorn, FaTiktok } from "react-icons/fa";
import { MdOutlineStarOutline, MdLibraryAddCheck } from "react-icons/md";
import { getDashboardUser,allConversationsByUser, changeUserStatus, approveUser, activeAwayStatus, userEnableStatus, changeAmbassadorPayrate, userChangeDashboardAccess } from "RestAPI"
import { globalStyles } from "theme/styles";
import BBButton from "components/Button/Button";
import { BBSecondaryButton } from "components/Button/Button";
import { BrandInfoCard, BrandBasicInfoCard } from "./Profile"
import CounterCard from "components/Card/CounterCard";
import Chat from "components/Chat/Chat"
import { AppContext } from "contexts/AppContext";
import BBDropdown from "components/Dropdown/Dropdown";
import BBInput from "components/Input/Input";

const ShowUserForm = (props) => {
    const { UserID, brandInfo, showControls } = props;
    const [user, setUser] = useState(null)
    const [loading, setLoading] = useState(false)
    const [conversation, setConversation] = useState([])
    const [userChatData, setUserChatData] = useState(null)
    const toast = useToast()


    const fetchConversations = async () => {
        const response = await allConversationsByUser(UserID)
        if(response?.data?.length){
            setConversation(response.data)
            loadUserConversation(response.data[0])
        }
      }
    
      const loadUserConversation = async (selectedChat) => {
        if (userChatData?.moduleId && selectedChat.moduleId === userChatData.moduleId) {
          setUserChatData(null)
        } else {
          setUserChatData(selectedChat)
        }
    }
   
    const fetchUserDetails = async () => {
        setLoading(true)
        await getDashboardUser(UserID).then(response => {
            setUser(response.data && response.data.id ? response.data : null)
            setLoading(false)
        }).catch(err => setLoading(false))
    }

    const _activeAwayStatus = async (user) => {
        const res = await activeAwayStatus({id: user.id})
        if(res.data && res.data.id){
            toast({
                title: res.data.isActive ? 'User Active' : 'User Away',
                description: `User has been successfully set as ${res.data.isActive ? 'active' : 'away'}`,
                status: 'success',
                position: 'top',
                duration: 3000,
                isClosable: true,
            })
        }
        fetchUserDetails()
    }

    const _approveUser = async (user) => {
        const res = await approveUser(user)
        if(res.data && res.data.id){
            toast({
                title: res.data.isApproved ? 'User Approved' : 'User Rejected',
                description: `User has been successfully ${res.data.isApproved ? 'approved' : 'rejected'}`,
                status: 'success',
                position: 'top',
                duration: 3000,
                isClosable: true,
            })
        }
        fetchUserDetails()
    }

    const _changeUserStatus = async (user) => {
        const res = await changeUserStatus(user)
        if(res.data && res.data.id){
            toast({
                title: res.data.deletedOn ? 'User Deleted' : 'User Restored',
                description: `User has been successfully ${res.data.deletedOn ? 'deleted' : 'restored'}`,
                status: 'success',
                position: 'top',
                duration: 3000,
                isClosable: true,
            })
        }
        fetchUserDetails()
    }

    const _userEnableStatus = async (user) => {
        const res = await userEnableStatus(user)
        if(res.data && res.data.id){
            toast({
                title: res.data.isEnabled ? 'User Enabled' : 'User Disabled',
                description: `User has been successfully ${res.data.isEnabled ? 'enabled for recommendations' : 'disabled for recommendations'}`,
                status: 'success',
                position: 'top',
                duration: 3000,
                isClosable: true,
            })
        }
        fetchUserDetails()
    }

    const _changeDashboardAccess = async (user) => {
        const res = await userChangeDashboardAccess(user)
        if(res.data && res.data.id){
            toast({
                title: res.data.isEnabled ? 'User granted dashbaord access' : 'Removed dashboard access to user',
                description: `User has been successfully ${res.data.isEnabled ? 'enabled for dashbaord access' : 'disabled for dashbaord access'}`,
                status: 'success',
                position: 'top',
                duration: 3000,
                isClosable: true,
            })
        }
        fetchUserDetails()
    }

    useEffect(() => {
        if(UserID){
            fetchUserDetails();
            fetchConversations()
        }else{
            brandInfo && setUser(brandInfo)
        } 
    }, [UserID])

    return (
        <>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader><ModalCloseButton /></ModalHeader>
                <ModalBody pb={6}>
                    {loading && !user && (
                        <Flex align="center" minWidth="100%" flexWrap="nowrap">
                            <Box padding='6' boxShadow='lg' bg='white' minWidth="100%">
                                <SkeletonCircle size='10' />
                                <SkeletonText mt='4' noOfLines={4} spacing='4' />
                            </Box>
                        </Flex>
                    )}

                    {
                        !loading && user && user.role && <>
                            {!!user.role.toUpperCase().includes('BRAND') && <ViewBrandUserProfile showControls={showControls} user={user} conversation={conversation}  userChatData={userChatData} loadUserConversation={loadUserConversation} 
                                _activeAwayStatus={_activeAwayStatus} _approveUser={_approveUser} _changeUserStatus={_changeUserStatus} _userEnableStatus={_userEnableStatus} _changeDashboardAccess={_changeDashboardAccess}/>}
                            {!!user.role.toUpperCase().includes('AMBASSADOR') && <ViewAmbassadorProfile showControls={showControls} user={user} _activeAwayStatus={_activeAwayStatus} 
                            _approveUser={_approveUser} _changeUserStatus={_changeUserStatus} _userEnableStatus={_userEnableStatus} _changeDashboardAccess = {_changeDashboardAccess} refresh={fetchUserDetails} />}
                        </>
                    }
                </ModalBody>
            </ModalContent>

        </>
    );
};

export default ShowUserForm


export const ViewAmbassadorProfile = ({ user, _activeAwayStatus, _approveUser, _changeUserStatus, _userEnableStatus,_changeDashboardAccess,  refresh, showControls }) => {
    const [video, setVideo] = useState('')
    useEffect(() => setVideo(user.videoResumeUrl), [user])
    const {userData} = useContext(AppContext)
    const [showPayrateForm, setShowPayrateForm] = useState(false)
    const [payRate, setPayRate] = useState('')
    const [gigVariation, setGigVariation] = useState('')
    const [gigVariationType, setGigVariationType] = useState('')
    const [gigChannel, setGigChannel] = useState('')

    const submitPayrate = async() => {
        let payrate = {
            payRate,
            gigVariation,
            gigVariationType,
            ambassador: user,
        }
        if(!!gigChannel && gigVariation === 'Publish'){
            payrate.gigChannel = gigChannel
        }
        const response = await changeAmbassadorPayrate(payrate)
        if(response){
            refresh()
        }
    }

    const VideoPreview = (props) => <video
        className="video-card"
        controls
        preload="metadata"
    >
        <source src={props.video} type="video/mp4" />
        Your browser does not support the video tag.
    </video>

    return (
        <Box position='relative' w='100%' h='100%' borderRadius='10px' bgColor='brandbassBlue2.200'>
            <Flex flexDirection={'column'} gap={4}>
                {showControls && (userData.role === 'SUPER_ADMIN' || userData.role === 'BRAND_SUPER_ADMIN' || userData.role === 'CLIENT_SUPER_ADMIN') ? 
                <Box alignSelf={'end'} nt={4} mb={4} mr={4}>
                    <Stack align="right" spacing={4} direction={{base:'column',md:'row'}} mt={4} ml={4}>
                    {userData.role === 'SUPER_ADMIN' && <BBButton variant={'outline'} onClick={() => _changeDashboardAccess(user)}>{user.allowDashboardAccess ? 'Remove Dashboard' : 'Allow Dashboard'}</BBButton>}
                    {userData.role === 'SUPER_ADMIN' && <BBButton variant={'outline'} onClick={() => _changeUserStatus(user)}>{user.deletedOn ? 'Restore' : 'Delete'}</BBButton>}
                        <BBButton variant={'outline'} isDisabled={user.deletedOn} onClick={() => _userEnableStatus(user)}>{user.isEnabled ? 'Disable' : 'Enable'}</BBButton>
                        <BBButton variant={'outline'} isDisabled={user.deletedOn} onClick={() => _approveUser(user)}>{user.isApproved ? 'Reject' : 'Approve'}</BBButton>
                        <BBButton variant={'outline'} isDisabled={user.deletedOn} onClick={() => _activeAwayStatus(user)}>{user.isActive ? 'Set Away' : 'Set Active'}</BBButton>
                    </Stack>
                </Box> : null}
                <HStack direction='column' p={6} spacing={6}>
                    <Box position='relative' w='50%' h='100%' >
                        <Flex flexDirection="column" gap={10}>
                            {/* <Stack spacing={4} direction='column'> */}
                            <Flex position='relative' direction='row' justifyContent='center'>
                                <Flex flexDirection='column'>
                                    <Avatar src={user.profileImageUrl} w='160px' h='160px' borderRadius="50%"  >
                                    </Avatar>
                                    <Text as="SmallTextRegular" color={globalStyles.colors.gray3} textAlign='center' mt={2} >@{user.userName}</Text>
                                </Flex>
                                <Box position="relative" alignSelf='center'>
                                    <Stack direction='column' display={"inline-block"} margin="11px 23px">
                                        <Text as='h5' fontWeight={"bold"}>{`${user.firstName} ${user.lastName}`}</Text>

                                        <Text textAlign={"justify"} fontWeight='400' fontSize='14px' lineHeight='24px' color='brandbassGray.500'>
                                            {user.tagLine}
                                        </Text>
                                        {/* <AvatarBadge boxSize='1.25em' bg='green.500'> */}
                                        {/* <Flex gap={2} style={{marginTop: 16}}>
                                                {user.instagramAccount && <Link href={`https://www.instagram.com/${user.instagramAccount}`} isExternal>
                                                    <Avatar size='sm' name='Instagram' src={Instagram} bg='blue.100' />
                                                </Link>}
                                                {user.facebookAccount && <Link href={`https://www.facebook.com/${user.facebookAccount}`} isExternal>
                                                    <Avatar size='sm' name='Facebook' as={FaFacebook} bg='blue.100' />                                                                        
                                                </Link>}
                                            </Flex> */}
                                        {/* </AvatarBadge> */}
                                    </Stack>
                                </Box>
                            </Flex>
                            {userData.role === 'SUPER_ADMIN' ? <Box position='relative' textAlign="left">
                                <Text as='h6' fontWeight={"bold"} mb="6px">Contact:</Text>
                                <Text fontWeight='400' fontSize='14px' lineHeight='24px' color='brandbassGray.500' textAlign='justify'>
                                    {user.email}
                                </Text>
                                <Text fontWeight='400' fontSize='14px' lineHeight='24px' color='brandbassGray.500' textAlign='justify'>
                                    {user.mobileNumber}
                                </Text>
                            </Box> : null}
                            <Box position='relative' textAlign="left">
                                <Text as='h6' fontWeight={"bold"} mb="6px">Interests:</Text>
                                {user.categories && user.categories.length ? user.categories.map(category => {
                                    return <Badge
                                        fontWeight='400' fontSize='10px' lineHeight='16px' color='brandbassGray.700'
                                        // background={'#C4C4C4'} 
                                        background='white' borderColor={globalStyles.colors.primary} borderWidth='1px'
                                        variant='solid'
                                        borderRadius="50px" p={2} pl={4} pr={4} m={1}>
                                        {category.name}
                                    </Badge>
                                }) : ""}
                            </Box>
                            <Box position='relative' textAlign="left">
                                <Text as='h6' fontWeight={"bold"} mb="6px">Bio:</Text>
                                <Text fontWeight='400' fontSize='14px' lineHeight='24px' color='brandbassGray.500' textAlign='justify'>
                                    {user.summary}
                                </Text>
                            </Box>
                            <Box position='relative' textAlign="left">
                                <Flex gap={4} style={{ marginTop: 16 }}>
                                    {user.instagramAccount && <Link href={`https://www.instagram.com/${user.instagramAccount}`} isExternal>
                                        {/* <Avatar size='sm' name='Instagram' src={Instagram} bg='blue.100' /> */}
                                        <BBSecondaryButton leftIcon={<FaInstagram />} variant='subtle' background='white' boxShadow="lg" color='brandbassBlue.500'>Instagram</BBSecondaryButton>
                                    </Link>}
                                    {user.facebookAccount && <Link href={`https://www.facebook.com/${user.facebookAccount}`} isExternal>
                                        {/* <Avatar size='sm' name='Facebook' as={FaFacebook} bg='blue.100' />                                                                         */}
                                        <BBSecondaryButton leftIcon={<FaFacebook />} variant='subtle' background='white' boxShadow="lg" color='brandbassBlue.500'>Facebook</BBSecondaryButton>
                                    </Link>}
                                    {user.tiktokAccount && <Link href={`https://www.tiktok.com/@${user.tiktokAccount.replace('@','')}`} isExternal>
                                        {/* <Avatar size='sm' name='Facebook' as={FaFacebook} bg='blue.100' />                                                                         */}
                                        <BBSecondaryButton leftIcon={<FaTiktok />} variant='subtle' background='white' boxShadow="lg" color='brandbassBlue.500'>Tiktok</BBSecondaryButton>
                                    </Link>}
                                </Flex>
                            </Box>
                            {userData.role === 'SUPER_ADMIN' ? 
                            <Box position='relative' textAlign="left">
                                <Flex flexDirection='column' gap={2}>
                                    <Text as='h6' fontWeight={"bold"} mb="6px">Payrates:</Text>
                                    {user.payrates?.length ? user.payrates.map((payrate,index) => {
                                    return (<Text key={index} ml={8} fontWeight='400' fontSize='14px' lineHeight='24px' color='brandbassGray.500' textAlign='justify'>
                                        ${payrate.payRate} for {payrate.gigVariation} {payrate.gigVariationType} {payrate.gigChannel}
                                    </Text>)}) : 'Defaults'}                                    
                                </Flex>
                                {!showPayrateForm ? <BBButton mt={4} onClick={() => setShowPayrateForm(true)}>Change payrate</BBButton> :
                                    <BBButton variant={'outline'} onClick={() => setShowPayrateForm(false)}>Cancel</BBButton>}
                                {showPayrateForm ? <Flex gap={2} mt={8}>
                                    <BBDropdown value={gigVariation} w={'160px'} placeholder={'Select'} onChange={(e) => setGigVariation(e.target.value)} options={[{name: 'Content', value:'Content'}, {name: 'Publish',value: 'Publish'}]}></BBDropdown>
                                    <BBDropdown value={gigVariationType} w={'160px'} placeholder={'Select'} onChange={(e) => setGigVariationType(e.target.value)} options={[{name: 'Video', value:'Video'}]}></BBDropdown>
                                    {gigVariation === 'Publish' && gigVariationType === 'Video' ? <BBDropdown value={gigChannel} w={'160px'} placeholder={'Select'} onChange={(e) => setGigChannel(e.target.value)} options={[{name: 'Facebook', value:'Facebook'},{name: 'Instagram', value:'Instagram'},{name: 'Tiktok', value:'Tiktok'}]}></BBDropdown> : null}
                                    <BBInput value={payRate} w={'160px'} placeholder="Enter payrate" onChange={(e) => setPayRate(e.target.value)}></BBInput>
                                    <Spacer/>
                                    <BBButton pl={8} pr={8} onClick={submitPayrate}>Save</BBButton>
                                </Flex> : null }
                            </Box> : null}
                            {/* <Box position='relative' textAlign="left">
                                    <Button variant="solid" background={'#FF489F'} borderRadius= '20px'>Send me a gig</Button>
                                </Box> */}
                            {/* </Stack> */}
                        </Flex>
                    </Box>
                    <Box position='relative' h='100%' w={{ base: 'full', lg: '50%' }} paddingRight={'px'}>
                        <Flex flexDirection="column" p={1} minH={100} className="video-container">
                            <VideoPreview video={video} />
                        </Flex>
                    </Box>
                </HStack>
            </Flex>
        </Box>
    )
}

export const ViewBrandUserProfile = (props) => {
    const { user,conversation,userChatData, _approveUser, _changeUserStatus, _userEnableStatus, _changeDashboardAccess, showControls } = props
    const {userData} = useContext(AppContext)

    return <>
        <Flex flexDirection='column' p={2} flex={1}>
            {showControls && (userData.role === 'SUPER_ADMIN' || userData.role === 'BRAND_SUPER_ADMIN' || userData.role === 'CLIENT_SUPER_ADMIN') ? 
            <Box mt={4} mb={4} mr={4} alignSelf='end'>
                <Stack align="right" spacing={4} direction={{base:'column',md:'row'}} mt={4} ml={4}>
                {userData.role === 'SUPER_ADMIN' && <BBButton variant={'outline'} onClick={() => _changeDashboardAccess(user)}>{user.allowDashboardAccess ? 'Remove Dashboard' : 'Allow Dashboard'}</BBButton>}
                {userData.role === 'SUPER_ADMIN' && <BBButton variant={'outline'} onClick={() => _changeUserStatus(user)}>{user.deletedOn ? 'Restore' : 'Delete'}</BBButton>}
                    <BBButton variant={'outline'} isDisabled={user.deletedOn} onClick={() => _userEnableStatus(user)}>{user.isEnabled ? 'Disable' : 'Enable'}</BBButton>
                    <BBButton variant={'outline'} isDisabled={user.deletedOn} onClick={() => _approveUser(user)}>{user.isApproved ? 'Reject' : 'Approve'}</BBButton>
                </Stack>
            </Box> : null}
            <Flex flexDirection='row' justify='space-between' gap='1rem' pb='2rem' borderWidth={1} borderColor='primary' px={4} py={2} flex={1} mb={4}>
                <Flex flexDirection='column' gap='1rem' flex={0.3}>
                    <Flex justify='space-around' flexDirection='row' gap='0.5rem' bgColor={'brandbassBlue2.200'} rounded='lg' flex={1}>
                        <Flex flexDirection='column' gap='1rem'  px={4} py={2} flex={1} rounded='lg'>
                            <Text color='primary' className="count-header" textAlign='left' align="left" fontWeight='700' fontSize='16px' >
                                Active campaigns
                            </Text>
                            <Text color='primary' className="count-number" alignSelf='flex-end' >
                                {user.campaign?.activeCount || 0}
                            </Text>
                        </Flex>
                        <Flex flexDirection='column' gap='1rem' px={4} py={2} flex={1} rounded='lg'>
                            <Text color='primary' className="count-header" textAlign='left' align="left" fontWeight='700' fontSize='16px' >
                                Campaigns authored
                            </Text>
                            <Text color='primary' className="count-number" alignSelf='flex-end' >
                                {user.campaign?.totalCount || 0}
                            </Text>
                        </Flex>
                    </Flex>
                    <Flex flexDirection='column' gap='1rem'  px={4} py={2} flex={1} rounded='lg' bgColor={'brandbassBlue2.200'}>
                        <Text color='primary' className="count-header" textAlign='left' align="left" fontWeight='700' fontSize='16px' >
                            Groups
                        </Text>
                        <AvatarGroup display='flex' justifyContent='center' flexWrap='wrap' alignSelf='flex-end'>
                            {user.groups?.map((item, idx) => <Avatar cursor='pointer' _hover={{ zIndex: 3 }} name={item.groupName} src={item.imageUrl} key={idx} />)}
                        </AvatarGroup>
                    </Flex>
                </Flex>
                <BrandInfoCard data={user} role={user?.role} flex={1} alignSelf='center' />
            </Flex>
            <Tabs variant='enclosed'>
                <TabList>
                    <Tab>Basic Information</Tab>
                    {conversation?.length ? <Tab>Conversations</Tab> : null}
                </TabList>
                <TabPanels>
                    <TabPanel>
                        <BrandBasicInfoCard data={user} bgColor='white' flex={1} />
                    </TabPanel>
                    {conversation?.length ? <TabPanel>
                        <Stack direction='column' w={"100%"}>
                            <HStack spacing={{ base: 0, md: 4 }} flexDirection={{ base: 'column', lg: 'row' }}>
                                <Flex id="brandCard" w={'100%'}>
                                    <Box bgColor='white' w='100%' borderRadius='15px' p={{ base: '0', lg: '25px' }}>
                                        <Flex flexDirection='column' alignItems='left' gap='1rem'>
                                            <Box textAlign='left'>
                                                <HStack gap={{ base: '15px', lg: '10px' }} py={2} flexDirection={{ base: 'column', lg: 'row' }}>
                                                    <Flex mt='10px' py='10px' h={{ base: '400px', md: '450px', lg: '450px' }} w={'100%'} overflowY='scroll' flexDirection='column' gap={'10px'}>
                                                        {
                                                            conversation.map((item, idx) => {
                                                                return <Flex cursor={'pointer'} bgColor={!!userChatData && userChatData.moduleId === item.moduleId ? 'lightBlue' : 'white'} flexDirection='column' minW='100%' alignItems='flex-start'
                                                                    onClick={() => props.loadUserConversation(item)}>
                                                                    <Flex p='10px' borderRadius='10px' minW='100%' >
                                                                        <HStack spacing={2}>
                                                                            <Avatar src={item.moduleImageUrl} w='50px' h='50px' borderRadius="12px" me="18px" />
                                                                            <Text fontFamily='Rubik' fontSize='16px' textAlign={['left', 'center']} lineHeight='24px'>{item.moduleTitle}</Text>
                                                                        </HStack>
                                                                        <Spacer />
                                                                        <Button align="right" variant='ghost'>VIEW</Button>
                                                                    </Flex>
                                                                </Flex>
                                                            })
                                                        }
                                                    </Flex>
                                                    <Flex id='chat' w={{ xxl: '100%', base: '100%', md: '50%' }} flex={{ base: 1, lg: 0.5 }} mt='15px'>
                                                        {
                                                            userChatData && userChatData.moduleId &&
                                                            <Chat
                                                                moduleId={userChatData.moduleId}
                                                                module={'campaign'}
                                                                participants={[userChatData.receiverId, userChatData.senderId]}
                                                                width={500}
                                                                height={450}
                                                                disableChat={true}
                                                            />
                                                        }
                                                    </Flex>
                                                    {/* <Chat CampaignID={campaign.id} messages={campaign.messages} width={500} height={450} /> */}
                                                </HStack>
                                            </Box>
                                        </Flex>
                                    </Box>
                                </Flex>
                            </HStack>
                        </Stack>
                    </TabPanel> : null }
                </TabPanels>
            </Tabs>
        </Flex>
    </>
}
