
import { Button, Flex, Box, Text, Tag, TagLabel, Center, Image, useColorModeValue, Avatar, Badge, IconButton, Spacer, Skeleton, SkeletonCircle, SkeletonText, filter, } from "@chakra-ui/react";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CounterCard from "components/Card/CounterCard.js";
import React, { useState, useEffect, useContext, useCallback } from "react";
import ReactDataGrid from '@inovua/reactdatagrid-community'
import { getIssues } from "RestAPI";
import { checkboxColumn } from "../Campaign/CampaignList";
import CardBody from "components/Card/CardBody";
import moment from "moment";
import { EditIcon, ViewIcon } from "@chakra-ui/icons";
import IssueForm from "./IssueForm";
import { AppContext } from "contexts/AppContext";
import { globalStyles } from "theme/styles";
import EmptyList from "assets/img/empty/issues.png";
import NoIssues from "assets/svg/emptyList/NoIssues.svg";
import BBButton from "components/Button/Button";
import { FaPlus } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { TrashIcon } from "components/Icons/Icons";
import { DeleteEntityModal } from "layouts/Dashboard";
import "./Issue.css"

const IssueList = (props) => {
    const navigate = useNavigate()
    const { placeholderImage, userData } = useContext(AppContext)
    const title = 'Issues'
    const textColor = useColorModeValue("black3", "white");
    const [issueData, setIssueData] = useState([])
    const [showIssueForm, setShowIssueForm] = useState(false)
    const [selectedIssue, setSelectedIssue] = useState(null)
    const [overView, setOverview] = useState(null)
    const [loading, setLoading] = useState(true)
    const [deleteModal, setDeleteModal] = useState(null)
    const [entityId, setEntityId] = useState(null)
  
    const deleteItem = (row) => {
      setEntityId(row.id)
      setDeleteModal(true)
    }
  
    const refreshList = useCallback(() => {
        setLoading(true)
        getIssues().then(response => {
            setLoading(false);
            console.log(response.data)
            if (Array.isArray(response.data) && response.data.length) {
                response.data = response.data.map(i => {
                    i.brand = i.createdBy?.brand || null; 
                    i.brandName = i.brand?.brandName
                    return i
                })
                setIssueData(response.data)
                setOverview({
                    totalUsers: response.data.length,
                    campaign: response.data.filter(item => { return (item.issueType === 'Campaign') }),
                    technical: response.data.filter(item => { return (item.issueType === 'Technical') }),
                    profile: response.data.filter(item => { return (item.issueType === 'Profile') }),
                    invoice: response.data.filter(item => { return (item.issueType === 'Invoice') }),
                })
            }
        }).catch(err => { setLoading(false); console.error(err) })
    })

    const viewIssue = (issue) => {
        // setSelectedIssue(issue)
        // setShowIssueForm(true)
        navigate(`/edit/issue/${issue.id}`,{state: {
            issueData: issue,
            readOnly: true
        }})
    }

    const columns = [
        {
            name: "id",
            type: "number",
            header: <Text color="gray3" textTransform='uppercase'>ID</Text>,
            defaultVisible: false,
            defaultFlex: 1,
            defaultWidth: 100,
            sortable: true,
            render: ({ value }) => (
                <Text color="black3" fontSize='md' fontWeight="bold">
                    {value}
                </Text>
            ),
        },
        {
            name: "brandName",
            type: "string",
            header: <Text color="gray3" textTransform='uppercase'>Brand</Text>,
            defaultVisible: userData.role==='SUPER_ADMIN'?true:false,
            defaultFlex: 1,
            defaultWidth: 100,
            sortable: true,
            filterEditorProps: {
                style: {
                    background: '#fefefe',
                },
                placeholder: 'Search by brand...'
            },
            render: ({ value }) => (
                <Text color={textColor} fontSize='md'>
                    {value || 'NA'}
                </Text>
            ),
        },
        {
            name: "createdBy",
            type: "string",
            header: <Text color="gray3" textTransform='uppercase'>user</Text>,
            defaultVisible: true,
            defaultFlex: 1,
            defaultWidth: 100,
            sortable: true,
            filterEditorProps: {
                style: {
                    background: '#fefefe',
                },
                placeholder: 'Search by name...'
            },
            render: ({ value }) => <Flex cursor="pointer" align="center" py=".8rem" minWidth="100%" flexWrap="nowrap" >
                <Avatar src={value.profileImageUrl} w="50px" borderRadius="50px" me="18px" name={`${value.firstName} ${value.lastName}`} />
                <Flex direction="column">
                    <Text
                        fontSize="md"
                        color={textColor}
                        fontWeight="normal"
                        minWidth="100%"
                    >
                        {value.firstName} {value.lastName}
                    </Text>
                    <Text fontSize="sm" color={"gray.500"} fontWeight="normal">
                        {value.email}
                    </Text>
                </Flex>
            </Flex>,
        },
        {
            name: "issueDescription",
            type: "string",
            header: <Text color="gray3" textTransform='uppercase'>description</Text>,
            defaultVisible: true,
            defaultFlex: 1,
            defaultWidth: 100,
            sortable: true,
            filterEditorProps: {
                style: {
                    background: '#fefefe',
                },
                placeholder: 'Search by description...'
            },
            render: ({ value }) => (
                <Text title={value} isTruncated color="black3" fontSize='sm' fontWeight="normal">
                    {value}
                </Text>
            ),
        },
        {
            name: "issueType",
            type: "string",
            header: <Text color="gray3" textTransform='uppercase'>type</Text>,
            defaultVisible: true,
            defaultFlex: 1,
            maxWidth: 120,
            sortable: true,
            render: ({ value }) => (
                <Text color="black3" fontSize='md' fontWeight="normal">
                    {value}
                </Text>
            ),
        },
        {
            name: "issueStatus",
            header: <Text color="gray3">STATUS</Text>,
            defaultVisible: true,
            defaultFlex: 1,
            maxWidth: 160,
            sortable: true,
            render: ({ value }) => {
              let bgColor = globalStyles.colors.statusGreen
              if (value === 'Created') {
                bgColor = globalStyles.colors.statusYellow
              } else if (value === 'Attn. Needed') {
                bgColor = globalStyles.colors.statusRed
              } else if (value === 'Closed') {
                bgColor = '#4DA6F6'
              } else if (value === 'Draft') {
                bgColor = globalStyles.colors.statusGray
              }
              return (
                < Tag textAlign={'center'} minW={28} backgroundColor={bgColor}><Spacer /><TagLabel textAlign={'center'}>{value}</TagLabel><Spacer /></Tag>
              )
            },
          },
        {
            name: "createdOn",
            type: "string",
            header: <Text color="gray3" textTransform='uppercase'>created On</Text>,
            defaultVisible: true,
            defaultFlex: 1,
            maxWidth: 150,
            sortable: true,
            render: ({ value }) => (
                <Text color="black3" fontSize='md' fontWeight="normal">
                    {moment(value).format('MM-DD-YYYY')}
                </Text>
            ),
        },
        {
            name: "reason",
            type: "string",
            header: <Text color="gray3" textTransform='uppercase'>reason</Text>,
            defaultVisible: true,
            defaultFlex: 1,
            defaultWidth: 100,
            sortable: true,
            render: ({ value }) => (
                <Text title={value} isTruncated color="black3" fontSize='sm' fontWeight="normal">
                    {value}
                </Text>
            ),
        },
        {
            id: 'action', defaultFlex: 1, header: <Text color="gray3" textTransform='uppercase'>Action</Text>, defaultWidth: 30, sortable: true, render: ({ data }) => <Flex gap='0.25rem'>
                {userData.role === 'BRAND_USER' ? null : <IconButton onClick={() => viewIssue(data)}
                    variant='outline' size="xs"
                    colorScheme='teal' borderRadius={"50%"}
                    aria-label='Edit item email'
                    icon={<ViewIcon />}
                />}
                <IconButton onClick={() => deleteItem(data)}
                    variant='outline' size="xs"
                    colorScheme='teal' borderRadius={"50%"}
                    aria-label='Delete item'
                    icon={<TrashIcon />}
                />
            </Flex>
        }
    ]

    const defaultFilterValue = [{name: 'brandName', type: 'string', operator: 'contains', value: ''},
        { name: 'createdBy', type: 'username', operator: 'contains', value: '' },
        { name: 'issueDescription', type: 'string', operator: 'contains', value: '' }]
    
    const filterTypes = Object.assign({}, ReactDataGrid.defaultProps.filterTypes, {
        username: {
          name: 'username',
          emptyValue: null,
          operators: [
            {
              name: 'contains',
              fn: ({ value, filterValue, data }) => {
                const match = value.firstName?.toLowerCase().indexOf(filterValue.toLowerCase()) !== -1 || value.lastName?.toLowerCase().indexOf(filterValue.toLowerCase()) !== -1
                return !filterValue ? true : match                  
              }
            }
          ]
        }
      });
    columns.forEach(item => item.style = { borderLeftColor: 'white', borderRightColor: 'white', borderTopColor: 'white', borderBottomColor: 'gray.200' })

    useEffect(refreshList, [])
    return <>
        <DeleteEntityModal 
            visible={deleteModal} 
            closeHandler={(refresh) => {
                if(!!refresh) refreshList()
                setDeleteModal(false)
            }} 
            entity='issue'
            entityId={entityId}
        />
        {/* <IssueForm callback={() => refreshList()} visible={showIssueForm} closeHandler={() => setShowIssueForm(false)} issueData={selectedIssue} /> */}
        <Card id="issue-list" mt='5px' p='16px' overflowX={{ sm: "scroll" }} boxShadow='0px 0px 20px 1px rgb(0 0 0 / 10%)' borderRadius='10px'>
            <CardHeader p='12px 0px 28px 0px'>
                <Flex flex={1} align="center" gap='2rem' w="100%" ml={2} mr={2}>
                    <Flex direction='column' gap='2rem' w="100%">
                        <Flex gap='1rem' flexDirection='column'>
                            <Text fontSize='30px' color={textColor} fontWeight='bold' pb='.5rem'>
                                {title}
                            </Text>
                            {
                                !loading && issueData && issueData.length == 0 && <>
                                    <Text fontStyle='italic' fontWeight='600' fontSize='16px' maxW='540px'>This is your issues view, where you can open issues and find help.</Text>
                                </>
                            }
                            <BBButton shadow={true} maxW='fit-content' size="sm" leftIcon={<FaPlus />} colorScheme='brandbassBlue' color="white" onClick={() => navigate('/new/issue')}>
                                ISSUE
                            </BBButton>
                        </Flex>
                        <Flex gap='20px' p='10px' alignItems='center' borderRadius='10px' >
                            {
                                loading && (
                                    <Flex align="center" minWidth="100%" flexWrap="nowrap">
                                        <Box padding='6' boxShadow='lg' minWidth="100%">
                                            <SkeletonText mt='4' noOfLines={4} spacing='4' />
                                        </Box>
                                    </Flex>
                                )
                            }
                            {!loading && issueData && issueData.length > 0 && (<>
                                <CounterCard title={"Total"} width={180} count={overView ? overView.totalUsers : 0} />
                                <CounterCard title={"Campaign"} width={180} count={overView ? overView.campaign.length : 0} />
                                <CounterCard title={"Technical"} width={180} count={overView ? overView.technical.length : 0} />
                                <CounterCard title={"Profile"} width={180} count={overView ? overView.profile.length : 0} />
                                <CounterCard title={"Invoice"} width={180} count={overView ? overView.invoice.length : 0} />
                            </>)}
                        </Flex>
                    </Flex>
                </Flex>
            </CardHeader>
            <CardBody >
                <>
                    {
                        loading && (
                            <Flex align="center" minWidth="100%" flexWrap="nowrap">
                                <Box padding='6' boxShadow='lg' minWidth="100%">
                                    <SkeletonCircle size='20' />
                                    <SkeletonText mt='4' noOfLines={4} spacing='4' />
                                </Box>
                            </Flex>
                        )
                    }
                    {issueData && issueData.length == 0 && (
                        <Flex align="center" minWidth="100%" flexWrap="nowrap">
                            <Box padding='6' minWidth="100%" justifyContent={"center"}>
                                <Center>
                                    <Image
                                        src={NoIssues}
                                        maxW="40%"
                                        borderRadius="lg"
                                        fallbackSrc={placeholderImage(40)}
                                        boxShadow='xs'
                                    /></Center>
                            </Box>
                        </Flex>
                    )}
                    {!loading && issueData && issueData.length > 0 && (
                        <ReactDataGrid
                            loading={issueData === null}
                            className="brandbass-data-grid"
                            style={{ minHeight: 860, borderColor: 'white' }}
                            idProperty="id"
                            columns={columns}
                            dataSource={issueData || []}
                            pagination={true}
                            defaultLimit={10}
                            defaultSortInfo={{ id: "id", name: 'id', dir: -1, type: 'number' }}
                            checkboxColumn={checkboxColumn}
                            checkboxOnlyRowSelect={true}
                            rowHeight={70}
                            headerHeight={60}
                            showZebraRows={false}
                            enableSelection={true}
                            defaultLoading={loading}
                            showHoverRows={true}
                            showCellBorders="horizontal"
                            enableColumnAutosize={true}
                            defaultFilterValue={defaultFilterValue}
                            filterTypes={filterTypes}
                            enableColumnFilterContextMenu={false}
                            emptyText="No issues"
                        />
                    )}
                </>
            </CardBody>
        </Card>
    </>
}

export default IssueList