// Chakra imports
import {
  FormControl,
  FormHelperText,
  Flex,
  useToast,
  Spinner
} from "@chakra-ui/react";
// Custom components
import React, { useState, useEffect, useCallback } from "react";
import { adminCreateUser, checkEmailAvailability, checkUsernameAvailability, getBrandGroups, getBrandTeams } from "RestAPI";
import BBInput from "components/Input/Input";
import BBDropdown from "components/Dropdown/Dropdown";
import BBAutocomplete from "components/Autocomplete/Autocomplete";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { FormHeader } from "layouts/FormLayout";
import { getDashboardUser } from "RestAPI";

const UserForm = ({ onClose, refreshList }) => {
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [userName, setUserName] = useState('')
  const [email, setEmail] = useState('')
  const [role, setRole] = useState('')
  const [password, setPassword] = useState('None')
  const [userBrandFlag, setUserBrandFlag] = useState('None')
  const [usernameAvailable, setUsernameAvailable] = useState(true)
  const [emailAvailable, setEmailAvailable] = useState(true)
  const [loading, setLoading] = useState(false)
  const [teams, setTeams] = useState([])
  const [groups, setGroups] = useState([])
  const [selectedTeam, setSelectedTeam] = useState()
  const [selectedGroups, setSelectedGroups] = useState([])
  const [submit, setSubmit] = useState(false)
  const [user, setUser] = useState(null)
  const { initializeForm } = useOutletContext();
  const navigate = useNavigate()
  const toast = useToast()
  const { UserID } = useParams()

  const selectGroup = (values) => {
    let data = groups.filter(group => values.includes(group.groupName));
    setSelectedGroups(data.map(g => { return { id: g.id, value: g.groupName, name: g.groupName } }))
  }

  const teamsHandler = (value, item) => {
    console.log('teamhshand.ler', value, item)
    setSelectedTeam(item?.originalValue)
  }

  const saveUser = useCallback(() => {
    if (firstName && lastName && userName && email && role && password) {
      if (role === 'AMBASSADOR' && !userBrandFlag) {
        return
      }
      setLoading(true)
      let params = { firstName, lastName, userName, email, role, userBrandFlag, teams: [selectedTeam], groups: selectedGroups }
      if (!UserID && !user) params.password = password
      if(UserID) params.id = +UserID
      adminCreateUser(params)
        .then(response => {
          toast({
            title: `User "${userName}" created successfully`,
            description: "",
            status: 'success',
            position: 'top',
            duration: 3000,
            isClosable: true,
          })
          setLoading(false)
          navigate('/users')
        }).catch(err => {
          toast({
            title: `An error occured when saving the user.`,
            description: "Please try after sometime or create an issue",
            status: 'error',
            position: 'top',
            duration: 3000,
            isClosable: true,
          })
          setLoading(false)
        })
    } else {
      if (firstName || !lastName || !userName || !email || !role || !password) {
        setSubmit(true)
      }
    }
  }, [firstName, lastName, userName, email, role, password, userBrandFlag, selectedTeam, selectedGroups])

  const usernameAvailability = () => {
    if (userName) {
      checkUsernameAvailability(userName)
        .then(response => {
          if (response.data) {
            setUsernameAvailable(response.data.available)
          }
        })
    } else {
      setUsernameAvailable(true)
    }
  }

  const emailAvailability = () => {
    if (email) {
      checkEmailAvailability(email)
        .then(response => {
          if (response.data) {
            setEmailAvailable(response.data.available)
          }
        })
    } else {
      setEmailAvailable(true)
    }
  }

  const fetchUserDetails = async () => {
    if (!UserID) return
    await getDashboardUser(UserID).then(response => {
      setUser(response.data && response.data.id ? response.data : null)
      // setLoading(false)
    }).catch(err => setLoading(false))
  }

  useEffect(fetchUserDetails, [UserID])

  useEffect(async() => {
    setLoading(true)
    setTimeout(async() => {
      let teamsRes = await getBrandTeams()
      if (Array.isArray(teamsRes) && teamsRes.length) {
        setTeams(teamsRes)
      }
      let groupsRes = await getBrandGroups()
      if (Array.isArray(groupsRes) && groupsRes.length) {
        setGroups(groupsRes)
      }
      setLoading(false)
    }, 2000)    
  }, [])

  useEffect(() => {
    setFirstName(user?.firstName || null)
    setLastName(user?.lastName || null)
    setUserName(user?.userName || null)
    setEmail(user?.email || null)
    setRole(user?.role || null)
    setSelectedTeam(user?.teams?.length ? user.teams[0] : null)
    setSelectedGroups(user?.groups || null)
    setUserBrandFlag(user?.userBrandFlag || null)
  }, [user])

  return loading ? <Flex alignSelf={'center'}><Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='brandbassBlue' size='xl' /></Flex> : (
    <>
      <FormHeader
        caption={ !!UserID ? 'Edit User' : 'Create User'}
        submitHandler={saveUser}
        closeHandler={() => navigate('/users')}
        cancelHandler={() => navigate('/users')}
      />
      <Flex id="user-form" flexDirection={'column'}>
        <Flex flexDirection={{ base: 'column', xl: "row" }}>
          <FormControl style={{ margin: 16 }}>
            <BBInput disabled={!!UserID} value={firstName} isInvalid={submit && !firstName} required={true} label="First Name" placeholder='First name' onChange={(e) => { setSubmit(false); setFirstName(e.target.value) }} />
          </FormControl>
          <FormControl style={{ margin: 16 }}>
            <BBInput disabled={!!UserID} value={lastName} isInvalid={submit && !lastName} required={true} label="Last Name" placeholder='Last name' onChange={(e) => { setSubmit(false); setLastName(e.target.value) }} />
          </FormControl>
        </Flex>
        <Flex flexDirection={{ base: 'column', xl: "row" }}>
          <FormControl style={{ margin: 16 }} isInvalid={!usernameAvailable}>
            <BBInput disabled={!!UserID} value={userName} required={true} label="Username" isInvalid={submit && !userName} placeholder='Username' status={!usernameAvailable ? 'error' : null} message={'Username is not available. Please choose another one.'}
              onChange={(e) => { setSubmit(false); setUserName(e.target.value) }}
              onBlur={usernameAvailability} />
          </FormControl>
          <FormControl style={{ margin: 16 }} isInvalid={!emailAvailable}>
            <BBInput disabled={!!UserID} value={email} required={true} label="Email" isInvalid={submit && !email} placeholder='Email' status={!emailAvailable ? 'error' : null} message={'Email is not available. Please choose another one.'} onChange={(e) => { setSubmit(false); setEmail(e.target.value) }} onBlur={emailAvailability} />
          </FormControl>
        </Flex>
        {
          !UserID && !user &&
          <Flex flexDirection={{ base: 'column', xl: "row" }}>
            <FormControl style={{ margin: 16 }}>
              <BBInput disabled={!!UserID} required={true} isInvalid={submit && !password} label="Temporary Password" placeholder='Password' onChange={(e) => { setSubmit(false); setPassword(e.target.value) }} />
              <FormHelperText>
                Choose a password with at least one uppercase, one lowercase, one number and one special character (!@#$)
              </FormHelperText>
            </FormControl>
          </Flex>
        }
        <Flex flexDirection={{ base: 'column', xl: "row" }}>
          <FormControl style={{ margin: 16 }}>
            <BBDropdown bgColor={!UserID ? 'auto' : 'white'} cursor={!UserID ? 'auto' : 'not-allowed'} disabled={!!UserID} required={true} isInvalid={submit && !role} width={'100%'} height="50px" label="Role" placeholder="Select role" value={role} onClick={(e) => { setSubmit(false); setRole(e.target.value) }}
              options={[{ name: 'Brand Manager', value: 'BRAND_MANAGER' }, { name: 'Brand User', value: 'BRAND_USER' }, { name: 'Ambassador', value: 'AMBASSADOR' }]} />

          </FormControl>
          {role === 'AMBASSADOR' ? <FormControl style={{ margin: 16 }}>
            <BBDropdown
              disabled={!!UserID}
              width={'100%'}
              height="50px"
              label="User Type"
              placeholder="Select user type"
              value={userBrandFlag}
              onClick={(e) => setUserBrandFlag(e.target.value)}
              options={[{ name: 'Exclusive', value: 'Exclusive' }, { name: 'Exclusive to group', value: 'Exclusive group' },
              { name: 'Open to public', value: 'Preferred public' }, { name: 'Public with restriction', value: 'Preferred public restricted' }]} />
          </FormControl> : null}
        </Flex>

        <Flex flexDirection={{ base: 'column', xl: "row" }} style={{ margin: 16 }} >
          {!UserID || teams?.length ? <BBAutocomplete style={{ marginRight: 16 }} 
            defaultValues={selectedTeam ? [selectedTeam.teamName] : []}
            options={teams.map(t => { return { id: t.id, value: t.teamName, name: t.teamName, imageUrl: t.imageUrl } })} 
            isMultiple={false} label='Add to team' 
            onChange={(value, item) => teamsHandler(value, item)} /> : null}
          {!UserID || groups?.length ? <BBAutocomplete className='addGroup'
            defaultValues={selectedGroups?.length ? selectedGroups.map(g => g.groupName) : []}
            options={groups.map(g => { return { id: g.id, value: g.groupName, name: g.groupName, imageUrl: g.imageUrl } })} 
            isMultiple={true} label='Add to group' 
            onChange={(values) => selectGroup(values)} /> : null}
        </Flex>
      </Flex>
    </>
  );
};

export default UserForm;

