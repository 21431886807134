// Chakra imports
import {
    Button,
    Flex,
    FormControl,
    FormLabel,
    Input,
    Text,
    useToast,
    Spinner
  } from "@chakra-ui/react";
  // Assets
  import React, { useState } from "react";
  import { createAttachmentObject } from 'RestAPI';
  import { TrashIcon } from "components/Icons/Icons";
  import { globalStyles } from "theme/styles";
import { Separator } from "components/Separator/Separator";

  
  function FileAttachment(props) {
    const {attachments, setAttachments} = props
    const [fileUploading1, setFileUploading1] = useState(false)
    const [fileUploading2, setFileUploading2] = useState(false)
    const isReadOnly = !setAttachments
    const toast = useToast()

    const handleAttachment = async (e, type) => {
        const file = e.target.files[0];
        if (!file) return;
        if(file.type !== 'application/pdf'){
            toast({
                title: `File upload error`,
                description: "Only pdf files are allowed to upload",
                status: 'warning',
                position: 'top',
                duration: 3000,
                isClosable: true,
            })
            return
        }
        try{
            type === 'Guidelines' ? setFileUploading2(true) : setFileUploading1(true)
            const attachmentRes = await createAttachmentObject({ mimeType: file.type, attachmentType: type, fileName: file.name });
            if(attachmentRes && attachmentRes.signedUrl){
                await fetch(attachmentRes.signedUrl, {
                    method: 'PUT',
                    headers: { 'Content-Type': file.type },
                    body: file,
                });
                setAttachments([...attachments, attachmentRes])
            }
            type === 'Guidelines' ? setFileUploading2(false) : setFileUploading1(false)
        }catch(err){
            type === 'Guidelines' ? setFileUploading2(false) : setFileUploading1(false)
        }
    };

    const removeAttachment = (index, type) => {
        let otherAttachements = attachments.filter(a => a.attachmentType !== type)
        let filteredAttachments = attachments.filter(a => a.attachmentType === type).filter((a,ind) => ind !== index)
        setAttachments([...otherAttachements, ...filteredAttachments])
    }

    return attachments ? (
    <>
        <Flex direction={{base:'column',lg:"row"}} style={props.style}>
            <FormControl mt={8} direction="column" >
                {isReadOnly ? null : <FormLabel lineHeight='22px' fontWeight={'bold'} fontSize='16px' padding='5px 2px'>Attachments (Only PDF allowed):</FormLabel>}
                <Flex padding={8} flexDirection={'column'} borderRadius={'md'} borderWidth={1} borderColor={'gray.200'}>
                    <Flex gap={2}>
                        <FormLabel width={40} lineHeight='18px' fontWeight={'500'} fontSize='14px' padding='5px 2px' ml={8}>License & Agreements</FormLabel>
                        {isReadOnly ? null : <> 
                        <Input
                            type="file"
                            onChange={(e) => handleAttachment(e,'License & Agreements')}
                            bg="transparent"
                            position="absolute"
                            // inset="0 0 0 0"
                            cursor="pointer"
                            zIndex={9}
                            opacity={0}
                            // w={'100%'}
                            // h="100%"
                        />
                        {fileUploading1 ? <Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='brandbassBlue' size='xl' /> : <Button variant='solid' borderRadius={'md'} onClick={(e) => e.preventDefault()}>Upload file</Button>}
                        </>}
                    </Flex>
                    <Flex flexDirection='column' ml={64} mt={4} gap={2} mb={8}>
                    {attachments.filter(a => a.attachmentType === 'License & Agreements').map((a, index) => {
                        return(
                        <Flex key={index}>
                            {isReadOnly ? null : <TrashIcon mt={1} mr={8} cursor="pointer" onClick={(e) => {
                            e.stopPropagation()
                            removeAttachment(index,'License & Agreements')
                            }} />}
                        {a.signedUrlRead ? <a style={{textDecoration:'underline', color:`${globalStyles.colors.secondary}`, fontSize:14}} href={a.signedUrlRead} target='_blank'>{a.fileName}</a> : <Text fontSize={14}>{a.fileName}</Text>}
                        </Flex>
                        )})}
                    </Flex>
                    <Separator/>
                    <Flex gap={2} mt={8}>
                        <FormLabel width={40} lineHeight='18px' fontWeight={'500'} fontSize='14px' padding='5px 2px' ml={8}>Guidelines</FormLabel>
                        {isReadOnly ? null : <> 
                        <Input
                            type="file"
                            onChange={(e) => handleAttachment(e,'Guidelines')}
                            bg="transparent"
                            position="absolute"
                            // inset="0 0 0 0"
                            cursor="pointer"
                            opacity={0}
                            zIndex={9}
                            w={'100%'}
                            // h="100%"
                        />
                        {fileUploading2 ? <Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='brandbassBlue' size='xl' /> : <Button variant='solid' borderRadius={'md'} onClick={(e) => e.preventDefault()}>Upload file</Button>}
                        </>}
                    </Flex>
                    <Flex flexDirection='column' ml={64} mt={4} gap={2}>
                    {attachments.filter(a => a.attachmentType === 'Guidelines').map((a, index) => {
                        return(
                        <Flex key={index}>
                            {isReadOnly ? null : <TrashIcon mt={1} mr={8} cursor="pointer" onClick={(e) => {
                            e.stopPropagation()
                            removeAttachment(index, 'Guidelines')
                            }} /> }
                            {a.signedUrlRead ? <a style={{textDecoration:'underline', color:`${globalStyles.colors.secondary}`, fontSize:14}} href={a.signedUrlRead} target='_blank'>{a.fileName}</a> : <Text fontSize={14}>{a.fileName}</Text>}
                        </Flex>
                        )})}
                    </Flex>
                </Flex>
            </FormControl>
        </Flex>
    </>
    ) : null;
}
    
export default FileAttachment;
    