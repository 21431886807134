
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Routes, Redirect } from "react-router-dom";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import AuthLayout from "layouts/Auth.js";
import AdminLayout from "layouts/Admin.js";
import RTLLayout from "layouts/RTL.js";
import { AppProvider } from "contexts/AppContext";
import './global.css'
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { BrandBassDashboardRouter } from "routes";
import theme from "theme/theme";
import { ChakraProvider } from "@chakra-ui/react";

// const stripePromise = loadStripe('pk_test_51JhNFMIkc4cbCVBb7VCiyCojw25S3JKMHmBzXasec1jDuEgLd1UOzmejIhD4CaTHkJz4jrLISA8joTUs3nLSXMwF00iz5CQq4O');
// const stripePromise = loadStripe('pk_live_51JhNFMIkc4cbCVBbIisollGImgE684jpk8okoDU5r1BeM7mIcrusjmxPPCyFdoq9IwwqiKRGeZEmL1o9w09eSeEh00U6xq1Iga');
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY)

ReactDOM.render(
  <ChakraProvider theme={theme} resetCss={false}>
    <Elements stripe={stripePromise}>
      <AppProvider>
        <BrowserRouter>
          <BrandBassDashboardRouter />
        </BrowserRouter>
      </AppProvider>
    </Elements>
  </ChakraProvider>,
  document.getElementById("root")
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();