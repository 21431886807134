/*eslint-disable*/
import React from "react";
import PropTypes from "prop-types";
import {
    Box,
    Flex,
    Text,
    ListItem, List, ListIcon,Link
} from "@chakra-ui/react";
import {
    ShoppingCartIcon,
    EditIcon,
    WarningIcon,
    BellIcon,
    MessageIcon
} from "components/Icons/Icons.js";
export default function BBNotificationCard(props) {
    const {submissionPending, messagesPending, attnNeededCampaigns} = props
    return (
        <>
            <Box bgColor='#F2F2F2' borderRadius='30px' p='25px' width={"100%"} >
                <Flex flexDirection='row' alignItems='center' gap='1rem' marginBottom='20px'>
                    <BellIcon /><Text variant="notification-label">Stay up to date with the latest notifications!</Text>
                </Flex>
                <Flex alignContent={'center'}>
                    <List spacing={4} ml="35px">
                        <ListItem>
                            <ListIcon as={WarningIcon} color='brandbassPink.500' />
                            <Text variant={"notification-text"} as={'span'}>Approval needed on the Campaign - 
                            { submissionPending.length ? <Link textDecoration={"underline"} href={submissionPending?.length ? `campaigns/${submissionPending[0].id}` : ''}>{submissionPending?.length ? submissionPending[0].name:'None'}</Link> : ' None'}
                            </Text>
                        </ListItem>
                        <ListItem>
                            <ListIcon  as={EditIcon} />
                            <Text variant={"notification-text"} as={'span'}>View your latest <Link textDecoration={"underline"} href={`campaigns?status=Closed`}>gig approvals.</Link></Text>
                        </ListItem>
                        <ListItem>
                            <ListIcon  as={MessageIcon} />
                            <Text variant={"notification-text"} as={'span'}>You have <Link textDecoration={"underline"} href={`notifications`}> {messagesPending || 0} pending messages!</Link></Text>
                        </ListItem>
                        <ListItem>
                            <ListIcon  as={ShoppingCartIcon} />
                            <Text variant={"notification-text"} as={'span'}><Link textDecoration={"underline"} href={`campaigns?status=Attn. Needed`}>{attnNeededCampaigns} campaigns</Link> need attention.</Text>
                        </ListItem>
                    </List>
                </Flex>
            </Box>
        </>
    );
}
