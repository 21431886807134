import { SearchIcon } from "@chakra-ui/icons"
import { Box, Flex, Button, Input, InputGroup, InputLeftAddon, InputRightAddon, InputRightElement, Text, Spacer, Image, Avatar, Icon, CloseButton, Grid, GridItem, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, Spinner, AvatarGroup } from "@chakra-ui/react"
import { SendMessageIcon } from "components/Icons/Icons"
import { SearchBar } from "components/Navbars/SearchBar/SearchBar"
import { AppContext } from "contexts/AppContext"
import moment from "moment"
import React, { useContext, useEffect, useRef, useState } from "react"
import { getUserPublicInfo } from "RestAPI"
import { getUserData } from "RestAPI"
import { getCampaignMessages, postCampaignMessage } from "RestAPI"
import _debounce from 'lodash/debounce';
import { FaBullhorn, FaCloudDownloadAlt, FaDownload, FaFileAudio, FaFileDownload, FaPaperclip, FaRegFile, FaRegFileAudio, FaRegFileExcel, FaRegFilePdf, FaRegFilePowerpoint, FaRegFileWord, FaVideo, FaVuejs } from "react-icons/fa"
import { getFileUploadSignedUrl } from "RestAPI"
import { createMessageAttachment, getOneConversation } from "RestAPI"
import _remove from "lodash/remove"
import { getMessageFileUploadSignedUrl, createOrFindConversation } from "RestAPI"
import { BBSecondaryButton } from "components/Button/Button"
import { AiOutlineFileZip } from "react-icons/ai"

const Chat = (props) => {
  const { userData } = useContext(AppContext)
  const { moduleId, module, moduleName, messages, width, height, receiver } = props
  const [conversationId, setConversationId] = useState(props.conversationId)
  const [conversation, setConversation] = useState(messages || [])
  const [otherUser, setOtherUser] = useState(receiver || null)  
  const [messageText, setMessageText] = useState('')
  const [messageAttachment, setMessageAttachment] = useState([])
  const [messageAttachmentPreview, setMessageAttachmentPreview] = useState([])
  const messagesEndRef = useRef(null)
  const [loading, setLoading] = useState(false)
  const [disableChat, setDisableChat] = useState(props.disableChat || false)
  const [showSender, setShowSender] = useState(false)
  const [isUploading, setIsUploading] = useState(false)
  const [participants, setParticipants] = useState(participants)

  const fetchConversation = async () =>{
    if(conversationId){
      setLoading(true)
      let conversation = await getOneConversation(conversationId)
      setLoading(false)
      if(conversation && conversation.users && conversation.id === conversationId){
        setParticipants(conversation.users)
      }
      if(conversation && conversation.messages  && conversation.id === conversationId){
        setConversation(conversation.messages)
        setDisableChat(false)
      }
      if(conversation ){
        let userMatch = conversation.users.filter(u => u.id === userData.id)
        console.log(userMatch.length)
        if(!userMatch.length){
          setDisableChat(true)
        }
      }
      if(!conversation){
        setParticipants([])
        setConversation([])
        setDisableChat(true)
      }
    }
    if(!conversationId && moduleId){
      let conversationObj = {conversationType: module}
      conversationObj[module] = {id: moduleId}
      conversationObj.conversationTitle = moduleName
      let response = await createOrFindConversation(conversationObj)
      if(response?.id){
        setConversationId(response.id)
        setConversation(response.messages || [])
      }
    }
  }

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" })
  }

  useEffect(() => {
    const timedFunction = setInterval(fetchConversation, 30000);
    return () => clearInterval(timedFunction);
  }, [conversationId])

  useEffect(() => {
    console.log('chat convid', conversationId)
    fetchConversation()
  }, [])

  useEffect(() => {
    scrollToBottom()
  }, [conversation])

  useEffect(() => {
    if (userData?.role?.includes('BRAND') || userData?.role?.includes('ADMIN')) {
      return setShowSender(true)
    }
    return setShowSender(false)
  }, [userData])

  const sendMessage = () => {
    if (!messageText && !messageAttachment) return
    const message = {
      message: messageText,
      messageType: `General`,
      receiverId: otherUser?.id,
      moduleId,
      module,
      attachments: messageAttachment,
      conversation: {id: conversationId}
    }
    setMessageText('')
    postCampaignMessage(message).then(response => {
      if (response && response.data) {
        fetchConversation()
      }
      scrollToBottom()
      setMessageAttachment([])
      setMessageAttachmentPreview([])
    }).catch(err => {
      console.error(err);
    })
  }

  const uploadAttachment = async (e) => {
    const files = e.target.files;
    if (!files || !files.length) return;
    setIsUploading(true)
    for (const file of files) {
      const s3Obj = await getMessageFileUploadSignedUrl({ mime: file.type }, "Message");
      if (!s3Obj || !s3Obj.data || !s3Obj.data.key)
        return

      fetch(s3Obj.data.url, {
        method: 'PUT',
        headers: { 'Content-Type': file.type },
        body: file,
      }).then(async (res) => {
        setIsUploading(false)
        if (res.status === 200 && res.ok === true) {
          const messageAttachmentRecord = await createMessageAttachment(s3Obj.data.key, file.type)
          setMessageAttachment([...messageAttachment, messageAttachmentRecord.data])
          setMessageAttachmentPreview([...messageAttachmentPreview, URL.createObjectURL(file)]);
        }
      }).catch(err => {
        setIsUploading(false)
        console.error(err)
      })
    }
  }

  return (!userData || !userData.id) ? null : <>
    <Box border='1px' borderColor='gray4' w={width} h={height} maxW='100%' overflow='hidden' borderRadius='1rem' position='relative' p='20px 20px 0 20px'>
      {loading ? <Flex flexDir={'column'} flex={1} width='100%' height='100%' alignItems='center'><Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='brandbassBlue' size='xl' /> </Flex> : 
      <>
      <Flex>
        <Text fontWeight='700' fontSize={20}>Chat</Text>
        <Spacer />
        {participants?.length ? 
        <AvatarGroup>
          {
            participants.map(user => {
              return (<Avatar
                name={user.firstName}
                key={user.id}
                src={user.imageUrl}
                _hover={{ zIndex: "3", cursor: "pointer" }}
              />)
            })
          }
        </AvatarGroup>
        :null}
        {otherUser ? <Avatar
          name={otherUser.firstName}
          src={otherUser.profileImageUrl}
          size='xl'
          _hover={{ zIndex: "3", cursor: "pointer" }}
        /> : null}
      </Flex>
      {/* <InputGroup>
        <Input variant='flushed' placeholder="Search in chat" color='brandbassBlue.500' _placeholder={{ opacity: 1, color: 'gray.500', fontWeight: '700' }} />
        <InputRightElement children={<SearchIcon color='gray.500' />} />
      </InputGroup> */}
      <Flex mt='10px' py='10px' h='65%' overflow='scroll' flexDirection='column' gap='10px'>
        {
          conversation.map((item, idx) => {
            if (userData.id === item.sender?.id) return <OwnMessage key={idx} msg={item} showSender={showSender} />
            return <ReceivedMessage key={idx} msg={item} showSender={showSender} />
          })
        }
        <div ref={messagesEndRef}></div >
      </Flex>
      <Flex position='absolute' bottom={0} right={0} left={0} flexDirection='column' >
        {
          !!messageAttachmentPreview && !!messageAttachmentPreview.length &&
          <Flex gap='10px' h='300px' py='10px' px='20px' minW='full' bgColor='brandbassBlue2.200' boxShadow='xs'>
            {
              messageAttachmentPreview.map((item, idx) => <Flex key={idx} position='relative' boxShadow='xs' w='100px' h='75px' align='center' justify='center' bgColor='white'>
                <CloseButton position='absolute' top={0} right={0} bgColor='white' opacity={0.7} _hover={{opacity: 1}} cursor='pointer' zIndex={1}
                  onClick={() => {
                    const remainingPreviews = messageAttachmentPreview.filter(i => i !== item)
                    const remainingItems = messageAttachment.filter(i => i.id !== item.id)
                    setMessageAttachmentPreview(remainingPreviews)
                    setMessageAttachment(remainingItems)
                  }}
                />
                <FileTypeImagePreview item={{...messageAttachment[idx], fileUrl: item}} />
              </Flex>)
            }
          </Flex>
        }
        <Flex bgColor='brandbassGray.100' h='60px' borderTopRadius={0} borderBottomRadius='1rem' overflow='hidden' alignItems='center' justifyContent='space-evenly'>
          <Button _hover={{ background: 'transparent' }} variant='ghost' disabled={!conversation || disableChat}>
            {
              isUploading ? <Spinner /> : <Icon as={FaPaperclip} w={5} h={5} />
            }
            <Input disabled={isUploading || !conversation || disableChat} type="file" onChange={uploadAttachment} bg="transparent" position="absolute" inset="0 0 0 0" cursor="pointer" opacity={0} h="100%" />
          </Button>
          <Input placeholder='Type your message...' variant='outline' disabled={disableChat} bgColor='white' maxW='80%' value={messageText} onChange={(e) => setMessageText(e.target.value)} />
          <Button _hover={{ background: 'transparent' }} variant='ghost' onClick={sendMessage} disabled={isUploading || !conversation || disableChat}><SendMessageIcon /></Button>
        </Flex>

      </Flex>
      </>}
    </Box>
  </>
}

export default Chat

const renderMessage = (message) => {
  const checkForLink = (text) => {
    let regex = /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/ig
    return regex.test(text)
  }
  return (
    <Flex style={{
        flexDirection: 'row',
        alignSelf: 'flex-start',
        justifyContent:'space-evenly'       
      }}>
      <Text>
        {message?.split(' ').map((msg,index) => {
          return checkForLink(msg) ? <a style={{color: 'blue',textDecorationLine:"underline"}} target="_blank" href={`https://${msg.replace('https://','')}`} key={index}>{msg}{' '}</a> : msg + ' '
        })}
      </Text>
    </Flex>
  )
}

export const OwnMessage = (props) => {
  const { msg, showSender } = props
  const [showAttachmentModal, setShowAttachmentModal] = useState(false)

  return <>
    <AttachmentModal visible={showAttachmentModal} closeHandler={() => setShowAttachmentModal(false)} attachments={msg.attachments} />
    <Flex flexDirection='column' maxW='75%' alignItems='flex-end' alignSelf={'flex-end'}>
      <Flex flexDirection='column' p='10px' borderRadius='10px' bgColor='brandbassGray.200' position='relative'  minWidth={'200px'}>
        {
          !!showSender &&
          <Text fontSize='xs' fontStyle='italic' fontWeight='semibold' alignSelf='flex-end'>
            {`${msg.sender.firstName} ${msg.sender.lastName} - ${msg.sender.role.replaceAll('_', ' ')}`}
          </Text>
        }
        {
          !!msg.attachments && !!msg.attachments.length &&
          <Grid cursor='pointer' templateColumns='repeat(2, 1fr)' gap='6px' py='0.5rem' onClick={() => setShowAttachmentModal(true)}>
            {
              msg.attachments.map((item, idx, arr) => {
                if (idx > 3) return null
                if (idx <= 2 || (idx === 3 && arr.length === 4)) return <GridItem key={idx} rounded='sm' overflow='hidden' w='100%' h='10' bgColor='white' display='flex' style={{alignItems: 'center', justifyContent: 'center'}} >
                  <FileTypeImagePreview item={item} />
                </GridItem>
                if (idx === 3 && arr.length > 4) return <GridItem key={idx} rounded='sm' overflow='hidden' w='100%' h='10' bgColor='white' position='relative' display='flex' style={{alignItems: 'center', justifyContent: 'center'}} >
                  <FileTypeImagePreview item={item} />
                  <Box position='absolute' inset='0' opacity={0.4} bgColor='black' />
                  <Flex position='absolute' inset='0' justify='center' align='center' >
                    <Text color='white'>+{arr.length - 4}</Text>
                  </Flex>
                </GridItem>
              })
            }
          </Grid>
        }

        {renderMessage(msg.message)}
      </Flex>
      <Text fontSize='9px' color='gray.400'> {moment(msg.createdOn).fromNow()} </Text>
    </Flex>
  </>
}

export const FileTypeImagePreview = (props) => {
  const { item } = props
  if (item.attachmentType?.toLowerCase() === 'image') {
    return <Image src={item.fileUrl || ''} w='full' h='full' objectFit='cover' />
  }
  if (item.attachmentType?.toLowerCase() === 'video') {
    return <Icon as={FaVideo} w='50%' h='50%' objectFit='cover' />
  }
  if (item.attachmentType?.toLowerCase() === 'audio') {
    return <Icon as={FaRegFileAudio} w='50%' h='50%' objectFit='cover' />
  }
  return <Icon id='fileIcon' w='full' h='full' objectFit='cover' as={() => fileIcon(item.mimeType)} />
}

export const fileIcon = (mimeType) => {
  switch (mimeType) {
    case 'application/msword':
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
    case 'text/plain':
      return <FaRegFileWord style={{width: '50%', height: '50%'}} />

    case 'application/pdf':
    case 'application/epub+zip':
      return <FaRegFilePdf style={{width: '50%', height: '50%'}} />
    
    case 'text/csv':
    case 'application/vnd.ms-excel':
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      return <FaRegFileExcel style={{width: '50%', height: '50%'}} />
    
    case 'application/vnd.ms-powerpoint':
    case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
      return <FaRegFilePowerpoint style={{width: '50%', height: '50%'}} />
    
    case 'application/zip':
    case 'application/x-7z-compressed':
    case 'application/x-tar':
      return <AiOutlineFileZip style={{width: '50%', height: '50%'}} />
     
    default:
      return <FaRegFile style={{width: '50%', height: '50%'}} />
  }
}

export const FileTypeAttachmentView = (props) => {
  const { item } = props

  if (item.attachmentType.toLowerCase() === 'image') {
    return <Image cursor='pointer' w="320px" h="240px" src={item.fileUrl} objectFit='cover' />
  }
  if (item.attachmentType.toLowerCase() === 'video' || item.attachmentType.toLowerCase() === 'audio') {
    return <video controls width="320" height="240" preload="metadata"
      style={{ zIndex: 0, maxWidth: 320, maxHeight: 240, minWidth: 320, minHeight: 240 }}
    >
      <source src={item.fileUrl} type={item.mimeType} />
      Your browser does not support the video tag.
    </video>
  }

  return <Icon id='fileIcon' w='full' h='full' objectFit='cover' as={() => fileIcon(item.mimeType)} />
}

export const ReceivedMessage = (props) => {
  const { msg, showSender } = props
  const [showAttachmentModal, setShowAttachmentModal] = useState(false)
  
  return <>
    <AttachmentModal visible={showAttachmentModal} closeHandler={() => setShowAttachmentModal(false)} attachments={msg.attachments} />
    <Flex flexDirection='column' maxW='75%' alignItems='flex-start'>
      <Flex flexDirection='column' p='10px' borderRadius='10px' bgColor='brandbassBlue2.200' position='relative' minWidth={'200px'}>
        <Text fontSize='xs' fontStyle='italic' fontWeight='semibold' alignSelf='flex-end'>
          {!!showSender && `${msg.sender?.firstName || ''} `}
        </Text>
        {
          !!msg.attachments && !!msg.attachments.length &&
          <Grid cursor='pointer' templateColumns='repeat(2, 1fr)' gap='6px' py='0.5rem' onClick={() => setShowAttachmentModal(true)}>
            {
              msg.attachments.map((item, idx, arr) => {
                if (idx > 3) return null
                if (idx <= 2 || (idx === 3 && arr.length === 4)) return <GridItem key={idx} rounded='sm' overflow='hidden' w='100%' h='10' bgColor='white' display='flex' style={{alignItems: 'center', justifyContent: 'center'}} >
                  <FileTypeImagePreview item={item} />
                </GridItem>
                if (idx === 3 && arr.length > 4) return <GridItem key={idx} rounded='sm' overflow='hidden' w='100%' h='10' bgColor='white' position='relative' display='flex' style={{alignItems: 'center', justifyContent: 'center'}} >
                  <FileTypeImagePreview item={item} />
                  <Box position='absolute' inset='0' opacity={0.4} bgColor='black' />
                  <Flex position='absolute' inset='0' justify='center' align='center' >
                    <Text color='white'>+{arr.length - 4}</Text>
                  </Flex>
                </GridItem>
              })
            }
          </Grid>
        }
       {renderMessage(msg.message)}
      </Flex>
      <Text fontSize='10px' color='gray.500'> {moment(msg.createdOn).fromNow()} </Text>
    </Flex>
  </>
}

export const AttachmentModal = (props) => {
  const { visible, closeHandler, successHandler, attachments } = props
  const [showModal, setShowModal] = useState(false)

  useEffect(() => setShowModal(!!visible), [visible])
  return <>
    <Modal isOpen={showModal} onClose={closeHandler} size="6xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader color='brandbassBlue'>
          <Text fontSize='2xl' fontFamily='Rubik' >Attachments</Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <Box p={8} borderWidth="1.5px" borderRadius="lg" overflow="scroll">
            <Flex gap='1.5rem' align='center' justify='center' flexWrap='wrap' >
              {
                attachments.map((item, idx) => <Box boxShadow='0px 0px 0.5px 0.5px lightgrey' key={idx}>
                  <Flex position='relative' w="320px" h="240px" align='center' justify='center' >
                    <a href={item.fileUrl} target='_blank' download={item.s3Key}>
                      <BBSecondaryButton position='absolute' top='2' right='2' zIndex={1} style={{ opacity: 0.9, filter: `drop-shadow(0px 0px 1px white)` }} >
                        <Icon as={FaDownload} w={5} h={5} />
                      </BBSecondaryButton>
                    </a>
                    <FileTypeAttachmentView item={item} />
                  </Flex>
                </Box>
                )
              }
            </Flex>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  </>
}
