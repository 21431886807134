import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
    AutoComplete,
    AutoCompleteInput,
    AutoCompleteItem,
    AutoCompleteList,
    AutoCompleteTag
} from "@choc-ui/chakra-autocomplete";
import {
    Avatar,
    FormControl,
    FormLabel
} from "@chakra-ui/react";
function BBAutocomplete(props) {
    return (
        <FormControl w={props.width ? props.width : '100%'} style={props.style} className={props.className}>
            {   !!props.label &&
                <FormLabel fontSize={'16px'} fontWeight={'bold'}>{props.label}</FormLabel>
            }
            <AutoComplete openOnFocus {...(props.isMultiple && { multiple: true })} onChange={props.onChange} defaultValues={props.defaultValues}>
                {
                    props.isMultiple
                        ?
                        <AutoCompleteInput disabled={props.disabled}
                            minHeight="35px" borderColor="primary" variant="filled"
                            placeholder={props.placeholder ? props.placeholder : "Search..."}
                        >
                            {
                            (({ tags }) =>
                                tags.map((tag, tid) => (
                                    <AutoCompleteTag
                                        background={"gray7"}
                                        key={tid}
                                        label={tag.label}
                                        onRemove={tag.onRemove}
                                    />
                                )))
                            }
                        </AutoCompleteInput>
                        :
                        <AutoCompleteInput disabled={props.disabled} placeholder={props.placeholder ? props.placeholder : "Search..."} height={props.height ? props.height : '50px'} borderColor="primary" variant="filled" />
                }
                <AutoCompleteList>
                    {props.options && !!props.options.length && props.options.map((item, cid) => (
                        <AutoCompleteItem
                            key={`option-${cid}`}
                            value={item}
                            getValue={(item) => item.name}
                            textTransform="capitalize"
                            _selected={{ bg: "whiteAlpha.50" }}
                            _focus={{ bg: "brandbassBlue.600" }}
                            zIndex={1}
                        >
                            <Avatar size="sm" mr={4} name={item.name} src={item.imageUrl} />
                            {item.name}
                        </AutoCompleteItem>
                    ))}
                </AutoCompleteList>
            </AutoComplete>
        </FormControl>
    );
}
export default BBAutocomplete;