import { AddIcon } from "@chakra-ui/icons"
import {
    Box,
    Button,
    Checkbox,
    CheckboxGroup,
    Flex,
    FormControl,
    FormLabel,
    IconButton,
    Image,
    Img,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    NumberDecrementStepper,
    NumberIncrementStepper,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    Select,
    Slider,
    SliderFilledTrack,
    SliderMark,
    SliderThumb,
    SliderTrack,
    Spacer,
    Spinner,
    Text,
    Textarea,
    Tooltip
} from "@chakra-ui/react"
import { AppContext } from "contexts/AppContext"
import { useContext, useEffect, useState } from "react"
import { getCampaignSignedUrl, updateSubmission} from 'RestAPI'

export const SubmitVideo = (props) => {
    const { placeholderImage } = useContext(AppContext)
    const { gig, campaign, refreshCampaign, onClose } = props
    const { id, imageUrl, gigName, gigDescription, gigPrice, videoDuration, contentSettings, videoScenario, additionalDetails, gigRequirement } = gig
    const [video, setVideo] = useState(null)
    const [mimeType, setMimeType] = useState('')
    const [loading, setLoading] = useState(false)
    const [submission, setSubmission] = useState({})
   
    const handleVideoUpload = async (e) => {
        const file = e.target.files[0];
        if (!file) return;
        setLoading(true)
        const res = await getCampaignSignedUrl({
            campaign: {id: campaign.id},
            gig: {id: gig.id, gigName: gig.gigName},
            mimeType: file.type
          });
        const video = URL.createObjectURL(file) || '';
        setVideo(video);
        setMimeType(file.type)
        if(res && res.data && res.data.s3Url){
            await fetch(res.data.s3Url, {
                method: 'PUT',
                headers: { 'Content-Type': file.type },
                body: file,
            });
            setSubmission(res.data.submission);            
        }
        setLoading(false)
        
    };

    const handleSubmit = async () => {
        setLoading(true)
        await updateSubmission(submission);
        setLoading(false)
        onClose()
        refreshCampaign()
    }

    return !gig ? null : (
        <Flex
            gap={4}
            flexDirection="column"
            flexWrap="wrap"
            justifyContent="space-evenly"
            align="center"
        >
            <Box position="relative">
            <Input
                type="file"
                onChange={handleVideoUpload}
                bg="transparent"
                position="absolute"
                inset="0 10 0 10"
                cursor="pointer"
                opacity={0}
                h="50px"
                placeholder="Add video"
            />
            <Box mt={2}
                p={1} borderWidth="1.5px"
                borderRadius="md"><Text>Click to upload video</Text></Box>
            {video ? <video
                controls
                style={{ marginTop: 16, maxWidth: 320, maxHeight: 240, minWidth: 320, minHeight: 240, boxShadow: '0px 0px 5px 1px grey' }}
                width="320"
                height="240"
                preload="metadata"
            >
                <source src={video} type={mimeType} />
                Your browser does not support the video tag.
            </video> : null}
            </Box>
            <Spacer/>
            {video ? 
            <Flex gap={8} alignSelf='end'>
                <Button  mr={3}
                    onClick={props.onClose}
                    colorScheme="brandbassBlue"
                    variant="outline"
                    borderRadius="md"
                >
                    Cancel
                </Button>
                {loading ? <Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='brandbassBlue' size='xl' /> : 
                <Button
                    colorScheme="brandbassBlue"
                    borderRadius="md"
                    onClick={handleSubmit}
                >
                    Submit
                </Button>}
                
            </Flex> : null }
        </Flex>
    )
        
}

export const SubmitVideoModal = (props) => {
    const { visible, closeHandler, gig } = props
    const [showModal, setShowModal] = useState(false)

    useEffect(() => setShowModal(!!visible), [visible])

    return (
        <Modal isOpen={showModal} onClose={closeHandler} size="xl">
            <ModalOverlay />
            <ModalContent>
                <ModalHeader color='brandbassBlue'>Submit Video</ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={6}>
                    <SubmitVideo {...props} onClose={closeHandler}/>
                </ModalBody>
                {/* <ModalFooter>
                
                </ModalFooter> */}
            </ModalContent>
        </Modal>
    )
}