// Chakra imports
import {
  FormControl,
  Checkbox,
  Flex,
  useToast,
  Box,
  Input,
  Image
} from "@chakra-ui/react";
import BBAutocomplete from "components/Autocomplete/Autocomplete";
import BBInput from "components/Input/Input";
import { FormHeader } from "layouts/FormLayout";
// Custom components
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getEligibleUsersForTeam,getFileUploadSignedUrl } from "RestAPI";
import { createTeam } from "RestAPI";

const TeamForm = ({ onClose, refreshList }) => {
  const location = useLocation()
  const data = location.state?.data || null
  const navigate = useNavigate()
  const [teamName, setTeamName] = useState(data ? data.teamName : '')
  const [description, setDescription] = useState(data ? data.description : '')
  const [isActive, setIsActive] = useState(data ? data.isActive : true)
  const [owner, setOwner] = useState(data && data.owner ? data.owner : null)
  const [users, setUsers] = useState([])
  const [loading, setLoading] = useState(false)
  const [selectedUsers, setSelectedUers] = useState(data && data.users ? data.users : [])
  const [submit, setSubmit] = useState(false)
  const [imageUrl,setImageUrl] = useState(data && data.imageUrl ? data.imageUrl : null)
  const [imagePreview, setImagePreview]  = useState(null)
  const toast = useToast()

  // const { isOpen, onOpen, onClose } = useDisclosure()
  useEffect(() => {
    getEligibleUsersForTeam().then(response => {
      if (!response) response = []
      response = [...response, ...selectedUsers]
      setUsers(response)
    })
  }, [])

  const saveTeam = (e) => {
    if (!teamName || !description) {
      setSubmit(true)
      return
    }
    setLoading(true)
    // const selectedUsers = users.filter(u => !!u.selected)
    createTeam({ id: data ? data.id : null, teamName, description, isActive, owner: { id: owner?.id ? owner.id : owner }, users: selectedUsers,imageUrl })
      .then(response => {
        toast({
          title: `Team "${teamName}" saved successfully.`,
          description: "",
          status: 'success',
          position: 'top',
          duration: 3000,
          isClosable: true,
        })
        setLoading(false)
        navigate('/teams')
      }).catch(err => {
        toast({
          title: `An error occured when saving the team.`,
          description: "Please try after sometime or create an issue",
          status: 'error',
          position: 'top',
          duration: 3000,
          isClosable: true,
        })
        setLoading(false)
      })
  }

  const uploadImageUrl = async(e) =>{
    const file = e.target.files[0];
    if (!file) return;

    const s3Obj = await getFileUploadSignedUrl({ mime: file.type }, "team");
    if (!s3Obj || !s3Obj.data || !s3Obj.data.key)
      return;

    setImagePreview (URL.createObjectURL(file) || '');
    setImageUrl(s3Obj.data.key)
    await fetch(s3Obj.data.url, {
      method: 'PUT',
      headers: { 'Content-Type': file.type },
      body: file,
    });
  }

  const getSelectedTeamMembers = (values) => {
    // let userIds = items.map(i => i.originalValue?.id)
    let data = users.filter(item => values.includes(item.firstName + ' ' + item.lastName));
    setSelectedUers(data.map(u => { return { id: u.id, value: u.firstName + ' ' + u.lastName, name: u.firstName + ' ' + u.lastName, imageUrl: u.profileImageUrl } }))
  }

  return (
    <Flex flexDirection={'column'} mb={32}>
      <FormHeader
        caption={!!data ? 'Edit Team' : 'Create Team'}
        submitHandler={() => saveTeam()}
        closeHandler={() => navigate('/teams')}
        cancelHandler={() => navigate('/teams')}
      />
      <Flex flexDirection="column" >
        <Box p={8} borderWidth="0px" borderRadius="lg" w='full' h='full' alignSelf='center'>
          <Flex flexDirection="column" gap={10} mt={{ base: '65px', md: '5px' }}>
            <Flex justify="space-between" flexDirection={{ base: 'column', md: 'row' }}>
              <Box w={{ base: '100%', md: "50%" }}>
                <FormControl style={{ margin: 16 }} >
                  <BBInput required={true} isInvalid={submit && !teamName} value={teamName} label="Team Name" placeholder='Team name' onChange={(e) => { setSubmit(false); setTeamName(e.target.value) }} />
                </FormControl>
                <FormControl style={{ margin: 16 }}>
                  <BBInput required={true} isInvalid={submit && !description} value={description} label="Description" placeholder='Description' onChange={(e) => { setSubmit(false); setDescription(e.target.value) }} />
                </FormControl>
              </Box>
              <Box position="relative" w={{ base: '100%', md: "30%" }} alignItems="center" margin={"16px"}>
                <Input
                  type="file"
                  onChange={uploadImageUrl}
                  bg="transparent"
                  position="absolute"
                  inset="0 0 0 0"
                  cursor="pointer"
                  opacity={0}
                  h="100%"
                />
                <Image
                  src={imageUrl && imageUrl.includes('http') ? imageUrl : imagePreview}
                  objectFit='contain'
                  boxShadow='lg'
                  fallbackSrc="https://via.placeholder.com/250"
                  borderRadius="lg"
                  w={250}
                  h={250}
                  overflow='hidden'
                {...(submit && !imageUrl && { border: '2px solid red' })}
                />
                {submit && !imageUrl && <Text color={'#EB5757'} variant="bb-status">Please upload Image</Text>}
              </Box>
            </Flex>
          </Flex>
          <Checkbox spacing='1rem' style={{ margin: 16 }} id='is-active' onChange={(e) => setIsActive(e.target.checked)} isChecked={isActive} defaultChecked>Is Active</Checkbox>
          <BBAutocomplete
            defaultValues={owner ? [owner.firstName + ' ' + owner.lastName] : []}
            style={{ margin: 16 }}
            options={users.map(u => { return { id: u.id, value: u.firstName + ' ' + u.lastName, name: u.firstName + ' ' + u.lastName, imageUrl: u.profileImageUrl } })}
            isMultiple={false} label='Team owner'
            onChange={(value, item) => setOwner(item?.originalValue)} />
          <BBAutocomplete
            defaultValues={selectedUsers?.map(u => u.firstName + ' ' + u.lastName)}
            style={{ margin: 16, paddingBottom: 56 }}
            options={users.map(u => { return { id: u.id, value: u.firstName + ' ' + u.lastName, name: u.firstName + ' ' + u.lastName, imageUrl: u.profileImageUrl } })}
            isMultiple={true}
            label='Team members'
            onChange={(values, items) => getSelectedTeamMembers(values)} />
        </Box>
      </Flex >
    </Flex >
  );
};

export default TeamForm;

