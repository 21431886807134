import { EditIcon, LockIcon } from "@chakra-ui/icons"
import { Box, Flex, Text } from "@chakra-ui/react"
import { StarIcon } from "components/Icons/Icons"
import { PersonIcon } from "components/Icons/Icons"
import { ProfileIcon } from "components/Icons/Icons"
import { CompletedIcon } from "components/Icons/Icons"
import { VideoCameraIcon } from "components/Icons/Icons"
import { ShipmentIcon } from "components/Icons/Icons"
import { CreditIcon } from "components/Icons/Icons"
import { GlobeIcon } from "components/Icons/Icons"
import { AppContext } from "contexts/AppContext"
import { useContext } from "react"

const CampaignOverview = (props) => {
    // const { overview } = props
    const overview = {

    }

    return <>
        <Flex as='div'
            backgroundColor='brandbassBlue2.200'
            minH='700px'
            maxH='770px'
            w={{ base: 'full', xl: '30%' }}
            borderRadius='3xl'
            flexDirection='column'
            p='30'
            gap='20px'
        >
            <Text fontSize='22' fontWeight='700'>Campaign status</Text>
            <Flex flexDirection='column' marginLeft='40px'>
                <Stepper {...props}/>
            </Flex>
        </Flex>
    </>
}

export default CampaignOverview

export const Stepper = (props) => {
    const {campaign} = props
    const videosCount = campaign.gigs.length;
    const {submissions} = campaign
    const submissionsCount = submissions.filter(sub => sub.revision===0 && sub.submissionStatus !== 'Drafted').length
    const acceptedCount = submissions.filter(sub => sub.submissionStatus === 'Accepted').length
    const {userData} = useContext(AppContext)
    // const { steps } = props
    const steps = userData.role === 'AMBASSADOR' ? [
        { label: "Campaign created", icon: <EditIcon width='20px' height='20px' /> , active: true},
        { label: "Ambassador Assigned", icon: <ProfileIcon width='20px' height='20px' /> , active: !!campaign.invites?.filter(inv => inv.inviteStatus === 'Accepted').length},
        { label: `${submissionsCount}/${videosCount} gigs submitted`, icon: <VideoCameraIcon width='20px' height='20px' /> , active: submissionsCount > 0},
        { label: `${acceptedCount}/${videosCount} gigs accepted`, icon: <VideoCameraIcon width='20px' height='20px' /> , active: acceptedCount > 0},
        { label: "Rating competed", icon: <StarIcon width='20px' height='20px' /> , active: !!campaign.rating},
        { label: "Campaign complete!", icon: <CompletedIcon width='20px' height='20px' /> , active: campaign.campaignStatus === 'Closed'},
    ] : 
    [
        { label: "Campaign created", icon: <EditIcon width='20px' height='20px' /> , active: true},
        { label: "Payment completed", icon: <CreditIcon width='20px' height='20px' /> , active: !!campaign.payment?.length},
        { label: "Ambassadors invited", icon: <PersonIcon width='20px' height='20px' /> , active: !!campaign.invites?.length},
        { label: "Ambassador Assigned", icon: <ProfileIcon width='20px' height='20px' /> , active: !!campaign.invites?.filter(inv => inv.inviteStatus === 'Accepted').length},
        // { label: "Shipment pending", icon: <ShipmentIcon width='20px' height='20px' /> },
        { label: `${submissionsCount}/${videosCount} gigs submitted`, icon: <VideoCameraIcon width='20px' height='20px' /> , active: submissionsCount > 0},
        { label: `${acceptedCount}/${videosCount} gigs accepted`, icon: <VideoCameraIcon width='20px' height='20px' /> , active: acceptedCount > 0},
        { label: "Rating competed", icon: <StarIcon width='20px' height='20px' /> , active: !!campaign.rating},
        { label: "Campaign complete!", icon: <CompletedIcon width='20px' height='20px' /> , active: campaign.campaignStatus === 'Closed'},
    ]
    return <>
        <Flex flexDirection='column' gap='20px' justifyContent='center' >
            {
                steps.map((item, idx) => 
                <Flex key={idx} flexDirection='column' opacity={item.active ? 1 : 0.5}>
                    <Step icon={item.icon} label={item.label} />
                    <VerticalSeparator style={{ marginLeft: 8, marginTop: 12, display: idx === steps.length-1 ? 'none' : 'block'}} />
                </Flex>
                )
            }
        </Flex>
    </>
}

export const Step = (props) => {
    const { label, icon } = props

    return <>
        <Flex alignItems='center' gap='25px'>
            {icon}
            <Text fontSize='17px'>{label}</Text>
        </Flex>
    </>
}

export const VerticalSeparator = (props) => <Box h="24px" w="1px" bg="black" style={props.style} />