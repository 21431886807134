import ContractList from "./components/ContractList";

function Contracts() {
  return <ContractList
    title={"Contracts"}
  />

}

export default Contracts;
