// Chakra imports
import { Button, Text, Avatar, Flex, Tag, TagLabel, useColorModeValue, Modal, Badge, useDisclosure, Box, Spinner, 
  Divider, Skeleton, IconButton, SkeletonCircle, SkeletonText, Spacer, AvatarGroup, useToast, ModalOverlay, ModalBody, ModalContent, ModalHeader, ModalFooter } from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CounterCard from "components/Card/CounterCard.js";
import ShowUserForm from "./ShowUser"
import moment from "moment";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useContext, useCallback, useState } from "react";
import { EditIcon } from "@chakra-ui/icons";
import { AppContext } from "contexts/AppContext";
import UserForm from "./UserForm";
import ReactDataGrid from '@inovua/reactdatagrid-community'
import '@inovua/reactdatagrid-community/index.css'
import { checkboxColumn } from "views/Dashboard/Campaign/CampaignList";
import { getBrandUsers, getSubscribedAvailableUsers } from "RestAPI";
import { VerticalSeparator } from "views/Dashboard/Campaign/CampaignOverview";
import { globalStyles } from "theme/styles";
import EmptyList from "assets/img/empty/issues.png";
import BBButton from "components/Button/Button";
import { FaEye, FaPlus } from "react-icons/fa";
import { DeleteEntityModal } from "layouts/Dashboard";
import { TrashIcon } from "components/Icons/Icons";
import ViewGroup from "views/Dashboard/Groups/components/ViewGroup";
import ViewTeam from "views/Dashboard/Teams/components/ViewTeam";
import "../Users.css"

const UserList = ({ title, viewOnly }) => {
  const navigate = useNavigate()
  const { hasEditPermission, hasCreatePermission, userData } = useContext(AppContext)
  const textColor = useColorModeValue("black3", "white");
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [showUser, setShowUser] = useState(false);
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(true)
  const [overView, setOverview] = useState(null)
  const [deleteModal, setDeleteModal] = useState(null)
  const [entityId, setEntityId] = useState(null)
  const [showGroupDetails, setShowGroupDetails] = useState(false)
  const [groupId, setGroupId] = useState();
  const [showTeamDetails, setShowTeamDetails] = useState(false)
  const [TeamID, setTeamID] = useState();
  const [brandUsers, setBrandUsers] = useState() 
  const [userSubscription, setUserSubscription] = useState({})
  const toast = useToast()
  const [showUserCreditModal, setShowUserCreditModal] = useState(false)

  const deleteItem = (row) => {
    setEntityId(row.id)
    setDeleteModal(true)
  }

  const refreshList = () => {
    setLoading(true)
    getBrandUsers().then(response => {
      if (Array.isArray(response) && response.length) {
        setBrandUsers(response)
        setOverview({
          totalUsers: response.length,
          activeUsers: response.filter(item => !!item.isActive),
          inactiveUsers: response.filter(item => !item.isActive),
        })
      }
      setLoading(false)
    })
    getSubscribedAvailableUsers().then(response =>{
      setUserSubscription(response)
    })
  }

  const addUser = () => {
    // TODO:: uncomment when subscriptions is ready
    // if(userSubscription.userCredits - overView.totalUsers <= 0){
    //   setShowUserCreditModal(true)      
    // }else{
      navigate('/new/user')
    // }
  }

  const onCellClick = useCallback((event, cellProps) => {
    console.log(cellProps)
    const { columnIndex } = cellProps
    if (1 === columnIndex) {
      console.log('columnIndex =', columnIndex)
      setUser(cellProps.data)
      setShowUser(true)
    }
  }, [])

  const columns = [
    { id: 'id', name: 'id', header: <Text color="gray3" textTransform='uppercase'>Id</Text>, defaultVisible: false, type: 'number', defaultWidth: 80, sortable: true },
    {
      id: 'fullname', defaultFlex: 1, defaultWidth: 150, name: 'lastName', header: <Text color="gray3" textTransform='uppercase'>Name</Text>, sortable: true, render: ({ data }) => <Flex cursor="pointer" align="center" py=".8rem" minWidth="100%" flexWrap="nowrap" >
        <Avatar src={data.profileImageUrl} w="50px" borderRadius="50px" me="18px" onClick={() => setShowUser(true)} />
        <Flex direction="column">
          <Text
            fontSize="md"
            color={textColor}
            fontWeight="normal"
            minWidth="100%"
          >
            {data.firstName} {data.lastName}
          </Text>
          <Text fontSize="sm" color={"gray.500"} fontWeight="normal">
            {data.email}
          </Text>
        </Flex>
      </Flex>
    },
    {
      id: 'userName', defaultFlex: 1, defaultWidth: 60, name: 'userName', header: <Text color="gray3" textTransform='uppercase'>User Name</Text>, sortable: true,
      filterEditorProps: {
        style: {
          background: '#fefefe',
        },
        placeholder: 'Search by name...'
      },
      render: ({ data }) => <Text fontSize="md" color={textColor} fontWeight="normal" pb=".5rem">
        {data.userName}
      </Text>
    },
    {
      id: 'role', defaultFlex: 1, maxWidth: 190, name: 'role', header: <Text color="gray3" textTransform='uppercase'>User Type</Text>, sortable: true, render: ({ data }) => <Flex direction="column">
        <Text fontSize="md" color={textColor} fontWeight="normal">
          {data.role.replaceAll('_', ' ')}
        </Text>
        <Text fontSize="sm" color="gray.500" fontWeight="normal">
          {data.userBrandFlag}
        </Text>
      </Flex>
    },
    {
      name: "isActive",
      header: <Text color="gray3" textTransform='uppercase'>STATUS</Text>,
      defaultVisible: true,
      defaultFlex: 1,
      maxWidth: 160,
      sortable: true,
      render: ({ value }) => {
        return (
          < Tag textAlign={'center'} minW={28} backgroundColor={value?'statusGreen':'statusYellow'}><Spacer /><TagLabel textAlign={'center'}>{value?'Active':'Inactive'}</TagLabel><Spacer /></Tag>
        )
      },
    },
    {
      id: 'createdOn', name: 'createdOn', maxWidth: 120, defaultFlex: 1, header: <Text color="gray3" textTransform='uppercase'>Date</Text>, sortable: true, render: ({ data }) => <Text fontSize="md" color={textColor} fontWeight="normal" pb=".5rem">
        {moment(data.createdOn).format('MM-DD-YYYY')}
      </Text>
    }
    ,
    {
      id: 'teams', defaultFlex: 1, header: <Text color="gray3" textTransform='uppercase'>Teams</Text>, defaultWidth: 40, sortable: true, 
      render: ({ data }) => data.teams && !!data.teams.length ? data.teams.map(team => {
        return <AvatarGroup size="md" max={2}>
          <Avatar
          onClick={() => {setTeamID(team.id);setShowTeamDetails(true)}}
            src={team.imageUrl}
            name={team.teamName}
            title={team.teamName}
            key={team.id}
            _hover={{ zIndex: "3", cursor: "pointer" }}
          />
        </AvatarGroup>
      }) : ""
    }
    ,
    {
      id: 'groups', defaultFlex: 1, header: <Text color="gray3" textTransform='uppercase'>Groups</Text>, defaultWidth: 60, sortable: true, 
      render: ({ data }) => 
        <Flex alignItems="center" justifyContent="flex-start">
          {!data || !data.groups || !data.groups.length ? (
            "-"
          ) : (
            <AvatarGroup size="md">
              {data.groups.slice(0,3).map((group) =>
                <Avatar
                  onClick={() => {setGroupId(group.id);setShowGroupDetails(true)}}
                  src={group.imageUrl}
                  name={group.groupName}
                  title={group.groupName}
                  key={group.id}
                  // src={user.profileImageUrl}
                  _hover={{ zIndex: "3", cursor: "pointer" }}
                />
              )}
            </AvatarGroup>
          )}
        </Flex>
    }
    ,
    {
      id: 'action', defaultFlex: 1, header: <Text color="gray3" textTransform='uppercase'>Action</Text>, defaultWidth: 30, sortable: true, render: ({ data }) => viewOnly ? "" : <Flex gap='0.25rem' justifyContent="center" alignItems="center" >
        {
          userData?.role?.includes('ADMIN') && <>
            <IconButton
              onClick={() => navigate(`/edit/user/${data.id}`)}
              variant='outline' size="xs"
              colorScheme='teal' borderRadius={"50%"}
              aria-label='Edit item'
              icon={<EditIcon />
              }
            />
            <IconButton onClick={() => deleteItem(data)}
              variant='outline' size="xs"
              colorScheme='teal' borderRadius={"50%"}
              aria-label='Delete item'
              icon={<TrashIcon />}
            />
          </>
        }

        <IconButton onClick={() => {
          setUser(data)
          setShowUser(true)
        }}
          variant='outline' size="xs"
          colorScheme='teal' borderRadius={"50%"}
          aria-label='Delete item'
          icon={<FaEye />}
        />
      </Flex>
    }
  ];

  columns.forEach(item => item.style = { borderLeftColor: 'white', borderRightColor: 'white', borderTopColor: 'white', borderBottomColor: 'gray.200' })

  useEffect(refreshList, [])

  return <>
    <DeleteEntityModal 
      visible={deleteModal} 
      closeHandler={(refresh) => {
        if(!!refresh) refreshList()
        setDeleteModal(false)
      }} 
      entity='user'
      entityId={entityId}
    />
    <Card id="users-list" mt='5px' p='16px' overflowX={{ sm: "scroll" }} boxShadow='0px 0px 20px 1px rgb(0 0 0 / 10%)' borderRadius='10px'>
      <CardHeader p='12px 0px 28px 0px'>
        <Flex flex={1} align="center" gap='2rem' w="100%" ml={2} mr={2}>
          <Flex direction='column' gap='2rem' w="100%">
            <Flex gap='1rem' flexDirection='column'>
              <Text fontSize='30px' color={textColor} fontWeight='bold' pb='.5rem'>
                {title}
              </Text>
              <Spacer />
              {
                userData.role !== 'BRAND_SUPER_ADMIN' || userData.role === 'CLIENT_SUPER_ADMIN'
                ? null :
                  
                  <BBButton shadow={true} maxW='fit-content' size="sm" leftIcon={<FaPlus />} colorScheme='brandbassBlue' color="white" onClick={addUser}>
                    USER
                  </BBButton>
              }
            </Flex>
            <Flex gap='20px' p='10px' alignItems='center' borderRadius='10px' >
              {
                loading && (
                  <Flex align="center" minWidth="100%" flexWrap="nowrap">
                    <Box padding='6' boxShadow='lg' minWidth="100%">
                      <SkeletonText mt='4' noOfLines={4} spacing='4' />
                    </Box>
                  </Flex>
                )
              }
              {!loading && (<>
                {/* TODO: Uncomment for complete Subscription <CounterCard title={"Total Credits"} width={200} count={userSubscription ? userSubscription.userCredits : 0} />
                <CounterCard title={"Available credits"} width={200} count={userSubscription ? userSubscription.userCredits - overView?.totalUsers || 0 : 0} /> */}
                <CounterCard title={"Total"} width={200} count={overView ? overView.totalUsers : 0} />
                <CounterCard title={"Active"} width={200} count={overView ? overView.activeUsers.length : 0} />
                <CounterCard title={"Inactive"} width={200} count={overView ? overView.inactiveUsers.length : 0} />

              </>)}
            </Flex>
          </Flex>
        </Flex>
      </CardHeader>
      <CardBody >
        {
          loading && (
            <Flex align="center" minWidth="100%" flexWrap="nowrap">
              <Box padding='6' boxShadow='lg' minWidth="100%">
                <SkeletonCircle size='20' />
                <SkeletonText mt='4' noOfLines={4} spacing='4' />
              </Box>
            </Flex>
          )
        }
        {!loading && (
          <ReactDataGrid
            loading={loading}
            className="brandbass-data-grid"
            style={{ minHeight: 860, borderColor: 'white' }}
            idProperty="id"
            columns={columns}
            dataSource={brandUsers || []}
            pagination={true}
            defaultLimit={10}
            defaultSortInfo={{ id: "id", name: 'id', dir: -1, type: 'number' }}
            checkboxColumn={checkboxColumn}
            checkboxOnlyRowSelect={true}
            rowHeight={70}
            headerHeight={60}
            showZebraRows={false}
            defaultLoading={loading}
            enableSelection={true}
            showHoverRows={true}
            showCellBorders="horizontal"
            enableColumnAutosize={true}
            defaultFilterValue={[{ name: 'userName', type: 'string', operator: 'contains', value: '' }]}
            enableColumnFilterContextMenu={false}
            emptyText="No users"
            onCellClick={onCellClick}
          />
        )}
      </CardBody>
      <Modal size={'5xl'} isOpen={showUser} onClose={() => setShowUser(false)}>
        <ShowUserForm onClose={() => setShowUser(false)} UserID={user.id} showControls={true}/>
      </Modal>
      <Modal size={'4xl'} closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
        <UserForm onClose={onClose} refreshList={refreshList} />
      </Modal>
      <Modal size={'4xl'} isOpen={showGroupDetails} onClose={() => setShowGroupDetails(false)}>
        <ViewGroup onClose={() => setShowGroupDetails(false)} GroupID={groupId} />
      </Modal>
      <Modal size={'4xl'} isOpen={showTeamDetails} onClose={() => setShowTeamDetails(false)}>
        <ViewTeam onClose={() => setShowTeamDetails(false)} TeamID={TeamID} />
      </Modal>
      {showUserCreditModal && 
      <Modal isOpen={showUserCreditModal} size="xl" scrollBehavior='outside' closeOnOverlayClick={false} >
        <ModalOverlay />
        <ModalContent>
        <ModalHeader color='brandbassBlue'>User limit reached</ModalHeader>
        <ModalBody pb={6}>
            <Flex p={8} borderColor='grayBlue' borderWidth="1px" borderRadius="lg" alignItems='center' justifyContent='center'>
                <Text fontSize='20px'>
                Please increase user credits in your subscription to add new user
                </Text>
            </Flex>
        </ModalBody>
        <ModalFooter>          
            <Button
            colorScheme="brandbassBlue"
            variant="outline"
            borderRadius="lg" mr={3}
            onClick={() => {setShowUserCreditModal(false); navigate('/edit/subscription')}}
            >
            Modify Subscription
            </Button>

            <Button
            colorScheme="brandbassBlue"

            borderRadius="lg"
            onClick={() => setShowUserCreditModal(false)}
            >
            Cancel
            </Button>
        </ModalFooter>
        </ModalContent>
    </Modal>}
    </Card>
  </>
};



export default UserList;
