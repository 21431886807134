// Chakra imports
import {
  FormControl,
  Checkbox,
  useToast,
  Box,
  Input,
  Image,
  Flex,
  FormLabel,
  Text,
  Modal
} from "@chakra-ui/react";
import BBAutocomplete from "components/Autocomplete/Autocomplete";
import BBInput from "components/Input/Input";
import { FormHeader } from "layouts/FormLayout";
import moment from "moment";
// Custom components
import React, { forwardRef, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getContractUserRecommendation, createContract,getContract, getBrandGroups } from "RestAPI";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import BBButton from "components/Button/Button";
import BBDropdown from "components/Dropdown/Dropdown";
import AmbassadorCard from "components/Card/AmbassadorCard";
import ShowUserForm from "views/Dashboard/Users/components/ShowUser";
import { TrashIcon } from "components/Icons/Icons";
import BBTextarea from "components/Textarea/Textarea";
import FileAttachment from "views/Auth/FileAttachment";
import { AppContext } from "contexts/AppContext";

const ContractForm = ({ onClose, refreshList }) => {
  const {userData} = useContext(AppContext)
  const navigate = useNavigate()
  const {ContractID} = useParams()
  const [groups, setGroups] = useState([])
  const [loading, setLoading] = useState(false)
  const [selectedGroups, setSelectedGroups] = useState([])
  const date = new Date()
  date.setDate(date.getDate() + 2)
  const [ambassador, setAmbassador] = useState(null)
  const [ambassadors, setAmbassadors] = useState(null)
  const [submit, setSubmit] = useState(false)
  const toast = useToast()
  const [contract, setContract] = useState(null)
  const [items, setItems] = useState([])
  const [showAmbassador, setShowAmbassador] = useState(false)
  const [todos, setTodos] = useState([])
  const [notTodos, setNotTodos] = useState([])
  const [attachments, setAttachments] = useState([])
  const [contractAmount, setContractAmount] = useState({ambassadorAmount:0 , brandAmount: 0})
  const expiryDate = new Date()
  const [inviteExpiryDate, setInviteExpiryDate] = useState(new Date(expiryDate.setDate(expiryDate.getDate() + 5)))

  const selectGroup = (values) => {
    let data = groups.filter(group => values.includes(group.groupName));
    setSelectedGroups(data.map(g => { return { id: g.id, value: g.groupName, name: g.groupName } }))
  }

  const saveContract = (isDraft) => {
    setLoading(true)
    let revision = 0
    if(contract){
      revision = contract.revision
    }
    if(!contract.startDate){
      contract.startDate = date
    }
    let duration = moment(contract.endDate).diff(contract.startDate, 'days')
    let durationUnit = 'days'
    let amountAmbassador = items.reduce((sum, item) => sum + item.cost * item.minQuantity, 0)
    let amountBrand = amountAmbassador +  (userData.brand?.brandbassComission /100 * amountAmbassador)
    createContract({...contract, 
      id: parseInt(ContractID), 
      contractStatus : isDraft ? 'Draft' : ContractID && contract.contractStatus !== 'Draft' ? contract.contractStatus : 'Initiated',
      contractType: 'Normal',
      revision,
      duration,
      durationUnit,
      invites: [{user: ambassador, expiryDate: inviteExpiryDate}],
      groups: selectedGroups,
      items,
      todos,
      notTodos,
      attachments,
      amountAmbassador,
      amountBrand
    })
      .then(response => {
        setLoading(false)
        if(response?.id){
          toast({
            title: `Contract "${contract.title}" created successfully`,
            description: "",
            status: 'success',
            position: 'top',
            duration: 3000,
            isClosable: true,
          })
          navigate('/contracts')
        }else{
          toast({
            title: `An error occured when saving the contract.`,
            description: "Please try after sometime or create an issue",
            status: 'error',
            position: 'top',
            duration: 3000,
            isClosable: true,
          })
        }
      }).catch(err => {
        toast({
          title: `An error occured when saving the contract.`,
          description: "Please try after sometime or create an issue",
          status: 'error',
          position: 'top',
          duration: 3000,
          isClosable: true,
        })
        setLoading(false)
      })
  }

  const updateContractItem = (index, fieldName, value)=>{
    setItems(items.map((item,i) => {
      if(i===index){
        item[fieldName] = value
      }
      return item
    }))
  }

  const addNewItem =() =>{
    setItems([...items, {itemType: '', minQuantity: '', maxQuantity: '', cost: ''}])
  }

  const addNewTodo =() =>{
    setTodos([...todos, {name: ''}])
  }

  const addNewNotTodo =() =>{
    setNotTodos([...notTodos, {name: ''}])
  }

  const ContractStartDate = forwardRef(({ value, onClick }, ref) => (
    <BBButton variant={'outline'} mt={4} onClick={onClick} ref={ref}>
      {value ? value : 'Pick date'}
    </BBButton>
  ));

  const fetchContract = async(ContractID) => {
    const contractRes = await getContract(ContractID)
    if(contractRes?.id){
      setContract(contractRes)
      if(contractRes.contractScope === 'Exclusive to group' && contractRes.groups?.length){
        setSelectedGroups(contractRes.groups.map(g => { return { id: g.id, value: g.groupName, name: g.groupName } }))
      }else{
        setSelectedGroups([])
      }
      if(contractRes?.invites?.length){
        setAmbassador(contractRes.invites[0].user)
        setInviteExpiryDate(new Date(contractRes.invites[0].expiryDate))
      }else{
        setAmbassador({})
      }
      if(contractRes.items?.length){
        setItems(contractRes.items)
      }
      if(contractRes.todos?.length){
        setTodos(contractRes.todos)
      }
      if(contractRes.notTodos?.length){
        setNotTodos(contractRes.notTodos)
      }
      if(contractRes.attachments?.length){
        setAttachments(contractRes.attachments  )
      }
    }
  }

  const updateContract = (fieldName, value) => {
    let field = {}
    field[fieldName] = value
    setContract({...contract, ...field})
  }

  useEffect(async() => {
    setLoading(true)
    if(ContractID){
      await fetchContract(ContractID)
    }else{
      const response = await getContractUserRecommendation()    
      if (response && response.data){
        setAmbassadors(response.data)
      }
      let groupsRes = await getBrandGroups()
      if (Array.isArray(groupsRes) && groupsRes.length) {
        setGroups(groupsRes)
      }
    }    
    setLoading(false)
  }, [])

  useEffect(async() => {
    if(contract?.id){
      const response = await getContractUserRecommendation()    
      if (response && response.data){
        setAmbassadors(response.data)
      }
    }
  },[ambassador])

  useEffect(async() => {
    if(contract?.id){
      let groupsRes = await getBrandGroups()
      if (Array.isArray(groupsRes) && groupsRes.length) {
        setGroups(groupsRes)
      }
    }
  },[selectedGroups])

  useEffect(() => {    
    const amountAmbassador = items.reduce((sum, item) => sum + item.cost * item.minQuantity, 0)
    let amountBrand = amountAmbassador +  (userData.brand?.brandbassComission /100 * amountAmbassador)
    if(amountAmbassador > 0){
      setContractAmount({amountAmbassador, amountBrand})
    }
  },[items])

  return (
    <>
      <FormHeader
        caption={!!ContractID ? 'Edit Contract' : 'Create Contract'}
        submitHandler={() => saveContract()}
        draftHandler=  {contract && contract.id && contract.contractStatus !== 'Draft' ? null : () => saveContract(true)}
        closeHandler={() => navigate('/contracts')}
        cancelHandler={() => navigate('/contracts')}
      />
      <Flex flexDirection="column" >
        <Box p={8} borderWidth="0px" borderRadius="lg" w='full' alignSelf='center'>
          <Flex flexDirection="column" gap={10}>
            <Flex flexDirection="column" gap={10} mt={{ base: '65px', md: '5px' }}>
              <Flex justify="space-between" flexDirection={{base:'column',lg: 'row'}} gap={4}>
                <Box w={{ base: '100%', lg: "50%" }} >
                  <FormControl style={{ margin: 16 }} isRequired>
                    <BBInput value={contract?contract.title:''}  required label="Contract name" placeholder='Contract name' onChange={(e) => updateContract('title', e.target.value) } />
                  </FormControl>
                </Box>
                <Box w={{ base: '100%', lg: "50%" }}>
                  <FormControl style={{ margin: 16 }}>
                    <BBInput value={contract?contract.description:''} isInvalid={submit && !description} required label="Description" placeholder='Description' onChange={(e) =>  updateContract('description', e.target.value) } />
                  </FormControl>
                </Box>                
              </Flex>
            </Flex>
          </Flex>
          {ambassadors?.length ? <Flex justify="space-between" flexDirection={{base:'column',lg: 'row'}} gap={4} ml={4}>
             <Box w={{ base: '100%', lg: "50%" }} >
              <BBAutocomplete
                defaultValues={ambassador ? [ambassador.userName] : []}
                // style={{ margin: 16 }}
                options={ambassadors && !!ambassadors.length && ambassadors.map(u => { return { id: u.id, value: u.userName, name: u.userName, imageUrl: u.profileImageUrl } })}
                isMultiple={false}
                label='Invite Ambassador'
                onChange={(values, items) => setAmbassador(items.originalValue)}
              />
            </Box> 
            <Box w={{ base: '100%', lg: "10%" }} mt={8} >
              <BBButton disabled={!ambassador} variant='outline' onClick={() => setShowAmbassador(true)} h={'50px'}  ml={2}> View details</BBButton>
              {showAmbassador && 
              <Modal size={'4xl'} isOpen={showAmbassador} onClose={() => setShowAmbassador(false)}>
                <ShowUserForm onClose={() => setShowAmbassador(false)} UserID={ambassador.id} />
              </Modal>}
            </Box>
            <Box w={{ base: '100%', lg: "40%" }} >
              <FormControl display='flex' flexDirection="column" style={{ marginLeft: 48, marginTop: 0 }}>
                <FormLabel fontWeight='bold' fontSize='16px'  htmlFor='startdate' mb='0'>
                  Invite expiry date
                </FormLabel>
                <DatePicker
                  placeholder='Select date'
                  selected={new Date(inviteExpiryDate)}
                  dateFormat="MMMM d, yyyy"
                  minDate={date}
                  customInput={<ContractStartDate />}
                  onChange={(date) => setInviteExpiryDate(date) } />
              </FormControl>
            </Box>
          </Flex>: null}
          <Flex justify="space-between" flexDirection={{base:'column',lg: 'row'}} gap={4} margin={4}>
            <Box w={{ base: '100%', lg: "50%" }} >
              <FormControl isRequired>
                <BBDropdown
                  width={'100%'}
                  height="50px"
                  label="Contract scope"
                  placeholder="Select ambassador scope"
                  value={contract?.contractScope || ''}
                  onClick={(e) => updateContract('contractScope', e.target.value)}
                  options={[{id:1, name: 'Exclusive', value: 'Exclusive', label: 'Exclusive'},{id:2, name: 'Exclusive to group', value: 'Exclusive to group',  label: 'Exclusive to group'}]} />
              </FormControl>
            </Box>
            {groups.length ? <Box w={{ base: '100%', lg: "50%" }} >
              <FormControl>
                <FormLabel fontWeight='bold' fontSize='16px' htmlFor='group' style={{marginLeft: 12}}>
                  Groups
                </FormLabel>
                <BBAutocomplete
                  width={'100%'}
                  disabled={contract?.contractScope === 'Exclusive'}
                  style={{marginTop: 16, marginLeft: 8}}
                  defaultValues={selectedGroups?.map(u => u.groupName)}                  
                  options={groups && !!groups.length && groups.map(u => { return { id: u.id, value: u.groupName, name: u.groupName, imageUrl: u.imageUrl } })}
                  isMultiple={true}
                  // label='Groups'
                  onChange={(values, items) => selectGroup(values)}
                />
              </FormControl>
            </Box> : null}
          </Flex>          
          <Flex justify="space-between" flexDirection={{base:'column',lg: 'row'}} gap={4}>
            <Box w={{ base: '100%', lg: "50%" }} >
              <FormControl display='flex' flexDirection="column" style={{ margin: 16 }}>
                <FormLabel fontWeight='bold' fontSize='16px' padding='5px 2px' htmlFor='startdate' mb='0'>
                  Contract start date
                </FormLabel>
                <DatePicker
                  placeholder='Select date'
                  selected={contract && contract.startDate ? new Date(contract.startDate) : date}
                  dateFormat="MMMM d, yyyy"
                  minDate={date}
                  customInput={<ContractStartDate />}
                  onChange={(date) => updateContract('startDate', date) } />
              </FormControl>
            </Box>
            <Box w={{ base: '100%', lg: "50%" }} >
              <FormControl display='flex' flexDirection="column" style={{ margin: 16 }}>
                <FormLabel fontWeight='bold' fontSize='16px' padding='5px 2px' htmlFor='startdate' mb='0'>
                  Contract end date
                </FormLabel>
                <DatePicker
                  placeholder='Select date'
                  selected={contract && contract.endDate ? new Date(contract.endDate) : null}
                  minDate={contract && contract.startDate ?contract.startDate : date}
                  dateFormat="MMMM d, yyyy"
                  customInput={<ContractStartDate />}
                  onChange={(date) => updateContract('endDate',date)} />
              </FormControl>
            </Box>            
          </Flex>
          <Flex justifyContent={'space-between'}>
            <FormLabel fontWeight='bold' fontSize='20px' htmlFor='group' style={{marginLeft: 12, marginTop: 16}}>
              MILESTONES
            </FormLabel>
            <BBButton size={'sm'} mt={4} variant='outline' onClick={addNewItem}>+ Add</BBButton>
          </Flex>
          {
            !items?.length ? 
            <Flex margin={8} padding={4} border={'1px solid'} borderColor={"brandbassBlue"} borderRadius='md' height={'60px'} justifyContent='center' cursor={'pointer'} 
              onClick={addNewItem}>
              <Text color={'brandbassBlue'}>Click to add an item</Text>
            </Flex> : <>
              {items.map((item, index) => {
                return (
                <Flex key={index} justify="space-between" flexDirection={{base:'column',lg: 'row'}} gap={4}>
                  <Box w={{ base: '50%', lg: "25%" }} >
                    <FormControl  style={{ margin: 16 }} isRequired>
                      <BBDropdown
                        width={'100%'}
                        height="50px"
                        label="Type"
                        placeholder="Select one"
                        value={item.itemType}
                        onClick={(e) => {
                          updateContractItem(index,'itemType', e.target.value)
                        }}
                        options={[{id:1, name: 'Content', value: 'Content', label: 'Content'},{id:2, name: 'Publish', value: 'Publish',  label: 'Publish'},
                        {id:3, name: 'Usage', value: 'Usage',  label: 'Usage'}, {id:4, name: 'Live session', value: 'Live session',  label: 'Live session'}]} />
                    </FormControl>
                  </Box>
                  <Box w={{ base: '50%', lg: "25%" }} >
                    <FormControl style={{ margin: 16 }} isRequired>
                      <BBInput value={item.minQuantity}  required label="Min Quantity" placeholder='Min Quantity' onChange={(e) => { updateContractItem(index, 'minQuantity', e.target.value) }} />
                    </FormControl>
                  </Box>
                  <Box w={{ base: '50%', lg: "25%" }} >
                    <FormControl style={{ margin: 16 }} isRequired>
                      <BBInput value={item.maxQuantity}  required label="Max Quantity" placeholder='Max Quantity' onChange={(e) => { updateContractItem(index, 'maxQuantity', e.target.value) }} />
                    </FormControl>
                  </Box>
                  <Box w={{ base: '50%', lg: "20%" }} >
                    <FormControl style={{ margin: 16 }} isRequired>
                      <BBInput value={item.cost}  required label="Cost" placeholder='Cost per unit' onChange={(e) => { updateContractItem(index, 'cost', e.target.value) }} />
                    </FormControl>
                  </Box>
                  <Box w={ "5%" } >
                    <TrashIcon cursor="pointer" mt={16} ml={4} onClick={(e) => {
                      setItems(items.filter((item,i) => i !== index))
                    }} />
                  </Box>
                </Flex>)
              })}
              <Flex gap={4} style={{marginLeft: 12, marginTop: 16}} justifyContent={'center'}>
                <FormLabel fontWeight='bold' fontSize='16' htmlFor='group' >
                Contract Amount 
                </FormLabel>
                <Text>Ambassador Contract: ${contractAmount.amountAmbassador ? contractAmount.amountAmbassador : 0}</Text>
                <Text>Brandbass Fee: ${contractAmount.amountAmbassador ? (contractAmount.amountAmbassador*userData.brand.brandbassComission)/100 : 0}</Text>
                <Text>Total: ${contractAmount.amountAmbassador ? contractAmount.amountBrand : 0}</Text>
              </Flex>
            </>
          }
          <Flex justifyContent={'space-between'}>
            <FormLabel fontWeight='bold' fontSize='20px' htmlFor='group' style={{marginLeft: 12, marginTop: 16}}>
              TODOs
            </FormLabel>
            <BBButton size={'sm'} mt={4} variant='outline' onClick={addNewTodo}>+ Add</BBButton>
          </Flex>
          {
            !todos?.length ? 
            <Flex margin={8} padding={4} border={'1px solid'} borderColor={"brandbassBlue"} borderRadius='md' height={'60px'} justifyContent='center' cursor={'pointer'} 
              onClick={addNewTodo}>
              <Text color={'brandbassBlue'}>Click to add a Todo</Text>
            </Flex> : <>
              {todos.map((todo, index) => {
                return (
                <Flex key={index} justify="space-between" flexDirection={{base:'column',lg: 'row'}} gap={4}>
                  <Box w={'95%'} >
                    <FormControl  style={{ margin: 16 }} isRequired>
                      <BBTextarea
                      placeholder="Example: Immediately respond to the messages"
                      onChange={(e) =>
                        setTodos(todos.map((todo, i) => {
                          if(i === index){
                            todo.name = e.target.value
                          }
                          return todo
                        }))
                      }
                      value={todo.name || ''}></BBTextarea>
                    </FormControl>
                  </Box>
                  <Box w={ "5%" } >
                    <TrashIcon cursor="pointer" mt={16} ml={4} onClick={(e) => {
                      setTodos(todos.filter((todo,i) => i !== index))
                    }} />
                  </Box>
                </Flex>)
              })}
            </>
          }
          <Flex justifyContent={'space-between'}>
            <FormLabel fontWeight='bold' fontSize='20px' htmlFor='group' style={{marginLeft: 12, marginTop: 16}}>
              NOT TODOs
            </FormLabel>
            <BBButton size={'sm'} mt={4} variant='outline' onClick={addNewNotTodo}>+ Add</BBButton>
          </Flex>
          {
            !notTodos?.length ? 
            <Flex margin={8} padding={4} border={'1px solid'} borderColor={"brandbassBlue"} borderRadius='md' height={'60px'} justifyContent='center' cursor={'pointer'} 
              onClick={addNewNotTodo}>
              <Text color={'brandbassBlue'}>Click to add a Not Todo</Text>
            </Flex> : <>
              {notTodos.map((notTodo, index) => {
                return (
                <Flex key={index} justify="space-between" flexDirection={{base:'column',lg: 'row'}} gap={4}>
                  <Box w={'95%'} >
                    <FormControl  style={{ margin: 16 }} isRequired>
                      <BBTextarea
                      placeholder="Example: Immediately respond to the messages"
                      onChange={(e) =>
                        setNotTodos(notTodos.map((notTodo, i) => {
                          if(i === index){
                            notTodo.name = e.target.value
                          }
                          return notTodo
                        }))
                      }
                      value={notTodo.name || ''}></BBTextarea>
                    </FormControl>
                  </Box>
                  <Box w={ "5%" } >
                    <TrashIcon cursor="pointer" mt={16} ml={4} onClick={(e) => {
                      setNotTodos(notTodo.filter((notTodo,i) => i !== index))
                    }} />
                  </Box>
                </Flex>)
              })}
            </>
          }
          <FileAttachment style={{margin: 16}} attachments={attachments} setAttachments={setAttachments} />
        </Box>
      </Flex>
    </>
  );
};

export default ContractForm;

