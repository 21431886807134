
import { lifestyleOptions, genderOptions, ageOptions } from "variables/brandBassVariables";
import { Select as CRSelect } from "chakra-react-select";
import { bodyareaOptions } from "variables/brandBassVariables";
import { productCategoryOptions } from "variables/brandBassVariables";
import { productUsageOptions } from "variables/brandBassVariables";
import {
    Box, Button, Flex, FormControl, FormLabel, 
    Modal, ModalBody, ModalCloseButton, ModalContent,
    ModalFooter, ModalHeader, ModalOverlay, Slider, SliderFilledTrack,
    SliderThumb, SliderTrack, Text, Tooltip,
} from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";

import BBDropdown from "components/Dropdown/Dropdown";
import BBButton from "components/Button/Button";
import { getBrandInterest, getAmbassadorInterest } from "RestAPI";
import { AppContext } from "contexts/AppContext";

const MatchProfileForm = (props) => {
    const { visible, closeHandler, dataHandler, interest } = props
    const {userData} = useContext(AppContext)
    const blankInterestObj = {
      age: 16,
      affordability: 5,
      luxury: 5,
      safety: 5,
      quality: 5,
      lifestyleAllNatural: false,
      lifestyleArtisan: false,
      lifestyleBohemian: false,
      lifestyleFashionBeauty: false,
      lifestyleManWithMission: false,
      lifestyleMom: false,
      lifestyleSports: false,
      lifestyleWomenPower: false,
      lifestyleEcoFriendly: false,
      genderMen: false,
      genderUnisex: true,
      genderWomen: false,
      bodyAreaFace: false,
      bodyAreaFullBody: false,
      bodyAreaHair: false,
      bodyAreaLowerBody: false,
      bodyAreaUpperBody: false,
      typeProductsBeautyMakeup: false,
      typeProductsClothes: false,
      typeProductsFashionAccessories: false,
      typeProductsFragrance: false,
      typeProductsHairCare: false,
      typeProductsSkinCare: false,
      locationEverydayUseAllDay: false,
      locationFormalMeetings: false,
      locationFormalParty: false,
      locationGymSports: false,
      locationHome: false,
      locationOutdoor: false,
      locationParty: false,
      locationVacation: false,
    };
    const [showModal, setShowModal] = useState(false)
    const [interestDetails, setInterestDetails] = useState(interest || blankInterestObj)
    
    const getBrandMatchProfile = async() => {
      const matchProfile =  userData.role === 'AMBASSADOR' ? await getAmbassadorInterest() : await getBrandInterest()
      console.log('MatchProfiel',matchProfile.data)
      if(matchProfile.data && !matchProfile.data.error){
        setInterestDetails(matchProfile.data)
      }
    }

    const submit = () => {
      dataHandler(interestDetails)
      closeHandler()
    }
  
    const updateInterest = (obj) => setInterestDetails({ ...interestDetails, ...obj })
  
    const updateAgeInterest = (val) => {
      ageOptions.map(item => {
        if (val == item.value) {
          updateInterest({ age: item.value })
        }
      })
    }
  
    const updateGenderInterest = (val) => {
      if (!val) return
      if (val.toLowerCase() === 'men') return updateInterest({ genderMen: true, genderWomen: false, genderUnisex: false })
      if (val.toLowerCase() === 'women') return updateInterest({ genderMen: false, genderWomen: true, genderUnisex: false })
      if (val.toLowerCase() === 'any') return updateInterest({ genderMen: false, genderWomen: false, genderUnisex: true })
      return updateInterest({ genderMen: false, genderWomen: false, genderUnisex: false })
    }
  
    const updateLifestyleInterest = (val) => {
      const blankLifestyleInterest = {
        lifestyleAllNatural: false,
        lifestyleArtisan: false,
        lifestyleBohemian: false,
        lifestyleFashionBeauty: false,
        lifestyleManWithMission: false,
        lifestyleMom: false,
        lifestyleSports: false,
        lifestyleWomenPower: false,
        lifestyleEcoFriendly: false,
      }
  
      if (!val) return
      if (val === 'Bohemian') return updateInterest({ ...blankLifestyleInterest, lifestyleBohemian: true })
      if (val === 'All natural') return updateInterest({ ...blankLifestyleInterest, lifestyleAllNatural: true })
      if (val === 'Sustainable, eco-friendly') return updateInterest({ ...blankLifestyleInterest, lifestyleEcoFriendly: true })
      if (val === 'Artisan-crafted, handmade') return updateInterest({ ...blankLifestyleInterest, lifestyleArtisan: true })
      if (val === 'Health and Fitness') return updateInterest({ ...blankLifestyleInterest, lifestyleSports: true })
      if (val === 'Fashion and Beauty') return updateInterest({ ...blankLifestyleInterest, lifestyleFashionBeauty: true })
      if (val === 'Mommy and Baby') return updateInterest({ ...blankLifestyleInterest, lifestyleMom: true })
      if (val === 'Women empowerment') return updateInterest({ ...blankLifestyleInterest, lifestyleWomenPower: true })
      if (val === 'Men on a mission') return updateInterest({ ...blankLifestyleInterest, lifestyleManWithMission: true })
  
      return updateInterest({ ...blankLifestyleInterest })
    }
  
    const updateBodyareaInterest = (selections) => {
      const bodyareaInterestObj = {
        bodyAreaFace: false,
        bodyAreaFullBody: false,
        bodyAreaHair: false,
        bodyAreaLowerBody: false,
        bodyAreaUpperBody: false,
      }
  
      for (const item of selections) {
        console.log(item)
        if (item.value === 'Hair') bodyareaInterestObj.bodyAreaHair = true
        if (item.value === 'Face') bodyareaInterestObj.bodyAreaFace = true
        if (item.value === 'Full body') bodyareaInterestObj.bodyAreaFullBody = true
        if (item.value === 'Lower body') bodyareaInterestObj.bodyAreaLowerBody = true
        if (item.value === 'Upper Body') bodyareaInterestObj.bodyAreaUpperBody = true
      }
  
      return updateInterest({ ...bodyareaInterestObj })
    }
  
    const updateProductUsageInterest = (selections) => {
      const productUsageInterestObj = {
        locationEverydayUseAllDay: false,
        locationFormalMeetings: false,
        locationFormalParty: false,
        locationGymSports: false,
        locationHome: false,
        locationOutdoor: false,
        locationParty: false,
        locationVacation: false,
      }
  
      for (const item of selections) {
        if (item.value === 'Outdoor') productUsageInterestObj.locationOutdoor = true
        if (item.value === 'Everyday use/ All day') productUsageInterestObj.locationEverydayUseAllDay = true
        if (item.value === 'Home') productUsageInterestObj.locationHome = true
        if (item.value === 'Gym/Sports') productUsageInterestObj.locationGymSports = true
        if (item.value === 'Party') productUsageInterestObj.locationParty = true
        if (item.value === 'Formal meetings') productUsageInterestObj.locationFormalMeetings = true
        if (item.value === 'Formal party') productUsageInterestObj.locationFormalParty = true
        if (item.value === 'Relaxed settings as in vacation') productUsageInterestObj.locationVacation = true
      }
  
      return updateInterest({ ...productUsageInterestObj })
    }
  
    const updateProductCategoryInterest = (selections) => {
      const productCategoryInterestObj = {
        typeProductsBeautyMakeup: false,
        typeProductsClothes: false,
        typeProductsFashionAccessories: false,
        typeProductsFragrance: false,
        typeProductsHairCare: false,
        typeProductsSkinCare: false,
      }
  
      for (const item of selections) {
        if (item.value === 'Skin care') productCategoryInterestObj.typeProductsSkinCare = true
        if (item.value === 'Beauty and Makeup') productCategoryInterestObj.typeProductsBeautyMakeup = true
        if (item.value === 'Clothes') productCategoryInterestObj.typeProductsClothes = true
        if (item.value === 'Hair care') productCategoryInterestObj.typeProductsHairCare = true
        if (item.value === 'Fashion Accessories') productCategoryInterestObj.typeProductsFashionAccessories = true
        if (item.value === 'Fragrance') productCategoryInterestObj.typeProductsFragrance = true
      }
  
      return updateInterest({ ...productCategoryInterestObj })
    }
  
    useEffect(() => setShowModal(!!visible), [visible])

    useEffect(() => {
      getBrandMatchProfile()
    },[])
  
    return <>
      <Modal isOpen={showModal} onClose={closeHandler} size="4xl" scrollBehavior='outside' closeOnOverlayClick={false}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader color='brandbassBlue' >Match Profile</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Box
              p={8}
              pb='150px'
              borderWidth="1.5px"
              borderRadius="lg"
            >
              <Flex flexDirection='column' gap={10}>
                <SliderThumbWithTooltip
                  min={1} max={10} step={1} defaultValue={interestDetails?.affordability || 5}
                  setValue={(val) => updateInterest({ affordability: val })}
                  text={<Text padding={"0px 1px 10px 1px"} fontSize={'16px'} fontWeight={'bold'}>How affordable is the brand in its particular segment?</Text>}
                  markerLeast='Affordable'
                  markerMost='Expensive'
                />
  
                <SliderThumbWithTooltip
                  min={1} max={10} step={1} defaultValue={interestDetails?.luxury || 5}
                  setValue={(val) => updateInterest({ luxury: val })}
                  text={<Text padding={"0px 1px 10px 1px"} fontSize={'16px'} fontWeight={'bold'}>How much of a statement do the products make?</Text>}
                  markerLeast='Manufactured'
                  markerMost='Handcrafted'
                />
  
                <SliderThumbWithTooltip
                  min={1} max={10} step={1} defaultValue={interestDetails?.safety || 5}
                  setValue={(val) => updateInterest({ safety: val, quality: val })}
                  text={<Text padding={"0px 1px 10px 1px"} fontSize={'16px'} fontWeight={'bold'}>How concerned is the brand about safety and natural ingredients?</Text>}
                  markerLeast='Not concerned'
                  markerMost='Very concerned'
                />
                <Box>
                  <FormControl>
                    <BBDropdown label='Which age group does the brand identify with?' 
                      variant='flushed' id="age" placeholder="Select age group" width='100%'
                      onChange={(e) => updateAgeInterest(e.target.value)} options={ageOptions} 
                      value={ageOptions.find(item => {
                        if(interestDetails?.age == item.value){
                          return item
                        }
                      })?.value}/>                  
                  </FormControl>
                </Box>
                <Box>
                  <FormControl>
                    <BBDropdown  width='100%' label="What gender are the brand's products for?" variant='flushed' id="gender" placeholder="Select gender" onChange={(e) => updateGenderInterest(e.target.value)} options={genderOptions} 
                    value={genderOptions.find(item => interestDetails[item.key])?.label || ''}
                    />
                  </FormControl>
                </Box>
                <Box>
                  <FormControl>
                    <BBDropdown width='100%' label="Which lifestyle does the brand identify with?" variant='flushed' id="lifestyle" placeholder="Select lifestyle"
                     onChange={(e) => updateLifestyleInterest(e.target.value)} options={lifestyleOptions}
                     value={lifestyleOptions.find(item => interestDetails[item.key])?.label || ''}/>
                  </FormControl>
                </Box>
                <Box>
                  <FormControl>
                    <FormLabel padding={"0px 1px 10px 1px"} fontSize={'16px'} fontWeight={'bold'}>What area of the body are your products targeting?</FormLabel>
                    <CRSelect
                      isMulti
                      name="bodyarea"
                      options={bodyareaOptions}
                      closeMenuOnSelect={false}
                      placeholder="Select one or more options"
                      onChange={(selections) => updateBodyareaInterest(selections)}
                      value={bodyareaOptions.filter(i => !!interestDetails[i.key])}
                    />
                  </FormControl>
                </Box>
                <Box>
                  <FormControl>
                    <FormLabel padding={"0px 1px 10px 1px"} fontSize={'16px'} fontWeight={'bold'}>What categories do your products fall in?</FormLabel>
                    <CRSelect
                      isMulti
                      name="productcategory"
                      options={productCategoryOptions}
                      placeholder="Select one or more options"
                      closeMenuOnSelect={false}
                      onChange={(selections) => updateProductCategoryInterest(selections)}
                      value={productCategoryOptions.filter(i => !!interestDetails[i.key])}
                    />
                  </FormControl>
                </Box>
                <Box>
                  <FormControl>
                    <FormLabel padding={"0px 1px 10px 1px"} fontSize={'16px'} fontWeight={'bold'}>What settings are your products used?</FormLabel>
                    <CRSelect
                      isMulti
                      name="settings"
                      options={productUsageOptions}
                      placeholder="Select one or more options"
                      closeMenuOnSelect={false}
                      onChange={(selections) => updateProductUsageInterest(selections)}
                      value={productUsageOptions.filter(i => !!interestDetails[i.key])}
                    />
                  </FormControl>
                </Box>
              </Flex>
            </Box>
          </ModalBody>
          <ModalFooter>
          <BBButton
              mr={3}
              variant="outline"
              onClick={closeHandler}
            >
              Cancel
            </BBButton>
            <BBButton
              onClick={submit}
            >
              Submit
            </BBButton>
            
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  }
  
  export const SliderThumbWithTooltip = (props) => {
    const { text, markerLeast, markerMost, min, max, step, setValue, defaultValue } = props
    const [sliderValue, setSliderValue] = useState(defaultValue || 5)
    const [showTooltip, setShowTooltip] = useState(false)
    useEffect(() => !!sliderValue && setValue(sliderValue), [sliderValue])
    return <Box>
      {text}
      <Slider
        id='slider'
        defaultValue={defaultValue || 5}
        min={min}
        max={max}
        step={step}
        colorScheme='brandbassBlue'
        onChangeEnd={(v) => setSliderValue(v)}
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
        gap='1rem'
      >
        <SliderTrack>
          <SliderFilledTrack />
        </SliderTrack>
        <Tooltip
          hasArrow
          bg='brandbassBlue.500'
          color='white'
          placement='top'
          isOpen={showTooltip}
          label={`${sliderValue}`}
        >
        <SliderThumb bgColor={'brandbassBlue.500'}/>
        </Tooltip>
        <Flex justify='space-between' w='full' mt='2rem'>
          <Text fontSize="xs">{markerLeast}</Text>
          <Text fontSize="xs">{markerMost}</Text>
        </Flex>
      </Slider>
    </Box>
  }
  
export default MatchProfileForm;
