import {
    Box, Button, Checkbox, Flex, Modal, ModalBody, ModalCloseButton, ModalContent,
    ModalHeader, ModalOverlay, Spacer, Spinner, Text, Avatar, Image, Input
} from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import {
    getExclusiveAmbassadors
} from "RestAPI";
import { useToast } from '@chakra-ui/react'
import { ViewAmbassadorProfile } from "../Users/components/ShowUser";
import { globalStyles } from "theme/styles";
import SpinnerGif from "assets/img/SpinnerGif.gif"
import { BBSecondaryButton } from "components/Button/Button";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import "./Campaign.css"
import { AppContext } from "contexts/AppContext";

export const ExclusiveAmbassadors = (props) => {
    const {userData} = useContext(AppContext)
    const { visible, closeHandler } = props
    const [showModal, setShowModal] = useState(false)
    const [loading, setLoading] = useState(false)
    const [searchParams] = useSearchParams();
    const [searchString, setSearchString] = useState('')
    const [originalAmbassadors, setOriginalAmbassadors] = useState([])
    const [ambassadors, setAmbassadors] = useState([])
    const toast = useToast();
    const [selectedUser, setSelectedUser] = useState(null)
    const navigate = useNavigate()

    const submit = async () => {
        let selectedAmbassadors = ambassadors.filter(a => !!a.selected)
        if (selectedAmbassadors.length > 1) {
            toast({
                title: 'Choose 1 ambassador',
                description: "Please choose only one ambassador.",
                status: 'error',
                position: 'top',
                duration: 3000,
                isClosable: true,
            })
            return
        }
        if (selectedAmbassadors.length) {
            closeHandler({}, selectedAmbassadors[0])
        }
    }

    const getRecommendations = async () => {
        if(loading) return
        setLoading(true)
        let response = await getExclusiveAmbassadors()
        setLoading(false)
        if (response && response.users) {
            const suitableAmbassadors = response.users.map(a => {
                a.selected = false
                return a
            })
            setOriginalAmbassadors(suitableAmbassadors)
            setAmbassadors(suitableAmbassadors)
            setSelectedUser(suitableAmbassadors[0])
        }
    }


    const updateAmbassadors = (val, type) => {
        let amb = ambassadors.filter(a => !!a.selected)
        if (amb.length >= 1 && !!type) {
            toast({
                title: 'Choose 1 ambassadors',
                description: "Please choose only one ambassador.",
                status: 'error',
                position: 'top',
                duration: 3000,
                isClosable: true,
            })
            return
        }
        setAmbassadors(ambassadors.map(amb => {
            if (amb.id === val.id) {
                amb.selected = type
            }
            return amb
        }))
    }

    const searchAmbassadors = () => {
        if(searchString?.length){
            let searchResults = []
            ambassadors.forEach(user => {
                if(user.firstName.indexOf(searchString) > -1){
                    searchResults.push(user)
                }else if(user.lastName.indexOf(searchString) > -1){
                    searchResults.push(user)
                }else if(user.userName.indexOf(searchString) > -1){
                    searchResults.push(user)
                }else if(user.email.indexOf(searchString) > -1){
                    searchResults.push(user)
                }
            })
            setAmbassadors(searchResults)
            setSelectedUser(searchResults[0])
        }else{
            setAmbassadors(ambassadors)
            setSelectedUser(ambassadors[0])
        }
    }

    const clearSearch = () => {
        setSearchString('');
        setAmbassadors(originalAmbassadors);
        setSelectedUser(originalAmbassadors[0])
    }

    useEffect(() => {
        if(!visible) return setShowModal(!!visible)
        getRecommendations()
        setShowModal(!!visible)
    }, [visible])

    return <>
        <Modal isOpen={showModal} onClose={closeHandler} size="6xl">
            <ModalOverlay />
            <ModalContent>
                <ModalHeader color='brandbassBlue'></ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={6} flexDirection='row'>
                    {loading ? 
                    <Flex align='center' flexDirection='column' gap='1rem' pb='2rem'>
                        <Text fontStyle='italic' fontWeight='600' fontSize='20px'>Hang tight for a couple of seconds!</Text>
                        <Text fontStyle='italic' fontWeight='600' fontSize='20px'>Working on your ambassador results, all you have to do is select one ambassador.</Text>
                        <Spinner thickness='8px' speed='0.85s' emptyColor='gray.200' color='brandbassBlue' w='8rem' h='8rem' mt='3rem'/>
                        {/* <Image src={SpinnerGif} w='8rem' h='8rem' /> */}
                        </Flex> 
                        : !ambassadors.length ?
                            <Flex flexDirection='column' align='center' gap='1rem' p={4}>
                            <Text textAlign='center' p={2} fontStyle='italic' fontWeight='600' fontSize='20px'>
                             
                                Hang tight! Having technical difficulty finding ambassadors. Please try later.</Text> 
                            <Flex align='center' gap='2rem' >
                                <BBSecondaryButton onClick={closeHandler}>I'll wait</BBSecondaryButton>
                                {/* <BBSecondaryButton onClick={()=> navigate(`/edit/campaign/${campaign.id}`)} >Edit campaign</BBSecondaryButton> */}
                            </Flex>
                            </Flex>
                            :
                            <Flex  id="recommendations" flexDirection='column' gap={2}>
                            
                            <Flex gap={8}>
                                <Flex gap={16} ml={4} alignItems='center'>
                                    <Text>{ambassadors?.length ? ambassadors.filter(a => !!a.selected).length : 0}/1 Selected</Text>
                                    <Button
                                        colorScheme="brandbassBlue"
                                        ml={6}
                                        borderRadius="lg"
                                        onClick={submit}
                                    >
                                        Select & Continue
                                    </Button>
                                </Flex>
                                {userData.role === 'SUPER_ADMIN' ?
                                <Flex ml={16}>
                                    <Input value={searchString} onChange={(e) => setSearchString(e.target.value)} type={'text'} placeholder={'Search by name'}/>
                                    <Button
                                        colorScheme="brandbassBlue"
                                        ml={6}
                                        borderRadius="lg"
                                        onClick={searchAmbassadors}
                                    >
                                        Search
                                    </Button>
                                    <Button
                                        variant={'outline'}
                                        colorScheme="brandbassBlue"
                                        ml={6}
                                        borderRadius="lg"
                                        onClick={clearSearch}
                                    >
                                        Clear
                                    </Button>
                                </Flex> : null}
                            </Flex>
                            <Flex>
                                <Box
                                    p={4}
                                    borderRadius="lg"
                                    overflow="scroll"
                                    width="40%"
                                >
                                    <Flex flexDirection='column' gap={2}>
                                        {
                                                ambassadors.map((user, idx) => {
                                                    return (
                                                
                                                    <Flex  flexDirection={'column'} key={idx}  onClick={() => setSelectedUser(user)}
                                                        backgroundColor={selectedUser?.id === user.id ? globalStyles.colors.brandbassBlue2[200] : 'white'}
                                                        borderColor={selectedUser?.id === user.id ? 'white' : globalStyles.colors.primary}
                                                        borderWidth={selectedUser?.id === user.id ? '0px' : '0.5px'}
                                                        p={2} borderRadius='md'>
                                                        <Flex justifyContent="space-between" cursor='pointer'>
                                                            <Flex alignItems="center">
                                                                <Box
                                                                    me="12px"
                                                                    borderRadius="50%"
                                                                    border="1px solid"
                                                                    borderColor='white'
                                                                    display="flex"
                                                                    alignItems="center"
                                                                    justifyContent="center"
                                                                    w="50px"
                                                                    h="50px"
                                                                >
                                                                    <Avatar src={user.profileImageUrl} w='48px' h='48px' borderRadius="50%" />
                                                                </Box>
                                                                <Flex direction="column">
                                                                    <Text
                                                                        fontSize={{ sm: "md", md: "lg", lg: "md" }}
                                                                        // color={textColor}
                                                                        fontWeight="bold"
                                                                    >
                                                                        {user.firstName}
                                                                    </Text>
                                                                    <Text
                                                                        fontSize={{ sm: "xs", md: "sm", lg: "xs" }}
                                                                        color="gray.400"
                                                                        fontWeight="semibold"
                                                                    >
                                                                        {user.inviteStatus ? user.inviteStatus : user.tagLine}
                                                                    </Text>
                                                                </Flex>
                                                                <Spacer />                                                                
                                                            </Flex>

                                                            <Box
                                                                alignSelf='center'
                                                                ml={2}
                                                            >
                                                                <Checkbox float='right' spacing='6rem' onChange={e => { e.stopPropagation(); updateAmbassadors(user, e.target.checked) }}></Checkbox>
                                                            </Box>
                                                        </Flex>
                                                        <Flex ml={16}>
                                                        {user.payrates?.length ? 
                                                            <Box gap={2}>
                                                                {user.payrates.map(payrate => {
                                                                return (
                                                                    <Text key={payrate.id} fontWeight='400' fontSize='12px' lineHeight='20px' color='brandbassGray.500' textAlign='justify'>
                                                                        ${payrate.payRate} for {payrate.gigVariation} {payrate.gigVariationType} {payrate.gigChannel} -  {user.credits?.filter(c => c.gigVariation === payrate.gigVariation && c.gigVariationType === payrate.gigVariationType).reduce((sum,a) => sum+a.numCredits, 0)} credits left
                                                                    </Text>)
                                                                })}
                                                            </Box> : 
                                                            <Text fontWeight='400' fontSize='14px' lineHeight='24px' color='brandbassGray.500' textAlign='justify'>
                                                                Default Payrates
                                                            </Text>}
                                                        </Flex>
                                                    </Flex>)
                                                })
                                        }

                                    </Flex>
                                </Box>
                                {selectedUser && <Box
                                    p={4}
                                    borderRadius="lg"
                                    overflow="scroll"
                                    width="60%">
                                    <ViewAmbassadorProfile user={selectedUser} />
                                </Box>}
                            </Flex>
                        </Flex>}
                </ModalBody>
            </ModalContent>
        </Modal>
    </>
}
