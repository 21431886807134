import { EditIcon } from "@chakra-ui/icons";
import {
  Avatar,
  AvatarGroup,
  Flex,
  Icon,
  Progress,
  Td,
  Text,
  Tr,
  useColorModeValue,
  Image,
  Button,
  Checkbox,
} from "@chakra-ui/react";
import { AppContext } from "contexts/AppContext";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

function CampaignTableRow(props) {
  if (!props || !props.campaign) return null

  const navigate = useNavigate()
  const { userData, placeholderImage } = useContext(AppContext)
  const { campaign } = props
  const { imageUrl, name, invites, amount, campaignStatus, createdOn, progression, notes, id } = campaign;
  const textColor = useColorModeValue("gray.700", "white");

  const [selectedCampaigns, setSelectedCampaigns] = useState([])

  const updateSelectedCampaigns = (element, id) => {
    if (!element) return null

    const campaignsSelected = selectedCampaigns.length ? [...selectedCampaigns] : []
    if (!!element.checked) return setSelectedCampaigns(campaignsSelected.push(id))

    if (!element.checked) return setSelectedCampaigns(campaignsSelected.filter(item => item !== id))
  }

  let campaignValue = 0;
  for(let gig of campaign.gigs){
    campaignValue += gig.ambassadorFee;
    if(gig.giveawayValue && (gig.giveawayType === 'Cash Tip' || gig.giveawayValue === 'Alternate Sample')){
      campaignValue += gig.giveawayValue;
    }
    if(gig.productGiveawayValue){
      campaignValue += gig.productGiveawayValue;
    }
  }

  return (
    <Tr>
      <Td minWidth={{ sm: "250px" }} pl="0px">
        <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap" gap='1rem'>
          <Image src={imageUrl} objectFit='cover' minW='40px' maxW='40px' minH='40px' maxH='40px' borderRadius='lg' fallbackSrc={placeholderImage(40)} />
          <Flex flexDirection='column'>
            <Text
              fontSize="12px"
              color='gray.700'
              fontWeight="bold"
              minWidth="100%"
              cursor='pointer'
              onClick={() => navigate(`/campaigns/${id}`, { replace: true })}
            >
              {name}
            </Text>
            <Text
              fontSize="12px"
              color='gray.500'
              fontWeight="normal"
              minWidth="100%"
            >
              {notes}
            </Text>
          </Flex>

        </Flex>
      </Td>
      <Td>
        <Flex gap='1rem'>
          {
            campaign.gigs && campaign.gigs.map((item, idx) => <LinkButton
              key={idx}
              text={item.gigName}
              showIcon={true}
              onClick={() => {}}
              icon={<LinkArrowIcon />}
            />)
          }
        </Flex>

      </Td>
      {userData.role === 'AMBASSADOR' ? <Td>
        <Flex alignItems='center' justifyContent='center'>
          <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem">
            {campaign.brand ? campaign.brand.brandName : ''}
          </Text>
        </Flex>
      </Td> : <Td>
        <Flex alignItems='center' justifyContent='center'>
          {
            !invites || !invites.length ? '-' : ''
          }
          <AvatarGroup size="sm">
            {
              invites.map(invite => !invite.user ? null : <Avatar
                name={invite.user.firstName}
                key={invite.id}
                src={invite.user.profileImageUrl}
                _hover={{ zIndex: "3", cursor: "pointer" }}
              />)
            }
          </AvatarGroup>
        </Flex>
      </Td>
      }
      <Td>
        <LinkButton text={userData.role === 'AMBASSADOR' && campaignStatus === 'Created' ? 'Invite' : campaignStatus} />
      </Td>
      {userData.role === 'AMBASSADOR' ? null : <Td>
        <Flex justifyContent='center' alignItems='center'>
          -
        </Flex>
      </Td>}
      <Td>
        <Flex justifyContent='center' alignItems='center'>
          <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem">
            ${userData.role === 'AMBASSADOR' ? campaignValue : amount}
          </Text>
        </Flex>
      </Td>
      {userData.role === 'AMBASSADOR' ? null : <Td> 
      {campaignStatus ===  'Active' || campaignStatus === 'Closed' ? null : <Flex justifyContent='center' alignItems='center' >
          <EditIcon onClick={() => navigate(`/edit/campaign/${id}`, { replace: true })} cursor='pointer'/>
        </Flex>}
      </Td>}
      <Td>
        <Checkbox onChange={(e) => updateSelectedCampaigns(e.target, id)} />
      </Td>
    </Tr>
  );
}

export default CampaignTableRow;


export const LinkButton = (props) => {
  const { showIcon, icon, buttonColor, text, textColor, className, style, onClick, ...rest } = props
  return <>
    <Button {...rest} onClick={onClick} h={25} backgroundColor='brandbassBlue2.500' >
      <Flex gap='10px' alignItems='center'>
        <Text title={text} isTruncated fontSize={12} fontWeight='bold' color={textColor || 'white'} maxW='50px'>{text}</Text>
        {!!showIcon && icon}
      </Flex>
    </Button>
  </>
}

export const LinkArrowIcon = () => {
  return <>
    <svg width="15" height="11" viewBox="0 0 15 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M0.180124 9.64961C-0.00980426 9.44966 -0.0542541 9.14958 0.0693497 8.90176L0.670636 7.69625C2.09372 4.84312 4.97625 3.04478 8.12642 3.04479H8.42205C8.44361 2.56934 8.47144 2.09412 8.50554 1.61924L8.56369 0.809429C8.61098 0.150865 9.3343 -0.217276 9.88238 0.13826C11.6804 1.3046 13.2435 2.80747 14.4883 4.56674L14.8793 5.11924C15.0402 5.34677 15.0402 5.65323 14.8793 5.88076L14.4883 6.43326C13.2435 8.19254 11.6804 9.6954 9.88238 10.8617C9.3343 11.2173 8.61098 10.8491 8.56369 10.1906L8.50554 9.38076C8.46482 8.81365 8.43304 8.24605 8.41019 7.67817C6.56749 7.62416 4.72702 8.00174 3.03557 8.79427L0.912264 9.78915C0.663835 9.90555 0.370052 9.84956 0.180124 9.64961ZM2.08148 7.80374L2.49678 7.60915C4.56347 6.6408 6.83106 6.23199 9.08117 6.40744C9.40886 6.43299 9.6648 6.70535 9.67451 7.03886C9.69634 7.7884 9.73412 8.53757 9.78785 9.28586L9.7925 9.35065C11.2012 8.33981 12.4357 7.09687 13.4441 5.67175L13.5656 5.5L13.4441 5.32825C12.4357 3.90313 11.2012 2.66019 9.7925 1.64935L9.78785 1.71414C9.73989 2.38209 9.70464 3.05074 9.68209 3.71973C9.67025 4.07127 9.3861 4.35004 9.03961 4.35004L8.12642 4.35004C5.64301 4.35004 3.35619 5.67087 2.08148 7.80374Z" fill="white" />
    </svg>
  </>
}