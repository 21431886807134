import { Button, Flex,Box, Text, Tag, TagLabel, Center, Image, useColorModeValue, Avatar, Badge, IconButton, Spacer , Skeleton, SkeletonCircle, SkeletonText,} from "@chakra-ui/react";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CounterCard from "components/Card/CounterCard.js";
import React, { useState,useContext, useEffect } from "react";
import ReactDataGrid from '@inovua/reactdatagrid-community'
import { getFeedbacks } from "RestAPI";
import { checkboxColumn } from "../Campaign/CampaignList";
import CardBody from "components/Card/CardBody";
import moment from "moment";
import { EditIcon, StarIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import { AppContext } from "contexts/AppContext";
import { globalStyles } from "theme/styles";
import EmptyList from "assets/img/empty/feedback.png";
import NoFeedbacks from "assets/svg/emptyList/NoFeedbacks.svg";
import BBButton from "components/Button/Button";
import { FaPlus } from "react-icons/fa";
import './Feedback.css'

const FeedbackList = (props) => {
    const { placeholderImage, hasEditPermission } = useContext(AppContext)
    const title = 'Feedback'
    const textColor = useColorModeValue("black3", "white");
    const [feedbackData, setFeedbackData] = useState([])
    const [overView, setOverview] = useState(null)
    const [loading, setLoading] = useState(true)
    const navigate = useNavigate()
    const refreshList = () => {
        setLoading(true)
        getFeedbacks().then(response => {
            setLoading(false);
            if (Array.isArray(response.data) && response.data.length) {
                setFeedbackData(response.data)
                setOverview({
                    totalUsers: response.data.length,
                    campaign: response.data.filter(item => { return (item.feedbackType === 'Campaign') }),
                    ambassador: response.data.filter(item => { return (item.feedbackType === 'Ambassador') }),
                    reject: response.data.filter(item => { return (item.feedbackType === 'RejectCampaign') }),
                })
            }
        }).catch(err => { setLoading(false); console.error(err) })
    }

    const columns = [
        {
            name: "id",
            type: "number",
            header: <Text color="gray3" textTransform='uppercase'>ID</Text>,
            defaultVisible: false,
            defaultFlex: 1,
            defaultWidth: 100,
            sortable: true,
            render: ({ value }) => (
                <Text color="black3" fontSize='md' fontWeight="bold">
                    {value}
                </Text>
            ),
        },
        {
            name: "campaign",
            type: "string",
            header: <Text color="gray3" textTransform='uppercase'>campaign</Text>,
            defaultVisible: true,
            defaultFlex: 1,
            defaultWidth: 100,
            sortable: true,
            filterEditorProps: {
                style: {
                    background: '#fefefe',
                },
                placeholder: 'Search by campaign...'
            },
            render: ({ value }) => (
                <Text title={value?.name} isTruncated color="black3" fontSize='md' fontWeight="normal" onClick={() => navigate(`/campaigns/${value?.id}`, { replace: true })} >
                    {value?.name}
                </Text>
            ),
        },
        {
            name: "brand",
            type: "string",
            header: <Text color="gray3" textTransform='uppercase'>brand</Text>,
            defaultVisible: true,
            defaultFlex: 1,
            defaultWidth: 100,
            sortable: true,
            filterEditorProps: {
                style: {
                    background: '#fefefe',
                },
                placeholder: 'Search by brand...'
            },
            render: ({ value }) => <Flex cursor="pointer" align="center" py=".8rem" minWidth="100%" flexWrap="nowrap" >
                <Avatar src={value.logoUrl} w="50px" borderRadius="50px" me="18px" name={`${value.brandName}`} />
                <Flex direction="column">
                    <Text
                        fontSize="md"
                        color={textColor}
                        fontWeight="normal"
                        minWidth="100%"
                    >
                        {value.brandName}
                    </Text>
                    <Text fontSize="sm" color={"gray.500"} fontWeight="normal">
                        {value.email}
                    </Text>
                </Flex>
            </Flex>,
        },
        {
            name: "ambassador",
            type: "string",
            header: <Text color="gray3" textTransform='uppercase'>ambassador</Text>,
            defaultVisible: true,
            defaultFlex: 1,
            defaultWidth: 100,
            sortable: true,
            filterEditorProps: {
                style: {
                    background: '#fefefe',
                },
                placeholder: 'Search by name...'
            },
            render: ({ value }) => !value ? '-' :
                <Flex cursor="pointer" align="center" py=".8rem" minWidth="100%" flexWrap="nowrap" >
                    <Avatar src={value.profileImageUrl} w="50px" borderRadius="50px" me="18px" name={`${value.firstName} ${value.lastName}`} />
                    <Flex direction="column">
                        <Text
                            fontSize="md"
                            color={textColor}
                            fontWeight="normal"
                            minWidth="100%"
                        >
                            {value.firstName} {value.lastName}
                        </Text>
                        <Text fontSize="sm" color={"gray.500"} fontWeight="normal">
                            {value.email}
                        </Text>
                    </Flex>
                </Flex>,
        },
        {
            name: "feedbackType",
            header: <Text color="gray3" textTransform='uppercase'>TYPE</Text>,
            defaultVisible: true,
            defaultFlex: 1,
            maxWidth: 160,
            sortable: true,
            render: ({ value }) => {
              let bgColor = globalStyles.colors.statusGreen
              if (value === 'Campaign') {
                bgColor = globalStyles.colors.statusYellow
              } else if (value === 'Brand') {
                bgColor = globalStyles.colors.statusRed
              } else if (value === 'Ambasador') {
                bgColor = '#4DA6F6'
              } else if (value === 'Draft') {
                bgColor = globalStyles.colors.statusGray
              }
              return (
                < Tag textAlign={'center'} minW={28} backgroundColor={bgColor}><Spacer /><TagLabel textAlign={'center'}>{value}</TagLabel><Spacer /></Tag>
              )
            },
          },

        {
            name: "rating",
            type: "string",
            header: <Text color="gray3" textTransform='uppercase'>rating</Text>,
            defaultVisible: true,
            defaultFlex: 1,
            maxWidth: 150,
            sortable: true,
            render: ({ value }) => !value ? '-' : [...Array(value)].map((e, i) => <StarIcon key={i} color="yellow.500" />)
        },
        {
            name: "createdOn",
            type: "string",
            header: <Text color="gray3" textTransform='uppercase'>created On</Text>,
            defaultVisible: true,
            defaultFlex: 1,
            maxWidth: 150,
            sortable: true,
            render: ({ value }) => (
                <Text color="black3" fontSize='md' fontWeight="normal">
                    {moment(value).format('MM-DD-YYYY')}
                </Text>
            ),
        },
        {
            name: "comment",
            type: "string",
            header: <Text color="gray3" textTransform='uppercase'>comment</Text>,
            defaultVisible: true,
            defaultFlex: 1,
            defaultWidth: 100,
            sortable: true,
            render: ({ value }) => (
                <Text title={value} isTruncated color="black3" fontSize='sm' fontWeight="normal">
                    {value || '-'}
                </Text>
            ),
        },
        // {
        //     id: 'action', defaultFlex: 1, header: <Text color="gray3" textTransform='uppercase'>Action</Text>, defaultWidth: 30, sortable: true, render: ({ data }) =>
        //         !hasEditPermission ? null :
        //         <IconButton onClick={() => { }}
        //             variant='outline' size="xs"
        //             colorScheme='teal' borderRadius={"50%"}
        //             aria-label='Send email'
        //             icon={<EditIcon />}
        //         />
        // }

    ]

    const defaultFilterValue = [{ name: 'campaign', type: 'campaign', operator: 'contains', value: '' }, { name: 'brand', type: 'brand', operator: 'contains', value: '' }, { name: 'ambassador', type: 'ambassador', operator: 'contains', value: '' }]
    const filterTypes = Object.assign({}, ReactDataGrid.defaultProps.filterTypes, {
        campaign: {
          name: 'campaign',
          emptyValue: null,
          operators: [
            {
              name: 'contains',
              fn: ({ value, filterValue, data }) => {
                const match = value && value.name?.toLowerCase().indexOf(filterValue.toLowerCase()) !== -1 
                return !filterValue ? true : match                  
              }
            }
          ]
        },
        brand: {
            name: 'brand',
            emptyValue: null,
            operators: [
              {
                name: 'contains',
                fn: ({ value, filterValue, data }) => {
                  const match = value?.brandName?.toLowerCase().indexOf(filterValue.toLowerCase()) !== -1 
                  return !filterValue ? true : match                  
                }
              }
            ]
          },
        ambassador: {
            name: 'ambassador',
            emptyValue: null,
            operators: [
              {
                name: 'contains',
                fn: ({ value, filterValue, data }) => {
                  const match = value?.firstName?.toLowerCase().indexOf(filterValue.toLowerCase()) !== -1 || value?.lastName?.toLowerCase().indexOf(filterValue.toLowerCase()) !== -1
                  return !filterValue ? true : match                  
                }
              }
            ]
          }
      });

    columns.forEach(item => item.style = { borderLeftColor: 'white', borderRightColor: 'white', borderTopColor: 'white', borderBottomColor: 'gray.200' })

    useEffect(refreshList, [])

    return <>
        <Card id="feedback-list" mt='5px' p='16px' overflowX={{ sm: "scroll" }} boxShadow='0px 0px 20px 1px rgb(0 0 0 / 10%)' borderRadius='10px'>
            <CardHeader p='12px 0px 28px 0px'>
                <Flex flex={1} align="center" gap='2rem' w="100%" ml={2} mr={2}>
                    <Flex direction='column' gap='2rem' w="100%">
                        <Flex gap='1rem' flexDirection='column' >
                            <Text fontSize='30px' color={textColor} fontWeight='bold'>
                                {title}
                            </Text>
                            {
                                !loading && feedbackData && feedbackData.length == 0 && <>
                                    <Text fontStyle='italic' fontWeight='600' fontSize='16px' maxW='540px'>This is your feedback view, where you can see and review feedback.</Text>
                                </>
                            }
                            {/* <Button colorScheme='brandbassBlue' size='sm' onClick={() => setShowIssueForm(true)}>
                                + Create
                            </Button> */}
                        </Flex>
                        <Flex gap='20px' p='10px' alignItems='center' borderRadius='10px' >
                            {
                                loading && (
                                    <Flex align="center" minWidth="100%" flexWrap="nowrap">
                                        <Box padding='6' boxShadow='lg' minWidth="100%">
                                            <SkeletonText mt='4' noOfLines={4} spacing='4' />
                                        </Box>
                                    </Flex>
                                )
                            }
                            {!loading && feedbackData && feedbackData.length > 0 && (<>
                                <CounterCard title={"Total"} width={200} count={overView ? overView.totalUsers : 0} />
                                <CounterCard title={"Campaign"} width={200} count={overView ? overView.campaign.length : 0} />
                                <CounterCard title={"Ambassador"} width={200} count={overView ? overView.ambassador.length : 0} />
                                <CounterCard title={"Rejects"} width={200} count={overView ? overView.reject.length : 0} />
                            </>)}
                        </Flex>
                    </Flex>
                </Flex>
            </CardHeader>
            <CardBody>
                <>
                    {
                        loading && (
                            <Flex align="center" minWidth="100%" flexWrap="nowrap">
                                <Box padding='6' boxShadow='lg' minWidth="100%">
                                    <SkeletonCircle size='20' />
                                    <SkeletonText mt='4' noOfLines={4} spacing='4' />
                                </Box>
                            </Flex>
                        )
                    }
                    {feedbackData && feedbackData.length == 0 && (
                        <Flex align="center" minWidth="100%" flexWrap="nowrap">
                            <Box padding='6' minWidth="100%" justifyContent={"center"}>
                                <Center>
                                    <Image
                                        src={NoFeedbacks}
                                        maxW="40%"
                                        borderRadius="lg"
                                        fallbackSrc={placeholderImage(40)}
                                        boxShadow='xs'
                                    /></Center>
                            </Box>
                        </Flex>
                    )}
                    {!loading && feedbackData && feedbackData.length > 0 && (
                        <ReactDataGrid
                            loading={feedbackData === null}
                            className="brandbass-data-grid"
                            style={{ minHeight: 860, borderColor: 'white' }}
                            idProperty="id"
                            columns={columns}
                            dataSource={feedbackData || []}
                            pagination={true}
                            defaultLimit={10}
                            defaultSortInfo={{ id: "id", name: 'id', dir: -1, type: 'number' }}
                            checkboxColumn={checkboxColumn}
                            checkboxOnlyRowSelect={true}
                            rowHeight={70}
                            headerHeight={60}
                            showZebraRows={false}
                            defaultLoading={loading}
                            enableSelection={true}
                            showHoverRows={true}
                            showCellBorders="horizontal"
                            enableColumnAutosize={true}
                            defaultFilterValue={defaultFilterValue}
                            filterTypes={filterTypes}
                            enableColumnFilterContextMenu={false}
                            emptyText="No feedbacks"
                        />
                    )}
                </>
            </CardBody>
        </Card>
    </>
}

export default FeedbackList 