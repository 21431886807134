import React, { useState } from "react";
// Chakra imports
import {
  Box,
  Flex,
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Link, Stack,
  Switch,
  Text,
  useColorModeValue,
  Select,
  Image,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from "@chakra-ui/react";
// Assets
import signInImage from "assets/svg/LoginBG.svg";
import { authenticate } from "RestAPI";
import { useContext } from "react";
import { AppContext } from "contexts/AppContext";
import { Navigate, useNavigate, NavLink } from "react-router-dom";
import BrandBassShortIcon from 'assets/svg/brandbass-short-logo.svg'
import BrandBassIcon from 'assets/svg/brandbass-logo.svg'
import BBInput from "components/Input/Input";
import BBButton from "components/Button/Button";

function SignIn() {
  // Chakra color mode
  const { setUserData, userData } = useContext(AppContext)
  const titleColor = useColorModeValue("brandbassBlue2.500", "white");
  const textColor = useColorModeValue("gray.400", "white");
  const [error, seterror] = useState('')
  const [redirectAlert, setRedirectAlert] = useState(false)
  const [brandName, setBrandName] = useState('')
  const [redirectBrand, setRedirectBrand] = useState('')
  const [credential, setCredential] = useState({
    userIdentifier: '',
    userPassword: ''
  })
  let brandSubdomain = window.location.hostname.split('.')[0]
  brandSubdomain = !!brandSubdomain ? brandSubdomain.toLowerCase() : ''

  const navigate = useNavigate()

  const updateCredential = (obj) => {
    seterror('')
    setCredential({ ...credential, ...obj })
  }

  const loginSubmit = async () => {
    const res = await authenticate(credential)
    localStorage.setItem('jwt', '')
    localStorage.setItem('refreshToken', '')
    if (res.data && res.data.error === 'PASSWORD_RESET_REQUIRED') {
      navigate("reset-password", { state: { userIdentifier: credential.userIdentifier, oldPassword: credential.userPassword } })
    }

    if (!res || !res.data || !res.data.userObject){
      return seterror(!!res && !!res.data && !!res.data.error && !!res.data.error.message
        ? res.data.error.message
        : 'Invalid credentials. Please try again.')
    }

    if(res.data.userObject.brand && res.data.userObject.brand.subdomain && brandSubdomain !== res.data.userObject.brand.subdomain && (!res.data.userObject.client || brandSubdomain !== res.data.userObject.client?.subdomain)){
      // return window.location.replace(`https:${res.data.userObject.brand.subdomain}.stage.brandbass.ai`)
      setBrandName(res.data.userObject.brand.brandName)
      setRedirectBrand(res.data.userObject.brand.subdomain)
      return setRedirectAlert(true)
    }
    localStorage.setItem('jwt', res.data.jwt || '')
    localStorage.setItem('refreshToken', res.data.refreshToken || '')
    seterror('')
    // TODO:: uncomment when subscriptions is ready
    // if(res.data.userObject.role === 'BRAND_SUPER_ADMIN' &&  (!res.data.subscriptions || !res.data.subscriptions.data || !res.data.subscriptions.data.length)){
    //   return navigate('/new/subscription')
    // }
    setUserData(!!res && !!res.data && !!res.data.userObject ? res.data.userObject : null)
  }

  if(redirectAlert){
    return (
      <Alert
        background={'brandbassBlue2.500'}
        status='success'
        variant='subtle'
        flexDirection='column'
        alignItems='center'
        justifyContent='center'
        textAlign='center'
        height='200px'
      >
        {/* <AlertIcon boxSize='40px' mr={0} /> */}
        <AlertTitle mt={4} mb={1} fontSize='lg'>
          Brand Login
        </AlertTitle>
        <AlertDescription maxWidth='sm'>
          We have found you are associated with brand {brandName}. Please click below to login.
        </AlertDescription>
        <Link mt={4} href={`https://${redirectBrand}.brandbass.ai`}>
          {`https://${redirectBrand}.brandbass.ai`}
        </Link>
      </Alert>
    )
  }
  return !!userData && !!userData.id && ((!!userData.brand && !!userData.brand.id) || (userData.role.toLowerCase() === 'ambassador' || userData.role.toLowerCase() === 'super_admin' || userData.role === 'CLIENT_SUPER_ADMIN')) ? <Navigate to="/" /> :
    !!userData && !!userData.id ? <Navigate to="/new/brand-registration" /> : (
      <Flex position='relative'>

        <Flex
          h={{ sm: "initial", md: "75vh", lg: "85vh" }}
          w='100%'
          maxW='1044px'
          mx='auto'
          justifyContent='space-between'
          mb='30px'
          pt={{ sm: "100px", md: "0px" }}>
          <Flex
            alignItems='center'
            justifyContent='start'
            style={{ userSelect: "none" }}
            w={{ base: "100%", md: "50%", lg: "42%" }}>

            <Flex
              direction='column'
              w='100%'
              background='transparent'
              p='48px'
              mt={{ md: "150px", lg: "80px" }}>
              <Heading color={'primaryColor'} fontSize='32px' mb='10px'>
                Welcome Back
              </Heading>
              <Text
                mb='36px'
                ms='4px'
                color={textColor}
                fontWeight='bold'
                fontSize='14px'>
                Enter your username and password to sign in
              </Text>
              <FormControl>

                <BBInput
                  id="username"
                  mb='24px'
                  label="Username"
                  placeholder='Your username'
                  onChange={(e) => updateCredential({ userIdentifier: e.target.value })}
                  value={credential.userIdentifier} />

                <BBInput
                  id="password"
                  type='password'
                  mb='36px'
                  label="Password"
                  placeholder='Your password'
                  onChange={(e) => updateCredential({ userPassword: e.target.value })}
                  value={credential.userPassword} />

                <FormControl display='flex' alignItems='center'>
                  <Switch id='remember-login' colorScheme='brandbassBlue2' me='10px' />
                  <FormLabel
                    htmlFor='remember-login'
                    mb='0'
                    ms='1'
                    fontWeight='normal'>
                    Remember me
                  </FormLabel>
                </FormControl>
                {!!error && <Text as="p" mt="10px" color={'red'}> {error} </Text>}
                <BBButton type='submit' 
                  w='100%'
                  h='45'
                  mb='20px'
                  mt='20px'
                  onClick={loginSubmit}>
                  SIGN IN
                </BBButton>
              </FormControl>
              {brandSubdomain === 'dashboard' || brandSubdomain === 'dashboard-prod' || brandSubdomain === 'admin' ? <Flex
                flexDirection='column'
                justifyContent='center'
                alignItems='center'
                maxW='100%'
                mt='0px'>
                <Text color={textColor}>
                  Don't have an account?
                  <NavLink style={{ color: titleColor, fontWeight: 'bold', marginLeft: 8 }} to={{ pathname: "/auth/signup" }}>
                    Sign Up
                  </NavLink>
                </Text>
              </Flex> : null}
              <NavLink style={{ textAlign: 'center', color: '#a19a9a', fontWeight: 'bold', display: 'block', cursor: 'pointer' }} to={{ pathname: "/auth/forgot-password" }}>
                Forgot Password
              </NavLink>
            </Flex>

          </Flex>

          <Box
            display={{ base: "none", md: "block" }}
            overflowX='hidden'
            h='100%'
            w='50vw'
            mt={-70}
            position='absolute'
            right='0px'>
            <Flex
              flexDirection='column'
              justifyContent='center'
              alignItems='center'
              bgImage={signInImage}
              w='100%'
              h='100%'
              bgSize='cover'
              bgPosition='50%'
              position='absolute'
              borderBottomLeftRadius='20px'>
              <Image src={BrandBassShortIcon} w='67px' h='87px' />
              <Image src={BrandBassIcon} w='250px' />
            </Flex>
          </Box>
        </Flex>
      </Flex>
    );
}

export default SignIn;
