export const buttonStyles = {
  components: {
    Button: {
      
      sizes: {
        large: {
          h: '68px',
          fontSize: '20px',
          width: '349px',
          borderRadius: '8px'
        },
        medium: {
          h: '62px',
          fontSize: '18px',
          width: '314px',
          borderRadius: '8px'
        },
        normal: {
          h: '55px',
          fontSize: '16px',
          width: '275px',
          borderRadius: '8px'
        },
        small: {
          h: '48px',
          fontSize: '14px',
          width: '241px',
          borderRadius: '8px'
        }
      },
      // defaultProps:{
      //   _hover: {
      //     bg: 'underline',
      //   },
      // },
      variants: {
        "no-hover": {
          _hover: {
            boxShadow: "none",
            bg: "rgb(3, 36, 65)"
          },
        },
        "transparent-with-icon": {
          bg: "transparent",
          fontWeight: "bold",
          borderRadius: "inherit",
          cursor: "pointer",
          _hover: "none",
          _active: {
            bg: "transparent",
            transform: "none",
            borderColor: "transparent",
          },
          _focus: {
            boxShadow: "none",
          },
          _hover: {
            boxShadow: "none",
          },
        },
        "outline":{
          border: '2px solid',
          color: 'gray1'
        },
        "icon-button":{
          borderRadius:'50%',
          background:'brandbassBlue.500',
          width:'56px',
          height:'56px',
          fontSize:'18px',
          color:'white'
        }
      },
      baseStyle: {
        borderRadius: "15px",
        _focus: {
          boxShadow: "none",
        },
      },
    },
  },
};
