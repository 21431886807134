import { Flex, Text } from "@chakra-ui/react"

const NoMatch = () => {
    return (
        <Flex 
            justifyContent= 'center'
            alignItems= 'center'
            h= '100vh'
            w= 'full'
        >
            <Text>Page not found - 404!</Text>
        </Flex>
    )
}

export default NoMatch

