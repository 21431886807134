// Chakra imports
import { EditIcon, ViewIcon } from "@chakra-ui/icons";
import {
  Button,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr, Box, Tag, TagLabel, Center,
  useColorModeValue, Skeleton, SkeletonCircle, SkeletonText,
  Modal, IconButton,
  useDisclosure,
  Flex,
  AvatarGroup,
  Avatar,
  Badge, Spacer
} from "@chakra-ui/react";
import ReactDataGrid from "@inovua/reactdatagrid-community";
// Custom components
import Card from "components/Card/Card.js";
import CounterCard from "components/Card/CounterCard.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import { TrashIcon } from "components/Icons/Icons";
import TeamsTableRow from "components/Tables/TeamsTableRow";
import moment from "moment";
import React, { useEffect, useState, useContext } from "react";
import { getBrandGroups } from "RestAPI";
import { checkboxColumn } from "views/Dashboard/Campaign/CampaignList";
import GroupForm from "./GroupForm";
import { AppContext } from "contexts/AppContext";
import { globalStyles } from "theme/styles";
import EmptyList from "assets/img/empty/project.png";
import BBButton from "components/Button/Button";
import { FaPlus } from "react-icons/fa";
import { useNavigate, useSearchParams } from "react-router-dom";
import { DeleteEntityModal } from "layouts/Dashboard";
import ViewGroup from "./ViewGroup";
import "../Group.css"

const GroupList = ({ title, captions }) => {
  const navigate = useNavigate()
  const { hasEditPermission, hasCreatePermission, userData } = useContext(AppContext)
  const textColor = useColorModeValue("black3", "white");
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [selectedRow, setSelectedRow] = useState({})
  const [groupData, setGroupData] = useState(null)
  const [loading, setLoading] = useState(true)
  const [overView, setOverview] = useState(null)
  const [searchParams] = useSearchParams()
  const myGroups = searchParams.get('myGroups')
  const editItem = (row) => {
    navigate(`/edit/group/${row.id}`, {state: {data: row}} )
  }
  const [deleteModal, setDeleteModal] = useState(null)
  const [entityId, setEntityId] = useState(null)
  const [showGroupDetails, setShowGroupDetails] = useState(false)
  const [groupId, setGroupId] = useState();

  const deleteItem = (row) => {
    setEntityId(row.id)
    setDeleteModal(true)
  }


  const refreshList = () => {
    setLoading(true)
    getBrandGroups({myGroups}).then(response => {
      setLoading(false);
      if (Array.isArray(response) && response.length) {
        response = response.map(group=>{
          group.nameObj={groupName:group.groupName,imageUrl: group.imageUrl}
          return group
        })
        setGroupData(response)
        setOverview({
          totalUsers: response.length,
          activeUsers: response.filter(item => !!item.isActive),
          inactiveUsers: response.filter(item => !item.isActive),
        })
      }
    }).catch(err => { setLoading(false); console.error(err) })
  }

  const columns = [
    {
      name: "id",
      type: "number",
      header: <Text color="gray3" textTransform='uppercase'>ID</Text>,
      defaultVisible: false,
      defaultFlex: 1,
      defaultWidth: 100,
      sortable: true,
      render: ({ value }) => (
        <Text color="black3" fontSize='md' fontWeight="normal">
          {value}
        </Text>
      ),
    },
    {
      name: "nameObj",
      type: "string",
      header: <Text color="gray3" textTransform='uppercase'>Group Name</Text>,
      defaultVisible: true,
      defaultFlex: 1,
      defaultWidth: 100,
      sortable: true,
      filterEditorProps: {
        style: {
          background: '#fefefe',
        },
        placeholder: 'Search by name...'
      },
      render: ({ value }) => (
        <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap" >
          <Avatar src={value.imageUrl} name={value.groupName} w="50px" borderRadius="50px" me="18px" />
          <Text color="black3" fontSize='md' fontWeight="normal">
            {value.groupName}
          </Text>
        </Flex>
      ),
    },
    {
      name: "description",
      type: "string",
      header: <Text color="gray3" textTransform='uppercase'>description</Text>,
      defaultVisible: true,
      defaultFlex: 1,
      defaultWidth: 100,
      sortable: true,
      render: ({ value }) => (
        <Text color="black3" fontSize='md' fontWeight="normal">
          {value}
        </Text>
      ),
    },
    {
      name: "owner",
      type: "string",
      header: <Text color="gray3" textTransform='uppercase'>owner</Text>,
      defaultVisible: true,
      defaultFlex: 1,
      defaultWidth: 100,
      sortable: true,
      render: ({ value }) => (
        <Flex direction="column">
          <Text
            fontSize="md"
            color={textColor}
            fontWeight="normal"
            minWidth="100%"
          >
            {value.firstName} {value.lastName}
          </Text>
          <Text fontSize="sm" color={"gray.500"} fontWeight="normal">
            {value.email}
          </Text>
        </Flex>
      ),
    },
    {
      name: "users",
      header: <Text color="gray3" textTransform='uppercase'>Members</Text>,
      defaultVisible: true,
      defaultFlex: 1,
      maxWidth: 250,
      sortable: false,
      render: ({ value }) => (
        <>
          <Flex alignItems="center" justifyContent="flex-start">
            {!value || !value.length ? (
              "-"
            ) : (
              <AvatarGroup size="md">
                {value.map((user) =>
                  <Avatar
                    name={user.firstName}
                    title={user.firstName}
                    key={user.id}
                    src={user.profileImageUrl}
                    _hover={{ zIndex: "3", cursor: "pointer" }}
                  />
                )}
              </AvatarGroup>
            )}
          </Flex>
        </>
      ),
    },
    {
      name: "isActive",
      header: <Text color="gray3" textTransform='uppercase'>STATUS</Text>,
      defaultVisible: true,
      defaultFlex: 1,
      maxWidth: 160,
      sortable: true,
      render: ({ value }) => {
        let val = value ? 'Active' : 'InActive';
        let bgColor = globalStyles.colors.statusGreen
        if (val === 'Created') {
          bgColor = globalStyles.colors.statusYellow
        } else if (val === 'Inactive') {
          bgColor = globalStyles.colors.statusRed
        } else if (val === 'Closed') {
          bgColor = '#4DA6F6'
        } else if (val === 'Draft') {
          bgColor = globalStyles.colors.statusGray
        }
        return (
          < Tag textAlign={'center'} minW={28} backgroundColor={bgColor}><Spacer /><TagLabel textAlign={'center'}>{val}</TagLabel><Spacer /></Tag>
        )
      },
    },
    {
      name: "createdOn",
      type: "date",
      header: <Text color="gray3" textTransform='uppercase'>Created on</Text>,
      defaultVisible: true,
      defaultFlex: 1,
      maxWidth: 160,
      sortable: true,
      render: ({ value }) => (
        <Text color="black3" fontSize='md' fontWeight="normal">
          {moment(value).format('MM-DD-YYYY')}
        </Text>
      ),
    },
    {
      header: <Text color="gray3" textAlign='center'>ACTION</Text>,
      defaultVisible: true,
      defaultFlex: 1,
      defaultWidth: 30,
      textAlign: 'center',
      sortable: false,
      render: ({ data }) => !(userData?.role?.includes('ADMIN') || data?.owner?.id === userData.id) 
      ? null 
      : <Flex gap='0.25rem' justifyContent="center" alignItems="center" >
        <IconButton onClick={() => editItem(data)}
          variant='outline' size="xs"
          colorScheme='teal' borderRadius={"50%"}
          aria-label='Edit item'
          icon={<EditIcon />}
        />
        <IconButton onClick={() => deleteItem(data) }
          disabled={data.isDefault}
          variant='outline' size="xs"
          colorScheme='teal' borderRadius={"50%"}
          aria-label='Delete item'
          icon={<TrashIcon />}
        />
        <IconButton onClick={() => {setGroupId(data.id);setShowGroupDetails(true)}}
          variant='outline' size="xs"
          colorScheme='teal' borderRadius={"50%"}
          aria-label='Delete item'
          icon={<ViewIcon />}
        />
      </Flex>
    },
  ]

  columns.forEach(item => item.style = { borderLeftColor: 'white', borderRightColor: 'white', borderTopColor: 'white', borderBottomColor: 'gray.200' })

  useEffect(refreshList, [])

  return (
    <>
      <DeleteEntityModal 
        visible={deleteModal} 
        closeHandler={(refresh) => {
          if(!!refresh) refreshList()
          setDeleteModal(false)
        }} 
        entity='group'
        entityId={entityId}
      />
      <Card id="group-list" mt='5px' p='16px' overflowX={{ sm: "scroll" }} boxShadow='0px 0px 20px 1px rgb(0 0 0 / 10%)' borderRadius='10px'>
        <CardHeader p='12px 0px 28px 0px'>
          <Flex flex={1} align="center" gap='2rem' w="100%" ml={2} mr={2}>
            <Flex direction='column' gap='2rem' w="100%">
              <Flex gap='1rem' flexDirection='column'>
                <Text fontSize='30px' color={textColor} fontWeight='bold' pb='.5rem'>
                  {title}
                </Text>
                <Spacer />
                {
                  !!hasCreatePermission &&
                  <BBButton shadow={true} maxW='fit-content' boxShadow='1px 4px 10px rgba(31, 100, 255, 0.25)' size="sm" leftIcon={<FaPlus />} colorScheme='brandbassBlue' color="white" onClick={() => navigate('/new/group')}>
                    GROUP
                  </BBButton>
                }
              </Flex>
              <Flex gap='20px' p='10px' alignItems='center' borderRadius='10px' >
                {
                  loading && (
                    <Flex align="center" minWidth="100%" flexWrap="nowrap">
                      <Box padding='6' boxShadow='lg' minWidth="100%">
                        <SkeletonText mt='4' noOfLines={4} spacing='4' />
                      </Box>
                    </Flex>
                  )
                }
                {!loading && (<>
                  <CounterCard title={"Total"} width={250} count={overView ? overView.totalUsers : 0} />
                  <CounterCard title={"Active"} width={250} count={overView ? overView.activeUsers.length : 0} />
                  <CounterCard title={"Inactive"} width={250} count={overView ? overView.inactiveUsers.length : 0} />
                </>)}
              </Flex>
            </Flex>
          </Flex>
        </CardHeader>
        <CardBody >
          {
            loading && (
              <Flex align="center" minWidth="100%" flexWrap="nowrap">
                <Box padding='6' boxShadow='lg' minWidth="100%">
                  <SkeletonCircle size='20' />
                  <SkeletonText mt='4' noOfLines={4} spacing='4' />
                </Box>
              </Flex>
            )
          }
          {!loading && (
            <ReactDataGrid
              loading={groupData === null}
              className="brandbass-data-grid"
              style={{ minHeight: 860, borderColor: 'white' }}
              idProperty="id"
              columns={columns}
              dataSource={groupData || []}
              pagination={true}
              defaultLimit={10}
              defaultLoading={loading}
              defaultSortInfo={{ id: "id", name: 'id', dir: -1, type: 'number' }}
              checkboxColumn={checkboxColumn}
              checkboxOnlyRowSelect={true}
              rowHeight={70}
              headerHeight={60}
              showZebraRows={false}
              enableSelection={true}
              showHoverRows={true}
              showCellBorders="horizontal"
              enableColumnAutosize={true}
              defaultFilterValue={[{ name: 'groupName', type: 'string', operator: 'contains', value: '' }]}
              enableColumnFilterContextMenu={false}
              emptyText="No groups"
            />
          )}
        </CardBody>
      </Card>
      <Modal size={'4xl'} isOpen={showGroupDetails} onClose={() => setShowGroupDetails(false)}>
        <ViewGroup onClose={() => setShowGroupDetails(false)} GroupID={groupId} />
      </Modal>
    </>
  );
};

export default GroupList;
