/*eslint-disable*/
import React from "react";
import { Button } from "@chakra-ui/react";

export default function BBButton({ colorScheme, _hover, _disabled, _active, fallBackText, shadow, ...props }) {
    return (
        <Button
            _hover={{ ..._hover && { ..._hover }, bg: props.variant === 'outline' || props.variant === 'ghost' ? 'gray5' : "primary", borderColor: 'primary' }}
            borderRadius={props.borderRadius ? props.borderRadius : 'md'}
            _active={{
                ..._active && { ..._active },
                bg: 'gray',
                borderColor: 'cyanBlue'
            }}
            _disabled={{
                ..._disabled && { ..._disabled },
                bg: props.variant === 'outline' ? 'white' : 'gray4',
                ...(props.variant === 'outline' && { borderColor: 'lightBlack' })
            }}
            disabled={props.disabled}
            leftIcon={props.leftIcon ? props.leftIcon : ''}
            colorScheme={colorScheme ? colorScheme === 'primary' ? 'brandbassBlue2' : colorScheme : 'brandbassBlue'}
            boxShadow={!!shadow ? '1px 4px 10px rgba(31, 100, 255, 0.25)' : ''}
            {...props}>{props.children ? props.children : fallBackText ? fallBackText : props.icon ? props.icon : ''}</Button>)
}

export const BBSecondaryButton = (props) => <BBButton
    {...props}
    _hover={{ bgColor: 'primary', color: 'white' }}
    variant='outline'
/>

export const BBSecondaryButtonWithoutHover = (props) => <BBButton
    {...props}
    variant='outline'
/>
