import {
  Box, Flex, Modal, ModalBody, ModalCloseButton, ModalContent,
  ModalFooter, ModalHeader, ModalOverlay, Radio, RadioGroup, Text, VStack, useToast
} from "@chakra-ui/react";
import BBButton from "components/Button/Button";
import BBTextarea from "components/Textarea/Textarea";
import { useContext, useEffect, useState } from "react";
import { saveFeedback } from "RestAPI";
import { getAllQuestionsOfType } from "RestAPI";
import { AppContext } from "contexts/AppContext"
import { StarIcon } from "@chakra-ui/icons";

const FeedbackForm = (props) => {
  const { visible, closeHandler, campaign, feedbackType, readOnly, feedback } = props
  const { userData } = useContext(AppContext)
  const [showModal, setShowModal] = useState(false)
  const [questions, setQuestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedAnswers, setSelectedAnswers] = useState([]);
  const [queAnswers, setQueAnswers] = useState([]);
  const [comment, setComment] = useState('');
  const [qa, setQA] = useState([])
  const [rating, setRating] = useState(0)
  const title = 'Feedback'
  const ratedEntity = feedbackType !== 'RejectCampaign' ? userData.role.toUpperCase().includes('BRAND') ? 'Ambassadors' : 'Brands' : 'Campaigns'
  const toast = useToast()

  useEffect(() => {
    // setLoading(true);
    getAllQuestionsOfType({ type: feedbackType })
      .then(response => {
        setLoading(false);
        if (!response || !response.data) return
        let qa = [];
        response.data.forEach(questionItem => {
          if (questionItem?.answers?.length) {
            questionItem.answers.forEach(answerItem => {
              answerItem.text = answerItem.answerText;
              answerItem.key = answerItem.id;
            });
            questionItem.answers.reverse();
            qa.push({ qid: questionItem.id, aid: null });
          }
        });
        setQuestions(response.data);
        setQueAnswers(qa);
      })
      .catch(_err => {
        console.log(_err)
        setLoading(false);
      });
  }, []);

  const updateSelectedAnswers = (answerText, qObj, idx) => {
    const selectedAnswer = qObj.answers.filter(ans => ans.answerText === answerText)
    if (!selectedAnswer || !selectedAnswer.length) return console.error('invalid answer')
    const qid = qObj.id
    const aid = selectedAnswer[0].id
    const qaCopy = [...qa]
    qaCopy[idx] = { qid, aid }
    setQA(qaCopy)
  };

  const validateInput = () => {
    if (!rating) return false
    if (!qa || !qa.length) return false
    const nonAnswered = qa.filter(item => !item.qid || !item.aid)
    if (nonAnswered.length) return false
    return true
  }

  const submit = () => {
    setLoading(true)
    saveFeedback(feedback?.id, {
      campaignId: campaign?.id || null,
      qa,
      comment,
      feedbackType,
      rating,
    }).then(res => {
      toast({
        title: 'Feedback saved',
        description: "",
        status: 'success',
        position: 'top',
        duration: 3000,
        isClosable: true,
      })
      setShowModal(false)
      closeHandler()
      setLoading(false)
    }).catch(err => {
      toast({
        title: `An error occured when saving the feedback.`,
        description: "Please try after sometime or create an issue",
        status: 'error',
        position: 'top',
        duration: 3000,
        isClosable: true,
      })
      setLoading(false)
    })
  }

  const cancel = () => {
    setQA([])
    setRating(0)
    setComment('')
    setQuestions([])
    closeHandler()
  }

  useEffect(() => setShowModal(!!visible), [visible])

  return <>
    <Modal isOpen={showModal} onClose={closeHandler} size="4xl" scrollBehavior='outside' >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader color='brandbassBlue'>
          {title}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <Box
            id="feedback-form"
            p={8}
            pb='150px'
            borderWidth="1.5px"
            borderRadius="lg"
          >
            <Flex flexDirection='column' gap={10}>
              <Flex flexDirection='column' gap={10}>
                <Text>Please provide a feed back. It helps us to find better {ratedEntity} for you!</Text>
                <Flex gap='1rem' align='center' justify='center'>
                  {
                    [...Array(5)].map((e, i) => <StarIcon
                      key={i}
                      cursor='pointer'
                      w='2rem'
                      h='2rem'
                      color={i + 1 <= rating ? 'yellow.500' : 'white'}
                      stroke={i + 1 > rating ? 'yellow.500' : 'white'}
                      onClick={() => setRating(i + 1)}
                    />)
                  }
                </Flex>
              </Flex>
              {
                !!rating && rating < 5 && questions.length > 0 &&
                questions.map((qItem, idx) => {
                  return (
                    <Box key={idx}>
                      <RadioGroup
                        name="feedbackQA"
                        onChange={(answerText) => updateSelectedAnswers(answerText, qItem, idx)}
                        defaultValue={''}
                      >
                        <Text
                          fontWeight='bold'
                          fontStyle='italic'
                          color='primary'
                          mb='12px'
                        >
                          {qItem.questionText}
                        </Text>
                        <VStack align='stretch' spacing='1rem'>
                          {
                            qItem.answers.map(
                              (item, idx) =>
                                <Radio
                                  key={idx}
                                  value={item.text}
                                  color='primary'
                                >
                                  {item.text}
                                </Radio>
                            )
                          }
                        </VStack>
                      </RadioGroup>
                    </Box>
                  );
                })}
              <BBTextarea value={comment} onChange={e => setComment(e.target.value)} placeholder='Additional comments if any' />
            </Flex>
          </Box>
        </ModalBody>
        {
          !readOnly &&
          <ModalFooter>
            <BBButton
              mr={3}
              variant="outline"
              onClick={cancel}
            >
              Cancel
            </BBButton>
            <BBButton
              onClick={submit}
              disabled={!validateInput || loading}
            >
              Submit
            </BBButton>
            
          </ModalFooter>
        }
      </ModalContent>
    </Modal>
  </>
}

export default FeedbackForm