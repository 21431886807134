import GroupList from "./components/GroupList";

function Groups() {
  return <GroupList
    title={"Groups"}
    captions={["Name", "Description", "Owner", "Members", "Status", "Date", ""]}
  />

}

export default Groups;
