import { Box, Button, Flex, Grid, GridItem, Image, Tab, TabList, TabPanel, TabPanels, Tabs, Text, Spacer, CircularProgress, Spinner, SimpleGrid, IconButton, Tag, Badge, useToast, Avatar, useColorModeValue, TableContainer, Table, Thead, Tr, Th, Tbody, Td, others, Accordion, AccordionItem, AccordionIcon, AccordionButton, AccordionPanel, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, AvatarGroup, UnorderedList, ListItem } from "@chakra-ui/react"
import BBButton from "components/Button/Button"
import AmbassadorCard from "components/Card/AmbassadorCard"
import GigDetailsCard from "components/Card/GigDetailsCard"
import Chat from "components/Chat/Chat"
import { CopyIcon } from "components/Icons/Icons"
import { EditIcon } from "components/Icons/Icons"
import { BeeIcon } from "components/Icons/Icons"
import { TrashIcon } from "components/Icons/Icons"
import { AppContext } from "contexts/AppContext"
import { useContext, useEffect, useState } from "react"
import { useParams, useNavigate, useLocation } from "react-router-dom"
import { getContract } from "RestAPI"
import FeedbackForm from "../../Feedback/FeedbackForm"
import { CheckIcon, CloseIcon, StarIcon } from "@chakra-ui/icons"
import { globalStyles } from "theme/styles"
import { BBSecondaryButton, BBSecondaryButtonWithoutHover } from "components/Button/Button"
import NoInvites from "assets/svg/emptyList/NoInvites.svg"
import NoGigs from "assets/svg/emptyList/NoGigs.svg"
import moment from "moment"
import { updateInviteStatus } from "RestAPI"
import { DeleteEntityModal } from "layouts/Dashboard"
import CounterCard from "components/Card/CounterCard"
import CampaignList from "views/Dashboard/Campaign/CampaignList"

const ViewContract = () => {
    const { ContractID } = useParams()
    const { placeholderImage, userData } = useContext(AppContext)
    const [contract, setContract] = useState(null)
    const [loading, setLoading] = useState(false)
    const [ambassador, setAmbassador] = useState(null)
    const navigate = useNavigate()
    const location = useLocation()
    const displayConversation = location.state?.displayConversation
    const [showAmbassadorModal, setShowAmbassadorModal] = useState(false)
    const [showIssueForm, setShowIssueForm] = useState(false)
    const [deleteModal, setDeleteModal] = useState(false)
    const [showFeedbackForm, setShowFeedbackForm] = useState(false)
    const feedbackType = userData.role.toLowerCase().includes('brand') ? 'Ambassador' : 'Contract'
    const toast = useToast()
    const textColor = useColorModeValue("black3", "white");
    const [showCampaignsModal, setShowCampaignsModal] = useState(false)
    const [campaignStatus, setCampaignStatus] = useState('')

    const fetchContractDetails = async () => {
        const response = await getContract(ContractID)
        if (response && response.invites?.length) {
            setAmbassador(response.invites[0].user)
        }
        if(userData.role !== 'AMBASSADOR'){
            response.items.forEach(item => {
                let credits = response.credits.filter(c => c.gigVariation === item.itemType).reduce((sum,a)=>sum + a.numCredits,0)
                response.minCreditsAvailable = credits <= 0 ? false: true
            })
        }
        setContract(response.id ? response : null)
    }

    const statusColor = (status) => {
        if (!status) return null
        switch (status) {
            case 'Invited': return 'statusYellow'
            case 'Draft': return 'statusGray'
            case 'Attn. Needed': return 'statusRed'
            case 'Pending Payment': return 'statusRed'
            case 'Pending Invites': return 'statusRed'
            case 'Closed': return 'secondary'
            default: return 'statusGreen'
        }
    }

    useEffect(() => {
        fetchContractDetails()
    }, [ContractID])

    const acceptInvite = (status) => {
        const invite = contract.invites[0]
        invite.inviteStatus = status
        setLoading(true);
        updateInviteStatus(contract.id, invite)
            .then(response => {
                fetchContractDetails()
                setLoading(false)
            }).catch(err => setLoading(false))
    }

    const createIssue = () => {
        navigate('/new/issue/', { state: { issueData: { contract } } })
    }

    let status = contract?.contractStatus
    let invite = contract?.invites?.length ? contract.invites[0] : null
    if(invite && status === 'Initiated'){
        status = invite.inviteStatus
    }
    
    if(contract){
        contract.createdOn = moment(contract.createdOn).format("L LT")
        contract.updatedOn = moment(contract.updatedOn).format("L LT")        
    }

    return (!ContractID || isNaN(ContractID) || !contract) ? null : <>
        {/* <IssueForm visible={showIssueForm} closeHandler={() => setShowIssueForm(false)} contract={contract} /> */}
        {deleteModal ? <DeleteEntityModal
            visible={deleteModal} 
            closeHandler={(refresh) => {
                navigate('/contracts')
                setDeleteModal(false)
            }} 
            entity='contract'
            entityId={ContractID}
        /> : null}
        <FeedbackForm visible={showFeedbackForm} closeHandler={() => { setShowFeedbackForm(false); fetchContractDetails() }} contract={contract} feedbackType={feedbackType} />
        <Flex flexDirection='column' gap='2rem'>
            <Text ml={12} fontSize={24} fontWeight='600' >Contract Overview</Text>
            <Flex id="contract-details" flexDirection={{ base: 'column'}} gap='1.5rem'>
                <Flex as='div'
                    flexDirection='column'
                    backgroundColor='white'
                    minH='700px'
                    w={{base:'100%', xl: '70%'}}
                    overflow='scroll'
                    borderRadius='3xl'
                    p={50}
                    gap='80px'
                >
                    <Flex gap='1.5rem' minW='full'>
                        {/* <Image w={150} h={150} borderRadius={10} src={contract.imageUrl} fallbackSrc={placeholderImage(150)} /> */}
                        <Flex flexDirection='column' flex={1} gap={2}>
                        <Flex direction="row">
                            <Avatar src={contract.brand?.logoUrl} w="50px" borderRadius="50px" me="18px" />
                            <Text
                                fontSize="md"
                                color={textColor}
                                fontWeight="normal"
                                minWidth="100%"
                                >
                                {contract.brand?.brandName}
                            </Text>
                        </Flex>
                            <Text fontWeight='500' fontSize='20px' color={'primary'}>{contract.title}</Text>
                            <Flex gap={4}>
                                <Badge mt={2} px={2} py={1}  bgColor={'secondary'} rounded='md' maxW='fit-content'  fontSize='md'>
                                    {contract.contractScope}
                                </Badge> 
                                {contract.contractScope === 'Exclusive' ? null : 
                                <AvatarGroup size="sm">
                                    {contract.groups.map(group =>
                                        <Avatar
                                        name={group.groupName}
                                        title = {group.groupName}
                                        key={group.id}
                                        src={group.imageUrl}
                                        _hover={{ zIndex: "3"}}
                                        />
                                    )}
                                </AvatarGroup>}
                            </Flex>
                            {/* {userData.role === 'AMBASSADOR' ? null : <Flex gap={2}>
                                <Text color={'gray3'}>Budget:</Text>
                                <Text color={'primary'}>${contract.amountBrand}</Text>
                            </Flex>}
                            <Flex gap={2}>
                                <Text color={'gray3'}>Ambassador Amount:</Text>
                                <Text color={'primary'}>${contract.amountAmbassador}</Text>
                            </Flex> */}
                            <Badge mt={2} px={2} py={1}  bgColor={'gray.300'} rounded='md' maxW='fit-content'  fontSize='sm'>
                                {['Initiated','Draft'].includes(contract.contractStatus) ? 'Starts ' + moment(contract.startDate).fromNow() : contract.contractStatus === 'Active' ? 'Ends ' + moment(contract.endDate).fromNow() : 'Ended on ' + moment(contract.endDate).format('MM-DD-YYYY')}
                                {/* {contract.duration} {contract.durationUnit} */}
                            </Badge>
                            {userData.role === 'AMBASSADOR' ? null : <Flex mt={2} gap={4}>
                                <Text mt={2}>
                                    Min Credit Status
                                </Text>
                                <Flex flexDirection={'column'}>
                                    {!!contract.minCreditsAvailable ? <IconButton colorScheme={'green'} icon={<CheckIcon/>}></IconButton> : 
                                    <BBButton onClick={() => navigate('/new/credit?type=Exclusive')} bgColor={'red'}>Buy Credits</BBButton>}
                                    {/* <IconButton colorScheme={'red'} icon={<CloseIcon/>}></IconButton> */}
                                    
                                </Flex>
                            </Flex>}
                            <Badge mt={2} px={2} py={1} bgColor={() => statusColor(status)} rounded='md' maxW='fit-content' fontSize='md'>
                                {status}
                            </Badge>
                        </Flex>

                        <Flex justifySelf='flex-end' justifyContent='flex-end' flex={1} gap={2}>
                            <Flex flexDirection='column' gap='2rem'>
                                <Flex gap={2} justify='flex-end' align='center'>
                                
                                    {
                                        userData.role === 'AMBASSADOR' && contract.contractStatus === 'Initiated'
                                            ?
                                            <>
                                                {loading ? <Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='brandbassBlue' size='xl' /> : <> <Button disabled={status === 'Accepted'} float="right" onClick={() => acceptInvite('Accepted')}>Accept </Button>
                                                    <Button disabled={status === 'Declined'} float="right" onClick={() => acceptInvite('Declined')}>Decline </Button> </>}
                                            </>
                                            : null
                                    }

                                    {
                                        ((userData.role === 'BRAND_MANAGER' && userData.id === contract.createdBy.id) || userData.role === 'BRAND_SUPER_ADMIN' || userData.role === 'CLIENT_SUPER_ADMIN') && (contract.contractStatus === 'Initiated' || contract.contractStatus === 'Draft')
                                            ?
                                            <>
                                                {loading ? <Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='brandbassBlue' size='xl' /> : <>
                                                    {contract.payment?.length ? null :
                                                        <IconButton onClick={() => setDeleteModal(true)}
                                                            variant='outline' size="md"
                                                            colorScheme='teal'
                                                            aria-label='Send email'
                                                            icon={<TrashIcon />}
                                                        />}
                                                    <IconButton onClick={() => navigate(`/edit/contract/${contract.id}`)}
                                                        variant='outline' size="md"
                                                        colorScheme='teal'
                                                        aria-label='Send email'
                                                        icon={<EditIcon />}
                                                    />
                                                </>
                                                }
                                            </>
                                            : null
                                    }
                                    {/* <Flex gap='0.25rem' flex={1} justifyContent='flex-end'> */}
                                   {contract.contractStatus === 'Expired' ? null : <IconButton onClick={createIssue}
                                        variant='outline' size="md"
                                        colorScheme='teal'
                                        aria-label='Send email'
                                        icon={<BeeIcon />}
                                    />}
                                </Flex>
                                {userData.role === 'AMBASSADOR' ? null : <Flex flexWrap='wrap' gap='1rem' alignSelf={'start'}>
                                    <AmbassadorCard invite={contract.invites[0]} colorIndex={0} />
                                </Flex>}
                                
                            </Flex>
                        </Flex>
                    </Flex>
                    <Tabs isLazy defaultIndex={displayConversation?4:0}>
                        <TabList pb='5px' borderColor='black'>
                            <Tab fontWeight='700' textTransform='uppercase' pt='8px' pb='5px' h='30px' borderRadius='10px' _selected={{ color: 'white', bg: 'primary' }}>Info</Tab>
                            <Tab fontWeight='700' textTransform='uppercase' pt='8px' pb='5px' h='30px' borderRadius='10px' _selected={{ color: 'white', bg: 'primary' }}>Milestones</Tab>
                            <Tab fontWeight='700' textTransform='uppercase' pt='8px' pb='5px' h='30px' borderRadius='10px' _selected={{ color: 'white', bg: 'primary' }}>Attachments</Tab>
                            <Tab isDisabled={!contract.campaigns} fontWeight='700' textTransform='uppercase' pt='8px' pb='5px' h='30px' borderRadius='10px' _selected={{ color: 'white', bg: 'primary' }}>Campaigns</Tab>
                            <Tab isDisabled={!ambassador} fontWeight='700' textTransform='uppercase' pt='8px' pb='5px' h='30px' borderRadius='10px' _selected={{ color: 'white', bg: 'primary' }}>Chat</Tab>
                        </TabList>
                        <TabPanels pt='32px'>
                            <TabPanel>
                                <Flex flexDirection={'column'} >
                                    <Flex flexDirection='column' boxShadow='base' py={3} px={2} gap='0.5rem'>
                                        <Flex gap={2}>
                                            <Text fontSize={14} color={'gray3'}>Revision:</Text>
                                            <Spacer/>
                                            <Text color={'primary'}>{contract.revision}</Text>
                                        </Flex>
                                        <Flex gap={2}>
                                            <Text fontSize={14} color={'gray3'}>Scope:</Text>
                                            <Spacer/>
                                            <Text color={'primary'}>{contract.contractScope}</Text>
                                        </Flex>
                                        <Flex gap={2}>
                                            <Text fontSize={14} color={'gray3'}>Groups</Text>
                                            <Spacer/>
                                            <Text color={'primary'}>{contract.groups.reduce((a,b) => a + ', ' + b.groupName, '')}</Text>
                                        </Flex>
                                        <Flex gap={2}>
                                            <Text fontSize={14} color={'gray3'}>Start Date</Text>
                                            <Spacer/>
                                            <Text color={'primary'}>{moment(contract.startDate).format('MM-DD-YYYY')}</Text>
                                        </Flex>
                                        <Flex gap={2}>
                                            <Text fontSize={14} color={'gray3'}>End Date:</Text>
                                            <Spacer/>
                                            <Text color={'primary'}>{moment(contract.endDate).format('MM-DD-YYYY')}</Text>
                                        </Flex>
                                        <Flex gap={2}>
                                            <Text fontSize={14} color={'gray3'}>Invite Expires On:</Text>
                                            <Spacer/>
                                            <Text color={'primary'}>{moment(contract.invites[0]?.expiryDate).format('MM-DD-YYYY')}</Text>
                                        </Flex>
                                        <Flex gap={2}>
                                            <Text fontSize={14} color={'gray3'}>Created On:</Text>
                                            <Spacer/>
                                            <Text color={'primary'}>{moment.utc(contract.createdOn+'+00:00').local().format('DD MMM YYYY hh:mm A')}</Text>
                                        </Flex>
                                        <Flex gap={2}>
                                            <Text fontSize={14} color={'gray3'}>Last Updated:</Text>
                                            <Spacer/>
                                            <Text color={'primary'}>{moment.utc(contract.updatedOn+'+00:00').local().format('DD MMM YYYY hh:mm A')}</Text>
                                        </Flex>
                                    </Flex>
                                </Flex>
                            </TabPanel>
                            {/* {userData.role === 'AMBASSADOR' ? null : 
                            <TabPanel>
                                <Flex flexDirection={'column'} alignItems={'center'}>
                                    <Flex flexWrap='wrap' gap='1rem' alignSelf={'start'}>
                                        {contract.invites?.length ? contract.invites.map((item, idx) => 
                                        <AmbassadorCard key={idx} invite={item} colorIndex={Math.abs(3 - idx)} />) 
                                        : null }
                                        {!contract.invites?.length ? 
                                        <Flex flexDirection='column' gap={2} borderColor='primary' borderWidth='2px' borderStyle='solid' p='2rem' align='center' rounded='sm'>
                                            <Image src={NoInvites} w='200px' />
                                            <Text color='primary' fontSize='xl' fontWeight='500'>No invites at the moment</Text>
                                            {contract.payment?.length ? <BBButton onClick={() => setShowAmbassadorModal(true)}>Invite ambassadors</BBButton> : null}
                                        </Flex> : null}
                                    </Flex>
                                    {contract.payment?.length && contract.invites?.length && !contract.invites?.filter(inv => inv.inviteStatus !== 'Rejected')?.length ? 
                                    <BBButton mt={4} onClick={() => setShowAmbassadorModal(true)}>Invite ambassadors</BBButton> : null}
                                    
                                </Flex>
                            </TabPanel>} */}
                            <TabPanel>
                                <Flex flexDirection={'column'}>
                                    <TableContainer>
                                        <Table size='sm'>
                                        <Thead>
                                            <Tr>
                                            <Th>TYPE</Th>
                                            <Th>AGREED</Th>
                                            <Th>BOUGHT</Th>
                                            <Th>USED</Th>
                                            <Th>COST</Th>
                                            <Th>TOTAL</Th>
                                            </Tr>                    
                                        </Thead>
                                        <Tbody>
                                        {contract.items.map(item => {
                                            return (
                                            <Tr key={item.id}>
                                                <Td>{item.itemType}</Td>
                                                <Td>{item.minQuantity}</Td>
                                                <Td>{item.boughtCredits}</Td>
                                                <Td>{item.usedCredits || 0}</Td>
                                                <Td>${item.cost}</Td>
                                                <Td>${item.cost * item.usedCredits || 0}</Td>
                                            </Tr>
                                            )
                                        })}
                                        </Tbody>
                                        </Table>
                                    </TableContainer>
                                    <Flex mt={8} flexDirection='column'>
                                        <Text fontWeight={'bold'}>Budget Summary</Text>
                                        <Flex>
                                            <Flex gap={4} ml={4}>
                                                <Text fontWeight={'400'}>Total agreed:</Text>
                                                <Text>${contract.items.reduce((sum,a) => sum+a.minQuantity * a.cost,0)}</Text>
                                            </Flex>
                                            <Flex gap={4} ml={4}>
                                                <Text fontWeight={'400'}>Total bought:</Text>
                                                <Text>${contract.items.reduce((sum,a)=>sum+(a.boughtCredits?a.boughtCredits:0) * a.cost, 0)}</Text>
                                            </Flex>
                                            <Flex gap={4} ml={4}>
                                                <Text fontWeight={'400'}>Total used:</Text>
                                                <Text>${contract.items.reduce((sum,a) => sum+(a.usedCredits?a.usedCredits:0) * a.cost,0)}</Text>
                                            </Flex>
                                            {/* <Flex gap={4} ml={4}>
                                                <Text fontWeight={'400'}>Total Balance:</Text>
                                                <Text>${contract.items.reduce((sum,a) => sum+a.minQuantity * a.cost,0)}</Text>
                                            </Flex> */}
                                        </Flex>
                                    </Flex>
                                </Flex>
                            </TabPanel>
                            <TabPanel>
                            <Flex flexDirection='column' gap='1rem'>
                                <Accordion allowMultiple>
                                    <AccordionItem>
                                        <AccordionButton>
                                            <Box flex='1' textAlign='left'>
                                                <Text>TODOs</Text>
                                            </Box>
                                            <AccordionIcon/>
                                        </AccordionButton>
                                        <AccordionPanel>
                                        {contract.todos.map(item => {
                                            return (
                                                <UnorderedList>
                                                    <ListItem>{item.name}</ListItem>
                                                </UnorderedList>
                                            )
                                        })}
                                        </AccordionPanel>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionButton>
                                            <Box flex='1' textAlign='left'>
                                                <Text>NOT TODOs</Text>
                                            </Box>
                                            <AccordionIcon/>
                                        </AccordionButton>
                                        <AccordionPanel>
                                        {contract.notTodos.map(item => {
                                            return (
                                                <UnorderedList gap={4} justifyContent={'space-between'}>
                                                    <ListItem>{item.name}</ListItem>
                                                </UnorderedList>
                                            )
                                        })}
                                        </AccordionPanel>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionButton>
                                            <Box flex='1' textAlign='left'>
                                                <Text>ATTACHMENTS</Text>
                                            </Box>
                                            <AccordionIcon/>
                                        </AccordionButton>
                                        <AccordionPanel>
                                        {contract.attachments.map(a => {
                                            return (
                                                <UnorderedList gap={4} justifyContent={'space-between'}>
                                                   <ListItem> {a.signedUrlRead ? <a style={{textDecoration:'underline', color:`${globalStyles.colors.secondary}`, fontSize:14}} href={a.signedUrlRead} target='_blank'>{a.fileName}</a> : <Text fontSize={14}>{a.fileName}</Text>}
                                                   </ListItem>
                                                </UnorderedList>
                                            )
                                        })}
                                        </AccordionPanel>
                                    </AccordionItem>
                                </Accordion>
                            </Flex>
                            </TabPanel>
                            <TabPanel>
                                <Flex gap={2}>
                                    <CounterCard title={"Total"} width={120} count={contract.campaigns?.length || 0} clickHandler={() => {setCampaignStatus('');setShowCampaignsModal(true)}}/>
                                    <CounterCard title={"Pending"} width={120} count={contract.campaigns?.filter(c=>c.campaignStatus === 'Created')?.length || 0}  clickHandler={() => {setCampaignStatus('Created');setShowCampaignsModal(true)}}/>
                                    <CounterCard title={"Active"} width={120} count={contract.campaigns?.filter(c=>c.campaignStatus === 'Active')?.length || 0}  clickHandler={() => {setCampaignStatus('Active');setShowCampaignsModal(true)}}/>
                                    <CounterCard title={"Done"} width={120} count={contract.campaigns?.filter(c=>c.campaignStatus === 'Closed')?.length || 0}  clickHandler={() => {setCampaignStatus('Closed');setShowCampaignsModal(true)}}/>
                                </Flex>
                            </TabPanel>
                            <TabPanel>
                                <Chat conversationId={contract.conversation?.id} moduleId={contract.id} module={'contract'} moduleName={contract.title} receiver={ambassador} width={500} height={600}/>
                            </TabPanel> 
                        </TabPanels>
                    </Tabs>
                </Flex>
            </Flex>
            {showCampaignsModal ? 
            <Modal isOpen={showCampaignsModal} onClose={() => setShowCampaignsModal(false)} closeHandler={() => setShowCampaignsModal(false)} size={'8xl'}>
                <ModalOverlay/>
                <ModalContent>
                    <ModalHeader color="brandbassBlue"/>
                    <ModalCloseButton/>
                    <ModalBody flexDirection={'row'} pb={6}>
                        <CampaignList hideOverview={true} filter={{status:campaignStatus,userId: ambassador?.id, role: 'ambassadors', ambassadorFlag: 'Exclusive', createdOn: {start: contract.startDate, end: contract.endDate}}}></CampaignList>
                    </ModalBody>
                </ModalContent>
            </Modal> : null}
        </Flex>
    </>
}

export default ViewContract
