// Chakra imports
import { EditIcon, ViewIcon } from "@chakra-ui/icons";
import {
  Text,
  Box, Tag, TagLabel,
  SkeletonCircle, SkeletonText,
  useColorModeValue,
  Modal, IconButton,
  useDisclosure,
  Flex,
  AvatarGroup,
  Avatar, Spacer
} from "@chakra-ui/react";
import ReactDataGrid from "@inovua/reactdatagrid-community";
// Custom components
import Card from "components/Card/Card.js";
import CounterCard from "components/Card/CounterCard.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import { TrashIcon } from "components/Icons/Icons";
import moment from "moment";
import React, { useEffect, useState, useContext } from "react";
import { findAllSubscriptions } from "RestAPI";
import { checkboxColumn } from "views/Dashboard/Campaign/CampaignList";
import { AppContext } from "contexts/AppContext";
import BBButton from "components/Button/Button";
import { FaPlus } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { DeleteEntityModal } from "layouts/Dashboard";

const SubscriptionList = ({ title }) => {
  const navigate = useNavigate()
  const { hasEditPermission, hasCreatePermission, userData } = useContext(AppContext)
  const textColor = useColorModeValue("black3", "white");
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [selectedRow, setSelectedRow] = useState({})
  const [loading, setLoading] = useState(true)
  const [overView, setOverview] = useState(null)
  const editItem = (row) => {
    navigate(`/edit/subscription/${row.id}`)
  }
  const [subscriptionData, setSubscriptionData] = useState(null)
  const [deleteModal, setDeleteModal] = useState(null)
  const [entityId, setEntityId] = useState(null)
  
  const deleteItem = (row) => {
    setEntityId(row.id)
    setDeleteModal(true)
  }

  const refreshList = () => {
    setLoading(true)
    findAllSubscriptions().then(response => {
      setLoading(false);
      if (Array.isArray(response) && response.length) {
        setSubscriptionData(response)
        setOverview({
          totalSubscriptions: response.length,
          activeSubscriptions: response.filter(item => item.subscriptionStatus === 'Active'),
          inactiveSubscriptions: response.filter(item => item.subscriptionStatus === 'Inactive'),
        })
      }
    }).catch(err => { setLoading(false); console.error(err) })
  }

  const columns = [
    {
      name: "id",
      type: "number",
      header: <Text color="gray3" fontSize="xs" textTransform='uppercase'>ID</Text>,
      defaultVisible: true,
      defaultFlex: 1,
      maxWidth: 60,
      sortable: true,
      render: ({ value }) => (
        <Text color="black3" fontSize='md' fontWeight="normal">
          {value}
        </Text>
      ),
    },
    {
      name: "brand",
      type: "string",
      header: <Text color="gray3" fontSize="xs" textTransform='uppercase'>Brand</Text>,
      defaultVisible: true,
      defaultFlex: 1,
      defaultWidth: 100,
      sortable: true,
      filterEditorProps: {
        style: {
          background: '#fefefe',
        },
        placeholder: 'Search by name...'
      },
      render: ({ value }) => (
        <Text color="black3" fontSize='md' fontWeight="normal">
          {value.brandName}
        </Text>
      ),
    },
    {
      name: "name",
      header: <Text color="gray3" fontSize="xs" textTransform='uppercase'>Tier</Text>,
      defaultVisible: true,
      defaultFlex: 1,
      maxWidth: 100,
      sortable: false,
      render: ({ value }) => (
        <Text color="black3" fontSize='md' fontWeight="normal">
          {value}
        </Text>
      ),
    },
    {
      name: "subscriptionStatus",
      header: <Text color="gray3" fontSize="xs" textTransform='uppercase'>STATUS</Text>,
      defaultVisible: true,
      defaultFlex: 1,
      maxWidth: 160,
      sortable: true,
      render: ({ value }) => {
        return (
          < Tag textAlign={'center'} minW={28} backgroundColor={value === 'Active'?'statusGreen':'statusYellow'}><Spacer /><TagLabel textAlign={'center'}>{value}</TagLabel><Spacer /></Tag>
        )
      },
    },
    {
      name: "totalMonthlyAmount",
      type: "date",
      header: <Text color="gray3" fontSize="xs" textTransform='uppercase'>Monthly Amount</Text>,
      defaultVisible: true,
      defaultFlex: 1,
      maxWidth: 180,
      sortable: true,
      render: ({ value }) => (
        <Text color="black3" fontSize='md' fontWeight="normal">
          ${value/100}
        </Text>
      ),
    },
    {
      name: "startDate",
      type: "date",
      header: <Text color="gray3" fontSize="xs" textTransform='uppercase'>Started On</Text>,
      defaultVisible: true,
      defaultFlex: 1,
      maxWidth: 120,
      sortable: true,
      render: ({ value }) => (
        <Text color="black3" fontSize='md' fontWeight="normal">
          {moment(value).format('MM-DD-YYYY')}
        </Text>
      ),
    },
    {
      name: "renewalDate",
      type: "date",
      header: <Text color="gray3" fontSize="xs" textTransform='uppercase'>Renewal On</Text>,
      defaultVisible: true,
      defaultFlex: 1,
      maxWidth: 140,
      sortable: true,
      render: ({ value }) => (
        <Text color="black3" fontSize='md' fontWeight="normal">
          {value ? moment(value).format('MM-DD-YYYY') : ''}
        </Text>
      ),
    },
    {
      name: "customSubscription",
      type: "date",
      header: <Text color="gray3" fontSize="xs" textTransform='uppercase'>Custom</Text>,
      defaultVisible: true,
      defaultFlex: 1,
      maxWidth: 120,
      sortable: true,
      render: ({ value }) => (
        <Text color="black3" fontSize='md' fontWeight="normal">
          {value ? 'Yes' : 'No'}
        </Text>
      ),
    },
    {
      name: "enableEnterpriseFeatures",
      type: "date",
      header: <Text color="gray3" fontSize="xs" textTransform='uppercase'>Enterprise features</Text>,
      defaultVisible: true,
      defaultFlex: 1,
      maxWidth: 160,
      sortable: true,
      render: ({ value }) => (
        <Text color="black3" fontSize='md' fontWeight="normal">
          {value ? 'Yes' : 'No'}
        </Text>
      ),
    },
  ]
  if(userData?.role?.includes('ADMIN')) {
    columns.push(
      {
        header: <Text color="gray3" textAlign='center'>ACTION</Text>,
        defaultVisible: true,
        defaultFlex: 1,
        defaultWidth: 30,
        textAlign: 'center',
        sortable: false,
        render: ({ data }) => <Flex gap='0.25rem' justifyContent="center" alignItems="center" >
          <IconButton onClick={() => editItem(data)}
            variant='outline' size="xs"
            colorScheme='teal' borderRadius={"50%"}
            aria-label='Edit item'
            icon={<EditIcon />}
          />
          {/* <IconButton onClick={() => deleteItem(data) }
            disabled={data.isDefault}
            variant='outline' size="xs"
            colorScheme='teal' borderRadius={"50%"}
            aria-label='Delete item'
            icon={<TrashIcon />}
          />
          /> */}
        </Flex>
      },
    )
  }
  columns.forEach(item => item.style = { borderLeftColor: 'white', borderRightColor: 'white', borderTopColor: 'white', borderBottomColor: 'gray.200' })


  useEffect(refreshList, [])

  return (
    <>
      <DeleteEntityModal 
        visible={deleteModal} 
        closeHandler={(refresh) => {
          if(!!refresh) refreshList()
          setDeleteModal(false)
        }} 
        entity='subscription'
        entityId={entityId}
      />
      <Card id="subscriptions-list" mt='5px' p='16px' overflowX={{ sm: "scroll" }} boxShadow='0px 0px 20px 1px rgb(0 0 0 / 10%)' borderRadius='10px'>
        <CardHeader p='12px 0px 28px 0px'>
          <Flex flex={1} align="center" gap='2rem' w="100%" ml={2} mr={2}>
            <Flex direction='column' gap='2rem' w="100%">
              <Flex gap='1rem' flexDirection='column'>
                <Text fontSize='30px' color={textColor} fontWeight='bold' pb='.5rem'>
                  {title}
                </Text>
                <Spacer />
                {
                   userData.role !== 'SUPER_ADMIN'
                   ? null :
                  <BBButton shadow={true} maxW='fit-content' size="sm" leftIcon={<FaPlus />} colorScheme='brandbassBlue' color="white" onClick={() => navigate('/new/subscription')}>
                    Subscription
                  </BBButton>
                }
              </Flex>
              <Flex gap='20px' p='10px' alignItems='center' borderRadius='10px' >
                {
                  loading && (
                    <Flex align="center" minWidth="100%" flexWrap="nowrap">
                      <Box padding='6' boxShadow='lg' minWidth="100%">
                        <SkeletonText mt='4' noOfLines={4} spacing='4' />
                      </Box>
                    </Flex>
                  )
                }
                {!loading && (<>
                  <CounterCard title={"Total"} width={250} count={overView ? overView.totalSubscriptions : 0} />
                  <CounterCard title={"Active"} width={250} count={overView ? overView.activeSubscriptions?.length : 0} />
                  <CounterCard title={"Inactive"} width={250} count={overView ? overView.inactiveSubscriptions?.length : 0} />
                </>)}
              </Flex>
            </Flex>
          </Flex>
        </CardHeader>
        <CardBody  >
          {
            loading && (
              <Flex align="center" minWidth="100%" flexWrap="nowrap">
                <Box padding='6' boxShadow='lg' minWidth="100%">
                  <SkeletonCircle size='20' />
                  <SkeletonText mt='4' noOfLines={4} spacing='4' />
                </Box>
              </Flex>
            )
          }
          {!loading && (
            <ReactDataGrid
              loading={subscriptionData === null}
              className="brandbass-data-grid"
              style={{ minHeight: 860, borderColor: 'white' }}
              idProperty="id"
              columns={columns}
              dataSource={subscriptionData || []}
              pagination={true}
              defaultLimit={10}
              defaultSortInfo={{ id: "id", name: 'id', dir: -1, type: 'number' }}
              checkboxColumn={checkboxColumn}
              checkboxOnlyRowSelect={true}
              rowHeight={70}
              defaultLoading={loading}
              headerHeight={60}
              showZebraRows={false}
              enableSelection={true}
              showHoverRows={true}
              showCellBorders="horizontal"
              enableColumnAutosize={true}
              enableColumnFilterContextMenu={false}
              emptyText="No subscriptions"
            />
          )}
        </CardBody>
      </Card>
    </>
  );
};

export default SubscriptionList;
