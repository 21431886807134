// Chakra imports
import { ViewIcon } from "@chakra-ui/icons";
import {
  Text,
  Box, Tag, TagLabel,
  useColorModeValue, SkeletonCircle, SkeletonText,
  Modal, IconButton,
  Flex,
  Avatar, Spacer
} from "@chakra-ui/react";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import moment from "moment";
import React, { useEffect, useState, useContext } from "react";
import { getCredits } from "RestAPI";
import { checkboxColumn } from "views/Dashboard/Campaign/CampaignList";
import { globalStyles } from "theme/styles";
import { useNavigate } from "react-router-dom";
import ShowUserForm from 'views/Dashboard/Users/components/ShowUser'
import { AppContext } from "contexts/AppContext";
import BBButton from "components/Button/Button"
import { FaPlus } from "react-icons/fa"
import "./Credit.css"

const CreditsList = ({ title, captions }) => {
  const navigate = useNavigate()
  const { hasCreatePermission, userData } = useContext(AppContext)
  const [loading, setLoading] = useState(true)
  const [credits, setCredits] = useState([])
  const textColor = useColorModeValue("black3", "white");

  const refreshList = () => {
    setLoading(true)
    getCredits().then(response => {
      setLoading(false);
      if (response && Array.isArray(response.data) && !!response.data.length) {
        response.data = response.data.map(credit => {
          credit.gigName = credit.gig ? credit.gig.gigName : ' '
          credit.campaignName = credit.gig && credit.gig.campaign ? credit.gig.campaign.name : ' '
          return credit
        })
        setCredits(response.data)
      }
    }).catch(err => { setLoading(false); console.error(err) })
  }

  const columns = [
    {
      name: "id",
      type: "number",
      header: <Text color="gray3" textTransform='uppercase'>ID</Text>,
      defaultVisible: false,
      defaultFlex: 1,
      defaultWidth: 100,
      sortable: true,
      render: ({ value }) => (
        <Text color="black3" fontSize='md' fontWeight="normal">
          {value}
        </Text>
      ),
    },
    {
      name: "brand",
      type: "string",
      header: <Text color="gray3" textTransform='uppercase'>Brand</Text>,
      defaultVisible: userData.role === 'SUPER_ADMIN' ? true : false,
      defaultFlex: 1,
      defaultWidth: 100,
      sortable: true,
      render: ({ value }) => (
        <Text color="black3" fontSize='md' fontWeight="normal">
          {value.brandName}
        </Text>
      ),
    },
    {
      name: "creditType",
      type: "string",
      header: <Text color="gray3" textTransform='uppercase'>Credit Type</Text>,
      defaultVisible: true,
      defaultFlex: 1,
      defaultWidth: 100,
      sortable: true,
      render: ({ value }) => (
        <Text color="black3" fontSize='md' fontWeight="normal">
          {value}
        </Text>
      )
    },
    {
      name: "campaignType",
      type: "string",
      header: <Text color="gray3" textTransform='uppercase'>Campaign Type</Text>,
      defaultVisible: true,
      defaultFlex: 1,
      defaultWidth: 100,
      sortable: true,
      render: ({ value }) => (
        <Text color="black3" fontSize='md' fontWeight="normal">
          {value}
        </Text>
      )
    },
    {
      name: "gigVariation",
      type: "string",
      header: <Text color="gray3" textTransform='uppercase'>Gig Variation</Text>,
      defaultVisible: true,
      defaultFlex: 1,
      defaultWidth: 100,
      sortable: true,
      render: ({ value }) => (
        <Text color="black3" fontSize='md' fontWeight="normal">
          {value}
        </Text>
      )
    },
    {
      name: "gigVariationType",
      type: "string",
      header: <Text color="gray3" textTransform='uppercase'>Gig Variation Type</Text>,
      defaultVisible: true,
      defaultFlex: 1,
      defaultWidth: 100,
      sortable: true,
      render: ({ value }) => (
        <Text color="black3" fontSize='md' fontWeight="normal">
          {value}
        </Text>
      )
    },
    {
      name: "numCredits",
      type: "string",
      header: <Text color="gray3" textTransform='uppercase'>No Of Credits</Text>,
      defaultVisible: true,
      defaultFlex: 1,
      defaultWidth: 100,
      sortable: true,
      render: ({ value }) => (
        <Text color="black3" fontSize='md' fontWeight="normal">
          {value}
        </Text>
      )
    },
    {
      name: "gigName",
      type: "string",
      header: <Text color="gray3" textTransform='uppercase'>Gig Name</Text>,
      defaultVisible: true,
      defaultFlex: 1,
      defaultWidth: 100,
      sortable: true,
      render: ({ value }) => (
        <Text color="black3" fontSize='md' fontWeight="normal">
          {value}
        </Text>
      )
    },
    {
      name: "campaignName",
      type: "string",
      header: <Text color="gray3" textTransform='uppercase'>Campaign Name</Text>,
      defaultVisible: true,
      defaultFlex: 1,
      defaultWidth: 100,
      sortable: true,
      render: ({ value }) => (
        <Text color="black3" fontSize='md' fontWeight="normal">
          {value}
        </Text>
      )
    },
    {
      name: "contract",
      type: "string",
      header: <Text color="gray3" textTransform='uppercase'>Contract Name</Text>,
      defaultVisible: true,
      defaultFlex: 1,
      defaultWidth: 100,
      sortable: true,
      render: ({ value }) => (
        <Text color="black3" fontSize='md' fontWeight="normal">
          {value?.title}
        </Text>
      )
    },
    {
      name: "ambassador",
      type: "string",
      header: <Text color="gray3" textTransform='uppercase'>Ambassador</Text>,
      defaultVisible: true,
      defaultFlex: 1,
      defaultWidth: 100,
      sortable: true,
      render: ({ value }) => (
        <Text color="black3" fontSize='md' fontWeight="normal">
          {value?.firstName} {value?.lastName}
        </Text>
      )
    },
    {
      name: "createdOn",
      type: "date",
      header: <Text color="gray3" textTransform='uppercase'>Created on</Text>,
      defaultVisible: true,
      defaultFlex: 1,
      maxWidth: 160,
      sortable: true,
      render: ({ value }) => (
        <Text color="black3" fontSize='md' fontWeight="normal">
          {moment(value).format('MM-DD-YYYY')}
        </Text>
      ),
    }
  ]

  columns.forEach(item => item.style = { borderLeftColor: 'white', borderRightColor: 'white', borderTopColor: 'white', borderBottomColor: 'gray.200' })

  useEffect(refreshList, [])

  return (
    <>
      <Card id="credits-list" mt='5px' p='16px' overflowX={{ sm: "scroll" }} boxShadow='0px 0px 20px 1px rgb(0 0 0 / 10%)' borderRadius='10px'>
        <CardHeader p='12px 0px 28px 0px'>
          <Flex flex={1} align="center" gap='2rem' w="100%" ml={2} mr={2}>
            <Flex direction='column' gap='2rem' w="100%">
              <Flex gap='1rem' flexDirection='column'>
                <Text fontSize='30px' color={textColor} fontWeight='bold' pb='.5rem'>
                  Credit Transactions
                </Text>
                {
                  !loading && credits && credits.length == 0 && <>
                  <Text fontStyle='italic' fontWeight='600' fontSize='16px' maxW='540px'>This is your credits view, where you can edit and create new credits. Let's start with your first credit!</Text>
                  </>
                }
                <Spacer />
                {
                  !!hasCreatePermission && ['SUPER_ADMIN','BRAND_SUPER_ADMIN'].includes(userData.role)  && 
                  <BBButton shadow={true} maxW='fit-content' size="sm" leftIcon={<FaPlus />} colorScheme='brandbassBlue' color="white" onClick={() => navigate('/new/credit')}>
                    CREDIT
                  </BBButton>
                }
              </Flex>
            </Flex>
          </Flex>
        </CardHeader>
        <CardBody >
          {
            loading && (
              <Flex align="center" minWidth="100%" flexWrap="nowrap">
                <Box padding='6' boxShadow='lg' minWidth="100%">
                  <SkeletonCircle size='20' />
                  <SkeletonText mt='4' noOfLines={4} spacing='4' />
                </Box>
              </Flex>
            )
          }
          {!loading && (
            <ReactDataGrid
              loading={credits === null}
              className="brandbass-data-grid"
              style={{ minHeight: 860, borderColor: 'white' }}
              idProperty="id"
              columns={columns}
              dataSource={credits || []}
              pagination={true}
              defaultLimit={10}
              defaultLoading={loading}
              defaultSortInfo={{ id: "id", name: 'id', dir: -1, type: 'number' }}
              checkboxColumn={checkboxColumn}
              checkboxOnlyRowSelect={true}
              rowHeight={70}
              headerHeight={60}
              showZebraRows={false}
              enableSelection={true}
              showHoverRows={true}
              showCellBorders="horizontal"
              enableColumnAutosize={true}
              enableColumnFilterContextMenu={false}
              emptyText="No Credits"
            />
          )}
        </CardBody>
      </Card>

    </>
  );
};

export default CreditsList;
