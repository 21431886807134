export const customThemeStyles = {
    //use variantColor or colorscheme
    components: {
      Checkbox: {
        baseStyle: {
          control:{
              borderColor: 'brandbassBlue.600'
            }
          },
          defaultProps:{
            colorScheme:'brandbassBlue'
          }
      },
      Radio: {
        baseStyle: {
          control:{
              borderColor: 'brandbassBlue.600'
            }
          },
          defaultProps:{
            colorScheme:'brandbassBlue'
          }
      },
      Text:{
        variants: {
          "bb-status":{
            fontSize:'14px' ,
            lineHeight:'20px' ,
            fontWeight:'400', padding:'5px'
          },
          "bb-inputLabel":{
            lineHeight:'22px',
            fontWeight:'bold',
            fontSize:'16px',
            padding:'5px 2px'
          },
          "notification-text":{
            fontSize:'16px',
            lineHeight:'24px',
            fontWeight:'400',
            paddingLeft: '5px'
          },
          "notification-label":{
            fontSize:'16px',
            lineHeight:'24px',
            fontWeight:'600',
            paddingLeft: '5px',
            fontStyle:"italic"
          },
          "image-label":{
            fontWeight: 'bold',
            left: '20px',
            position: 'absolute',
            bottom: '30px',
            color: 'white',
            fontSize: '25px',
            lineHeight:'24px'
          }
        }
      }
    }
  };
  