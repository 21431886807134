/*eslint-disable*/
import React from "react";
import PropTypes from "prop-types";
import { Input, Text,FormControl,FormLabel } from '@chakra-ui/react'


export default function BBInput({ height, padding, paddingLeft,labelFontWeight, ...props }) {
  return (
    <>
      <FormControl {...(props.required && { isRequired: props.required })} >
        {props.label && <FormLabel lineHeight='22px'
          fontWeight={labelFontWeight ? labelFontWeight : 'bold'}
          fontSize='16px'
          padding='5px 2px'>{props.label}</FormLabel>}
        <Input type={props.type ? props.type : "text"} placeholder={props.placeholder ? props.placeholder : ''}
          height={height ? height : '50px'}
          padding={padding ? padding : '16px 0px'}
          paddingLeft={paddingLeft ? paddingLeft : '20px'}
          borderColor={props.status ? props.status : 'inherit'}
          border={props.status && '2px solid'}
          errorBorderColor={"#EB5757"}
          {...props}
        />
      </FormControl>
      {props.isInvalid && props.required && <Text color={'#EB5757'} variant="bb-status">Please enter the value</Text>}
      {props.message && props.status && <Text color={props.status} variant="bb-status">{props.message}</Text>}
    </>
  );
}
