// Chakra imports
import UserList from "./components/UserList";

function Users() {
  return <UserList
    title={"Users"}
  />
}

export default Users;
