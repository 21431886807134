// Chakra imports
import {
  Box,
  Button,
  Flex,
  Grid,
  Image,
  SimpleGrid,
  Text,
  HStack,
  Spinner,
  Divider,
  useColorModeValue,
  Skeleton, SkeletonCircle, SkeletonText,
  Spacer, Icon, useToast
} from "@chakra-ui/react";
// assets
import peopleImage from "assets/img/people-image.png";
import logoChakra from "assets/svg/logo-white.svg";
import AmbassadorCard from "components/Card/AmbassadorCard";
import BarChart from "components/Charts/BarChart";
import LineChart from "components/Charts/LineChart";
// Custom icons
import {
  CartIcon,
  DocumentIcon,
  GlobeIcon,
  WalletIcon,
} from "components/Icons/Icons.js";
import BBNotificationCard from "components/NotificationCard/NotificationCard";
import { AppContext } from "contexts/AppContext";
import React, { useContext, useEffect, useState } from "react";
import { getUserRecommendation, getDashboardMetrics } from "RestAPI";
import { dashboardTableData, timelineData } from "variables/general";
import MatchProfileForm from "../Campaign/MatchProfile";
import { VerticalSeparator } from "../Campaign/CampaignOverview";
import ActiveUsers from "./components/ActiveUsers";
import BuiltByDevelopers from "./components/BuiltByDevelopers";
import MiniStatistics from "./components/MiniStatistics";
import OrdersOverview from "./components/OrdersOverview";
import Projects from "./components/Projects";
import SalesOverview from "./components/SalesOverview";
import WorkWithTheRockets from "./components/WorkWithTheRockets";
import { FaArrowRight } from "react-icons/fa";
import { globalStyles } from "theme/styles";
import { Navigate, useNavigate } from "react-router-dom";
import BBButton from "components/Button/Button";
import { saveBrandInterest } from "RestAPI";
import "./Home.css"
import {SubscriptionModal} from "../Subscription/Subscription";

export default function Home() {
  const iconBoxInside = useColorModeValue("white", "white");

  return (
    <Flex flexDirection='column' pt={{ base: "120px", md: "75px" }}>
      <SimpleGrid columns={{ sm: 1, md: 2, xl: 4 }} spacing='24px'>
        <MiniStatistics
          title={"Today's Moneys"}
          amount={"$53,000"}
          percentage={55}
          icon={<WalletIcon h={"24px"} w={"24px"} color={iconBoxInside} />}
        />
        <MiniStatistics
          title={"Today's Users"}
          amount={"2,300"}
          percentage={5}
          icon={<GlobeIcon h={"24px"} w={"24px"} color={iconBoxInside} />}
        />
        <MiniStatistics
          title={"New Clients"}
          amount={"+3,020"}
          percentage={-14}
          icon={<DocumentIcon h={"24px"} w={"24px"} color={iconBoxInside} />}
        />
        <MiniStatistics
          title={"Total Sales"}
          amount={"$173,000"}
          percentage={8}
          icon={<CartIcon h={"24px"} w={"24px"} color={iconBoxInside} />}
        />
      </SimpleGrid>
      <Grid
        templateColumns={{ md: "1fr", lg: "1.8fr 1.2fr" }}
        templateRows={{ md: "1fr auto", lg: "1fr" }}
        my='26px'
        gap='24px'>
        <BuiltByDevelopers
          title={"Built by Developers"}
          name={"Purity UI Dashboard"}
          description={
            "From colors, cards, typography to complex elements, you will find the full documentation."
          }
          image={
            <Image
              src={logoChakra}
              alt='chakra image'
              minWidth={{ md: "300px", lg: "auto" }}
            />
          }
        />
        <WorkWithTheRockets
          backgroundImage={peopleImage}
          title={"Work with the rockets"}
          description={
            "Wealth creation is a revolutionary recent positive-sum game. It is all about who takes the opportunity first."
          }
        />
      </Grid>
      <Grid
        templateColumns={{ sm: "1fr", lg: "1.3fr 1.7fr" }}
        templateRows={{ sm: "repeat(2, 1fr)", lg: "1fr" }}
        gap='24px'
        mb={{ lg: "26px" }}>
        <ActiveUsers
          title={"Active Users"}
          percentage={23}
          chart={<BarChart />}
        />
        <SalesOverview
          title={"Sales Overview"}
          percentage={5}
          chart={<LineChart />}
        />
      </Grid>
      <Grid
        templateColumns={{ sm: "1fr", md: "1fr 1fr", lg: "2fr 1fr" }}
        templateRows={{ sm: "1fr auto", md: "1fr", lg: "1fr" }}
        gap='24px'>
        <Projects
          title={"Projects"}
          amount={30}
          captions={["Companies", "Members", "Budget", "Completion"]}
          data={dashboardTableData}
        />
        <OrdersOverview
          title={"Orders Overview"}
          amount={30}
          data={timelineData}
        />
      </Grid>
    </Flex>
  );
}

export const HomeDashboard = (props) => {
  const { userData, placeholderImage, setUserData } = useContext(AppContext)
  if (!userData) return null

  const [showMatchProfileForm, setShowMatchProfileForm] = useState(false)
  const { id = '', imageUrl = '', profileImageUrl = '', firstName = '', lastName = '', instagramAccount = '', videoResumeUrl = '', tagLine = '' } = userData
  const [ambassadors, setAmbassadors] = useState([])
  const [loading, setLoading] = useState(false)
  const [groupsCount, setGroupsCount] = useState(0)
  const [campaignCount, setCampaignCount] = useState(0)
  const [campaigns, setCampaigns] = useState([])
  const [projects, setProjects] = useState([])
  const [submissionPending, setSubmissionPending] = useState(0)
  const [messagesPending, setMessagesPending] = useState(0)
  const [attnNeededCampaigns, setAttnNeededCampaigns] = useState(0)
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false)
  const navigate = useNavigate()
  const toast = useToast()

  const fetchAmbassadors = async () => {
    const response = await getUserRecommendation()
    setLoading(false)
    if (response && response.data) return setAmbassadors(response.data.slice(0, 3))
  }

  const validateSubscription = () => {
    // Check api and get subscription info
    // setShowSubscriptionModal(true)    
  }

  const updateMatchInterest = async ({interest}) => {
    delete interest.updatedOn;
    delete interest.isDeleted;
    delete interest.isUserApproved;
    delete interest.pc1;
    delete interest.pc2;
    
    const response = await saveBrandInterest(interest)
    if(response){
      toast({
        title: `Match profile saved`,
        description: "Successfully updated match profile",
        status: 'success',
        position: 'top',
        duration: 3000,
        isClosable: true,
      })
      if(!userData.isApproved){
        let response = await approveUser({id: userData.id})
        if(response && response.data){
          setUserData({...userData, isApproved: true})
        }
      }
    }
  }

  const getDashboardData = async () => {
    getDashboardMetrics().then(response => {
      if (response?.data?.groupsCount) {
        setGroupsCount(response.data.groupsCount)
      }
      if (response?.data?.campaignCount) {
        setCampaignCount(response.data.campaignCount)
      }
      if(response.data?.campaigns){
        setCampaigns(response.data.campaigns)
      }
      if(response.data?.projects){
        setProjects(response.data.projects)
      }
      if(response.data?.attnNeededCampaigns){
        setAttnNeededCampaigns(response.data.attnNeededCampaigns)
      }
      if(response.data?.messagesPending){
        setMessagesPending(response.data.messagesPending)
      }
      if(response.data?.submissionPending){
        setSubmissionPending(response.data.submissionPending)
      }
    })
  }

  useEffect(() => {
    setLoading(true);
    fetchAmbassadors()
    getDashboardData()
    validateSubscription()
  }, [])

  return !userData ? null : <>
    <Flex flexDirection='column' gap='40px' pt='10px' display="flex" alignItems={"center"} id='landing'>
      <Flex flexDirection={{ base: 'column', xl: 'row' }} gap={{base: '40px', xl: '10px'}} width={{ base: '100%', xxl: '70%' }}>
        <Flex id="ambassadors" justifyContent='space-evenly' gap='10px' flexDirection={{ base: 'row', xs: 'column' }} w={{ base: 'full', xl: '50%' }} >
          {
            loading ? (
              <Flex align="center" minWidth="100%" flexWrap="nowrap">
                <Box padding='6' boxShadow='lg' minWidth="100%">
                  <SkeletonCircle size='20' />
                  <SkeletonText mt='4' noOfLines={4} spacing='4' />
                </Box>
              </Flex>
            ): 
            <Flex  w={{ base: 'full', lg: '100%' }}  flexDirection={'column'} borderRadius={15} boxShadow='xl' gap={5} p={6}>
              <Text as={'h6'}>{userData.role === 'AMBASSADOR' ? 'Featured Brands' : 'Featured Ambassadors'}</Text>
            
              <Flex gap={4} justifyContent='space-evenly' flexDirection={{ base: 'row'}}>
                {ambassadors.map((item, idx) => <AmbassadorCard key={idx} invite={{ user: item }} colorIndex={Math.abs(3 - idx)} />)
              }
              </Flex>
            </Flex>           
          }
        </Flex>
        {campaignCount > 0 ? 
        <Flex id="bbNotificationCard" w={{ base: 'full', xl: '50%' }}>
          <BBNotificationCard submissionPending={submissionPending} messagesPending={messagesPending} attnNeededCampaigns={attnNeededCampaigns}/> 
          </Flex> : 
         <Flex id="blogCard">
          <Image src='https://i.imgur.com/XiFEEhw.png' />
        </Flex>}
      </Flex>
      <Flex flexDirection={{ base: 'column', lg: 'row' }} gap={{base:'40px', lg:'10px'}} width={{ base: '100%', xxl: '70%' }}>
        <Flex id="brandCard" w={{ base: 'full', lg: '30%' }}>
          <Box bgColor='brandbassBlue2.200' w='full' borderRadius='15px' p='25px'>
            <Flex flexDirection='column' alignItems='center' gap='1rem' justify='space-between' flex={1} minH='full'>
              <Box position='relative'>
                <Image src={imageUrl || profileImageUrl} fallbackSrc={placeholderImage(150)} borderRadius='50%' w='150px' h='150px' />
              </Box>
              <Box textAlign='center'>
                <Text  fontWeight='700' fontSize='22px' lineHeight='24px' color='primary' textTransform='capitalize'>
                  {`${firstName} ${lastName}`}
                </Text>
                <Text  fontWeight='400' fontSize='20px' lineHeight='24px' color='primary' textTransform='capitalize'>
                  {(tagLine || userData.role.replaceAll('_', ' ')).toLowerCase()}
                </Text>
              </Box>
              <Flex justify='space-between' w='full'>
                <Flex flexDirection='column' minW='105px' alignItems='center' onClick={() => navigate('../campaigns')} cursor='pointer'>
                  <Text  fontWeight='500' fontSize='18px' color='primary'>{campaignCount}</Text>
                  <Text  fontWeight='500' fontSize='16px' lineHeight='24px' color='primary'>CAMPAIGNS</Text>
                </Flex>
                <Divider orientation='vertical' minH={50} borderColor='primary' />
                <Flex flexDirection='column' minW='105px' alignItems='center' onClick={() => navigate('groups')}>
                  <Text  fontWeight='500' fontSize='18px' color='primary'>{groupsCount}</Text>
                  <Text  fontWeight='500' fontSize='16px' lineHeight='24px' color='primary'>GROUPS</Text>
                </Flex>
              </Flex>
            </Flex>
          </Box>
        </Flex>
        <Flex w={{ base: 'full', lg: '70%' }} gap='10px'>
        {
            loading ? (
              <Flex align="center" minWidth="100%" flexWrap="nowrap">
                <Box padding='6' boxShadow='lg' minWidth="100%">
                  <SkeletonCircle size='20' />
                  <SkeletonText mt='4' noOfLines={4} spacing='4' />
                </Box>
              </Flex>
            ) :
          projects.length>2 ?  
          <Flex  w={{ base: 'full', lg: '100%' }} flexDirection={'column'} borderRadius={15} boxShadow='xs' gap={4} p={4}>
            <Flex>
            <Text as={'h6'}>Active projects</Text>
            <Spacer/>
            <BBButton variant='outline' onClick={(e) => {e.preventDefault(); navigate(`./projects?type=active`)}}>View all</BBButton>
          </Flex>
          
          <Flex gap={4} justifyContent='space-evenly' flexDirection={{ base: 'row' }}>
          {projects.map((project,idx) => {
            return (              
                <DashboardCard key={project.id} type='project' name={project.projectName} description={project.description} imageUrl={project.imageUrl} count={project.campaigns?.length} colorIndex={Math.abs(3 - idx)}></DashboardCard>              
            )
          })}
          </Flex>
          {/* <Flex>
            <Spacer/>
            <BBButton variant='outline' onClick={(e) => {e.preventDefault(); navigate(`./projects?type=active`, { replace: true })}}>View all</BBButton>
          </Flex> */}
        </Flex>
        : campaigns.length > 2 ? 
          <Flex  w={{ base: 'full', lg: '100%' }}  flexDirection={'column'} borderRadius={15} boxShadow='xl' gap={5} p={6}>
            <Flex>
            <Text as={'h6'}>Active campaigns</Text>
              <Spacer/>
              <BBButton variant='outline' onClick={(e) => {e.preventDefault(); navigate(`./campaigns?status=Active`)}}>View all</BBButton>
            </Flex>
            
            <Flex gap={4} justifyContent='space-evenly' flexDirection={{ base: 'row'}}>
            {campaigns.map((campaign, idx) => {
              return (
                  <DashboardCard key={campaign.id} type='campaign' name={campaign.name} description={campaign.description} imageUrl={campaign.imageUrl} count={campaign.gigs?.length} id={campaign.id} colorIndex={Math.abs(3 - idx)}></DashboardCard>
              )
            })}
            </Flex>
            {/* <Flex>
              <Spacer/>
              <BBButton variant='outline' onClick={(e) => {e.preventDefault(); navigate(`./campaigns?type=active`, { replace: true })}}>View all</BBButton>
            </Flex> */}
          </Flex>
        : <>
          {userData.role === 'BRAND_USER' ? null : <Flex id="matchProfile" w={{ base: 'full', lg: '50%' }} gap='10px'>
            <Box h='full' w='full' borderRadius='15px' position='relative' bgImage='https://i.imgur.com/nwjxMC4.png' bgRepeat="no-repeat" bgSize="cover">
              <Button onClick={() => setShowMatchProfileForm(true)} position='absolute' bottom={0} w='full' bgColor='brandbassPink.500' color='white'>Do your match profile!</Button>
            </Box>
          </Flex>}
          <Flex id="quickStart" w={{ base: 'full', lg: '50%' }} gap='10px'>
            <Box h='full' w='full' borderRadius='15px' position='relative' bgImage='https://i.imgur.com/J4cpWtp.png' bgRepeat="no-repeat" bgSize="cover">
              <Button position='absolute' bottom={0} w='full' bgColor='brandbassBlue2.500' color='white'>Download Quick Start Guide</Button>
            </Box>
          </Flex>
        </>}
        </Flex>
      </Flex>
    </Flex>
    <MatchProfileForm
      visible={showMatchProfileForm}
      closeHandler={() => setShowMatchProfileForm(false)}
      dataHandler={(val) => updateMatchInterest({ interest: val })}
    />
    <SubscriptionModal showModal={showSubscriptionModal} closeHandler={() => setShowSubscriptionModal(false)}/>
  </>
}

export const DashboardCard = (props) => {
  const {name, type, description, imageUrl, count, id, colorIndex} = props
  const backgroundVariants = [
    `linear-gradient(4.29deg, #FDEDE5 7.36%, #FEF5F0 53.39%)`,
    `linear-gradient(1.51deg, #EBF1FF 5.34%, #F2F5FE 72.41%)`,
    `linear-gradient(15.95deg, #F1F8FE 15.52%, #ECF6FF 70.25%)`
  ]
  const backgroundVariantIndex = colorIndex > 2 ? colorIndex % 3 : colorIndex
  const navigate = useNavigate()

  return (
    <Box position='relative' w='200px' h='240px' borderRadius='30px'
      //  borderWidth={'1px'} borderColor={globalStyles.colors.primary}
        background={backgroundVariants[backgroundVariantIndex]}
        // background={'white'}
        >
        <Flex flexDirection='column' alignItems='start' pt='6px' gap='4px'>
            <Box position='relative' alignSelf='center' p={2}>
                <Image src={imageUrl} w='160px' h='120px' objectFit='cover' borderRadius="15px"/>
            </Box>
            <Box textAlign='start' ml={4} w='85%' cursor='pointer' onClick={() => navigate(type==='campaign'?`/campaigns/${id}`:`/projects/${id}`)}>
                <Text fontWeight='500' fontSize='16px' lineHeight='24px' isTruncated w='full'>
                    {name}
                </Text>
                <Text fontWeight='400' fontSize='14px' lineHeight='24px' color='brandbassGray.500' isTruncated w='full'>
                    {description}
                </Text>
                <Flex mt={2} w='full'>
                    <Text fontWeight='500' fontSize='12px' lineHeight='20px'>
                        {count} {type === 'campaign' ? 'Gigs' : 'Campaigns'}
                    </Text>
                    <Spacer/>
                    <Icon float='right' as={FaArrowRight}/>
                </Flex>
            </Box>
        </Flex>
    </Box>
  )
}