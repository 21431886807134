import React, { useState } from "react";
// Chakra imports
import {
  Box,
  Flex,
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Link,
  Switch,
  Text,
  useColorModeValue,
  Select,
  Image,
  useToast
} from "@chakra-ui/react";
import signInImage from "assets/svg/LoginBG.svg";
import BrandBassShortIcon from 'assets/svg/brandbass-short-logo.svg';
import BrandBassIcon from 'assets/svg/brandbass-logo.svg';
import { resetPassword } from "RestAPI";
import { useNavigate, useLocation } from "react-router-dom";
import BBInput from "components/Input/Input";
import BBButton from "components/Button/Button";
import { checkEmailAvailability, checkUsernameAvailability, forgotPassword } from 'RestAPI';

function ForgotPassword(props) {
  // Chakra color mode
  const titleColor = useColorModeValue("brandbassBlue2.500", "white");
  const textColor = useColorModeValue("gray.400", "white");
  const [error, setError] = useState('')
  const [userPassword, setUserPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('');
  const [submit,setSubmit] = useState(false);
  const [userName, setUserName] = useState('');
  const [usernameAvailable, setUsernameAvailable] = useState(true);
  const [requestCodeSuccessful, setRequestCodeSuccessful] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');
  const [validPassword,setValidPassword] = useState(false);
  const [passwordMatch, setPasswordMatch] = useState(false);
  const [loading,setLoading] = useState(false)
  const navigate = useNavigate();
  const location = useLocation(); 
  const toast = useToast()

    const handleResetPassword = async () => {
        setSubmit(true)
        if (userPassword !== confirmPassword) {
            setError('Passwords do not match');
            return;
        }
        if (!userName || !usernameAvailable || !verificationCode || !requestCodeSuccessful || !passwordMatch || !userPassword || !confirmPassword || !validPassword) {   
              return;
        }
        const response = await resetPassword({
            userName:userName.trim(),
            userPassword,
            verificationCode,
          });
          setLoading(false);
          if (response.data && response.data.error) {
            if(response.data.error.code === 'CodeMismatchException'){
              setError('Invalid verification code provided, please try again.')
            }else if(response.data.error.message){
              setError(response.data.error.message);
            }
          } else {
            toast({
              title: `Forgot Password`,
              description: "Successfully reset the password. Please signin with new password.",
              status: 'success',
              position: 'top',
              duration: 3000,
              isClosable: true,
            })
            navigate("/auth");
          }
    }

    const checkPassword = () => {
        const regexFunc = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,16}$/;
        if (!regexFunc.test(userPassword)) {
            setValidPassword(false)
            return;
        }
        setValidPassword(true)
    };

    const checkUsername = async () => {
        try {
            const response = await checkUsernameAvailability(userName.trim());
            const responseEmail = await checkEmailAvailability(userName.trim());
            if (response.data && response.data.available && responseEmail.data && responseEmail.data.available) {
                setUsernameAvailable(false);
            } else {
                setUsernameAvailable(true);
            }
        } catch (err) {
            console.log(err);
        }
    }

    const handleRequestCode = async () => {
        setSubmit(true)
        if(!userName || !usernameAvailable){
            return;
        }
        setRequestCodeSuccessful(false);
        const response = await forgotPassword(userName.trim());
        if (response.data && response.data.error) {
            setError(response.data.error);
        } else if (response.data) {
            setRequestCodeSuccessful(true);
        }
    };

    const checkConfirmPassword = () => {
        if (userPassword === confirmPassword) {
            setPasswordMatch(true);
            setError('');
        } else {
            setError('Passwords do not match');
            setPasswordMatch(false);
        }
    };

  return (
    <Flex position='relative'>
      <Flex
        h={{ sm: "initial", md: "75vh", lg: "85vh" }}
        w='100%'
        maxW='1044px'
        mx='auto'
        justifyContent='space-between'
        mb='30px'
        pt={{ sm: "100px", md: "60px" }}>
        <Flex
          alignItems='center'
          justifyContent='start'
          style={{ userSelect: "none" }}
          w={{ base: "100%", md: "50%", lg: "42%" }}>
          <Flex
            direction='column'
            w='100%'
            background='transparent'
            p='48px'
            mt={{ md: "150px", lg: "80px" }}>
            <Heading color={titleColor} fontSize='32px' mb='10px'>
              Welcome!
            </Heading>
            <Text
              mb='36px'
              ms='4px'
              color={textColor}
              fontWeight='bold'
              fontSize='14px'>
              Please setup new password
            </Text>
            <FormControl>

            <BBInput
                mb='36px' 
                label="User Name/Email" 
                value={userName} 
                placeholder='Your UserName/Email' 
                onChange={e => {setSubmit(false);setUserName(e.target.value)}} 
                onBlur={checkUsername} 
                status={!usernameAvailable ? 'error' : null} 
                message={'Username/Email is not available. Please enter valid one.'}
                required={true}
                isInvalid={submit && !userName}/> 

            {requestCodeSuccessful && <>

                <BBInput
                    mb='36px' 
                    label="Verification code" 
                    value={verificationCode} 
                    placeholder='Your Verification Code' 
                    required={true}
                    onChange={e => {setSubmit(false);setVerificationCode(e.target.value)}}
                    isInvalid={submit && !verificationCode} />

                <BBInput 
                    mb='36px' 
                    label="Password" 
                    type='password' 
                    value={userPassword}
                    placeholder='Your password' 
                    onChange={e => {setSubmit(false);setUserPassword(e.target.value)}}
                    onBlur={checkPassword}
                    status={!validPassword ? 'error' : null}
                    message={'Password must be at least 8 characters and it must contain at least one uppercase, one lowercase, one number and one special character'}
                    required={true}
                    isInvalid={submit && !userPassword}/>

                <BBInput 
                    mb='36px' 
                    label="Confirm Password" 
                    type='password' 
                    value={confirmPassword}
                    placeholder='Confirm password' 
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    onBlur={checkConfirmPassword}
                    required={true}
                    isInvalid={submit && !confirmPassword} />
              </>}

             
              {!!error && <Text as="p" mt="10px" color={'red'}> {error} </Text>}

                {!requestCodeSuccessful ? 
                <BBButton type='submit'
                    w='100%'
                    h='45'
                    mb='20px'
                    mt='20px'
                    onClick={handleRequestCode}>
                    Request Code 
                </BBButton> : 
                <BBButton type='submit'
                    w='100%'
                    h='45'
                    mb='20px'
                    mt='20px'
                    onClick={handleResetPassword}>
                    Change Password
                </BBButton>}
            </FormControl>
          </Flex>
        </Flex>
        <Box
          display={{ base: "none", md: "block" }}
          overflowX='hidden'
          h='100%'
          w='50vw'
          mt={-70}
          position='absolute'
          right='0px'>
          <Flex
            flexDirection='column'
            justifyContent='center'
            alignItems='center'
            bgImage={signInImage}
            w='100%'
            h='100%'
            bgSize='cover'
            bgPosition='50%'
            position='absolute'
            borderBottomLeftRadius='20px'>
            <Image src={BrandBassShortIcon} w='67px' h='87px' />
              <Image src={BrandBassIcon} w='250px' />
          </Flex>
        </Box>
      </Flex>
    </Flex>
  );
}

export default ForgotPassword;
