// Chakra imports
import {
  Button,
  Flex,
  Stack,
  Box,
  Text,
  Spacer,
  Divider,
  Avatar,
  HStack,
  useColorModeValue,
  Image,
  Switch,
  Modal,
  FormLabel,
  AvatarGroup,
  SimpleGrid,
  Hide,
  Link,
  Skeleton, SkeletonCircle, SkeletonText,
  useDisclosure,
  Input,
  Tooltip,
  Spinner,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Checkbox,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Td,
  Tbody,
  CircularProgress
} from "@chakra-ui/react";
// Custom components
import { EmailIcon, ExternalLinkIcon } from "@chakra-ui/icons";
import { PersonIcon } from "components/Icons/Icons";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import moment from "moment";
import { AppContext } from "contexts/AppContext";
import React, { useState, useContext, useEffect } from "react";
import Chat from "components/Chat/Chat"
import SocialIcon from "components/Icons/SocialIcon"
import { useToast } from '@chakra-ui/react'
import brandImage from "assets/img/img.png";
import {
  PencilEditIcon,SaveIcon
} from "components/Icons/Icons.js";
import Facebook from "assets/icons/Facebook.png";
import Instagram from "assets/icons/Instagram.png";
import Twitter from "assets/icons/Twitter.png";
import Linkedin from "assets/icons/Linkedin.png";
import BBButton from "components/Button/Button";
import ShowUserForm from "./ShowUser"

import { getFileUploadSignedUrl,getDashboardUser, getCampaign, changeUserStatus, approveUser, activeAwayStatus, userEnableStatus,saveUser } from "RestAPI"
import { BeeIcon } from "components/Icons/Icons";
import IssueForm from "views/Dashboard/Issues/IssueForm";
import CounterCard from "components/Card/CounterCard";
import MatchProfileForm from "views/Dashboard/Campaign/MatchProfile";
import { saveBrandInterest } from "RestAPI";
import BBInput from "components/Input/Input";
import BrandDetails from "views/Dashboard/Brands/BrandDetails";
import "../Users.css"
import { createNotificationPreferences } from "RestAPI";
import { updateNotificationPreferences } from "RestAPI";
import { getNotificationPreferences } from "RestAPI";
import { BBSecondaryButtonWithoutHover } from "components/Button/Button";
const notiModules = ['Campaign',"Payment","Profile","Contract","Chat","User"]

const Profile = (props) => {
  const toast = useToast()
  const { placeholderImage, userData, setUserData } = useContext(AppContext)
  const { UserID } = useParams();
  const textColor = useColorModeValue("gray.700", "white");
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [data, setData] = useState({})
  const [loading, setLoading] = useState(true)
  const [role, setRole] = useState('Brand Admin')
  const [roleType, setRoleType] = useState('AMBASSADOR')
  const [address, setAddress] = useState({})
  const [team, setTeam] = useState({})
  const [groups, setGroups] = useState([])
  const [conversation, setConversation] = useState([])
  const [showUser, setShowUser] = useState(false);
  const [showIssueForm, setShowIssueForm] = useState(false)
  const [imagePreview, setImagePreview]  = useState(null)
  const [showMatchProfileForm, setShowMatchProfileForm] = useState(false)
  const [showBrandDetails, setShowBrandDetails] = useState(null);
  const [params] = useSearchParams()
  const openMatchProfile = params.get('openMatchProfile')
  const [showNotificationSettingsModal, setShowNotificationSettingsModal] = useState(false)
  const [preferences, setPreferences] = useState([])
  const [pauseAllNotifications, setPauseAllNotifications] = useState(false)
  
  const navigate = useNavigate()
  const backgroundVariants = [
    `linear-gradient(4.29deg, #FDEDE5 7.36%, #FEF5F0 53.39%)`,
    `linear-gradient(1.51deg, #EBF1FF 5.34%, #F2F5FE 72.41%)`,
    `linear-gradient(15.95deg, #F1F8FE 15.52%, #ECF6FF 70.25%)`
  ]

  const fetchConversations = async () => {
  }
  
  const createIssue = () => {
    navigate('/new/issue/', {state: {issueData: {issueType: 'Profile'}} })
  }

  const uploadUserProfileImage = async (e) => {
    
    const file = e.target.files[0];
    if (!file) return;

    const s3Obj = await getFileUploadSignedUrl({ mime: file.type }, "userProfile");
    if (!s3Obj || !s3Obj.data || !s3Obj.data.key)
      return;

    setImagePreview (URL.createObjectURL(file) || '');
    setData({ data : {...data,profileImageUrl:s3Obj.data.key} })
    await fetch(s3Obj.data.url, {
      method: 'PUT',
      headers: { 'Content-Type': file.type },
      body: file,
    });
    saveUser(data.id,{id: data.id,profileImageUrl:s3Obj.data.key})
        .then(response => {
          if(response.data?.profileImageUrl){
            setUserData({...userData, profileImageUrl: response.data.profileImageUrl})
          }
          fetchUserData()
        }).catch(err => {
          toast({
            title: `An error occured when saving the user.`,
            description: "Please try after sometime or create an issue",
            status: 'error',
            position: 'top',
            duration: 3000,
            isClosable: true,
          })
          setLoading(false)
        })
  };

  const fetchUserData = () =>{
    getDashboardUser(userData.id).then(response => {
      setData(response.data)
      console.log(response.data)
      setRoleType(response.data.role && response.data.role.indexOf('BRAND') != -1 ? 'BRAND' : 'AMBASSADOR')
      setRole(response.data.role ? response.data.role.replaceAll('_', ' ') : response.data.role)
      setAddress(response.data.addresses && response.data.addresses.length > 0 ? response.data.addresses[0] : {})
      setLoading(false)
    })
  }

  const updateMatchInterest = async ({interest}) => {
    delete interest.updatedOn;
    delete interest.isDeleted;
    delete interest.isUserApproved;
    delete interest.pc1;
    delete interest.pc2;

    const response = await saveBrandInterest(interest)
    if(response){
      toast({
        title: `Match profile saved`,
        description: "Successfully updated match profile",
        status: 'success',
        position: 'top',
        duration: 3000,
        isClosable: true,
      })
      if(!userData.isApproved){
        let response = await approveUser({id: userData.id})
        if(response && response.data){
          setUserData({...userData, isApproved: true})
        }
      }
    }
  }

  async function handlePauseNotifications () {
    setLoading(true)
    if(!pauseAllNotifications){
      let bodyCreate = preferences.filter(pref => !pref.id).map(pref => {
        pref.allowDesktopNotification = false
        pref.allowMobileNotification = false
        pref.allowEmailNotification = false
        return pref
      })
      if(bodyCreate.length){
        await createNotificationPreferences(bodyCreate)
      }
      let bodyUpdate = preferences.filter(pref => !!pref.id).map(pref => {
        pref.allowDesktopNotification = false
        pref.allowMobileNotification = false
        pref.allowEmailNotification = false
        return pref
      })
      if(bodyUpdate.length){
        let res = await updateNotificationPreferences(bodyUpdate)
      }
    }else{
      setShowNotificationSettingsModal(true)
    }
    await findNotificationPreferences()
    setLoading(false)
    setPauseAllNotifications(!pauseAllNotifications)
  }

  async function findNotificationPreferences(){
    setLoading(true)
    let notificationPreferences = await getNotificationPreferences() || []
    setLoading(false)
    if(notificationPreferences.length){
      console.log(notificationPreferences.reduce((a,b) => a || (b.allowMobileNotification || b.allowDesktopNotification || b.allowEmailNotification) , false))
      setPauseAllNotifications(!notificationPreferences.reduce((a,b) => a || (b.allowMobileNotification || b.allowDesktopNotification || b.allowEmailNotification) , false))
    }
    for (let module of notiModules){
      let match = notificationPreferences.find(n => n.notificationModule === module)
      if(!match){
        notificationPreferences.push({
          notificationModule: module,
          allowMobileNotification: true,
          allowDesktopNotification: false,
          allowEmailNotification: true
        })
      }
    }
    setPreferences(notificationPreferences)
  }

  useEffect(() => {
    console.log(props)
    fetchConversations()
    findNotificationPreferences()
    fetchUserData()
  }, [])

  useEffect(() => {
    if(openMatchProfile){
      setShowMatchProfileForm(true)
    }
  },[openMatchProfile])
  return (
    <Flex id="my-profile" flexDirection='column' gap='40px' pt='40px' width={{base:'100%'}} maxW={{base: '100%', hd: '1500px'}} margin={"auto"}>
      {/* <IssueForm visible={showIssueForm} closeHandler={() => setShowIssueForm(false)} issueData={{issueType: 'Profile'}} /> */}
      {showUser && <Modal size={'4xl'} isOpen={showUser} onClose={() => setShowUser(false)}>
        <ShowUserForm onClose={() => setShowUser(false)} brandInfo={data.brand} UserID={data.id}/>
      </Modal>}
      <Flex align="start" minWidth="100%" flexWrap="nowrap" flexDirection={{base:'column',lg:'row'}}>
        <Box>
        <Text fontSize='30px' color={'primary'} fontWeight='bold' pb='.5rem'>Profile</Text>
          <Text fontStyle={"italic"} textAlign='left' align="left" fontWeight='500' fontSize='16px' >
            Hello! This is your profile. Here you can change the settings for your profile.
          </Text>
        </Box>
        <Spacer />
        {/* <Box>
          <Stack align="right" spacing={4} direction={{base:'column',md:'row'}}>
            <Button leftIcon={<BeeIcon />} bgColor='white' onClick={createIssue} >Help</Button>
            {data && !data.deletedOn && (
              <Button leftIcon={<PersonIcon />} bgColor='white' onClick={changeUserStatus}>Inactivate</Button>
            )}
            {data && data.deletedOn && (
              <Button leftIcon={<PersonIcon />} bgColor='white' onClick={changeUserStatus}>Activate</Button>
            )}
            {data && !data.isEnabled && (
              <Button leftIcon={<EmailIcon />} bgColor='white' isDisabled={data && data.deletedOn} onClick={userEnableStatus}>Enable</Button>
            )}
            {data && data.isEnabled && (
              <Button leftIcon={<EmailIcon />} bgColor='white' isDisabled={data && data.deletedOn} onClick={userEnableStatus}>Disable</Button>
            )}
            {data && !data.isApproved && (
              <Button leftIcon={<EmailIcon />} bgColor='white' isDisabled={data && data.deletedOn} onClick={approveUser}>Approve</Button>
            )}
            {data && data.isApproved && (
              <Button leftIcon={<EmailIcon />} bgColor='white' isDisabled={data && data.deletedOn} onClick={approveUser}>Reject</Button>
            )}
            {data && data.role === 'AMBASSADOR' && (
              <>
                {data && !data.isActive && (
                  <Button leftIcon={<EmailIcon />} bgColor='white' isDisabled={data && data.deletedOn} onClick={activeAwayStatus}>Set Active</Button>
                )}
                {data && data.isActive && (
                  <Button leftIcon={<EmailIcon />} bgColor='white' isDisabled={data && data.deletedOn} onClick={activeAwayStatus}>Set Away</Button>
                )}

              </>
            )}
          </Stack>
        </Box> */}
      </Flex>
      <HStack gap={12} marginBottom={{ base: '10px', lg: '0px' }} flexDirection={{ base: 'column', md: 'row' }} width="100%" justify='space-between' flexWrap={'wrap'} >
        <Flex id="brandCard" width={{ base: '100%'}} maxW={{base: 'full', xxl: '470px'}} >
          {loading && (
            <Flex align="center" minWidth="100%" flexWrap="nowrap">
              <Box padding='6' boxShadow='lg' bg='white' minWidth="100%">
                <SkeletonCircle size='10' />
                <SkeletonText mt='4' noOfLines={4} spacing='4' />
              </Box>
            </Flex>
          )}
          {!loading && <BrandInfoCard data={data} role={role} flex={1} uploadUserProfileImage={uploadUserProfileImage} imagePreview={imagePreview}/>}
        </Flex>
        
       <Flex width={{ base: '100%'}} flex={1} >
          {loading && (
            <Flex align="center" minWidth="100%" flexWrap="nowrap">
              <Box padding='6' boxShadow='lg' bg='white' minWidth="100%">
                <SkeletonCircle size='10' />
                <SkeletonText mt='4' noOfLines={4} spacing='4' />
              </Box>
            </Flex>
          )}
          {!loading && (
            <Stack >
              <Box position='relative' borderRadius='15px' bgColor='blackAlpha.50' justify='center' padding={8}>
                <Text textAlign='left' align="left" fontWeight='500' fontSize='16px' >
                  Account overview
                </Text>
                <HStack p={4} gap='1rem' >
                  
                    <CounterCard 
                      title={
                        <Text textAlign='left' align="left" fontWeight='500' fontSize='16px' >
                          Active<br />campaigns
                        </Text>
                      } 
                      width={115}
                      count={data.campaign?.activeCount || 0} />
                  
                  {userData.role === 'BRAND_USER' ? null : 
                    <CounterCard 
                      title={
                        userData.role === 'AMBASSADOR' ? <Text textAlign='left' align="left" fontWeight='500' fontSize='16px' >
                          Campaigns<br />completed
                        </Text> : <Text textAlign='left' align="left" fontWeight='500' fontSize='16px' >
                          Campaigns<br />authored
                        </Text> 
                      } 
                      width={115}
                      count={data.campaign?.totalCount || 0} />}
                  
                    <Stack direction='column'>
                      {/* <Box position='relative' w='100%' h='100%' minW="120px" borderRadius='10px' bgColor='brandbassBlue2.200'>
                        <Flex flexDirection='column' alignItems='left'>
                          <HStack direction='column' p={4}>
                            <Text textAlign='left' align="left" fontWeight='500' fontSize='16px' >
                              Teams: -N/A-
                            </Text>
                            <AvatarGroup size="sm" max={2}>
                              {campaign && campaign.gigs && campaign.gigs.map((user) => {
                                return (
                                  <Avatar bg='white.500' borderColor='blue.500'
                                    name={`${user.gigName}`}
                                    key={user.id}
                                    src={user.imageUrl}
                                    _hover={{ zIndex: "3", cursor: "pointer" }}
                                  />
                                );
                              })}
                            </AvatarGroup>
                          </HStack>
                        </Flex>
                      </Box> */}
                      {/* <Box position='relative' w='100%' h='100%' minW="150px" borderRadius='10px' bgColor='brandbassBlue2.200'>
                        <Flex flexDirection='column' alignItems='left' gap='1rem'>
                          <HStack direction='column' p={4}>
                            <Text textAlign='left' align="left" fontWeight='500' fontSize='16px' >
                              Groups: -N/A-
                            </Text>
                            <AvatarGroup size="sm" max={2}>
                              {conversation && conversation.map((user) => {
                                return (
                                  <Avatar bg='white.500' borderColor='blue.500'
                                    name={`${user.moduleTitle}`}
                                    key={user.moduleId}
                                    src={user.moduleImageUrl}
                                    _hover={{ zIndex: "3", cursor: "pointer" }}
                                  />
                                );
                              })}

                            </AvatarGroup>
                          </HStack>
                        </Flex>
                      </Box> */}
                    </Stack>

                  {/* {userData.brand ? <> <Box position='relative' w='100%' h='100%' maxW='500px' rounded='10px' overflow='hidden' bgColor='primary' onClick={() => setShowBrandDetails(true)}>
                    <Image position={"relative"} width={"100%"} cursor={'pointer'} src={data.brand?.logoUrl ? data.brand.logoUrl : brandImage} height="160px" objectFit={"cover"}  />
                    <Text variant="image-label" filter='drop-shadow(2px 4px 6px black)' >{data.brand?.brandName}</Text>
                  </Box> 
                  {userData.role === 'BRAND_SUPER_ADMIN' || userData.role === 'CLIENT_SUPER_ADMIN' ?  <Tooltip label="Edit brand info"><PencilEditIcon onClick={() => navigate('/edit/brand-registration')}/></Tooltip> : null}
                  </> : null} */}
                </HStack>
                <Modal size={'4xl'} isOpen={showBrandDetails} onClose={() => setShowBrandDetails(false)}>
                  <BrandDetails onClose={() => setShowBrandDetails(false)} BrandID={userData.brand?.id} />
                </Modal>
              </Box>
            </Stack>
          )}
        </Flex>
        {userData.role === 'AMBASSADOR' ? null : <Flex flex={1} width={{ base: '100%'}} maxW='470px'>
          <Box minH="270px" minW={'320px'} width={"100%"} borderRadius='15px' position='relative' bgImage={data?.brand?.logoUrl} bgRepeat="no-repeat" bgSize="cover" cursor={'pointer'} onClick={() => setShowBrandDetails(true)}>
            <Text filter='drop-shadow(2px 4px 6px black)' color={'white'} fontSize={20} alignSelf={'self-end'} mt={4} ml={4} style={{textTransform: 'capitalize'}} >{data?.brand?.brandName}</Text>
            {userData.role === 'BRAND_USER' || userData.role === 'BRAND_MANAGER' ? null :
             <BBButton onClick={() => navigate('/edit/brand-registration')} position='absolute' bottom={0} w='full' bgColor='gray.100' opacity={0.8} color={'brandbassBlue'} variant="solid" _hover={{color: 'white'}}>Edit brand info</BBButton>}
          </Box>
        </Flex>}
      </HStack>
      <Flex align="center" minWidth="100%" flexWrap="nowrap">
        {loading && (
          <Flex align="center" minWidth="100%" flexWrap="nowrap">
            <Box padding='6' boxShadow='lg' bg='white' minWidth="100%">
              <SkeletonCircle size='10' />
              <SkeletonText mt='4' noOfLines={4} spacing='4' />
            </Box>
          </Flex>
        )}

        {!loading && (
          <HStack spacing={{ base:'0',lg:'4'}} gap={8} flexDirection={{ base: 'column', lg: 'row' }} width="100%">
            <Flex id="brandCard"  w={{ base: 'full', lg: '40%' }}>
              <Box bgColor='white' w='full' borderRadius='10px' p='25px' boxShadow={"xl"}>
                <BrandBasicInfoCard data={data} setData={(data)=>setData(data)} showEdit={true}/>
              </Box>
            </Flex>

            <Stack direction='column' width={{base:'100%', lg:'60%'}}>
              <HStack spacing={{base:0, md:4}} gap={'10px'} flexDirection={{base:'column',md:'row'}}>
                <Flex id="brandCard" minH="150px" width={"100%"}>
                  <Box minH='150px' w='100%' bgColor='gray.200' borderRadius='15px' p='25px' boxShadow={"xl"}>
                    <Flex flexDirection='column' alignItems='left' gap='1rem'>
                      <Box position='relative'>
                        <Text py={2} fontWeight='500' fontSize='18px' lineHeight='24px'>
                          Notification Settings
                        </Text>
                      </Box>
                      <Box textAlign='left' >
                        <HStack spacing={4} py={2} justifyContent={'space-between'}>
                          <FormLabel color='brandbassGray.500' htmlFor='isChecked'>Pause all notifications</FormLabel>
                          <Switch size='lg' id='messages' isChecked={pauseAllNotifications} onChange={handlePauseNotifications}/>
                        </HStack>
                        <HStack spacing={4} py={2} mt={8} justifyContent='center'>
                          <BBButton variant='outline' onClick={() => setShowNotificationSettingsModal(true)}>Manage Subscriptions</BBButton>
                        </HStack>
                      </Box>
                      {/* <Box textAlign='left'>
                        <HStack spacing={4} py={2}>
                          <Switch size='lg' id='messages' isChecked disabled/>
                          <FormLabel color='brandbassGray.500' htmlFor='isChecked'>Email me when I have messages pending</FormLabel>
                        </HStack>
                        <HStack spacing={4} py={2}>
                          <Switch size='lg' id='gigs' isChecked disabled/>
                          <FormLabel color='brandbassGray.500' htmlFor='isChecked'>Email me when I have new gigs submissions</FormLabel>
                        </HStack>
                        <HStack spacing={4} py={2}>
                          <Switch size='lg' id='updates' disabled />
                          <FormLabel color='brandbassGray.500' htmlFor='isChecked'>Email me for Brandbass updates</FormLabel>
                        </HStack>
                      </Box> */}
                    </Flex>
                  </Box>
                </Flex>
                {userData.role === 'BRAND_USER' ? null : <Flex id="matchProfile" w='100%'>
                  <Box minH="330px"  width={"100%"} borderRadius='15px' position='relative' bgImage='https://i.imgur.com/nwjxMC4.png' bgRepeat="no-repeat" bgSize="cover">
                    <Button onClick={() => setShowMatchProfileForm(true)} position='absolute' bottom={0} w='full' bgColor='brandbassPink.500' variant="solid" >Do your match profile!</Button>
                  </Box>
                </Flex>}
                
              </HStack>
              <Text textAlign='left' align="left" fontWeight='500' fontSize='16px'>
                Read more:
              </Text>
              <HStack>
                <Flex gap={'10px'} w={{ base: 'full', lg: '100%' }} flexDirection={{base:'column',lg:'row'}}>
                  <BBButton height="55px" borderRadius={"10px"} onClick={() => window.open('https://www.brandbass.ai/blogs.html')} bgColor='brandbassBlue2.200' variant={'outline'} w="100%" fontWeight='600' fontSize='18px'>
                    Brandbass Blog
                  </BBButton>
                  <BBButton height="55px" borderRadius={"10px"} onClick={() => window.open('https://www.brandbass.ai')} bgColor='brandbassBlue2.200' variant={'outline'} w="100%" fontWeight='600' fontSize='18px'>
                    Brandbass Website
                  </BBButton>
                </Flex>
              </HStack>
            </Stack>
          </HStack>
        )}
      </Flex>
      {showNotificationSettingsModal && <NotificationSettingsModal visible={showNotificationSettingsModal} preferences={preferences} closeHandler={() => {setShowNotificationSettingsModal(false); findNotificationPreferences()}}/>}
      <MatchProfileForm
        visible={showMatchProfileForm}
        closeHandler={() => setShowMatchProfileForm(false)}
        dataHandler={(val) => updateMatchInterest({ interest: val })}
      />
    </Flex>
  );
};

export default Profile;

export const NotificationSettingsModal = (props) => {
  const { visible, closeHandler, successHandler } = props
  const [showModal, setShowModal] = useState(false)
  const [preferences, setPreferences] = useState(props.preferences || [])
  const [loading, setLoading] = useState(false)

  function updatePreferences(module, type, value) {
    setPreferences(preferences.map(pref => {
      if(pref.notificationModule === module){
        if(type){
          pref[type] = value
        }else{
          pref.allowMobileNotification = value
          pref.allowEmailNotification = value
        }
      }
      return pref
    }))
  }

  async function savePreferences(){
    let bodyCreate = preferences.filter(pref => !pref.id)
    if(bodyCreate.length){
      await createNotificationPreferences(bodyCreate)
    }
    let bodyUpdate = preferences.filter(pref => !!pref.id)
    if(bodyUpdate.length){
      let res = await updateNotificationPreferences(bodyUpdate)
    }
  }
  
  async function handleSavePreferences () {
    setLoading(true)
    await savePreferences()
    setLoading(false)
    props.closeHandler()
  }

  
  const campaignPref = preferences.find(pref => pref.notificationModule === 'Campaign')  
  const contractPref = preferences.find(pref => pref.notificationModule === 'Contract')
  const paymentPref = preferences.find(pref => pref.notificationModule === 'Payment')
  const chatPref = preferences.find(pref => pref.notificationModule === 'Chat')


  useEffect(() => setShowModal(!!visible), [visible])

  return <>
    <Modal isOpen={showModal} onClose={closeHandler} size="2xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader color='brandbassBlue'>
          <Text fontSize='2xl' fontFamily='Rubik' >Manage Subscriptions</Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <Box borderWidth="1.5px" borderRadius="lg" overflow="scroll">
            <TableContainer>
              <Table size='lg'>
                <Thead>
                    <Tr>
                      <Th>MODULE</Th>
                      <Th>MOBILE</Th>
                      <Th>DESKTOP</Th>
                      <Th>EMAIL</Th>
                    </Tr>                    
                </Thead>
                <Tbody>
                    <Tr>
                      <Td>Campaign</Td>
                      <Td><Checkbox isChecked={campaignPref.allowMobileNotification} onChange={(e) => updatePreferences('Campaign', 'allowMobileNotification', e.target.checked)}/></Td>
                      <Td><Checkbox isDisabled={true} isChecked={campaignPref.allowDesktopNotification} onChange={(e) => updatePreferences('Campaign', 'allowDesktopNotification', e.target.checked)}/></Td>
                      <Td><Checkbox isChecked={campaignPref.allowEmailNotification} onChange={(e) => updatePreferences('Campaign', 'allowEmailNotification', e.target.checked)}/></Td>
                    </Tr>
                    <Tr>
                      <Td>Contract</Td>
                      <Td><Checkbox isChecked={contractPref.allowMobileNotification} onChange={(e) => updatePreferences('Contract', 'allowMobileNotification', e.target.checked)}/></Td>
                      <Td><Checkbox isDisabled={true} isChecked={contractPref.allowDesktopNotification} onChange={(e) => updatePreferences('Contract', 'allowDesktopNotification', e.target.checked)}/></Td>
                      <Td><Checkbox isChecked={contractPref.allowEmailNotification} onChange={(e) => updatePreferences('Contract', 'allowEmailNotification', e.target.checked)}/></Td>
                    </Tr>
                    <Tr>
                      <Td>Payment</Td>
                      <Td><Checkbox isChecked={paymentPref.allowMobileNotification} onChange={(e) => updatePreferences('Payment', 'allowMobileNotification', e.target.checked)}/></Td>
                      <Td><Checkbox isDisabled={true} isChecked={paymentPref.allowDesktopNotification} onChange={(e) => updatePreferences('Payment', 'allowDesktopNotification', e.target.checked)}/></Td>
                      <Td><Checkbox isChecked={paymentPref.allowEmailNotification} onChange={(e) => updatePreferences('Payment', 'allowEmailNotification', e.target.checked)}/></Td>
                    </Tr>
                    <Tr>
                      <Td>Chat</Td>
                      <Td><Checkbox isChecked={chatPref.allowMobileNotification} onChange={(e) => updatePreferences('Chat', 'allowMobileNotification', e.target.checked)}/></Td>
                      <Td><Checkbox isDisabled={true} isChecked={chatPref.allowDesktopNotification} onChange={(e) => updatePreferences('Chat', 'allowDesktopNotification', e.target.checked)}/></Td>
                      <Td><Checkbox isChecked={chatPref.allowEmailNotification} onChange={(e) => updatePreferences('Chat', 'allowEmailNotification', e.target.checked)}/></Td>
                    </Tr>
                </Tbody>
              </Table>
            </TableContainer>
          </Box>
        </ModalBody>
        <ModalFooter>
          <BBSecondaryButtonWithoutHover
              colorScheme="brandbassBlue"
              variant="outline"
              borderRadius="lg" mr={3}
              onClick={closeHandler}
          >
              Cancel
          </BBSecondaryButtonWithoutHover>
          {loading ? <CircularProgress value={80} /> : <BBButton
              colorScheme="brandbassBlue"
              borderRadius="lg"
              onClick={handleSavePreferences}
          >
              Save
          </BBButton>}
      </ModalFooter>
      </ModalContent>
    </Modal>
  </>
}

export const BrandInfoCard = (props) => {
  const {data, setData, role, uploadUserProfileImage, imagePreview, ...rest} = props
  const navigate = useNavigate()
  const {userData} = useContext(AppContext)
  const brandSubdomain = window.location.hostname.split('.')[0]?.toLowerCase() || ''

  return <Flex {...rest} id="brandCard">
    <Box bgColor='brandbassBlue2.200' w='full' borderRadius='15px' p='25px'>
      <Flex flexDirection='column' alignItems='center' gap='1rem' justify='space-between' flex={1} minH='full'>
        <Flex align='center' justify='space-evenly' w='full'>
          <Flex flexDirection='column' gap='1rem' textAlign='center'>
            <Text fontWeight='700' fontSize='22px' lineHeight='24px' color='primary' display='flex' flexWrap='wrap' maxW='80%' margin='auto'>
              {`${data.firstName} ${data.lastName}`}
            </Text>
            <Text fontWeight='400' fontSize='20px' lineHeight='24px' color='primary' textTransform='capitalize'>
              {role?.replaceAll('_',' ').toLowerCase()}
            </Text>
          </Flex>
          <Box position='relative'>
            <Avatar src={data.profileImageUrl && data.profileImageUrl.includes('http') ? data.profileImageUrl : imagePreview}
              name={`${data.firstName} ${data.lastName}`} size='2xl' />
            <Box position="relative" left={"115px"}
              bottom={"30px"} >
              <Input
                type="file"
                onChange={uploadUserProfileImage}
                bg="transparent"
                position="absolute"
                inset="0 0 0 0"
                cursor="pointer"
                opacity={0}
                h="100%"
              />
             {userData.id === data.id ? <PencilEditIcon fontSize={'20px'} /> : null }
            </Box>
          </Box>
        </Flex>
        {brandSubdomain === 'dashboard' || brandSubdomain === 'dashboard-prod' || brandSubdomain === 'admin' ? null : <Flex justify='space-between' w='full'>
          <Flex flexDirection='column' minW='105px' alignItems='center' onClick={() => navigate('/teams')} cursor='pointer'>
            <Text fontWeight='400' fontSize='18px' color='brandbassBlue.500'>{data.teams?.length ? data.teams[0].teamName : 'NA'}</Text>
            <Text fontWeight='500' fontSize='16px' lineHeight='24px' color='primary'>TEAM</Text>
          </Flex>
          <Divider orientation='vertical' minH={50} borderColor='primary' />
          <Flex flexDirection='column' minW='105px' alignItems='center' onClick={() => navigate('/groups?myGroups=true')} cursor='pointer'>
            <Text fontWeight='500' fontSize='18px' color='primary'>{data.groups?.length || 0 }</Text>
            <Text fontWeight='500' fontSize='16px' lineHeight='24px' color='primary'>GROUPS</Text>
          </Flex>
        </Flex>}
      </Flex>
    </Box>
  </Flex>
}

export const BrandBasicInfoCard = (props) => {
  const {data,columns, ...rest} = props
  const {userData} = useContext(AppContext)
  const address = !!data?.addresses?.length ? data.addresses[0] : {}
  const [userAddress,setUserAddress] = useState(!!data?.addresses?.length ? {...data.addresses[0]} : {})
  const [editUserForm,setEditUserForm] = useState(false);
  const [userInfo,setUserInfo]=useState(data ? {...data} : {})
  const toast = useToast()
  const [loading,setLoading]=useState(false)
  const updateData = (e) =>{
    userInfo[e.target.name] = e.target.value
    setUserInfo({...userInfo})
  }
  const updateAddress = (e) =>{
    userAddress[e.target.name] = e.target.value
    setUserAddress({...userAddress})
  }
  const saveUserForm = () =>{
    setLoading(true)
    saveUser(data.id,{id: data.id,firstName:userInfo.firstName,lastName:userInfo.lastName,dob:userInfo.dob,email:userInfo.email,mobileNumber:userInfo.mobileNumber, ...userAddress.zipCode && {zipCode:userAddress.zipCode},...userAddress && Object.keys(userAddress).length>0 &&{ addresses:[{...userAddress}]}})
    .then(response => {
      if(response.data && !response.data.error && !response.data.message){
        setUserInfo(response.data)
        setUserAddress(!response.data?.addresses?.length ? {...response.data.addresses[0]} : {})
        setEditUserForm(false)
        props.setData(response.data)
      }
      if(response.data.message){
        console.log("message called")
        toast({
          title: `An error occured when saving the user.`,
          description: "Please try after sometime or create an issue",
          status: 'error',
          position: 'top',
          duration: 3000,
          isClosable: true,
        })
        setEditUserForm(false)
      setUserInfo(data)
      setUserAddress(!!data?.addresses?.length ? {...data.addresses[0]} : {})
      }
      setLoading(false)
    }).catch(err => {
      console.log('catch called')
      toast({
        title: `An error occured when saving the user.`,
        description: "Please try after sometime or create an issue",
        status: 'error',
        position: 'top',
        duration: 3000,
        isClosable: true,
      })
      setEditUserForm(false)
      setUserInfo(data)
      setUserAddress(!!data?.addresses?.length ? {...data.addresses[0]} : {})
      setLoading(false)
    })
  }
  return !data || !data.id ? null : <>
    <Flex {...rest} flexDirection='column' alignItems='left' gap='1rem'>
      <Flex position='relative' flexDirection={'row'}>
        <Text py={2} fontWeight='500' fontSize='20px' lineHeight='24px'>
          Basic Information  
        </Text>
        <Spacer/>
        {userData.id === data.id && props.showEdit ? !editUserForm ? <PencilEditIcon  cursor={"pointer"} fontSize={'20px'} mt={2} onClick={() => setEditUserForm(true)} /> : editUserForm && <><SaveIcon cursor={"pointer"} mt={2} fontSize={'20px'} onClick={saveUserForm} />{loading && <Spinner />}</> : null}

      </Flex>
      <Box textAlign='left'>
        <SimpleGrid w="100%" columns={!!columns ? columns : { base: 1, md: 2 }} spacing={10}>
          <Stack direction='column'>
            <Text fontWeight='500' fontSize='18px' lineHeight='24px'>
              First Name
            </Text>
            {editUserForm ? <BBInput
              name="firstName"
              value={userInfo.firstName ? userInfo.firstName : ''}
              placeholder='Your first name'
              onChange={updateData}
            />
              : <Text fontSize='14px' lineHeight='24px' color='brandbassGray.500'>
                {userInfo.firstName || 'NA'}
              </Text>}
          </Stack>
          <Stack direction='column'>
            <Text fontWeight='500' fontSize='18px' lineHeight='24px'>
              Last Name
            </Text>
            {editUserForm ? <BBInput
              name="lastName"
              value={userInfo.lastName ? userInfo.lastName : ''}
              placeholder='Your last name'
              onChange={updateData}

            /> : <Text fontSize='14px' lineHeight='24px' color='brandbassGray.500'>
              {userInfo.lastName || 'NA'}
            </Text>}
          </Stack>
          <Stack direction='column'>
            <Text fontWeight='500' fontSize='18px' lineHeight='24px'>
              Date of Birth
            </Text>
            {editUserForm ? <BBInput
              name={"dob"}
              value={userInfo.dob ? userInfo.dob : ''}
              placeholder='Date Of Birth'
              onChange={updateData}

            /> : <Text fontSize='14px' lineHeight='24px' color='brandbassGray.500'>
              {userInfo.dob || 'NA'}
            </Text>}
          </Stack>
          <Stack direction='column'>
            <Text fontWeight='500' fontSize='18px' lineHeight='24px'>
              Username
            </Text>
            {editUserForm ? <BBInput isDisabled={true}
              name="zipCode"
              value={userInfo.userName}
              placeholder='Username'
              onChange={()=>{}}

            /> : <Text fontSize='14px' lineHeight='24px' color='brandbassGray.500'>
              {userInfo.userName}
            </Text>}
          </Stack>
          
          <Stack direction='column'>
            <Text fontWeight='500' fontSize='18px' lineHeight='24px'>
              Business Email
            </Text>
            {editUserForm ? <BBInput
              name="email"
              value={userInfo.email ? userInfo.email : ''}
              placeholder='Business Email'
              onChange={updateData}
            /> : <Text fontSize='14px' lineHeight='24px' color='brandbassGray.500'>
              {userInfo.email || 'NA'}
            </Text>}
          </Stack>
          <Stack direction='column'>
            <Text fontWeight='500' fontSize='18px' lineHeight='24px'>
              Phone Number
            </Text>
            {editUserForm ? <BBInput
              type="number"
              name="mobileNumber"
              value={userInfo.mobileNumber ? userInfo.mobileNumber : ''}
              placeholder='Phone Number'
              onChange={updateData}
            /> : <Text fontSize='14px' lineHeight='24px' color='brandbassGray.500'>
              {userInfo.mobileNumber || 'NA'}
            </Text>}
          </Stack>
          {
            userData.id === data.id && !editUserForm ? <>
              <Stack direction='column'>
                <Text fontWeight='500' fontSize='18px' lineHeight='24px'>
                  Address
                </Text>
                <Text fontSize='14px' lineHeight='24px' color='brandbassGray.500'>
                  {address.lineOne || 'NA'}
                </Text>
                {address.lineTwo ? <Text fontSize='14px' lineHeight='24px' color='brandbassGray.500'>
                  {address.lineTwo || ''}
                </Text> : null }
                {address.city ? <Text fontSize='14px' lineHeight='24px' color='brandbassGray.500'>
                  {address.city || ''} {'-'} {address.zipCode || ''}
                </Text> : null}
                {address.state ? <Text fontSize='14px' lineHeight='24px' color='brandbassGray.500'>
                  {address.state || ''} {', '} {address.country || ''}
                </Text> : null}
              </Stack>
            </> : editUserForm && <>
              <Stack direction='column'>
                <Text fontWeight='500' fontSize='18px' lineHeight='24px'>
                  Address
                </Text>
              </Stack>
              <Stack direction='column'>
              </Stack>
              <Stack direction='column'>
                <BBInput
                  name="lineOne"
                  value={address.lineOne}
                  placeholder='Line One'
                  onChange={updateAddress}
                />
              </Stack>
              <Stack direction='column'>
                <BBInput
                  name="lineTwo"
                  value={userAddress.lineTwo}
                  placeholder='Line Two'
                  onChange={updateAddress}
                />
              </Stack>
              <Stack direction='column'>
                <BBInput
                  name="city"
                  value={userAddress.city}
                  placeholder='City'
                  onChange={updateAddress}
                />
              </Stack>
              <Stack direction='column'>
                <BBInput
                  name="state"
                  value={userAddress.state}
                  placeholder='State'
                  onChange={updateAddress}
                />
              </Stack>
              <Stack direction='column'>
                <BBInput
                  name="zipCode"
                  value={userAddress.zipCode}
                  placeholder='Zip code'
                  onChange={updateAddress}
                />
              </Stack>              
              <Stack>
                <BBInput
                  name="country"
                  value={userAddress.country}
                  placeholder='Country'
                  onChange={updateAddress}
                />
              </Stack>
            </>
          }
        </SimpleGrid>
      </Box>
    </Flex>
  </>
}