import { Box, SimpleGrid, useColorModeValue,Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, Flex, Spacer, useToast, FormControl } from '@chakra-ui/react'
import React, {useState, useEffect, useContext} from 'react'
import { SiHive, SiMarketo, SiMicrosoft } from 'react-icons/si'
import BBButton from 'components/Button/Button'
import { PricingCard } from './PricingCard'
import BBDropdown from 'components/Dropdown/Dropdown'
import { getSubscriptions, saveBrandSubscription } from 'RestAPI'
import { SubscriptionPaymentModal } from '../Campaign/PaymentModal'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { updatePaymentStatus, updateSubscription, cancelSubscription, modifySubscription, getAllBrands, findSubscriptionByID } from 'RestAPI'
import { FormHeader } from 'layouts/FormLayout'
import { validateSubscription } from 'RestAPI'
import { AppContext } from 'contexts/AppContext'
import BBAutocomplete from 'components/Autocomplete/Autocomplete'

export const Subscription = () => {
    const [amount, setAmount] = useState(0)
    const [pricesMidtier, setPricesMidtier] = useState([])
    const [pricesEnterprise, setPricesEnterprise] = useState([])
    const [selectedPrices, setSelectedPrices] = useState([])
    const [userCount, setUserCount] = useState(1)
    const [creditCount, setCreditCount] = useState(5)
    const [servicesCount, setServicesCount] = useState(5)
    const [userPrice, setUserPrice] = useState(0)
    const [creditPrice, setCreditPrice] = useState(0)
    const [servicesPrice, setServicesPrice] = useState(0)
    const [showCheckoutModal, setShowCheckoutModal] = useState(false)
    const [subscriptionResponse, setSubscriptionResponse] = useState(null)
    const [searchParams] = useSearchParams()
    const paymentIntentFromStripe = searchParams.get('payment_intent')
    const pId = searchParams.get('paymentId')
    const stripeRedirect = searchParams.get('redirect_status')
    const navigate = useNavigate()
    const toast = useToast()
    const [showCancelSubscriptionModal, setShowCancelSubscriptionModal] = useState(false)
    const [loading, setLoading] = useState(false)
    const [existingSubscription, setExistingSubscription] = useState(null)
    const {userData} = useContext(AppContext)
    const [brandsList, setBrandList] = useState([])
    const [brandID, setBrandID] = useState(null)
    const {SubscriptionID} = useParams()

    useEffect(async() => {
        setLoading(true)
        if(userData.role === 'SUPER_ADMIN'){
            let response =  await getAllBrands()
            if (Array.isArray(response.data) && response.data.length) {
                let brands = response.data.map((brand) => {
                return {
                    id: Number(brand.id),
                    name: brand.brandName,
                    label: brand.brandName,
                    value: Number(brand.id),
                };
                });
                setBrandList(brands);
            }
        }
        let pricesRes = await getSubscriptions();
        let midtier = pricesRes.data.filter(pr => {
            return pr.nickname && pr.nickname.indexOf('midtier') > -1
        })
        let enterprise = pricesRes.data.filter(pr => pr.nickname?.indexOf('enterprise') > -1)
        setPricesMidtier(midtier)
        setPricesEnterprise(enterprise)
        let res = {}
        if(SubscriptionID && userData.role === 'SUPER_ADMIN'){
            res = await findSubscriptionByID(SubscriptionID)
        }else{
            res = await validateSubscription()
        }
        setLoading(false)
        if(res){
            setExistingSubscription(res)
            let userP = res.products?.filter(pr => pr.unitLabel === 'user')
            if(userP?.length){
                setUserCount(userP[0].count)
            }
            let creditP = res.products?.filter(pr => pr.unitLabel === 'credit')
            if(creditP?.length){
                setCreditCount(creditP[0].count)
            }
            let servicesP = res.products?.filter(pr => pr.unitLabel === 'hour')
            if(servicesP?.length){
                setServicesCount(servicesP[0].count)
            }            
        }
        if(res?.name === 'midtier'){
            setSelectedPrices(midtier)
        }else if(res?.name === 'enterprise'){
            setSelectedPrices(enterprise)
        }else{
            setSelectedPrices(midtier)
        }
        updatePrices(midtier)        
    },[])

    useEffect(()=>{
        updatePrices(selectedPrices)
    },[userCount, creditCount, servicesCount])

    useEffect(async() => {
        if(!paymentIntentFromStripe || !stripeRedirect) return
        setShowCheckoutModal(false)
        if(stripeRedirect.toLowerCase() === 'succeeded') {
          toast({
            title: 'Payment successful.',
            description: "You can now access the dashboard",
            status: 'success',
            position: 'top',
            duration: 3000,
            isClosable: true,
          })
          if(pId){
            updatePaymentStatus(pId, 'Succeeded', paymentIntentFromStripe)
            .then(res => navigate('/', {replace: true}))
            .catch(err => console.error(err))
          }
          let subscription = subscriptionResponse.subscription
          subscription.paymentId = pId
          await updateSubscription(subscription)
        }
    } , [paymentIntentFromStripe, stripeRedirect])

    const updatePrices = (prices) => {
        let userP = prices.find(pr => pr.nickname.indexOf('user') > -1)
        if(userP){
            setUserPrice(userP.unit_amount)
        }
        let creditP = prices.find(pr => pr.nickname.indexOf('credits') > -1)
        if(creditP){
            setCreditPrice(creditP.unit_amount)
        }
        let servicesP = prices.find(pr => pr.nickname.indexOf('services') > -1)
        if(servicesP){
            setServicesPrice(servicesP.unit_amount)
        }
    }

    const updateAmount = (type, count) => {
        if(type === 'user'){
            setAmount({...amount, userAmount: count * 19.99})
        }
    }

    const selectTier = (tier) => {
        if(tier === 'midtier'){
            setSelectedPrices(pricesMidtier)
        }else if(tier === 'enterprise'){
            setSelectedPrices(pricesEnterprise)
        }
    }

    const getCountByPrice = (price) => {
        if(price.nickname.indexOf('user') > -1){
            return userCount
        }else if(price.nickname.indexOf('credits') > -1){
            return creditCount
        }else if(price.nickname.indexOf('services') > -1){
            return servicesCount
        }
    }

    const confirmCancelSubscription = async() => {
        setShowCancelSubscriptionModal(false)
        await cancelSubscription(subscriptionResponse.subscription)
        navigate('/signout', { replace: true })
    }

    const handleCheckoutSubmit = async() => {
        // Create product objects
        setLoading(true)
        const products = selectedPrices.map(price => {
            return {
                productName: price.product.name,
                productPrice: price.unit_amount,
                stripePriceId: price.id,
                count: getCountByPrice(price),
                unitLabel: price.product.unit_label
            }
        })
        // Create subscription object
        const subscription = {
            name: selectedPrices[0].nickname.split('-')[0],
            totalMonthlyAmount: userCount*userPrice + creditCount*creditPrice + servicesCount*servicesPrice,
            duration: 12,
            products,
            brand: brandID ? {id: brandID} : null
        }
        if(existingSubscription){
            let userP = existingSubscription.products?.filter(pr => pr.unitLabel === 'user')
            let creditP = existingSubscription.products?.filter(pr => pr.unitLabel === 'credit')
            let servicesP = existingSubscription.products?.filter(pr => pr.unitLabel === 'hour')
            if((userP[0]?.count !== userCount) || 
            (creditP[0]?.count !== creditCount) ||
            (servicesP[0]?.count !== servicesCount)){
                let modifiedSubscription = existingSubscription
                modifiedSubscription.products = subscription.products
                modifiedSubscription.totalMonthlyAmount = subscription.totalMonthlyAmount
                modifiedSubscription.name = subscription.name
                await modifySubscription(existingSubscription.id, modifiedSubscription)
                toast({
                    title: 'Update successful.',
                    description: "Subscription is modified",
                    status: 'success',
                    position: 'top',
                    duration: 3000,
                    isClosable: true,
                })
                if(userData.role === 'SUPER_ADMIN'){
                    navigate('/subscriptions')
                }else{
                    navigate('/billing')
                }
            }else{
                toast({
                    title: 'No change.',
                    description: "Please check and update at least one quantity",
                    status: 'info',
                    position: 'top',
                    duration: 3000,
                    isClosable: true,
                })
            }
        }else{
            const subscriptionResponse = await saveBrandSubscription(subscription)
            if(subscriptionResponse?.payment?.paymentId){
                setSubscriptionResponse(subscriptionResponse)
                setShowCheckoutModal(true)
            }else{
                if(userData.role === 'SUPER_ADMIN'){
                    navigate('/subscriptions')
                }else{
                    navigate('/billing')
                }
            }
        }
        setLoading(false)
    }

    return (
        <>
        <Flex position={'fixed'} left={0} top={0} zIndex={10} width={'100%'} style={{backgroundColor:'white'}}>
            <FormHeader
                caption={existingSubscription ? 'Edit Subscription' : 'Subscription'}
                submitHandler={()=>handleCheckoutSubmit(false)}
                closeHandler={() =>  existingSubscription ? userData.role === 'SUPER_ADMIN' ? navigate('/subscriptions') : navigate('/billing') : navigate('/')}
                cancelHandler={() =>  existingSubscription ? userData.role === 'SUPER_ADMIN' ? navigate('/subscriptions') : navigate('/billing') : navigate('/')}
                loading={loading}
            />
        </Flex>
        <Box
            as="section"
            bg={useColorModeValue('gray.50', 'gray.800')}
            py="14"
            px={{
            base: '4',
            md: '8',
            }}
        >
            <SimpleGrid
            columns={{
                base: 1,
                lg: 3,
            }}
            spacing={{
                base: '8',
                lg: '0',
            }}
            maxW="7xl"
            mx="auto"
            justifyItems="center"
            alignItems="center"
            >
                <PricingCard
                    data={{
                    price: 'Free',
                    name: 'Standard',
                    features: [
                        'Pay per gig',
                        'Free mobile app',
                        'Free Updates',
                    ],
                    }}
                    icon={SiMicrosoft}
                    button={
                    <BBButton variant="outline" 
                    borderWidth="2px" 
                    size="lg" 
                    w="full" 
                    fontWeight="extrabold"
                    py={{
                        md: '8',
                    }}>
                        Select
                    </BBButton>
                    }
                />
                <PricingCard
                    zIndex={1}
                    isPopular
                    transform={{
                    lg: 'scale(1.05)',
                    }}
                    data={{
                    price: '$19',
                    name: 'Midtier',
                    features: [
                        'Basic dashboard access',
                        'Campaign & Gig management',
                        'Access to Managed services',
                        'Free Updates',
                    ],
                    }}
                    icon={SiHive}
                    button={<BBButton
                        borderWidth="2px" 
                        size="lg" 
                        w="full" 
                        fontWeight="extrabold"
                        py={{
                        md: '8',
                        }}
                        onChange={() => selectTier('midtier')}>Select</BBButton>}
                />
                <PricingCard
                    data={{
                    price: '$29',
                    name: 'Enterprise',
                    features: [
                        'Advanced features',
                        'Manage Campaigns, Projects',
                        'Manage Teams, Groups',
                    ],
                    }}
                    icon={SiMarketo}
                    button={
                    <BBButton variant="outline"
                    borderWidth="2px" 
                    size="lg" 
                    w="full" 
                    fontWeight="extrabold"
                    py={{
                        md: '8',
                    }}
                    onChange={() => selectTier('enterprise')}>
                        Select
                    </BBButton>
                    }
                />
            </SimpleGrid>
            {userData.role === 'SUPER_ADMIN' ? 
            <>
            {SubscriptionID ? <Text>Brand: {existingSubscription?.brand?.brandName}</Text> : <FormControl style={{ margin: 16 }}>
                <BBAutocomplete
                width={{ base: "50%", sm: "100%" }}
                height="50px"
                options={brandsList}
                isMultiple={false} 
                label='Brand'
                onChange={(value, item) => {                
                    setBrandID(item?.originalValue.id)
                }} />
            </FormControl> }
            </> : null}
            <Box>
                <Flex flexDirection={'row'} alignItems="center"  mt={8}>
                    <Text width={'full'}>How many users will need access to dashboard?</Text>
                    <BBDropdown  ml={8}
                        options={[{label: 1, value: 1},{label:2, value:2},{label:3, value:3},{label:4,value:4},{label:5,value:5}]}
                        value={userCount}
                        onChange={(e) => setUserCount(parseInt(e.target.value))}
                    ></BBDropdown>
                    <Text ml={8} width={'full'}>${userPrice/100} /user</Text>
                </Flex>
                <Flex flexDir={'row'} alignItems="center" mt={8}>
                    <Text width={'full'}>How many credits would be used per month?</Text>
                    <BBDropdown  ml={8}
                        options={[{label: 5, value: 5},{label:10, value:10},{label:15, value:15},{label:20,value:20},{label:25,value:25}]}
                        value={creditCount}
                        onChange={(e) => setCreditCount(parseInt(e.target.value))}
                    ></BBDropdown>
                    <Text ml={8} width={'full'}>${creditPrice/100} /credit</Text>
                </Flex>
                <Flex flexDir={'row'} alignItems="center" mt={8}>
                    <Text width={'full'}>How many hours of Managed services needed from Brandbass?</Text>
                    <BBDropdown ml={8}
                        options={[{label: 5, value: 5},{label:10, value:10},{label:15, value:15},{label:20,value:20},{label:25,value:25}]}
                        value={servicesCount}
                        onChange={(e) => setServicesCount(parseInt(e.target.value))}
                    ></BBDropdown>
                    <Text ml={8} width={'full'}>${servicesPrice/100} /hour</Text>
                </Flex>
            </Box>
            <Flex flexDir={'column'} width={'full'} mt={16} alignSelf={'center'} alignItems='center' flex={1} gap={8}>
                <Flex gap={8}>
                    <Text fontSize="2xl">Total Monthly Amount: ${(userCount*userPrice + creditCount*creditPrice + servicesCount*servicesPrice) /100}</Text>
                    <BBButton size={'lg'} alignSelf='center' onClick={handleCheckoutSubmit}>
                        {existingSubscription ? 'Update subscription' : userData.role === 'SUPER_ADMIN' ? 'Save Subscription' : 'Start checkout'}
                    </BBButton>
                </Flex>
            </Flex>
            {showCheckoutModal && <SubscriptionPaymentModal
                visible={showCheckoutModal}
                closeHandler={(e, proceedNext) => {
                    setShowCheckoutModal(false);
                    setShowCancelSubscriptionModal(true);    
                }}
                successHandler={() => {
                    setShowCheckoutModal(false)
                }}
                paymentDetails={{...subscriptionResponse.payment, amount: subscriptionResponse.subscription.totalMonthlyAmount}}
                subscription={subscriptionResponse.subscription}
                subscriptionIntent={subscriptionResponse.intent}
            />}
            {showCancelSubscriptionModal && 
            <Modal isOpen={showCancelSubscriptionModal} size="xl" scrollBehavior='outside' closeOnOverlayClick={false} >
                <ModalOverlay />
                <ModalContent>
                <ModalHeader color='brandbassBlue'>Cancel Subscription</ModalHeader>
                <ModalBody pb={6}>
                    <Flex p={8} borderColor='grayBlue' borderWidth="1px" borderRadius="lg" alignItems='center' justifyContent='center'>
                        <Text fontSize='20px'>
                        Do you want to cancel subscription? You cannot access website and will have access to mobile app only. You will be logged out immediately.
                        </Text>
                    </Flex>
                </ModalBody>
                <ModalFooter>          
                    <Button
                    colorScheme="brandbassBlue"
                    variant="outline"
                    borderRadius="lg" mr={3}
                    onClick={() => confirmCancelSubscription()}
                    >
                    Yes (Logout)
                    </Button>

                    <Button
                    colorScheme="brandbassBlue"

                    borderRadius="lg"
                    onClick={() => setShowCheckoutModal(true)}
                    >
                    Back to checkout
                    </Button>
                </ModalFooter>
                </ModalContent>
            </Modal>
            }
    </Box>
  </>)
}

export const SubscriptionModal = ({showModal, closeHandler}) => {
    return (
        <>
    <Modal isOpen={showModal} onClose={closeHandler} size="6xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader color='brandbassBlue'>
            {/* <Text fontSize='2xl' fontFamily='Rubik' >Subscription</Text> */}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
              <Subscription/>
          </ModalBody>
        </ModalContent>
    </Modal>
    </>
    )
}