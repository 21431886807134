import { Box, Button, Flex, Grid, GridItem, Image, Tab, TabList, TabPanel, TabPanels, Tabs, Text, Spacer, CircularProgress, Spinner, SimpleGrid, IconButton, Tag, Badge, useToast } from "@chakra-ui/react"
import BBButton from "components/Button/Button"
import AmbassadorCard from "components/Card/AmbassadorCard"
import GigDetailsCard from "components/Card/GigDetailsCard"
import Chat from "components/Chat/Chat"
import { CopyIcon } from "components/Icons/Icons"
import { EditIcon } from "components/Icons/Icons"
import { BeeIcon } from "components/Icons/Icons"
import { TrashIcon } from "components/Icons/Icons"
import { AppContext } from "contexts/AppContext"
import { useContext, useEffect, useState } from "react"
import { useParams, useNavigate, useLocation } from "react-router-dom"
import { getCampaign, saveCampaignInvite } from "RestAPI"
import FeedbackForm from "../Feedback/FeedbackForm"
import IssueForm from "../Issues/IssueForm"
import { AmbassadorInviteModal } from "./CampaignForm"
import { CloneCampaignModal, DeleteCampaignModal } from "./CampaignList"
import CampaignOverview from "./CampaignOverview"
import CampaignSubmissions from "./CampaignSubmissions"
import { StarIcon } from "@chakra-ui/icons";
import { RecommendedAmbassadors } from "./RecommendedAmbassadors"
import { globalStyles } from "theme/styles"
import { BBSecondaryButton, BBSecondaryButtonWithoutHover } from "components/Button/Button"
import NoInvites from "assets/svg/emptyList/NoInvites.svg"
import NoGigs from "assets/svg/emptyList/NoGigs.svg"
import moment from "moment"
import "./Campaign.css"
import FileAttachment from "views/Auth/FileAttachment"

const CampaignDetails = () => {
    const { CampaignID } = useParams()
    const { placeholderImage, userData } = useContext(AppContext)
    const [campaign, setCampaign] = useState(null)
    const [loading, setLoading] = useState(false)
    const [ambassador, setAmbassador] = useState(null)
    const navigate = useNavigate()
    const location = useLocation()
    const displayConversation = location.state?.displayConversation
    const [showCloneCampaignModal, setShowCloneCampaignModal] = useState(false)
    const [showDeleteCampaignModal, setShowDeleteCampaignModal] = useState(false)
    const [showAmbassadorModal, setShowAmbassadorModal] = useState(false)
    const [showIssueForm, setShowIssueForm] = useState(false)
    const [showFeedbackForm, setShowFeedbackForm] = useState(false)
    const feedbackType = userData.role.toLowerCase().includes('brand') ? 'Ambassador' : 'Campaign'
    const toast = useToast()
    const fetchCampaignDetails = async () => {
        const response = await getCampaign(CampaignID)
        if (response.data && response.data.invites) {
            let match = response.data.invites.filter(inv => inv.inviteStatus === 'Accepted')
            if (match && match.length) {
                setAmbassador(match[0].user)
            }
        }
        if (response.data && !response.data.rating && response.data.campaignStatus === 'Closed') {
            setShowFeedbackForm(true)
        }
        if(userData.role === 'AMBASSADOR' && response.data){
            let compensation = 0;
            for(let gig of response.data?.gigs){
              compensation += gig.ambassadorFee
              if(gig.giveawayValue && (gig.giveawayType === 'Cash Tip' || gig.giveawayValue === 'Alternate Sample')){
                compensation += gig.giveawayValue;
              }
              if(gig.productGiveawayValue){
                compensation += gig.productGiveawayValue;
              }
            }
            response.data.compensation = compensation
        }
        setCampaign(response.data && response.data.id ? response.data : null)
    }

    const statusColor = (status) => {
        if (!status) return null
        switch (status) {
            case 'Invited': return 'statusYellow'
            case 'Draft': return 'statusGray'
            case 'Attn. Needed': return 'statusRed'
            case 'Pending Payment': return 'statusRed'
            case 'Pending Invites': return 'statusRed'
            case 'Closed': return 'secondary'
            default: return 'statusGreen'
        }
    }

    useEffect(() => {
        fetchCampaignDetails()
    }, [CampaignID])

    const acceptInvite = (status) => {
        const invites = [{ campaign: { id: campaign.id }, inviteStatus: status }];
        setLoading(true);
        saveCampaignInvite(invites)
            .then(response => {
                fetchCampaignDetails()
                setLoading(false)
            }).catch(err => setLoading(false))
    }

    const createIssue = () => {
        navigate('/new/issue/', { state: { issueData: { campaign } } })
    }

    let status = campaign?.campaignStatus
    if (status === 'Created' && !campaign?.payment?.length) {
        status = 'Pending Payment'
    } else if (!campaign?.invites?.length || (campaign.invites?.length && !campaign.invites.filter(inv => inv.inviteStatus !== 'Rejected')?.length)) {
        status = 'Pending Invites'
    }
    
    if(campaign){
        campaign.createdOn = moment(campaign.createdOn).format("L LT")
        campaign.updatedOn = moment(campaign.updatedOn).format("L LT")        
    }

    return (!CampaignID || isNaN(CampaignID) || !campaign) ? null : <>
        {/* <IssueForm visible={showIssueForm} closeHandler={() => setShowIssueForm(false)} campaign={campaign} /> */}
        <FeedbackForm visible={showFeedbackForm} closeHandler={() => { setShowFeedbackForm(false); fetchCampaignDetails() }} campaign={campaign} feedbackType={feedbackType} />
        <Flex flexDirection='column' gap='2rem'>
            <Text fontSize={16} fontWeight='600' >Campaign Overview</Text>
            <Flex id="campaign-details" flexDirection={{ base: 'column', xl: 'row' }} gap='1.5rem'>
                <Flex as='div'
                    flexDirection='column'
                    backgroundColor='white'
                    minH='700px'
                    w={{ base: 'full', xl: '65%' }}
                    overflow='scroll'
                    borderRadius='3xl'
                    p={50}
                    gap='80px'
                >
                    <Flex gap='1.5rem' minW='full'>
                        <Image w={150} h={150} borderRadius={10} src={campaign.imageUrl} fallbackSrc={placeholderImage(150)} />
                        <Flex flexDirection='column' flex={1} gap={2}>
                            <Text fontWeight='500' fontSize='20px' color={'primary'}>{campaign.name}</Text>
                            {userData.role === 'AMBASSADOR' ? 
                            <Flex gap={2}>
                                <Text color={'gray3'}>Compensation:</Text>
                                <Text color={'primary'}>${campaign.compensation}</Text>
                            </Flex>:
                            // <Flex gap={2}>
                            //     <Text color={'gray3'}>Budget:</Text>
                            //     <Text color={'primary'}>${campaign.budget}</Text>
                            // </Flex>
                            null
                            }
                            <Badge mt={2} px={2} py={1}  bgColor={'secondary'} rounded='md' maxW='fit-content'  fontSize='md'>
                                {campaign.ambassadorFlag}
                            </Badge>
                            {!!campaign.rating ? <Flex gap='1rem' mt={2}>
                                
                                     {/* !!campaign.rating && [...Array(5)].map((e, i) => <StarIcon
                                        key={i}
                                        cursor='pointer'
                                        w='2rem'
                                        h='2rem'
                                        color={i + 1 <= campaign.rating ? 'yellow.500' : 'white'}
                                        stroke={i + 1 > campaign.rating ? 'yellow.500' : 'white'}
                                    />) */}
                                    <StarIcon color={'yellow.500'} w='2rem' h='2rem'/>
                                    <Text mt={2}>{campaign.rating}/5</Text>
                                
                            </Flex>: null}
                        </Flex>

                        <Flex justifySelf='flex-end' justifyContent='flex-end' flex={1} gap={2}>
                            <Flex flexDirection='column' gap='2rem'>
                                <Flex gap={2} justify='flex-end' align='center'>
                                    {
                                        userData.role === 'AMBASSADOR' && campaign.campaignStatus === 'Created'
                                            ?
                                            <>
                                                {loading ? <Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='brandbassBlue' size='xl' /> : <> <Button float="right" onClick={() => acceptInvite('Accepted')}>Accept </Button>
                                                    <Button float="right" onClick={() => acceptInvite('Rejected')}>Decline </Button> </>}
                                            </>
                                            : null
                                    }

                                    {
                                        ((userData.role === 'BRAND_MANAGER' && userData.id === campaign.createdBy.id) || userData.role === 'BRAND_SUPER_ADMIN' || userData.role === 'CLIENT_SUPER_ADMIN') && (campaign.campaignStatus === 'Created' || campaign.campaignStatus === 'Draft')
                                            ?
                                            <>
                                                {loading ? <Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='brandbassBlue' size='xl' /> : <>
                                                    {campaign.payment?.length ? null :
                                                        <IconButton onClick={() => setShowDeleteCampaignModal(true)}
                                                            variant='outline' size="md"
                                                            colorScheme='teal'
                                                            aria-label='Send email'
                                                            icon={<TrashIcon />}
                                                        />}
                                                    <IconButton onClick={() => navigate(`/edit/campaign/${campaign.id}`)}
                                                        variant='outline' size="md"
                                                        colorScheme='teal'
                                                        aria-label='Send email'
                                                        icon={<EditIcon />}
                                                    />
                                                </>
                                                }
                                            </>
                                            : null
                                    }
                                    {/* <Flex gap='0.25rem' flex={1} justifyContent='flex-end'> */}
                                    {userData.role === 'BRAND_MANAGER' || userData.role === 'BRAND_SUPER_ADMIN' || userData.role === 'CLIENT_SUPER_ADMIN' &&
                                        <IconButton onClick={() => setShowCloneCampaignModal(true)}
                                            variant='outline' size="md"
                                            colorScheme='teal'
                                            aria-label='Send email'
                                            icon={<CopyIcon />}
                                        />}
                                    {campaign.campaignStatus === 'Closed' ? null : <IconButton onClick={createIssue}
                                        variant='outline' size="md"
                                        colorScheme='teal'
                                        aria-label='Send email'
                                        icon={<BeeIcon />}
                                    />}

                                    {
                                        !!campaign && !campaign.rating && campaign.campaignStatus === 'Closed' && <IconButton onClick={() => setShowFeedbackForm(true)}
                                            variant='outline' size="md"
                                            colorScheme='teal'
                                            aria-label='Send email'
                                            icon={<StarIcon />}
                                        />
                                    }
                                </Flex>
                                <Flex justify='space-between' alignSelf='flex-end' flexDirection='column' flex={1}>
                                    <Badge px={2} py={1} textAlign='center' bgColor={() => statusColor(status)} rounded='md' maxW='fit-content' alignSelf='flex-end' fontSize='md'>
                                        {
                                            userData.role === 'AMBASSADOR' && campaign.campaignStatus === 'Created'
                                                ? 'Invite Pending'
                                                : ['Pending Payment', 'Pending Invites'].includes(status)
                                                    ? 'Attn. Needed'
                                                    : status
                                        }
                                    </Badge>
                                   

                                    {
                                        (['Pending Payment', 'Pending Invites'].includes(status)) && <>
                                            <Text fontSize='sm' fontWeight='500'>
                                                Action needed: <BBSecondaryButton size='xs' onClick={() => {
                                                    if(status === 'Pending Payment'){
                                                        navigate(`/edit/campaign/${campaign.id}`)
                                                    }else{
                                                        setShowAmbassadorModal(true)
                                                    }
                                                }}>
                                                    {status === 'Pending Payment' ? 'Make payment' : 'Invite ambassadors'}
                                            </BBSecondaryButton>
                                            </Text>
                                        </>
                                    }
                                </Flex>
                            </Flex>
                        </Flex>
                    </Flex>
                    <Tabs isLazy   defaultIndex={displayConversation?4:0}>
                        <TabList pb='5px' borderColor='black'>
                            <Tab fontWeight='700' textTransform='uppercase' pt='8px' pb='5px' h='30px' borderRadius='10px' _selected={{ color: 'white', bg: 'primary' }}>Info</Tab>
                            {campaign.attachments?.length ? <Tab fontWeight='700' textTransform='uppercase' pt='8px' pb='5px' h='30px' borderRadius='10px' _selected={{ color: 'white', bg: 'primary' }}>Attachments</Tab> : null}
                            {userData.role === 'AMBASSADOR' ? null : <Tab isDisabled={userData.role === 'AMBASSADOR'} fontWeight='700' textTransform='uppercase' pt='8px' pb='5px' h='30px' borderRadius='10px' _selected={{ color: 'white', bg: 'primary' }}>Invites</Tab>}
                            <Tab fontWeight='700' textTransform='uppercase' pt='8px' pb='5px' h='30px' borderRadius='10px' _selected={{ color: 'white', bg: 'primary' }}>Gigs</Tab>
                            <Tab isDisabled={!campaign.submissions || !campaign.submissions.length} fontWeight='700' textTransform='uppercase' pt='8px' pb='5px' h='30px' borderRadius='10px' _selected={{ color: 'white', bg: 'primary' }}>Submissions</Tab>
                            <Tab isDisabled={!ambassador} fontWeight='700' textTransform='uppercase' pt='8px' pb='5px' h='30px' borderRadius='10px' _selected={{ color: 'white', bg: 'primary' }}>Chat</Tab>
                        </TabList>
                        <TabPanels pt='32px'>
                            <TabPanel>
                                <Flex flexDirection={'column'} >
                                    <Flex flexDirection='column' boxShadow='base' py={3} px={2} gap='0.5rem'>
                                        <Flex gap={2}>
                                            <Text fontSize={14} color={'gray3'}>Category:</Text>
                                            <Spacer/>
                                            <Text color={'primary'}>{campaign.categories?.length ? campaign.categories[0].name : '-'}</Text>
                                        </Flex>
                                        <Flex gap={2}>
                                            <Text fontSize={14} color={'gray3'}>Criteria:</Text>
                                            <Spacer/>
                                            <Text color={'primary'}>{campaign.ambassadorLocationType}</Text>
                                            {campaign.ambassadorLocationType === 'Location-specific' ?  
                                            <Text color={'primary'}>, Zip: {campaign.location}</Text> : null}
                                        </Flex>
                                        <Flex gap={2}>
                                            <Text fontSize={14} color={'gray3'}>Gender:</Text>
                                            <Spacer/>
                                            <Text color={'primary'}>{campaign.ambassadorCriteriaGender}</Text>
                                        </Flex>
                                        <Flex gap={2}>
                                            <Text fontSize={14} color={'gray3'}>Age:</Text>
                                            <Spacer/>
                                            <Text color={'primary'}>{campaign.ambassadorCriteriaAgeFrom} - {campaign.ambassadorCriteriaAgeTo}</Text>
                                        </Flex>
                                        {/* <Flex gap='5px' align='center' justify='space-between'>
                                            <Text fontWeight='500' fontSize='15px'>Category:</Text>
                                            <Box boxShadow='xs' p={1} rounded='sm' cursor='pointer' fontSize='xs'>{campaign.categories?.length ? campaign.categories[0].name : '-'}</Box>
                                        </Flex> */}
                                        {/* <Box>
                                            <Text fontWeight='500' fontSize='15px' >Criteria:</Text>
                                            <Flex flexDirection='row' pl={4} gap={8}>
                                                <Flex gap='5px' align='center' justify='space-between'>
                                                    <Text fontWeight='400' fontSize='xs'>Location: </Text>
                                                    <Box boxShadow='xs' p={1} rounded='sm' cursor='pointer' fontSize='xs'>{campaign.ambassadorLocationType}</Box>
                                                </Flex>
                                                {campaign.ambassadorLocationType === 'Location-specific' ?  <Flex gap='5px' align='center' justify='space-between'>
                                                    <Text fontWeight='400' fontSize='xs'>Zip: </Text>
                                                    <Box boxShadow='xs' p={1} rounded='sm' cursor='pointer' fontSize='xs'>{campaign.location}</Box>
                                                </Flex> : null}
                                                <Flex gap='5px' align='center' justify='space-between'>
                                                    <Text fontWeight='400' fontSize='xs'>Gender: </Text>
                                                    <Box boxShadow='xs' p={1} rounded='sm' cursor='pointer' fontSize='xs'>{campaign.ambassadorCriteriaGender}</Box>
                                                </Flex>
                                                <Flex gap='5px' align='center' justify='space-between'>
                                                    <Text fontWeight='400' fontSize='xs'>Age: </Text>
                                                    <Box boxShadow='xs' p={1} rounded='sm' cursor='pointer' fontSize='xs'>{campaign.ambassadorCriteriaAgeFrom} - {campaign.ambassadorCriteriaAgeTo}</Box>
                                                </Flex>
                                                
                                            </Flex>
                                        </Box> */}
                                        <Flex gap={2}>
                                            <Text fontSize={14} color={'gray3'}>Created On:</Text>
                                            <Spacer/>
                                            <Text color={'primary'}>{moment.utc(campaign.createdOn+'+00:00').local().format('DD MMM YYYY hh:mm A')}</Text>
                                        </Flex>
                                        <Flex gap={2}>
                                            <Text fontSize={14} color={'gray3'}>Last Updated:</Text>
                                            <Spacer/>
                                            <Text color={'primary'}>{moment.utc(campaign.updatedOn+'+00:00').local().format('DD MMM YYYY hh:mm A')}</Text>
                                        </Flex>
                                        <Flex gap={2}>
                                            <Text fontSize={14} color={'gray3'}>Created By:</Text>
                                            <Spacer/>
                                            <Text color={'primary'}>{campaign.createdBy?.firstName} {campaign.createdBy?.lastName}</Text>
                                        </Flex>
                                        {userData.role === 'AMBASSADOR' ? null :
                                        <Flex gap={2}>
                                            <Text fontSize={14} color={'gray3'}>Credits:</Text>
                                            <Spacer/>
                                            <Text color={'primary'}>{campaign.gigs?.filter(g => !!g.credit && g.gigVariation==='Content')?.length} Content</Text>
                                            <Text color={'primary'}>{campaign.gigs?.filter(g => !!g.credit && g.gigVariation==='Publish')?.length} Publish</Text>
                                        </Flex>}
                                        {userData.role === 'AMBASSADOR' ? null :
                                        <Flex gap={2}>
                                            <Text fontSize={14} color={'gray3'}>Amount paid:</Text>
                                            <Spacer/>
                                            <Text color={'primary'}>${campaign.amount}</Text>
                                        </Flex>}
                                        <Flex gap={2}>
                                            <Text fontSize={14} color={'gray3'}>Project:</Text>
                                            <Spacer/>
                                            <Text color={'primary'}>{campaign.campaignSet?.project?.projectName} - {campaign.campaignSet?.campaignSetName} </Text>
                                        </Flex>
                                        <Flex gap={2}>
                                            <Text fontSize={14} color={'gray3'}>Groups:</Text>
                                            <Spacer/>
                                            <Text color={'primary'}>{campaign.groups?.map(g => g.groupName).join(', ')} </Text>
                                        </Flex>
                                    </Flex>
                                </Flex>
                            </TabPanel>
                            {campaign.attachments?.length ? 
                            <TabPanel>
                                {/* <Flex flexDirection={'column'} alignItems={'center'}> */}
                                    <FileAttachment attachments={campaign.attachments}/>
                                {/* </Flex> */}
                            </TabPanel> : null}
                            {userData.role === 'AMBASSADOR' ? null : 
                            <TabPanel>
                                <Flex flexDirection={'column'} alignItems={'center'}>
                                    <Flex flexWrap='wrap' gap='1rem' alignSelf={'start'}>
                                        {campaign.invites?.length ? campaign.invites.map((item, idx) => 
                                        <AmbassadorCard key={idx} invite={item} colorIndex={Math.abs(3 - idx)} />) 
                                        : null }
                                        {!campaign.invites?.length ? 
                                        <Flex flexDirection='column' gap={2} borderColor='primary' borderWidth='2px' borderStyle='solid' p='2rem' align='center' rounded='sm'>
                                            <Image src={NoInvites} w='200px' />
                                            <Text color='primary' fontSize='xl' fontWeight='500'>No invites at the moment</Text>
                                            {campaign.payment?.length ? <BBButton onClick={() => setShowAmbassadorModal(true)}>Invite ambassadors</BBButton> : null}
                                        </Flex> : null}
                                    </Flex>
                                    {(campaign.payment?.length && campaign.invites?.length && !campaign.invites?.filter(inv => inv.inviteStatus !== 'Rejected')?.length) || 
                                    (!campaign.invites?.filter(inv => inv.inviteStatus !== 'Rejected')?.length && userData.role === 'SUPER_ADMIN') ? 
                                    <BBButton mt={4} onClick={() => setShowAmbassadorModal(true)}>Invite ambassadors</BBButton> : null}
                                    
                                </Flex>
                            </TabPanel>}
                            <TabPanel>
                                <Flex flexWrap='wrap' gap='1rem' w='full'>
                                    {
                                        !!campaign.gigs.length
                                            ? <Flex gap='1rem' flex={1}>
                                                {
                                                    campaign.gigs.sort((a,b) => a.key - b.key).map((item, idx) => <GigDetailsCard key={idx} gig={item} campaign={campaign} refreshCampaign={fetchCampaignDetails} />)
                                                }
                                            </Flex>
                                            : <Flex flexDirection='column' gap={2} borderColor='primary' borderWidth='2px' borderStyle='solid' p='2rem' align='center' rounded='sm'>
                                                <Image src={NoGigs} w='200px' />
                                                <Text color='primary' fontSize='xl' fontWeight='500'>No gigs at the moment</Text>
                                            </Flex>
                                    }
                                </Flex>
                            </TabPanel>
                            <TabPanel>
                                <CampaignSubmissions gigs={campaign.gigs} submissions={campaign.submissions} refreshCampaign={fetchCampaignDetails} />
                            </TabPanel>
                            <TabPanel>
                                <Chat moduleId={campaign.id} module='campaign' conversationId={campaign.conversation?.id} messages={campaign.messages} receiver={ambassador} width={500} height={600} disableChat={campaign.campaignStatus==='Closed' || (userData.role !== 'AMBASSADOR' && campaign.createdBy?.id !== userData.id)}/>
                            </TabPanel> 
                        </TabPanels>
                    </Tabs>
                    <RecommendedAmbassadors
                                    visible={showAmbassadorModal}
                                    closeHandler={(ev, refresh) => {
                                        if (refresh) {
                                            fetchCampaignDetails();
                                        } else {
                                            toast({
                                                title: 'Ambassadors not chosen yet',
                                                description: "Please review and invite upto 3 ambassadors.",
                                                status: 'error',
                                                position: 'top',
                                                duration: 3000,
                                                isClosable: true,
                                            })
                                        }
                                        setShowAmbassadorModal(false)
                                    }}
                                    campaign={campaign}
                                />
                </Flex>
                <CampaignOverview campaign={campaign} />
            </Flex>
            <DeleteCampaignModal
                visible={showDeleteCampaignModal}
                closeHandler={(refresh) => {
                    setShowDeleteCampaignModal(false)
                    if (refresh) {
                        navigate('/campaigns')
                    }
                }}
                campaignId={campaign.id}
            />
            <CloneCampaignModal
                visible={showCloneCampaignModal}
                closeHandler={(clonedCampaignId) => {
                    setShowCloneCampaignModal(false)
                    if (clonedCampaignId) {
                        navigate(`/edit/campaign/${clonedCampaignId}`)
                    }
                }}
                campaignId={campaign.id}
            />
        </Flex>
    </>
}

export default CampaignDetails
