import {
    Avatar,
    Badge,
    Button,
    Flex,
    Text,
    useColorModeValue,
    AvatarGroup,
    Table,
    Thead,
    Th,
    Box,
    SimpleGrid,
    Stack,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    TableContainer, Tbody, Tr, Td, Tag, Spacer, TagLabel,SkeletonText,
    Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter
} from "@chakra-ui/react";
import ShowUserForm from "views/Dashboard/Users/components/ShowUser";

import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import moment from "moment";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom"
import { getGroup } from "RestAPI";
import UserList from "views/Dashboard/Users/components/UserList";

const ViewGroup = (props) => {
    const textColor = useColorModeValue("gray.700", "white");
    const [group, setGroup] = useState({})
    const { GroupID } = props
    const [loading,setLoading]=useState(false)
    const [showUser, setShowUser] = useState(false);
    const [userID, setUserID] = useState(null);

    useEffect(() => {
        setLoading(true)
        getGroup(GroupID).then(response => {
            if (response && !response.error) {
                setLoading(false)
                setGroup(response)
            }else{
                setLoading(false)
            }
        })
    }, [])

    return (
        <>
            <ModalOverlay />
            <ModalContent overflow='scroll' width={"100%"}>
                <ModalHeader color='brandbassBlue'>Group Details</ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={6}>
                {
                loading && (
                    <Flex align="center" minWidth="100%" flexWrap="nowrap">
                        <Box padding='6' boxShadow='lg' minWidth="100%">
                            <SkeletonText mt='4' noOfLines={4} spacing='4' />
                        </Box>
                    </Flex>
                )
            }
                {!loading && <Flex flexDirection={{ base: 'column', xl: 'row' }} gap='1.5rem' width={"100%"}> 
                    <Flex as='div'
                        flexDirection='column'
                        w={ '100%'}
                        overflow='scroll'
                        borderRadius='3xl'
                        p={{base:'10px',md:'20px'}}
                        gap='20px'
                    >
                        <Flex gap='1rem' width={"100%"}>
                                <Flex flexDirection='column' gap={'4px'}>
                                    <Text fontWeight='500' fontSize='18px' lineHeight='24px'>
                                        Name
                                    </Text>
                                    <Text fontSize='14px' lineHeight='24px' color='brandbassGray.500' >
                                        {group && group.groupName ?group.groupName:'NA' } 
                                    </Text>
                                    <Tag  mt={"5px"} textAlign={'center'} minW={20} backgroundColor={group.isActive ? "statusGreen" : "statusGray"}><Spacer /><TagLabel textAlign={'center'}>{group.isActive ? "Active" : "InActive"}</TagLabel><Spacer /></Tag>
                            
                                </Flex>
                            <Spacer />
                            <Flex flexDirection='column'>
                                <Avatar size="xl" name={group.groupName} />
                                </Flex>
                        </Flex>
                            <Flex flexDirection='row'>
                                <Flex flexDirection='column' gap={'4px'}>
                                    <Text fontWeight='500' fontSize='18px' lineHeight='24px'>
                                        Description
                                    </Text>
                                    <Text fontSize='14px' lineHeight='24px' color='brandbassGray.500' >
                                        {group && group.description ? group.description : 'NA'}
                                    </Text>
                                </Flex>
                            </Flex>
                                <Flex flexDirection='column' gap={"4px"}>
                                    <Text fontWeight='500' fontSize='18px' lineHeight='24px'>
                                        owner
                                    </Text>
                                    <Text fontSize='14px' lineHeight='24px' color='brandbassGray.500' >
                                        {group.owner ? `${group.owner.firstName} ${group.owner.lastName}` : 'NA'}
                                    </Text>
                                </Flex>
                        <Flex flexDirection='column' gap='1rem'>
                            <Accordion allowToggle width={"100%"}>
                                <AccordionItem width={"100%"}>
                                    <h2>
                                        <AccordionButton width={"100%"}>
                                            <Box flex='1' textAlign='left'>
                                                <Flex flexDirection='column'>
                                                    <Flex align='center'>
                                                        <Text whiteSpace={"nowrap"} mr={"5px"}>Members</Text>
                                                        <Flex mr={'10px'} align='center' justifySelf='flex-end' justifyContent={{ base: 'flex-start', md: 'flex-end' }} flex={1} gap={10}>
                                                            {/* <Tag textAlign={'center'} minW={20} backgroundColor={'statusGreen'}><Spacer /><TagLabel textAlign={'center'}>{'created'}</TagLabel><Spacer /></Tag> */}
                                                            <Text>{group.users?.length}</Text>
                                                        </Flex>
                                                    </Flex>
                                                </Flex>
                                            </Box>
                                            <AccordionIcon />
                                        </AccordionButton>
                                    </h2>
                                    <AccordionPanel width={"100%"}>
                                        {group.users && !!group.users.length ? <>
                                            <Flex width={"100%"}>
                                                <TableContainer width={"100%"} overflow={"auto"}>
                                                    <Table variant='simple'>
                                                        <Tbody >
                                                            {group.users.map((user, index) => {
                                                                return (
                                                                <Tr key={index} overflow={"auto"}> 
                                                                    <Td>
                                                                        <Flex cursor="pointer" align="center" minWidth="100%" flexWrap="nowrap" >
                                                                            <Avatar name={user.firstName + " " + user.lastName} src={user.profileImageUrl} w="50px" borderRadius="50px" me="10px" onClick={() => {setUserID(user.id);setShowUser(true)}}/>
                                                                            <Flex direction="column">
                                                                                <Text fontSize='14px' lineHeight='24px'
                                                                                    fontSize="md"
                                                                                    fontWeight="normal"
                                                                                    minWidth="100%"
                                                                                >
                                                                                    {user ? user.firstName + " " + user.lastName : 'NA'}
                                                                                </Text>
                                                                            </Flex>
                                                                        </Flex>
                                                                    </Td>
                                                                    <Td>
                                                                        <Text color="black3" fontWeight="600" fontSize='14px' lineHeight='24px' textAlign={'center'}>
                                                                            {user.role ? user.role.replaceAll("_"," ") : 'NA'}
                                                                        </Text>
                                                                    </Td>
                                                                </Tr>)
                                                            })}
                                                        </Tbody>
                                                    </Table>
                                                </TableContainer>
                                            </Flex>
                                        </> : <Text>No Members</Text>}
                                    </AccordionPanel>
                                </AccordionItem>
                            </Accordion>
                        </Flex>
                    </Flex>
                    </Flex>}
                    <Modal size={'3xl'} isOpen={showUser} onClose={() => setShowUser(false)}>
                        <ShowUserForm onClose={() => setShowUser(false)} UserID={userID} />
                    </Modal>
                </ModalBody>
                <ModalFooter>
                </ModalFooter>
            </ModalContent>
        </>
        
    )
}
export default ViewGroup;