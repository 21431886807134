import Card from "components/Card/Card";
import { AppContext } from "contexts/AppContext";
import { useContext, useEffect, useRef, useState } from "react"
import { allMyConversations, createOrFindConversation } from "RestAPI";
import { Text, useColorModeValue, SkeletonText, Flex, Box, Center, Image, CloseButton } from "@chakra-ui/react";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import { GigIcon } from "components/Icons/Icons";
import moment from "moment";
import Chat from "components/Chat/Chat";
import { getCampaign } from "RestAPI";
import EmptyList from "assets/img/empty/conversations.png";
import NoConversations from "assets/svg/emptyList/NoConversations.svg"
import BBButton from "components/Button/Button";
import { FaMinus, FaPlus } from "react-icons/fa";
import BBAutocomplete from "components/Autocomplete/Autocomplete";
import { getBrandUsers } from "RestAPI";
import { getBrandTeams } from "RestAPI";
import { getBrandGroups } from "RestAPI";
import { getDashboardUser } from "RestAPI";
import { BBSecondaryButton } from "components/Button/Button";
import { BBSecondaryButtonWithoutHover } from "components/Button/Button";
import "./Conversation.css"
import CounterCard from "components/Card/CounterCard";
import { getCampaigns } from "RestAPI";
import BBDropdown from "components/Dropdown/Dropdown";
import { useLocation } from "react-router-dom";

const ConversationList = (props) => {
    const { userData, placeholderImage } = useContext(AppContext)
    const textColor = useColorModeValue("black3", "white");
    const title = `Your Conversations`
    const [allConversations, setAllConversations] = useState([])
    const [conversationListData, setConversationListData] = useState([])
    const [chatData, setChatData] = useState(null)
    const [loading, setLoading] = useState(true)
    const [showSearchField, setShowSearchField] = useState(false)

    const [users, setUsers] = useState([])
    const [groups, setGroups] = useState([])
    const [team, setTeam] = useState([])
    const [campaigns, setCampaigns] = useState([])
    const [currentTab, setCurrentTab] = useState('campaign')
    const [overview, setOverview] = useState({campaignConversations:0, groupConversations:0, teamConversations: 0, userConversations: 0})
    const [newConversation, setNewConversation] = useState(null)
    const location = useLocation()
    const brandSubdomain = window.location.hostname.split('.')[0]?.toLowerCase() || ''
    const isPremium = (brandSubdomain === 'dashboard' || brandSubdomain === 'dashboard-prod' || brandSubdomain === 'admin') && userData.role !== 'SUPER_ADMIN' ? false : true
    const refreshList = async () => {
        setLoading(true)
        const response = await allMyConversations()
        
        if (response && response.data?.length) {
            setAllConversations(response.data)
            let campaignConv = response.data.filter(con => con.conversationType === 'campaign')
            let groupConv = response.data.filter(con => con.conversationType === 'group')
            let teamConv = response.data.filter(con => con.conversationType === 'team')
            let userConv = response.data.filter(con => con.conversationType === 'user')
            if(newConversation){
                let conv = response.data.filter(con => con.conversationType === newConversation.conversationType)
                let index = conv.findIndex(item => item.id === newConversation.id)
                if(index){
                    let splicedConv = conv.splice(index,1)
                    conv.unshift(splicedConv[0])
                }
                setConversationListData(conv)
                setCurrentTab(newConversation.conversationType)
            }else if(location.state && location.state.id && location.state.type) {
                let conv = response.data.filter(con => con.conversationType === location.state.type)
                setConversationListData(conv)
                setCurrentTab(location.state.type)
            }else{
                setConversationListData(campaignConv)
            }
            let overview = {campaignConversations: campaignConv.length || 0, groupConversations: groupConv?.length || 0, teamConversations: teamConv?.length || 0, userConversations: userConv?.length || 0}
            setOverview(overview)
        }
        setLoading(false);
    }

    const handleClick = async(e, selected) => {
        setCurrentTab(selected)
        setLoading(true)
        let conversation = allConversations.filter(con => con.conversationType === selected)
        if(conversation.length){
            setConversationListData(conversation)
        }else{
            setConversationListData([])
        }
        setLoading(false)        
    }

    const loadConversation = async (selectedChat) => {
        if(location.state && location.state.id){
            let conv = conversationListData.filter(con => con.id === location.state.id)
            if(conv.length){
                setChatData(conv[0])
            }
        }else if (chatData?.id && selectedChat.id === chatData.id) {
            setChatData(null)
        } else {
            setChatData(selectedChat)
        }
    }

    const fetchGroups = async () => {
        const groupsList = await getBrandGroups({myGroups:'true'})
        setGroups(groupsList?.length ? groupsList : [])
    }

    const fetchTeams = async () => {
        const teamsList = await getDashboardUser(userData.id)
        setTeam(teamsList?.data?.teams?.length ? teamsList.data.teams[0] : [] )
    }

    const fetchBrandUsers = async () => {
        const usersList = await getBrandUsers()
        setUsers(usersList?.length ? usersList : [])
    }
    
    const fetchCampaigns = async () => {
        const campaigns = await getCampaigns()
        if(campaigns.data?.length){
            setCampaigns(campaigns.data.filter(c => c.campaignStatus === 'Active'))
        }
    }

    useEffect( () => {
        if(newConversation){
            loadConversation(newConversation)
            setNewConversation(null)
        }else{
            loadConversation(conversationListData[0])
        }
    },[conversationListData])

    useEffect(() => {
        if(newConversation?.id){
            refreshList()
        }
    }, [newConversation])

    useEffect(() => {
        refreshList()
        fetchBrandUsers()
        fetchTeams()
        fetchGroups()
        fetchCampaigns()
    }, [])

    return <>
        <Card id="conversation-list" p='16px' overflowX={{ sm: "scroll" }} boxShadow='0px 0px 20px 1px rgb(0 0 0 / 10%)' borderRadius='10px'>
            <CardHeader p='6px 0px 12px 0px'>
                <Flex flex={1} align="center" gap='2rem'>
                    <Flex direction='column' gap='2rem'>
                        <Flex gap='1rem' flexDirection='column'>
                            <Text fontSize='20px' color={textColor} fontWeight='500' pb='.5rem'>
                                {title}
                            </Text>
                            {isPremium ? <Flex flexDirection={{base: 'column', md: 'column'}} align='flex-start' gap='1rem' >
                                <BBButton minH='32px' shadow={true} maxW='fit-content' boxShadow='1px 4px 10px rgba(31, 100, 255, 0.25)' size="sm" leftIcon={showSearchField ? <FaMinus /> : <FaPlus />} colorScheme='brandbassBlue' color="white" onClick={() => setShowSearchField(!showSearchField)}>
                                    {showSearchField ? 'Cancel' : 'Start'}
                                </BBButton>
                                {showSearchField ? <SearchChatRecepients 
                                    visible={showSearchField} 
                                    closeHandler={(conv) => {
                                        setNewConversation(conv)
                                        setShowSearchField(false)}
                                    }
                                    users={users}
                                    groups={groups}
                                    team={team}
                                    campaigns={campaigns}
                                /> : null}
                            </Flex> : null}
                            
                            {
                                !loading && conversationListData && conversationListData.length == 0 && <>
                                    <Text fontStyle='italic' fontWeight='600' fontSize='16px' maxW='540px'>This is your conversations view, where you can start new conversations with ambassadors and sort everything out. Let's chat!</Text>
                                </>
                            }
                        </Flex>
                        <Flex gap='20px' p='10px' alignItems='center' borderRadius='10px' minWidth="100%">
                            {
                            loading && (
                                <Flex align="center" minWidth="100%" flexWrap="nowrap">
                                    <Box padding='6' boxShadow='lg' minWidth="100%">
                                        <SkeletonText mt='4' noOfLines={4} spacing='4' />
                                    </Box>
                                </Flex>
                            )
                            }
                            {!loading && (<>
                            <CounterCard title={"Campaign"} width={125} count={overview ? overview.campaignConversations : '0'} clickHandler={(e) => handleClick(e, 'campaign')} selected={currentTab === 'campaign'}/>
                            {isPremium ? <> <CounterCard title={"Group"} width={125} count={overview ? overview.groupConversations : '0'} clickHandler={(e) => handleClick(e,'group')} selected={currentTab === 'group'}/>
                            <CounterCard title={"Team"} width={125} count={overview ? overview.teamConversations : '0'} clickHandler={(e) => handleClick(e,'team')} selected={currentTab === 'team'}/>
                            <CounterCard title={"User"} width={125} count={overview ? overview.userConversations : '0'} clickHandler={(e) => handleClick(e,'user')} selected={currentTab === 'user'}/>                            
                            </> : null}
                            </>)}
                        </Flex>
                    </Flex>
                </Flex>
            </CardHeader>
            <CardBody>
                <Flex id='container' flex={1} w='100%'>
                    {
                        loading && (
                            <Flex align="center" minWidth="100%" flexWrap="nowrap">
                                <Box padding='6' boxShadow='lg' minWidth="100%">
                                    <SkeletonText mt='4' noOfLines={5} spacing='4' />
                                </Box>
                            </Flex>
                        )
                    }
                    {!loading && (<>
                        {!conversationListData || !conversationListData.length
                            ?
                            <Flex align="center" minWidth="100%" flexWrap="nowrap">
                                <Box padding='6' minWidth="100%" justifyContent={"center"}>
                                    <Center>
                                        <Image
                                            src={NoConversations}
                                            maxW="40%"
                                            borderRadius="lg"
                                            fallbackSrc={placeholderImage(40)}
                                            boxShadow='xs'
                                        /></Center>
                                </Box>
                            </Flex>
                            :
                            <Flex id='wrapper' flexDirection={{ base: 'column', lg: 'row' }} w={{ base: '100%', md: '50%' }} flex={1} gap='1rem'>
                                <Flex
                                    border='1px' borderColor='gray4'
                                    id='list'
                                    flexDirection='column'
                                    py={3}
                                    flex={{ base: 1, lg: 0.3 }}
                                    h='100vh'
                                    maxH={'600px'}
                                    overflowY='scroll'
                                    borderRadius='1rem'
                                >
                                    {
                                        conversationListData.map((item, idx) => <Flex
                                            cursor='pointer'
                                            onClick={() => loadConversation(item)}
                                            gap='1rem'
                                            key={idx}
                                            py={3}
                                            px={6}
                                            borderBottom='1px solid'
                                            borderColor='gray8'
                                            bgColor={!!chatData && chatData.id === item.id ? 'lightBlue' : ''}
                                        >
                                            <Box position='relative' w='80px' h='80px' minW={'80px'}>
                                                <Image
                                                    src={item.campaign?.imageUrl}
                                                    w='full'
                                                    h='full'
                                                    borderRadius='10px'
                                                    fallbackSrc={placeholderImage(80)}
                                                />
                                                <Flex
                                                    alignItems='center'
                                                    justifyContent='center'
                                                    bgColor='brandbassBlue.500'
                                                    borderRadius='full'
                                                    w='35px'
                                                    h='35px'
                                                    position='absolute'
                                                    bottom={0}
                                                    right={0}
                                                    transform='translateX(50%)'
                                                >
                                                    <GigIcon color='white' />
                                                </Flex>
                                            </Box>
                                            <Flex flexDirection='column' ml='40px' justifyContent='center'>
                                                <Text isTruncated w='70%' fontSize='16px' fontStyle='italic' fontWeight='600' color='brandbassBlue.500'>
                                                    {item.conversationTitle}
                                                </Text>
                                                {item.message?.length && <Text color={'gray.500'}>
                                                    {moment(item.messages[0]?.createdOn).fromNow()}
                                                </Text>}
                                                {item.brandName ? <Text>
                                                    {item.brandName}
                                                </Text> : null}
                                            </Flex>
                                        </Flex>
                                        )
                                    }
                                </Flex>
                                <Flex id='chat' flex={{ base: 1, lg: 0.7 }}>
                                    {
                                        !!chatData && 
                                        <Chat
                                            moduleId={chatData.campaign? chatData.campaign.id : null}
                                            module={chatData.campaign ? 'campaign' : chatData.contract ? 'contract' : null}
                                            receiver={chatData.receiverId}
                                            // participants={[chatData.receiverId, chatData.senderId]}
                                            disableChat = {userData.role === 'SUPER_ADMIN'}
                                            participants={chatData.users}
                                            conversationId={chatData.id}
                                            width='full'
                                            height='70vh'
                                        />
                                    }
                                </Flex>
                            </Flex>

                        }
                    </>
                    )}
                </Flex>
            </CardBody>
        </Card>
    </>
}

export default ConversationList


export const SearchChatRecepients = (props) => {
    const { visible, closeHandler, team, groups, users, campaigns } = props
    const { userData } = useContext(AppContext)
    const [showField, setShowField] = useState(false)
    
    const [selectedUsers, setSelectedUsers] = useState([])
    const [selectedGroup, setSelectedGroup] = useState(null)
    const [selectedTeam, setSelectedTeam] = useState([])
    const [selectedCampaign, setSelectedCampaign] = useState(null)
    const [conversationType, setConversationType] = useState(null)

    const openChat = async() => {
        let conversationObj = {conversationType}
        if(conversationType === 'team' && selectedTeam.length){
            conversationObj.teams = selectedTeam
            conversationObj.conversationTitle = selectedTeam[0].teamName
        }else if(conversationType === 'group' && selectedGroup){
            conversationObj.groups = [selectedGroup]
            conversationObj.conversationTitle = selectedGroup.name
        }else if(conversationType === 'user' && selectedUsers.length){
            conversationObj.users = selectedUsers
            conversationObj.conversationTitle = selectedUsers.concat({name: userData.userName}).reduce((finalString,b) => `${finalString} ${b.name}`,'')
        }else if(conversationType === 'campaign' && selectedCampaign){
            conversationObj.campaign = selectedCampaign
            conversationObj.conversationTitle = selectedCampaign.name
        }else{
            return
        }
        let response = await createOrFindConversation(conversationObj)
        closeHandler(response)
    }

    const updateUsers = (valArr) => {
        let data = users.filter(user => valArr.includes(user.userName));
        setSelectedUsers(data.map(u => { return { id: u.id, value: u.userName, name: u.userName } }))
    }
    
    useEffect(() => {
        setShowField(!!visible)
    }, [visible])


    return !showField ? null : <>
        <Flex flexDirection={{base: 'column', md: 'row'}} gap='1rem' align='flex-start' >
            <BBDropdown 
                height='50px'
                placeholder="Select one"
                value={conversationType}
                onClick={(e) => setConversationType(e.target.value)}
                options={[{ value: 'group', name: 'Group', label: 'Group', id: 1 }, { value: 'team', name: 'Team', label: 'Team', id: 2 }, { value: 'user', name: 'User', label: 'User', id: 3 }, { value: 'campaign', name: 'Campaign', label: 'Campaign', id: 4 }]} />
            { conversationType === 'team' ? 
            <BBSecondaryButtonWithoutHover opacity={selectedTeam.length > 0 ? 1 : 0.5} onClick={() => setSelectedTeam([team])} maxW='400px' h='50px' flexWrap='wrap' whiteSpace='normal' textAlign='justify' >
                {`Chat with your team - ${team.teamName}`}
            </BBSecondaryButtonWithoutHover> 
            : conversationType === 'group' ? 
            <BBAutocomplete
                style={{ minWidth: 250, maxWidth: 250 }}
                placeholder='Select group'
                options={groups.map(g => { return { id: Number(g.id), value: Number(g.id), label: g.groupName, name: g.groupName, imageUrl: g.imageUrl } })}
                isMultiple={false}
                onChange={(val, item) => setSelectedGroup(item.originalValue)}
            /> 
            : conversationType === 'user' ?
            <BBAutocomplete
                style={{ minWidth: 250, maxWidth: 250 }}
                placeholder='Select user(s)'
                options={users.map(u => { return { id: u.id, value: u.userName, label:u.userName, name: u.userName, imageUrl: u.imageUrl } })}
                isMultiple={true}
                onChange={(vals) => updateUsers(vals)}
            /> 
            : conversationType === 'campaign' ? 
            <BBAutocomplete
                style={{ minWidth: 250, maxWidth: 250 }}
                placeholder='Select campaign'
                options={campaigns.map(c => { return { id: c.id, value: c.name, label: c.name, name: c.name, imageUrl: c.imageUrl } })}
                isMultiple={false}
                onChange={(val, item) => setSelectedCampaign(item.originalValue)}
            /> 
            : null }
            <BBSecondaryButton minW='fit-content' h='50px' onClick={openChat} >
                Chat
            </BBSecondaryButton>

        </Flex>
    </>
}
