export const textStyles = {
    styles: {
        global: {
            h1: {
                fontSize: '56px',
                lineHeight: '61.6px',
                fontFamily:'Open Sans'
            },
            h2: {
                fontSize: '48px',
                lineHeight: '52.8px',
                fontFamily:'Open Sans'
            },
            h3: {
                fontSize: '40px',
                lineHeight: '44px',
                fontFamily:'Open Sans'
            },
            h4: {
                fontSize: '32px',
                lineHeight: '35.2px',
                fontFamily:'Open Sans'
            },
            h5: {
                fontSize: '24px',
                lineHeight: '26.4px',
                fontFamily:'Open Sans'
            },
            h6: {
                fontSize: '20px',
                lineHeight: '22px',
                fontFamily:'Open Sans'
            },
            largeTextBold:{
                fontSize: '20px',
                lineHeight: '28px',
                fontFamily:'Open Sans',
                fontWeight:'bold'
            },
            largeTextRegular:{
                fontSize: '20px',
                lineHeight: '28px',
                fontFamily:'Open Sans',
                fontWeight:'normal'
            },
            mediumTextBold:{
                fontSize: '18px',
                lineHeight: '25.2px',
                fontFamily:'Open Sans',
                fontWeight:'bold'
            },
            mediumTextRegular:{
                fontSize: '18px',
                lineHeight: '25.2px',
                fontFamily:'Open Sans',
                fontWeight:'normal'
            },
            normalTextBold:{
                fontSize: '16px',
                lineHeight: '22.4px',
                fontFamily:'Open Sans',
                fontWeight:'bold'
            },
            normalTextRegular:{
                fontSize: '16px',
                lineHeight: '22.4px',
                fontFamily:'Open Sans',
                fontWeight:'normal'
            },
            smallTextBold:{
                fontSize: '14px',
                lineHeight: '19.6px',
                fontFamily:'Open Sans',
                fontWeight:'bold'
            },
            SmallTextRegular:{
                fontSize: '14px',
                lineHeight: '19.6px',
                fontFamily:'Open Sans',
                fontWeight:'normal'
            }
        },
      },

};
