// Chakra imports
import { Flex, Text, useColorModeValue, Divider, Stack, Box, TableContainer, Table, Tbody, Td, Tr, Thead, Tag, TagLabel, Spacer, 
  Badge, Modal, ModalBody, ModalContent, ModalHeader, ModalFooter, ModalOverlay, Button, Spinner } from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import BillingRow from "components/Tables/BillingRow";
import React, {useState, useEffect} from "react";
import BBButton from "components/Button/Button";
import moment from "moment";
import { globalStyles } from "theme/styles";
import { navigate } from "react-big-calendar/lib/utils/constants";
import { toggleSubscription, validateSubscription } from "RestAPI";
import { useNavigate } from "react-router-dom";

const BillingInformation = ({ background }) => {
  const textColor = useColorModeValue("gray.700", "white");
  const [subscription, setSubscription] = useState({})
  const [showCancelSubscriptionModal, setShowCancelSubscriptionModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const navigate =  useNavigate()

  useEffect(async() => {
    let subscription = await validateSubscription()
    setSubscription(subscription)    
  },[])

  const confirmCancelSubscription = async() => {
    setLoading(true)
    setShowCancelSubscriptionModal(false)
    let response = await toggleSubscription(subscription.id)
    let sub = await validateSubscription()
    setSubscription(sub) 
    setLoading(false)
  }

  return (
    <Card me={{ lg: "24px" }} background={background ? background : '#ECF6FF'}>
      <Flex direction='column'>
        <CardHeader paddingBottom={"35px"}>
          <Text color={textColor} fontSize='lg' fontWeight='bold'>
            Subscriptions
          </Text>
        </CardHeader>
        <CardBody>
          <Flex direction='column' w='100%'>
            <Box>
              <Text color={textColor} marginBottom={"6px"} fontSize='16px' fontWeight='400' lineHeight='22px'>
                CURRENT PLAN
              </Text>
              <Divider size={'md'} borderColor="black" borderBottomWidth='1.5px' />
              <Flex gap={2} alignItems='center'>
                <Text color={textColor} textTransform={'capitalize'} fontStyle='italic' marginTop={"20px"} fontSize='22px' fontWeight='300' lineHeight='30px'>
                  {subscription? subscription.name : 'Premium (Pilot)'}
                </Text>
                {/* <Badge borderColor='primary' height={'20px'} borderWidth='1px' background={subscription.subscriptionStatus === 'Active' ? globalStyles.colors.statusGreen : globalStyles.colors.statusGray} 
                  fontWeight='bold' p={1} textAlign='center' fontSize='sm'>{subscription.subscriptionStatus}</Badge> */}
                <Tag textAlign={'center'} minW={18} height={6} mt={6} ml={8} backgroundColor={subscription?.subscriptionStatus === 'Inactive' ? globalStyles.colors.statusGray : globalStyles.colors.statusGreen}>
                  <Spacer /><TagLabel textAlign={'center'}>{subscription? subscription.subscriptionStatus : 'Active'}</TagLabel><Spacer /></Tag>
              </Flex>
              {subscription ? <Text color={textColor} marginTop={"10px"} fontSize='20px' fontWeight='400' lineHeight='27px'>
                ${subscription.totalMonthlyAmount/100} per month
              </Text> : null}
              {loading ?  <Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='brandbassBlue' size='xl'/> : subscription ? 
              <Stack spacing={4} direction='row' marginTop={"20px"}>
                {subscription.stripeSubscriptionId && !subscription.cancelAtPeriodEnd ? <BBButton disabled={true} onClick={() => navigate(`/edit/subscription`)}>Modify plan</BBButton> : null}
                {subscription.stripeSubscriptionId && !subscription.cancelAtPeriodEnd ? <BBButton disabled={true}>Upgrade plan</BBButton> : null}
                {subscription.stripeSubscriptionId ? !subscription.cancelAtPeriodEnd ? <BBButton disabled={true} onClick={() => setShowCancelSubscriptionModal(true)} variant="outline">Cancel plan</BBButton> : 
                  <BBButton onClick={() => confirmCancelSubscription()} variant="outline">Activate plan</BBButton> : null}
              </Stack> : null}
              {subscription ? <Text color={textColor} marginTop={"15px"} fontSize='14px' fontWeight='400' lineHeight='19px'>
                Your plan {!subscription.cancelAtPeriodEnd ? 'renews' : 'ends'} on {moment(subscription.renewalDate).format('MM-DD-YYYY')}
              </Text> : null }
            </Box>
            {showCancelSubscriptionModal && 
            <Modal isOpen={showCancelSubscriptionModal} size="xl" scrollBehavior='outside' closeOnOverlayClick={false} >
                <ModalOverlay />
                <ModalContent>
                <ModalHeader color='brandbassBlue'>Cancel Subscription</ModalHeader>
                <ModalBody pb={6}>
                    <Flex p={8} borderColor='grayBlue' borderWidth="1px" borderRadius="lg" alignItems='center' justifyContent='center'>
                        <Text fontSize='20px'>
                        Do you want to cancel subscription? You cannot access website and will have access to mobile app only.
                        </Text>
                    </Flex>
                </ModalBody>
                <ModalFooter>          
                    <Button
                    colorScheme="brandbassBlue"
                    variant="outline"
                    borderRadius="lg" mr={3}
                    onClick={() => confirmCancelSubscription()}
                    >
                    Yes
                    </Button>

                    <Button
                    colorScheme="brandbassBlue"

                    borderRadius="lg"
                    onClick={() => setShowCancelSubscriptionModal(false)}
                    >
                    No
                    </Button>
                </ModalFooter>
                </ModalContent>
            </Modal>
            }
            {/* <Box marginTop={"40px"}>
              <Text color={textColor} marginBottom={"6px"} fontSize='16px' fontWeight='400' lineHeight='22px'>
                BILLING HISTORY
              </Text>
              <Divider size={'md'} borderColor="black" borderBottomWidth='1.5px' />
              <TableContainer marginTop={"15px"}>
                <Table size='md' variant="unstyled" marginLeft={'-20px'}>
                  <Tbody fontSize={"16px"} fontStyle={"italic"} fontWeight={"300"}>
                    {data.map((item, index) => {
                      return (
                        <Tr key={index}>
                          <Td>{item.date}</Td>
                          <Td>${item.amount}</Td>
                          <Td>{item.type}</Td>
                        </Tr>
                      )
                    })}
                  </Tbody>
                </Table>
              </TableContainer>
            </Box> */}
          </Flex>
        </CardBody>
      </Flex>
    </Card>
  );
};

export default BillingInformation;
