export const lifestyleOptions = [
    {
        label: 'Bohemian',
        value: 'Bohemian',
        id: 0,
        key: 'lifestyleBohemian',
    },
    {
        label: 'All natural',
        value: 'All natural',
        id: 1,
        key: 'lifestyleAllNatural',
    },
    {
        label: 'Sustainable, eco-friendly',
        value: 'Sustainable, eco-friendly',
        id: 2,
        key: 'lifestyleEcoFriendly',
    },
    {
        label: 'Artisan-crafted, handmade',
        value: 'Artisan-crafted, handmade',
        id: 3,
        key: 'lifestyleArtisan',
    },
    {
        label: 'Health and Fitness',
        value: 'Health and Fitness',
        id: 4,
        key: 'lifestyleSports',
    },
    {
        label: 'Fashion and Beauty',
        value: 'Fashion and Beauty',
        id: 5,
        key: 'lifestyleFashionBeauty',
    },
    {
        label: 'Mommy and Baby',
        value: 'Mommy and Baby',
        id: 6,
        key: 'lifestyleMom',
    },
    {
        label: 'Women empowerment',
        value: 'Women empowerment',
        id: 7,
        key: 'lifestyleWomenPower',
    },
    {
        label: 'Men on a mission',
        value: 'Men on a mission',
        id: 8,
        key: 'lifestyleManWithMission',
    },
];

export const genderOptions = [
    {
        label: 'Men',
        value: 'Men',
        id: 0,
        key: 'genderMen'
    },
    {
        label: 'Women',
        value: 'Women',
        id: 1,
        key: 'genderWomen'
    },
    {
        label: 'Any',
        value: 'Any',
        id: 2,
        key: 'genderUnisex'
    }
];

export const ageOptions = [
    {
        label: '0 to 5 years',
        value: 3,
        id: 0,
        key: '3',
    },
    {
        label: '5 to 12 years',
        value: 9,
        id: 1,
        key: '9',
    },
    {
        label: '13 to 19 years',
        value: 16,
        id: 2,
        key: '16',
    },
    {
        label: '20 to 30 years',
        value: 25,
        id: 3,
        key: '25',
    },
    {
        label: '30 to 40 years',
        value: 35,
        id: 3,
        key: '35',
    },
    {
        label: '40 to 60 years',
        value: 50,
        id: 5,
        key: '50',
    }
];

export const bodyareaOptions = [
    { value: 'Hair', label: 'Hair', key:'bodyAreaHair' },
    { value: 'Full body', label: 'Full body', key:'bodyAreaFullBody'  },
    { value: 'Face', label: 'Face' , key:'bodyAreaFace' },
    { value: 'Lower body', label: 'Lower body', key:'bodyAreaLowerBody'  },
    { value: 'Upper Body', label: 'Upper Body' , key:'bodyAreaUpperBody' },
];

export const productCategoryOptions = [
    { value: 'Skin care', label: 'Skin care' , key:'typeProductsSkinCare' },
    { value: 'Beauty and Makeup', label: 'Beauty and Makeup', key:'typeProductsBeautyMakeup'  },
    { value: 'Clothes', label: 'Clothes' , key:'typeProductsClothes' },
    { value: 'Hair care', label: 'Hair care' , key:'typeProductsHairCare' },
    { value: 'Fashion Accessories', label: 'Fashion Accessories', key:'typeProductsFashionAccessories'  },
    { value: 'Fragrance', label: 'Fragrance' , key:'typeProductsFragrance' },
];

export const productUsageOptions = [
    { value: 'Outdoor', label: 'Outdoor' , key:'locationOutdoor' },
    { value: 'Everyday use/ All day', label: 'Everyday use/ All day' , key:'locationEverydayUseAllDay' },
    { value: 'Home', label: 'Home' , key:'locationHome' },
    { value: 'Gym/Sports', label: 'Gym/Sports', key:'locationGymSports'  },
    { value: 'Party', label: 'Party', key:'locationParty'  },
    { value: 'Formal meetings', label: 'Formal meetings', key:'locationFormalMeetings'  },
    { value: 'Formal party', label: 'Formal party' , key:'locationFormalParty' },
    { value: 'Relaxed settings as in vacation', label: 'Relaxed settings as in vacation', key:'locationVacation'  },
];
