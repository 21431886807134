// Chakra imports
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Checkbox,
  Stack,
  Flex,
  IconButton,
  useToast
} from "@chakra-ui/react";
// Custom components
import React, { useEffect, useState } from "react";
import { getBrandUsers, createProject } from "RestAPI";
import { DeleteIcon } from '@chakra-ui/icons'
import { FormHeader } from "layouts/FormLayout";
import { useLocation, useNavigate } from "react-router-dom";
import BBAutocomplete from "components/Autocomplete/Autocomplete";

const ProjectForm = ({ onClose, refreshList }) => {
  const location = useLocation()
  const data = location.state?.data || null
  const navigateTo = location.state?.navigateTo || null
  const [projectName, setProjectName] = useState(data?data.projectName:'')
  const [description, setDescription] = useState(data?data.description:'')
  const [isActive, setIsActive] = useState(data?data.isActive:true)
  const [owner, setOwner] = useState(data && data.owner?data.owner:null)
  const [users, setUsers] = useState([])
  const [loading, setLoading] = useState(false)
  const [campaignSets, setCampaignSets] = useState(data && data.campaignSets?.length ? data.campaignSets : [])
  const navigate = useNavigate()
  const toast = useToast()

  // const { isOpen, onOpen, onClose } = useDisclosure()
  useEffect(() => {
    getBrandUsers().then(response => {
      if(Array.isArray(response) && response.length){
        response = response.map(user => {
          user.selected = false
          if(data && data.users && data.users.length){
            let match = data.users.filter(u => u.id===user.id)
            if(match && match.length){
              user.selected = true
            }
          } 
          return user
        })
        setUsers(response)
      }
    })
  },[])

  const saveProject = () => {
    setLoading(true)
    const selectedUsers = users.filter(u => !!u.selected)
    createProject({id: data?data.id:null,projectName, description, isActive, owner : {id: owner.id?owner.id:owner}, campaignSets})
    .then(response => {
      toast({
        title: `Project "${projectName}" created successfully`,
        description: "",
        status: 'success',
        position: 'top',
        duration: 3000,
        isClosable: true,
      })
      setLoading(false)
      navigateTo === 'projectView' ? navigate('/projects/'+data.id) : navigate('/projects')
    }).catch(err => {
      toast({
        title: `An error occured when saving the project.`,
        description: "Please try after sometime or create an issue",
        status: 'error',
        position: 'top',
        duration: 3000,
        isClosable: true,
      })
      setLoading(false)
    })
  }

  const selectUser = (e, user) => {
    setUsers(users.map(u => {
      if(u.id === user.id){
        u.selected = e.target.checked
      }
      return u
    }))
  }

  const updateCampaignSet  = (index, set) => {
    setCampaignSets(campaignSets.map((s, i) => {
      if(i === index){
        s.campaignSetName = set.campaignSetName
      }
      return s;
    }))
  }

  return (
    <>
      <FormHeader
        caption={!!data ? 'Edit Project' : 'Create Project'}
        submitHandler={() => saveProject()}
        closeHandler={() => navigateTo === 'projectView' ? navigate('/projects/'+data.id) : navigate('/projects')}
        cancelHandler={() => navigateTo === 'projectView' ? navigate('/projects/'+data.id) : navigate('/projects')}
      />
      <Flex flexDirection="column" pt={{ base: "120px", md: "75px" }}>
          <Flex flexDirection="column" gap={2}>
            <FormControl style={{margin: 16}} isRequired>
              <FormLabel htmlFor='project-name'>Project name</FormLabel>
              <Input value={projectName} id='project-name' placeholder='Project name' onChange={(e) => setProjectName(e.target.value)}/>
            </FormControl>
            <FormControl style={{margin: 16}}>
              <FormLabel htmlFor='description'>Description</FormLabel>
              <Input value={description} id='descriptionname' placeholder='Description' onChange={(e) => setDescription(e.target.value)}/>
            </FormControl>
            <Checkbox  style={{margin: 16}} id='is-active' onChange={(e) => setIsActive(e.target.checked)} isChecked={isActive}>Is Active</Checkbox>
            <BBAutocomplete
              defaultValues={owner ? [owner.firstName + ' ' + owner.lastName] : []}
              style={{ margin: 16 }}
              options={users.map(u => { return { id: u.id, value: u.firstName + ' ' + u.lastName, name: u.firstName + ' ' + u.lastName, imageUrl: u.profileImageUrl } })}
              isMultiple={false} label='Project owner'
              onChange={(value, item) => setOwner(item?.originalValue)} />
            {/* <FormControl  style={{margin: 16}} isRequired>
              <FormLabel>Project Owner</FormLabel>
              <RadioGroup value={owner} onChange={setOwner}>
                <Stack spacing={4} direction='column'>
                  {users.map(user => {
                    return <Radio key={user.id} value={user.id.toString()}>{user.firstName} {user.lastName}</Radio>
                  })}
                </Stack>
              </RadioGroup>
            </FormControl> */}
            <FormControl  style={{margin: 16}} isRequired>
              <FormLabel>Campaign Sets</FormLabel>
              {campaignSets.length ? <Stack spacing={4} direction='column'>
                {campaignSets.map((set, index) => {
                  return <Flex key = {index} p={1} justify="space-between" align="center">
                    <FormControl isRequired>
                      <FormLabel htmlFor='set-name'>Name</FormLabel>
                      <Input value={set.campaignSetName} id='set-name' placeholder='Campaign set name' onChange={(e) => updateCampaignSet(index, {campaignSetName: e.target.value})}/>
                    </FormControl>
                    <IconButton
                      ml={8}
                      mt={8}
                      onClick={() => setCampaignSets(campaignSets.filter((set,i) => i !== index))}
                      colorScheme='blue'
                      aria-label='delete'
                      icon={<DeleteIcon />}
                    />
                  </Flex>
                })}
              </Stack> : null}
              <Button mt={8} onClick={() => setCampaignSets([...campaignSets,{campaignSetName:''}])}>Add new campaign set</Button>
            </FormControl>
          </Flex>
      </Flex >
      </>
  );
};

export default ProjectForm;

