// Chakra Icons
import { BellIcon, SearchIcon } from "@chakra-ui/icons";
// Chakra Imports
import {
  Button,
  Flex,
  Text,
  Badge,
  Menu,
  MenuButton,
  MenuItem,AvatarBadge,Avatar,
  MenuList,
  useColorModeValue,
  HStack, MenuDivider, Spacer
} from "@chakra-ui/react";

// Custom Icons
import { ChevronDownIcon } from "@chakra-ui/icons";
import { ProfileIcon, SettingsIcon } from "components/Icons/Icons";
import {  FaSignOutAlt } from "react-icons/fa";
// Custom Components
import { ItemContent } from "components/Menu/ItemContent";
import SidebarResponsive from "components/Sidebar/SidebarResponsive";
import PropTypes from "prop-types";
import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import routes from "routes.js";
import CreateEntityLinks from "./CreateEntityLinks";
import { AppContext } from "contexts/AppContext";
import { useContext, useState, useEffect } from "react";
import moment from 'moment'
import { getNotifications, getUnreadNotificationsCount, updateNotification } from 'RestAPI'

export default function HeaderLinks(props) {
  const { variant, children, fixed, secondary, onOpen, ...rest } = props;
  const { userData } = useContext(AppContext);
  // Chakra Color Mode
  let mainTeal = useColorModeValue("teal.300", "teal.300");
  let inputBg = useColorModeValue("white", "gray.800");
  let mainText = useColorModeValue("gray.700", "gray.200");
  let navbarIcon = useColorModeValue("gray.500", "gray.200");
  let searchIcon = useColorModeValue("gray.700", "gray.200");
  const navigate = useNavigate();
  const [notifications, setNotifications] = useState([])
  const [loading, setLoading] = useState(false)
  const [viewAll, setViewAll] = useState(false)
  const [count, setCount] = useState(null)

  const dashRoutes = !userData?.role ? [] : routes.filter(item => !item.access || item.access.includes(userData.role))
  
  useEffect(() => {
    getUnreadNotificationsCount()
      .then(response => {
        if (response.data) {
          setCount(response.data > 9 ? '9+' : response.data)
        }
      })
    getNotifications({limit: 10}).then(response => {
      if (response.data) {
        for (let item of response.data) {
          const diff = moment().diff(moment(item.createdOn), 'minutes');
          item.time = diff < 60 ? `${diff}m ago` : diff / 60 < 24 ? `${parseInt(diff / 60)}h ago` : moment(item.createdOn).format('MM/DD/YY');
        }
        if (response.data.length > 5) {
          setViewAll(true)
        }
        setNotifications(response.data.splice(0, 5));
      }
      setLoading(false);
    }).catch(err => {
      setLoading(false);
    });
  }, [])

  const handleNotiClick = async (item) => {
    if (item.status !== 'Seen') {
      setLoading(true)
      item.status = 'Seen';
      await updateNotification(item)
      setLoading(false)
    }
    if (item.module === 'Campaign' && item.eventType === "newMessage") {
      navigate(`campaigns/${item.moduleId}`, { displayConversation: true , replace: true })
    } else if (item.module === 'Campaign') {
      navigate(`campaigns/${item.moduleId}`, { replace: true })
    } else if (item.module === 'Contract' && item.eventType === "newMessage") {
      navigate(`/contracts/${item.moduleId}`, { state: {displayConversation: true }})
    } else if(item.module === 'Contract'){
      navigate(`/contracts/${item.moduleId}`)
    } else if(item.eventType === 'newMessage'){
      navigate(`/conversations`,{state: {type:item.module.toLowerCase(),id:item.moduleId}})
    } 
  }

  if (secondary) {
    navbarIcon = "white";
    mainText = "white";
  }
  const settingsRef = React.useRef();
  return (


    <Flex
      pe={{ sm: "0px", md: "16px" }}
      w={{ sm: "100%", md: "auto" }}
      alignItems="center"
      flexDirection="row"
      cursor='pointer'
    >
      <SidebarResponsive
        logoText={props.logoText}
        secondary={props.secondary}
        routes={dashRoutes}
        position='absolute' top='0' left='0'
        // logo={logo}
        {...rest}
      />
      <Spacer/>
      <HStack spacing={4}>
        {userData.role === 'AMBASSADOR' || userData.role === 'BRAND_USER' || userData.role === 'CLIENT_SUPER_ADMIN' ? null : <CreateEntityLinks w='full' maxW={150} />}
        <Menu>
          <MenuButton fontSize={"3xl"}>
            <BellIcon color={navbarIcon} />
            {count > 0 ? <Badge  style={{ position: 'absolute', borderRadius: "50%", background: "red", color: "white", fontWeight: "700", fontSize: '10px', marginLeft: '-10px', marginTop: '-6px', height: '18px', width: '18px' }}><Text mt={'-3px'}>{count}</Text></Badge> : null }
          </MenuButton>
          <MenuList p="16px 8px" border='2px' borderColor='brandbassBlue' >
            <Flex flexDirection="column" >
              {notifications.length ? notifications.map((item, index) => {
                return (
                  <MenuItem onClick={() => handleNotiClick(item)} borderRadius="8px" mb="10px" key={index} >
                    <ItemContent
                    seen={item.status}
                      time={item.time}
                      info=""
                      boldInfo={item.message.substr(0, 50)}
                      aName=""
                      aSrc={item.imageUrl}
                    /> 
                  </MenuItem>
                )
              }) : null}
              {viewAll ? <>
                <MenuDivider />
                <MenuItem justifyContent='center' onClick={() => navigate('/notifications')}>View All</MenuItem>
              </> : null}
            </Flex>
          </MenuList>

        </Menu>
        <Menu >
          <MenuButton fontSize={"3xl"}  >
          <HStack spacing={1} >
          <ProfileIcon color={navbarIcon} /><Text fontFamily='Rubik' textAlign='right' align="right" color='brandbassGray.500' fontWeight='500' fontSize='16px' >{userData?.firstName} </Text>
          </HStack>
          </MenuButton>
          <MenuList>
            <MenuItem  icon={<ProfileIcon fontSize={"xl"}/>}  onClick={() => navigate('/profile', { replace: true })}>My Profile</MenuItem>
            <MenuItem icon={<FaSignOutAlt fontSize={"xl"}/>}  onClick={() => navigate('/signout', { replace: true })}>Signout</MenuItem>
          </MenuList>
        </Menu>
      </HStack>


    </Flex>

  );
}

HeaderLinks.propTypes = {
  variant: PropTypes.string,
  fixed: PropTypes.bool,
  secondary: PropTypes.bool,
  onOpen: PropTypes.func,
};
