import { Flex, Text } from "@chakra-ui/react"
import BBButton from "components/Button/Button"
import { BBSecondaryButton } from "components/Button/Button"
import { useNavigate } from "react-router-dom"

const NoPermission = () => {
    const navigate = useNavigate()
    
    return (
        <Flex 
            justifyContent= 'center'
            alignItems= 'center'
            h= '100vh'
            w= 'full'
            flexDirection='column'
            gap='3rem'
        >
            <Text fontSize='3xl'>It appears you don't have enough permissions. Please contact your admin.</Text>
            <Flex gap='3rem'>
                <BBSecondaryButton onClick={() => navigate(-1)} >Go back</BBSecondaryButton>
                <BBSecondaryButton onClick={() => navigate('/')} >Go to home</BBSecondaryButton>
            </Flex>
            
        </Flex>
    )
}

export default NoPermission

