/*eslint-disable*/
import { ButtonGroup, Container, IconButton, Stack, Divider, Flex, Spacer, HStack, Text, Image } from '@chakra-ui/react'
import * as React from 'react'
import { FaFacebook, FaLinkedin, FaTwitter } from 'react-icons/fa'
import BrandBassShortIcon from 'assets/svg/brandbass-short-logo.svg'
import LightBulbIcon from 'assets/svg/light-bulb.svg'
import { useNavigate } from 'react-router-dom'

export default function Footer(props) {
  const navigate = useNavigate()
  // const linkTeal = useColorModeValue("teal.400", "red.200");=
  return (
    <Container as="footer" role="contentinfo" mt={3} w={{ base: 'full', lg: '100%' }} maxW={"100%"} minW={"100%"} h={50} bottom={0} position="absolute" >
      {/* <Divider /> */}
      <Stack spacing={{ base: '4', md: '5' }} pt={2} >
        <HStack spacing={4} direction='column' ml={10} mr={10}>
          <Image src={BrandBassShortIcon} w='27px' h='37px' />
          <Text fontSize="sm" color="subtle">
            &copy; {new Date().getFullYear()} Brandbass, Inc. All rights reserved.
          </Text>
          <Spacer />
          <ButtonGroup variant="ghost" style={{ marginRight: "20px" }}>
            <Flex gap='1rem' alignItems='center' cursor='pointer' mr={4} onClick={() => navigate('/new/issue')}>
              <Image src={LightBulbIcon} w='15px' h='20px' />
              <Text color='brandbassBlue.500'>Help</Text>
            </Flex>
            <Divider orientation='vertical' minH={10} />
            <IconButton
              as="a"
              href="#"
              aria-label="LinkedIn"
              icon={<FaLinkedin fontSize="1.25rem" />}
            />
            <IconButton as="a" href="#" aria-label="GitHub" icon={<FaFacebook fontSize="1.25rem" />} />
            <IconButton
              as="a"
              href="#"
              aria-label="Twitter"
              icon={<FaTwitter fontSize="1.25rem" />}
            />
          </ButtonGroup>
        </HStack>
      </Stack>
    </Container>
  );
}
