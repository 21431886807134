import React, { useState } from "react";
// Chakra imports
import {
  Box,
  Flex,
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Link,
  Switch,
  Text,
  useColorModeValue,
  Select,
  CircularProgress,
  Image
} from "@chakra-ui/react";
// Assets
// import signInImage from "assets/img/signInImage.png";
import { confirmUser } from "RestAPI";
import { useContext } from "react";
import { useNavigate } from 'react-router-dom'
import signInImage from "assets/svg/LoginBG.svg";
import BBButton from "components/Button/Button";
import BBInput from "components/Input/Input";
import BrandBassShortIcon from 'assets/svg/brandbass-short-logo.svg';
import BrandBassIcon from 'assets/svg/brandbass-logo.svg';

function ConfirmRegistration(props) {
  // Chakra color mode
  const titleColor = useColorModeValue("brandbassBlue2.500", "white");
  const textColor = useColorModeValue("gray.400", "white");
  const [error, seterror] = useState('')
  const [verificationCode, setVerificationCode] = useState('')
  const [loading, setLoading] = useState(false)
  const [registrationSuccess, setRegistrationSuccess] = useState(false)
  const bgColor = useColorModeValue("white", "gray.700");
  const [submit, setSubmit] = useState(false)
  const navigate = useNavigate()

  const handleSubmitPress = async () => {
    setSubmit(true);
    if (!verificationCode) {
      return;
    }
    setLoading(true);
    const response = await confirmUser({
      userName: localStorage.getItem('userName'),
      userEmail: localStorage.getItem('userEmail'),
      verificationCode,
    });
    setLoading(false);
    if (response.data && response.data === 'SUCCESS') {
      setRegistrationSuccess(true);
      setTimeout(function () {
        navigate("/auth")
      }, 2000);
    } else {
      if (response.data && response.data.error) {
        seterror(response.data.error);
      }
    }
  };

  return (
    <Flex position='relative'>
      <Flex
        h={{ sm: "initial", md: "75vh", lg: "85vh" }}
        w='100%'
        maxW='1044px'
        mx='auto'
        justifyContent='space-between'
        mb='30px'
        pt={{ sm: "100px", md: "0px" }}>
        <Flex
          alignItems='center'
          justifyContent='start'
          style={{ userSelect: "none" }}
          w={{ base: "100%", md: "50%", lg: "42%" }}>
          <Flex
            direction='column'
            w='100%'
            background='transparent'
            p='48px'
            mt={{ md: "150px", lg: "80px" }}>
            <Heading color={titleColor} fontSize='32px' mb='10px'>
              Welcome!
            </Heading>
            <Text
              mb='36px'
              ms='4px'
              color={textColor}
              fontWeight='bold'
              fontSize='14px'>
              Please enter the verification code received on your email!
            </Text>
            <FormControl>

              <BBInput required={true} isInvalid={submit && !verificationCode} value={verificationCode} mb='36px' label="Verification code"
                placeholder='Enter code' onChange={e => { setSubmit(false); setVerificationCode(e.target.value) }} />

              {!!error && <Text as="p" mt="10px" color={'red'}> {error} </Text>}
              {!!registrationSuccess && <Text as="p" mt="10px" color={'green'}> Registration Successful </Text>}

              {loading ? <CircularProgress value={80} /> : <BBButton type='submit'
                w='100%'
                h='45'
                mb='20px'
                mt='20px'
                onClick={handleSubmitPress}>
                Submit
              </BBButton>}
            </FormControl>
          </Flex>
        </Flex>
        <Box
          display={{ base: "none", md: "block" }}
          overflowX='hidden'
          h='100%'
          w='50vw'
          mt={-70}
          position='absolute'
          right='0px'>
          <Flex
            flexDirection='column'
            justifyContent='center'
            alignItems='center'
            bgImage={signInImage}
            w='100%'
            h='100%'
            bgSize='cover'
            bgPosition='50%'
            position='absolute'
            borderBottomLeftRadius='20px'>
            <Image src={BrandBassShortIcon} w='67px' h='87px' />
              <Image src={BrandBassIcon} w='250px' />
          </Flex>
        </Box>
      </Flex>
    </Flex>
  );
}

export default ConfirmRegistration;
