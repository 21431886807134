import { InfoIcon } from "@chakra-ui/icons";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Badge,
  Box, Button, Checkbox, CheckboxGroup, CircularProgress, Flex, FormControl, FormLabel, IconButton, Image, Img,
  Input, InputGroup, InputLeftElement, Modal, ModalBody, ModalCloseButton, ModalContent,
  ModalFooter, ModalHeader, ModalOverlay, NumberDecrementStepper, NumberIncrementStepper,
  NumberInput, NumberInputField, NumberInputStepper, Select, Slide, Slider, SliderFilledTrack,
  SliderMark, SliderThumb, SliderTrack, Spacer, Spinner, Switch, Text, Textarea, Tooltip, useDisclosure, FormHelperText, Tag, Avatar
} from "@chakra-ui/react";
import { forwardRef, useContext, useEffect, useState } from "react";
import { Navigate, useParams, useNavigate, useOutletContext, useSearchParams,useLocation } from "react-router-dom";
import {
  getFileUploadSignedUrl, getCampaignsLookupData, getCampaign, getBrandAttachments, 
  getCampaignsCategories, createCampaign, makeCampaignPayment, getUserRecommendation, 
  campaignInvite, getCampaignSignedUrl, saveExclusiveCampaignInvite
} from "RestAPI";
import PaymentModal from "./PaymentModal";
import { AppContext } from "contexts/AppContext";
import { useToast } from '@chakra-ui/react'
import { TrashIcon } from "components/Icons/Icons";
import BBInput from "components/Input/Input";
import BBDropdown from "components/Dropdown/Dropdown";
import BBButton from "components/Button/Button";
import BBTextarea from "components/Textarea/Textarea";
import MatchProfileForm from "./MatchProfile";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { RecommendedAmbassadors } from "./RecommendedAmbassadors";
import { BBSecondaryButton } from "components/Button/Button";
import { FormHeader } from "layouts/FormLayout";
import { updatePaymentStatus } from "RestAPI";
import { getBrandProjects } from "RestAPI";
import { getBrandGroups } from "RestAPI";
import BBAutocomplete from "components/Autocomplete/Autocomplete";
import { getBrandInterest } from "RestAPI";
import FileAttachment from "views/Auth/FileAttachment";
import { ExclusiveAmbassadors } from "./ExclusiveAmbassadors";
import { globalStyles } from "theme/styles";
import { getExclusiveAmbassadors } from "RestAPI";

const CampaignForm = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const navigateTo = location.state?.navigateTo || null

  const { placeholderImage,userData } = useContext(AppContext)
  const { initializeForm } = useOutletContext();
  const { CampaignID } = useParams();
  const blankCampaign = { gigs: [], categories: [], ambassadorFlag: "Public" };
  const [campaignDetails, setCampaignDetails] = useState(blankCampaign);
  const [savedCampaignDetails, setsavedCampaignDetails] = useState(null);
  const [campaignImagePreview, setcampaignImagePreview] = useState(null);

  const [showMatchProfileForm, setShowMatchProfileForm] = useState(false);
  const [showAmbassadorModal, setShowAmbassadorModal] = useState(false);
  const [recommendations, setrecommendations] = useState(null);

  const [showGigForm, setShowGigForm] = useState(false);
  const [gigFormDetails, setGigFormDetails] = useState({});

  const [showPaymentForm, setShowPaymentForm] = useState(false);
  const [paymentDetails, setPaymentDetails] = useState({});
  const [clientSecret, setclientSecret] = useState(null)
  const [paymentId, setPaymentId] = useState(null)
  const [campaignInfoForPayment, setCampaignInfoForPayment] = useState(null)

  const [contentSettingsOptions, setcontentSettingOptions] = useState([]);
  const [VideoDurationOptions, setVideoDurationOptions] = useState([]);
  const [categoryOptions, setcategoryOptions] = useState([]);
  const [locateOptions, setLocateOptions] = useState([]);
  const [videoScenarioOptions, setvideoScenarioOptions] = useState([]);
  const [genderOptions, setGenderOptions] = useState([]);
  const [productDeliveryOptions, setproductDeliveryOptions] = useState([]);
  const [gigTypeOptions, setGigTypeOptions] = useState([]);
  const [giveawayTypeOptions, setGiveawayTypeOptions] = useState([]);
  const [publishChannelOptions, setPublishChannelOptions] = useState([]);
  const [loading, setLoading] = useState(false)
  const toast = useToast()
  const [validationError, setValidationError] = useState({})
  const [submit, setSubmit] = useState(false)
  const [searchParams] = useSearchParams();
  const paymentIntentFromStripe = searchParams.get('payment_intent');
  const pId = searchParams.get('paymentId')
  const stripeRedirect = searchParams.get('redirect_status');
  const [projects, setProjects] = useState([])
  const [campaignSets, setCampaignSets] = useState([])
  const [groups, setGroups] = useState([])
  const [selectedCampaignSet, setSelectedCampaignSet] = useState({})
  const [selectedGroups, setSelectedGroups] = useState([])
  const [project, setProject] = useState({})
  const [showInterestModal, setShowInterestModal] = useState(false)
  const [attachments, setAttachments] = useState([])
  const [preferenceOptions, setPreferenceOptions] = useState([])
  const brandSubdomain = window.location.hostname.split('.')[0]?.toLowerCase() || ''
  const isPremium = brandSubdomain === 'dashboard' || brandSubdomain === 'dashboard-prod' || brandSubdomain === 'admin' ? false : true
  const [showExclusiveModal, setShowExclusiveModal] = useState(false)
  const [exclusiveAmbassador, setExclusiveAmbassador] = useState(null)
  const [exclusiveCampaignAmount, setExclusiveCampaignAmount] = useState(0)
  const [showCreditsUnavailableModal, setShowCreditsUnavailableModal] = useState(false)
  const [disableContentGig, setDisableContentGig] = useState(false)
  const [disablePublishGig, setDisablePublishGig] = useState(false)
  const [showAmbassadorPreference, setShowAmbassadorPreference] = useState(false)

  const navigationList = {
    'projectView' : `/projects/${location.state?.project.id}`
  }
  const setOptions = (data, setFunc) => {
    let optionsData = [];
    if (!data) return setFunc(optionsData);

    const optionKeys = Object.keys(data);
    let i = 0;
    optionKeys.forEach((element) => {
      const value = data[element];
      const tObj = {
        name: value.name || value,
        label: value.name || value,
        value: value.code || value,
        id: value.id || value,
      };
      optionsData.push(tObj);
      i++;
    });
    setFunc(optionsData);
  };

  const fetchBrandInterest = async () => {
    const res = await getBrandInterest()
    if(res.data && res.data.error === 'MATCHPROFILE_NOT_FOUND'){
      setShowInterestModal(true)
    }else{
      updateCampaignDetails({ interest: res.data })
    }
  }

  const fetchBrandAttachments = async () => {
    const res = await getBrandAttachments()
    if(res && res.length){
      setAttachments(res)
    }
  }

  const fetchCampaignDetails = async (id) => {
    if (!id || isNaN(id)) setCampaignDetails({});

    const campaign = await getCampaign(id);
    if (!campaign || !campaign.data || !campaign.data.id)
      return setCampaignDetails(blankCampaign);

    setcampaignImagePreview(campaign.data.imageUrl)
    if(campaign.data.groups?.length){
      setSelectedGroups(campaign.data.groups)
    }
    if(campaign.data.campaignSet){
      setSelectedCampaignSet(campaign.data.campaignSet)
      setProject(campaign.data.campaignSet?.project)      
    }
    await fetchProjectsAndGroups()
    if(campaign.data && campaign.data.attachments){
      setAttachments(campaign.data.attachments)
    }
    return setCampaignDetails(campaign.data);
  };

  const fetchCategories = async () => {
    const categories = await getCampaignsCategories();
    if (!categories || !categories.data || !categories.data.length)
      return setcategoryOptions([]);
    categories.data = categories.data.map(i => { i.value = i.name; return i })
    setcategoryOptions(categories.data);
  };

  const fetchProjectsAndGroups = async () => {
    const responseP = await getBrandProjects();
    if(responseP?.length){
      setProjects(responseP.filter(p => !!p.campaignSets?.length))
    }
    const responseG = await getBrandGroups()
    if(responseG?.length){
      setGroups(responseG)
    }
    if(projects?.length && project?.id){
      setCampaignSets(projects.filter(p => p.id === project.id)[0]?.campaignSets)
    }
  }

  const setInitialProject = () => {
    if(location && location.state){
      setProject(location.state.project)
      setCampaignSets(location.state.project.campaignSets)
      setSelectedCampaignSet(location.state.campaignSet)
    }    
  }

  const setInitialCampaignSet = () => {
    if(location && location.state){
      setSelectedCampaignSet(location.state.campaignSet)
    }
  }

  const setInitialGroups = () => {
    // if(campaignDetails.groups?.length){
    //   setSelectedGroups(campaignDetails.groups)
    // }
  }

  const fetchCampaignsLookupData = async () => {
    const options = await getCampaignsLookupData();
    if (!options || !options.data) return [];
    const {
      contentSettings,
      gender,
      productDelivery,
      videoDuration,
      videoScenario,
      giveawayType,
      gigType,
      ambassadorLocationType,
      publishChannelOptions,
      preferenceOptions
    } = options.data || {};
    delete giveawayType["PRODUCT"];

    setOptions(ambassadorLocationType, setLocateOptions);
    setOptions(contentSettings, setcontentSettingOptions);
    setOptions(productDelivery, setproductDeliveryOptions);
    setOptions(videoDuration, setVideoDurationOptions);
    setOptions(videoScenario, setvideoScenarioOptions);
    setOptions(gender, setGenderOptions);
    setOptions(gigType, setGigTypeOptions);
    setOptions(giveawayType, setGiveawayTypeOptions);
    setOptions(publishChannelOptions, setPublishChannelOptions);
    setOptions(isPremium ? preferenceOptions : {PUBLIC: 'Public'}, setPreferenceOptions);
  };

  const handleSubmit = async (draft = false) => {
    const data = { ...campaignDetails }
    if (!data.name || !data.imageUrl || !data.categories?.length || !data.gigs?.length
      || (!['Exclusive', 'Exclusive group'].includes(data.ambassadorFlag) && (!data.ambassadorCriteriaAgeFrom || !data.ambassadorCriteriaAgeTo))) {
      toast({
        title: 'Form Incomplete',
        description: "Please fill all the mandatory fields.",
        status: 'error',
        position: 'top',
        duration: 3000,
        isClosable: true,
      })
      setSubmit(true);
      return
    }

    if (!draft && ['Exclusive', 'Exclusive group'].includes(data.ambassadorFlag) && !exclusiveAmbassador) {
      toast({
        title: 'Form Incomplete',
        description: "You have selected Exclusive. Please select ambassador or save as draft.",
        status: 'error',
        position: 'top',
        duration: 3000,
        isClosable: true,
      })
      setSubmit(true);
      return
    }
    if (draft) {
      data.campaignStatus = 'Draft'
    } else {
      if (data.campaignStatus && data.campaignStatus !== 'Draft') {
        delete data.campaignStatus
      } else {
        data.campaignStatus = 'Created'
      }
    }
    if (data.imageUrl && data.imageUrl.includes('http')) {
      delete data.imageUrl // no new image is set, so reset the signed url with null
    }
    for (let gig of data.gigs) {
      if (gig.imageUrl && gig.imageUrl.includes('http')) {
        delete gig.imageUrl
      }
    }
    if(selectedGroups?.length){
      data.groups = selectedGroups
    }
    if(selectedCampaignSet?.id){
      data.campaignSet = selectedCampaignSet
    }
    if(attachments && attachments.length){
      data.attachments = attachments
    }
    if(['Exclusive', 'Exclusive group'].includes(data.ambassadorFlag) && exclusiveAmbassador){
      data.invites = [{user: exclusiveAmbassador, inviteStatus: 'Draft'}]
      data.ambassadorCriteriaAgeFrom = 0
      data.ambassadorCriteriaAgeTo = 0
    }
    setLoading(true)
    const campaignApiResponse = await createCampaign(data)
    setLoading(false)
    if (campaignApiResponse && campaignApiResponse.data && campaignApiResponse.data.id) {
      setsavedCampaignDetails(campaignApiResponse.data)
      if(draft){
        toast({
          title: 'Campaign saved as a draft.',
          description: "",
          status: 'success',
          position: 'top',
          duration: 3000,
          isClosable: true,
        })
        navigateTo && location.state ? navigate(navigationList[navigateTo]) : navigate('/campaigns')
      }else{
        toast({
          title: 'Campaign saved successfully.',
          description: "",
          status: 'success',
          position: 'top',
          duration: 3000,
          isClosable: true,
        })
        if (campaignDetails && campaignDetails.payment && campaignDetails.payment.length) {
          if (campaignDetails.invites?.length) {
            navigateTo && location.state? navigate(navigationList[navigateTo]) : navigate('/campaigns')
          } else {
            setShowAmbassadorModal(true)
          }
        } else {
          displayPaymentModal(campaignApiResponse.data)
        }
      }
    }
  };

  const displayPaymentModal = async (campaign) => {
    if (!campaign || isNaN(campaign.amount)) return
    setLoading(true)
    if(campaign.amount === 0){
      await saveExclusiveCampaignInvite(campaign.id)
    }
    let paymentObjResponse = await makeCampaignPayment({
      amount: 100 * campaign.amount,
      paymentMethodId: "",
      campaign: campaign,
    });
    setCampaignInfoForPayment(campaign)
    setLoading(false)
    setPaymentId(paymentObjResponse?.data?.paymentId || null)
    setPaymentDetails({ ...paymentDetails, paymentId: paymentObjResponse?.data?.paymentId || null, amount: 100*campaign.amount })
    console.log(paymentObjResponse)
  }

  const handleCampaignImage = async (e) => {
    const file = e.target.files[0];
    if (!file) return;
    setcampaignImagePreview(URL.createObjectURL(file) || '');
    const s3Obj = await getFileUploadSignedUrl({ mime: file.type }, "Campaign");
    if (!s3Obj || !s3Obj.data || !s3Obj.data.key)
      return updateCampaignDetails({ imageUrl: null });

    updateCampaignDetails({ imageUrl: s3Obj.data.key });
    await fetch(s3Obj.data.url, {
      method: 'PUT',
      headers: { 'Content-Type': file.type },
      body: file,
    });
    // free memory when ever this component is unmounted
    // return () => URL.revokeObjectURL(gigImagePreview)
  };

  const updateCampaignDetails = (obj) => {
    setCampaignDetails({ ...campaignDetails, ...obj });
  };

  const updateCampaignGigs = (gig) => {
    const gigs = campaignDetails.gigs;
    const existingGigIndex = gigs.findIndex(
      (x) => x.id === gig.id && x.key === gig.key
    );
    if (existingGigIndex > -1) {
      gigs[existingGigIndex] = gig;
    } else {
      gigs.push(gig);
    }
    updateCampaignDetails({ gigs: gigs });
  };

  const removeCampaignGig = (index) => {
    let gigs = campaignDetails.gigs
    gigs.splice(index, 1)
    updateCampaignDetails({ gigs })
  }
  const updateCampaignCategory = (val) => {
    const category = categoryOptions.filter((item) => item.name === val.category);
    updateCampaignDetails({ categories: [...category] });
  };

  const GigCard = (props) => {
    const { gig, index } = props
    const openGigForm = () => {
      setGigFormDetails(gig);
      setShowGigForm(true);
    };
    return !gig ? null : (
      <Box
        boxShadow="xl"
        p="6"
        rounded="md"
        bg="white"
        cursor="pointer"
        onClick={openGigForm}
      >
        <Flex gap="20px">
          <Image
            h={100}
            w={100}
            src={gig.image || gig.imageUrl}
            fallbackSrc={placeholderImage(100)}
            borderRadius="lg"
            overflow='hidden'
          />
          <Flex justifyContent="space-evenly" flexDirection="column" w='full' overflow='hidden'>
            <Text fontSize="2xl" color="brandbassBlue" isTruncated>
              {gig.gigName}
            </Text>
            <Flex gap={8}>
              <Text fontSize="md" color="gray.500" isTruncated >
                {gig.gigType}
              </Text>
              <Text fontSize="md" color="gray.500" isTruncated >
                {gig.gigVariation === 'Content' ? gig.videoScenario : gig.publishChannel}
              </Text>
              <Text fontSize="md" color="gray.500" isTruncated  >
                {gig.gigVariation === 'Content' ? gig.contentSettings : moment(gig.publishTime).format('MM-DD-YYYY hh:mm')}
              </Text>
              {gig.gigVariation === 'Content' ? <Text fontSize="md" color="gray.500" isTruncated  >
                {gig.videoDuration}
              </Text> : null}
            </Flex>
            <Flex>
              <Text fontSize="lg" color="gray.500" isTruncated w='full' >
                {gig.gigDescription}
              </Text>
              <Badge mt={4} colorScheme='green' variant='subtle'>{gig.gigVariation?.toUpperCase()}</Badge>
            </Flex>
          </Flex>
          <TrashIcon cursor="pointer" onClick={(e) => {
            e.stopPropagation()
            props.removeCampaignGig(index)
          }} />
        </Flex>
      </Box>
    );
  };

  const GigForm = (props) => {
    const { gig } = props;
    const [gigDetails, setGigDetails] = useState(gig || {});
    const [gigImagePreview, setGigImagePreview] = useState(gig.image || null);
    const [publishGig, setPublishGig] = useState(false)
    const toast = useToast()
    const [videoUrl, setVideoUrl] = useState(gig.videoUrl || null)
    const [mimeType, setMimeType] = useState('')
    const [videoLoading, setVideoLoading] = useState(false)
    const { isOpen, onToggle } = useDisclosure()
    const [submit,setSubmit]= useState(false)

    const handleGigImage = async (e) => {
      const file = e.target.files[0];
      if (!file) return;

      const s3Obj = await getFileUploadSignedUrl({ mime: file.type }, "Gig");
      if (!s3Obj || !s3Obj.data || !s3Obj.data.key)
        return updateGigDetails({ imageUrl: null });

      const image = URL.createObjectURL(file) || '';
      updateGigDetails({ image: image, imageUrl: s3Obj.data.key });
      setGigImagePreview(image);
      await fetch(s3Obj.data.url, {
        method: 'PUT',
        headers: { 'Content-Type': file.type },
        body: file,
      });
    };

    const updateGigDetails = (obj) => {
      setGigDetails({ ...gigDetails, ...obj });
    };

    const submitGig = () => {
      let existsGiveaway = false
      if (gigDetails.productGiveaway && gigDetails.productGiveawayValue > 0) {
        existsGiveaway = true
      }
      if (gigDetails.giveawayType && gigDetails.giveawayValue) {
        existsGiveaway = true
      }
      if(!gigDetails.gigVariation){
        setSubmit(true)
        return
      } 
      if (gigDetails.gigVariation === 'Content' && (!gigDetails.gigName || !gigDetails.imageUrl || !gigDetails.gigType
        || !gigDetails.videoScenario || !gigDetails.contentSettings || !gigDetails.videoDuration
        || !existsGiveaway)) {
        setSubmit(true)
        if(!existsGiveaway){
          toast({
            title: 'Form Incomplete',
            description: "Please enter giveaway details.",
            status: 'error',
            position: 'top',
            duration: 3000,
            isClosable: true,
          })
        }else{
          toast({
            title: 'Form Incomplete',
            description: "Please fill all the mandatory fields.",
            status: 'error',
            position: 'top',
            duration: 3000,
            isClosable: true,
          })
        }
        return
      }
      if (gigDetails.gigVariation === 'Publish' && (!gigDetails.gigName || !gigDetails.imageUrl || !gigDetails.gigType
        || !gigDetails.postContent || !gigDetails.publishChannel || !(gigDetails.submissions || gigDetails.parentKey))) {
          setSubmit(true)
          if(!(gigDetails.submissions || gigDetails.parentKey)){
            toast({
              title: 'Form Incomplete',
              description: "Please upload the video to be publish.",
              status: 'error',
              position: 'top',
              duration: 3000,
              isClosable: true,
            })
          }else{
            toast({
              title: 'Form Incomplete',
              description: "Please fill all the mandatory fields.",
              status: 'error',
              position: 'top',
              duration: 3000,
              isClosable: true,
            })
          }
        return
      }
      gigDetails.index = gigDetails.key
      if (publishGig) {
        let publishGigObj = { ...gigDetails };
        publishGigObj.key = gigDetails.key + 1;
        delete gigDetails.postContent
        delete gigDetails.tags
        delete gigDetails.publishTime
        delete gigDetails.publishChannel
        publishGigObj.gigVariation = 'Publish';
        publishGigObj.gigVariationType = 'Video';
        publishGigObj.parentKey = gigDetails.key;
        publishGigObj.productGiveaway = '';
        publishGigObj.productGiveawayValue = 0;
        publishGigObj.giveawayType = '';
        publishGigObj.giveawayValue = 0;
        gigDetails.gigVariation = 'Content';
        let gigs = [...campaignDetails.gigs, ...[gigDetails], ...[publishGigObj]]
        updateCampaignDetails({ gigs: gigs });
      } else {
        updateCampaignGigs(gigDetails);
      }
      setShowGigForm(false);
    };

    const handleVideoUpload = async (e) => {
      const file = e.target.files[0];
      if (!file) return;
      setVideoLoading(true)
      const res = await getCampaignSignedUrl({
        mimeType: file.type
      });
      const video = URL.createObjectURL(file) || '';
      setVideoUrl(video);
      setMimeType(file.type)
      if (res && res.data && res.data.s3Url) {
        await fetch(res.data.s3Url, {
          method: 'PUT',
          headers: { 'Content-Type': file.type },
          body: file,
        });
        const submission = res.data.submission ? res.data.submission : null;
        updateGigDetails({ submissions: [submission], videoUrl: video });
      }
      setVideoLoading(false)

    };

    const showToast = (message) => {
      toast({
        title: 'Info',
        description: message,
        status: 'info',
        position: 'bottom',
        duration: 5000,
        isClosable: true,
        variant: 'subtle'
      })
    }
    const PublishTimeInput = forwardRef(({ value, onClick }, ref) => (
      <BBButton mt={4} onClick={onClick} ref={ref}>
        {value ? value : 'Pick date and time'}
      </BBButton>
    ));

    useEffect(() => {
      if (gig.id && gig.gigVariation === 'Publish' && !gig.parentKey) {
        let submission = campaignDetails.submissions.filter(s => s.gig?.id === gig.id)[0]
        if (submission) {
          setVideoUrl(submission.signedUrl)
          updateGigDetails({ submissions: [submission] });
        }
      }
    }, [])
    
    return (
      <>
        <Modal
          isOpen={showGigForm}
          onClose={() => setShowGigForm(false)}
          size="4xl"
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>New Gig</ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6}>
              <Box
                p={8}
                borderWidth="0px"
                borderRadius="lg"
                overflow="hidden"
              >
                <Flex flexDirection="column" gap={10}>
                  <Flex flexDirection={{ base: 'row' }} gap={4}>
                    <FormControl isRequired>
                      <BBDropdown 
                        width={{ base: '50%', sm: '100%' }} 
                        height="50px" 
                        label="Variation" 
                        placeholder="Select gig variation"
                        required={true}
                        isInvalid={submit && !gigDetails.gigVariation}
                        value={gigDetails.gigVariation || ''}
                        onClick={(e) => {
                          setSubmit(false);
                          updateGigDetails({ gigVariation: e.target.value || '' })
                        }}
                        options={[{ value: 'Content', name: 'Content', label: 'Content', id: 1, disabled: props.disableContentGig }, { value: 'Publish', name: 'Publish', label: 'Publish only', id: 2, disabled:  props.disablePublishGig}]} />
                    </FormControl>
                    <Spacer />
                    <FormControl isRequired>
                      <BBDropdown width={{ base: '50%', sm: '100%' }} height="50px" label="Variation type" placeholder="Select variation type"
                        value={gigDetails.gigVariationType || ''}
                        onClick={(e) =>
                          updateGigDetails({ gigVariationType: e.target.value || '' })
                        }
                        options={[{ value: 'Video', name: 'Video', label: 'Video', id: 1 }]} />
                    </FormControl>
                  </Flex>
                  <Box>
                    <Flex flexDirection="column" gap={2}>
                      <Text fontWeight='bold' fontSize='16px' padding='5px 2px'>Gig details:</Text>
                      <Box
                        p={6}
                        borderWidth="1.5px"
                        borderRadius="lg"
                        overflow="hidden"
                      >
                        <Flex
                          gap={16}
                          flexDirection="row"
                        // flexWrap="wrap"
                        // justifyContent="space-evenly"
                        // align="center"
                        >
                          <Box position="relative">
                            <Input
                              type="file"
                              onChange={handleGigImage}
                              bg="transparent"
                              position="absolute"
                              inset="0 0 0 0"
                              cursor="pointer"
                              opacity={0}
                              h="100%"
                            />
                            <Image
                              w={300}
                              h={194}
                              src={gigDetails.imageUrl && gigDetails.imageUrl.includes('http') ? gigDetails.imageUrl : gigImagePreview}
                              objectFit='contain'
                              boxShadow='lg'
                              fallbackSrc="https://via.placeholder.com/150"
                              borderRadius="lg"
                              {...(submit && !gigDetails.imageUrl && {border:'2px solid red'})}
                              />
                              {submit && !gigDetails.imageUrl && <Text color={'#EB5757'} variant="bb-status">Please upload Image</Text>}
                          </Box>
                          <Flex
                            flexDirection="column"
                            justify="space-between"
                            gap={4}
                            w="full"
                          >
                            <FormControl>
                              <BBInput 
                                required={true} 
                                label="" 
                                variant="flushed" 
                                placeholder='Gig name'
                                isInvalid={submit&& !gigDetails.gigName} 
                                onChange={(e) => {
                                  setSubmit(false);
                                  updateGigDetails({
                                    gigName: e.target.value || '',
                                  })
                                }}
                                value={gigDetails.gigName || ''} maxLength={30} />
                              <Text float='right' as="SmallTextRegular" color='brandbassGray.500'>{gigDetails.gigName?.length || 0}/30</Text>
                            </FormControl>
                            <FormControl>
                              <BBInput required={true} label="" variant="flushed" placeholder='Short description' onChange={(e) =>
                                updateGigDetails({
                                  gigDescription: e.target.value || '',
                                })
                              }
                                value={gigDetails.gigDescription || ''} maxLength={30} />
                              <Text float='right' as="SmallTextRegular" color='brandbassGray.500'>{gigDetails.gigDescription?.length || 0}/30</Text>
                            </FormControl>
                          </Flex>
                        </Flex>
                      </Box>
                    </Flex>
                  </Box>
                  <FormControl isRequired>
                    <BBDropdown 
                      width={'100%'}
                      height="50px"
                      label="Type"
                      placeholder="Select gig type"
                      required={true}
                      isInvalid={submit && !gigDetails.gigType}
                      value={gigDetails.gigType || ''}
                      onClick={(e) => {
                        setSubmit(false);
                        updateGigDetails({ gigType: e.target.value || '' })
                      }}
                      options={gigTypeOptions} />
                  </FormControl>
                  {gigDetails.gigVariation === 'Publish' ? null :
                  <FormControl isRequired>
                    <BBDropdown width={'100%'}
                        height="50px"
                        label="Video scenario"
                        required={true}
                        isInvalid={submit && gigDetails.gigVariation === 'Content' && !gigDetails.videoScenario}
                        placeholder="Select video scenario"
                        value={gigDetails.videoScenario || ''}
                        onClick={(e) => {
                          setSubmit(false);
                          updateGigDetails({ videoScenario: e.target.value || '' })
                        }}
                        options={videoScenarioOptions} />
                  </FormControl>}
                  {gigDetails.gigVariation === 'Publish' ? null : <Box>
                    <Flex flexDirection="column" gap={2}>
                      {/* <Text fontWeight='bold' fontSize='16px' padding='5px 2px'>Content settings:</Text> */}
                      <Flex gap={4}>
                        <BBDropdown
                          id="gig-type"
                          required={true}
                          isInvalid={submit && gigDetails.gigVariation === 'Content' && !gigDetails.contentSettings}
                          width={{ base: '50%', sm: '100%' }}
                          label='Content settings'
                          placeholder="Video aspect ratio"
                          onClick={(e) => {
                            setSubmit(false);
                            updateGigDetails({
                              contentSettings: e.target.value || '',
                            })
                          }}
                          value={gigDetails.contentSettings || ''}
                          options={contentSettingsOptions}
                        />
                        <Spacer />
                        <BBDropdown
                          id="gig-type"
                          required={true}
                          isInvalid={submit && gigDetails.gigVariation === 'Content' && !gigDetails.videoDuration}
                          mandatoryWithOutLabel={true}
                          label='VideoDuration'
                          placeholder="Video duration"
                          width={{ base: '50%', sm: '100%' }}
                          onClick={(e) => {
                            setSubmit(false);
                            updateGigDetails({
                              videoDuration: e.target.value || '',
                            })
                          }}
                          value={gigDetails.videoDuration || ''}
                          options={VideoDurationOptions}
                        />
                      </Flex>
                    </Flex>
                  </Box>}
                  {gigDetails.gigVariation === 'Publish' ? null : <Flex flexDirection="column">
                    <Flex justify="space-between">
                      <Text fontWeight='bold' fontSize='16px' padding='5px 2px'>Additional details</Text>
                      <IconButton cursor="pointer" icon={<InfoIcon />} onClick={() => showToast('Include extra information about the Brand, Collection, or Product')} />
                    </Flex>
                    <BBTextarea
                      placeholder="Example: We are launching a holiday collection..."
                      onChange={(e) =>
                        updateGigDetails({
                          additionalDetails: e.target.value || '',
                        })
                      }
                      value={gigDetails.additionalDetails || ''}
                    />
                  </Flex>}
                  {gigDetails.gigVariation === 'Publish' ? null : <Flex flexDirection="column">
                    <Flex justify="space-between">
                      <Text fontWeight='bold' fontSize='16px' padding='5px 2px'>Requirement</Text>
                      <IconButton cursor="pointer" icon={<InfoIcon />} onClick={() => showToast('Include information that you need the Ambassador to clearly mention in the video')} />
                    </Flex>
                    <BBTextarea
                      placeholder="Example: We are launching a holiday collection, which is both sustainable and stylish. We are excited to work with fashion conscious ambassadors."
                      onChange={(e) =>
                        updateGigDetails({
                          gigRequirement: e.target.value || '',
                        })
                      }
                      value={gigDetails.gigRequirement || ''}
                    />
                  </Flex>}
                  {gigDetails.gigVariation === 'Publish' ? null : <Box>
                    <Flex flexDirection="column" gap='1rem'>
                      <Flex justify="space-between">
                        <Text fontWeight='bold' fontSize='16px' padding='5px 2px'>Giveaway:</Text>
                        <IconButton cursor="pointer" icon={<InfoIcon />} onClick={() => showToast('State the retail value of the product sample you will be sending to ambassador in order to create your content')} />
                      </Flex>
                      <Flex justify="space-between" gap='1rem'>
                        <Checkbox spacing='1rem' size='lg' isChecked={!!gigDetails.productGiveaway && !gigDetails.giveawayType} onChange={(e) => updateGigDetails({ productGiveaway: e.target.checked, giveawayType: null, giveawayValue: null })}>
                          <Text>Product</Text>
                        </Checkbox>
                        <InputGroup>
                          <InputLeftElement zIndex={0} height='50px' pr={4} pointerEvents='none' color='brandbassBlue.500' fontSize='1rem' children='$' />
                          <BBInput type='number' placeholder='Specify value' onChange={(e) => updateGigDetails({ productGiveawayValue: e.target.value })} value={gigDetails.productGiveawayValue} />
                        </InputGroup>
                      </Flex>
                      <Flex justify="space-between" gap='1rem'>
                        <Checkbox size='lg' spacing='1rem' isChecked={gigDetails.giveawayType && !gigDetails.productGiveaway} >
                          <Text>Others </Text>
                        </Checkbox>
                        <BBDropdown
                          ml={2}
                          height='50px'
                          id="gig-giveaway-others-type"
                          placeholder="Select"
                          onChange={(e) =>
                            updateGigDetails({
                              giveawayType: e.target.value || '',
                              productGiveaway: false,
                              productGiveawayValue: 0
                            })
                          }
                          value={gigDetails.giveawayType || ''}
                          options={giveawayTypeOptions}
                        />
                        <BBInput placeholder='Specify value' value={gigDetails.giveawayValue || ''} onChange={(e) => updateGigDetails({ giveawayValue: e.target.value })} />
                      </Flex>
                    </Flex>
                  </Box>}
                  {gigDetails.gigVariation === 'Content' && !gigDetails.id && !gigDetails.index && !props.disablePublishGig? <FormControl display='flex' alignItems='center'>
                    <FormLabel fontWeight='bold' fontSize='16px' padding='5px 2px' htmlFor='publishgig' mb='0'>
                      Publish gig to social channel?
                    </FormLabel>
                    <Switch id='publishgig' onChange={(e) => setPublishGig(e.target.checked)} />
                  </FormControl> : null}
                  {!publishGig && !gigDetails.parentKey && gigDetails.gigVariation === 'Publish' ?
                    <FormControl display='flex' flexDirection="column">
                      <FormLabel fontWeight='bold' fontSize='16px' padding='5px 2px' htmlFor='publishgig' mb='0'>
                        Publish video
                      </FormLabel>
                      <Box position="relative" alignSelf='center'>
                        {videoLoading ? <Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='brandbassBlue' size='xl' /> : <Input
                          type="file"
                          onChange={handleVideoUpload}
                          bg="transparent"
                          position="absolute"
                          inset="0 10 0 10"
                          cursor="pointer"
                          opacity={0}
                          h="50px"
                          placeholder="Add video"
                        />}
                        <Box mt={2}
                          p={1} borderWidth="1.5px" backgroundColor='brandbassGray.200'
                          borderRadius="md" textAlign='center'><Text color="">Click to upload video to be published</Text></Box>
                        {videoUrl ? <video
                          controls
                          style={{ marginTop: 16, maxWidth: 320, maxHeight: 240, minWidth: 320, minHeight: 240, boxShadow: '0px 0px 5px 1px grey' }}
                          width="320"
                          height="240"
                          preload="metadata"
                        >
                          <source src={videoUrl} type={mimeType} />
                          Your browser does not support the video tag.
                        </video> : null}
                      </Box>
                    </FormControl> : null}
                  {publishGig || gigDetails.gigVariation === 'Publish' ? <Flex flexDirection="column">
                    <Flex justify="space-between">
                      <Text fontWeight='bold' fontSize='16px' padding='5px 2px'>Post content</Text>
                      <IconButton cursor="pointer" icon={<InfoIcon />} onClick={() => showToast('State any posting requirements and suggested scripts')} />
                    </Flex>
                    <BBTextarea
                      required={true}
                      isInvalid={gigDetails.gigVariation === 'Publish' && submit && !gigDetails.postContent}
                      placeholder="Example: Follow my shop @ABCbrand to shop this post! I am excited to work with ABCbrand, which is dedicated to creating a line that is clean, natural, sustainably produced, vegan, and cruelty free.  https://ABCbrand.com."
                      onChange={(e) => {
                        setSubmit(false);
                        updateGigDetails({
                          postContent: e.target.value || '',
                        })
                      }}
                      value={gigDetails.postContent || ''}
                    />
                  </Flex> : null}
                  {publishGig || gigDetails.gigVariation === 'Publish' ? <Flex flexDirection="column">
                    <Flex justify="space-between">
                      <Text fontWeight='bold' fontSize='16px' padding='5px 2px'>Tags</Text>
                      <IconButton cursor="pointer" icon={<InfoIcon />} onClick={() => showToast('State any account names and relevant hashtags')} />
                    </Flex>
                    <BBTextarea
                      placeholder="Example: @ABCbrand @brandbass #cleanskincare #organicskincare #veganskincare #sustainableskincare"
                      onChange={(e) =>
                        updateGigDetails({
                          tags: e.target.value || '',
                        })
                      }
                      value={gigDetails.tags || ''}
                    />
                  </Flex> : null}
                  {publishGig || gigDetails.gigVariation === 'Publish' ? <FormControl isRequired>
                    <BBDropdown
                      width={'100%'}
                      required={true}
                      isInvalid={gigDetails.gigVariation === 'Publish' && submit && !gigDetails.publishChannel}
                      height="50px" label="Publish channel" placeholder="Select channel to publish"
                      value={gigDetails.publishChannel || ''}
                      onClick={(e) => {
                        setSubmit(false);
                        updateGigDetails({ publishChannel: e.target.value || '' })
                      }}
                      options={[{ value: "Facebook", name: "Facebook", id: 0 }, { value: "Instagram", name: "Instagram", id: 1 }, { value: "Tiktok", name: "Tiktok", id: 2 }]} />
                  </FormControl> : null}
                  {publishGig || gigDetails.gigVariation === 'Publish' ?
                    <FormControl display='flex' flexDirection="column">
                      <FormLabel fontWeight='bold' fontSize='16px' padding='5px 2px' htmlFor='publishtime' mb='0'>
                        Publish time
                      </FormLabel>
                      <DatePicker
                        placeholder='Select date'
                        selected={gigDetails.publishTime ? new Date(gigDetails.publishTime) : null}
                        dateFormat="MMMM d, yyyy h:mm aa"
                        showTimeSelect
                        timeFormat="HH:mm"
                        customInput={<PublishTimeInput />}
                        onChange={(date) =>
                          updateGigDetails({ publishTime: date })
                        } />

                    </FormControl> : null}
                </Flex>
              </Box>
            </ModalBody>

            <ModalFooter>
            <BBButton ml={4} mr={3}
                variant='outline'
                onClick={() => setShowGigForm(false)}
              >
                Cancel
              </BBButton>
              <BBButton onClick={submitGig}>
                Save
              </BBButton>
              
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    );
  };

  // const handleInvitesPress = () => {
  //   let invites = [...campaignDetails.invites];
  //   invites = invites.map(invite => {
  //     invite = {...invite, ...invite.user};
  //     return invite;
  //   });
  //   let activeInvites = invites.filter(i => i.inviteStatus !== 'Rejected');
  //   navigation.navigate("RecommendedAmbassador",{invites: activeInvites.length ? invites : [],heartBool:activeInvites.length===0,campaignId:CampaignID, isLocation: campaignDetails.ambassadorLocationType === 'Location-specific'});
  // }

  const paymentSuccessHandler = async () => {
    // const suitableAmbassadors = await getUserRecommendation({ campaignId: savedCampaignDetails.id || campaignDetails.id || null })
    // setrecommendations(!!suitableAmbassadors && !!suitableAmbassadors.data && !!suitableAmbassadors.data.users ? suitableAmbassadors.data.users : null)
    setShowAmbassadorModal(true)
  }

  const getRecommendations = async () => {
    const suitableAmbassadors = await getUserRecommendation({ campaignId: savedCampaignDetails?.id || campaignDetails?.id || null })
    setrecommendations(!!suitableAmbassadors && !!suitableAmbassadors.data && !!suitableAmbassadors.data.users ? suitableAmbassadors.data.users : null)
    setShowAmbassadorModal(true)
  }

  const getSelectedGroups = (values) => {
    // let groupIds = items && items.map(i => i.originalValue?.id)
    let data = groups.filter(item => values.includes(item.groupName) );
    setSelectedGroups(data.map(g => { return { id: g.id, value: g.groupName, name: g.groupName } }))
  }

  useEffect(async() => {
    setLoading(true)
    if(userData.role !== 'SUPER_ADMIN'){
      let res = await getExclusiveAmbassadors()
      if(res.users?.length){
        setShowAmbassadorPreference(true)
      }else{
        setCampaignDetails({...campaignDetails, ambassadorFlag: 'Public'})
      }
    }
    await fetchBrandInterest();    
    await fetchCategories();
    await fetchCampaignsLookupData();
    if (!!CampaignID && !isNaN(CampaignID)){
      await fetchCampaignDetails(Number(CampaignID));
    }else{
      fetchBrandAttachments();
      fetchProjectsAndGroups()
    }
    setLoading(false)
  }, []);

  useEffect(() => {
    setInitialProject()
  },[projects])

  useEffect(() => {
    setInitialCampaignSet()
  },[campaignSets])

  useEffect(() => {
    setInitialGroups()
  },[groups])

  // useEffect(() => {
  //   fetchProjectsAndGroups();
  // },[campaignDetails])
  useEffect(() => {
    if (!showGigForm) setGigFormDetails({ key: (campaignDetails.gigs?.length || 0) + 1 })
  }, [showGigForm])

  useEffect(() => {
    if(!clientSecret && !paymentId) return
    setShowPaymentForm(true)
    }, [clientSecret, paymentId])

  useEffect(() => {
    if(!paymentIntentFromStripe || !stripeRedirect) return
    setShowPaymentForm(false)
    if(stripeRedirect.toLowerCase() === 'succeeded') {
      toast({
        title: 'Payment successful.',
        description: "You can now send invites to ambassadors",
        status: 'success',
        position: 'top',
        duration: 3000,
        isClosable: true,
      })
      updatePaymentStatus(pId, 'Succeeded', paymentIntentFromStripe)
      .then(async res =>{
        if(exclusiveAmbassador){
          await saveExclusiveCampaignInvite(CampaignID ? CampaignID : campaignInfoForPayment?.id)
        }else{
          setShowAmbassadorModal(true)
        }
      }) 
      .catch(err => console.error(err))
    }
  } , [paymentIntentFromStripe, stripeRedirect])

  useEffect(() => {
    if(exclusiveAmbassador && ['Exclusive','Exclusive group'].includes(campaignDetails.ambassadorFlag) && campaignDetails.gigs?.length){
      let amount = 0
      let creditsContent = exclusiveAmbassador.credits.filter(c => c.gigVariation === 'Content').reduce((sum,a) => sum + a.numCredits, 0)
      let creditsPublish = exclusiveAmbassador.credits.filter(c => c.gigVariation === 'Publish').reduce((sum,a) => sum + a.numCredits, 0)
      setDisableContentGig(creditsContent <= campaignDetails.gigs.filter(g => g.gigVariation === 'Content').length)
      setDisablePublishGig(creditsPublish <= campaignDetails.gigs.filter(g => g.gigVariation === 'Publish').length)
      campaignDetails.gigs.forEach(gig => {
        let payrate = exclusiveAmbassador.payrates.find(p => p.gigVariation === gig.gigVariation && p.gigVariationType === gig.gigVariationType && (!p.gigChannel || p.gigChannel === gig.publishChannel))
        if(payrate){
          amount = amount + (payrate.payRate + (gig.giveawayType === 'Cash Tip' ? gig.giveawayValue : 0))
        }
      })
      setExclusiveCampaignAmount(amount)
    }else{
      setDisableContentGig(false)
      setDisablePublishGig(false)
    }
  },[campaignDetails, exclusiveAmbassador])

  return (
    <>
    <Flex position={'fixed'} left={0} top={0} zIndex={10} width={'100%'} style={{backgroundColor:'white'}}>
      <FormHeader 
        caption={CampaignID ? 'Edit Campaign' : 'Create Campaign'}
        submitHandler={()=>handleSubmit(false)}
        draftHandler= {() => handleSubmit(true)}
        closeHandler={() =>  navigateTo && location.state ? navigate(navigationList[navigateTo]) : navigate('/campaigns')}
        cancelHandler={() =>  navigateTo && location.state ? navigate(navigationList[navigateTo]) : navigate('/campaigns')}
        loading={loading}
      />
      </Flex>
      <Flex flexDirection="column" >

        <FormControl p={8} >
          {showAmbassadorPreference ? <BBDropdown required={true} isInvalid={submit && !campaignDetails.ambassadorFlag} width={'100%'} height="50px" label="Ambassador preference" placeholder="Select preference"
            value={campaignDetails.ambassadorFlag || ''}
            onClick={(e) => {
              setSubmit(false);
              if((e.target.value === 'Exclusive' || e.target.value === 'Exclusive group') && (!campaignDetails.ambassadorFlag || campaignDetails.ambassadorFlag !== e.target.value)){
                setShowExclusiveModal(true)
              }else{
                setExclusiveAmbassador(null)
              }
              updateCampaignDetails({
                ambassadorFlag: e.target.value,
              })
            }}
            options={preferenceOptions} /> : null}
            {exclusiveAmbassador && ['Exclusive','Exclusive group'].includes(campaignDetails.ambassadorFlag) ? 
            <Flex justifyContent={'space-between'}>
              <Flex width={'70%'} mt={4} justifyContent="space-between" cursor='pointer'
                  backgroundColor={globalStyles.colors.brandbassBlue2[200]}
                  borderColor={'white'}
                  borderWidth={'0px'}
                  p={2} borderRadius='md'>
                  <Flex alignItems="center">
                      <Box
                          me="12px"
                          borderRadius="50%"
                          border="1px solid"
                          borderColor='white'
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          w="50px"
                          h="50px"
                      >
                          <Avatar src={exclusiveAmbassador.profileImageUrl} w='48px' h='48px' borderRadius="50%" />
                      </Box>
                      <Flex direction="column">
                          <Text
                              fontSize={{ sm: "md", md: "lg", lg: "md" }}
                              // color={textColor}
                              fontWeight="bold"
                          >
                              {exclusiveAmbassador.firstName} {exclusiveAmbassador.lastName}
                          </Text>
                          <Text
                              fontSize={{ sm: "xs", md: "sm", lg: "xs" }}
                              color="gray.400"
                              fontWeight="semibold"
                          >
                              {exclusiveAmbassador.inviteStatus ? exclusiveAmbassador.inviteStatus : exclusiveAmbassador.tagLine}
                          </Text>
                      </Flex>
                      <Spacer/>
                      {exclusiveAmbassador.payrates?.length ? 
                      <Box gap={2} ml={16}>
                          {exclusiveAmbassador.payrates.map(payrate => {
                          return (
                              <Text key={payrate.id} fontWeight='400' fontSize='12px' lineHeight='20px' color='brandbassGray.500' textAlign='justify'>
                                  ${payrate.payRate} for {payrate.gigVariation} {payrate.gigVariationType} {payrate.gigChannel}  -  {exclusiveAmbassador.credits?.filter(c => c.gigVariation === payrate.gigVariation && c.gigVariationType === payrate.gigVariationType).reduce((sum,a) => sum+a.numCredits, 0)} credits left
                              </Text>)
                          })}
                      </Box> : 
                      <Text fontWeight='400' fontSize='14px' lineHeight='24px' color='brandbassGray.500' textAlign='justify'>
                          Default Payrates
                      </Text>}                              
                  </Flex>
                </Flex>
                <BBButton width={'30%'} mt={8} ml={8} onClick={() => setShowExclusiveModal(true)}>Change ambassador</BBButton>
            </Flex> : null}
        </FormControl>
        {campaignDetails.ambassadorFlag ? 
        <>
          <Box p={8} borderWidth="0px" borderRadius="lg" overflow="hidden" w='full' alignSelf='center'>
          {isPremium ? <FormControl isRequired >
          {projects?.length ? <Flex  mb={8} gap={4}>
            <BBAutocomplete  width={'50%'}
              defaultValues={project?[project.projectName]:[]}      
              options={projects.map(p => { return { id: p.id, value: p.projectName, name: p.projectName} })}
              isMultiple={false} label='Select project'
              onChange={(value, item) => setCampaignSets(projects.filter(p => p.id === item?.originalValue?.id)[0]?.campaignSets) }/>
            <BBAutocomplete  width={'50%'}
              defaultValues={selectedCampaignSet?[selectedCampaignSet.campaignSetName]:[]}
              options={campaignSets.map(cs => { return { id: cs.id, value: cs.campaignSetName, name: cs.campaignSetName} })}
              isMultiple={false} label='Select campaign set'
              onChange={(value, item) => setSelectedCampaignSet(item.originalValue) }/>
          </Flex>:null}
          
          {groups?.length ? <BBAutocomplete style={{marginTop: 8, marginBottom: 48}}
              defaultValues={selectedGroups?.map(g => g.groupName)}      
              options={groups && !!groups.length && groups.map(g => { return { id: g.id, value: g.groupName, name: g.groupName} })}
              isMultiple={true} label='Select groups'
              onChange={(value, items) => getSelectedGroups(value) }/> : null}
          </FormControl> : null}
          <Flex flexDirection="column" gap={10}>
            <Flex justify="space-between">
              <Box w="50%">
                <FormControl isRequired >
                  <BBInput required={true} isInvalid={submit && !campaignDetails.name} label="Campaign name" placeholder='Campaign name'
                    onChange={(e) => {
                      setSubmit(false);
                      updateCampaignDetails({ name: e.target.value || '' })
                    }

                    }
                    value={campaignDetails.name || ''} />
                </FormControl>
                <FormControl isRequired mt={10}>
                  <BBDropdown required={true} isInvalid={submit && !campaignDetails.categories.length} width={'100%'} height="50px" label="Select category" placeholder="Select category"
                    value={
                      campaignDetails.categories.length
                        ? campaignDetails.categories[0].name
                        : ''
                    }
                    onClick={(e) => { setSubmit(false); updateCampaignCategory({ category: e.target.value }) }}
                    options={categoryOptions} />
                </FormControl>
              </Box>
              <Box position="relative" w="30%" alignItems="center">
                <Input
                  type="file"
                  onChange={handleCampaignImage}
                  bg="transparent"
                  position="absolute"
                  inset="0 0 0 0"
                  cursor="pointer"
                  opacity={0}
                  h="100%"
                />
                <Image
                  src={campaignDetails.imageUrl && campaignDetails.imageUrl.includes('http') ? campaignDetails.imageUrl : campaignImagePreview}
                  objectFit='cover'
                  boxShadow='lg'
                  fallbackSrc="https://via.placeholder.com/250"
                  borderRadius="lg"
                  w={250}
                  h={250}
                  overflow='hidden'
                  {...(submit && !campaignDetails.imageUrl && {border:'2px solid red'})}
                />
                {submit && !campaignDetails.imageUrl && <Text color={'#EB5757'} variant="bb-status">Please upload Image</Text>}
              </Box>
            </Flex>


            {['Exclusive', 'Exclusive group'].includes(campaignDetails.ambassadorFlag) ? null : <Box>
              <Text>Ambassador criteria:</Text>
              <Box
                mt={4}
                p={8}
                borderWidth="1.5px"
                borderRadius="lg"
                overflow="hidden"
              >
                <Flex flexDirection="column" gap={5}>
                  <FormControl>
                    <BBDropdown required={true} isInvalid={submit && !campaignDetails.ambassadorLocationType} width={'100%'} height="50px" label="Select location" placeholder="Select location"
                      value={campaignDetails.ambassadorLocationType || ''}
                      onClick={(e) => {
                        setSubmit(false); updateCampaignDetails({
                          ambassadorLocationType: e.target.value,
                        })
                      }}
                      options={locateOptions} />
                  </FormControl>
                  {campaignDetails.ambassadorLocationType === 'Location-specific' ? <>
                    <FormControl isRequired>
                      <FormLabel htmlFor="age-from" lineHeight='22px'
                        fontWeight='bold'
                        fontSize='16px'
                        padding='5px 2px'>Where? Enter zip</FormLabel>
                      <NumberInput value={campaignDetails.location || ''}>
                        <NumberInputField
                          height="50px"
                          id="location"
                          placeholder="Enter zip"
                          onChange={(e) => updateCampaignDetails({ location: e.target.value })}
                        />
                      </NumberInput>
                    </FormControl>
                  </> : null}
                  <FormControl>
                    <BBDropdown required={true} isInvalid={submit && !campaignDetails.ambassadorCriteriaGender} width={'100%'} height="50px" label="Gender" placeholder="Select gender"
                      value={campaignDetails.ambassadorCriteriaGender || ''}
                      onClick={(e) => { setSubmit(false); updateCampaignDetails({ ambassadorCriteriaGender: e.target.value }) }}
                      options={genderOptions} />
                  </FormControl>

                  <Box>
                    <Flex gap={8}>
                      <FormControl isRequired>
                        <FormLabel htmlFor="age-from" lineHeight='22px'
                          fontWeight='bold'
                          fontSize='16px'
                          padding='5px 2px'>Age from:</FormLabel>
                        <NumberInput isInvalid={submit && !campaignDetails.ambassadorCriteriaAgeFrom} min={1} max={99} value={campaignDetails.ambassadorCriteriaAgeFrom}>
                          <NumberInputField
                            height="50px"
                            id="age-from"
                            placeholder="From"
                            onChange={(e) => { setSubmit(false); updateCampaignDetails({ ambassadorCriteriaAgeFrom: e.target.value }) }}
                          />
                          <NumberInputStepper>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                          </NumberInputStepper>
                        </NumberInput>
                        {submit && !campaignDetails.ambassadorCriteriaAgeFrom &&
                          <FormHelperText color="#EB5757"
                            fontSize='16px'
                            lineHeight='20px'
                            fontWeight='400'>
                            Please enter the value
                        </FormHelperText>}
                      </FormControl>

                      <FormControl isRequired>
                        <FormLabel htmlFor="age-to" lineHeight='22px'
                          fontWeight='bold'
                          fontSize='16px'
                          padding='5px 2px'>Age till:</FormLabel>
                        <NumberInput isInvalid={submit && !campaignDetails.ambassadorCriteriaAgeTo} min={1} max={99} value={campaignDetails.ambassadorCriteriaAgeTo}>
                          <NumberInputField
                            height="50px"
                            id="age-to"
                            placeholder="To"
                            onChange={(e) => {
                              setSubmit(false); updateCampaignDetails({ ambassadorCriteriaAgeTo: e.target.value })
                            }}
                          />
                          <NumberInputStepper>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                          </NumberInputStepper>
                        </NumberInput>
                        {submit && !campaignDetails.ambassadorCriteriaAgeTo && <FormHelperText color="#EB5757" fontSize='16px'
                          lineHeight='20px'
                          fontWeight='400'>
                          Please enter the value
                        </FormHelperText>}
                      </FormControl>
                    </Flex>
                  </Box>
                </Flex>
              </Box>
            </Box>}
            {['Exclusive', 'Exclusive group'].includes(campaignDetails.ambassadorFlag) ? null : 
            <Flex gap={8} align="center">
              <Box>
                <Text>Match Profile:</Text>
                <BBSecondaryButton mt={2} borderRadius="md" onClick={() => setShowMatchProfileForm(true)}>
                  Modify your match profile
                </BBSecondaryButton>
              </Box>
              {campaignDetails.payment?.length ? <Box>
                <Text>Ambassadors:</Text>
                <BBSecondaryButton mt={2} borderRadius="md" onClick={() => setShowAmbassadorModal(true)}>
                  View Ambassadors/Recommendations
                </BBSecondaryButton>
              </Box> : null}
            </Flex>
            }
            <Box>
              <Flex justify="space-between" align="center">
                <Text>Gigs:</Text>
                {campaignDetails.payment?.length ? null :<BBSecondaryButton
                  borderRadius="md"
                  onClick={() => setShowGigForm(true)}
                >
                  {" "}
                  + Add gig
                </BBSecondaryButton>}
              </Flex>
              <Box
                mt={4}
                p={8}
                borderWidth="1.5px"
                borderRadius="lg"
                overflow="hidden"
              >
                <Flex flexDirection="column" gap={5}>
                  {!!campaignDetails?.gigs?.length ?
                    campaignDetails.gigs.map((item, index) => (
                      <GigCard key={index} index={index} gig={item} removeCampaignGig={removeCampaignGig} />
                    )) : <Text textAlign='center'>No gigs. Click on add gig to create one.</Text>}
                </Flex>
              </Box>
            </Box>
            {exclusiveAmbassador && campaignDetails.gigs?.length > 0 ? 
            <Flex gap={2}>
              <Text>Amount:</Text>
              {/* <Flex gap={2} ml={8} flexDirection={'column'}>
                <Text>{`$${exclusiveCampaignAmount} for ${campaignDetails.gigs.length} gigs`}</Text>
                <Text>{`Brandbass comission: $${userData.brand?.brandbassComission * exclusiveCampaignAmount / 100} @${userData.brand?.brandbassComission}%`}</Text>
                <Text>{`Total amount =  $${exclusiveCampaignAmount + (userData.brand?.brandbassComission * exclusiveCampaignAmount / 100)}`}</Text>
              </Flex> */}
              <Text>{campaignDetails.gigs.length} credits will be used for this campaign.</Text>
            </Flex> : null}
            <FileAttachment attachments={attachments} setAttachments={setAttachments} />
            {/* <Flex gap={8}>
              {CampaignID ? null : <BBButton onClick={() => handleSubmit(true)}>
                Draft
              </BBButton>}
              {loading ? <Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='brandbassBlue' size='xl' /> : <BBButton
                borderRadius="md"
                onClick={() => handleSubmit(false)}
              >
                Submit
              </BBButton>}
              <BBButton onClick={() => navigate('/campaigns')} variant='outline' size='md'>Close</BBButton>
            </Flex> */}
          </Flex>
        </Box>
        </> : null}
      </Flex >
      {
        !!paymentId &&
        <PaymentModal
          visible={showPaymentForm}
          closeHandler={(e, proceedNext) => {
            setShowPaymentForm(false);
            if(!proceedNext){
              navigateTo && location.state ? navigate(navigationList[navigateTo]) : navigate('/campaigns')
            }
          }}
          successHandler={() => {
            setShowPaymentForm(false)
            paymentSuccessHandler()
          }}
          paymentDetails={paymentDetails}
          campaign={campaignInfoForPayment}
        />
      }
      <GigForm gig={gigFormDetails} disableContentGig={disableContentGig} disablePublishGig={disablePublishGig}/>

      {showMatchProfileForm ? <MatchProfileForm
        visible={showMatchProfileForm}
        closeHandler={() => setShowMatchProfileForm(false)}
        dataHandler={(val) => updateCampaignDetails({ interest: val })}
      /> : null }
      {showInterestModal ? <InterestNotAvailableModal
        visible={showInterestModal}
        closeHandler={() => navigate('/', {replace: true})}
      /> : null}
      {showExclusiveModal ? <ExclusiveAmbassadors
          visible={showExclusiveModal}
          closeHandler={(ev, selectedExclusiveAmbassador) => {
            setShowExclusiveModal(false)
            if (selectedExclusiveAmbassador) {
              let creditBalance = selectedExclusiveAmbassador.credits?.reduce((sum,a) => sum + a.numCredits, 0)
              if(creditBalance > 0){
                setExclusiveAmbassador(selectedExclusiveAmbassador)
                let creditsContent = selectedExclusiveAmbassador.credits.filter(c => c.gigVariation === 'Content').reduce((sum,a) => sum + a.numCredits, 0)
                let creditsPublish = selectedExclusiveAmbassador.credits.filter(c => c.gigVariation === 'Publish').reduce((sum,a) => sum + a.numCredits, 0)
                setDisableContentGig(creditsContent < 1)
                setDisablePublishGig(creditsPublish < 1)
              }else{
                setShowCreditsUnavailableModal(true)
              }
            }else{
              toast({
                title: 'Ambassador not selected',
                description: "Please review and select one ambassador.",
                status: 'error',
                position: 'top',
                duration: 3000,
                isClosable: true,
              })
              updateCampaignDetails({
                ambassadorFlag: null,
              })
            }            
          }}
          campaign={savedCampaignDetails || campaignDetails}
      /> : null}
      {showAmbassadorModal ? <RecommendedAmbassadors
          visible={showAmbassadorModal}
          closeHandler={(ev, refresh) => {
            setShowAmbassadorModal(false)
            if (refresh) {
              if (campaignDetails?.id) {
                navigate(`/campaigns/${campaignDetails.id}`)
              } else {
                navigateTo && location.state ? navigate(navigationList[navigateTo]) : navigate('/campaigns')
              }
            }else{
              toast({
                title: 'Ambassadors not chosen yet',
                description: "Please review and invite upto 3 ambassadors.",
                status: 'error',
                position: 'top',
                duration: 3000,
                isClosable: true,
              })
            }
            navigateTo && location.state ? navigate(navigationList[navigateTo]) : navigate('/campaigns')
            
          }}
          campaign={savedCampaignDetails || campaignDetails}
      /> : null}
      {showCreditsUnavailableModal ? 
      <CreditUnavailableModal
        visible={showCreditsUnavailableModal}
        closeHandler={() => { setShowCreditsUnavailableModal(false); updateCampaignDetails({ambassadorFlag: null,})}}
        dataHandler={() => {setShowCreditsUnavailableModal(false); setShowExclusiveModal(true)}}
      /> : null}
      {/* <AmbassadorInviteModal
        visible={showAmbassadorModal}
        closeHandler={(ev, refresh) => {
          if (refresh) {
            // fetchCampaignDetails(Number(CampaignID));
            if (campaignDetails?.id) {
              navigate(`/campaigns/${campaignDetails.id}`, { replace: true })
            } else {
              navigate(`/campaigns`, { replace: true })
            }
          }
          navigate(`/campaigns/${savedCampaignDetails.id}/edit`)
          setShowAmbassadorModal(false)
        }}
        dataHandler={(val) => updateCampaignDetails({ invites: val })}
        campaign={savedCampaignDetails || campaignDetails}
        recommendations={recommendations}
      /> */}
    </>
  );
};

export default CampaignForm;

export const CreateCampaign = () => <CampaignForm />;
export const EditCampaign = () => <CampaignForm />;


export const AmbassadorInviteModal = (props) => {
  const { visible, closeHandler, dataHandler, campaign, recommendations } = props

  const invitesAlreadyExist = !!campaign?.invites && !!campaign?.invites?.length && !!campaign.invites.filter(inv => inv.inviteStatus !== 'Rejected').length
  const [showModal, setShowModal] = useState(false)
  const [loading, setLoading] = useState(false)

  const [ambassadors, setAmbassadors] = useState([])
  const invites = campaign?.invites
  const toast = useToast();
  const submit = async () => {
    let selectedAmbassadors = ambassadors.filter(a => !!a.selected)
    if (selectedAmbassadors.length > 3) {
      toast({
        title: 'Choose 3 ambassadors',
        description: "Please choose upto 3 ambassadors.",
        status: 'error',
        position: 'top',
        duration: 3000,
        isClosable: true,
      })
      return
    }
    selectedAmbassadors = selectedAmbassadors.map(a => {
      return { user: a.id }
    })
    if (selectedAmbassadors.length) {
      await campaignInvite(campaign.id, { invites: selectedAmbassadors })
      closeHandler({}, true)
    }
  }

  const getRecommendations = async () => {
    setLoading(true)
    let response = await getUserRecommendation({ campaignId: campaign.id })
    setLoading(false)
    if (response && response.data && response.data.users) {
      const suitableAmbassadors = response.data.users.map(a => {
        a.selected = false
        return a
      })
      setAmbassadors(suitableAmbassadors)
    }
  }


  const updateAmbassadors = (val, type) => {
    if (invitesAlreadyExist) return
    // let selectedAmbassadors = [...invites]
    // //checked
    // if (!!type) {
    //   selectedAmbassadors.push(val)
    // }
    // //unchecked
    // if (!type) {
    //   selectedAmbassadors = invites.filter(item => item.id !== val.id)
    // }
    // setInvites(selectedAmbassadors)
    let amb = ambassadors.filter(a => a.selected)
    if (amb.length >= 3) {
      toast({
        title: 'Choose 3 ambassadors',
        description: "Please choose upto 3 ambassadors.",
        status: 'error',
        position: 'top',
        duration: 3000,
        isClosable: true,
      })
      return
    }
    setAmbassadors(ambassadors.map(amb => {
      if (amb.id === val.id) {
        amb.selected = type
      }
      return amb
    }))
  }

  useEffect(() => {
    if (!invitesAlreadyExist) {
      getRecommendations()
    }
    setShowModal(!!visible)
  }, [visible])

  return <>
    <Modal isOpen={showModal} onClose={closeHandler} size="4xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader color='brandbassBlue'>Ambassadors</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <Box
            p={8}
            pb='150px'
            // borderWidth="1.5px"
            borderRadius="lg"
            overflow="scroll"
          >
            <Flex flexDirection='column' gap={10}>
              {
                !!invitesAlreadyExist ?
                  <CheckboxGroup colorScheme='blue'>
                    {
                      invites.map((item, idx) => !item?.user ? null :
                        <Checkbox key={idx}>

                          <Flex alignItems='center' gap='20px' justifyContent='space-between' boxShadow="dark-xl" borderRadius="lg">
                            <Image
                              src={item.profileImageUrl || item.user.profileImageUrl}
                              fallbackSrc='https://via.placeholder.com/100'
                              w='100px' h='100px'
                              borderRadius="lg"
                              overflow='hidden'
                            />
                            <Flex direction="column">
                              <Text fontSize='2xl'>{item.user.userName}</Text>
                              <Text fontSize='l'>{item.inviteStatus}</Text>
                            </Flex>
                          </Flex>
                        </Checkbox>
                      )
                    }
                  </CheckboxGroup> :
                  <CheckboxGroup colorScheme='blue'>
                    {
                      loading ? <Flex alignSelf='center' flexDirection='column'><Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='brandbassBlue' size='xl' /> <Text>Hang tight for a couple of seconds! Working on your ambassador results, all you have to do is select your top 3 and send the invite.</Text></Flex> :
                        !ambassadors.length ? <Text>Hang tight! We are activating ambassadors in your area. For more immediate matches please switch to Digital/Remote in edit campaign</Text> :
                          <Accordion allowMultiple>
                            {ambassadors.map((user, idx) => !user ? null :
                              <AccordionItem key={idx}>
                                <AccordionButton>
                                  <Flex textAlign='left' gap={8}>
                                    <Checkbox spacing='6rem' key={idx} onChange={e => updateAmbassadors(user, e.target.checked)}></Checkbox>
                                    <Flex alignItems='center' gap='20px' justifyContent='space-between' boxShadow="dark-xl" borderRadius="lg">
                                      <Image
                                        src={user.profileImageUrl}
                                        fallbackSrc='https://via.placeholder.com/100'
                                        w='100px' h='100px'
                                        borderRadius="lg"
                                        overflow='hidden'
                                      />
                                      <Flex flexDirection='column'>
                                        <Text fontSize='2xl'>{user.firstName} {user.lastName}</Text>
                                        <Text fontSize='2xl'>{user.tagline}</Text>                                        
                                      </Flex>
                                    </Flex>
                                  </Flex>
                                  <Spacer />
                                  <AccordionIcon />
                                </AccordionButton>
                                <AccordionPanel pb={4}>
                                  <Flex
                                    flexDirection='row'
                                    gap='1rem'
                                    p='10px'
                                    key={idx}
                                    w='full'
                                    justifyContent='space-evenly'
                                    boxShadow='xs'
                                    alignItems='center'
                                  >
                                    <Flex flexDirection='column'>
                                      <Flex gap={1}>
                                        {user.categories?.length ? user.categories.slice(0, 5).map(cat => {
                                          return (
                                            <Tag size='sm' variant='subtle' key={cat.id}>{cat.name}</Tag>
                                        )
                                        }) : null}
                                      </Flex>
                                    <Spacer/>  
                                    <Text>
                                      {user.summary}
                                    </Text>

                                    </Flex>
                                    <Spacer/>
                                    <video
                                      controls
                                      style={{ maxWidth: 240, maxHeight: 320, minWidth: 240, minHeight: 320, boxShadow: '0px 0px 5px 1px grey' }}
                                      width="240"
                                      height="320"
                                      preload="metadata"
                                    >
                                      <source src={user.videoResumeUrl} />
                                    </video>
                                    
                                  </Flex>
                                </AccordionPanel>
                              </AccordionItem>
                            )}
                          </Accordion>
                    }
                  </CheckboxGroup>
              }

            </Flex>
          </Box>
        </ModalBody>
        <ModalFooter>
        <Button
            colorScheme="brandbassBlue"
            variant="outline"
            borderRadius="lg" mr={3}
            onClick={() => closeHandler({}, false)}
          >
            Cancel
          </Button>
          <Button
            colorScheme="brandbassBlue"
            borderRadius="lg"
            onClick={submit}
          >
            Submit
          </Button>
          
        </ModalFooter>
      </ModalContent>
    </Modal>
  </>
}

export const InterestNotAvailableModal = (props) => {
  const { visible, closeHandler } = props
  const [showModal, setShowModal] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    setShowModal(!!visible)
  }, [visible])

  return (
    <Modal isOpen={showModal} onClose={() => closeHandler()} size="xl" scrollBehavior='outside' closeOnOverlayClick={false} >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader color='brandbassBlue'>Match Profile Unavailable</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <Flex p={8} borderColor='grayBlue' borderWidth="1px" borderRadius="lg" alignItems='center' justifyContent='center'>
              <Text fontSize='20px'>
                Please fill Match Profile to create a campaign
              </Text>
          </Flex>
        </ModalBody>
        <ModalFooter>          
          <Button
            colorScheme="brandbassBlue"
            variant="outline"
            borderRadius="lg" mr={3}
            onClick={() => closeHandler()}
          >
            Cancel
          </Button>

          <Button
            colorScheme="brandbassBlue"

            borderRadius="lg"
            onClick={() => navigate('/profile?openMatchProfile=true')}
          >
            Ok
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export const CreditUnavailableModal = (props) => {
  const { visible, closeHandler, dataHandler } = props
  const [showModal, setShowModal] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    setShowModal(!!visible)
  }, [visible])

  return (
    <Modal isOpen={showModal} onClose={() => closeHandler()} size="xl" scrollBehavior='outside' closeOnOverlayClick={false} >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader color='brandbassBlue'>Credits unavailable for the selected ambassador</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <Flex p={8} borderColor='grayBlue' borderWidth="1px" borderRadius="lg" alignItems='center' justifyContent='center'>
              <Text fontSize='20px'>
                Please add exclusive credits to the contract assigned to the selected ambassador OR choose another ambassador
              </Text>
          </Flex>
        </ModalBody>
        <ModalFooter>          
          <Button
            colorScheme="brandbassBlue"
            variant="outline"
            borderRadius="lg" mr={3}
            onClick={() => dataHandler()}
          >
            Change ambassador
          </Button>

          <Button
            colorScheme="brandbassBlue"

            borderRadius="lg"
            onClick={() => navigate('/new/credit?type=Exclusive')}
          >
            Buy credits
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}