import React, { useEffect } from "react";

// Assets
// import signInImage from "assets/img/signInImage.png";
import { useContext } from "react";
import { AppContext } from "contexts/AppContext";
import { Navigate } from "react-router-dom";

function SignOut() {
  const {setUserData, userData} = useContext(AppContext)
  useEffect(() => {
      localStorage.clear()
      if(userData && userData.id) {
          setUserData(null)
      }
  }, []);

  return <Navigate to="/auth" />;
}

export default SignOut;
