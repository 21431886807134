// Endpoint for development
// const ENDPOINT = 'http://api-dev-brandbass-ai-1609174669.us-east-1.elb.amazonaws.com';
// const ENDPOINT = 'https://dev.brandbass.ai'

// Endpoint for Staging
// const ENDPOINT = 'https://api-stg.brandbass.ai';

// Endpoint for local
//  const ENDPOINT = 'http://localhost:3000';

// PROD 
// const ENDPOINT = 'https://api-dashboard.brandbass.ai';

const ENDPOINT = process.env.REACT_APP_API_BASEURL || 'https://api-dashboard.brandbass.ai'

const toObject = (arr, key) => arr.reduce((a, b) => ({ ...a, [b[key]]: b }), {});

async function apiFetch(route, verb = 'GET', params = {}, body){
    // params.role = "BRAND_MANAGER"; // TO DO : change role validation in API
    const jwt = localStorage.getItem('jwt'); // the token which is received on successful login
    const brandSubdomain = window.location.hostname.split('.')[0]
    params.brandSubdomain = !!brandSubdomain ? brandSubdomain.toLowerCase() : ''
    const finalRoute = route + '?' + serialize(params); // endpoint to call
    // options for the Fetch api
    const options = {
        method: verb,
        mode: 'cors', 
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer '+jwt
        }
    }

    // include body only for api calls that are not of type GET.
    if(verb.toLowerCase() !== 'get') options.body = body ? JSON.stringify(body) : ''
    localStorage.setItem('isLoading',true);
    return fetch(finalRoute, options)
        .then(response => response.json())
        .then(async responseJSON => {
            if(responseJSON.statusCode === 401 &&  responseJSON.message === 'Unauthorized'){
                localStorage.removeItem('isLoading');
                return;
            } else if(responseJSON.status === 404 ){
                localStorage.removeItem('isLoading');
                return responseJSON;
            } else {
                // include a 'total' key with length of the data array to have it compatible with React-Admin data-provider structure expectations
                responseJSON.total = responseJSON.data && responseJSON.data.length > 0 ? responseJSON.data.length : null
                localStorage.removeItem('isLoading');
                return responseJSON;
            }
        })
        .catch(error => {
            console.error(error)
            localStorage.removeItem('isLoading');
            return error;
        });   
}


export function getFileUploadSignedUrl(fileData, type){
    return apiFetch(`${ENDPOINT}/campaign/s3SignedUrlForUpload`, 'POST', {type}, fileData);
}

export function getMessageFileUploadSignedUrl(fileData, type){
    return apiFetch(`${ENDPOINT}/message/s3SignedUrlForUpload`, 'POST', {type}, fileData);
}

export function authenticate(signin){
    if(signin.userIdentifier && signin.userPassword){
        return apiFetch(`${ENDPOINT}/authentication/validate`,'POST', {}, signin);
    }
}

export function getUserData(){
    return apiFetch(`${ENDPOINT}/user/validate`,'GET', {});
}

export function checkUsernameAvailability(username){
    if(username){
        return apiFetch(`${ENDPOINT}/authentication/checkUsername`,'GET',{username});
    }
}

export function checkEmailAvailability(email){
    if(email){
        return apiFetch(`${ENDPOINT}/authentication/checkUserEmail`,'GET',{email});
    }
}

export function getCampaignMessages(id) {
    if(id) {
        return apiFetch(`${ENDPOINT}/message/findAllMessagesByModule/campaign/${id}`)
    }
}

export function checkClientVersion(){
    return apiFetch(`${ENDPOINT}/clientVersion`,'GET');
}

export function signUpUser(authentication, params){
    return apiFetch(`${ENDPOINT}/authentication/register`,'POST',params, authentication);
}

export function confirmUser(authentication,params){
    return apiFetch(`${ENDPOINT}/authentication/confirmRegistration`,'POST',params, authentication);
}

export function saveUser(userId, user){
    return apiFetch(`${ENDPOINT}/user/${userId}`,'PATCH',{},user);
}

export function adminCreateUser(user){
    if(user.id){
        return apiFetch(`${ENDPOINT}/user/adminUpdateUser`,'POST',{},user);        
    }else{
        return apiFetch(`${ENDPOINT}/user/adminCreateUser`,'POST',{},user);
    }    
}

export function forgotPassword(userName){
    return apiFetch(`${ENDPOINT}/authentication/forgotPassword`,'GET',{userName});
}

export function forcePasswordChange(authentication){
    return apiFetch(`${ENDPOINT}/authentication/forcePasswordChange`,'POST',{}, authentication);
}

export function resetPassword(authentication){
    return apiFetch(`${ENDPOINT}/authentication/changePassword`, 'POST', {}, authentication)
}

export function getIssues(params){
    return apiFetch(`${ENDPOINT}/issue`, 'GET',{},params).then(function(response){ return  response.data  });
}

export function getIssueReasons(type){
    return apiFetch(`${ENDPOINT}/issue/reasons`, 'GET', {type})
}

export function saveIssue(issue){
    return apiFetch(`${ENDPOINT}/issue`,'POST',{}, issue);
}

export function deleteIssue(id){
    return apiFetch(`${ENDPOINT}/issue/${id}`,'DELETE');
}
``
export function deleteProject(id){
    return apiFetch(`${ENDPOINT}/project/${id}`,'DELETE');
}

export function deleteTeam(id){
    return apiFetch(`${ENDPOINT}/team/${id}`,'DELETE');
}

export function deleteGroup(id){
    return apiFetch(`${ENDPOINT}/group/${id}`,'DELETE');
}

export function deleteUser(id){
    return apiFetch(`${ENDPOINT}/user/${id}`,'DELETE');
}

export function getOneIssue(id){
    return apiFetch(`${ENDPOINT}/issue/${id}`, 'GET');
}

export function allConversationsByUser(id){
    return apiFetch(`${ENDPOINT}/message/allConversationsByUser/${id}`, 'GET');
}

export function allMyConversations(id){
    // return apiFetch(`${ENDPOINT}/message/findAllConversationsByUser`, 'GET');
    return apiFetch(`${ENDPOINT}/message/conversation`, 'GET');
}

export function getOneConversation(id){
    // return apiFetch(`${ENDPOINT}/message/findAllConversationsByUser`, 'GET');
    return apiFetch(`${ENDPOINT}/message/conversation/${id}`, 'GET').then(function(response){ return response.data });
}

export function getDashboardCounts(){
    return apiFetch(`${ENDPOINT}/dashboard/counts`, 'GET').then(function(response){ return response && response.data ? response.data : response; });
}

export function getSettings(){
    return apiFetch(`${ENDPOINT}/setting`, 'GET').then(function(response){ console.log(response.data);  return response.data; });
}

export function getSetting(){
    return apiFetch(`${ENDPOINT}/setting/dashboard`, 'POST',{}, {}).then(function(response){ console.log(response.data); let ss = toObject( response.data.settings, 'name');
    console.log(ss); return {
        data: { id: 123, language: 'en', settings:ss }
    } });
}

export function updateSetting(params){
    return apiFetch(`${ENDPOINT}/setting`, 'PUT',{}, params).then(function(response){ console.log(response.data);  return { data: response.data }; });
}

export function getUsers(params){
    console.log('params ',params)
    return apiFetch(`${ENDPOINT}/user/all`, 'POST',{}, params).then(function(response){ return response.data; });
}

export function getBrandUsers(params){
    return apiFetch(`${ENDPOINT}/user/brandUsers`, 'GET',params,{}).then(function(response){ return response.data; });
}

export function getEligibleUsersForTeam(params){
    return apiFetch(`${ENDPOINT}/team/eligibleUsersForTeam`, 'GET',{}, params).then(function(response){ return response.data; });
}

export function getBrandTeams(params){
    return apiFetch(`${ENDPOINT}/team`, 'GET',{}, params).then(function(response){ return response.data; });
}

export function createTeam(team){
    if(team.id){
        return apiFetch(`${ENDPOINT}/team/${team.id}`, 'PATCH', {}, team).then(function(response){return response.data;})
    }else{
        return apiFetch(`${ENDPOINT}/team`, 'POST', {}, team).then(function(response){return response.data;})
    }
}

export function getTeam(id){
    return apiFetch(`${ENDPOINT}/team/${id}`,'GET').then((response) => response.data)
}

export function getBrandGroups(params){
    return apiFetch(`${ENDPOINT}/group`, 'GET', params).then(function(response){ return response.data; });
}

export function createGroup(group){
    if(group.id){
        return apiFetch(`${ENDPOINT}/group/${group.id}`, 'PATCH', {}, group).then(function(response){return response.data;})
    }else{
        return apiFetch(`${ENDPOINT}/group`, 'POST', {}, group).then(function(response){return response.data;})
    }
}

export function getGroup(id){
    return apiFetch(`${ENDPOINT}/group/${id}`,'GET').then((response) => response.data)
}

export function getBrandProjects(params){
    return apiFetch(`${ENDPOINT}/project`, 'GET',{}, params).then(function(response){ return response.data; });
}

export function createProject(project){
    if(project.id){
        return apiFetch(`${ENDPOINT}/project/${project.id}`, 'PATCH', {}, project).then(function(response){return response.data;})
    }else{
        return apiFetch(`${ENDPOINT}/project`, 'POST', {}, project).then(function(response){return response.data;})
    }
}

export function getBrands(params){
    return apiFetch(`${ENDPOINT}/user/brands`, 'POST',{}, params).then(function(response){ return response.data; });
}
export function getAmbassadors(params){
    return apiFetch(`${ENDPOINT}/user/ambassadors`, 'POST',{}, params).then(function(response){ return response ? response.data:[]; });
}

export function getEarnings(params){
    return apiFetch(`${ENDPOINT}/user/earnings`, 'POST',{}, params).then(function(response){ return response ? response.data:[]; });
}

export function getPayments(params){
    return apiFetch(`${ENDPOINT}/user/payments`, 'POST',{}, params).then(function(response){ return response ? response.data:[]; });
}

export function getUser(userId){
    return apiFetch(`${ENDPOINT}/user/${userId}`, 'GET').then(function(response){ return  { data: response.data }; });
} 

export function getDashboardUser(userId){
    return apiFetch(`${ENDPOINT}/user/dashboard/${userId}`, 'GET').then(function(response){ return  { data: response.data }; });
}
export function approveUser(params){
    return apiFetch(`${ENDPOINT}/user/approve`, 'POST',{}, params).then(function(response){ return response; });
}

export function changeUserStatus(params){
    return apiFetch(`${ENDPOINT}/user/status`, 'POST',{}, params).then(function(response){ return response; });
}

export function activeAwayStatus(params){
    return apiFetch(`${ENDPOINT}/user/away`, 'POST',{}, params).then(function(response){ return response; });
}

export function userEnableStatus(params){
    return apiFetch(`${ENDPOINT}/user/enable`, 'POST',{}, params).then(function(response){ return response; });
}

export function changeAmbassadorPayrate(payrate){
    return apiFetch(`${ENDPOINT}/user/ambassadorPayrate`, 'POST',{}, payrate).then(function(response){ return response; });
}

export function userChangeDashboardAccess(params){
    return apiFetch(`${ENDPOINT}/user/dashboardAccess`, 'POST',{}, params).then(function(response){ return response; });
}

export function getCampaigns(params){
    return apiFetch(`${ENDPOINT}/campaign/all`, 'POST',{}, params).then(function(response){ return   response? response.data:null  });
}
export function getInvites(params){
    return apiFetch(`${ENDPOINT}/user-invite/all`, 'POST',{}, params).then(function(response){ return   response.data  });
}
export function getGigs(params){
    return apiFetch(`${ENDPOINT}/campaign/allGigs`, 'POST',{}, params).then(function(response){ return   response.data  });
}

export function getFeedbacks(params){
    return apiFetch(`${ENDPOINT}/feedback/all`, 'POST',{}, params).then(function(response){ return   response.data  });
}

export function deleteCampaign(id){
    return apiFetch(`${ENDPOINT}/campaign/${id}`,'DELETE').then(function(response){ return   { data: response.data };   });
}

export function deleteGig(id){
    return apiFetch(`${ENDPOINT}/campaign/gig/${id}`,'DELETE').then(function(response){ return   { data: response.data };   });
}

export function getCampaign(campaginId){
    return apiFetch(`${ENDPOINT}/campaign/dashboard/${campaginId}`, 'GET').then(function(response){ return  { data: response.data }; });
}

export function inviteCampaign(params){
    console.log('params ',params)
    return apiFetch(`${ENDPOINT}/campaign/invite`, 'POST',{}, params).then(function(response){ return  { data: response }; });
}
export function deleteInvite(id){
    return apiFetch(`${ENDPOINT}/user-invite/${id}`,'DELETE').then(function(response){ return   { data: response.data };   });
}

export function approveInvite(params){
    console.log('params ',params)
    return apiFetch(`${ENDPOINT}/user-invite/approve-user-invite`,'POST',{}, params);
}

export function resendInviteEmail(params){
    console.log('params ',params)
    return apiFetch(`${ENDPOINT}/user-invite/resend-user-invite`,'POST',{}, params);
}

export function createInvite(params){
    console.log('params ',params)
    return apiFetch(`${ENDPOINT}/user-invite/create-user-invite`,'POST',{}, params).then(function(response){ console.log(response);return   { data: response?.data };   });
}

export function locationUpdate(params){
    console.log('params ',params)
    return apiFetch(`${ENDPOINT}/user/locationupdate`,'POST',{}, params);
}


export function updatePaymentStatus(id, status, stripeReferenceId, paymentMethodId){
    return apiFetch(`${ENDPOINT}/payment/update-status`, 'POST', {paymentId: id, status, stripeReferenceId, paymentMethodId});
}

export function getCampaignsCategories(params){
    return apiFetch(`${ENDPOINT}/category`, 'GET', params);
}

export function getCampaignsLookupData(params){
    return apiFetch(`${ENDPOINT}/campaign/lookupdata`, 'GET', params);
}

export function getCampaignDetails(id, params){
    if(id) {
        return apiFetch(`${ENDPOINT}/campaign/${id}`, 'GET', params);
    }
}

export function createCampaign(data){
    if(data.id) {
        return apiFetch(`${ENDPOINT}/campaign/updateCampaign`, 'POST', {},data);
    }else{
        return apiFetch(`${ENDPOINT}/campaign`, 'POST', {},data);
    }
}

export function updateCampaign(data){
    if(data) {
        return apiFetch(`${ENDPOINT}/campaign/updateCampaign`, 'POST', "",data);
    }
}

export function cloneCampaign(data){
    if(data) {
        return apiFetch(`${ENDPOINT}/campaign/clone`, 'POST', {},data);
    }
}

export function campaignInvite(id,data){
    if(data) {
        return apiFetch(`${ENDPOINT}/campaign/${id}`, 'PATCH', {},data);
    }
}

export function getUserRecommendation(params){
    if(params && params.campaignId){
        return apiFetch(`${ENDPOINT}/recommendation/campaign/${params.campaignId}`, 'GET', params);
    }else{
        return apiFetch(`${ENDPOINT}/recommendation/ambassadors`, 'GET', params);
    }
}

export function getContractUserRecommendation(params){
    return apiFetch(`${ENDPOINT}/recommendation/contractAmbassadors`, 'GET', params);
}

export function getCampaignSignedUrl(submission){
    // if(submission.campaign.id){
        return apiFetch(`${ENDPOINT}/campaign/s3SignedUrl`, 'POST', {}, submission);
    // }
}

export function getCampaignSubmissions(CampaignID, GigID){
    if(CampaignID){
        return apiFetch(`${ENDPOINT}/campaign/submissions`,'GET',{CampaignID, GigID});
    }
}

export function makeCampaignPayment(payment){
    return apiFetch(`${ENDPOINT}/payment`, 'POST', {}, payment);
}

export function getBrandData(id){
    return apiFetch(`${ENDPOINT}/brand/${id}`, 'GET');
}

export function createBrand(brand){
    return apiFetch(`${ENDPOINT}/brand`,'POST',{},brand);
}
export function saveBrand(brandId,data){
    return apiFetch(`${ENDPOINT}/brand/${brandId}`,'PATCH',{},data);
}
export function deleteBrand(brandId){
    return apiFetch(`${ENDPOINT}/brand/${brandId}`,'DELETE');
}

export function updateSubmission(submission){
    if(submission && submission.id){
        return apiFetch(`${ENDPOINT}/campaign/updateSubmission`, 'POST', {}, submission);
    }
}

export function saveCampaignInvite(invites){
    if(invites.length){
        return apiFetch(`${ENDPOINT}/campaign/saveInvites`, 'POST',{}, invites);
    }
}

export function getNotifications(params){
    return apiFetch(`${ENDPOINT}/notification`, 'GET', params);
}

export  function getUnreadNotificationsCount(){
    return apiFetch(`${ENDPOINT}/notification/unread-count`,'GET');
}

export function updateNotification(data){
    return apiFetch(`${ENDPOINT}/notification/${data.id}`, 'PATCH', {},data);
}

export function postCampaignMessage(data) {
    if(data) {
        return apiFetch(`${ENDPOINT}/message/new`, 'POST',  {},data);
    }
}

export function getDashboardMetrics() {
    return apiFetch(`${ENDPOINT}/dashboard/brandMetrics`)
}

export function getUserPublicInfo(id) {
    return apiFetch(`${ENDPOINT}/user/${id}/public`,'GET'); 
}


export function getAllQuestionsOfType(params) {
    return apiFetch(`${ENDPOINT}/feedback/question`, `GET`, params);
}

export function saveFeedback(id, feedback) {
    if(id){
        return apiFetch(`${ENDPOINT}/feedback/${id}`, `PATCH`, {}, feedback);
    }else{
        return apiFetch(`${ENDPOINT}/feedback`, `POST`, {}, feedback);
    }    
}

export function getCards(){
    return apiFetch(`${ENDPOINT}/payment/getCards`,'GET');
}

export function updatePaymentIntent(paymentIntentId, paymentIntent, paymentMethod){
    return apiFetch(`${ENDPOINT}/payment/update-intent`,'POST', {}, {paymentIntentId, paymentIntent, paymentMethod});
}

export function createPaymentIntent({paymentId, amount, paymentMethod}){
    return apiFetch(`${ENDPOINT}/payment/create-intent`,'POST', {}, {paymentId, amount, paymentMethod});
}

export function getAllCampaigns(){
    return apiFetch(`${ENDPOINT}/campaign/campaignsWithoutCampaignSet`,'GET')
}

export function getProject(id) {
    return apiFetch(`${ENDPOINT}/project/${id}`,'GET')
}

export function getBrandInterest(){
    return apiFetch(`${ENDPOINT}/brand-interests/getMatchProfile`, 'GET', {})
}

export function getAmbassadorInterest(){
    return apiFetch(`${ENDPOINT}/ambassador-interests/getMatchProfile`, 'GET', {})
}

export function getCredits(){
    return apiFetch(`${ENDPOINT}/payment/adminGetCredits`, 'GET', {})
}

export function createCredit(credit){
    return apiFetch(`${ENDPOINT}/payment/adminAddCredits`, 'POST', {}, credit).then(function(response){return response.data;})
}

export function getAllBrands(){
    return apiFetch(`${ENDPOINT}/brand`, 'GET', {})
}

export function saveBrandInterest(data){
    if(data.id){
        return apiFetch(`${ENDPOINT}/brand-interests/${data.id}`,'PATCH',{}, data);
    }else{
        return apiFetch(`${ENDPOINT}/brand-interests/`,'POST',{},data);
    }    
}

export function createMessageAttachment(s3Key, attachmentType){
    if(!s3Key) return null
    return apiFetch(`${ENDPOINT}/message/attachment/`, `POST`, {}, {s3Key, attachmentType})
}

export function getCreditBalance(credit){
    return apiFetch(`${ENDPOINT}/payment/creditBalance`, 'GET', {}).then(function(response){return response.data;})
}

export function getOrders(){
    return apiFetch(`${ENDPOINT}/payment/orders`, 'GET', {}).then(function(response){return response.data;})
}

export function createAttachmentObject(attachment){
    return apiFetch(`${ENDPOINT}/attachment`,'POST',{}, attachment).then(response => response.data)
}

export function getBrandAttachments(){
    return apiFetch(`${ENDPOINT}/brand/attachment`,'GET',{}).then(response => response.data)
}

export function getSubscriptions(){
    return apiFetch(`${ENDPOINT}/payment/getSubscriptions`,'GET',{}).then(response => response.data)
}

export function getUserSubscriptions(){
    return apiFetch(`${ENDPOINT}/payment/getUserSubscriptions`,'GET',{}).then(response => response.data)
}

export function saveBrandSubscription(subscription){
    return apiFetch(`${ENDPOINT}/subscription`,'POST',{}, subscription).then(response => response.data)
}

export function updateSubscription(subscription){
    return apiFetch(`${ENDPOINT}/subscription/update`,'POST',{}, subscription).then(response => response.data)
}

export function modifySubscription(id, subscription){
    return apiFetch(`${ENDPOINT}/subscription/${id}`,'PATCH',{}, subscription).then(response => response.data)
}

export function cancelSubscription(subscription){
    return apiFetch(`${ENDPOINT}/subscription/${subscription.id}`,'DELETE').then(response => response.data)
}

export function getSubscribedAvailableUsers(){
    return apiFetch(`${ENDPOINT}/subscription/users`,'GET').then(response => response.data)
}

export function validateSubscription(){
    return apiFetch(`${ENDPOINT}/subscription/validate`,'GET').then(response => response.data)
}

export function findSubscriptionByID(id){
    return apiFetch(`${ENDPOINT}/subscription/${id}`,'GET').then(response => response.data)
}

export function toggleSubscription(id){
    return apiFetch(`${ENDPOINT}/subscription/${id}/toggle`,'POST').then(response => response.data)
}

export function getCreditPaymentObject(credit){
    return apiFetch(`${ENDPOINT}/payment/creditPayment`,'POST', {}, credit).then(response => response.data)
}

export function getContractCreditPaymentObject(contractItems){
    return apiFetch(`${ENDPOINT}/payment/creditPaymentContract`,'POST', {}, contractItems).then(response => response.data)
}

export function findAllSubscriptions(){
    return apiFetch(`${ENDPOINT}/subscription`,'GET').then(response => response.data)
}

export function createOrFindConversation(conversation){
    return apiFetch(`${ENDPOINT}/message/findOrCreateNewConversation`,'POST',{}, conversation).then(response => response.data)
}

export function getExclusiveAmbassadors(){
    return apiFetch(`${ENDPOINT}/recommendation/exclusiveAmbassadors`,'GET').then(response => response.data)
}


export function getBrandContracts(){
    return apiFetch(`${ENDPOINT}/contract`,'GET').then(response => response.data)
}

export function getContract(id){
    return apiFetch(`${ENDPOINT}/contract/${id}`,'GET').then(response => response.data)
}

export function deleteContract(id){
    return apiFetch(`${ENDPOINT}/contract/${id}`,'DELETE').then(response => response.data)
}

export function createContract(contract){
    return apiFetch(`${ENDPOINT}/contract`,'POST',{}, contract).then(response => response.data)
}

export function updateInviteStatus(id, invite){
    return apiFetch(`${ENDPOINT}/contract/${id}/updateInviteStatus`,'POST',{}, invite).then(response => response.data)
}

export function saveExclusiveCampaignInvite(campaignId){
    return apiFetch(`${ENDPOINT}/campaign/${campaignId}/updateExclusiveInviteStatus`,'POST').then(response => response.data)
}

export function getAllSubmissions(search){
    return apiFetch(`${ENDPOINT}/campaign/allSubmissions`,'POST',{},search).then(response => response.data)
}

export function getOneSubmission(SubmissionID){
    return apiFetch(`${ENDPOINT}/campaign/submission/${SubmissionID}`,'GET').then(response => response.data)
}

export function createNotificationPreferences(preferences){
    return apiFetch(`${ENDPOINT}/notification-preference/multiple`,'POST', {}, preferences).then(response => response.data)
}

export function updateNotificationPreferences(preferences){
    return apiFetch(`${ENDPOINT}/notification-preference/updateMultiple`,'POST', {}, preferences).then(response => response.data)
}

export function getNotificationPreferences(preferences){
    return apiFetch(`${ENDPOINT}/notification-preference`,'GET').then(response => response.data)
}

export function updatePreferredAmbassadors(brandId, prefAmbassadors){
    return apiFetch(`${ENDPOINT}/brand/${brandId}/preferredAmbassadors`,'POST',{}, prefAmbassadors).then(response => response.data)
}

function serialize(obj) {
    var str = [];
    for (var p in obj)
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
      }
    return str.join("&");
}
