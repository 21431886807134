/*eslint-disable*/
import React from "react";
import PropTypes from "prop-types";
import { Textarea, FormControl, FormLabel,Text } from '@chakra-ui/react';

export default function BBTextarea(props) {
  return (
    <>
      <FormControl {...(props.required && { isRequired: props.required })} >
        {props.label && <FormLabel lineHeight='22px'
          fontWeight='bold'
          fontSize='16px'
          padding='5px 2px'>{props.label}</FormLabel>}
        <Textarea
         {...props}
         paddingLeft={props.paddingLeft ? props.paddingLeft : '20px'} 
          value={props.value}
          onChange={props.onChange}
          placeholder={props.placeholder ? props.placeholder : ''}
          resize={props.resize ? props.resize : true}
        />
        {props.isInvalid && props.required && <Text color={'#EB5757'} variant="bb-status">Please enter the value</Text>}
      </FormControl>
    </>
  );
}
