// import
import Tables from "views/Dashboard/Tables";
import Billing from "views/Dashboard/Billing";
import RTLPage from "views/Dashboard/RTL";
// import Profile from "views/Dashboard/Profile";
import SignIn from "views/Auth/SignIn.js";
import SignUp from "views/Auth/SignUp.js";
import Users from "views/Dashboard/Users";
import Teams from "views/Dashboard/Teams";
import {
  Flex, Box, Stack, Container
} from "@chakra-ui/react";
import {
  HomeIcon,
  StatsIcon,
  CreditIcon,
  PersonIcon,
  DocumentIcon,
  RocketIcon,
  SupportIcon,
  ChatIcon
} from "components/Icons/Icons";

import { CreateCampaign } from "views/Dashboard/Campaign/CampaignForm";
import { BellIcon, Icon, PlusSquareIcon, StarIcon, WarningTwoIcon } from "@chakra-ui/icons";
import CampaignForm from "views/Dashboard/Campaign/CampaignForm";
import NoMatch from "views/404/NoMatch";
import { Route, Routes } from "react-router-dom";
import Login from "layouts/Login";
import Dashboard from "layouts/Dashboard";
import Campaigns from "views/Dashboard/Tables/components/Campaigns";
import CheckAuth from "components/Utils/CheckAuth";
import Groups from "views/Dashboard/Groups";
import Projects from "views/Dashboard/Projects"
import Footer from "components/Footer/Footer"
import Registration from "layouts/Registration";
import Profile from "views/Dashboard/Users/components/Profile";
import UserList from "views/Dashboard/Users/components/UserList";
import TeamList from "views/Dashboard/Teams/components/TeamList";
import UserForm from "views/Dashboard/Users/components/UserForm";
import ViewUser from "views/Dashboard/Users/components/ViewUser";
import TeamForm from "views/Dashboard/Teams/components/TeamForm";
import CampaignDetails from "views/Dashboard/Campaign/CampaignDetails";
import ForceChangePassword from "views/Auth/ForceChangePassword";
import ConfirmRegistration from "views/Auth/ConfirmRegistration";
import BrandRegistration from "views/Auth/BrandRegistration";
import SignOut from "views/Auth/SignOut";
import ProjectForm from "views/Dashboard/Projects/components/ProjectForm";
import Home from "views/Dashboard/Home";
import { GigIcon } from "components/Icons/Icons";
import { GlobeIcon } from "components/Icons/Icons";
import { HomeDashboard } from "views/Dashboard/Home";
import ViewTeam from "views/Dashboard/Teams/components/ViewTeam";
import ViewGroup from "views/Dashboard/Groups/components/ViewGroup";
import CampaignList from "views/Dashboard/Campaign/CampaignList";
import IssueList from "views/Dashboard/Issues/IssueList";
import ConversationList from "views/Dashboard/Conversations/ConversationList";
import FeedbackList from "views/Dashboard/Feedback/FeedbackList";
import NotificationList from "views/Dashboard/Notifications/NotificationList";
import { GroupIcon } from "components/Icons/Icons";
import { ProjectIcon } from "components/Icons/Icons";
import { FaBullhorn, FaHome, FaPeopleArrows, FaMoneyBillAlt, FaProjectDiagram, FaRocketchat, FaTeamspeak, FaUser, FaUserFriends,FaBriefcase,FaCog, FaCoins,FaUsers, FaSubscript, FaVideo } from "react-icons/fa";
import FormLayout from "layouts/FormLayout";
import GroupForm from "views/Dashboard/Groups/components/GroupForm";
import IssueForm from "views/Dashboard/Issues/IssueForm";
import ForgotPassword from "views/Auth/ForgotPassword";
import ProjectDetails from "views/Dashboard/Projects/components/ProjectDetails";
import BrandsUsersList from "views/Dashboard/Brands/BrandsUsersList";
import AmbassdorsList from "views/Dashboard/Ambassdors/AmbassdorsList";
import Settings from "views/Dashboard/Settings/Settings";
import CreditsList from "views/Dashboard/Credits/CreditsList";
import CreditForm from "views/Dashboard/Credits/CreditForm";
import BrandsList from "views/Dashboard/Brands/BrandsList";
import { Subscription } from "views/Dashboard/Subscription/Subscription";
import SubscriptionList from "views/Dashboard/Subscription/SubscriptionList";
import Contracts from "views/Dashboard/Contracts";
import ViewContract from "views/Dashboard/Contracts/components/ViewContract";
import ContractForm from "views/Dashboard/Contracts/components/ContractForm";
import SubmissionList from "views/Dashboard/Campaign/SubmissionList";

const brandSubdomain = window.location.hostname.split('.')[0]?.toLowerCase() || ''

export const BrandBassDashboardRouter = () => {
  return (<Stack w={{ base: 'full' }} direction="column" minH='100vh'>
    <Box p={0} width="100%" maxW="100%" mb={10}>
      <Routes>
        <Route path="/auth" element={<Login />}>
          <Route path="" element={<SignIn />} />
          {brandSubdomain === 'dashboard' || brandSubdomain === 'dashboard-prod' ? <Route path="signup" element={<SignUp />} /> : null}
          <Route path="confirm-registration" element={<ConfirmRegistration />} />
          <Route path="reset-password" element={<ForceChangePassword />} />
          <Route path="forgot-password" element={<ForgotPassword />} />
        </Route>

        <Route path="/" element={<CheckAuth access="view"> <Dashboard /> </CheckAuth>}>
          <Route path="" element={<HomeDashboard />} />
          <Route path="mock" element={<Home />} />
        
          <Route path="campaigns" element={<CampaignList />} />
          <Route path="submissions" element={<SubmissionList />} />

          <Route path="brand-users" element={<BrandsUsersList />} />
          <Route path="brands" element={<BrandsList />} />
          <Route path="ambassadors" element={<AmbassdorsList />} />
          <Route path="settings" element={<Settings />} />
          <Route path="credits" element={<CreditsList />} />
          <Route path="contracts" element={<Contracts/>}/>
          <Route path="contracts/:ContractID" element={<ViewContract/>}/>
          <Route path="campaigns/:CampaignID" element={<CampaignDetails />} />
          <Route path="projects/:ProjectID" element={<ProjectDetails />} />
          {
            premiumRouteCheck(
              <>
                <Route path="users" element={<Users />} />
                <Route path="users/:UserID/edit" element={<ViewUser />} />
                <Route path="teams" element={<Teams />} />
                <Route path="teams/:TeamID" element={<ViewTeam />} />
                <Route path="groups" element={<Groups />} />
                <Route path="groups/:GroupID" element={<ViewGroup />} />
                <Route path="projects" element={<Projects />} />
                <Route path="billing" element={<Billing />} />
                <Route path="contracts" element={<Contracts/>}/>
                <Route path="contracts/:ContractID" element={<ViewContract/>}/>
              </>
            )
          }
          <Route path="issues" element={<IssueList />} />
          <Route path="feedbacks" element={<FeedbackList />} />
          <Route path="profile" element={<Profile />} />
          <Route path="signout" element={<SignOut />} />
          <Route path="conversations" element={<ConversationList />} />
          <Route path="notifications" element={<NotificationList />} />
          <Route path="subscriptions" element={<SubscriptionList />} />
        </Route>

        <Route path="/new" element={<CheckAuth access="create"> <FormLayout /> </CheckAuth>}>
          <Route path="subscription" element={<Subscription />} />          
          <Route path="brand-registration" element={<BrandRegistration />} />
          <Route path="campaign" element={<CampaignForm />} />
          <Route path="user" element={<UserForm />} />
          <Route path="group" element={<GroupForm />} />
          <Route path="team" element={<TeamForm />} />
          <Route path="project" element={<ProjectForm />} />
          <Route path="credit" element={<CreditForm />} />
          <Route path="contract" element={<ContractForm />} />
        </Route>
        <Route path="/new" element={<CheckAuth> <FormLayout /> </CheckAuth>}>
          <Route path="issue" element={<IssueForm />} />
        </Route>

        <Route path="/edit" element={<CheckAuth access="edit"> <FormLayout /> </CheckAuth>}>
          <Route path="subscription" element={<Subscription />} />
          <Route path="subscription/:SubscriptionID" element={<Subscription />} />
          <Route path="brand-registration" element={<BrandRegistration />} />
          <Route path="brand-registration/:BrandID" element={<BrandRegistration />} />
          <Route path="campaign/:CampaignID" element={<CampaignForm />} />
          <Route path="group/:GroupID" element={<GroupForm />} />
          <Route path="team/:TeamID" element={<TeamForm />} />
          <Route path="issue/:IssueID" element={<IssueForm />} />
          <Route path="project/:ProjectID" element={<ProjectForm />} />
          <Route path="user/:UserID" element={<UserForm />} />
          <Route path="contract/:ContractID" element={<ContractForm />} />
        </Route>

        <Route path="*" element={<NoMatch />} />
      </Routes>
    </Box>
    <Box width="100%" maxW="100%" paddingTop='1rem' paddingY='1rem' >
      <Footer />
    </Box>
  </Stack>
  )
}
const generalRoutes = [
  {
    path: "/",
    name: "Dashboard",
    icon: <Icon as={FaHome} w={5} h={5} />,
    layout: "/",
  },
  {
    path: "/campaigns",
    name: "Campaigns",
    icon: <Icon as={FaBullhorn} w={5} h={5} />,
    layout: "/",
  },
  {
    path: "/submissions",
    name: "Submissions",
    icon: <Icon as={FaVideo} w={5} h={5} />,
    layout: "/",
  },
  {
    path: "/brand-users",
    name: "Brand Users",
    // icon: <ChatIcon />,
    icon: <Icon as={FaUsers} w={5} h={5} />,
    layout: "/",
    access: ['SUPER_ADMIN']
  },
  {
    path: "/ambassadors",
    name: "Ambassadors",
    // icon: <ChatIcon />,
    icon: <Icon as={FaUser} w={5} h={5} />,
    layout: "/",
    access: ['SUPER_ADMIN']
  },
  {
    path: "/subscriptions",
    name: "Subscriptions",
    // icon: <ChatIcon />,
    icon: <Icon as={FaMoneyBillAlt} w={5} h={5} />,
    layout: "/",
    access: ['SUPER_ADMIN']
  },
  {
    path: "/issues",
    name: "Issues",
    icon: <WarningTwoIcon w={5} h={5} />,
    layout: "/",
  },
  {
    path: "/feedbacks",
    name: "Feedback",
    icon: <StarIcon w={5} h={5} />,
    layout: "/",
    access: ['SUPER_ADMIN', 'CLIENT_SUPER_ADMIN', 'BRAND_SUPER_ADMIN', 'BRAND_MANAGER']
  },
  {
    path: "/conversations",
    name: "Conversations",
    icon: <Icon as={FaRocketchat} w={5} h={5} />,
    layout: "/",
    access: ['SUPER_ADMIN','CLIENT_SUPER_ADMIN', 'BRAND_SUPER_ADMIN', 'BRAND_MANAGER']
  },
  {
    path: "/notifications",
    name: "Notifications",
    icon: <BellIcon w={6} h={6} />,
    layout: "/",
  },
  {
    path: "/settings",
    name: "Settings",
    // icon: <ChatIcon />,
    icon: <Icon as={FaCog} w={5} h={5} />,
    layout: "/",
    access: ['SUPER_ADMIN']
  },
  {
    path: "/credits",
    name: "Credits",
    // icon: <ChatIcon />,
    icon: <Icon as={FaCoins} w={5} h={5} />,
    layout: "/",
    access: ['SUPER_ADMIN','CLIENT_SUPER_ADMIN','BRAND_SUPER_ADMIN']
  },
  {
    path: "/brands",
    name: "Brands",
    // icon: <ChatIcon />,
    icon: <Icon as={FaBriefcase} w={5} h={5} />,
    layout: "/",
    access: ['SUPER_ADMIN', 'CLIENT_SUPER_ADMIN']
  },
  {
    path: "/contracts",
    name: "Contracts",
    icon: <Icon as={FaRocketchat} w={5} h={5} />,
    layout: "/",
    access: ['AMBASSADOR','SUPER_ADMIN']
  },
  // {
  //   path: "/subscription",
  //   name: "Subscription",
  //   // icon: <ChatIcon />,
  //   icon: <Icon as={FaMoneyBillAlt} w={5} h={5} />,
  //   layout: "/",
  //   access: ['BRAND_SUPER_ADMIN', 'CLIENT_SUPER_ADMIN']
  // },
]

const premiumRoutes = [
  {
    path: "/users",
    name: "Users",
    icon: <Icon as={FaUser} w={5} h={5} />,
    layout: "/",
    access: ['SUPER_ADMIN','CLIENT_SUPER_ADMIN', 'BRAND_SUPER_ADMIN', 'BRAND_MANAGER']
  },
  {
    path: "/contracts",
    name: "Contracts",
    icon: <Icon as={FaRocketchat} w={5} h={5} />,
    layout: "/",
    access: ['SUPER_ADMIN','CLIENT_SUPER_ADMIN', 'BRAND_SUPER_ADMIN', 'BRAND_MANAGER']
  },
  {
    path: "/teams",
    name: "Teams",
    icon: <Icon as={FaUserFriends} w={5} h={5} />,
    layout: "/",
    access: ['SUPER_ADMIN','CLIENT_SUPER_ADMIN', 'BRAND_SUPER_ADMIN', 'BRAND_MANAGER']
  },
  {
    path: "/groups",
    name: "Groups",
    icon: <Icon as={FaPeopleArrows} w={5} h={5} />,
    layout: "/",
    access: ['SUPER_ADMIN','CLIENT_SUPER_ADMIN', 'BRAND_SUPER_ADMIN', 'BRAND_MANAGER']
  },
  {
    path: "/projects",
    name: "Projects",
    icon: <Icon as={FaProjectDiagram} w={5} h={5} />,
    layout: "/",
    access: ['SUPER_ADMIN','CLIENT_SUPER_ADMIN', 'BRAND_SUPER_ADMIN', 'BRAND_MANAGER']
  }, 
  {
    path: "/billing",
    name: "Billing",
    icon: <Icon as={FaMoneyBillAlt} w={5} h={5} />,
    layout: "/",
    access: ['CLIENT_SUPER_ADMIN', 'BRAND_SUPER_ADMIN', 'BRAND_MANAGER']
  },
  // {
  //   path: "/credits",
  //   name: "Credits",
  //   // icon: <ChatIcon />,
  //   icon: <Icon as={FaCoins} w={5} h={5} />,
  //   layout: "/",
  //   access: ['CLIENT_SUPER_ADMIN', 'BRAND_SUPER_ADMIN']
  // }
]

const dashRoutes = brandSubdomain === 'dashboard'  || brandSubdomain === 'dashboard-prod' || brandSubdomain === 'admin' ? generalRoutes : [...generalRoutes, ...premiumRoutes]

const premiumRouteCheck = (element) => brandSubdomain === 'dashboard'  || brandSubdomain === 'dashboard-prod' || brandSubdomain === 'admin' ? null : element

export default dashRoutes;

/*
export enum UserRole  {
  SUPER_ADMIN = "SUPER_ADMIN",
  AMBASSADOR = "AMBASSADOR",
  BRAND_MANAGER = "BRAND_MANAGER",
  BRAND_SUPER_ADMIN = "BRAND_SUPER_ADMIN",
  BRAND_USER = "BRAND_USER",
  NONE = "NONE"
}

*/
