import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Flex,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    MenuItemOption,
    MenuGroup,
    MenuOptionGroup,
    MenuDivider, Button} from "@chakra-ui/react"

import {ChevronDownIcon} from "@chakra-ui/icons"

import { Separator } from "components/Separator/Separator"
import { useNavigate } from "react-router-dom"

const CreateEntityLinks = (props) => {
    const navigate = useNavigate()
    const brandSubdomain = window.location.hostname.split('.')[0]?.toLowerCase() || ''
    return <Box {...props}>
        <Menu>
            <MenuButton as={Button} leftIcon={<ChevronDownIcon />} size="sm" variant="outline" colorScheme='brandbassBlue' color="brandbassBlue" >
                CREATE
            </MenuButton>
            <MenuList>
                <MenuItem onClick={() => navigate('/new/campaign', { replace: true })}>Campaign</MenuItem>
                {brandSubdomain === 'dashboard' || brandSubdomain === 'dashboard-prod' || brandSubdomain === 'admin' ? null : <MenuItem onClick={() => navigate('/new/project', { replace: true })}>Project</MenuItem>}
            </MenuList>
        </Menu>
    </Box>
}

export default CreateEntityLinks